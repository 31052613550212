var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.psychosocial_accompaniment?.meeting_happened
    ? _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
        _c(
          "div",
          { staticClass: "col-span-2 text-burnedYellow pb-2" },
          [
            _c("Label", {
              attrs: {
                text: "Situação de Vulnerabilidade",
                icon: null,
                "icon-type": null,
                type: "text-sm",
                weight: "semibold",
                normal: "",
                mode: "uppercase",
              },
            }),
            _c("div", { staticClass: "border-ashes-hover border-b-2 w-full" }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("AppRadioButton", {
              attrs: {
                values: _vm.vulnerabilityMatrixOptions,
                labelText: "Cadastrar Matriz de Vulnerabilidade?",
                showLabel: "",
                dispatch:
                  "PsychosocialAccompaniments/setRegisterVulnerabilityMatrix",
              },
            }),
          ],
          1
        ),
        _vm.showOpenMatrixButton
          ? _c(
              "div",
              [
                _c("AppButtonColorful", {
                  attrs: {
                    text: "Abrir Matriz",
                    typeButton: "danger",
                    showIcon: "",
                    iconName: "add",
                    iconType: "outlined",
                    dispatch:
                      "MatrixRiskStratifications/openEditModalMatrixRiskStratification",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.showEditMatrixButton
          ? _c(
              "div",
              [
                _c("AppButtonColorful", {
                  attrs: {
                    text: "Editar Matriz",
                    typeButton: "danger",
                    showIcon: "",
                    iconName: "add",
                    iconType: "outlined",
                    dispatch:
                      "MatrixRiskStratifications/openEditModalMatrixRiskStratification",
                    payload: _vm.matrix_risk_stratifications_by_psychosocial,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.showSituationTextField
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text":
                      "Situação de acordo com dados marcados até o momento",
                    text: _vm.getSituation(),
                    color: "carmesim",
                    "type-text": "custom",
                    weight: "semibold",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }