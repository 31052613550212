var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { class: _vm.liClass }, [
    _c("div", { staticClass: "grid grid-cols-2 items-center pt-2" }, [
      _c("div", [
        _c("div", { staticClass: "flex justify-start items-center" }, [
          _c(
            "div",
            { class: _vm.iconClass },
            [
              _c("AppMaterialIcon", {
                staticClass: "place-self-center text-white",
                attrs: {
                  iconName: "book",
                  iconType: "outlined",
                  iconSize: "36",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseText", {
                staticClass: "mr-1",
                attrs: {
                  text: _vm.getNameStudent(),
                  typeText: "custom",
                  mode: "underline",
                  weight: "bold",
                  size: "xl",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      !_vm.meeting.suspended
        ? _c("div", { staticClass: "grid justify-self-stretch" }, [
            _c("div", { staticClass: "grid grid-cols-6 items-center" }, [
              _c(
                "div",
                { staticClass: "flex justify-start items-center col-span-5" },
                [
                  _c(
                    "div",
                    { staticClass: "w-40" },
                    [
                      ((_vm.permit && _vm.season_date_manual_execution_end) ||
                        (_vm.permit && _vm.ability_profile)) &&
                      _vm.isTeamsInProgress()
                        ? _c("AppRadioButton", {
                            attrs: {
                              values: [
                                {
                                  name: `presences${_vm.presence.id}`,
                                  value: true,
                                  presence: _vm.presence,
                                  description: "Sim",
                                  checked: _vm.presence.presence === true,
                                },
                                {
                                  name: `presences${_vm.presence.id}`,
                                  value: false,
                                  presence: _vm.presence,
                                  description: "Não",
                                  checked: _vm.presence.presence === false,
                                },
                              ],
                              showLabel: false,
                              dispatch: "Presence/setIsPresent",
                            },
                          })
                        : _vm._e(),
                      !_vm.permit ||
                      (!_vm.season_date_manual_execution_end &&
                        !_vm.ability_profile) ||
                      !_vm.isTeamsInProgress()
                        ? _c("BaseText", {
                            staticClass: "mr-1",
                            attrs: {
                              text: _vm.getPresenceResponse(),
                              typeText: "custom",
                              weight: "normal",
                              size: "sm",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm.presence.presence != null &&
                  _vm.presence.presence !== true &&
                  !_vm.presence.justification &&
                  _vm.permit &&
                  (_vm.season_date_manual_execution_end ||
                    _vm.ability_profile) &&
                  _vm.isTeamsInProgress()
                    ? _c("div", [
                        _c(
                          "a",
                          {
                            staticClass: "cursor-pointer",
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.openModa(_vm.presence)
                              },
                            },
                          },
                          [
                            _c("BaseText", {
                              staticClass: "mr-1",
                              attrs: {
                                text: "Justificar",
                                typeText: "custom",
                                mode: "underline",
                                weight: "bold",
                                size: "sm",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _c("div", [
                        _c(
                          "div",
                          { staticClass: "break-all" },
                          [
                            _c("BaseText", {
                              staticClass: "mr-1",
                              attrs: {
                                text: _vm.getJustificationResponse(),
                                typeText: "custom",
                                mode: "normal-case",
                                weight: "bold",
                                size: "sm",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                  _c(
                    "div",
                    { key: _vm.shouldShowDownloadButton },
                    [
                      _vm.shouldShowDownloadButton
                        ? _c("AppButtonIcon", {
                            attrs: {
                              iconName: "download",
                              payload: _vm.presence.student_documents[0].id,
                              dispatch:
                                "ModalInscription/showStudentDocumentJustificationDocument",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
              _vm.isTeamsInProgress()
                ? _c(
                    "div",
                    { staticClass: "justify-self-end" },
                    [
                      _vm.presence.justification && _vm.permit
                        ? _c("AppButtonIcon", {
                            attrs: {
                              iconName: "edit",
                              dispatch: "Presence/openJustificationModal",
                              payload: _vm.presence,
                            },
                          })
                        : _vm._e(),
                      _vm.presence.presence != null && _vm.permit_destroy
                        ? _c("AppButtonIcon", {
                            attrs: {
                              iconName: "delete",
                              dispatch:
                                "Presence/openCloseDeletePresenceConfirmation",
                              payload: _vm.presence,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ])
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }