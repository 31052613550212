var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m-5" },
    [
      _c("div", { staticClass: "grid grid-cols-2 gap-5 mt-10 pb-8" }, [
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("BaseText", {
              attrs: {
                text: "escolha a edição da qual quer participar",
                "type-text": "title-forms",
              },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("Multiselect", {
              attrs: {
                id: "select-season",
                "select-placeholder": "Selecionar",
                "label-text": "Edição",
                value: _vm.student.season,
                "close-on-select": "",
                "show-label": "",
                required: "",
                "error-message": _vm.getErrorMessage("season", _vm.season_id),
                "multiple-option": false,
                options: _vm.seasons,
                dispatch: _vm.setSeason,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("Multiselect", {
              attrs: {
                id: "select-equipment",
                "select-placeholder": "Selecionar",
                "label-text": "Equipamento",
                "error-message": _vm.getErrorMessage(
                  "equipment",
                  _vm.equipment
                ),
                value: _vm.student.equipment,
                "close-on-select": "",
                "show-label": "",
                "multiple-option": false,
                options: _vm.equipmentAll,
                dispatch: _vm.setEquipment,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "grid grid-cols-5 gap-5" }, [
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("BaseText", {
              attrs: { text: "dados pessoais", "type-text": "title-forms" },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "name",
                value: _vm.student.name,
                "show-label": "",
                "label-text": "nome completo",
                required: "",
                "error-message": _vm.getErrorMessage(
                  "student.name",
                  _vm.student.name
                ),
                dispatch: "Student/setName",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "socialName",
                value: _vm.student.social_name,
                "show-label": "",
                "label-text": "Nome Social",
                "error-message": _vm.getErrorMessage(
                  "student.social_name",
                  _vm.student.social_name
                ),
                dispatch: "Student/setSocialName",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "rg",
                value: _vm.student.rg,
                "input-placeholder": "0000000000000",
                "show-label": "",
                "label-text": "rg",
                dispatch: "Student/setRg",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "dispatching_agency",
                value: _vm.student.dispatching_agency,
                "show-label": "",
                "label-text": "orgão expeditor",
                dispatch: "Student/setDispatchingAgency",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "expedition",
                "input-type": "date",
                value: _vm.student.expedition,
                "show-label": "",
                "label-text": "expedição",
                dispatch: "Student/setExpedition",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "cpf",
                value: _vm.student.cpf,
                "input-placeholder": "000.000.000-000",
                mask: "###.###.###-##",
                "show-label": "",
                "label-text": "cpf",
                "error-message": _vm.getErrorMessage(
                  "student.cpf",
                  _vm.student.cpf
                ),
                dispatch: "Student/setCpf",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "city_of_birth",
                value: _vm.student.city_of_birth,
                "show-label": "",
                "label-text": "cidade onde nasceu",
                dispatch: "Student/setCityOfBirth",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "birth_certificate",
                "input-placeholder": "00000000000000",
                value: _vm.student.birth_certificate,
                "show-label": "",
                "label-text": "certidão de nascimento",
                dispatch: "Student/setBirthCertificate",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "book",
                value: _vm.student.book,
                "show-label": "",
                "label-text": "livro",
                dispatch: "Student/setBook",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "page",
                value: _vm.student.page,
                "show-label": "",
                "label-text": "folha",
                dispatch: "Student/setPage",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "email",
                value: _vm.student.email,
                "show-label": "",
                "error-message": _vm.getErrorMessage(
                  "student.email",
                  _vm.student.email
                ),
                "label-text": "email",
                dispatch: "Student/setEmail",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "cellphone_number",
                value: _vm.student.cellphone_number,
                "input-placeholder": "(85) 90000.0000",
                "show-label": "",
                required: "",
                mask: "(##) #####.####",
                "error-message": _vm.getErrorMessage(
                  "student.cellphone_number",
                  _vm.student.cellphone_number
                ),
                "label-text": "telefone",
                dispatch: "Student/setCellphoneNumber",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "whatszap",
                value: _vm.student.whatszap,
                mask: ["(##) ####.####", "(##) #####.####"],
                "input-placeholder": "(85) 90000.0000",
                "show-label": "",
                "label-text": "whatsapp de contato",
                dispatch: "Student/setWhatszap",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2 flex-col items-end" },
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "birthdate",
                "input-type": "date",
                value: _vm.student.birthdate,
                "show-label": "",
                "label-text": "Data de Nascimento",
                required: "",
                "error-message": _vm.getErrorMessage(
                  "student.birthdate",
                  _vm.student.birthdate
                ),
                dispatch: "Student/addBirthdate",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-3 flex justify-center items-center" },
          [
            _c("AppRadioButton", {
              attrs: {
                "show-label": "",
                "label-text": "gênero",
                values: [
                  {
                    name: "gender",
                    value: "Feminino",
                    description: "feminino",
                    checked: _vm.student.gender === "Feminino",
                  },
                  {
                    name: "gender",
                    value: "Masculino",
                    description: "masculino",
                    checked: _vm.student.gender === "Masculino",
                  },
                  {
                    name: "gender",
                    value: "Não-Binárie",
                    description: "Não-Binárie",
                    checked: _vm.student.gender === "Não-Binárie",
                  },
                  {
                    name: "gender",
                    value: "Outros",
                    description: "outros",
                    checked: _vm.student.gender === "Outros",
                  },
                  {
                    name: "gender",
                    value: "Prefiro não Falar",
                    description: "Prefiro não Falar",
                    checked: _vm.student.gender === "Prefiro não Falar",
                  },
                ],
                dispatch: "Student/setGender",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-3 flex justify-center items-center" },
          [
            _c("AppRadioButton", {
              attrs: {
                "show-label": "",
                "label-text": "Se identifica como LGBTQIAPN+",
                values: [
                  {
                    name: "identifies_as_lgbtqiapn",
                    value: "Sim",
                    description: "Sim",
                    checked: _vm.student.identifies_as_lgbtqiapn === "Sim",
                  },
                  {
                    name: "identifies_as_lgbtqiapn",
                    value: "Não",
                    description: "Não",
                    checked: _vm.student.identifies_as_lgbtqiapn === "Não",
                  },
                  {
                    name: "identifies_as_lgbtqiapn",
                    value: "Prefiro não Falar",
                    description: "Prefiro não Falar",
                    checked:
                      _vm.student.identifies_as_lgbtqiapn ===
                      "Prefiro não Falar",
                  },
                ],
                dispatch: "Student/setIdentifiesAsLgbtqiapn",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-3 flex justify-center items-center" },
          [
            _c("AppRadioButton", {
              attrs: {
                "show-label": "",
                "label-text": "Pertence a povos originários",
                values: [
                  {
                    name: "belongs_to_indigenous_peoples",
                    value: "Não",
                    description: "Não",
                    checked:
                      _vm.student.belongs_to_indigenous_peoples === "Não",
                  },
                  {
                    name: "belongs_to_indigenous_peoples",
                    value: "Indígena",
                    description: "Indígena",
                    checked:
                      _vm.student.belongs_to_indigenous_peoples === "Indígena",
                  },
                  {
                    name: "belongs_to_indigenous_peoples",
                    value: "Quilombola",
                    description: "Quilombola",
                    checked:
                      _vm.student.belongs_to_indigenous_peoples ===
                      "Quilombola",
                  },
                  {
                    name: "belongs_to_indigenous_peoples",
                    value: "Cigano",
                    description: "Cigano",
                    checked:
                      _vm.student.belongs_to_indigenous_peoples === "Cigano",
                  },
                  {
                    name: "belongs_to_indigenous_peoples",
                    value: "Outros",
                    description: "Outros",
                    checked:
                      _vm.student.belongs_to_indigenous_peoples === "Outros",
                  },
                ],
                dispatch: "Student/setBelongsToIndigenousPeoples",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("Multiselect", {
              staticClass: "mr-5",
              attrs: {
                id: "select-civil-state",
                "select-placeholder": "Selecionar",
                "label-text": "Estado Cívil",
                "close-on-select": "",
                "show-label": "",
                "multiple-option": false,
                options: _vm.civilStates,
                value: _vm.student.civil_state,
                dispatch: _vm.setCivilState,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "nis",
                value: _vm.student.nis,
                "show-label": "",
                "label-text": "nis",
                dispatch: "Student/setNis",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("Multiselect", {
              staticClass: "mr-5",
              attrs: {
                id: "select-deficiencie",
                "select-placeholder": "Escolha uma ou mais opções",
                "label-text": "Possui alguma deficiência?",
                "show-label": "",
                options: _vm.deficiencies,
                value: _vm.student.deficiencies_attributes,
                dispatch: _vm.setDeficiencies,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-3" },
          [
            _c("Multiselect", {
              staticClass: "mr-5",
              attrs: {
                id: "select-race",
                "select-placeholder": "Selecionar",
                "label-text": "Cor / Raça",
                "close-on-select": "",
                "show-label": "",
                "multiple-option": false,
                options: _vm.races,
                value: _vm.student.race,
                dispatch: _vm.setRace,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("Multiselect", {
              staticClass: "mr-5",
              attrs: {
                id: "select-number-sons",
                "select-placeholder": "Selecionar",
                "label-text": "Possui filhos?",
                "close-on-select": "",
                "multiple-option": false,
                "show-label": "",
                options: _vm.numbersSons,
                value: _vm.student.number_sons,
                dispatch: _vm.setNumberSons,
                "error-message": _vm.getErrorMessage(
                  "student.number_sons",
                  _vm.student.number_sons
                ),
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("AppRadioButton", {
              attrs: {
                "show-label": "",
                "label-text": "Está grávida?",
                values: [
                  {
                    name: "is_pregnant",
                    value: true,
                    description: "sim",
                    checked: _vm.student.is_pregnant === true,
                  },
                  {
                    name: "is_pregnant",
                    value: false,
                    description: "não",
                    checked: _vm.student.is_pregnant === false,
                  },
                ],
                "error-message": _vm.getErrorMessage(
                  "student.is_pregnant",
                  _vm.student.is_pregnant
                ),
                dispatch: "Student/setIsPregnant",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "voter_title",
                value: _vm.student.document_attributes.voter_title,
                "show-label": "",
                mask: "#### #### ####",
                "label-text": "Título de eleitor (jovens a partir de 16 anos):",
                dispatch: "Student/setVoterTitle",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "work_permit",
                value: _vm.student.document_attributes.work_permit,
                mask: "###.#####.##-#",
                "show-label": "",
                "label-text": "Carteira de trabalho:",
                dispatch: "Student/setWorkPermit",
                width: "36px",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "reserve_card",
                value: _vm.student.document_attributes.reserve_card,
                "show-label": "",
                mask: "##.####.######-#",
                "label-text":
                  "carteira de reservista (homens a partir de 18 anos):",
                dispatch: "Student/setReserveCard",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "grid grid-cols-4 gap-5 mt-10" }, [
        _c(
          "div",
          { staticClass: "col-span-4" },
          [
            _c("BaseText", {
              attrs: { text: "Endereço", "type-text": "title-forms" },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "cep",
                value: _vm.student.cep,
                "input-placeholder": "00000-000",
                "show-label": "",
                mask: "#####-###",
                "label-text": "cep",
                "error-message": _vm.getErrorMessage(
                  "student.cep",
                  _vm.student.cep
                ),
                dispatch: "Student/setCep",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "street",
                value: _vm.student.street,
                "show-label": "",
                "label-text": "rua",
                dispatch: "Student/setStreet",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "street_number",
                value: _vm.student.street_number,
                "show-label": "",
                "label-text": "número",
                dispatch: "Student/setStreetNumber",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("Multiselect", {
              staticClass: "mr-5",
              attrs: {
                id: "select-state",
                "select-placeholder": "Selecionar",
                "label-text": "Estado",
                value: _vm.student.state,
                "close-on-select": "",
                "show-label": "",
                required: "",
                "error-message": _vm.getErrorMessage(
                  "student.state",
                  _vm.student.state
                ),
                "multiple-option": false,
                options: _vm.states,
                dispatch: _vm.setState,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("Multiselect", {
              staticClass: "mr-5",
              attrs: {
                id: "select-city",
                "select-placeholder": "Selecionar",
                "label-text": "Cidade",
                value: _vm.student.city,
                "close-on-select": "",
                "show-label": "",
                "multiple-option": false,
                options: _vm.cities,
                required: "",
                "error-message": _vm.getErrorMessage(
                  "student.city",
                  _vm.student.city
                ),
                dispatch: _vm.setCity,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("Multiselect", {
              staticClass: "mr-5",
              attrs: {
                id: "select-neighborhood",
                "select-placeholder": "Selecionar",
                "label-text": "Bairro",
                value: _vm.student.neighborhood,
                "close-on-select": "",
                "show-label": "",
                required: "",
                "error-message": _vm.getErrorMessage(
                  "student.neighborhood",
                  _vm.student.neighborhood
                ),
                "multiple-option": false,
                options: _vm.neighborhoods,
                dispatch: _vm.setNeighborhood,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "community",
                value: _vm.student.community,
                "show-label": "",
                "label-text": "comunidade",
                dispatch: "Student/setCommunity",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "complement",
                value: _vm.student.complement,
                "show-label": "",
                "label-text": "complemento (opcional)",
                dispatch: "Student/setComplement",
              },
            }),
          ],
          1
        ),
      ]),
      _vm.student.id
        ? _c("div", { staticClass: "grid grid-cols-4 gap-5 mt-10" }, [
            _c(
              "div",
              { staticClass: "col-span-4" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Dados de Matrícula",
                    "type-text": "title-forms",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            ),
            _vm.student.id
              ? _c(
                  "div",
                  { staticClass: "col-span-3" },
                  [
                    _c("Multiselect", {
                      staticClass: "mr-5",
                      attrs: {
                        id: "select-qualification-status",
                        "select-placeholder": "Selecionar",
                        "label-text": "Selecionar Status Qualificação",
                        "close-on-select": "",
                        "show-label": "",
                        "multiple-option": false,
                        options: _vm.qualificationStatus,
                        value: _vm.student.qualification_status,
                        dispatch: _vm.setQualificationStatus,
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ])
        : _vm._e(),
      _c("div", { staticClass: "grid grid-cols-6 gap-5 mt-10" }, [
        _c(
          "div",
          { staticClass: "col-span-6" },
          [
            _c("BaseText", {
              attrs: {
                text: "dados complementares",
                "type-text": "title-forms",
              },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("AppRadioButton", {
              attrs: {
                "show-label": "",
                "label-text": "frequenta a escola?",
                values: [
                  {
                    name: "studying",
                    value: true,
                    description: "sim",
                    checked: _vm.student.studying === true,
                  },
                  {
                    name: "studying",
                    value: false,
                    description: "não",
                    checked: _vm.student.studying === false,
                  },
                ],
                required: "",
                "error-message": _vm.getErrorMessage(
                  "student.studying",
                  _vm.student.studying
                ),
                dispatch: "Student/setStudying",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-span-2" }, [
          _vm.student.studying === true
            ? _c("div", [
                _c("img", {
                  staticClass: "inline-block",
                  attrs: {
                    src: require("../assets/images/icon-alerta.svg"),
                    alt: "",
                  },
                }),
                _c(
                  "span",
                  {
                    staticClass: "font-system",
                    staticStyle: { "font-size": "0.65rem" },
                  },
                  [
                    _vm._v(
                      " APENAS JOVENS NO SISTEMA SOCIOEDUCATIVO ESTÃO DENTRO DO PRÉ-REQUISITO PARA PARTICIPAR DO PROJETO VIRANDO O JOGO. Jovens regularmente matriculados na rede de ensino fora desta exceção não devem participar. "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          [
            _c("AppRadioButton", {
              attrs: {
                "show-label": "",
                "label-text": "tem ocupação formal?",
                values: [
                  {
                    name: "busy",
                    value: true,
                    description: "sim",
                    checked: _vm.student.busy === true,
                  },
                  {
                    name: "busy",
                    value: false,
                    description: "não",
                    checked: _vm.student.busy === false,
                  },
                ],
                required: "",
                "error-message": _vm.getErrorMessage(
                  "student.busy",
                  _vm.student.busy
                ),
                dispatch: "Student/setBusy",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "col-span-2" }, [
          _vm.student.busy === true
            ? _c("div", [
                _c("img", {
                  staticClass: "inline-block",
                  attrs: {
                    src: require("../assets/images/icon-alerta.svg"),
                    alt: "",
                  },
                }),
                _c(
                  "span",
                  {
                    staticClass: "font-system",
                    staticStyle: { "font-size": "0.65rem" },
                  },
                  [
                    _vm._v(
                      " Jovens que possuem ocupação formal não estão dentro do pré-requisito para participar do projeto virando o jogo. "
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
        _c(
          "div",
          { staticClass: "col-span-3" },
          [
            _c("Multiselect", {
              staticClass: "mr-5",
              attrs: {
                id: "select-completed-series",
                "select-placeholder": "Selecionar",
                "label-text": "qual a sua última série concluída?",
                "close-on-select": "",
                "show-label": "",
                "multiple-option": false,
                options: _vm.completedSeries,
                value: _vm.student.completed_series,
                dispatch: _vm.setCompletedSeries,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-3" },
          [
            _c("Multiselect", {
              staticClass: "mr-5",
              attrs: {
                id: "select-professional-qualifications",
                "select-placeholder": "Escolha uma ou mais opções",
                "label-text":
                  "Escolha uma ou mais qualificações profissionais de seu interesse",
                value: _vm.student.professional_qualifications_attributes,
                "close-on-select": false,
                "show-label": "",
                label: "description",
                options: _vm.professionalQualifications,
                dispatch: _vm.setProfessionalQualifications,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-3" },
          [
            _c("AppCheckBox", {
              attrs: {
                "show-label": "",
                "label-text":
                  "ESCOLHA QUAIS OS TURNOS VOCÊ TEM DISPONÍVEIS PARA PARTICIPAR DO PROJETO",
                values: [
                  {
                    name: "morning-shift",
                    value: "2",
                    description: "manhã",
                    checked: _vm.student.morning_shift,
                  },
                  {
                    name: "afternoon-shift",
                    value: "1",
                    description: "tarde",
                    checked: _vm.student.afternoon_shift,
                  },
                  {
                    name: "night-shift",
                    value: "0",
                    description: "noite",
                    checked: _vm.student.night_shift,
                  },
                ],
                dispatch: "Student/setShift",
              },
            }),
          ],
          1
        ),
        _vm.student.id
          ? _c(
              "div",
              { staticClass: "col-span-3" },
              [
                _c("Multiselect", {
                  staticClass: "mr-5",
                  attrs: {
                    id: "select-institutional-referral",
                    "select-placeholder": "Selecionar",
                    "label-text": "Selecionar Encaminhamento Institucional",
                    "close-on-select": "",
                    "show-label": "",
                    "multiple-option": false,
                    options: _vm.institutionalReferral,
                    value: _vm.student.institutional_referral,
                    dispatch: _vm.setInstitutionalReferral,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "grid grid-cols-4 gap-5 mt-10" }, [
        _c(
          "div",
          { staticClass: "col-span-4" },
          [
            _c("BaseText", {
              attrs: {
                text: "Dados de Conta Bancária",
                "type-text": "title-forms",
              },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-3" },
          [
            _c("Multiselect", {
              staticClass: "mr-5",
              attrs: {
                id: "select-bank-name",
                "select-placeholder": "Selecione o seu banco",
                "label-text": "Nome e código do Banco",
                value: _vm.student.bank_name,
                "close-on-select": "",
                "show-label": "",
                "multiple-option": false,
                options: _vm.bankNames,
                "error-message": _vm.getErrorMessage(
                  "student.bank_name",
                  _vm.student.bank_name
                ),
                dispatch: _vm.setBankName,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "agency",
                value: _vm.student.agency,
                "input-placeholder": "Número da Agência",
                "show-label": "",
                "label-text": "Agência",
                dispatch: "Student/setAgency",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "account",
                value: _vm.student.account,
                "input-placeholder": "Informe o número da conta sem o dígito",
                "show-label": "",
                "label-text": "Conta",
                dispatch: "Student/setAccount",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "account_digit",
                value: _vm.student.account_digit,
                "input-placeholder": "Informe o dígito da conta",
                "show-label": "",
                "label-text": "Dígito",
                dispatch: "Student/setAccountDigit",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("Multiselect", {
              staticClass: "mr-5",
              attrs: {
                id: "select-account-type",
                "select-placeholder": "Selecione o tipo da conta",
                "label-text": "Tipo da Conta",
                value: _vm.student.account_type,
                "close-on-select": "",
                "show-label": "",
                "multiple-option": false,
                options: _vm.accountTypes,
                "error-message": _vm.getErrorMessage(
                  "student.account_type",
                  _vm.student.account_type
                ),
                dispatch: _vm.setAccountType,
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "grid grid-cols-1 gap-5 mt-10" }, [
        _c(
          "div",
          [
            _c("BaseText", {
              attrs: {
                text: "dados do responsável e familiares",
                "type-text": "title-forms",
              },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "mt-5" },
        [
          _c(
            "BaseText",
            {
              attrs: {
                "type-text": "custom",
                weight: "normal",
                color: "quantum",
                size: "base",
              },
            },
            [
              _vm._v(
                " Informações da MÃE e FILHOS (quando possuir) são OBRIGATÓRIOS. Informações de parentes próximos também devem ser incluídos. "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._l(_vm.relatives, function (relative, index) {
        return _c(
          "div",
          { key: index, staticClass: "grid grid-cols-4 gap-5 mt-10" },
          [
            _c(
              "div",
              [
                _c("Multiselect", {
                  staticClass: "mr-5",
                  attrs: {
                    id: "select-degree-kinship",
                    "select-placeholder": "Selecionar",
                    "label-text": "grau de parentesco",
                    "close-on-select": "",
                    "show-label": "",
                    "multiple-option": false,
                    options: _vm.mountDegreeKinships(index),
                    value: relative.degree_kinship,
                    "error-message": _vm.getErrorMessage(
                      "student.relatives.degree_kinship",
                      _vm.student.relatives_attributes[index].degree_kinship
                    ),
                    required: "",
                    dispatch: _vm.setDegreeKinship,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex items-center" },
              [
                _c("AppRadioButton", {
                  attrs: {
                    id: "responsible-" + index,
                    values: [
                      {
                        name: "responsible",
                        value: index,
                        description: "Marcar como responsável",
                      },
                    ],
                    dispatch: "Student/setResponsible",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex items-center col-span-2" },
              [
                _c("AppRadioButton", {
                  attrs: {
                    id: "reference_person-" + index,
                    values: [
                      {
                        name: "reference_person",
                        value: index,
                        description: "Marcar como pessoa de referência",
                      },
                    ],
                    dispatch: "Student/setReferencePerson",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("BaseInput", {
                  attrs: {
                    active: "",
                    "input-name": "name-relative",
                    value: _vm.student.relatives_attributes[index].name,
                    "error-message": _vm.getErrorMessage(
                      "student.relatives.name",
                      _vm.student.relatives_attributes[index].name
                    ),
                    "show-label": "",
                    required: "",
                    "label-text": "nome completo",
                    dispatch: "Student/setRelativeName",
                    payload: { index },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("BaseInput", {
                  attrs: {
                    active: "",
                    "input-name": "rg-relative",
                    value: _vm.student.relatives_attributes[index].rg,
                    "show-label": "",
                    "input-placeholder": "0000000000000",
                    "label-text": "rg",
                    dispatch: "Student/setRelativeRg",
                    payload: { index },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("BaseInput", {
                  attrs: {
                    active: "",
                    "input-name": "cpf-relative",
                    value: _vm.student.relatives_attributes[index].cpf,
                    "error-message": _vm.getErrorMessage(
                      "student.relatives.cpf",
                      _vm.student.relatives_attributes[index].cpf
                    ),
                    "show-label": "",
                    "input-placeholder": "000.000.000-000",
                    mask: "###.###.###-##",
                    "label-text": "cpf",
                    dispatch: "Student/setRelativeCpf",
                    payload: { index },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("BaseInput", {
                  attrs: {
                    active: "",
                    "input-name": "cellphone_number-relative",
                    value:
                      _vm.student.relatives_attributes[index].cellphone_number,
                    "show-label": "",
                    "input-placeholder": "(85) 90000.0000",
                    mask: "(##) #####.####",
                    "label-text": "telefone",
                    "error-message": _vm.getErrorMessage(
                      "student.relatives.cellphone_number",
                      _vm.student.relatives_attributes[index].cellphone_number
                    ),
                    dispatch: "Student/setRelativeCellphoneNumber",
                    payload: { index },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("BaseInput", {
                  attrs: {
                    active: "",
                    "input-name": "email-relative",
                    value: _vm.student.relatives_attributes[index].email,
                    "show-label": "",
                    "input-placeholder": "digiteseu@email.com",
                    "label-text": "e-mail",
                    "error-message": _vm.getErrorMessage(
                      "student.relatives.email",
                      _vm.student.relatives_attributes[index].email
                    ),
                    dispatch: "Student/setRelativeEmail",
                    payload: { index },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "flex items-end col-span-2" },
              [
                _c("BaseInput", {
                  attrs: {
                    active: "",
                    "input-name": "birth-year-relative",
                    value: _vm.student.relatives_attributes[index].birth_date,
                    "show-label": "",
                    "input-placeholder": "Ano",
                    "label-text": "Data de Nascimento",
                    "input-type": "date",
                    dispatch: "Student/setRelativeBirthDate",
                    payload: { index },
                  },
                }),
                _c("div", { staticStyle: { "margin-left": "345px" } }, [
                  index >= 1
                    ? _c(
                        "a",
                        {
                          staticClass: "text-burnedYellow underline",
                          attrs: { href: "javascript:void(0)" },
                          on: { click: _vm.deleteRelative },
                        },
                        [
                          _c("AppMaterialIcon", {
                            staticClass: "align-bottom",
                            attrs: { "icon-name": "delete", "icon-size": "36" },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
              ],
              1
            ),
            _vm._m(0, true),
          ]
        )
      }),
      _c("div", { staticClass: "mt-5" }, [
        _c(
          "a",
          {
            staticClass: "text-burnedYellow underline",
            attrs: { href: "javascript:void(0)" },
            on: { click: _vm.addRelative },
          },
          [
            _c("AppMaterialIcon", {
              staticClass: "align-middle",
              attrs: { "icon-name": "add_circle", "icon-size": "36" },
            }),
            _c("BaseText", {
              staticClass: "align-middle mr-2",
              attrs: {
                text: "Adicionar familiar",
                "type-text": "custom",
                weight: "medium",
                size: "sm",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-span-4 mt-5" }, [
      _c("hr", {
        staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }