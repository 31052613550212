var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-12 my-6" },
    [
      _c("div", { staticClass: "flex", attrs: { id: "menus_phase_teams" } }, [
        _c("div", [
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.changeTab("teams_in_progress")
                },
              },
            },
            [
              _c("BaseText", {
                attrs: {
                  text: "TURMAS ATIVAS",
                  typeText: "custom",
                  weight: "semibold",
                  mode: "uppercase",
                  size: "sm",
                  color: _vm.setColorTeamsInProgress,
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "border-l mx-10" }),
        _c("div", [
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.changeTab("teams_finished")
                },
              },
            },
            [
              _c("BaseText", {
                attrs: {
                  text: "TURMAS ENCERRADAS",
                  typeText: "custom",
                  weight: "semibold",
                  mode: "uppercase",
                  size: "sm",
                  color: _vm.setColorTeamsFinished,
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c("hr", {
        staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
      }),
      _c("TeamPhase"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }