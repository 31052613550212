import { getAxios, baseURL } from "./Api";

export async function getStudentsPhaseOne(seasonId, phaseId) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let url = `/alunos/disponiveis_para_fase_um`;

  return api.get(url, { params: { season_id: seasonId, phase_id: phaseId } });
}

export async function getStudentsPhaseTwo(seasonId, disciplineId) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let url = `/alunos/disponiveis_para_fase_dois`;

  return api.get(url, { params: { season_id: seasonId, discipline_id: disciplineId } });
}

export async function getStudentsPhaseThree(seasonId, disciplineId) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let url = `/alunos/disponiveis_para_fase_tres`;

  return api.get(url, { params: { season_id: seasonId, discipline_id: disciplineId } });
}

export async function getArea(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let url = `/areas/${id}`;

  return api.get(url);
}

export async function createArea(area) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.post("/areas", { areas: area });
}

export async function destroyArea(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`areas/${id}`);
}

export async function updateArea(id, area) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`areas/${id}`, { areas: area });
}

export async function getAreasByCityId(city_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`cidades/${city_id}/areas`);
}
