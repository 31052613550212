var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-4" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start col-span-3" },
                      [
                        _c("Label", {
                          staticClass: "text-ashes-dark",
                          attrs: {
                            text: "Editar Data para/de Anexo de Comprovante de Matrícula",
                            icon: null,
                            "icon-type": null,
                            type: "text-2xl",
                            weight: "bold",
                            normal: "",
                            mode: "normal-case",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "SchoolReinsertions/closeModalSchoolReinsertionDeadlineAttachProofOfEnrollment",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações de Comprovante de Matrícula e Data",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("AppButtonUploadFile2", {
                          attrs: {
                            id: "file-declaration",
                            id_file: `${_vm.idFileStudentDeclaracao}`,
                            name: "declaração",
                            text: "+  Comprovante de Matrícula",
                            dispatch:
                              "ModalInscription/setDocumentInscriptionDECLARACAO",
                            "type-document": "declaration",
                            "label-text": "Anexar Documento",
                            "show-label": "",
                            color: "ashes-dark",
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "-mt-8" },
                          [
                            _c("AppButtonIcon", {
                              attrs: {
                                iconName: "delete",
                                payload: _vm.inscription.student,
                                dispatch:
                                  "ModalInscription/deleteStudentDocumentDECLARACAO",
                              },
                            }),
                            _c("AppButtonIcon", {
                              attrs: {
                                iconName: "download",
                                payload: _vm.inscription.student,
                                dispatch:
                                  "ModalInscription/showStudentDocumentDECLARACAO",
                              },
                            }),
                            _c("AppButtonIcon", {
                              attrs: {
                                iconName: "event",
                                dispatch:
                                  "SchoolReinsertions/toggleSchoolReinsertionDeadlineAttachProofOfEnrollment",
                                title:
                                  "Editar data para/de anexo de comprovante de matrícula",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.toggleSchoolReinsertionDeadlineAttachProofOfEnrollment
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name":
                                  "deadline_attach_proof_of_enrollment",
                                "input-type": "date",
                                value:
                                  _vm.school_reinsertion
                                    .deadline_attach_proof_of_enrollment,
                                "show-label": "",
                                "label-text":
                                  "Data Para/De Anexo de Comprovante de Matrícula",
                                dispatch:
                                  "SchoolReinsertions/setDeadlineAttachProofOfEnrollment",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "SchoolReinsertions/closeModalSchoolReinsertionDeadlineAttachProofOfEnrollment",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "Salvar",
                            typeButton: "success",
                            dispatch: "SchoolReinsertions/update",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1337887662
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }