var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { attrs: { bg_color: _vm.getbgType } }, [
    _c(
      "div",
      { staticClass: "grid grid-cols-custom items-center container1" },
      [
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.formattedDate(_vm.school_reinsertion.created_at),
                mode: "normal-case",
                color: _vm.getColorText,
                typeText: "custom",
                weight: "bold",
                size: "base",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.formattedDate(
                  _vm.school_reinsertion.reinsertion_date
                ),
                mode: "normal-case",
                color: _vm.getColorText,
                typeText: "custom",
                weight: "semibold",
                size: "base",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.school_reinsertion.city.name,
                mode: "normal-case",
                color: _vm.getColorText,
                typeText: "custom",
                weight: "semibold",
                size: "base",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.school_reinsertion.activity_type.name,
                mode: "normal-case",
                color: _vm.getColorText,
                typeText: "custom",
                weight: "semibold",
                size: "base",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.school_reinsertion.result_type
                  ? _vm.school_reinsertion.result_type.name
                  : "-",
                mode: "normal-case",
                color: _vm.getColorText,
                typeText: "custom",
                weight: "semibold",
                size: "base",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.school_reinsertion.articulator,
                mode: "normal-case",
                color: _vm.getColorText,
                typeText: "custom",
                weight: "semibold",
                size: "base",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 flex" },
          [
            _vm.permitShow
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "visibility",
                    dispatch: "SchoolReinsertions/openViewModal",
                    payload: _vm.school_reinsertion,
                    iconColor: _vm.getColorText,
                  },
                })
              : _vm._e(),
            _vm.permit &&
            (this.school_reinsertion?.result_type?.id === 2 ||
              this.school_reinsertion?.activity_type?.id === 3)
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "event",
                    dispatch:
                      "SchoolReinsertions/openModalSchoolReinsertionDeadlineAttachProofOfEnrollment",
                    payload: _vm.school_reinsertion,
                    iconColor: _vm.getColorText,
                    title: "Editar data para anexo de comprovante de matrícula",
                  },
                })
              : _vm._e(),
            _vm.permit &&
            (this.school_reinsertion?.result_type?.id === 1 ||
              this.school_reinsertion?.activity_type?.id === 2)
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "event",
                    dispatch:
                      "SchoolReinsertions/openModalSchoolReinsertionReturnLetterDeadline",
                    payload: _vm.school_reinsertion,
                    iconColor: _vm.getColorText,
                    title: "Editar data de retorno da carta",
                  },
                })
              : _vm._e(),
            _vm.permitUp && _vm.toggleSchoolReinsertionNotReinserted
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "edit",
                    payload: _vm.school_reinsertion,
                    iconColor: _vm.getColorText,
                    dispatch: "SchoolReinsertions/openEditModalAddActivity",
                    title: "Editar Atendimento",
                  },
                })
              : _vm._e(),
            _vm.permitDes && _vm.toggleSchoolReinsertionNotReinserted
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "delete",
                    payload: _vm.school_reinsertion,
                    dispatch:
                      "SchoolReinsertions/toggleModalDeleteSchoolReinsertion",
                    iconColor: _vm.getColorText,
                    title: "Excluir Atendimento",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }