<template>
  <div class="grid auto-grid-auto p-10" v-if="permitList">
    <div>
      <BaseText
        :text="city.name + ' | Áreas'"
        typeText="custom"
        weight="semibold"
        :italic="false"
        size="3xl"
        color="ashes-hover"
      />
    </div>
    <div class="grid grid-cols-2 justify-items-stretch my-8">
      <div>
        <BaseInput
          active
          inputName=""
          inputType="text"
          iconName="search"
          showIcon
          dispatch="Areas/filterList"
        />
      </div>
      <div class="justify-self-end">
        <AppButtonColorful
          v-if="permit"
          text="Adicionar área"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="Area/createModal"
        />
      </div>
    </div>
    <div class="">
      <div class="grid grid-cols-4">
        <div class="col-span-1 ml-7">
          <BaseText
            text="Área de Abrangência"
            typeText="custom"
            weight="bold"
            :italic="false"
            mode="uppercase"
            size="xs"
            color="ashes-dark"
          />
        </div>
        <div class="justify-items-start ml-4">
          <BaseText
            text="N° de Bairros"
            typeText="custom"
            weight="bold"
            :italic="false"
            mode="uppercase"
            size="xs"
            color="ashes-dark"
          />
        </div>
        <div class="justify-items-start ml-1">
          <BaseText
            text="N° De Equipamentos"
            typeText="custom"
            weight="bold"
            :italic="false"
            mode="uppercase"
            size="xs"
            color="ashes-dark"
          />
        </div>
      </div>
      <div><AreaCards :data="areas" /></div>
    </div>
    <ModalArea :open="openModal" />
    <DeleteAreaConfirmation :open="openModalDelete" />
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import AreaCards from "./list/AreaCards.vue";
import DeleteAreaConfirmation from "./alerts/DeleteAreaConfirmation.vue";
import ModalArea from "./ModalArea.vue";
import BaseText from "../components/BaseText.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import ProhibitedAccessPage from "../components/ProhibitedAccessPage.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AreaCards,
    BaseText,
    AppButtonColorful,
    BaseInput,
    ModalArea,
    DeleteAreaConfirmation,
    ProhibitedAccessPage,
  },
  data() {
    return {
      permitList: false,
      permit: false,
    };
  },
  created() {
    this.$store.dispatch("Areas/loadAreas");
    this.permitChangeArea();
    this.permitListArea();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    permitChangeArea() {
      this.canAcces("area_create").then((resp) => {
        this.permit = resp;
      });
    },
    permitListArea() {
      this.canAcces("area_index").then((resp) => {
        this.permitList = resp;
      });
    },
  },
  computed: {
    ...mapState({
      areas: (state) => state.Areas.filterAreas,
      city: (state) => state.City.city,
      openModal: (state) => state.Area.toggleModal,
      openModalDelete: (state) => state.Areas.modalDeleteAreaConfirmationOpen,
      profiles: (state) => state.AccessControl.profiles,
    }),
  },
};
</script>
