import Vue from "vue";
import { isEmpty } from "lodash";
import { getEquipmentBySeasonId } from "../../service/EquipmentApi";
import { createTeam, updateTeam, showTeam, getShifts, finish, reactivate } from "../../service/TeamApi";
import { getTeachersByCityId } from "../../service/TeacherApi";
import router from "../../router/index";

const team = {
  id: null,
  code: null,
  start: null,
  closing: null,
  monday: null,
  tuesday: null,
  wednesday: null,
  thursday: null,
  friday: null,
  shift: "",
  vacancy: null,
  discipline: {},
  equipment: {},
  teacher: {},
  course: {},
  in_progress: null,
};

const state = {
  ...team,
  season: null,

  modalTeamOpen: false,
  equipmentAll: [],
  shifts: [],
  teachers: [],
  errorMessages: [],
  page: "meeting",
};

const mutations = {
  setIdMutation: (state, id) => {
    state.id = id;
  },
  setCodeMutation: (state, code) => {
    state.code = code;
  },
  setCourseMutation: (state, course) => {
    state.course = course;
  },
  setStartMutation: (state, start) => {
    state.start = start;
  },
  setClosingMutation: (state, closing) => {
    state.closing = closing;
  },
  setMondayMutation: (state, monday) => {
    state.monday = monday;
  },
  setTuesdayMutation: (state, tuesday) => {
    state.tuesday = tuesday;
  },
  setWednesdayMutation: (state, wednesday) => {
    state.wednesday = wednesday;
  },
  setThursdayMutation: (state, thursday) => {
    state.thursday = thursday;
  },
  setFridayMutation: (state, friday) => {
    state.friday = friday;
  },
  setShiftMutation: (state, shift) => {
    state.shift = shift;
  },
  setVacancyMutation: (state, vacancy) => {
    state.vacancy = vacancy;
  },
  setSeasonMutation: (state, season) => {
    state.season = season;
  },
  toggleModalMutation: (state) => {
    state.modalTeamOpen = !state.modalTeamOpen;
  },
  setDisciplineMutation: (state, discipline) => {
    state.discipline = discipline;
  },
  setEquipmentMutation: (state, equipment) => {
    state.equipment = equipment;
  },
  setTeacherMutation: (state, teacher) => {
    state.teacher = teacher;
  },
  setInProgressMutation: (state, inProgress) => {
    state.in_progress = inProgress;
  },
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
  loadEquipmentMutation: (state, equipment) => {
    state.equipmentAll = equipment;
  },
  loadShiftsMutation: (state, shifts) => {
    state.shifts = shifts;
  },
  loadTeachersMutation: (state, teachers) => {
    state.teachers = teachers;
  },
  changePageMutation: (state, page) => {
    state.page = page;
  },
};

const actions = {
  changePage: ({ commit }, payload) => {
    commit("changePageMutation", payload);
  },
  setStart: ({ commit }, payload) => {
    commit("setStartMutation", payload.value);
  },
  setClosing: ({ commit }, payload) => {
    commit("setClosingMutation", payload.value);
  },
  setDiscipline: ({ commit, dispatch }, payload) => {
    commit("setDisciplineMutation", payload);
    dispatch("Courses/filterCourses", payload, { root: true });
  },
  setCourse: ({ commit }, payload) => {
    commit("setCourseMutation", payload);
  },
  setDayWeek: ({ commit, state }, payload) => {
    switch (payload.value) {
      case "monday":
        commit("setMondayMutation", !state.monday);
        break;
      case "tuesday":
        commit("setTuesdayMutation", !state.tuesday);
        break;
      case "wednesday":
        commit("setWednesdayMutation", !state.wednesday);
        break;
      case "thursday":
        commit("setThursdayMutation", !state.thursday);
        break;
      case "friday":
        commit("setFridayMutation", !state.friday);
        break;
    }
  },
  setShift: ({ commit }, payload) => {
    commit("setShiftMutation", payload);
  },
  setVacancy: ({ commit }, payload) => {
    commit("setVacancyMutation", payload.value);
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },
  setEquipment: ({ commit }, payload) => {
    commit("setEquipmentMutation", payload);
  },
  setTeacher: ({ commit }, payload) => {
    commit("setTeacherMutation", payload);
  },
  setInProgress: ({ commit }, payload) => {
    commit("setInProgressMutation", payload);
  },
  showTeam: ({ dispatch }, id) => {
    showTeam(id)
      .then((response) => {
        dispatch("teamWithEquipment", response.data.team);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadEquipment: ({ commit }, season_id) => {
    getEquipmentBySeasonId(season_id)
      .then((response) => {
        commit("loadEquipmentMutation", response.data.equipment);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadShifts: ({ commit }) => {
    getShifts()
      .then((response) => {
        commit("loadShiftsMutation", response.data.shifts);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadTeachers: ({ commit, rootState }) => {
    getTeachersByCityId(rootState.City.city.id)
      .then((response) => {
        commit("loadTeachersMutation", response.data.teachers);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  teamWithEquipment: ({ dispatch }, payload) => {
    dispatch("load", payload);
    dispatch("loadEquipment", payload.season.id);
  },
  load: ({ commit, dispatch }, payload) => {
    if (payload && !isEmpty(payload)) {
      commit("setIdMutation", payload.id);
      commit("setCodeMutation", payload.code);
      commit("setStartMutation", payload.start);
      commit("setClosingMutation", payload.closing);
      commit("setMondayMutation", payload.monday);
      commit("setTuesdayMutation", payload.tuesday);
      commit("setWednesdayMutation", payload.wednesday);
      commit("setThursdayMutation", payload.thursday);
      commit("setFridayMutation", payload.friday);
      commit("setShiftMutation", payload.shift);
      commit("setVacancyMutation", payload.vacancy);
      commit("setDisciplineMutation", payload.discipline);
      commit("setEquipmentMutation", payload.equipment);
      commit("setTeacherMutation", payload.teacher);
      commit("setInProgressMutation", payload.in_progress);
      commit("setSeasonMutation", payload.season);
      commit("setCourseMutation", payload.course);
      dispatch("Allocation/setStudents", payload.students, { root: true });
      dispatch("Season/loadSeason", payload.season, { root: true });
    } else {
      commit("setIdMutation", null);
      commit("setCodeMutation", null);
      commit("setStartMutation", null);
      commit("setClosingMutation", null);
      commit("setMondayMutation", null);
      commit("setTuesdayMutation", null);
      commit("setWednesdayMutation", null);
      commit("setThursdayMutation", null);
      commit("setFridayMutation", null);
      commit("setShiftMutation", null);
      commit("setVacancyMutation", null);
      commit("setDisciplineMutation", {});
      commit("setEquipmentMutation", {});
      commit("setTeacherMutation", {});
      commit("setInProgressMutation", null);
      commit("setSeasonMutation", null);
      commit("setCourseMutation", null);
      dispatch("Allocation/setStudents", [], { root: true });
    }
  },
  openModal: ({ commit, dispatch }) => {
    dispatch("load");
    commit("toggleModalMutation");
  },
  openEditModal: ({ commit, dispatch }, payload) => {
    dispatch("load", payload);
    commit("toggleModalMutation");
  },
  closeModal: ({ commit, dispatch }) => {
    commit("setErrorMessagesMutation", []);
    commit("toggleModalMutation");
    dispatch("load");
  },
  saveTeam: ({ commit, dispatch, getters }) => {
    const team = getters.getTeamForApi;
    createTeam(team)
      .then((response) => {
        Vue.$toast.success(response.data.message);
        dispatch("Teams/addTeam", response.data.team, { root: true });
        dispatch("closeModal");
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel cadastrar a turma! " +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );

        console.error(e.response.data.errors);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  updateTeam: ({ commit, dispatch, getters }) => {
    const team = getters.getTeamForApi;

    updateTeam(team.id, team)
      .then((response) => {
        dispatch("Teams/updateTeam", response.data.team, { root: true });

        Vue.$toast.success(response.data.message);
        dispatch("closeModal");
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel cadastrar a turma! " +
            "Por favor verifique se todos os campos com ( * ) foram preenchidos"
        );

        console.log(e.response.data.errors);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  finishTeam: ({ commit, state, dispatch }) => {
    finish(state.id)
      .then(() => {
        Vue.$toast.success("Turma encerrada com sucesso");
        dispatch("Teams/removeTeam", { id: state.id }, { root: true });
        dispatch("Teams/loadTeams", null, { root: true });
        router.push({ name: "season", params: { id: state.season.id } });
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel finalizar a turma! " +
            "Por favor verifique se a turma está de acordo para ser encerrada."
        );

        console.log(e.response.data.errors);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  reactivateTeam: ({ commit, state, dispatch }) => {
    reactivate(state.id)
      .then(() => {
        Vue.$toast.success("Turma reativada com sucesso");
        dispatch("Teams/loadTeams", null, { root: true });
        router.push({ name: "season", params: { id: state.season.id } });
      })
      .catch((e) => {
        Vue.$toast.error(
          "Não foi possivel reativar a turma! " +
            "Por favor verifique se a turma está de acordo para ser reativada."
        );

        console.log(e.response.data.errors);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
};

const getters = {
  getTeamForApi: (state, getters, rootState) => {
    let team = {
      ...rootState.Team,
      shift: rootState.Team.shift ? rootState.Team.shift.id : null,
      discipline_id: rootState.Team.discipline ? rootState.Team.discipline.id : null,
      equipment_id: rootState.Team.equipment ? rootState.Team.equipment.id : null,
      teacher_id: rootState.Team.teacher ? rootState.Team.teacher.id : null,
      season_id: rootState.Season.season ? rootState.Season.season.id : null,
      course_id: state.course?.id ? state.course.id : null,
    };

    delete team.discipline;
    delete team.equipment;
    delete team.teacher;
    delete team.code;
    delete team.equipmentAll;
    delete team.teachers;
    delete team.shifts;
    delete team.modalTeamOpen;
    delete team.errorMessages;

    return team;
  },
  getErrorMessage: (state) => (key) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      switch (error.type) {
        case "blank":
          return error.message;

        case "wrong_length":
          return error.message;

        case "invalid_cpf":
          return error.message;

        case "invalid_cep":
          return error.message;

        case "invalid":
          return error.message;

        case "taken":
          return error.message;

        default:
          return null;
      }
    } else {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
