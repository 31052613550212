var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid auto-grid-auto p-10" }, [
    _c(
      "div",
      {
        staticClass: "grid grid-cols-3 justify-between items-center mx-6 my-6",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          { staticClass: "text-ashes-hover font-medium text-2xl col-span-2" },
          [
            _c("Label", {
              class: _vm.title,
              attrs: {
                text: "Usuários",
                icon: null,
                iconType: null,
                type: "text-3xl",
                weight: "semibold",
                normal: "",
                mode: "normal-case",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mx-2 items-center justify-self-end" },
          [
            _vm.permit_create
              ? _c("AppButtonColorful", {
                  attrs: {
                    text: "Adicionar usuário(a)",
                    typeButton: "primary",
                    showIcon: "",
                    iconName: "add",
                    iconType: "outlined",
                    dispatch: "User/openModal",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "mx-6 mb-2", attrs: { id: "tabs-button" } },
      [
        _c("FilterComponent", [
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "name",
                  "input-type": "text",
                  "icon-name": "search",
                  showIcon: "",
                  "show-label": "",
                  value: _vm.name,
                  "label-text": "Filtrar por Nome do Usuário(a)",
                  "input-placeholder": "Digite o Nome do Usuário(a)",
                  dispatch: "Users/setFilterName",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "email",
                  "input-type": "text",
                  "icon-name": "search",
                  showIcon: "",
                  "show-label": "",
                  value: _vm.email,
                  "label-text": "Filtrar por Email do Usuário(a)",
                  "input-placeholder": "Digite o Email do Usuário(a)",
                  dispatch: "Users/setFilterEmail",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "cpf",
                  "input-type": "text",
                  "icon-name": "search",
                  showIcon: "",
                  "show-label": "",
                  value: _vm.cpf,
                  "label-text": "Filtrar por CPF do Usuário(a)",
                  "input-placeholder": "Digite o CPF do Usuário(a)",
                  dispatch: "Users/setFilterCpf",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Multiselect", {
                staticClass: "mr-5",
                attrs: {
                  id: "filterCity",
                  selectPlaceholder: "Selecionar Município",
                  "label-text": "Filtrar por Município",
                  closeOnSelect: "",
                  "show-label": "",
                  multipleOption: false,
                  options: _vm.cities,
                  value: _vm.city,
                  dispatch: _vm.setFilterCity,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Multiselect", {
                staticClass: "mr-5",
                attrs: {
                  id: "filterCity",
                  selectPlaceholder: "Selecionar Área",
                  "label-text": "Filtrar por Área",
                  closeOnSelect: "",
                  "show-label": "",
                  multipleOption: false,
                  options: _vm.user_areas,
                  value: _vm.area,
                  dispatch: _vm.setFilterArea,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Multiselect", {
                staticClass: "mr-5",
                attrs: {
                  id: "filterState",
                  selectPlaceholder: "Selecionar Status",
                  "label-text": "Filtrar por Status",
                  closeOnSelect: "",
                  "show-label": "",
                  multipleOption: false,
                  options: _vm.statuses,
                  value: _vm.status,
                  dispatch: _vm.setFilterStatus,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Multiselect", {
                staticClass: "mr-5",
                attrs: {
                  id: "filterState",
                  selectPlaceholder: "Selecionar Perfil",
                  "label-text": "Filtrar por Perfil",
                  label: "description",
                  closeOnSelect: "",
                  "show-label": "",
                  multipleOption: false,
                  options: _vm.abilityProfilesForMenus,
                  value: _vm.ability_profile,
                  dispatch: _vm.setFilterAbilityProfile,
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "mt-3" }, [
          _vm.users.length > 0 && _vm.permit_export_spreadsheet
            ? _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.exportToExcel("users_spreadsheet")
                    },
                  },
                },
                [
                  _c("img", {
                    staticClass: "inline-block",
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      src: require("@/assets/images/logout_black_24dp.svg"),
                      alt: "",
                    },
                  }),
                  _c("BaseText", {
                    staticStyle: { cursor: "pointer" },
                    attrs: {
                      text: "Exportar Planilha",
                      typeText: "custom",
                      italic: false,
                      size: "xs",
                      color: "burnedYellow",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "grid grid-cols-2 items-center mx-6 my-6",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          { staticClass: "mx-2" },
          [
            _vm.permit_list
              ? _c("AppButtonColorful", {
                  attrs: {
                    id: "btn-consult-areas",
                    showIcon: "",
                    iconName: "search",
                    text: "Consultar dados",
                    dispatch: "Users/loadUsers",
                    payload: "load",
                    typeButton: "primary",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm.users.length > 0
          ? _c("div", { staticClass: "mx-2 items-center justify-self-end" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.clearQuery()
                    },
                  },
                },
                [
                  _c("AppButtonColorful", {
                    attrs: {
                      id: "btn-consult-users",
                      showIcon: "",
                      iconName: "remove",
                      text: "Limpar consulta",
                      typeButton: "primary",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      { attrs: { id: "list-users" } },
      [
        _c(
          "div",
          { staticClass: "mx-6" },
          [_c("UsersCards", { attrs: { data: _vm.users } })],
          1
        ),
        _vm.users.length > 0
          ? _c(
              "div",
              {
                staticClass: "mx-12 my-6 pb-3",
                attrs: { id: "users-pagination" },
              },
              [
                _c("BasePagination", {
                  attrs: {
                    total: _vm.totalUsers,
                    "total-per-page": _vm.totalPerPage,
                    dispatch: "Users/setUsersOffset",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("ModalUser", { attrs: { open: _vm.toggleModal } }),
        _c("ModalConfirmDeleteUser", {
          attrs: { open: _vm.modalConfirmDeleteUser },
        }),
        _c("ModalUserActivation", { attrs: { open: _vm.modalActivation } }),
        _c("ModalExportSpreadsheets", {
          attrs: {
            dataSpreadsheet: _vm.dataSpreadsheet,
            modalToggleExport: _vm.modalToggleExport,
            titleModal: _vm.titleModal,
            dispatchToCloseModal: _vm.dispatchToCloseModal,
            json_data: _vm.json_data,
            sheetTabName: _vm.sheetTabName,
            fileName: _vm.fileName,
            variablePreloading: _vm.variablePreloading,
            json_fields: _vm.json_fields,
            dispatchOpenModal: _vm.dispatchOpenModal,
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }