import {
  getAtendents,
  createAtendent,
  createAtendentEquip,
  destroyAtendentEquip,
  getAtendent,
  destroyAtendent,
  updateAtendent,
  resetPassword,
} from "../../service/AtendentPsychosocialApi";
import { createUser, createUserArea, destroyUserArea } from "../../service/UserApi";
import { getEquipmentByAreaId } from "../../service/EquipmentApi";
import { profiles } from "../../service/AccessControlApi";
import Vue from "vue";
import { isEmpty } from "lodash";
import { getAreasByCity } from "../../service/AreaApi";

const state = {
  atendents: [],
  atendent: {
    id: null,
    user_id: null,
    name: "",
    cpf: "",
    cellphone_number: "",
    email: "",
    number_cress_crp: "",
    areas_attributes: [],
    equipments: [],
  },
  equipmentsData: null,
  atendent_equipments_toEdit: [],
  areasData: [],
  user_areas_toEdit: [],
  errorMessages: [],
  filterAtendents: [],
  modalDeleteAtendentConfirmationOpen: false,
  modalAtendentOpen: false,
  atendentIdForDeletion: null,
  password: null,
  password_confirmation: null,
  modalResetPassword: null,
  profiles: [],
};

const mutations = {
  changeValueMutation: async (state) => {
    const eqpAreas = state.equipmentsData;
    const idEqpAreas = [];

    eqpAreas.forEach((eqp) => {
      idEqpAreas.push(eqp.id);
    });
  },
  setIdMutation: (state, id) => {
    state.atendent.id = id;
  },
  setUserIdMutation: (state, id) => {
    state.atendent.user_id = id;
  },
  setNameMutation: (state, name) => {
    state.atendent.name = name;
  },
  setCpfMutation: (state, cpf) => {
    state.atendent.cpf = cpf;
  },
  setCellphoneNumberMutation: (state, cellphoneNumber) => {
    state.atendent.cellphone_number = cellphoneNumber;
  },
  setEmailMutation: (state, email) => {
    state.atendent.email = email;
  },
  setCressCrpMutation: (state, number) => {
    state.atendent.number_cress_crp = number;
  },
  setPasswordMutation: (state, password) => {
    state.password = password;
  },
  setPasswordConfirmationMutation: (state, password_confirmation) => {
    state.password_confirmation = password_confirmation;
  },
  setAtendents: (state, atendents) => {
    state.atendents = atendents;
    state.filterAtendents = atendents;
  },
  setAtendentMutation: (state, atendent) => {
    state.atendent = Object.assign(state.atendent, atendent);
    state.atendent.areas_attributes = atendent.area;
    state.atendent.equipments = atendent.equipments;
  },
  setAreasByCityMutation: (state, areas) => {
    state.areasData = areas;
  },
  setEquipmentsByIdsMutation: (state, equipments) => {
    state.equipmentsData = equipments;

    const eqpAreas = state.equipmentsData;
    const idEqpAreas = [];

    eqpAreas.forEach((eqp) => {
      idEqpAreas.push(eqp.id);
    });
    const selecionados = [];
    state.atendent.equipments.forEach((selecionado) => {
      selecionados.push(selecionado);
    });
    selecionados.forEach((selecionado) => {
      let pos = state.atendent.equipments.indexOf(selecionado);
      if (!idEqpAreas.includes(selecionado.id)) {
        state.atendent.equipments.splice(pos, 1);
      }
    });
  },
  setProfilesMutation: (state, profiles) => {
    state.profiles = profiles;
  },
  setUserAreasMutation: (state, areas) => {
    state.areasData = areas;
  },
  filterList: (state, input) => {
    let atendents = state.atendents;
    state.filterAtendents = atendents.filter((obj) => {
      let name = obj.name.toLowerCase().indexOf(input.value.toLowerCase());
      let cpf = obj.cpf.toLowerCase().indexOf(input.value.toLowerCase());

      return name > -1 || cpf > -1;
    });
  },
  toggleModalDeleteAtendentConfirmation: (state) => {
    state.modalDeleteAtendentConfirmationOpen = !state.modalDeleteAtendentConfirmationOpen;
  },

  toggleModalAtendent: (state) => {
    state.modalAtendentOpen = !state.modalAtendentOpen;
  },
  addAtendent: (state, atendent) => {
    state.atendents.push(atendent);
  },
  updateAtendent: (state, atendent) => {
    state.atendents = state.atendents.map((item) => {
      return item.id === atendent.id ? atendent : item;
    });
  },
  atendentForDeletion: (state, document_id) => {
    state.atendentIdForDeletion = document_id;
  },
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
  setArea: (state, areas) => {
    state.atendent.areas_attributes = areas;
  },
  setEquip: (state, equip) => {
    state.atendent.equipments = equip;
  },
  setEquipment: (state, equipment) => {
    state.atendent.equipments = equipment;
  },
  setEquipmentToEdit: (state, item) => {
    state.atendent_equipments_toEdit.push(item);
  },
  clearEquipToEdit: (state) => {
    state.atendent_equipments_toEdit = [];
  },
  setUserAreasToEdit: (state, item) => {
    state.user_areas_toEdit.push(item);
  },
  clearAreaToEdit: (state) => {
    state.user_areas_toEdit = [];
  },
  clearEquipmentToEdit: (state) => {
    state.user_equipments_toEdit = [];
  },
  toggleModalResetPasswordMutation: (state) => {
    state.modalResetPassword = !state.modalResetPassword;
  },
};

const actions = {
  changeValue: async ({ commit }, payload) => {
    commit("changeValueMutation", payload);
  },
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setUserId: ({ commit }, payload) => {
    commit("setUserIdMutation", payload);
  },
  setCpf: ({ commit }, payload) => {
    commit("setCpfMutation", payload.value);
  },
  setCellphoneNumber: ({ commit }, payload) => {
    commit("setCellphoneNumberMutation", payload.value);
  },
  setEmail: ({ commit }, payload) => {
    commit("setEmailMutation", payload.value);
  },
  setCressCrp: ({ commit }, payload) => {
    commit("setCressCrpMutation", payload.value);
  },
  setPassword: ({ commit }, payload) => {
    commit("setPasswordMutation", payload.value);
  },
  setPasswordConfirmation: ({ commit }, payload) => {
    commit("setPasswordConfirmationMutation", payload.value);
  },
  clearErrorMessages: ({ commit }) => {
    commit("setErrorMessagesMutation", []);
  },
  setAtendents: ({ commit }, atendents) => {
    commit("setAtendents", atendents);
  },
  filterList: ({ commit }, payload) => {
    commit("filterList", payload);
  },
  clearModalResetPassword: ({ commit }) => {
    commit("setPasswordMutation", null);
    commit("setPasswordConfirmationMutation", null);
    commit("toggleModalResetPasswordMutation");
  },
  openModalResetPassword: ({ dispatch, commit }, payload) => {
    dispatch("load", payload);
    commit("toggleModalResetPasswordMutation");
  },
  loadProfiles: ({ commit }) => {
    profiles()
      .then((response) => {
        commit("setProfilesMutation", response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  saveAtendent: ({ commit, dispatch, getters }) => {
    let atendent = state.atendent;
    let city_atendent = getters.getAtendentForApi;
    let ability_profileID;
    state.profiles.forEach((item) => {
      if (item.description == "Psicossocial") {
        ability_profileID = item.id;
      }
    });
    const objUser = {
      id: atendent.id,
      user_id: atendent.user_id,
      name: atendent.name,
      cpf: atendent.cpf,
      active: atendent.active,
      cellphone_number: atendent.cellphone_number,
      city_id: city_atendent.city_id,
      email: atendent.email,
      confirmed_at: new Date(),
      modalResetPassword: atendent.modalResetPassword,
      password: state.password,
      password_confirmation: state.password_confirmation,
      phone_number: atendent.phone_number,
      errorMessages: atendent.errorMessages,
      number_cress_crp: atendent.number_cress_crp,
      ability_profile_id: ability_profileID,
    };
    createUser(objUser)
      .then((response) => {
        dispatch("setUserId", response.data.data.id);
        atendent = state.atendent;
        city_atendent = getters.getAtendentForApi;
        const objAtendent = {
          user_id: atendent.user_id,
          name: atendent.name,
          cpf: atendent.cpf,
          cellphone_number: atendent.cellphone_number,
          city_id: city_atendent.city_id,
          number_cress_crp: atendent.number_cress_crp,
          email: atendent.email,
        };
        createAtendent(objAtendent)
          .then((response) => {
            state.atendent.areas_attributes.forEach((area) => {
              const areaObj = {
                user_id: objAtendent.user_id,
                area_id: area.id,
              };
              createUserArea(areaObj).then(() => {});
            });
            state.atendent.equipments.forEach((equip) => {
              const equipObj = {
                psychosocial_attendant_id: response.data.data.id,
                equipment_id: equip.id,
              };
              createAtendentEquip(equipObj).then(() => {});
            });
            Vue.$toast.success("Atendente criado com sucesso.");
            dispatch("Atendents/loadAtendents", null, { root: true });
            dispatch("Atendents/addAtendent", response.data.atendent, { root: true });
            dispatch("Atendents/closeModalAtendent", null, { root: true });
          })
          .catch((e) => {
            Vue.$toast.error(
              "Não foi possivel cadastrar o instrutor! " + e.response.data.errors.full_messages + "!"
            );

            console.log(e);
            commit("setErrorMessagesMutation", e.response.data.errors);
          });
      })
      .catch((e) => {
        console.log(e);

        Vue.$toast.error(
          "Não foi possivel cadastrar o usuário! " + e.response.data.errors.full_messages + "!"
        );

        commit("setErrorMessagesMutation", e.response.errors);
      });
  },
  loadAtendents: ({ commit }) => {
    const params = {
      city_id: localStorage.city_id,
    };
    getAtendents(params)
      .then((response) => {
        commit("setAtendents", response.data.psychosocial_attendants);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
        console.log("error", e);
      });
  },
  addAtendent: ({ commit }, payload) => {
    commit("addAtendent", payload);
  },
  updateAtendent: ({ dispatch, commit }) => {
    let payload = state.atendent;
    updateAtendent(payload.id, payload)
      .then(() => {
        let user_areas = [];
        state.user_areas_toEdit.forEach((item) => {
          let array = {
            id: item.id,
            user_id: item.user_id,
            area_id: item.area_id,
          };
          user_areas.push(array);
        });
        let areas_data = [];
        state.atendent.areas_attributes.forEach((item) => {
          let array = {
            area_id: item.id,
            user_id: payload.user.id,
          };
          areas_data.push(array);
        });
        const objCreate = areas_data.filter(
          (edit) => !user_areas.find((item) => item.area_id === edit.area_id)
        );

        const objDestroy = user_areas.filter(
          (edit) => !areas_data.find((item) => item.area_id === edit.area_id)
        );

        if (objCreate.length > 0) {
          let objToSend = [];
          objCreate.forEach((item) => {
            let array = {
              user_id: item.user_id,
              area_id: item.area_id,
            };
            objToSend.push(array);
          });
          objToSend.forEach((item) => {
            createUserArea(item)
              .then(() => {})
              .catch((e) => {
                console.log(e);
              })
              .catch((e) => {
                Vue.$toast.error(e.response.data.error + "!");
              });
          });
        }
        if (objDestroy.length > 0) {
          objDestroy.forEach((item) => {
            destroyUserArea(item.id)
              .then(() => {})
              .catch((e) => {
                console.log(e);
              })
              .catch((e) => {
                Vue.$toast.error(e.response.data.error + "!");
              });
          });
        }
        user_areas = [];
        areas_data = [];

        let atendent_equipments = [];
        state.atendent_equipments_toEdit.forEach((item) => {
          let array = {
            id: item.id,
            atendent_id: item.psychosocial_attendant_id,
            equipment_id: item.equipment_id,
          };
          atendent_equipments.push(array);
        });

        let equipments_data = [];

        state.atendent.equipments.forEach((item) => {
          let array = {
            equipment_id: item.id,
            psychosocial_attendant_id: payload.id,
          };
          equipments_data.push(array);
        });

        const objEquipCreate = equipments_data.filter(
          (edit) => !atendent_equipments.find((item) => item.id === edit.id)
        );

        const objEquipDestroy = atendent_equipments.filter(
          (edit) => !equipments_data.find((item) => item.id === edit.id)
        );

        if (objEquipCreate.length > 0) {
          objEquipCreate.forEach((item) => {
            createAtendentEquip(item)
              .then(() => {})
              .catch((e) => {
                console.log(e);
              })
              .catch((e) => {
                Vue.$toast.error(e.response.data.error + "!");
              });
          });
        }
        if (objEquipDestroy.length > 0) {
          objEquipDestroy.forEach((item) => {
            destroyAtendentEquip(item.id)
              .then(() => {})
              .catch((e) => {
                console.log(e);
              })
              .catch((e) => {
                Vue.$toast.error(e.response.data.error + "!");
              });
          });
        }
        atendent_equipments = [];
        equipments_data = [];
        dispatch("Atendents/loadAtendents");
        Vue.$toast.success("Atualizado com sucesso");
        dispatch("Atendents/clearAtendent", null, { root: true });
        commit("toggleModalAtendent");
      })
      .catch();
  },
  loadAtendent: ({ commit, dispatch }, payload) => {
    getAtendent(payload.id).then((response) => {
      commit("setAtendentMutation", response.data.psychosocial_attendant);
      dispatch("load", response.data.psychosocial_attendant);
    });
  },
  deleteAtendent: ({ rootState, dispatch }) => {
    const atendent_id = rootState.Atendents.atendentIdForDeletion;

    destroyAtendent(atendent_id)
      .then((response) => {
        const atendents = rootState.Atendents.atendents.filter((e) => {
          return e.id !== atendent_id;
        });
        dispatch("Atendents/setAtendents", atendents, { root: true });
        Vue.$toast.success(response.data.message);
      })
      .catch((e) => {
        console.log(e.response.data.errors);
        Vue.$toast.error(e.response.data.error);
      });
    dispatch("Atendents/openCloseDeleteAtendentConfirmation", null, { root: true });
  },
  loadAreas: ({ commit }) => {
    const city_id = localStorage.city_id;
    getAreasByCity(city_id).then((resp) => {
      commit("setAreasByCityMutation", resp.data.areas);
    });
  },
  loadEquipments: ({ commit }, areas) => {
    const ids = [];
    areas.forEach((ele) => {
      ids.push(ele.id);
    });
    const params = {
      city_id: localStorage.city_id,
      area_id: ids,
    };

    getEquipmentByAreaId(params).then((resp) => {
      commit("setEquipmentsByIdsMutation", resp.data.equipment);
    });
  },
  load: ({ commit }, payload) => {
    if (payload && !isEmpty(payload)) {
      commit("setIdMutation", payload.id);
      commit("setUserIdMutation", payload.user.id);
      commit("setNameMutation", payload.name);
      commit("setCpfMutation", payload.cpf);
      commit("setCellphoneNumberMutation", payload.cellphone_number);
      commit("setEmailMutation", payload.email);
      if (payload.user_areas && payload.user_areas.length > 0) {
        payload.user_areas.forEach((item) => {
          commit("setUserAreasToEdit", item);
        });
      }
      if (payload.attendant_equipments && payload.attendant_equipments.length > 0) {
        payload.attendant_equipments.forEach((item) => {
          commit("setEquipmentToEdit", item);
        });
      }
    } else {
      commit("setEquip", null);
      commit("setCressCrpMutation", null);
      commit("setIdMutation", null);
      commit("setUserIdMutation", null);
      commit("setNameMutation", null);
      commit("setCpfMutation", null);
      commit("setCellphoneNumberMutation", null);
      commit("setEmailMutation", null);
      commit("setPasswordMutation", null);
      commit("setPasswordConfirmationMutation", null);
      commit("setArea", null);
      commit("clearAreaToEdit");
      commit("clearEquipToEdit");
    }
  },
  resetPassword: ({ commit, dispatch }) => {
    if (state.password == null || state.password_confirmation == null) {
      Vue.$toast.error("Campos de senha e confirmação de senha devem ser preenchidos!");
    } else if (state.password != state.password_confirmation) {
      Vue.$toast.error("Campos de senha e confirmação de senha foram preenchidos com valores diferentes!");
    } else {
      resetPassword(state.atendent.user_id, state.password, state.password_confirmation)
        .then(() => {
          Vue.$toast.success("Senha atualizada com sucesso!");
          dispatch("clearModalResetPassword");
          dispatch("Users/loadUsers", null, { root: true });
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possível atualizar a senha!");
          console.log(e);
          commit("setErrorMessagesMutation", e.response.data.errors);
        });
    }
  },
  clearAtendent: ({ dispatch }) => {
    dispatch("load", null);
  },
  openModalAtendent: ({ commit, dispatch }) => {
    dispatch("Atendents/clearAtendent", null, { root: true });
    dispatch("Atendents/loadProfiles", null, { root: true });
    dispatch("Atendents/loadAreas", null, { root: true });
    commit("toggleModalAtendent");
  },
  closeModalAtendent: ({ commit, dispatch }) => {
    commit("toggleModalAtendent");
    dispatch("Atendents/clearErrorMessages", null, { root: true });
    dispatch("Atendents/clearAtendent", null, { root: true });
    commit("setUserAreasMutation", []);
  },
  closeModalResetPassword: ({ commit, dispatch }) => {
    dispatch("Atendents/clearErrorMessages", null, { root: true });
    dispatch("Atendents/clearAtendent", null, { root: true });
    commit("toggleModalResetPasswordMutation");
  },
  openCloseDeleteAtendentConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteAtendentConfirmation");
    if (state.modalDeleteAtendentConfirmationOpen) {
      commit("atendentForDeletion", payload.id);
    } else {
      commit("atendentForDeletion", null);
    }
  },
  openEditModalAtendent: ({ commit, dispatch }, atendent) => {
    dispatch("Atendents/loadAtendent", atendent, { root: true });
    commit("toggleModalAtendent");
  },
  setArea: ({ commit }, areas) => {
    commit("setArea", areas);
  },
  setEquip: ({ commit }, equip) => {
    commit("setEquip", equip);
  },
};

const getters = {
  getAtendentForApi: (state, getters, rootState) => {
    let atendent = {
      ...rootState.Atendent,
      city_id: rootState.City.city.id,
    };

    return atendent;
  },
  getErrorMessage: (state) => (key, field) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      if (error.type === "blank") {
        if (field === "" || field === " " || field === undefined || field === null) {
          return error.message;
        } else {
          return null;
        }
      } else {
        return error.message;
      }
    } else {
      return null;
    }
  },
  getAreas: () => (areas) => {
    let areasData = areas.map((area) => {
      return { id: area.id, name: area.name };
    });

    return areasData;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
