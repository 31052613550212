var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "font-system" },
    [
      _vm._m(0),
      _c(
        "div",
        {
          staticClass: "flex justify-between mx-12 my-6",
          attrs: { id: "tabs-button" },
        },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "w-104" },
              [
                _c("BaseInput", {
                  attrs: {
                    active: "",
                    inputName: "",
                    inputType: "text",
                    iconName: "search",
                    showIcon: "",
                    "input-placeholder": "Pesquisar por nome",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "float-right" },
            [
              _c("AppButtonColorful", {
                attrs: {
                  id: "btn-add-profile",
                  showIcon: "",
                  iconName: "add",
                  text: "Adicionar Perfil",
                  dispatch: "AccessControl/modalProfileOpen",
                  typeButton: "primary",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("div", { attrs: { id: "list-profiles" } }, [
        _c(
          "div",
          { staticClass: "mx-12" },
          [_c("ProfilesCards", { attrs: { data: _vm.profiles } })],
          1
        ),
      ]),
      _c("ModalFormProfileVue", { attrs: { open: _vm.modalProfileOpen } }),
      _c("PermissionsViewVue", { attrs: { open: _vm.showModalPermissions } }),
      _c("ModalConfirmDeleteProfileViewVue", {
        attrs: { open: _vm.modalConfirmDeleteProfile },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      { staticClass: "mx-12 my-6", attrs: { id: "title-page" } },
      [
        _c("div", { staticClass: "text-ashes-hover font-medium text-3xl" }, [
          _vm._v("Perfis"),
        ]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }