var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    _vm._l(_vm.headerColumns, function (headerColumn) {
      return _c(
        "th",
        {
          key: headerColumn.key,
          staticClass: "text-xs uppercase text-left font-semibold align-middle",
        },
        [_vm._v(" " + _vm._s(headerColumn.label) + " ")]
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }