var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c(
        "div",
        [
          _c(
            "div",
            {
              staticClass:
                "grid grid-cols-2 justify-between p-4 items-end gap-4",
              attrs: { id: "tabs-button" },
            },
            [
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Histórico de Atualizações",
                      "type-text": "custom",
                      weight: "normal",
                      color: "carmesim",
                      size: "sm",
                    },
                  }),
                ],
                1
              ),
              _vm.permitCreate
                ? _c(
                    "div",
                    { staticClass: "justify-self-end" },
                    [
                      _c("AppButtonColorful", {
                        attrs: {
                          text: "Matriz",
                          typeButton: "primary",
                          showIcon: "",
                          iconName: "add",
                          iconType: "outlined",
                          dispatch:
                            "MatrixRiskStratifications/openEditModalMatrixRiskStratification",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c("hr", {
            staticClass: "border-t-0 border-b-2 border-ashes opacity-50 mt-5",
          }),
          _c("div", { attrs: { id: "list-psychosocials" } }, [
            _c(
              "div",
              { staticClass: "mx-12" },
              [
                _c("PsychosocialAccompanimentVulnerabilityByInscriptionCards", {
                  attrs: {
                    data: _vm.matrix_risk_stratifications_by_inscription,
                  },
                }),
              ],
              1
            ),
            _vm.matrix_risk_stratifications_by_inscription.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "mx-12 my-6 pb-3",
                    attrs: {
                      id: "matrix_risk_stratifications_by_inscriptio_pagination",
                    },
                  },
                  [
                    _c("BasePagination", {
                      attrs: {
                        total: _vm.matrixRiskStratificationByInscriptionTotal,
                        "total-per-page":
                          _vm.matrixRiskStratificationByInscriptionTotalPerPage,
                        dispatch:
                          "MatrixRiskStratifications/setMatrixRiskStratificationByInscriptionOffset",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("ModalViewMatrixRisKStratificationByInscription", {
            attrs: { open: _vm.modalToggleViewMatrixRiskStratifications },
          }),
          _c("ModalMatrixRiskStratifications", {
            attrs: { open: _vm.modalToggleMatrixRiskStratifications },
          }),
          _c("DeleteMatrixRiskStratificationConfirmation", {
            attrs: { open: _vm.modalConfirmDeleteMatrixRiskStratification },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }