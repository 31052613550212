var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showButton
    ? _c(
        "button",
        {
          class: _vm.buttonClass,
          attrs: { id: _vm.id, type: "button" },
          on: { click: _vm.onClick },
        },
        [
          _vm.showIcon
            ? _c("AppMaterialIcon", {
                staticClass: "relative inset-y-1",
                attrs: { "icon-name": _vm.iconName, "icon-type": _vm.iconType },
              })
            : _vm._e(),
          _c("BaseText", {
            attrs: { text: _vm.text, "type-text": _vm.typeText },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }