var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "font-system" },
    [
      _c("div", { staticClass: "grid auto-grid-auto p-10" }, [
        _c(
          "div",
          [
            _c("BaseText", {
              attrs: {
                text: _vm.city.name + "| Equipamento",
                typeText: "custom",
                weight: "semibold",
                italic: false,
                size: "3xl",
                color: "ashes-hover",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "my-8" },
          [
            _c(
              "router-link",
              { attrs: { to: "/equipamentos" } },
              [
                _c("BaseText", {
                  attrs: {
                    text: "<- Voltar",
                    typeText: "custom",
                    weight: "medium",
                    italic: false,
                    mode: "capitalize",
                    size: "sm",
                    color: "carmesim",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseText", {
              attrs: {
                text: _vm.equipment.name + " - " + _vm.equipment.city.name,
                typeText: "custom",
                weight: "semibold",
                italic: false,
                mode: "capitalize",
                size: "3xl",
                color: "ashes-dark",
              },
            }),
            _vm.permitUp
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "edit",
                    payload: _vm.equipment,
                    dispatch: "Equipment/openEditModalEquipment",
                  },
                })
              : _vm._e(),
            _vm.permitDes
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "delete",
                    payload: _vm.equipment,
                    dispatch: "Equipment/openCloseDeleteEquipmentConfirmation",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "border-b border-ashes-hover my-8" },
          [
            _c("BaseText", {
              attrs: {
                text: "Áreas Vinculadas",
                typeText: "custom",
                weight: "medium",
                italic: false,
                mode: "uppercase",
                size: "md",
                color: "carmesim",
              },
            }),
          ],
          1
        ),
        _c(
          "ul",
          _vm._l(this.equipment.areas_attributes, function (area) {
            return _c(
              "li",
              { key: area.id },
              [
                _vm._v(" - "),
                _c("BaseText", {
                  attrs: {
                    text: area.name,
                    typeText: "custom",
                    weight: "medium",
                    italic: false,
                    mode: "capitalize",
                    size: "md",
                  },
                }),
              ],
              1
            )
          }),
          0
        ),
        _c(
          "div",
          { staticClass: "border-b border-ashes-hover my-8" },
          [
            _c("BaseText", {
              attrs: {
                text: "Endereço",
                typeText: "custom",
                weight: "medium",
                italic: false,
                mode: "uppercase",
                size: "md",
                color: "carmesim",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "grid grid-rols-auto" }, [
          _c("div", { staticClass: "grid grid-cols-2" }, [
            _c("div", { staticClass: "grid grid-rols-2" }, [
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: "CEP",
                      typeText: "custom",
                      weight: "bold",
                      italic: false,
                      mode: "uppercase",
                      size: "sm",
                      color: "ashes-dark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: _vm.equipment.cep,
                      typeText: "custom",
                      weight: "medium",
                      italic: false,
                      mode: "capitalize",
                      size: "base",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "grid grid-rols-2" }, [
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Geoposicionamento",
                      typeText: "custom",
                      weight: "bold",
                      italic: false,
                      mode: "uppercase",
                      size: "sm",
                      color: "ashes-dark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text:
                        _vm.equipment.latitude + ", " + _vm.equipment.longitude,
                      typeText: "custom",
                      weight: "medium",
                      italic: false,
                      mode: "capitalize",
                      size: "base",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "grid grid-cols-2" }, [
            _c("div", { staticClass: "grid grid-rols-2" }, [
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Rua",
                      typeText: "custom",
                      weight: "bold",
                      italic: false,
                      mode: "uppercase",
                      size: "sm",
                      color: "ashes-dark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: _vm.equipment.street,
                      typeText: "custom",
                      weight: "medium",
                      italic: false,
                      mode: "capitalize",
                      size: "base",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "grid grid-rols-2" }, [
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Número",
                      typeText: "custom",
                      weight: "bold",
                      italic: false,
                      mode: "uppercase",
                      size: "sm",
                      color: "ashes-dark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: _vm.equipment.street_number,
                      typeText: "custom",
                      weight: "medium",
                      italic: false,
                      mode: "capitalize",
                      size: "base",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "grid grid-cols-2" }, [
            _c("div", { staticClass: "grid grid-rols-2" }, [
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Bairro",
                      typeText: "custom",
                      weight: "bold",
                      italic: false,
                      mode: "uppercase",
                      size: "sm",
                      color: "ashes-dark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: _vm.equipment.neighborhood.name,
                      typeText: "custom",
                      weight: "medium",
                      italic: false,
                      mode: "capitalize",
                      size: "base",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "grid grid-rols-2" }, [
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Comunidade",
                      typeText: "custom",
                      weight: "bold",
                      italic: false,
                      mode: "uppercase",
                      size: "sm",
                      color: "ashes-dark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: _vm.equipment.community,
                      typeText: "custom",
                      weight: "medium",
                      italic: false,
                      mode: "capitalize",
                      size: "base",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "grid grid-cols-2" }, [
            _c("div", { staticClass: "grid grid-rols-2" }, [
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Municipio",
                      typeText: "custom",
                      weight: "bold",
                      italic: false,
                      mode: "uppercase",
                      size: "sm",
                      color: "ashes-dark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: _vm.equipment.city.name,
                      typeText: "custom",
                      weight: "medium",
                      italic: false,
                      mode: "capitalize",
                      size: "base",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "grid grid-rols-2" }, [
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Estado",
                      typeText: "custom",
                      weight: "bold",
                      italic: false,
                      mode: "uppercase",
                      size: "sm",
                      color: "ashes-dark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: _vm.equipment.state.name,
                      typeText: "custom",
                      weight: "medium",
                      italic: false,
                      mode: "capitalize",
                      size: "base",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
          _c("div", { staticClass: "grid grid-rols-2" }, [
            _c(
              "div",
              [
                _c("BaseText", {
                  attrs: {
                    text: "Complemento",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "sm",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("BaseText", {
                  attrs: {
                    text: _vm.equipment.complement,
                    typeText: "custom",
                    weight: "medium",
                    italic: false,
                    mode: "capitalize",
                    size: "base",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c("ModalEquipment", { attrs: { open: _vm.openModalEquipment } }),
      _c("DeleteEquipmentConfirmation", {
        attrs: { open: _vm.openModalDelete },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }