<template>
  <div class="grid auto-grid-auto">
    <div class="pl-12">
      <BaseText
        :text="city.name + ' | Usuários'"
        typeText="custom"
        weight="semibold"
        :italic="false"
        size="3xl"
        color="ashes-hover"
      />
    </div>
    <div id="tabs-button" class="flex justify-between mx-12 my-6">
      <div class="flex">
        <div class="w-104">
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por nome ou cpf"
            dispatch="Users/filterList"
          />
        </div>
      </div>
      <div class="float-right">
        <AppButtonColorful
          id="btn-add-teacher"
          showIcon
          iconName="add"
          text="Adicionar Usuário"
          dispatch="User/openModal"
          typeButton="primary"
        />
      </div>
    </div>
    <div class="p-4">
      <div><UsersCards :data="users" /></div>
    </div>
    <ModalUser :open="toggleModal" />
    <ModalConfirmDeleteUser :open="modalConfirmDeleteUser" />
    <ModalResetUserPassword :open="modalResetPassword" />
    <ModalUserActivation :open="modalActivation" />
  </div>
</template>

<script>
import ModalUser from "./ModalUser.vue";
import UsersCards from "./list/UsersCards.vue";
import BaseText from "../components/BaseText.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import ModalConfirmDeleteUser from "../views/ModalConfirmDeleteUser.vue";
import ModalUserActivation from "../views/ModalUserActivation.vue";
import ModalResetUserPassword from "./resets/ModalResetUserPassword.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    UsersCards,
    ModalConfirmDeleteUser,
    ModalResetUserPassword,
    ModalUserActivation,
    BaseText,
    ModalUser,
    AppButtonColorful,
    BaseInput,
  },
  beforeCreate() {
    this.$store.dispatch("Users/loadUsers");
  },
  computed: {
    ...mapState({
      users: (state) => state.Users.filterUsers,
      toggleModal: (state) => state.User.modalToggle,
      city: (state) => state.City.city,
      modalConfirmDeleteUser: (state) => state.User.modalConfirmDeleteUser,
      modalResetPassword: (state) => state.User.modalResetPassword,
      modalActivation: (state) => state.User.modalActivation,
    }),
  },
  methods: {
    ...mapActions({
      clearFilter: "Courses/clearFilter",
    }),
  },
};
</script>
