var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "font-system" }, [
    _c("div", { staticClass: "mx-12 my-6", attrs: { id: "title-page" } }, [
      _c("div", { staticClass: "text-ashes-hover font-medium text-3xl" }, [
        _vm._v(_vm._s(_vm.city.name) + " | Técnicos"),
      ]),
    ]),
    _c(
      "div",
      {
        staticClass: "flex justify-between mx-12 my-6",
        attrs: { id: "tabs-button" },
      },
      [
        _c("div", { staticClass: "flex" }, [
          _c(
            "div",
            { staticClass: "w-104" },
            [
              _c("BaseInput", {
                attrs: {
                  active: "",
                  inputName: "",
                  inputType: "text",
                  iconName: "search",
                  showIcon: "",
                  "input-placeholder": "Pesquisar por nome ou cpf",
                  dispatch: "Atendents/filterList",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "float-right" },
          [
            _vm.permitCreate
              ? _c("AppButtonColorful", {
                  attrs: {
                    id: "btn-add-atendent",
                    showIcon: "",
                    iconName: "add",
                    text: "Adicionar Técnico",
                    dispatch: "Atendents/openModalAtendent",
                    typeButton: "primary",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { attrs: { id: "list-atendent" } },
      [
        _c(
          "div",
          { staticClass: "mx-12" },
          [_c("AtendentsCards", { attrs: { data: _vm.atendents } })],
          1
        ),
        _c("ModalAtendent", { attrs: { open: _vm.modalAtendentOpen } }),
        _c("ModalResetAtendentPassword", {
          attrs: { open: _vm.modalResetPassword },
        }),
        _c("DeleteAtendentConfirmation", {
          attrs: { open: _vm.modalDeleteAtendentOpen },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }