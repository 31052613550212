// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/background-home-admin.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".background[data-v-727c7e34] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-size: cover;\n  height: 100%; /* Garante que a imagem de fundo cubra a altura total */\n}\n.height[data-v-727c7e34] {\n  min-height: 120vh;\n}\n\n/* Adicionando regras de responsividade */\n@media (max-width: 768px) {\n.background[data-v-727c7e34] {\n    height: 64vh; /* Ajusta a altura da imagem de fundo em dispositivos móveis */\n}\n}\r\n", ""]);
// Exports
module.exports = exports;
