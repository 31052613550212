var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showLabel
        ? _c("BaseLabel", {
            attrs: { text: _vm.labelText, required: _vm.required },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "flex flex-row" },
        _vm._l(_vm.values, function (item, index) {
          return _c(
            "div",
            { key: index, staticClass: "mr-5 app-checkbox" },
            [
              _c("input", {
                attrs: {
                  id: _vm.checkboxId(item.action_code, index),
                  type: "checkbox",
                  name: item.name,
                },
                domProps: { value: item.checked, checked: item.checked },
                on: {
                  click: function ($event) {
                    return _vm.onClick(item, $event)
                  },
                },
              }),
              _c("BaseLabel", {
                attrs: {
                  text: item.name,
                  target: _vm.checkboxId(item.action_code, index),
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _vm.errorMessage
        ? _c("span", { staticClass: "text-carmesim-hover text-xs" }, [
            _vm._v(_vm._s(_vm.errorMessage)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }