<template>
  <BaseCard active :class="liClass">
    <div class="grid grid-rows-2 content-center">
      <div>
        <Label
          :text="season.name"
          :icon="null"
          :iconType="null"
          type="text-xl"
          weight="extrabold"
          normal
          mode="normal-case"
        />
      </div>
      <div>
        <span v-for="area in season.areas_attributes" :key="area.code">
          |
          <Label
            :text="area.name"
            :icon="null"
            :iconType="null"
            type="text-xs"
            weight="medium"
            normal
            mode="uppercase"
            class="text-burnedYellow"
          />
        </span>
        |
      </div>
    </div>
    <div class="grid grid-cols-2">
      <div>
        <Label
          :text="season.date_inscription_start"
          :iconType="null"
          type="text-xs"
          icon="none"
          weight="medium"
          normal
          mode="uppercase"
          class="text-ashes-dark"
        />
        -
        <Label
          :text="season.date_inscription_end"
          :iconType="null"
          type="text-xs"
          icon="none"
          weight="medium"
          normal
          mode="uppercase"
          class="text-ashes-dark"
        />
      </div>
      <div>
        |
        <Label
          :text="season.inscription_status"
          :iconType="null"
          type="text-xs"
          icon="none"
          weight="bold"
          normal
          mode="uppercase"
        />
      </div>
    </div>
    <div class="grid grid-cols-2">
      <div>
        <Label
          :text="season.date_execution_start"
          :iconType="null"
          type="text-xs"
          icon="none"
          weight="medium"
          normal
          mode="uppercase"
          class="text-ashes-dark"
        />
        -
        <Label
          :text="season.date_execution_end"
          :iconType="null"
          type="text-xs"
          icon="none"
          weight="medium"
          normal
          mode="uppercase"
          class="text-ashes-dark"
        />
      </div>
      <div>
        |
        <Label
          :text="season.execution_status"
          :iconType="null"
          type="text-xs"
          icon="none"
          weight="bold"
          normal
          mode="uppercase"
        />
      </div>
    </div>
    <div class="text-right" v-if="permitFinishSeason && !season.date_manual_execution_end">
      <BaseButton
        label="Encerrar Edição"
        active
        styleButton="danger"
        dispatch="Season/openCloseEndSeasonConfirmation"
      />
    </div>
    <div class="text-right" v-if="permitFinishSeason && season.date_manual_execution_end">
      <BaseButton label="Edição Encerrada" active styleButton="danger" />
    </div>
    <div class="text-right" v-if="!season.date_manual_execution_end">
      <AppButtonIcon iconName="edit" @on-click.stop="openSeasonModal" v-if="permit" />
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import BaseButton from "./BaseButton.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import Label from "./Label.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    BaseButton,
    AppButtonIcon,
    Label,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
      permitFinishSeason: false,
    };
  },
  created() {
    this.permitChangeSeason();
    this.permitFinishSeasonAccess();
    this.$store.dispatch("Areas/loadAreas");
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    season: {
      type: Object,
    },
  },

  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-5": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
      };
    },
  },

  methods: {
    openSeasonModal() {
      this.$emit("onClick", { season: this.season, edit: true });
    },
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    permitChangeSeason() {
      this.canAcces("season_update").then((resp) => {
        this.permit = resp;
      });
    },
    permitFinishSeasonAccess() {
      this.canAcces("season_end_season").then((response) => {
        this.permitFinishSeason = response;
      });
    },
  },
};
</script>

<style></style>
