var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "font-system" },
    [
      _c(
        "div",
        { staticClass: "mx-12 my-6 w-80", attrs: { id: "title-page" } },
        [
          _c(
            "div",
            [
              _c("Multiselect", {
                attrs: {
                  id: "select-season",
                  "show-label": "",
                  "close-on-select": "",
                  "select-placeholder": "Selecione as opções",
                  "label-text": "Selecione a edição",
                  "clear-on-select": false,
                  "multiple-option": false,
                  value: _vm.season,
                  options: _vm.seasonsAll,
                  dispatch: _vm.setSeasonPage,
                },
              }),
            ],
            1
          ),
          _c("br"),
          _c("br"),
          _c(
            "div",
            { staticClass: "text-ashes-hover font-medium text-3xl w-max" },
            [_vm._v(_vm._s(_vm.city.name) + " | Alunos [Desligados]")]
          ),
          _c(
            "div",
            { staticClass: "my-10 flex", attrs: { id: "menus_youngstudent" } },
            [
              _c(
                "div",
                [
                  _c("router-link", { attrs: { to: "/estudantes" } }, [
                    _c(
                      "a",
                      { attrs: { href: "javascript:void(0)" } },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "ativos",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "sm",
                            color: "ashes-hover",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
              _c("div", { staticClass: "border-l mx-10" }),
              _c("div", [
                _c(
                  "a",
                  { attrs: { href: "javascript:void(0)" } },
                  [
                    _c("BaseText", {
                      staticClass: "underline",
                      attrs: {
                        text: "desligados",
                        typeText: "custom",
                        weight: "semibold",
                        mode: "uppercase",
                        size: "sm",
                        color: "burnedYellow",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "flex justify-between mx-12 my-6",
          attrs: { id: "tabs-button" },
        },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "w-104 teste" },
              [
                _c("BaseInput", {
                  attrs: {
                    active: "",
                    id: "filter-student",
                    inputName: "",
                    inputType: "text",
                    iconName: "search",
                    showIcon: "",
                    "input-placeholder": "Pesquisar por nome ou cpf",
                    dispatch: "Season/filterList",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "float-right" },
            [
              _c("AppButtonColorful", {
                attrs: {
                  id: "btn-add-student",
                  showIcon: "",
                  iconName: "add",
                  text: "Adicionar aluno",
                  dispatch: "Default/toggleModalPreInscription",
                  typeButton: "primary",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "mx-14 my-6 text-sm",
          attrs: { id: "table-young-student-disconnected" },
        },
        [
          _c("BaseTableStudent", {
            attrs: {
              header: _vm.mountHeader(),
              body: _vm.bodyTable,
              total: _vm.inscriptionTotal,
              "total-per-page": _vm.totalPerPage,
              dispatch: "Season/setOffset",
            },
          }),
        ],
        1
      ),
      _c("ModalInscriptionStudent", {
        attrs: { open: _vm.modalInscriptionStudentOpen },
      }),
      _c("ModalViewStudent", { attrs: { open: _vm.modalViewStudentOpen } }),
      _c("ModalStudentDropdown", {
        attrs: { open: _vm.modalStudentDropdownOpen },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }