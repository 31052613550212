var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permit
    ? _c("div", { staticClass: "font-system" }, [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "mx-14", attrs: { id: "tabs-button" } },
          [
            _c("FilterComponent", [
              _c(
                "div",
                { staticClass: "col-span-4" },
                [
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "name",
                      "input-type": "text",
                      "icon-name": "search",
                      showIcon: "",
                      "show-label": "",
                      value: _vm.params.name,
                      "label-text": "Pesquisar pelo nome",
                      "input-placeholder": "Pesquisar pelo nome",
                      dispatch: "SeasonPreviousInscriptions/setFilterName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "cpf",
                      "input-type": "text",
                      "icon-name": "search",
                      showIcon: "",
                      "show-label": "",
                      value: _vm.params.cpf,
                      "label-text": "Pesquisar pelo cpf",
                      "input-placeholder": "Pesquisar pelo cpf",
                      dispatch: "SeasonPreviousInscriptions/setFilterCpf",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "rg",
                      "input-type": "text",
                      "icon-name": "search",
                      showIcon: "",
                      "show-label": "",
                      value: _vm.params.rg,
                      "label-text": "Pesquisar pelo rg",
                      "input-placeholder": "Pesquisar pelo rg",
                      dispatch: "SeasonPreviousInscriptions/setFilterRg",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "grid grid-cols-2 items-center mx-12 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              { staticClass: "mx-2" },
              [
                _c("AppButtonColorful", {
                  attrs: {
                    id: "btn-consult-partners",
                    showIcon: "",
                    iconName: "search",
                    text: "Consultar dados",
                    dispatch:
                      "SeasonPreviousInscriptions/loadSeasonPreviousInscriptions",
                    payload: "load",
                    typeButton: "primary",
                  },
                }),
              ],
              1
            ),
            _vm.seasonPreviousInscriptions.length > 0
              ? _c(
                  "div",
                  { staticClass: "mx-2 items-center justify-self-end" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.clearFields()
                          },
                        },
                      },
                      [
                        _c("AppButtonColorful", {
                          attrs: {
                            id: "btn-consult-partners",
                            showIcon: "",
                            iconName: "remove",
                            text: "Limpar consulta",
                            typeButton: "primary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { attrs: { id: "list-partners" } },
          [
            _c(
              "div",
              { staticClass: "mx-12" },
              [
                _c("SeasonPreviousInscriptionsCards", {
                  attrs: { data: _vm.seasonPreviousInscriptions },
                }),
              ],
              1
            ),
            _vm.seasonPreviousInscriptions.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "mx-12 my-6 pb-3",
                    attrs: { id: "partners-pagination" },
                  },
                  [
                    _c("BasePagination", {
                      attrs: {
                        total: _vm.totalSeasonPreviousInscriptions,
                        "total-per-page": _vm.totalPerPage,
                        dispatch:
                          "SeasonPreviousInscriptions/setSeasonPreviousInscriptionsOffset",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("ActivateInscriptionConfirmation", {
              attrs: { open: _vm.modalToggleActivateInscription },
            }),
            _c("DeactivateInscriptionConfirmation", {
              attrs: { open: _vm.modalToggleDeactivateInscription },
            }),
            _c("ModalTransferInscriptionToSeason", {
              attrs: { open: _vm.modalToggleTransferInscription },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "grid grid-cols-3 justify-between items-center mx-12 my-6",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "text-ashes-hover font-medium text-3xl mx-2 col-span-2",
          },
          [_vm._v(" Participantes de Edições Anteriores ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }