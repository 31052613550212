// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/img-rodape.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".accordion-custom {\n  --vsa-highlight-color: #db8300;\n  --vsa-border-color: #ffa200;\n  --vsa-text-color: #fff;\n  --vsa-max-width: 100%;\n  --vsa-heading-padding: 2.2rem 2rem;\n  --vsa-content-padding: 1rem 2rem;\n  --vsa-border-width: 1;\n  --vsa-border-stylesolid: solid;\n}\n.vsa-item__heading,\r\n.vsa-item__content {\n  border-bottom: 1px solid #fff;\n}\n.vsa-item__trigger[aria-expanded=\"true\"] .vsa-item__trigger__icon--is-default:before {\n  transform: rotate(45deg) translate3d(25px, 10px, 0);\n}\n.vsa-item__trigger[aria-expanded=\"true\"] .vsa-item__trigger__icon--is-default:after {\n  transform: rotate(-45deg) translate3d(-24px, 10px, 0);\n}\n.footer {\n  background-color: #662e91;\n}\n.text-color {\n  color: #008037;\n}\n.description {\n  width: 600px;\n}\n#body {\n  position: relative;\n  top: 290px;\n}\n#text-carrosel,\r\n#accordion-game {\n  background-color: #fbfbfb;\n}\n.text-fase {\n  color: #002c13;\n}\n.splide {\n  padding: 0 3em;\n}\n.splide__arrow svg {\n  fill: #3ba543 !important;\n}\n.splide__arrow:hover svg {\n  fill: #69c968 !important;\n}\n.header {\n  background-color: #3ba543;\n  background-image: linear-gradient(45deg, #3ba543 49%, #69c968 70%);\n}\n.bot {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  height: 55px;\n}\r\n", ""]);
// Exports
module.exports = exports;
