var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-5" }, [
    _c(
      "div",
      {
        staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
        attrs: { id: "person-data" },
      },
      [
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("BaseText", {
              attrs: { text: "Dados do Aluno(a)", "type-text": "title-forms" },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _vm.inscription
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Nome do Aluno(a)",
                    text: _vm.inscription.student.name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.inscription
          ? _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "CPF",
                    text: _vm._f("VMask")(
                      _vm.inscription.student.cpf,
                      "###.###.###-##"
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.inscription
          ? _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Data de Nascimento",
                    text: _vm.formattedDate(_vm.inscription.student.birthdate),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.inscription
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Email",
                    text: _vm.inscription.student.email,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.inscription
          ? _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Telefone",
                    text: _vm._f("VMask")(
                      _vm.inscription.student.cellphone_number,
                      "(##) #####-####"
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("BaseText", {
              attrs: { text: "Dados da Matriz", "type-text": "title-forms" },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-5", attrs: { id: "matrix-data" } },
          _vm._l(
            _vm.matrix_risk_stratifications_by_inscription_filtered
              .matrix_risk_stratifications,
            function (item, index) {
              return _c(
                "div",
                {
                  key: item.psychosocial_accompaniment_id,
                  staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
                  attrs: { "data-index": index },
                },
                [
                  _c(
                    "div",
                    { staticClass: "col-span-5" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "Problema Identificado",
                          text: item.matrix_problem,
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-3" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "Situação",
                          text: item.situation,
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "Risco",
                          text: item.situation_risk,
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-3" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "Impacto",
                          text: item.impact,
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "Nível de Impacto",
                          text: item.impact_level,
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._m(0, true),
                ]
              )
            }
          ),
          0
        ),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-span-5" }, [
      _c("hr", {
        staticClass: "border-t-0 border-b-2 border-ashes opacity-50 col-span-5",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }