var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "signin-login relative" }, [
    _c(
      "div",
      { staticClass: "top-36 left-28 absolute" },
      [
        _c(
          "router-link",
          {
            staticClass: "text-herbal underline hover:text-herbal-hover",
            attrs: { to: "/" },
          },
          [
            _c("BaseText", {
              attrs: {
                text: "< Voltar",
                "type-text": "custom",
                weight: "medium",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "flex flex-col justify-center items-center" }, [
      _vm._m(0),
      _c(
        "div",
        { staticClass: "mt-20 mb-10" },
        [
          _c("BaseText", {
            staticClass: "text-color-admin underline",
            attrs: {
              text: "Área do Instrutor",
              "type-text": "custom",
              weight: "semibold",
              size: "xl",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("BaseText", {
            staticClass: "text-color-admin",
            attrs: {
              text: "Faça seu login",
              "type-text": "custom",
              weight: "semibold",
              size: "xl",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-96 my-2" },
        [
          _c("AppInputRound", {
            attrs: {
              id: "username",
              iconName: "person",
              labelText: "E-mail",
              textColor: "herbal",
              borderColor: "herbal",
              dispatch: "Session/setUsername",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "w-96 my-2" },
        [
          _c("AppInputRound", {
            attrs: {
              id: "password",
              iconName: "visibility_off",
              labelText: "Senha",
              textColor: "herbal",
              borderColor: "herbal",
              hiddenValue: "",
              dispatch: "Session/setPassword",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "flex justify-start" }, [
        _c(
          "div",
          { staticClass: "pl-5 w-96 mt-0 mb-3" },
          [
            _c(
              "router-link",
              {
                staticClass: "text-herbal underline hover:text-herbal-hover",
                attrs: { to: "#" },
              },
              [
                _c("BaseText", {
                  staticClass: "text-color-admin",
                  attrs: {
                    text: "Esqueceu a senha?",
                    "type-text": "custom",
                    weight: "normal",
                    size: "xs",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "my-2" },
        [
          _c("AppButtonColorful", {
            staticClass: "w-96",
            attrs: {
              text: "Acessar",
              typeButton: "primary",
              dispatch: "Session/validateUser",
              payload: { access: ["Instrutor"] },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "bot w-full absolute bottom-0" }),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "my-28" }, [
      _c("img", {
        attrs: {
          src: require("../assets/images/logo-name-green.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }