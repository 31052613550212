var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("div", { staticClass: "static" }, [
        _c(
          "div",
          {
            staticClass:
              "container1 bg-white text-left border border-black-600 mr-3 block font-normal leading-normal text-sm rounded-lg tooltiptext shadow-xl w-40",
          },
          [
            _c("ul", { staticClass: "text-gray-400 font-semibold p-2" }, [
              _vm.isAllocatedInClass()
                ? _c("li", [
                    _c(
                      "button",
                      {
                        staticClass: "hover:bg-ashes mt-2",
                        on: {
                          click: function ($event) {
                            return _vm.openModalTransferStudent()
                          },
                        },
                      },
                      [_vm._v("Transferir")]
                    ),
                  ])
                : _vm._e(),
              _c("li", [
                _c(
                  "button",
                  {
                    staticClass: "hover:bg-ashes mt-2",
                    on: {
                      click: function ($event) {
                        return _vm.getOption(1)
                      },
                    },
                  },
                  [_vm._v("Desligar")]
                ),
              ]),
              _vm.isAllocatedInClass()
                ? _c("li", [
                    _c(
                      "button",
                      {
                        staticClass: "hover:bg-ashes mt-2",
                        on: {
                          click: function ($event) {
                            return _vm.getOption(2)
                          },
                        },
                      },
                      [_vm._v("Remover da Turma")]
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("ModalTransferStudentToClass", {
              attrs: { open: _vm.showModalTransferStudent },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }