var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: [_vm.buttonClass(), _vm.iconColor],
      attrs: { type: "button" },
      on: {
        click: function ($event) {
          $event.stopPropagation()
          return _vm.onClick($event)
        },
      },
    },
    [
      _c("AppMaterialIcon", {
        attrs: {
          iconName: _vm.whatIsMyName(),
          iconType: _vm.iconType,
          iconSize: _vm.iconSize,
          iconColor: _vm.iconColor,
          hover: _vm.hover,
          title: _vm.title,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }