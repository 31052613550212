var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-5" }, [
    _c(
      "div",
      {
        staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
        attrs: { id: "person-data" },
      },
      [
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Informações da Situação",
                "type-text": "title-forms",
              },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Problema",
                text: _vm.matrix_problem ? _vm.matrix_problem.name : "-",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Situação",
                text: _vm.matrix_situation.situation
                  ? _vm.matrix_situation.situation
                  : "-",
                "type-text": "topic-details",
                classe: "break-words",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Nível da Situação",
                text: _vm.situation_risk ? _vm.situation_risk.name : "-",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Impacto",
                text: _vm.matrix_situation.impact
                  ? _vm.matrix_situation.impact
                  : "-",
                "type-text": "topic-details",
                classe: "break-words",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Nível do Impacto",
                text: _vm.impact_level ? _vm.impact_level.name : "-",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }