var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { class: _vm.liClass }, [
    _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
      _c("div", { staticClass: "mt-4 flex" }, [
        _c("div", { staticClass: "place-self-center" }, [
          _c("div", { class: _vm.iconClass }, [
            _c("img", {
              attrs: {
                src: require("../assets/images/profile-vj.png"),
                alt: "Foto de Perfil",
              },
            }),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "justify-self-start place-self-center" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.user.name,
                mode: "normal-case",
                color: "quantum",
                typeText: "custom",
                weight: "bold",
                size: "base",
              },
            }),
            true
              ? _c("AppMaterialIcon", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: _vm.citiesTooltipContent,
                        classes: "custom-tooltip",
                      },
                      expression:
                        "{ content: citiesTooltipContent, classes: 'custom-tooltip' }",
                    },
                  ],
                  staticClass:
                    "icon-input text-quantum cursor-pointer inline-flex relative",
                  attrs: { iconName: "location_city" },
                })
              : _vm._e(),
            true
              ? _c("AppMaterialIcon", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: {
                        content: _vm.areasTooltipContent,
                        classes: "custom-tooltip",
                      },
                      expression:
                        "{ content: areasTooltipContent, classes: 'custom-tooltip' }",
                    },
                  ],
                  staticClass:
                    "icon-input text-quantum cursor-pointer inline-flex relative",
                  attrs: { iconName: "map" },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.user.email,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.user.cpf,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.user.ability_profile.name
                ? _vm.user.ability_profile.name
                : "Não informado",
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.user.active ? "Ativo" : "Desativado",
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4 flex" },
        [
          _vm.permitUp
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "edit",
                  payload: _vm.user,
                  dispatch: "User/openEditModal",
                  title: "Atualizar Informações do Usuário(a)",
                },
              })
            : _vm._e(),
          _vm.permitResetPassword
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "vpn_key",
                  payload: _vm.user,
                  dispatch: "User/resetPassword",
                  title: "Resetar para Senha Padrão",
                },
              })
            : _vm._e(),
          _vm.permitUp
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: _vm.chooseIcon(),
                  payload: _vm.user,
                  dispatch: "User/openModalActivation",
                  title: "Ativar/Desativar Usuário(a)",
                },
              })
            : _vm._e(),
          _vm.permitDes
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "delete",
                  payload: _vm.user,
                  dispatch: "User/openCloseDeleteUserConfirmation",
                  title: "Excluir Usuário(a)",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }