var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { class: [_vm.classNamed, _vm.classSized, _vm.colorNamed, _vm.hoverNamed] },
    [_vm._v(_vm._s(_vm.iconName))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }