// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../../assets/images/expand_more_black_18dp.svg");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, ".multiselect__option--selected.multiselect__option--highlight {\n  background: #f3f3f3 !important;\n  color: #000 !important;\n}\n.multiselect__tags {\n  border: 2px solid #383838;\n  min-height: auto !important;\n  border-radius: 0.75rem;\n}\n.multiselect__tag {\n  background-color: #fc6735;\n  border-radius: 0.75rem;\n  margin-bottom: 0px;\n}\n.multiselect__select {\n  top: 2px;\n}\n.multiselect__option--highlight {\n  background-color: #fc6735;\n}\n.multiselect__tag-icon:focus,\r\n.multiselect__tag-icon:hover {\n  background: #fc6735;\n}\n.multiselect {\n  min-height: auto !important;\n}\n.multiselect__input,\r\n.multiselect__single {\n  border-radius: 0.75rem;\n}\n.multiselect__content-wrapper {\n  border: 2px solid #383838;\n  border-bottom-left-radius: 0.75rem;\n  border-bottom-right-radius: 0.75rem;\n  border-top: none;\n  margin-top: -3px;\n}\n.multiselect__select:before {\n  position: relative;\n  right: 1px;\n  top: 20%;\n  margin-top: 4px;\n  border-style: solid;\n  border-width: 0;\n  border-color: transparent transparent transparent transparent;\n  content: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n}\n.multiselect--active .multiselect__select {\n  transform: rotateZ(180dg);\n}\n.multiselect__tag-icon:after {\n  content: \"×\";\n  color: #fff;\n  font-size: 14px;\n  margin-bottom: 1px;\n  position: relative;\n}\n.multiselect__tag > span {\n  position: relative;\n}\n.multiselect__placeholder {\n  color: #878787;\n  display: inline-block;\n  margin-bottom: 10px;\n  padding-top: 2px;\n  font-size: 14px;\n}\n.multiselect__input::-moz-placeholder {\n  color: #878787;\n  display: inline-block;\n  margin-bottom: 10px;\n  padding-top: 2px;\n  font-size: 14px;\n}\n.multiselect__input::placeholder {\n  color: #878787;\n  display: inline-block;\n  margin-bottom: 10px;\n  padding-top: 2px;\n  font-size: 14px;\n}\r\n", ""]);
// Exports
module.exports = exports;
