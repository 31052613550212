var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-4" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start col-span-3" },
                      [
                        _vm.psychosocial_accompaniment?.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Realizar Acolhimento Inicial",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Adicionar Acolhimento Inicial",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "PsychosocialAccompaniments/closeModalFirstPsychosocialAccompaniment",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _vm.psychosocial_accompaniment?.id && _vm.accompaniment_origin
                    ? _c(
                        "div",
                        { staticClass: "grid grid-cols-4 gap-4 mt-4" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col-span-4 text-burnedYellow pb-2",
                            },
                            [
                              _c("Label", {
                                attrs: {
                                  text: "Informações do Atendimento de Origem",
                                  icon: null,
                                  "icon-type": null,
                                  type: "text-sm",
                                  weight: "semibold",
                                  normal: "",
                                  mode: "uppercase",
                                },
                              }),
                              _c("div", {
                                staticClass:
                                  "border-ashes-hover border-b-2 w-full",
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Data do Encontro",
                                  text: _vm.formattedDate(
                                    _vm.accompaniment_origin.date_accompaniment
                                  ),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Tipo do Atendimento",
                                  text: _vm.accompaniment_origin
                                    .accompaniment_type.name,
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Status do Acompanhamento",
                                  text: _vm.accompaniment_origin
                                    .accompaniment_status.name,
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-2" },
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text":
                                    "Usuário responsável pelo cadastro",
                                  text: _vm.accompaniment_origin.user.name,
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.psychosocial_accompaniment?.id
                    ? _c(
                        "div",
                        {
                          staticClass: "grid grid-cols-4 gap-4 mt-4",
                          attrs: { div: "" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col-span-4 text-burnedYellow pb-2",
                            },
                            [
                              _c("Label", {
                                attrs: {
                                  text: "Informações do Aluno(a)",
                                  icon: null,
                                  "icon-type": null,
                                  type: "text-sm",
                                  weight: "semibold",
                                  normal: "",
                                  mode: "uppercase",
                                },
                              }),
                              _c("div", {
                                staticClass:
                                  "border-ashes-hover border-b-2 w-full",
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-2" },
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Nome",
                                  text: _vm.inscription.student.name,
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Status do Aluno(a) no sistema",
                                  text: _vm.verifyStatus(),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "CPF",
                                  text: _vm._f("VMask")(
                                    _vm.verifyInscription("cpf"),
                                    "###.###.###-##"
                                  ),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Data de Nascimento",
                                  text: _vm.verifyInscription("birthdate"),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Idade",
                                  text: _vm.verifyInscription("age"),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Número Contato",
                                  text: _vm._f("VMask")(
                                    _vm.verifyInscription("cellphone_number"),
                                    "(##) #####-####"
                                  ),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Dados cadastrais do encontro",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "birthdate",
                            "input-type": "date",
                            value:
                              _vm.psychosocial_accompaniment
                                ?.date_accompaniment,
                            "show-label": "",
                            "label-text": "Data do Encontro",
                            required: "",
                            dispatch:
                              "PsychosocialAccompaniments/setDateAccompaniment",
                            readonly: true,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-1" },
                      [
                        _c("AppRadioButton", {
                          attrs: {
                            values: [
                              {
                                name: "has_meeting_happened",
                                value: true,
                                description: "Sim",
                                checked:
                                  _vm.psychosocial_accompaniment
                                    ?.meeting_happened === true,
                              },
                              {
                                name: "has_meeting_happened",
                                value: false,
                                description: "Não",
                                checked:
                                  _vm.psychosocial_accompaniment
                                    ?.meeting_happened === false,
                              },
                            ],
                            labelText: "Encontro aconteceu?",
                            showLabel: "",
                            dispatch:
                              "PsychosocialAccompaniments/setMeetingHappened",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "meeting_format",
                                    value: "Presencial",
                                    description: "Presencial",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.meeting_format === "Presencial",
                                  },
                                  {
                                    name: "meeting_format",
                                    value: "Remoto",
                                    description: "Remoto",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.meeting_format === "Remoto",
                                  },
                                ],
                                labelText: "Formato do Encontro",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setMeetingFormat",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    !_vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder":
                                  "Motivo do Encontro Não ter Ocorrido",
                                "label-text":
                                  "Motivo do Encontro Não ter Ocorrido",
                                "multiple-option": false,
                                value: _vm.meeting_not_happened_reason,
                                options: _vm.meeting_not_happened_reasons,
                                dispatch: _vm.setMeetingNotHappenedReason,
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name":
                                  "psychosocial_accompaniment_meeting_place",
                                "input-type": "text",
                                value:
                                  _vm.psychosocial_accompaniment?.meeting_place,
                                "show-label": "",
                                "label-text": "Local do Encontro",
                                dispatch:
                                  "PsychosocialAccompaniments/setMeetingPlace",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name":
                                  "psychosocial_accompaniment_meeting_remote_resource",
                                "input-type": "text",
                                value:
                                  _vm.psychosocial_accompaniment
                                    ?.meeting_remote_resource,
                                "show-label": "",
                                "label-text": "Recurso do encontro remoto",
                                dispatch:
                                  "PsychosocialAccompaniments/setMeetingRemoteResource",
                                readonly:
                                  _vm.psychosocial_accompaniment
                                    ?.meeting_format === "Presencial",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Profissionais que acompanham",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "register_attendant_teams",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.register_attendant_teams === true,
                                  },
                                  {
                                    name: "register_attendant_teams",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.register_attendant_teams === false,
                                  },
                                ],
                                labelText:
                                  "Informar profissionais que acompanham o Atendimento?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setRegisterAttendatTeams",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.register_attendant_teams
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _vm._l(
                              _vm.attendant_teams_attributes,
                              function (attendant, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "grid grid-cols-12 gap-4 mt-2",
                                  },
                                  [
                                    _vm.psychosocial_accompaniment
                                      ?.meeting_happened
                                      ? _c(
                                          "div",
                                          { staticClass: "col-span-11" },
                                          [
                                            _c("Multiselect", {
                                              attrs: {
                                                "close-on-select": true,
                                                "show-label": "",
                                                "clear-on-select": "",
                                                "select-placeholder":
                                                  "Selecione a opção",
                                                "label-text": "Profissional",
                                                "multiple-option": false,
                                                value:
                                                  _vm
                                                    .psychosocial_attendant_attributes[
                                                    index
                                                  ],
                                                options:
                                                  _vm.mountPsychosocialAttendants(
                                                    index
                                                  ),
                                                required: "",
                                                dispatch:
                                                  _vm.setPsychosocialAttendant,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.attendant_teams_attributes.length > 1 &&
                                    _vm.psychosocial_accompaniment
                                      .meeting_happened
                                      ? _c("div", { staticClass: "mt-5" }, [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "text-burnedYellow underline",
                                              attrs: {
                                                href: "javascript:void(0)",
                                              },
                                              on: {
                                                click: _vm.deleteAttendantTeam,
                                              },
                                            },
                                            [
                                              _c("AppMaterialIcon", {
                                                staticClass: "align-middle",
                                                attrs: {
                                                  "icon-name": "delete",
                                                  "icon-size": "36",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            _vm.attendant_teams_attributes.length > 1 &&
                            _vm.psychosocial_accompaniment.meeting_happened
                              ? _c("div", { staticClass: "col-span-4 mt-5" }, [
                                  _c("hr", {
                                    staticClass:
                                      "border-t-0 border-b-2 border-ashes opacity-50",
                                  }),
                                ])
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened &&
                    _vm.register_attendant_teams
                      ? _c("div", { staticClass: "mt-5" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-burnedYellow",
                              attrs: { href: "javascript:void(0)" },
                              on: { click: _vm.addAttendantTeam },
                            },
                            [
                              _c("AppMaterialIcon", {
                                staticClass: "align-middle",
                                attrs: {
                                  "icon-name": "add_circle",
                                  "icon-size": "36",
                                },
                              }),
                              _c("BaseText", {
                                staticClass: "align-middle mr-2",
                                attrs: {
                                  text: "Adicionar Profissional",
                                  "type-text": "custom",
                                  weight: "medium",
                                  size: "sm",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Situação de Moradia",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Situação de Moradia",
                                "label-text": "Situação de Moradia",
                                "multiple-option": false,
                                value: _vm.housing_situation,
                                options: _vm.housing_situations,
                                dispatch: _vm.setHousingSituation,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "number_of_residents",
                                "input-type": "number",
                                value:
                                  _vm.psychosocial_accompaniment
                                    ?.number_of_residents,
                                "show-label": "",
                                "label-text":
                                  "Quantas pessoas residem com você?",
                                inputMin: "0",
                                dispatch:
                                  "PsychosocialAccompaniments/setNumberOfResidents",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Selecione uma opção",
                                "label-text": "Situação do Imóvel",
                                "multiple-option": false,
                                value: _vm.housing_building_situation,
                                options: _vm.housing_building_situations,
                                dispatch: _vm.setHousingBuildingSituation,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Selecione uma opção",
                                "label-text": "Estrutura do Imóvel",
                                "multiple-option": false,
                                value: _vm.housing_building_material,
                                options: _vm.housing_building_materials,
                                dispatch: _vm.setHousingBuildingMaterial,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Selecione uma opção",
                                "label-text":
                                  "Qual a forma do abastecimento de energia elétrica?",
                                "multiple-option": false,
                                value: _vm.housing_has_electricity,
                                options: _vm.housing_has_electricities,
                                dispatch: _vm.setHousingHasElectricity,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Selecione uma opção",
                                "label-text":
                                  "Qual a forma do abastecimento de água?",
                                "multiple-option": false,
                                value: _vm.housing_has_water_supply,
                                options: _vm.housing_has_water_supplies,
                                dispatch: _vm.setHousingHasWaterSupply,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Selecione uma opção",
                                "label-text": "Fornecimento de Gás",
                                "multiple-option": false,
                                value: _vm.housing_has_gas_supply,
                                options: _vm.housing_has_gas_supplies,
                                dispatch: _vm.setHousingHasGasSupply,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Selecione uma opção",
                                "label-text":
                                  "De que forma é feito o escoamento do banheiro ou sanitário?",
                                "multiple-option": false,
                                value: _vm.housing_bathroom_drain_type,
                                options: _vm.housing_bathroom_drain_types,
                                dispatch: _vm.setHousingBathroomDrainType,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "housing_has_latrine",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.housing_has_latrine === true,
                                  },
                                  {
                                    name: "housing_has_latrine",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.housing_has_latrine === false,
                                  },
                                ],
                                labelText: "Tem saneamento básico?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setHousingHasLatrine",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "housing_has_bathroom",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.housing_has_bathroom === true,
                                  },
                                  {
                                    name: "housing_has_bathroom",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.housing_has_bathroom === false,
                                  },
                                ],
                                labelText: "Tem banheiro?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setHousingHasBathroom",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "housing_has_garbage_collection",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.housing_has_garbage_collection ===
                                      true,
                                  },
                                  {
                                    name: "housing_has_garbage_collection",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.housing_has_garbage_collection ===
                                      false,
                                  },
                                ],
                                labelText: "Tem coleta de lixo?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setHousingHasGarbageCollection",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseTextArea", {
                              attrs: {
                                active: "",
                                inputName:
                                  "Comentários sobre situação de Moradia",
                                labelText:
                                  "Comentários sobre situação de Moradia",
                                showLabel: "",
                                rows: "4",
                                value:
                                  _vm.psychosocial_accompaniment
                                    ?.housing_situation_comments,
                                dispatch:
                                  "PsychosocialAccompaniments/setHousingSituationComments",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Cumprimento de Medida Socioeducativa",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Selecione uma opção",
                                "label-text":
                                  "Situação do adolescente/jovem em relação ao cumprimento de medidas socioeducativas",
                                "multiple-option": false,
                                value: _vm.socio_educational_measure_situation,
                                options:
                                  _vm.socio_educational_measure_situations,
                                dispatch:
                                  _vm.setSocioEducationalMeasureSituation,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "is_half_open_socio_educational_measure",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_half_open_socio_educational_measure ===
                                      true,
                                  },
                                  {
                                    name: "is_half_open_socio_educational_measure",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_half_open_socio_educational_measure ===
                                      false,
                                  },
                                ],
                                labelText: "Meio Aberto?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setIsHalfOpenSocioEducationalMeasure",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "is_half_closed_socio_educational_measure",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_half_closed_socio_educational_measure ===
                                      true,
                                  },
                                  {
                                    name: "is_half_closed_socio_educational_measure",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_half_closed_socio_educational_measure ===
                                      false,
                                  },
                                ],
                                labelText: "Meio Fechado?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setIsHalfClosedSocioEducationalMeasure",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Cumprimento de Pena",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Selecione uma opção",
                                "label-text":
                                  "Situação do adolescente/jovem em relação ao cumprimento de pena",
                                "multiple-option": false,
                                value: _vm.sentence_serving_situation,
                                options:
                                  _vm.socio_educational_measure_situations,
                                dispatch: _vm.setSentenceServingSituation,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "is_half_open_sentence_serving_situation",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_half_open_sentence_serving_situation ===
                                      true,
                                  },
                                  {
                                    name: "is_half_open_sentence_serving_situation",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_half_open_sentence_serving_situation ===
                                      false,
                                  },
                                ],
                                labelText: "Meio Aberto?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setIsHalfOpenSentenceServingSituation",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "is_half_closed_sentence_serving_situation",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_half_closed_sentence_serving_situation ===
                                      true,
                                  },
                                  {
                                    name: "is_half_closed_sentence_serving_situation",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_half_closed_sentence_serving_situation ===
                                      false,
                                  },
                                ],
                                labelText: "Meio Fechado?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setIsHalfClosedSentenceServingSituation",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Situação Escolar",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "is_studying",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_studying === true,
                                  },
                                  {
                                    name: "is_studying",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_studying === false,
                                  },
                                ],
                                labelText: "Está estudando?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setIsStudying",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseTextArea", {
                              attrs: {
                                active: "",
                                inputName: "reason_for_not_studying",
                                labelText: "Razão para não estar estudando",
                                showLabel: "",
                                rows: "4",
                                value:
                                  _vm.psychosocial_accompaniment
                                    ?.reason_for_not_studying,
                                dispatch:
                                  "PsychosocialAccompaniments/setReasonForNotStudying",
                                readonly:
                                  _vm.psychosocial_accompaniment?.is_studying,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "last_grade_studied",
                                "input-type": "text",
                                value:
                                  _vm.psychosocial_accompaniment
                                    ?.last_grade_studied,
                                "show-label": "",
                                "label-text": "Última série estudada",
                                dispatch:
                                  "PsychosocialAccompaniments/setLastGradeStudied",
                                readonly:
                                  _vm.psychosocial_accompaniment?.is_studying,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "last_school_studied",
                                "input-type": "text",
                                value:
                                  _vm.psychosocial_accompaniment
                                    ?.last_school_studied,
                                "show-label": "",
                                "label-text": "Última escola em que estudou",
                                dispatch:
                                  "PsychosocialAccompaniments/setLastSchoolStudied",
                                readonly:
                                  _vm.psychosocial_accompaniment?.is_studying,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "need_school_reinsertion",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.need_school_reinsertion === true,
                                  },
                                  {
                                    name: "need_school_reinsertion",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.need_school_reinsertion === false,
                                  },
                                ],
                                labelText: "Necessita de reinserção escolar?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setNeedSchoolReinsertion",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Identidade de Gênero e Orientação Sexual",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Gênero",
                                "label-text": "Gênero",
                                "multiple-option": false,
                                value: _vm.gender,
                                options: _vm.genders,
                                dispatch: _vm.setGender,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder":
                                  "Se identifica como LGBTQIAPN+",
                                "label-text": "Se identifica como LGBTQIAPN+",
                                "multiple-option": false,
                                value: _vm.identifies_as_lgbtqiapn,
                                options: _vm.identifies_as_lgbtqiapns,
                                dispatch: _vm.setidentifiesAsLgbtqiapn,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseTextArea", {
                              attrs: {
                                active: "",
                                inputName: "gender_identity_observations",
                                labelText: "Observações",
                                showLabel: "",
                                rows: "4",
                                value:
                                  _vm.psychosocial_accompaniment
                                    ?.gender_identity_observations,
                                dispatch:
                                  "PsychosocialAccompaniments/setGenderIdentityObservations",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Situação de Saúde",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "is_pregnant",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_pregnant === true,
                                  },
                                  {
                                    name: "is_pregnant",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_pregnant === false,
                                  },
                                  // {
                                  //  name: 'is_pregnant',
                                  //  value: null,
                                  //  description: 'Não se aplica',
                                  //  checked: psychosocial_accompaniment?.is_pregnant === null,
                                  // },
                                ],
                                labelText: "Apresenta-se gestante?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setIsPregnant",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "gestational_age",
                                "input-type": "number",
                                value:
                                  _vm.psychosocial_accompaniment
                                    ?.gestational_age,
                                "show-label": "",
                                "label-text": "Idade Gestacional",
                                dispatch:
                                  "PsychosocialAccompaniments/setGestationalAge",
                                inputMin: "0",
                                readonly:
                                  !_vm.psychosocial_accompaniment?.is_pregnant,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "is_puerperium",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_puerperium === true,
                                  },
                                  {
                                    name: "is_puerperium",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.is_puerperium === false,
                                  },
                                  // {
                                  //  name: 'is_puerperium',
                                  //  value: null,
                                  //  description: 'Não se aplica',
                                  //  checked: psychosocial_accompaniment?.is_puerperium === null,
                                  // },
                                ],
                                labelText: "Período Puerpério",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setIsPuerperium",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "uses_psychoactive_substances",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.uses_psychoactive_substances === true,
                                  },
                                  {
                                    name: "uses_psychoactive_substances",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.uses_psychoactive_substances ===
                                      false,
                                  },
                                ],
                                labelText: "Faz uso de substâncias psicoativas",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setUsesPsychoactiveSubstances",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "uses_psychoactive_substances_recreationally",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.uses_psychoactive_substances_recreationally ===
                                      true,
                                  },
                                  {
                                    name: "uses_psychoactive_substances_recreationally",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.uses_psychoactive_substances_recreationally ===
                                      false,
                                  },
                                ],
                                labelText:
                                  "Faz uso recreativo de substâncias psicoativas",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setUsesPsychoactiveSubstancesRecreationally",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "self_medicate",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.self_medicate === true,
                                  },
                                  {
                                    name: "self_medicate",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.self_medicate === false,
                                  },
                                ],
                                labelText: "Se automedica",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setSelfMedicate",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "show_signs_of_self_harm",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.show_signs_of_self_harm === true,
                                  },
                                  {
                                    name: "show_signs_of_self_harm",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.show_signs_of_self_harm === false,
                                  },
                                ],
                                labelText:
                                  "Apresenta indícios de violência autoprovocada",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setShowSignsOfSelfHarm",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "show_signs_of_compromised_physical_health",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.show_signs_of_compromised_physical_health ===
                                      true,
                                  },
                                  {
                                    name: "show_signs_of_compromised_physical_health",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.show_signs_of_compromised_physical_health ===
                                      false,
                                  },
                                ],
                                labelText:
                                  "Apresenta indícios de saúde física comprometida",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setShowSignsOfCompromisedPhysicalHealth",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "show_signs_of_compromised_mental_health",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.show_signs_of_compromised_mental_health ===
                                      true,
                                  },
                                  {
                                    name: "show_signs_of_compromised_mental_health",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.show_signs_of_compromised_mental_health ===
                                      false,
                                  },
                                ],
                                labelText:
                                  "Apresenta indícios de saúde mental comprometida",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setShowSignsOfCompromisedMentalHealth",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "frequently_uses_health_network",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.frequently_uses_health_network ===
                                      true,
                                  },
                                  {
                                    name: "frequently_uses_health_network",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.frequently_uses_health_network ===
                                      false,
                                  },
                                ],
                                labelText:
                                  "Realiza com frequência atendimentos ou consultas na rede de saúde?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setFrequentlyUsesHealthNetwork",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "health_place_frequently",
                                "input-type": "text",
                                value:
                                  _vm.psychosocial_accompaniment
                                    ?.health_place_frequently,
                                "show-label": "",
                                "label-text": "Local de atendimento",
                                dispatch:
                                  "PsychosocialAccompaniments/setHealthPlaceFrequently",
                                readonly:
                                  !_vm.psychosocial_accompaniment
                                    ?.frequently_uses_health_network,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseTextArea", {
                              attrs: {
                                active: "",
                                inputName: "motivation_uses_health_frequently",
                                labelText: "Motivo do atendimento",
                                showLabel: "",
                                rows: "4",
                                value:
                                  _vm.psychosocial_accompaniment
                                    ?.motivation_uses_health_frequently,
                                dispatch:
                                  "PsychosocialAccompaniments/setMotivationUsesHealthFrquently",
                                readonly:
                                  !_vm.psychosocial_accompaniment
                                    ?.frequently_uses_health_network,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 mt-0" },
                          [
                            _c(
                              "div",
                              { staticClass: "flex" },
                              [
                                _c("BaseLabel", {
                                  staticClass: "flex items-end",
                                  attrs: { text: "Observações" },
                                }),
                                _c(
                                  "button",
                                  {
                                    ref: "btnHealthSituationObservationsRef",
                                    staticClass:
                                      "uppercase rounded outline-none ease-linear transition-all duration-150",
                                    on: {
                                      mouseenter: function ($event) {
                                        return _vm.toggleTooltip(
                                          "btnHealthSituationObservationsRef"
                                        )
                                      },
                                      mouseleave: function ($event) {
                                        return _vm.toggleTooltip(
                                          "btnHealthSituationObservationsRef"
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "material-icons mt-6 text-burnedYellow",
                                      },
                                      [
                                        _c(
                                          "span",
                                          { staticClass: "material-icons" },
                                          [_vm._v("info")]
                                        ),
                                      ]
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                ref: "tooltipHealthSituationObservationsRef",
                                staticClass:
                                  "bg-burnedYellow border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg",
                                class: {
                                  hidden:
                                    !_vm.tooltipHealthSituationObservations,
                                  block: _vm.tooltipHealthSituationObservations,
                                },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg",
                                    },
                                    [
                                      _vm._v(
                                        " Utilizar este espaço para detalhar a situação de saúde apreendida, se faz tratamento, uso de medicações, etc. "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c("BaseTextArea", {
                              attrs: {
                                active: "",
                                inputName: "health_situation_observations",
                                showLabel: "",
                                rows: "4",
                                value:
                                  _vm.psychosocial_accompaniment
                                    ?.health_situation_observations,
                                dispatch:
                                  "PsychosocialAccompaniments/setHealthSituationObservations",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Situação de Vulnerabilidade",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "register_vulnerability_matrix",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.register_vulnerability_matrix ===
                                      true,
                                  },
                                  {
                                    name: "register_vulnerability_matrix",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.register_vulnerability_matrix ===
                                      false,
                                  },
                                ],
                                labelText:
                                  "Cadastrar Matriz de Vulnerabilidade?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setRegisterVulnerabilityMatrix",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened &&
                    _vm.register_vulnerability_matrix &&
                    !_vm.matrix_risk_stratifications_by_psychosocial
                      ? _c(
                          "div",
                          [
                            _c("AppButtonColorful", {
                              attrs: {
                                text: "Abrir Matriz",
                                typeButton: "danger",
                                showIcon: "",
                                iconName: "add",
                                iconType: "outlined",
                                dispatch:
                                  "MatrixRiskStratifications/openEditModalMatrixRiskStratification",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened &&
                    _vm.register_vulnerability_matrix &&
                    _vm.matrix_risk_stratifications_by_psychosocial
                      ? _c(
                          "div",
                          [
                            _c("AppButtonColorful", {
                              attrs: {
                                text: "Editar Matriz",
                                typeButton: "danger",
                                showIcon: "",
                                iconName: "add",
                                iconType: "outlined",
                                dispatch:
                                  "MatrixRiskStratifications/openEditModalMatrixRiskStratification",
                                payload:
                                  _vm.matrix_risk_stratifications_by_psychosocial,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened &&
                    _vm.register_vulnerability_matrix &&
                    (_vm.matrix_situations_array.length > 0 ||
                      _vm.matrix_risk_stratifications_by_psychosocial)
                      ? _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text":
                                  "Situação de acordo com dados marcados até o momento",
                                text: _vm.getSituation(),
                                color: "carmesim",
                                "type-text": "custom",
                                weight: "semibold",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Encaminhamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "register_forwarding",
                                    value: true,
                                    description: "Sim",
                                    checked: _vm.register_forwarding === true,
                                  },
                                  {
                                    name: "register_forwarding",
                                    value: false,
                                    description: "Não",
                                    checked: _vm.register_forwarding === false,
                                  },
                                ],
                                labelText: "Cadastrar Encaminhamento?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setRegisterForwarding",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.register_forwarding
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _vm._l(
                              _vm.forwarding_attributes,
                              function (forwarding, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "grid grid-cols-2 gap-4 mt-2",
                                  },
                                  [
                                    _vm.psychosocial_accompaniment
                                      ?.meeting_happened
                                      ? _c(
                                          "div",
                                          [
                                            _c("Multiselect", {
                                              attrs: {
                                                "close-on-select": true,
                                                "show-label": "",
                                                "clear-on-select": "",
                                                "select-placeholder":
                                                  "Selecione as opções",
                                                "label-text":
                                                  "Políticas de Encaminhamento",
                                                "multiple-option": false,
                                                value:
                                                  _vm
                                                    .forwarding_policy_attributes[
                                                    index
                                                  ],
                                                options:
                                                  _vm.mountForwardingPolicies(
                                                    index
                                                  ),
                                                required: "",
                                                dispatch:
                                                  _vm.setForwardingPolicy,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.psychosocial_accompaniment
                                      ?.meeting_happened
                                      ? _c(
                                          "div",
                                          [
                                            _c("Multiselect", {
                                              attrs: {
                                                "close-on-select": true,
                                                "show-label": "",
                                                "clear-on-select": "",
                                                "select-placeholder":
                                                  "Selecione as opções",
                                                "label-text": "Equipamentos",
                                                "multiple-option": false,
                                                value:
                                                  _vm
                                                    .forwarding_facilities_attributes[
                                                    index
                                                  ],
                                                options:
                                                  _vm.mountForwardingFacilities(
                                                    index
                                                  ),
                                                required: "",
                                                dispatch:
                                                  _vm.setForwardingFacility,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.psychosocial_accompaniment
                                      ?.meeting_happened
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-2 text-burnedYellow-classic pb-2",
                                          },
                                          [
                                            _c("Label", {
                                              attrs: {
                                                text: "Informações de Data",
                                                icon: null,
                                                "icon-type": null,
                                                type: "text-xs",
                                                weight: "semibold",
                                                normal: "",
                                                mode: "uppercase",
                                              },
                                            }),
                                            _c("div", {
                                              staticClass:
                                                "border-ashes-hover border-b-2 w-full",
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.psychosocial_accompaniment
                                      ?.meeting_happened
                                      ? _c(
                                          "div",
                                          [
                                            _c("BaseInput", {
                                              attrs: {
                                                active: "",
                                                "input-name": "expedition",
                                                "input-type": "date",
                                                value:
                                                  _vm.forwarding_attributes[
                                                    index
                                                  ].forwarding_date,
                                                "show-label": "",
                                                required: "",
                                                "label-text":
                                                  "Data do Encaminhamento",
                                                payload: { index },
                                                dispatch:
                                                  "PsychosocialAccompaniments/setForwardingDate",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.psychosocial_accompaniment
                                      ?.meeting_happened
                                      ? _c(
                                          "div",
                                          [
                                            _c("BaseInput", {
                                              attrs: {
                                                active: "",
                                                "input-name": "expedition",
                                                "input-type": "date",
                                                value:
                                                  _vm.forwarding_attributes[
                                                    index
                                                  ].forwarding_expiration_date,
                                                "show-label": "",
                                                required: "",
                                                payload: { index },
                                                "label-text":
                                                  "Data de Expiração",
                                                dispatch:
                                                  "PsychosocialAccompaniments/setForwardingExpirationDate",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.psychosocial_accompaniment
                                      ?.meeting_happened
                                      ? _c(
                                          "div",
                                          [
                                            _c("BaseInput", {
                                              attrs: {
                                                active: "",
                                                "input-name": "dif",
                                                "input-type": "number",
                                                value:
                                                  _vm.forwarding_attributes[
                                                    index
                                                  ].differenceBetweenDates,
                                                payload: { index },
                                                "show-label": "",
                                                "label-text":
                                                  "Total de Dias entre as Datas",
                                                dispatch:
                                                  "PsychosocialAccompaniments/setForwardingDifferenceBetweenDates",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.psychosocial_accompaniment
                                      ?.meeting_happened
                                      ? _c(
                                          "div",
                                          { staticClass: "col-span-1" },
                                          [
                                            _c("AppTextField", {
                                              attrs: {
                                                "show-label": "",
                                                "label-text":
                                                  "Data nominal de Expiração",
                                                text: _vm.getFullDate(
                                                  _vm.forwarding_attributes[
                                                    index
                                                  ].forwarding_expiration_date
                                                ),
                                                color: "carmesim",
                                                "type-text": "custom",
                                                weight: "semibold",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.psychosocial_accompaniment
                                      ?.meeting_happened
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "col-span-2 text-burnedYellow-classic pb-2",
                                          },
                                          [
                                            _c("Label", {
                                              attrs: {
                                                text: "Descrição do Encaminhamento",
                                                icon: null,
                                                "icon-type": null,
                                                type: "text-xs",
                                                weight: "semibold",
                                                normal: "",
                                                mode: "uppercase",
                                              },
                                            }),
                                            _c("div", {
                                              staticClass:
                                                "border-ashes-hover border-b-2 w-full",
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.psychosocial_accompaniment
                                      ?.meeting_happened
                                      ? _c(
                                          "div",
                                          { staticClass: "col-span-2" },
                                          [
                                            _c("BaseTextArea", {
                                              attrs: {
                                                active: "",
                                                inputName: "description",
                                                labelText: "Descrição",
                                                showLabel: "",
                                                rows: "4",
                                                value:
                                                  _vm.forwarding_attributes[
                                                    index
                                                  ].forwarding_description,
                                                payload: { index },
                                                dispatch:
                                                  "PsychosocialAccompaniments/setForwardingDescription",
                                              },
                                            }),
                                            _vm.forwarding_attributes.length >
                                              1 &&
                                            _vm.register_forwarding &&
                                            _vm.psychosocial_accompaniment
                                              ?.meeting_happened
                                              ? _c(
                                                  "div",
                                                  { staticClass: "mt-5" },
                                                  [
                                                    _c(
                                                      "a",
                                                      {
                                                        staticClass:
                                                          "text-burnedYellow underline",
                                                        attrs: {
                                                          href: "javascript:void(0)",
                                                        },
                                                        on: {
                                                          click:
                                                            _vm.deleteForwarding,
                                                        },
                                                      },
                                                      [
                                                        _c("AppMaterialIcon", {
                                                          staticClass:
                                                            "align-middle",
                                                          attrs: {
                                                            "icon-name":
                                                              "delete",
                                                            "icon-size": "36",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            _vm.forwarding_attributes.length > 1 &&
                            _vm.register_forwarding &&
                            _vm.psychosocial_accompaniment?.meeting_happened
                              ? _c("div", { staticClass: "col-span-4 mt-5" }, [
                                  _c("hr", {
                                    staticClass:
                                      "border-t-0 border-b-2 border-ashes opacity-50",
                                  }),
                                ])
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.register_forwarding &&
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c("div", { staticClass: "mt-5" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-burnedYellow",
                              attrs: { href: "javascript:void(0)" },
                              on: { click: _vm.addForwarding },
                            },
                            [
                              _c("AppMaterialIcon", {
                                staticClass: "align-middle",
                                attrs: {
                                  "icon-name": "add_circle",
                                  "icon-size": "36",
                                },
                              }),
                              _c("BaseText", {
                                staticClass: "align-middle mr-2",
                                attrs: {
                                  text: "Adicionar Encaminhamento",
                                  "type-text": "custom",
                                  weight: "medium",
                                  size: "sm",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Plano de Acompanhamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "accompaniment_will_continue",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.accompaniment_will_continue === true,
                                  },
                                  {
                                    name: "accompaniment_will_continue",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.psychosocial_accompaniment
                                        ?.accompaniment_will_continue === false,
                                  },
                                ],
                                labelText: "Acompanhamento vai continuar?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setAccompanimentWillContinue",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened &&
                    _vm.psychosocial_accompaniment?.accompaniment_will_continue
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "birthdate",
                                "input-type": "date",
                                value:
                                  _vm.continued_accompaniment
                                    .date_accompaniment,
                                "show-label": "",
                                "label-text": "Data do Próximo Encontro",
                                required: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setDateAccompanimentContinuedAccompaniment",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment?.meeting_happened &&
                    _vm.psychosocial_accompaniment?.accompaniment_will_continue
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Tipo do Atendimento",
                                "label-text": "Tipo do Atendimento",
                                "multiple-option": false,
                                value:
                                  _vm.continued_accompaniment_selected
                                    .accompaniments_type,
                                options: _vm.type_accompaniment_select,
                                required: "",
                                dispatch:
                                  _vm.setPsychosocialAccompanimentsTypeContinuedAccompaniment,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Comentários e Conclusão do Encontro",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseTextArea", {
                          attrs: {
                            active: "",
                            inputName: "Situação apresentada",
                            labelText: "Situação apresentada",
                            showLabel: "",
                            rows: "4",
                            value:
                              _vm.psychosocial_accompaniment
                                ?.meeting_conclusion_description,
                            dispatch:
                              "PsychosocialAccompaniments/setMeetingConclusionDescription",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.psychosocial_accompaniment?.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseTextArea", {
                              attrs: {
                                active: "",
                                inputName: "accompaniment_comments",
                                labelText:
                                  "Observações técnicas sobre o\nacompanhamento",
                                showLabel: "",
                                rows: "4",
                                value:
                                  _vm.psychosocial_accompaniment
                                    ?.accompaniment_comments,
                                dispatch:
                                  "PsychosocialAccompaniments/setAccompanimentComments",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("ModalMatrixRiskStratifications", {
                    attrs: { open: _vm.modalToggleMatrixRiskStratifications },
                  }),
                  _c("SavePsychosocialAccompanimentWithoutMatrixConfirmation", {
                    attrs: {
                      open: _vm.modalConfirmSavePsychosocialAccompanimentWithoutMatrix,
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "PsychosocialAccompaniments/closeModalFirstPsychosocialAccompaniment",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.psychosocial_accompaniment?.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Salvar",
                                typeButton: "success",
                                dispatch: "PsychosocialAccompaniments/update",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "criar",
                                typeButton: "success",
                                dispatch: "PsychosocialAccompaniments/save",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1860179463
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }