var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.open
        ? _c("BaseModal", {
            attrs: { headerColor: "#fff" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "flex flex-row-reverse" }, [
                        _c(
                          "div",
                          {
                            staticClass:
                              "bg-white rounded-full w-7 h-7 justify-self-center",
                          },
                          [
                            _c("AppButtonIcon", {
                              attrs: {
                                "for-close": "",
                                dispatch: "Default/toggleModalPreInscription",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "container1" }, [
                        _c(
                          "div",
                          { staticClass: "container2" },
                          [
                            _c(
                              "div",
                              { staticClass: "m-5 my-0" },
                              [
                                _c("BaseText", {
                                  attrs: {
                                    text: "Cadastro de Aluno",
                                    "type-text": "custom",
                                    weight: "bold",
                                    mode: "capitalize",
                                    color: "burnedYellow",
                                    size: "3xl",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex justify-end items-center" },
                              [
                                _c("div", { staticClass: "mr-5" }, [
                                  _c(
                                    "a",
                                    {
                                      staticClass:
                                        "text-ashes-dark underline hover:text-burnedYellow",
                                      attrs: {
                                        href: "/docs/FICHA_DE_PRE_CADASTRO_ATUALIZADA_2024.pdf",
                                        target: "_blank",
                                      },
                                    },
                                    [
                                      _c("BaseText", {
                                        staticClass: "align-middle mr-2",
                                        attrs: {
                                          text: "Imprimir formulário em branco",
                                          "type-text": "custom",
                                          weight: "medium",
                                          size: "sm",
                                        },
                                      }),
                                      _c("AppMaterialIcon", {
                                        staticClass: "align-middle",
                                        attrs: { "icon-name": "print" },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            ),
                            _c("FormYoungStudent"),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "grid grid-cols-2 col-auto mx-5" },
                        [
                          _c(
                            "div",
                            { staticClass: "justify-self-start" },
                            [
                              _c("AppButtonEmpty", {
                                attrs: {
                                  text: "descartar",
                                  typeButton: "danger",
                                  dispatch: "Default/toggleModalPreInscription",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "justify-self-end" },
                            [
                              _c("AppButtonEmpty", {
                                attrs: {
                                  text: "Finalizar",
                                  typeButton: "success",
                                  dispatch: "Default/saveInscription",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3302355852
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }