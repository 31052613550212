var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.user.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar Usuário",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Adicionar Usuário",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: { dispatch: "User/closeModal", forClose: "" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações Gerais",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "name",
                            "input-name": "name",
                            "input-placeholder": "nome",
                            "input-type": "text",
                            "show-label": "",
                            "label-text": "nome",
                            required: "",
                            value: _vm.user.name,
                            "error-message": _vm.getErrorMessage(
                              "name",
                              _vm.user.name
                            ),
                            dispatch: "User/setName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-1" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            id: "areas",
                            "select-placeholder":
                              "Selecione o município(s) desejado",
                            "label-text": "Selecionar Município",
                            "close-on-select": false,
                            "show-label": "",
                            value: _vm.user.cities_attributes,
                            options: _vm.cities_all,
                            dispatch: _vm.setCitiesAttributes,
                            "error-message": _vm.getErrorMessage(
                              "cities",
                              _vm.user.cities_attributes
                            ),
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-1" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            id: "areas",
                            "select-placeholder": "Selecione as opções",
                            "label-text": _vm.getLabelArea(),
                            "close-on-select": false,
                            "show-label": "",
                            value: _vm.user.area,
                            options: _vm.areas,
                            dispatch: _vm.setAreas,
                            "error-message": _vm.getErrorMessage(
                              "areas",
                              _vm.user.areas_attributes
                            ),
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.user.roles_attributes[0]?.ability_profile_id === 7 ||
                    _vm.user.roles_attributes.id === 7
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("Multiselect", {
                              attrs: {
                                id: "equipments",
                                "select-placeholder": "Selecione as opções",
                                "label-text": _vm.getLabelEquipment(),
                                "close-on-select": false,
                                "show-label": "",
                                value: _vm.user.equipments_attributes,
                                options: _vm.equipments_by_area,
                                dispatch: _vm.setEquipmentsAttributes,
                                "error-message": _vm.getErrorMessage(
                                  "equipments",
                                  _vm.user.equipments_attributes
                                ),
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          staticClass: "mr-4",
                          attrs: {
                            id: "select-role",
                            "select-placeholder": "Perfil",
                            "label-text": "Perfil de Acesso",
                            "close-on-select": "",
                            required: "",
                            "show-label": "",
                            value: _vm.user.roles_attributes,
                            options: _vm.arrayProf,
                            dispatch: _vm.setRole,
                            "multiple-option": false,
                            "error-message": _vm.getErrorMessage(
                              "roles",
                              _vm.user
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.user.roles_attributes[0]?.ability_profile_id === 7 ||
                    _vm.user.roles_attributes.id === 7
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("Multiselect", {
                              attrs: {
                                id: "equipments",
                                "select-placeholder": "Selecione as opções",
                                "label-text": _vm.getLabelEquipment(),
                                "close-on-select": false,
                                "show-label": "",
                                value: _vm.user.equipments_attributes,
                                options: _vm.equipments_by_area,
                                dispatch: _vm.setEquipmentsAttributes,
                                "error-message": _vm.getErrorMessage(
                                  "equipments",
                                  _vm.user.equipments_attributes
                                ),
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "cpf",
                            "input-name": "cpf",
                            "input-placeholder": "000.000.000-00",
                            "input-type": "text",
                            "show-label": "",
                            "label-text": "cpf",
                            required: "",
                            mask: "###.###.###-##",
                            value: _vm.user.cpf,
                            "error-message": _vm.getErrorMessage(
                              "cpf",
                              _vm.user.cpf
                            ),
                            dispatch: "User/setCpf",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.user.roles_attributes[0]?.ability_profile_id === 7 ||
                    _vm.user.roles_attributes.id === 7
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                id: "cress_crp",
                                "input-name": "cress_crp",
                                "input-placeholder": "Informe o CRESS ou CRP",
                                "input-type": "text",
                                "show-label": "",
                                "label-text": "Código CRESS ou CRP",
                                required: "",
                                value: _vm.user.number_cress_crp,
                                "error-message": _vm.getErrorMessage(
                                  "number_cress_crp",
                                  _vm.user.number_cress_crp
                                ),
                                dispatch: "User/setNumberCressCrp",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "cellphone_number",
                            "input-name": "cellphone_number",
                            "input-placeholder": "(00) 00000-0000",
                            "input-type": "text",
                            "show-label": "",
                            "label-text": "telefone",
                            required: "",
                            mask: "(##) #####.####",
                            value: _vm.user.phone_number,
                            "error-message": _vm.getErrorMessage(
                              "phone_number",
                              _vm.user.phone_number
                            ),
                            dispatch: "User/setPhoneNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "email_user",
                            "input-name": "email_user",
                            "input-placeholder": "exemplo@email.com",
                            "input-type": "text",
                            "show-label": "",
                            "label-text": "E-mail",
                            required: "",
                            value: _vm.user.email,
                            "error-message": _vm.getErrorMessage(
                              "email",
                              _vm.user.email
                            ),
                            dispatch: "User/setEmail",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch: "User/closeModal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.user.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Atualizar",
                                typeButton: "success",
                                dispatch: "User/update",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "Criar",
                                typeButton: "success",
                                dispatch: "User/save",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3726411198
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }