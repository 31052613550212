<template>
  <div class="font-system" v-if="permit">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">{{ city.name }} | Parceiros</div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-partner"
          showIcon
          iconName="add"
          text="Cadastrar Parceiro"
          dispatch="Partners/openModalAddPartner"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div class="col-span-4">
          <BaseLabel
            text="Pesquisar por nome do Parceiro"
            typeText="custom"
            weight="normal"
            :italic="false"
            size="xs"
          />
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por nome"
            dispatch="Partners/setFilterName"
            :value="filter_params.name"
          />
        </div>
      </FilterComponent>
      <div class="mt-3">
        <a
          href="javascript:void(0)"
          @click="exportToExcel()"
          v-if="partners.length > 0 && permitExportSpreadsheet"
        >
          <img
            class="inline-block"
            src="@/assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
      </div>

      <BaseModal v-if="partners_spreadsheet.length > 0 && modalToggleExportPartnersSpreadsheet">
        <template v-slot:header>
          <div class="grid grid-cols-3">
            <div class="justify-self-start col-span-2">
              <Label
                text="Exportar Planilha de Parceiros"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon dispatch="Partners/closeExportPartnersSpreadsheetModal" forClose />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="Partners/closeExportPartnersSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data_partner"
                  :before-finish="closeExportPartnersSpreadsheetModal"
                  worksheet="Parceiros"
                  name="parceiros.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetPartnersResponse">
        <PreLoading />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-partners"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Partners/loadPartners"
          payload="load"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="partners.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-partners"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-partners">
      <div class="mx-12">
        <PartnersCards :data="partners" />
      </div>
      <div id="partners-pagination" class="mx-12 my-6 pb-3" v-if="partners.length > 0">
        <BasePagination
          :total="totalPartners"
          :total-per-page="totalPerPage"
          dispatch="Partners/setPartnersOffset"
        />
      </div>
      <ModalPartner :open="modalToggleAddPartner" />
      <DeletePartnerConfirmation :open="modalToggleDeletePartner" />
    </div>
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import PartnersCards from "../list/PartnersCards.vue";
import BasePagination from "../../components/BasePagination.vue";
import ModalPartner from "./modals/ModalPartner.vue";
import DeletePartnerConfirmation from "../alerts/DeletePartnerConfirmation.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseLabel from "../../components/BaseLabel.vue";
import ProhibitedAccessPage from "../../components/ProhibitedAccessPage.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import BaseText from "@/components/BaseText.vue";
import PreLoading from "@/components/PreLoading.vue";
import BaseModal from "@/components/BaseModal.vue";
import Label from "@/components/Label.vue";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";

export default {
  components: {
    AppButtonEmpty,
    Label,
    BaseModal,
    PreLoading,
    BaseText,
    AppButtonIcon,
    FilterComponent,
    BasePagination,
    BaseLabel,
    AppButtonColorful,
    PartnersCards,
    DeletePartnerConfirmation,
    BaseInput,
    ProhibitedAccessPage,
    ModalPartner,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
      permitExportSpreadsheet: false,
      json_fields: {
        Nome: "name",
        "Nº de Seleções Ofertadas": "total_opportunities_offered",
        "Nº Total de Vagas Ofertadas": "total_vacancies_offered",
        "Nº de Encaminhamentos Registrados": "total_registered_referrals",
        "Nº de Efetivados": "total_registered_referrals_made",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      closeExportPartnersSpreadsheetModal: "Partners/closeExportPartnersSpreadsheetModal",
      clearFields() {
        this.$store.dispatch("Partners/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    async exportToExcel() {
      await this.$store.dispatch("Partners/loadPartnersForExport");
    },
  },

  created() {
    this.canAcces("partner_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("partner_create").then((response) => {
      this.permitCreate = response;
    });
    this.canAcces("partner_export_spreadsheet").then((response) => {
      this.permitExportSpreadsheet = response;
    });
  },

  computed: {
    ...mapState({
      city: (state) => state.City.city,
      partners: (state) => state.Partners.partners,
      filter_params: (state) => state.Partners.params,
      modalToggleAddPartner: (state) => state.Partners.modalToggleAddPartner,
      modalToggleDeletePartner: (state) => state.Partners.modalToggleDeletePartner,
      offset: (state) => state.Partners.partnersOffset,
      totalPerPage: (state) => state.Partners.partnersTotalPerPage,
      totalPartners: (state) => state.Partners.partnersTotal,
      partners_spreadsheet: (state) => state.Partners.partners_spreadsheet,
      hasSpreadsheetPartnersResponse: (state) => state.Partners.hasSpreadsheetPartnersResponse,
      json_data_partner: (state) => state.Partners.json_data_partner,
      modalToggleExportPartnersSpreadsheet: (state) => state.Partners.modalToggleExportPartnersSpreadsheet,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Partners/loadPartners");
      this.backToTop();
    },
  },
};
</script>
