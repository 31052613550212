var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { attrs: { bg_color: _vm.getbgType } }, [
    _c("div", { class: _vm.gridClass, style: _vm.gridStyle }, [
      !_vm.togglePsychosocialAccompanimentUnaccompanied &&
      _vm.psychosocial_accompaniment.accompaniment_type.id !== 3
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.formattedDate(
                    _vm.psychosocial_accompaniment.created_at
                  ),
                  mode: "normal-case",
                  color: _vm.getColorText,
                  typeText: "custom",
                  weight: "bold",
                  size: "base",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.togglePsychosocialAccompanimentUnaccompanied &&
      _vm.psychosocial_accompaniment.accompaniment_type.id !== 3
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.formattedDate(
                    _vm.psychosocial_accompaniment.date_accompaniment
                  ),
                  mode: "normal-case",
                  color: _vm.getColorText,
                  typeText: "custom",
                  weight: "semibold",
                  size: "base",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.togglePsychosocialAccompanimentUnaccompanied &&
      _vm.psychosocial_accompaniment.accompaniment_type.id !== 3
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.formattedDate(
                    _vm.psychosocial_accompaniment.updated_at
                  ),
                  mode: "normal-case",
                  color: _vm.getColorText,
                  typeText: "custom",
                  weight: "semibold",
                  size: "base",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.togglePsychosocialAccompanimentUnaccompanied &&
      _vm.psychosocial_accompaniment.accompaniment_type.id !== 3
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.psychosocial_accompaniment.accompaniment_type.name,
                  mode: "normal-case",
                  color: _vm.getColorText,
                  typeText: "custom",
                  weight: "semibold",
                  size: "base",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.togglePsychosocialAccompanimentUnaccompanied &&
      _vm.psychosocial_accompaniment.accompaniment_type.id !== 3
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.psychosocial_accompaniment.accompaniment_status
                    .name,
                  color: _vm.getColorText,
                  typeText: "custom",
                  weight: "normal",
                  size: "base",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.togglePsychosocialAccompanimentUnaccompanied &&
      _vm.psychosocial_accompaniment.accompaniment_type.id !== 3
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.registeredForwarding(),
                  mode: "normal-case",
                  color: _vm.getColorText,
                  typeText: "custom",
                  weight: "normal",
                  size: "base",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.togglePsychosocialAccompanimentUnaccompanied &&
      _vm.psychosocial_accompaniment.accompaniment_type.id !== 3
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.hasSituation(),
                  color: _vm.getColorText,
                  typeText: "custom",
                  weight: "normal",
                  size: "base",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.togglePsychosocialAccompanimentUnaccompanied &&
      _vm.psychosocial_accompaniment.accompaniment_type.id !== 3
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.getSituation(),
                  color: _vm.getColorText,
                  typeText: "custom",
                  weight: "normal",
                  size: "base",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.togglePsychosocialAccompanimentUnaccompanied &&
      _vm.psychosocial_accompaniment.accompaniment_type.id !== 3
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.isOriginedFromAnotherAccompaniment(),
                  color: _vm.getColorText,
                  typeText: "custom",
                  weight: "normal",
                  size: "base",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.togglePsychosocialAccompanimentUnaccompanied &&
      _vm.psychosocial_accompaniment.accompaniment_type.id === 3
        ? _c(
            "div",
            { staticClass: "mt-4 ml-1" },
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.formattedDate(
                    _vm.psychosocial_accompaniment
                      ?.justification_for_non_acceptance_date
                  ),
                  color: _vm.getColorText,
                  typeText: "custom",
                  weight: "normal",
                  size: "base",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.togglePsychosocialAccompanimentUnaccompanied &&
      _vm.psychosocial_accompaniment.accompaniment_type.id === 3
        ? _c(
            "div",
            { staticClass: "mt-4" },
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.psychosocial_accompaniment
                    ?.justification_for_non_acceptance,
                  color: _vm.getColorText,
                  typeText: "custom",
                  weight: "normal",
                  size: "base",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mt-5 flex" },
        [
          _vm.psychosocial_accompaniment.accompaniment_status.name !==
            "Agendado" &&
          _vm.permitShow &&
          !_vm.togglePsychosocialAccompanimentUnaccompanied &&
          _vm.psychosocial_accompaniment.accompaniment_type.id !== 3
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "visibility",
                  dispatch: "PsychosocialAccompaniments/openViewModal",
                  payload: _vm.psychosocial_accompaniment,
                  iconColor: _vm.getIconColor,
                },
              })
            : _vm._e(),
          _vm.psychosocial_accompaniment.accompaniment_status.name ===
            "Agendado" && _vm.permitUp
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "restart_alt",
                  payload: _vm.psychosocial_accompaniment,
                  dispatch: _vm.getDispatchType,
                  iconColor: _vm.getIconColor,
                  title: "Realizar Atendimento",
                },
              })
            : _vm._e(),
          _vm.psychosocial_accompaniment.accompaniment_status.name ===
            "Agendado" && _vm.permitUp
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "update",
                  payload: _vm.psychosocial_accompaniment,
                  iconColor: _vm.getIconColor,
                  dispatch:
                    "PsychosocialAccompaniments/openModalPsychosocialAccompanimentPostponeDate",
                  title: "Reagendar Atendimento",
                },
              })
            : _vm._e(),
          _vm.permitUp
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "edit",
                  payload: _vm.psychosocial_accompaniment,
                  iconColor: _vm.getIconColor,
                  dispatch: _vm.getDispatchType,
                  title: "Editar Atendimento",
                },
              })
            : _vm._e(),
          _vm.permitDes
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "delete",
                  iconColor: _vm.getIconColor,
                  payload: _vm.psychosocial_accompaniment,
                  dispatch:
                    "PsychosocialAccompaniments/toggleModalDeletePsychosocialAccompaniment",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }