<template>
  <div class="font-system">
    <div id="title-page" class="mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl">{{ city.name }} | Técnicos</div>
    </div>
    <div id="tabs-button" class="flex justify-between mx-12 my-6">
      <div class="flex">
        <div class="w-104">
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por nome ou cpf"
            dispatch="Atendents/filterList"
          />
        </div>
      </div>
      <div class="float-right">
        <AppButtonColorful
          id="btn-add-atendent"
          showIcon
          iconName="add"
          text="Adicionar Técnico"
          dispatch="Atendents/openModalAtendent"
          typeButton="primary"
          v-if="permitCreate"
        />
      </div>
    </div>
    <div id="list-atendent">
      <div class="mx-12">
        <AtendentsCards :data="atendents" />
      </div>
      <ModalAtendent :open="modalAtendentOpen" />
      <ModalResetAtendentPassword :open="modalResetPassword" />
      <DeleteAtendentConfirmation :open="modalDeleteAtendentOpen" />
    </div>
  </div>
</template>

<script>
import AtendentsCards from "./list/AtendentsCards.vue";
import ModalAtendent from "./ModalAtendent.vue";
import ModalResetAtendentPassword from "./resets/ModalResetAtendentPassword.vue";
import DeleteAtendentConfirmation from "./alerts/DeleteAtendentConfirmation.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AppButtonColorful,
    AtendentsCards,
    ModalAtendent,
    ModalResetAtendentPassword,
    DeleteAtendentConfirmation,
    BaseInput,
  },
  data() {
    return {
      permitCreate: false,
      permit: false,
      permitUp: false,
    };
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
  },

  created() {
    // this.$store.dispatch("Atendents/loadAtendents", city);
    // this.$store.dispatch("Teacher/loadProfiles");
    this.$store.dispatch("Atendents/loadAtendents");
    this.canAcces("psychosocial_attendant_create").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("psychosocial_attendant_create").then((resp) => {
      this.permitCreate = resp;
    });
    this.canAcces("psychosocial_attendant_index").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("psychosocial_attendant_update").then((resp) => {
      this.permitUp = resp;
    });
  },

  computed: {
    ...mapState({
      atendents: (state) => state.Atendents.filterAtendents,
      modalDeleteAtendentOpen: (state) => state.Atendents.modalDeleteAtendentConfirmationOpen,
      modalAtendentOpen: (state) => state.Atendents.modalAtendentOpen,
      modalResetPassword: (state) => state.Atendents.modalResetPassword,
      city: (state) => state.City.city,
    }),
  },
};
</script>
