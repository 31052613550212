import Vue from "vue";
import { getEquipmentByCityId, destroyEquipment } from "../../service/EquipmentApi";

const state = {
  equipmentAll: [],
  filteredEquipmentAll: [],
  filters: {
    area: null,
    neighborhood: null,
  },
};

const mutations = {
  setEquipmentMutation: (state, equipment) => {
    state.filteredEquipmentAll = equipment;
    state.equipmentAll = equipment;
  },
  setAreaFilterMutation: (state, area) => {
    state.filters.area = area;
  },
  setNeighborhoodFilterMutation: (state, neighborhood) => {
    state.filters.neighborhood = neighborhood;
  },
  setFilteredEquipmentMutation: (state, equipment) => {
    state.filteredEquipmentAll = equipment;
  },
  addEquipmentMutation: (state, equipment) => {
    state.filteredEquipmentAll.push(equipment);
  },
  clearFiltersMutation: (state) => {
    state.filters.area = null;
    state.filters.neighborhood = null;
    state.filteredEquipmentAll = Object.assign(state.equipmentAll, state.filteredEquipmentAll);
  },
};

const actions = {
  loadEquipment: ({ commit, rootState }) => {
    let id = rootState.City.city.id;
    getEquipmentByCityId(id)
      .then((response) => {
        commit("setEquipmentMutation", response.data.equipment);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  addEquipment: ({ commit }, payload) => {
    commit("addEquipmentMutation", payload);
  },
  filterNeighborhood: ({ commit, dispatch }, neighborhood) => {
    commit("setNeighborhoodFilterMutation", neighborhood);
    dispatch("filterList");
  },
  filterArea: ({ commit, dispatch }, area) => {
    commit("setAreaFilterMutation", area);
    dispatch("filterList");
  },
  filterList: ({ commit, state }) => {
    const neighborhood = state.filters.neighborhood;
    const filteredByNeighborhood = neighborhood
      ? state.equipmentAll.filter((obj) => {
          return obj.neighborhood_id == neighborhood.id;
        })
      : state.equipmentAll;

    const area = state.filters.area;

    const filteredByArea = area
      ? filteredByNeighborhood.filter((obj) => {
          return obj.areas_attributes.some((el) => el.id == area.id);
        })
      : filteredByNeighborhood;

    commit("setFilteredEquipmentMutation", filteredByArea);
  },
  clearFilters: ({ commit }) => {
    commit("clearFiltersMutation");
  },
  openCloseDeleteEquipmentConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteEquipmentConfirmation");
    if (state.modalDeleteEquipmentConfirmationOpen) {
      commit("equipmentForDeletion", payload.id);
    } else {
      commit("equipmentForDeletion", null);
    }
  },
  deleteEquipment: ({ commit, state }) => {
    const equipment_id = state.equipmentIdForDeletion;

    destroyEquipment(equipment_id)
      .then((response) => {
        const equipment = state.equipmentAll.filter((e) => {
          return e.id !== equipment_id;
        });
        commit("loadEquipment", equipment);
        Vue.$toast.success(response.data.message);
        commit("toggleModalDeleteEquipmentConfirmation");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.errors);
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
