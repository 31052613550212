var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", [
    _c(
      "div",
      { staticClass: "grid grid-cols-custom items-center container1" },
      [
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.formattedDate(
                  _vm.matrix_risk_stratification_by_inscription
                    .date_accompaniment
                ),
                mode: "normal-case",
                color: "quantum",
                typeText: "custom",
                weight: "bold",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.getSituation("situation"),
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.getSituation("impact"),
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 flex" },
          [
            _vm.permitShow
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "visibility",
                    dispatch:
                      "MatrixRiskStratifications/openModalViewMatrixRiskStratification",
                    payload: _vm.matrix_risk_stratification_by_inscription,
                    title: "Visualizar informações",
                  },
                })
              : _vm._e(),
            _vm.permitUp
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "edit",
                    payload: _vm.matrix_risk_stratification_by_inscription,
                    dispatch:
                      "MatrixRiskStratifications/openEditModalMatrixRiskStratification",
                    title: "Atualizar Matriz",
                  },
                })
              : _vm._e(),
            _vm.permitDes
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "delete",
                    payload: _vm.matrix_risk_stratification_by_inscription,
                    dispatch:
                      "MatrixRiskStratifications/toggleModalDeleteMatrixRiskStratification",
                    title: "Excluir Matriz",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }