var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { attrs: { bg_color: _vm.getColors("bg_color") } }, [
    _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.forwarding.inscription.student.name,
              mode: "normal-case",
              color: _vm.getColors("color_text"),
              typeText: "custom",
              weight: "bold",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.forwarding.forwarding_user.name,
              mode: "normal-case",
              color: _vm.getColors("color_text"),
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.forwarding.inscription.season.name,
              mode: "normal-case",
              color: _vm.getColors("color_text"),
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.forwarding?.city?.name,
              mode: "normal-case",
              color: _vm.getColors("color_text"),
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.formattedBirthdate(_vm.forwarding.forwarding_date),
              mode: "normal-case",
              color: _vm.getColors("color_text"),
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.formattedBirthdate(
                _vm.forwarding.forwarding_expiration_date
              ),
              mode: "normal-case",
              color: _vm.getColors("color_text"),
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.forwarding.forwarding_policy.name,
              mode: "normal-case",
              color: _vm.getColors("color_text"),
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.forwarding.forwarding_status.name,
              mode: "normal-case",
              color: _vm.getColors("color_text"),
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4 flex" },
        [
          _vm.permitShow
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "visibility",
                  dispatch: "Forwardings/openViewModal",
                  payload: _vm.forwarding,
                  iconColor: _vm.getColors("icon_color"),
                  hover: _vm.getColors("hover_color"),
                  title: "Visualizar informações do encaminhamento",
                },
              })
            : _vm._e(),
          _vm.permitUp &&
          _vm.forwarding.forwarding_status.name === "Encaminhado"
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "restart_alt",
                  payload: _vm.forwarding,
                  iconColor: _vm.getColors("icon_color"),
                  hover: _vm.getColors("hover_color"),
                  dispatch: "Forwardings/openReturnModal",
                  title: "Realizar retorno do encaminhamento",
                },
              })
            : _vm._e(),
          _vm.permitUp &&
          _vm.forwarding.forwarding_status.name === "Encaminhado"
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "update",
                  payload: _vm.forwarding,
                  iconColor: _vm.getColors("icon_color"),
                  hover: _vm.getColors("hover_color"),
                  dispatch:
                    "Forwardings/openModalForwardingPostponeExpirationDate",
                  title: "Realizar Postergação de Data de Expiração",
                },
              })
            : _vm._e(),
          _vm.permitDes
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "delete",
                  payload: _vm.forwarding,
                  iconColor: _vm.getColors("icon_color"),
                  hover: _vm.getColors("hover_color"),
                  dispatch: "Forwardings/toggleModalDeleteForwarding",
                  title: "Deletar Encaminhamento",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }