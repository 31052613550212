var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Área",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Equipamento",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Município",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Bairro",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Nome",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Idade",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Escolaridade",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Curso de Qualificação",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Encaminhado?",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Tem currículo?",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "mt-4 flex" }),
      ]),
      _c(
        "BaseStaggeredFade",
        { attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.data, function (profile_search, index) {
          return _c(
            "li",
            { key: profile_search.id, attrs: { "data-index": index } },
            [
              _c(
                "div",
                [
                  _c("AppCardProfileSearch", {
                    attrs: { profile_search: profile_search },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "border-ashes w-full border" }),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }