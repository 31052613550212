var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid auto-grid-auto p-10 gap-5" },
    [
      _c(
        "div",
        [
          _c("BaseText", {
            attrs: {
              text: `Turma ${_vm.team.code}`,
              typeText: "custom",
              weight: "semibold",
              italic: false,
              size: "3xl",
              color: "ashes-hover",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c(
            "router-link",
            { attrs: { to: _vm.returnBtn() } },
            [
              _c("BaseText", {
                attrs: {
                  text: "<- Voltar",
                  typeText: "custom",
                  weight: "medium",
                  italic: false,
                  mode: "capitalize",
                  size: "sm",
                  color: "carmesim",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "grid grid-cols-2" }, [
        _c("div", { staticClass: "grid grid-flow-col auto-cols-max gap-2" }, [
          _c("div", { staticClass: "place-self-center" }, [
            _c(
              "div",
              { class: _vm.iconClass },
              [
                _c("AppMaterialIcon", {
                  staticClass: "place-self-center text-white",
                  attrs: {
                    iconName: "book",
                    iconType: "outlined",
                    iconSize: "36",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "gridf grid-rols-4" }, [
            _c(
              "div",
              [
                _c("BaseText", {
                  attrs: {
                    text:
                      _vm.team.discipline.phase_id == 2
                        ? _vm.team.discipline.name +
                          " - " +
                          _vm.team.course.name
                        : _vm.team.discipline.name,
                    typeText: "custom",
                    weight: "extrabold",
                    italic: false,
                    size: "xl",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "grid grid-flow-col auto-cols-max gap-2" },
              [
                _c(
                  "div",
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Instrutor |",
                        typeText: "custom",
                        weight: "medium",
                        italic: false,
                        size: "sm",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-burnedYellow" },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: _vm.getTeacherName(),
                        typeText: "custom",
                        weight: "bold",
                        italic: false,
                        size: "sm",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "grid grid-flow-col auto-cols-max gap-2" },
              [
                _c(
                  "div",
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Equipamento |",
                        typeText: "custom",
                        weight: "medium",
                        italic: false,
                        size: "sm",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-burnedYellow" },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: _vm.getEquipmentName(),
                        typeText: "custom",
                        weight: "bold",
                        italic: false,
                        size: "sm",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "grid grid-flow-col auto-cols-max gap-2" },
              [
                _c(
                  "div",
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Turno |",
                        typeText: "custom",
                        weight: "medium",
                        italic: false,
                        size: "sm",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "text-burnedYellow" },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: _vm.team.shift.name,
                        typeText: "custom",
                        weight: "bold",
                        italic: false,
                        size: "sm",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass:
              "grid grid-flow-col auto-cols-max gap-2 place-items-center",
          },
          [
            _c(
              "div",
              [
                _c("BaseInput", {
                  attrs: {
                    active: false,
                    inputName: "nome",
                    inputType: "date",
                    required: false,
                    labelText: "Início",
                    showLabel: "",
                    value: _vm.formatDate(_vm.team.start),
                    showIcon: false,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("BaseInput", {
                  attrs: {
                    active: false,
                    inputName: "nome",
                    inputType: "date",
                    required: false,
                    labelText: "Encerramento",
                    showLabel: "",
                    value: _vm.formatDate(_vm.team.closing),
                    showIcon: false,
                  },
                }),
              ],
              1
            ),
            _vm.permit && _vm.season_date_manual_execution_end
              ? _c(
                  "div",
                  { staticClass: "mt-5" },
                  [
                    _vm.showButtonNewTeam(_vm.discipline)
                      ? _c("AppButtonEmpty", {
                          attrs: {
                            text: " >> Ação Comunitária",
                            typeButton: "primary",
                            dispatch: "TeamAllocate/openModal",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
            _vm.team.discipline.id == 1 && _vm.season_date_manual_execution_end
              ? _c("div", { staticClass: "flex flex-wrap" }, [
                  _vm.permit
                    ? _c("div", { staticClass: "w-full" }, [
                        _c(
                          "button",
                          {
                            ref: "btnRef",
                            staticClass:
                              "uppercase rounded outline-none ease-linear transition-all duration-150",
                            on: {
                              mouseenter: function ($event) {
                                return _vm.toggleTooltip()
                              },
                              mouseleave: function ($event) {
                                return _vm.toggleTooltip()
                              },
                            },
                          },
                          [_vm._m(0)]
                        ),
                        _c(
                          "div",
                          {
                            ref: "tooltipRef",
                            staticClass:
                              "bg-burnedYellow border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg",
                            class: {
                              hidden: !_vm.tooltipShow,
                              block: _vm.tooltipShow,
                            },
                          },
                          [_vm._m(1)]
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
            _c(
              "div",
              { staticClass: "mt-5" },
              [
                _vm.showButtonFinishTeam(_vm.discipline) &&
                _vm.team.in_progress &&
                _vm.permitFinishTeam
                  ? _c("AppButtonEmpty", {
                      attrs: {
                        text: "Encerrar",
                        typeButton: "primary",
                        dispatch: "Team/finishTeam",
                      },
                    })
                  : _vm._e(),
                !_vm.team.in_progress && _vm.permitReactivateTeam
                  ? _c("AppButtonEmpty", {
                      attrs: {
                        text: "Reativar",
                        typeButton: "primary",
                        dispatch: "Team/reactivateTeam",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "grid grid-flow-col auto-cols-max" }, [
        _c("div", [
          _c(
            "a",
            {
              staticClass: "cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.activeTab("meeting")
                },
              },
            },
            [
              _c("BaseText", {
                attrs: {
                  text: "Encontros",
                  typeText: "custom",
                  weight: "medium",
                  italic: false,
                  mode: "uppercase",
                  size: "lg",
                  color: _vm.colorSubmenu("meeting"),
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "border-l mx-10" }),
        _c("div", [
          _c(
            "a",
            {
              staticClass: "cursor-pointer",
              on: {
                click: function ($event) {
                  return _vm.activeTab("student")
                },
              },
            },
            [
              _c("BaseText", {
                attrs: {
                  text: "Alunos",
                  typeText: "custom",
                  weight: "medium",
                  italic: false,
                  mode: "uppercase",
                  size: "lg",
                  color: _vm.colorSubmenu("student"),
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "border-l mx-10" }),
      ]),
      _vm.page === "meeting" ? _c("Meetings") : _c("Allocations"),
      _c("ModalTeamAllocate", { attrs: { open: _vm.modalTeamAllocateOpen } }),
      _c("SuspendMeetingConfirmation", {
        attrs: { open: _vm.modalSuspendMeetingConfirmationOpen },
      }),
      _c("DisableSuspensionMeetingConfirmation", {
        attrs: { open: _vm.modalDisableSuspensionMeetingConfirmationOpen },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "material-icons mt-6 text-burnedYellow" },
      [_c("span", { staticClass: "material-icons" }, [_vm._v(" info ")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "div",
        {
          staticClass:
            "text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg",
        },
        [
          _vm._v(
            " Ao clicar em “>> Ação Comunitária“ você encerrará esta turma de Formação Cidadã e a transformará em uma turma de Ação Comunitária. Todos os alunos desta turma de Formação Cidadã irão se manter na turma de Ação Comunitária.. "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }