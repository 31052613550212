import {
  getOpportunities,
  getOpportunityById,
  createOpportunity,
  updateOpportunity,
  destroyOpportunity,
  getOpportunityContractingTypes,
  getOpportunitiesForExport,
} from "../../service/OpportunitiesApi";
import { getCompaniesForMenus } from "../../service/CompaniesApi";
import { getPartnersForMenus } from "../../service/PartnersApi";
import { getSegmentsForMenus } from "../../service/SegmentsApi";
import { getOccupationsForMenus } from "../../service/OccupationsApi";
import moment from "moment";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

const state = {
  opportunity: {
    id: null,
    name: null,
    opportunity_date: null,
    city_id: localStorage.city_id,
    company_id: null,
    partner_id: null,
    segment_id: null,
    occupation_id: null,
    contracting_type: null,
    vacancy_quantity: null,
  },

  opportunities: [],
  companies: [],
  partners: [],
  segments: [],
  occupations: [],
  contracting_types: [],
  opportunities_spreadsheet: [],

  errorMessages: [],
  opportunityId: null,
  city: null,
  company: null,
  partner: null,
  segment: null,
  occupation: null,
  contracting_type: null,

  modalToggleAddOpportunity: false,
  modalToggleDeleteOpportunity: false,
  modalToggleViewOpportunity: false,
  modalToggleExportOpportunitiesSpreadsheet: false,

  hasSpreadsheetOpportunitiesResponse: true,
  json_data_opportunity: null,

  opportunitiesTotal: 0,
  opportunitiesTotalPerPage: 10,
  opportunitiesOffset: 0,

  params: {
    name: null,
    company: null,
    partner: null,
    segment: null,
    occupation: null,
    contracting_type: null,
    initial_date: null,
    final_date: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadOpportunities: (state, opportunities) => {
    state.opportunities = opportunities;
  },
  loadCompanies: (state, companies) => {
    state.companies = companies;
  },
  loadPartners: (state, partners) => {
    state.partners = partners;
  },
  loadSegments: (state, segments) => {
    state.segments = segments;
  },
  loadOccupations: (state, occupations) => {
    state.occupations = occupations;
  },
  loadContractingTypes: (state, contracting_types) => {
    state.contracting_types = contracting_types;
  },

  // MUTATIONS - FIELDS TO/FROM API
  setIdMutation: (state, id) => {
    state.opportunity.id = id;
  },
  setNameMutation: (state, name) => {
    state.opportunity.name = name;
  },
  setOpportunityDateMutation: (state, opportunity_date) => {
    state.opportunity.opportunity_date = opportunity_date;
  },
  setCityMutation: (state, city) => {
    state.city = city;
    state.opportunity.city_id = city ? city.id : null;
  },
  setCompanyMutation: (state, company) => {
    state.company = company;
    state.opportunity.company_id = company ? company.id : null;
  },
  setPartnerMutation: (state, partner) => {
    state.partner = partner;
    state.opportunity.partner_id = partner ? partner.id : null;
  },
  setSegmentMutation: (state, segment) => {
    state.segment = segment;
    state.opportunity.segment_id = segment ? segment.id : null;
  },
  setOccupationMutation: (state, occupation) => {
    state.occupation = occupation;
    state.opportunity.occupation_id = occupation ? occupation.id : null;
  },
  setContractingTypeMutation: (state, contracting_type) => {
    state.contracting_type = contracting_type;
    state.opportunity.contracting_type = contracting_type ? contracting_type.id : null;
  },
  setVacancyQuantityMutation: (state, vacancy_quantity) => {
    state.opportunity.vacancy_quantity = vacancy_quantity;
  },

  // --> MUTATIONS - DELETION <--
  opportunityForDeletion: (state, payload) => {
    if (payload) {
      state.opportunityId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterNameMutation: (state, name) => {
    state.params.name = name;
  },
  setFilterCompanyMutation: (state, company) => {
    state.params.company = company;
  },
  setFilterPartnerMutation: (state, partner) => {
    state.params.partner = partner;
  },
  setFilterSegmentMutation: (state, segment) => {
    state.params.segment = segment;
  },
  setFilterOccupationMutation: (state, occupation) => {
    state.params.occupation = occupation;
  },
  setFilterContractingTypeMutation: (state, contracting_type) => {
    state.params.contracting_type = contracting_type;
  },
  setFilterInitialDateMutation: (state, initial_date) => {
    state.params.initial_date = initial_date;
  },
  setFilterFinalDateMutation: (state, final_date) => {
    state.params.final_date = final_date;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddOpportunity: (state) => {
    state.modalToggleAddOpportunity = !state.modalToggleAddOpportunity;
  },
  toggleModalDeleteOpportunity: (state) => {
    state.modalToggleDeleteOpportunity = !state.modalToggleDeleteOpportunity;
  },
  toggleModalViewOpportunity: (state) => {
    state.modalToggleViewOpportunity = !state.modalToggleViewOpportunity;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportOpportunitiesSpreadsheet: (state) => {
    state.modalToggleExportOpportunitiesSpreadsheet = !state.modalToggleExportOpportunitiesSpreadsheet;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetOpportunitiesResponseMutation: (state, hasSpreadsheetOpportunitiesResponse) => {
    state.hasSpreadsheetOpportunitiesResponse = hasSpreadsheetOpportunitiesResponse;
  },
  setOpportunitiesPageToExportMutation: (state, opportunity) => {
    state.json_data_opportunity = null;

    const arrayData = [];
    opportunity.forEach((element, index, array) => {
      let company = array[index].company ? array[index].company.name : "-";
      let contracting_type = array[index].contracting_type ? array[index].contracting_type.name : "-";
      let name = array[index].name;
      let occupation = array[index].occupation ? array[index].occupation.name : null;
      let partner = array[index].partner ? array[index].partner.name : null;
      let segment = array[index].segment ? array[index].segment.name : null;
      let vacancy_quantity = array[index].vacancy_quantity === "0" ? 0 : array[index].vacancy_quantity;
      let opportunity_date = array[index].opportunity_date
        ? moment(array[index].opportunity_date).format("DD/MM/YYYY")
        : "-";

      const arrayItem = [];
      arrayItem["opportunity_date"] = opportunity_date;
      arrayItem["name"] = name;
      arrayItem["company"] = company;
      arrayItem["partner"] = partner;
      arrayItem["segment"] = segment;
      arrayItem["occupation"] = occupation;
      arrayItem["contracting_type"] = contracting_type;
      arrayItem["vacancy_quantity"] = vacancy_quantity;
      arrayData.push(arrayItem);
    });
    state.json_data_opportunity = arrayData;
    state.hasSpreadsheetOpportunitiesResponse = true;
    state.modalToggleExportOpportunitiesSpreadsheet = true;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setOpportunitiesTotalPerPage: (state, opportunitiesTotalPerPage) => {
    state.opportunitiesTotalPerPage = opportunitiesTotalPerPage;
  },
  setOpportunitiesOffset: (state, opportunitiesOffset) => {
    state.opportunitiesOffset = opportunitiesOffset;
  },
  setOpportunitiesTotal: (state, opportunitiesTotal) => {
    state.opportunitiesTotal = opportunitiesTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.opportunity) {
      state.opportunity[prop] = null;
    }
    state.opportunity.city_id = localStorage.city_id;
    state.company = null;
    state.partner = null;
    state.segment = null;
    state.occupation = null;
    state.contracting_type = null;
  },
  clearQuery: (state) => {
    state.params.name = null;
    state.params.company = null;
    state.params.partner = null;
    state.params.segment = null;
    state.params.occupation = null;
    state.params.contracting_type = null;
    state.params.initial_date = null;
    state.params.final_date = null;
    state.opportunities = [];
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setOpportunitiesSpreadsheetMutation: (state, opportunities_spreadsheet) => {
    state.opportunities_spreadsheet = opportunities_spreadsheet;
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

function getFilterParams() {
  const params = {
    name: state.params.name,
    city_id: localStorage.city_id,
    company_id: state.params.company ? state.params.company.id : null,
    partner_id: state.params.partner ? state.params.partner.id : null,
    segment_id: state.params.segment ? state.params.segment.id : null,
    occupation_id: state.params.occupation ? state.params.occupation.id : null,
    contracting_type: state.params.contracting_type ? state.params.contracting_type.id : null,
    initial_date: state.params.initial_date,
    final_date: state.params.final_date,
    per_page: state.opportunitiesTotalPerPage,
    offset: state.opportunitiesOffset,
  };
  return params;
}

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadOpportunities: ({ commit }, from) => {
    const params = getFilterParams();
    getOpportunities(params)
      .then((response) => {
        commit("loadOpportunities", response.data.opportunities);
        commit("setOpportunitiesTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadOpportunitiesForExport: ({ commit }) => {
    commit("hasSpreadsheetOpportunitiesResponseMutation", false);
    const params = getFilterParams();
    getOpportunitiesForExport(params)
      .then(async (response) => {
        commit("setOpportunitiesSpreadsheetMutation", response.data.opportunities);
        if (state.opportunities_spreadsheet.length > 0) {
          await commit("setOpportunitiesPageToExportMutation", response.data.opportunities);
        } else {
          commit("hasSpreadsheetOpportunitiesResponseMutation", true);
          commit("toggleModalToggleExportOpportunitiesSpreadsheet");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadCompanies: ({ commit }, value) => {
    const params = {
      name: value,
      city_id: localStorage.city_id,
      per_page: 10,
    };
    getCompaniesForMenus(params)
      .then((response) => {
        commit("loadCompanies", response.data.companies);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadPartners: ({ commit }, value) => {
    const params = {
      name: value,
      city_id: localStorage.city_id,
      per_page: 10,
    };
    getPartnersForMenus(params)
      .then((response) => {
        commit("loadPartners", response.data.partners);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadSegments: ({ commit }, value) => {
    const params = {
      name: value,
      per_page: 10,
    };
    getSegmentsForMenus(params)
      .then((response) => {
        commit("loadSegments", response.data.segments);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadOccupations: ({ commit }, value) => {
    const params = {
      name: value,
      per_page: 10,
    };
    getOccupationsForMenus(params)
      .then((response) => {
        commit("loadOccupations", response.data.occupations);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadContractingTypes: ({ commit }) => {
    getOpportunityContractingTypes()
      .then((response) => {
        commit("loadContractingTypes", response.data.contracting_type);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getOpportunityById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.opportunities.id);
        commit("setNameMutation", response.data.opportunities.name);
        commit("setOpportunityDateMutation", response.data.opportunities.opportunity_date);
        commit("setCityMutation", response.data.opportunities.city);
        commit("setCompanyMutation", response.data.opportunities.company);
        commit("setPartnerMutation", response.data.opportunities.partner);
        commit("setSegmentMutation", response.data.opportunities.segment);
        commit("setOccupationMutation", response.data.opportunities.occupation);
        commit("setContractingTypeMutation", response.data.opportunities.contracting_type);
        commit("setVacancyQuantityMutation", response.data.opportunities.vacancy_quantity);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setOpportunityDate: ({ commit }, payload) => {
    commit("setOpportunityDateMutation", payload.value);
  },
  setCompany: ({ commit }, payload) => {
    commit("setCompanyMutation", payload);
  },
  setPartner: ({ commit }, payload) => {
    commit("setPartnerMutation", payload);
  },
  setSegment: ({ commit }, payload) => {
    commit("setSegmentMutation", payload);
  },
  setOccupation: ({ commit }, payload) => {
    commit("setOccupationMutation", payload);
  },
  setContractingType: ({ commit }, payload) => {
    commit("setContractingTypeMutation", payload);
  },
  setVacancyQuantity: ({ commit }, payload) => {
    commit("setVacancyQuantityMutation", payload.value);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddOpportunity: async ({ commit }) => {
    commit("toggleModalAddOpportunity");
  },
  closeModalAddOpportunity: ({ commit }) => {
    commit("toggleModalAddOpportunity");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openEditModalAddOpportunity: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddOpportunity");
  },
  closeEditModalAddOpportunity: ({ commit }) => {
    commit("toggleModalAddOpportunity");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewOpportunity");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewOpportunity");
    commit("clearFields");
  },
  toggleModalDeleteOpportunity: ({ commit }, payload) => {
    commit("toggleModalDeleteOpportunity");
    if (state.modalToggleDeleteOpportunity && payload) {
      commit("opportunityForDeletion", payload);
    } else {
      commit("opportunityForDeletion", null);
    }
  },

  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportOpportunitiesSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportOpportunitiesSpreadsheet");
  },
  closeExportOpportunitiesSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportOpportunitiesSpreadsheet");
  },

  // --> ACTIONS - FILTERLIST
  setFilterName: ({ commit }, payload) => {
    commit("setFilterNameMutation", payload.value);
  },
  setFilterCompany: ({ commit }, payload) => {
    commit("setFilterCompanyMutation", payload);
  },
  setFilterPartner: ({ commit }, payload) => {
    commit("setFilterPartnerMutation", payload);
  },
  setFilterSegment: ({ commit }, payload) => {
    commit("setFilterSegmentMutation", payload);
  },
  setFilterOccupation: ({ commit }, payload) => {
    commit("setFilterOccupationMutation", payload);
  },
  setFilterContractingType: ({ commit }, payload) => {
    commit("setFilterContractingTypeMutation", payload);
  },
  setFilterInitialDate: ({ commit }, payload) => {
    commit("setFilterInitialDateMutation", payload.value);
  },
  setFilterFinalDate: ({ commit }, payload) => {
    commit("setFilterFinalDateMutation", payload.value);
  },

  // --> ACTIONS - PAGINATIONS <--
  setOpportunitiesTotalPerPage: ({ commit }, payload) => {
    commit("setOpportunitiesTotalPerPage", payload.totalPerPage);
  },
  setOpportunitiesOffset: ({ commit }, payload) => {
    commit("setOpportunitiesOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },

  //ACTIONS - CRUD
  save: ({ commit, dispatch }) => {
    createOpportunity(state.opportunity)
      .then(() => {
        Vue.$toast.success("Seleção cadastrada com sucesso!");
        dispatch("closeModalAddOpportunity");
        dispatch("loadOpportunities");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar a seleção! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  update: ({ commit, dispatch, state }) => {
    updateOpportunity(state.opportunity.id, state.opportunity)
      .then(() => {
        dispatch("loadOpportunities");
        dispatch("closeEditModalAddOpportunity");
        Vue.$toast.success("Seleção atualizada com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar a seleção! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyOpportunity(state.opportunityId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Seleção deletada com sucesso!");
        commit("toggleModalDeleteOpportunity");
        dispatch("loadOpportunities", from);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
