var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.course.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar curso",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Adicionar curso",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch: "Course/closeModal",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-rols-5 gap-5" }, [
                    _c("div", { staticClass: "grid grid-rols-2" }, [
                      _c(
                        "div",
                        [
                          _c("BaseText", {
                            attrs: {
                              text: "informações gerais",
                              typeText: "title-forms",
                              italic: false,
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", {
                        staticClass: "border border-ashes-hover w-full",
                      }),
                    ]),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "nome",
                            active: "",
                            inputName: "nome",
                            inputType: "text",
                            required: "",
                            labelText: "Nome",
                            showLabel: "",
                            value: _vm.course.name,
                            dispatch: "Course/setName",
                            "error-message": _vm.getErrorMessage(
                              "name",
                              _vm.course.name
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "col-span-1" }, [
                      _c(
                        "div",
                        [
                          _c("Multiselect", {
                            attrs: {
                              "close-on-select": true,
                              "show-label": "",
                              "clear-on-select": "",
                              "select-placeholder": "Município",
                              "multiple-option": false,
                              value: _vm.city,
                              options: _vm.cities,
                              "label-text": "Município",
                              dispatch: _vm.setCity,
                              required: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      [
                        _c("BaseTextArea", {
                          attrs: {
                            active: "",
                            inputName: "description",
                            required: "",
                            labelText: "Descrição",
                            showLabel: "",
                            rows: "4",
                            value: _vm.course.description,
                            dispatch: "Course/setDescription",
                            "error-message": _vm.getErrorMessage(
                              "description",
                              _vm.course.description
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch: "Course/closeModal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.course.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Salvar",
                                typeButton: "success",
                                dispatch: "Course/updateCourse",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "Criar",
                                typeButton: "success",
                                dispatch: "Course/createCourse",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1391417909
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }