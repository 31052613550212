<template>
  <ul>
    <li v-for="obj in data" :key="obj.id" @click.prevent="gotToArea(obj)" class="m-2">
      <AppCardArea :area="obj" />
      <div class="w-full border mt-1 border-ashes-hover"></div>
    </li>
  </ul>
</template>

<script>
import AppCardArea from "../../components/AppCardArea.vue";
import router from "../../router/index";

export default {
  components: {
    AppCardArea,
  },
  props: {
    data: Array,
  },
  methods: {
    gotToArea(params) {
      router.push({ name: "area", params: { id: params.id } });
    },
  },
};
</script>

<style></style>
