var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("div", { staticClass: "m-5" }, [
        _c(
          "div",
          {
            staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
            attrs: { id: "person-data" },
          },
          [
            _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Edição & Equipamento",
                    "type-text": "title-forms",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Edição",
                    text: _vm.inscription?.student?.season?.name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _vm.inscription?.student?.equipment
              ? _c(
                  "div",
                  { staticClass: "col-span-3" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Equipamento",
                        text: _vm.inscription?.student?.equipment?.name,
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _c(
                  "div",
                  { staticClass: "col-span-3" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Equipamento",
                        text: "NÃO INFORMADO",
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                ),
            _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("BaseText", {
                  attrs: { text: "Dados Pessoais", "type-text": "title-forms" },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "nome social",
                    text: _vm.inscription?.student?.social_name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "rg",
                    text: _vm.inscription?.student?.rg,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Orgão Expedidor",
                    text: _vm.inscription?.student?.dispatching_agency,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-3" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Expedição",
                    text: _vm.inscription?.student?.expedition,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "CPF",
                    text: _vm._f("VMask")(
                      _vm.inscription?.student?.cpf,
                      "###.###.###-##"
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "cidade onde nasceu",
                    text: _vm.inscription?.student?.city_of_birth,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "certidão de nascimento",
                    text: _vm._f("VMask")(
                      _vm.inscription?.student?.birth_certificate,
                      "###### ## ## #### # ##### ### ####### ##"
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    id: "book",
                    "show-label": "",
                    "label-text": "livro",
                    text: _vm.inscription?.student?.book,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    id: "",
                    "show-label": "",
                    "label-text": "folha",
                    text: _vm.inscription?.student?.page,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "email",
                    text: _vm.inscription?.student?.email,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "telefone",
                    text: _vm._f("VMask")(
                      _vm.inscription?.student?.cellphone_number,
                      "(##) #####-####"
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "whatsapp de contato",
                    text: _vm._f("VMask")(
                      _vm.inscription?.student?.whatszap,
                      "(##) #####-####"
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "data de nascimento",
                    text: _vm.formattedBirthdate(
                      _vm.inscription?.student?.birthdate
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "gênero",
                    text: _vm.inscription?.student?.gender,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Se identifica como LGBTQIAPN+",
                    text: _vm.inscription?.student?.identifies_as_lgbtqiapn,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Pertence a povos originários",
                    text: _vm.inscription?.student
                      ?.belongs_to_indigenous_peoples,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              {},
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "estado civil",
                    text: _vm.getCivilStateName(),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "NIS",
                    text: _vm.inscription?.student?.nis,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("BaseText", {
                  attrs: { text: "Endereço", "type-text": "title-forms" },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "cep",
                    text: _vm._f("VMask")(
                      _vm.inscription?.student?.cep,
                      "#####-###"
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "rua",
                    text: _vm.inscription?.student?.street,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "número",
                    text: _vm.inscription?.student?.street_number,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "bairro",
                    text: _vm.getNeighborhoodName(),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "comunidade",
                    text: _vm.inscription?.student?.community,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "município",
                    text: _vm.getCityName(),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "estado",
                    text: _vm.getStateName(),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-4" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "complemento (opcional)",
                    text: _vm.inscription?.student?.complement,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Dados Escolares",
                    "type-text": "title-forms",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "frequenta a escola?",
                    text: _vm.studying(),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text":
                      "se não frequenta a escola qual a sua última série concluída?",
                    text: _vm.getCompletedSeriesName(),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Última escola em que estudou?",
                    text: _vm.inscription?.student?.last_school_studied
                      ? _vm.inscription?.student?.last_school_studied?.name
                      : "Não informado",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Ano da última matrícula",
                    text: _vm.inscription?.student?.year_of_last_enrollment
                      ? _vm.inscription?.student?.year_of_last_enrollment?.name
                      : "Não informado",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Status Escolar",
                    text: _vm.inscription?.student?.educational_status
                      ? _vm.inscription?.student?.educational_status?.name
                      : "Não informado",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Status - Reinserção Escolar",
                    text: _vm.inscription?.student.school_reinsertion
                      ? _vm.inscription?.student.school_reinsertion.name
                      : "-",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Dados Complementares",
                    "type-text": "title-forms",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-3" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "tem ocupação",
                    text: _vm.busy(),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text":
                      "Em qual Área tem interesse na sua qualificação profissional?",
                    text: _vm.professionalQualificationNames(),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text":
                      "Qual turno você tem disponível para particapação do projeto?",
                    text: _vm.shift(),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Altura(m)",
                    text: _vm.inscription?.student?.height,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Peso(Kg)",
                    text: _vm.inscription?.student?.weight,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "tam. do tênis",
                    text: _vm.getShoeSize1(),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Dados Do Responsável e Familiares",
                    "type-text": "title-forms",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          _vm._l(
            _vm.inscription?.student?.relatives_attributes,
            function (relative, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
                  attrs: { id: "responsible-data" },
                },
                [
                  _c("div", { staticClass: "col-span-4" }, [
                    _c(
                      "div",
                      { staticClass: "flex items-end" },
                      [
                        _c("AppTextField", {
                          attrs: {
                            "show-label": "",
                            "label-text": "grau de parentesco",
                            text: _vm.getDegreeKinshipName(
                              relative.degree_kinship
                            ),
                            "type-text": "topic-details",
                          },
                        }),
                        relative.responsible || relative.reference_person
                          ? _c(
                              "div",
                              { staticClass: "text-container" },
                              [
                                relative.responsible
                                  ? _c("BaseText", {
                                      attrs: {
                                        color: "burnedYellow",
                                        text: "Responsável Atual",
                                        "type-text": "custom",
                                      },
                                    })
                                  : _vm._e(),
                                relative.reference_person
                                  ? _c("BaseText", {
                                      attrs: {
                                        color: "burnedYellow",
                                        text: "Pessoa de referência",
                                        "type-text": "custom",
                                      },
                                    })
                                  : _vm._e(),
                              ],
                              1
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "nome completo",
                          text: relative.name,
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "rg",
                          text: relative.rg,
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "cpf",
                          text: _vm._f("VMask")(relative.cpf, "###.###.###-##"),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "telefone",
                          text: _vm._f("VMask")(
                            relative.cellphone_number,
                            "(##) #####-####"
                          ),
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "e-mail",
                          text: relative.email,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "data de nascimento",
                          text: relative.birth_date,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              )
            }
          ),
          0
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }