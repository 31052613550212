var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "font-system" }, [
    _vm._m(0),
    _c(
      "div",
      {
        staticClass:
          "grid grid-cols-3 justify-between p-4 items-end mx-12 my-6 gap-4",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          { staticClass: "w-52" },
          [
            _c("Multiselect", {
              attrs: {
                "close-on-select": true,
                "show-label": "",
                "clear-on-select": "",
                "select-placeholder": "Selecionar edição",
                "multiple-option": false,
                value: _vm.season,
                options: _vm.seasons,
                "label-text": "Edição",
                dispatch: _vm.setSeason,
                required: "",
              },
            }),
          ],
          1
        ),
      ]
    ),
    _vm.season
      ? _c(
          "div",
          {
            staticClass: "mx-16 flex",
            attrs: { id: "menus_productive_insertion_main_page" },
          },
          [
            _vm.permitListOpportunities
              ? _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab("opportunities")
                        },
                      },
                    },
                    [
                      _c("BaseText", {
                        attrs: {
                          text: "Seleções",
                          typeText: "custom",
                          weight: "semibold",
                          mode: "uppercase",
                          size: "sm",
                          color: _vm.setColorOpportunities,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "border-l mx-10" }),
            _vm.permitListForwardingProductiveInsertion
              ? _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab("referredParcipants")
                        },
                      },
                    },
                    [
                      _c("BaseText", {
                        attrs: {
                          text: "Participantes Encaminhados",
                          typeText: "custom",
                          weight: "semibold",
                          mode: "uppercase",
                          size: "sm",
                          color: _vm.setColorReferredParcipants,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "border-l mx-10" }),
            _vm.permitListSpontaneousProductiveInsertion
              ? _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab("spontaneousInsertions")
                        },
                      },
                    },
                    [
                      _c("BaseText", {
                        attrs: {
                          text: "Inserções Espontâneas",
                          typeText: "custom",
                          weight: "semibold",
                          mode: "uppercase",
                          size: "sm",
                          color: _vm.setColorSpontaneousInsertions,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "border-l mx-10" }),
            _vm.permitListForwardingReservationRegistration
              ? _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab(
                            "forwardingReservationRegistration"
                          )
                        },
                      },
                    },
                    [
                      _c("BaseText", {
                        attrs: {
                          text: "Encaminhamentos para Cadastro Reserva",
                          typeText: "custom",
                          weight: "semibold",
                          mode: "uppercase",
                          size: "sm",
                          color: _vm.setColorForwardingReservationRegistrations,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "border-l mx-10" }),
            _c("div", [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.changeTab("profileSearch")
                    },
                  },
                },
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Busca por Perfil",
                      typeText: "custom",
                      weight: "semibold",
                      mode: "uppercase",
                      size: "sm",
                      color: _vm.setColorProfileSearch,
                    },
                  }),
                ],
                1
              ),
            ]),
          ]
        )
      : _vm._e(),
    _c("div", { attrs: { id: "list-productive-insertion-types" } }, [
      _vm.season
        ? _c(
            "div",
            [_c("Opportunity", { attrs: { open: _vm.toggleOpportunities } })],
            1
          )
        : _vm._e(),
      _vm.season
        ? _c(
            "div",
            [
              _c("ForwardingProductiveInsertion", {
                attrs: { open: _vm.toggleReferredParcipants },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.season
        ? _c(
            "div",
            [_c("ProfileSearch", { attrs: { open: _vm.toggleProfileSearch } })],
            1
          )
        : _vm._e(),
      _vm.season
        ? _c(
            "div",
            [
              _c("SpontaneousProductiveInsertion", {
                attrs: { open: _vm.toggleSpontaneousInsertions },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.season
        ? _c(
            "div",
            [
              _c("ForwardingReservationRegistration", {
                attrs: { open: _vm.toggleForwardingReservationRegistration },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "grid grid-cols-3 justify-between items-center mx-12 my-6",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "text-ashes-hover font-medium text-3xl mx-2 col-span-2",
          },
          [_vm._v("Interface de Inserção Produtiva")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }