var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", [
    _c(
      "div",
      { staticClass: "grid grid-cols-custom items-center container1" },
      [
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.planned_awareness_activity.created_at
                  ? _vm.formattedDate(_vm.planned_awareness_activity.created_at)
                  : "-",
                mode: "normal-case",
                color: "quantum",
                typeText: "custom",
                weight: "bold",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.planned_awareness_activity.area
                  ? _vm.planned_awareness_activity.area.name
                  : "-",
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.planned_awareness_activity.equipment
                  ? _vm.planned_awareness_activity.equipment.name
                  : "-",
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.planned_awareness_activity.articulator
                  ? _vm.planned_awareness_activity.articulator
                  : "-",
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.planned_awareness_activity.activity_date
                  ? _vm.formattedDate(
                      _vm.planned_awareness_activity.activity_date
                    )
                  : "-",
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 flex" },
          [
            _vm.permitShow
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "visibility",
                    dispatch: "PlannedAwarenessActivities/openViewModal",
                    payload: _vm.planned_awareness_activity,
                    title: "Visualizar Planejamento",
                  },
                })
              : _vm._e(),
            _vm.permitUp
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "edit",
                    payload: _vm.planned_awareness_activity,
                    dispatch:
                      "PlannedAwarenessActivities/openEditModalAddPlannedAwarenessActivity",
                    title: "Editar Planejamento",
                  },
                })
              : _vm._e(),
            _vm.permitDes
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "delete",
                    payload: _vm.planned_awareness_activity,
                    dispatch:
                      "PlannedAwarenessActivities/toggleModalDeletePlannedAwarenessActivity",
                    title: "Excluir Planejamento",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }