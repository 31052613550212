import { getAxios, baseURL } from "./Api";

export async function getSeasons() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/edicoes");
}

export async function getSeason(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get(`/edicoes/${id}`);
}

export async function getSeasonsByCityId(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/cidades/${filters.id}/edicoes`;
  return api.get(url, { params: filters });
}

export async function getSeasonsByCityIdForMenus(city_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get(`/cidades/${city_id}/edicoes/edicoes_para_listar`);
}

export async function getSeasonsByCityIdForForwardings(city_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get(`/cidades/${city_id}/edicoes/edicoes_para_encaminhamentos`);
}

export async function getPartialsSeasonsByCityId(city_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get(`/cidades/${city_id}/edicoes/parciais`);
}

export async function saveSeason(season) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.post("/edicoes", { seasons: season });
}

export async function updateSeason(id, season) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`/edicoes/${id}`, { seasons: season });
}

export async function endSeason(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/edicoes/${id}/encerrar_edicao`);
}
