var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "font-system" }, [
    _c(
      "div",
      {
        staticClass: "grid grid-cols-3 justify-between items-center mx-12 my-6",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "text-ashes-hover font-medium text-3xl mx-2 col-span-2",
          },
          [_vm._v(_vm._s(_vm.city.name) + " | Articuladores")]
        ),
        _vm.permitCreate
          ? _c(
              "div",
              { staticClass: "mx-2 items-center justify-self-end" },
              [
                _vm.permitCreate
                  ? _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-add-articulator",
                        showIcon: "",
                        iconName: "add",
                        text: "Cadastrar Articulador(a)",
                        dispatch: "Articulators/openModalAddArticulator",
                        typeButton: "primary",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      {
        staticClass:
          "grid grid-cols-4 justify-between p-4 items-end mx-12 my-6 gap-4",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                inputName: "",
                inputType: "text",
                iconName: "search",
                showIcon: "",
                "input-placeholder": "Pesquisar por nome",
                dispatch: "Articulators/setFilterName",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                inputName: "",
                inputType: "text",
                iconName: "search",
                showIcon: "",
                "input-placeholder": "Pesquisar por cpf",
                dispatch: "Articulators/setFilterCpf",
              },
            }),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "grid grid-cols-2 items-center mx-12 my-6",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          { staticClass: "mx-2" },
          [
            _c("AppButtonColorful", {
              attrs: {
                id: "btn-consult-forwardings",
                showIcon: "",
                iconName: "search",
                text: "Consultar dados",
                dispatch: "Articulators/loadArticulators",
                typeButton: "primary",
              },
            }),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { attrs: { id: "list-atendent" } },
      [
        _c(
          "div",
          { staticClass: "ml-12" },
          [_c("ArticulatorsCards", { attrs: { data: _vm.articulators } })],
          1
        ),
        _c("ModalArticulator", {
          attrs: { open: _vm.modalToggleAddArticulator },
        }),
        _c("DeleteArticulator", {
          attrs: { open: _vm.modalToggleDeleteArticulator },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }