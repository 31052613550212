<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="profile.description"
          mode="underline"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="xl"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          iconName="edit"
          :payload="profile"
          dispatch="AccessControl/openEditModalPermission"
          v-if="permitUp"
        />
        <AppButtonIcon
          iconName="delete"
          :payload="profile"
          dispatch="AccessControl/openCloseDeleteProfileConfirmation"
          v-if="permitDes"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },

  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
    };
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    profile: {
      type: Object,
    },
  },

  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    profileAcces() {
      this.canAcces("ability_profile_show").then((resp) => {
        this.permit = resp;
      });
    },
    profileDelete() {
      this.canAcces("ability_profile_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    profileChange() {
      this.canAcces("ability_profile_update").then((resp) => {
        this.permitUp = resp;
      });
    },
  },

  created() {
    this.profileAcces();
    this.profileChange();
    this.profileDelete();
  },

  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-4": true,
        "justify-items-stretch": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
        "cursor-pointer": true,
      };
    },
    ...mapState({
      showModalPermissions: (state) => state.AccessControl.modalPerrmissionsOpen,
    }),
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 3fr 2fr 2fr 3fr 0fr;
}
</style>
