<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="forwarding_reservation_registration.participant_status"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="getArea()"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="forwarding_reservation_registration.participant_name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="formattedDate(forwarding_reservation_registration.forwarding_date)"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="forwarding_reservation_registration.partner"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="forwarding_reservation_registration.company"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="permitUpdate"
          iconName="edit"
          :payload="forwarding_reservation_registration"
          dispatch="ForwardingReservationRegistrations/openEditModalAddForwardingReservationRegistration"
          title="Atualizar Encaminhamento"
        />
        <AppButtonIcon
          v-if="permitShow"
          iconName="visibility"
          dispatch="ForwardingReservationRegistrations/openViewModal"
          :payload="forwarding_reservation_registration"
          title="Visualizar Encaminhamento"
        />
        <AppButtonIcon
          v-if="permitDelete"
          iconName="delete"
          :payload="forwarding_reservation_registration"
          dispatch="ForwardingReservationRegistrations/toggleModalDeleteForwardingReservationRegistration"
          title="Deletar Encaminhamento"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permit: false,
      permitUpdate: false,
      permitDelete: false,
      permitShow: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    forwarding_reservation_registration: {
      type: Object,
    },
  },
  created() {
    this.forwardingReservationRegistrationAccess();
    this.forwardingReservationRegistrationDelete();
    this.forwardingReservationRegistrationUpdate();
    this.forwardingReservationRegistrationShow();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    forwardingReservationRegistrationAccess() {
      this.canAcces("forwarding_reservation_registration_index").then((response) => {
        this.permit = response;
      });
    },
    forwardingReservationRegistrationDelete() {
      this.canAcces("forwarding_reservation_registration_destroy").then((response) => {
        this.permitDelete = response;
      });
    },
    forwardingReservationRegistrationShow() {
      this.canAcces("forwarding_reservation_registration_show").then((response) => {
        this.permitShow = response;
      });
    },
    forwardingReservationRegistrationUpdate() {
      this.canAcces("forwarding_reservation_registration_update").then((response) => {
        this.permitUpdate = response;
      });
    },
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    getArea() {
      if (
        this.forwarding_reservation_registration.neighborhood &&
        this.forwarding_reservation_registration.neighborhood.area
      ) {
        return this.forwarding_reservation_registration.neighborhood.area.name;
      }

      return "Não informado";
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 9% 14% 23% 13% 16% 14% 12%;
}
</style>
