var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: [
        "button",
        _vm.size,
        _vm.variant,
        _vm.color,
        { "with-icon": _vm.iconName, compact: _vm.compact },
      ],
      attrs: { id: _vm.id },
      on: { click: _vm.onClick },
    },
    [
      _vm.iconName
        ? _c("span", { staticClass: "icon" }, [
            _c("i", { class: `material-icons` }, [
              _vm._v(_vm._s(_vm.iconName)),
            ]),
          ])
        : _vm._e(),
      _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.text))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }