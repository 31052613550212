var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid auto-grid-auto" },
    [
      _c(
        "div",
        { staticClass: "pl-12" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.city.name + " | Usuários",
              typeText: "custom",
              weight: "semibold",
              italic: false,
              size: "3xl",
              color: "ashes-hover",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "flex justify-between mx-12 my-6",
          attrs: { id: "tabs-button" },
        },
        [
          _c("div", { staticClass: "flex" }, [
            _c(
              "div",
              { staticClass: "w-104" },
              [
                _c("BaseInput", {
                  attrs: {
                    active: "",
                    inputName: "",
                    inputType: "text",
                    iconName: "search",
                    showIcon: "",
                    "input-placeholder": "Pesquisar por nome ou cpf",
                    dispatch: "Users/filterList",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "float-right" },
            [
              _c("AppButtonColorful", {
                attrs: {
                  id: "btn-add-teacher",
                  showIcon: "",
                  iconName: "add",
                  text: "Adicionar Usuário",
                  dispatch: "User/openModal",
                  typeButton: "primary",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "p-4" }, [
        _c("div", [_c("UsersCards", { attrs: { data: _vm.users } })], 1),
      ]),
      _c("ModalUser", { attrs: { open: _vm.toggleModal } }),
      _c("ModalConfirmDeleteUser", {
        attrs: { open: _vm.modalConfirmDeleteUser },
      }),
      _c("ModalResetUserPassword", { attrs: { open: _vm.modalResetPassword } }),
      _c("ModalUserActivation", { attrs: { open: _vm.modalActivation } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }