var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "font-system" }, [
    _c(
      "div",
      {
        staticClass: "grid grid-cols-3 justify-between items-center mx-12 my-6",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "text-ashes-hover font-medium text-3xl mx-2 col-span-2",
          },
          [_vm._v("Bairros")]
        ),
        _c(
          "div",
          { staticClass: "mx-2 items-center justify-self-end" },
          [
            _vm.permitCreate
              ? _c("AppButtonColorful", {
                  attrs: {
                    id: "btn-add-city",
                    showIcon: "",
                    iconName: "add",
                    text: "Cadastrar Bairro",
                    dispatch: "Neighborhoods/openModalAddNeighborhood",
                    typeButton: "primary",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "mx-14", attrs: { id: "tabs-button" } },
      [
        _c("FilterComponent", [
          _c(
            "div",
            { staticClass: "col-span-4" },
            [
              _c("BaseLabel", {
                attrs: {
                  text: "Pesquisar por nome do Bairro",
                  typeText: "custom",
                  weight: "normal",
                  italic: false,
                  size: "xs",
                },
              }),
              _c("BaseInput", {
                attrs: {
                  active: "",
                  inputName: "",
                  inputType: "text",
                  iconName: "search",
                  showIcon: "",
                  "input-placeholder": "Pesquisar por nome",
                  dispatch: "Neighborhoods/setFilterName",
                  value: _vm.filter_params.name,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("Multiselect", {
                attrs: {
                  "close-on-select": true,
                  "show-label": "",
                  "clear-on-select": "",
                  "select-placeholder": "Município",
                  "multiple-option": false,
                  value: _vm.city,
                  options: _vm.cities,
                  "label-text": "Filtrar por Município",
                  dispatch: _vm.setFilterCity,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("Multiselect", {
                attrs: {
                  "close-on-select": true,
                  "show-label": "",
                  "clear-on-select": "",
                  "select-placeholder": "Área",
                  "multiple-option": false,
                  value: _vm.area,
                  options: _vm.user_areas,
                  "label-text": "Filtrar por Área",
                  dispatch: _vm.setFilterArea,
                },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "grid grid-cols-2 items-center mx-12 my-6",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          { staticClass: "mx-2" },
          [
            _c("AppButtonColorful", {
              attrs: {
                id: "btn-consult-neighborhoods",
                showIcon: "",
                iconName: "search",
                text: "Consultar dados",
                dispatch: "Neighborhoods/loadNeighborhoods",
                payload: "load",
                typeButton: "primary",
              },
            }),
          ],
          1
        ),
        _vm.neighborhoods.length > 0
          ? _c("div", { staticClass: "mx-2 items-center justify-self-end" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.clearFields()
                    },
                  },
                },
                [
                  _c("AppButtonColorful", {
                    attrs: {
                      id: "btn-consult-neighborhoods",
                      showIcon: "",
                      iconName: "remove",
                      text: "Limpar consulta",
                      typeButton: "primary",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      { attrs: { id: "list-neighborhoods" } },
      [
        _c(
          "div",
          { staticClass: "mx-12" },
          [_c("NeighborhoodsCards", { attrs: { data: _vm.neighborhoods } })],
          1
        ),
        _vm.neighborhoods.length > 0
          ? _c(
              "div",
              {
                staticClass: "mx-12 my-6 pb-3",
                attrs: { id: "neighborhoods-pagination" },
              },
              [
                _c("BasePagination", {
                  attrs: {
                    total: _vm.totalNeighborhoods,
                    "total-per-page": _vm.totalPerPage,
                    dispatch: "Neighborhoods/setNeighborhoodsOffset",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("ModalNeighborhood", {
          attrs: { open: _vm.modalToggleAddNeighborhood },
        }),
        _c("DeleteNeighborhoodsConfirmation", {
          attrs: { open: _vm.modalToggleDeleteNeighborhood },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }