var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-7 mt-8" }, [
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("BaseText", {
              attrs: {
                text: "nome do jovem",
                typeText: "custom",
                weight: "medium",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "quantum",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseText", {
              attrs: {
                text: "turma",
                typeText: "custom",
                weight: "medium",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "quantum",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseText", {
              attrs: {
                text: "fase",
                typeText: "custom",
                weight: "medium",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "quantum",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseText", {
              attrs: {
                text: "nº de encontros",
                typeText: "custom",
                weight: "medium",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "quantum",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            !(_vm.benefit.type_benefit === "public_transport")
              ? _c("BaseText", {
                  attrs: {
                    text: "% de Presenças",
                    typeText: "custom",
                    weight: "medium",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "quantum",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseText", {
              attrs: {
                text: "valor",
                typeText: "custom",
                weight: "medium",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "quantum",
              },
            }),
          ],
          1
        ),
      ]),
      _vm._l(_vm.students, function (student) {
        return _c(
          "div",
          { key: student.id },
          [_c("AppCardStudentBenefit", { attrs: { student: student } })],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }