var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.benefit.id
                          ? _c("BaseText", {
                              attrs: {
                                text: `Editar folha de pagamento - ${_vm.benefitType}`,
                                typeText: "custom",
                                weight: "bold",
                                mode: "normal",
                                size: "2xl",
                                color: "ashes-dark",
                              },
                            })
                          : _c("BaseText", {
                              attrs: {
                                text: `Criar folha de pagamento - ${_vm.benefitType}`,
                                typeText: "custom",
                                weight: "bold",
                                mode: "normal",
                                size: "2xl",
                                color: "ashes-dark",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch: "Benefit/closeModal",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "Informações Gerais",
                            typeText: "custom",
                            weight: "medium",
                            mode: "uppercase",
                            size: "sm",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "date_benefit_start",
                            value: _vm.benefit.date_benefit_start,
                            "input-type": "date",
                            "show-icon": "",
                            "icon-name": "today",
                            "show-label": "",
                            "label-text": "Período Inicial",
                            required: "",
                            "error-message": _vm.getErrorMessage(
                              "date_benefit_start",
                              _vm.benefit.date_benefit_start
                            ),
                            dispatch: "Benefit/setDateBenefitStart",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "date_benefit_end",
                            value: _vm.benefit.date_benefit_end,
                            "input-type": "date",
                            "show-icon": "",
                            "icon-name": "today",
                            "show-label": "",
                            "label-text": "Período Final",
                            required: "",
                            "error-message": _vm.getErrorMessage(
                              "date_benefit_end",
                              _vm.benefit.date_benefit_end
                            ),
                            dispatch: "Benefit/setDateBenefitEnd",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            selectPlaceholder: "Edição",
                            "multiple-option": false,
                            options: _vm.seasons,
                            "show-label": true,
                            "label-text": "Edição",
                            required: "",
                            value: _vm.benefit.season,
                            "error-message": _vm.getErrorMessage(
                              "season",
                              _vm.benefit.season
                            ),
                            dispatch: _vm.setSeason,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _vm.tabBenefitType === "publicTransport"
                          ? _c("BaseInput", {
                              attrs: {
                                id: "public_transport_fee",
                                "input-name": "public_transport_fee",
                                "input-placeholder": "0,00",
                                "input-type": "text",
                                mask: "#,##",
                                "show-label": "",
                                "label-text": "Tarifa",
                                required: "",
                                value: String(_vm.benefit.public_transport_fee),
                                "error-message": _vm.getErrorMessage(
                                  "public_transport_fee",
                                  _vm.benefit.public_transport_fee
                                ),
                                dispatch: "Benefit/setPublicTransportFee",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch: "Benefit/closeModal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.benefit.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "salvar",
                                typeButton: "success",
                                dispatch: "Benefit/updateBenefit",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "criar",
                                typeButton: "success",
                                dispatch: "Benefit/saveBenefit",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3682142497
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }