<template>
  <div>
    <div :class="classesModal">
      <div class="grid grid-flow-row auto-rows-max md:auto-rows-min z-1000000000000">
        <div :class="classPosition">
          <BaseText
            class="underline"
            color="white"
            text="Selecione a cidade"
            typeText="custom"
            weight="semibold"
            :italic="false"
            mode="capitalize"
            size="2xl"
          />
        </div>
        <div id="content" :class="classPosition">
          <div :key="city.id" v-for="city in cities" @click.prevent="setCity(city)">
            <AppCardCity :name="city.name" />
          </div>
        </div>
      </div>
    </div>
    <div class="opacity-50 fixed inset-0 z-40 bg-black"></div>
  </div>
</template>

<script>
import AppCardCity from "../../components/AppCardCity.vue";
import BaseText from "../../components/BaseText.vue";

export default {
  components: {
    AppCardCity,
    BaseText,
  },
  props: {
    open: Boolean,
    dispatch: String,
    cities: {
      type: Array,
    },
  },
  computed: {
    classesModal() {
      return {
        "overflow-x-hidden": true,
        "overflow-y-auto": true,
        fixed: true,
        "inset-0": true,
        "z-50": true,
        "outline-none": true,
        "focus:outline-none": true,
        "justify-center": true,
        "items-center": true,
        flex: true,
      };
    },
    classPosition() {
      return {
        relative: true,
        "my-6": true,
        "mx-auto": true,
        "max-w-7xl": true,
        flex: true,
        "gap-14": true,
      };
    },
  },
  methods: {
    setCity(city) {
      if (this.dispatch) {
        localStorage.setItem("city_id", city.id);
        this.$store.dispatch(this.dispatch, city);

        const waitForPerms = new Promise((resolve) => {
          const checkPerms = () => {
            const perms = JSON.parse(window.localStorage.getItem("perms"));
            if (perms) {
              resolve();
            } else {
              setTimeout(checkPerms, 100);
            }
          };
          checkPerms();
        });

        waitForPerms.then(() => {
          this.$router.push("/admin").catch(() => {});
          this.$router.go();

          localStorage.setItem("season_date_manual_execution_end", null);
          localStorage.setItem("season", null);
          localStorage.setItem("season_id", null);
          sessionStorage.setItem("season_information", null);
        });
      }
    },
  },
};
</script>
