// import { getAreasByCity } from "../../service/AreaApi";
import { getEquipmentByAreaId } from "../../service/EquipmentApi";
import { getFacilitiesByForwardingPolicy } from "../../service/FacilitiesApi";
import { getSeasonsByCityIdForMenus } from "../../service/SeasonApi";
import { getReasonsForNorReinsertions } from "../../service/ReasonsForNonReinsertionsApi";
import {
  createSchoolReinsertion,
  destroySchoolReinsertion,
  getSchoolReinsertionById,
  getSchoolReinsertionByInscription,
  getSchoolReinsertions,
  getSchoolReinsertionsActivityTypes,
  getSchoolReinsertionsAdministrativeDependencies,
  getSchoolReinsertionsResultTypes,
  getSchoolReinsertionsSeries,
  getSchoolReinsertionsShifts,
  getSchoolReinsertionsTeachingModalities,
  updateSchoolReinsertion,
} from "../../service/SchoolReinsertionsApi";
import {
  activeMonitoringSchoolReinsertion,
  getInscriptionByIdAndResource,
  getInscriptionBySeasonAndArea,
  getInscriptionForGeneralMenus,
  getInscriptionsForSchoolReinsertionSpreadsheet,
} from "../../service/InscriptionApi";
import { getArticulators } from "../../service/ArticulatorsApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import moment from "moment";
import { getInstitutionalReferrals } from "@/service/StudentApi";
import { util_function } from "@/util_functions";

Vue.component("downloadExcel", JsonExcel);

const state = {
  school_reinsertion: {
    id: null,
    inscription_id: null,
    teaching_modality: null,
    administrative_dependency: null,
    activity_type: null,
    reinsertion_date: null,
    result_type: null,
    serie: null,
    shift: null,
    reasons_for_non_reinsertion_id: null,
    forwardings: null,
    letter_delivery_date: null,
    facility_id: null,
    enrollment_date: null,
    user_id: null,
    articulator: null,
    city_id: localStorage.city_id,
    school_reinsertion_origin_id: null,
    observations: null,
    deadline_attach_proof_of_enrollment: null,
    return_letter_deadline: null,
  },
  school_reinsertions: [],
  activity_types: [],
  result_types: [],
  series: [],
  shits: [],
  teaching_modalities: [],
  administrative_dependencies: [],
  institutional_referrals: [],
  user_areas: [],
  user_equipments: [],
  inscriptions: [],
  seasons: [],
  equipments_school_reinsertions: [],
  articulators: [],
  reasons_for_non_reinsertions: [],
  facilities: [],
  school_reinsertions_evolution_spreadsheet: [],
  school_reinsertions_inscriptions_spreadsheet: [],
  inscriptionsForGeneralMenus: [],

  schoolReinsertionId: null,
  season: null,
  user_areas_filtered: null,
  user_equipments_filtered: null,
  user: null,
  inscription: null,
  activity_type: null,
  result_type: null,
  serie: null,
  shift: null,
  teaching_modality: null,
  administrative_dependency: null,
  articulator: null,
  reasons_for_non_reinsertion: null,
  facility: null,

  tabSchoolReinsertionView: "notReinserted",
  toggleSchoolReinsertionNotReinserted: true,
  toggleSchoolReinsertionReinserted: false,
  toggleSchoolReinsertionPlannedAwarenessActivity: false,
  toggleSchoolReinsertionVacancyRequest: false,

  tabSchoolReinsertionNotReinsertedView: "personal_data",
  toggleSchoolReinsertionNotReinsertedPersonalData: true,
  toggleSchoolReinsertionNotReinsertedEvolution: false,
  toggleSchoolReinsertionNotReinsertedVacancyRequest: false,

  modalToggleAddSchoolReinsertion: false,
  modalToggleDeleteSchoolReinsertion: false,
  modalToggleViewSchoolReinsertion: false,
  modalToggleExportSchoolReinsertionsEvolutionSpreadsheet: false,
  modalToggleExportSchoolReinsertionsInscriptionsSpreadsheet: false,
  modalToggleEditSchoolReinsertionDeadlineAttachProofOfEnrollment: false,
  modalToggleEditSchoolReinsertionReturnLetterDeadline: false,

  toggleSchoolReinsertionDeadlineAttachProofOfEnrollment: false,

  hasSpreadsheetSchoolReinsertionsEvolutionResponse: true,
  json_data_school_reinsertion_evolution: null,
  hasSpreadsheetSchoolReinsertionsInscriptionsResponse: true,
  json_data_school_reinsertion_inscriptions: null,

  inscriptionsTotal: 0,
  totalPerPage: 10,
  offset: 0,

  offsetReinserteds: 0,
  offsetNotReinserteds: 0,

  schoolReinsertionsByInscriptionTotal: 0,
  schoolReinsertionsByInscriptionTotalPerPage: 10,
  schoolReinsertionsByInscriptionOffset: 0,

  accompaniment_statuses: [
    {
      id: 1,
      name: "Ativo",
    },
    {
      id: 2,
      name: "Suspenso",
    },
  ],
  enrollment_proof_options: [
    {
      id: 1,
      name: "Anexado",
    },
    {
      id: 2,
      name: "Pendente",
    },
  ],

  params: {
    not_reinserted_main_page: {
      status: null,
      inscription: null,
      equipment: null,
      articulator: null,
      institutional_referral: null,
      accompaniment_status: null,
    },
    not_reinserted_evolution: {
      activity_type: null,
      result_type: null,
      articulator: null,
      initial_date: null,
      final_date: null,
    },
    reinserted_main_page: {
      teaching_modality: null,
      facility: null,
      serie: null,
      initial_date_enrollment: null,
      final_date_enrollment: null,
      enrollment_proof: null,
    },
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadSchoolReinsertions: (state, school_reinsertions) => {
    state.school_reinsertions = school_reinsertions;
  },
  loadArticulators: (state, articulators) => {
    state.articulators = articulators;
  },
  loadActivityTypes: (state, activity_types) => {
    state.activity_types = activity_types;
  },
  loadResultTypes: (state, result_types) => {
    state.result_types = result_types;
  },
  loadSeries: (state, series) => {
    state.series = series;
  },
  loadShifts: (state, shifts) => {
    state.shifts = shifts;
  },
  loadTeachingModalities: (state, teaching_modalities) => {
    state.teaching_modalities = teaching_modalities;
  },
  loadAdministrativeDependencies: (state, administrative_dependencies) => {
    state.administrative_dependencies = administrative_dependencies;
  },
  loadInstitutionalReferrals: (state, institutional_referrals) => {
    state.institutional_referrals = institutional_referrals;
  },
  loadReasonsForNonReinsertions: (state, reasons_for_non_reinsertions) => {
    state.reasons_for_non_reinsertions = reasons_for_non_reinsertions;
  },
  loadFacilities: (state, facilities) => {
    state.facilities = facilities;
  },
  loadInscriptionsForGeneralMenus: (state, inscriptionsForGeneralMenus) => {
    state.inscriptionsForGeneralMenus = inscriptionsForGeneralMenus;
  },
  setSeasonsMutation: (state, seasons) => {
    state.seasons = seasons;
    state.offset = 0;
    state.offsetReinserteds = 0;
    state.offsetNotReinserteds = 0;
  },
  setEquipmentsForSchoolReinsertionMutation: (state, equipments_school_reinsertions) => {
    state.equipments_school_reinsertions = equipments_school_reinsertions;
  },

  // MUTATIONS - FIELDS TO API
  setIdMutation: (state, id) => {
    state.school_reinsertion.id = id;
  },
  setInscriptionMutation: (state, inscription) => {
    state.inscription = inscription;
    state.school_reinsertion.inscription_id = inscription.id;
    sessionStorage.setItem("inscription_id", inscription.id);
  },
  setUserAreasMutation: (state, user_areas) => {
    state.user_areas = user_areas;
  },
  setInscriptionsMutation: (state, inscriptions) => {
    state.inscriptions = inscriptions;
  },
  setUserEquipmentsMutation: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },
  setActivityTypeMutation: (state, activity_type) => {
    state.activity_type = activity_type;
    state.school_reinsertion.activity_type = activity_type ? activity_type.id : null;

    state.result_type = null;
    state.school_reinsertion.result_type = null;
  },
  setResultTypeMutation: (state, result_type) => {
    state.result_type = result_type;
    state.school_reinsertion.result_type = result_type ? result_type.id : null;
  },
  setArticulatorMutation: (state, articulator) => {
    state.school_reinsertion.articulator = articulator;
  },
  setSerieMutation: (state, serie) => {
    state.serie = serie;
    state.school_reinsertion.serie = serie ? serie.id : null;
  },
  setShiftMutation: (state, shift) => {
    state.shift = shift;
    state.school_reinsertion.shift = shift ? shift.id : null;
  },
  setTeachingModalityMutation: (state, teaching_modality) => {
    state.teaching_modality = teaching_modality;
    state.school_reinsertion.teaching_modality = teaching_modality ? teaching_modality.id : null;
  },
  setAdministrativeDependencyMutation: (state, administrative_dependency) => {
    state.administrative_dependency = administrative_dependency;
    state.school_reinsertion.administrative_dependency = administrative_dependency
      ? administrative_dependency.id
      : null;
  },
  setReasonsForNorReinsertionMutation: (state, reasons_for_non_reinsertion) => {
    state.reasons_for_non_reinsertion = reasons_for_non_reinsertion;
    state.school_reinsertion.reasons_for_non_reinsertion_id = reasons_for_non_reinsertion
      ? reasons_for_non_reinsertion.id
      : null;
  },
  setFacilityMutation: (state, facility) => {
    state.facility = facility;
    state.school_reinsertion.facility_id = facility ? facility.id : null;
  },
  setReinsertionDateMutation: (state, reinsertion_date) => {
    state.school_reinsertion.reinsertion_date = reinsertion_date;
  },
  setForwardingsMutation: (state, forwardings) => {
    state.school_reinsertion.forwardings = forwardings;
  },
  setObservationsMutation: (state, observations) => {
    state.school_reinsertion.observations = observations;
  },
  setLetterDeliveryDateMutation: (state, letter_delivery_date) => {
    state.school_reinsertion.letter_delivery_date = letter_delivery_date;
  },
  setEnrollmentDateMutation: (state, enrollment_date) => {
    state.school_reinsertion.enrollment_date = enrollment_date;
  },
  setDeadlineAttachProofOfEnrollmentMutation: (state, deadline_attach_proof_of_enrollment) => {
    state.school_reinsertion.deadline_attach_proof_of_enrollment = deadline_attach_proof_of_enrollment;
  },
  setReturnLetterDeadlineMutation: (state, return_letter_deadline) => {
    state.school_reinsertion.return_letter_deadline = return_letter_deadline;
  },
  setUserMutation: (state, user) => {
    state.user = user;
    if (user) {
      if (user.id) {
        state.school_reinsertion.user_id = user.id;
      } else {
        state.school_reinsertion.user_id = user.userId;
      }
    }
  },
  setSchoolReinsertionOriginIdMutation: (state, school_reinsertion_origin_id) => {
    state.school_reinsertion.school_reinsertion_origin_id = school_reinsertion_origin_id;
  },

  // --> MUTATIONS - DELETION <--
  schoolReinsertionForDeletion: (state, payload) => {
    if (payload) {
      state.schoolReinsertionId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterStatusNotReinsertedMainPageMutation: (state, status) => {
    state.params.not_reinserted_main_page.status = status;
  },
  setFilterInscriptionNotReinsertedMainPageMutation: (state, inscription) => {
    state.params.not_reinserted_main_page.inscription = inscription;
  },
  setFilterEquipmentNotReinsertedMainPageMutation: (state, equipment) => {
    state.params.not_reinserted_main_page.equipment = equipment;
  },
  setFilterArticulatorNotReinsertedMainPageMutation: (state, articulator) => {
    state.params.not_reinserted_main_page.articulator = articulator;
  },
  setUserAreasFilteredMutation: (state, user_areas_filtered) => {
    state.user_areas_filtered = user_areas_filtered;
  },

  setFilterActivityTypeNotReinsertedEvolutionMutation: (state, activity_type) => {
    state.params.not_reinserted_evolution.activity_type = activity_type;
  },
  setFilterResultTypeNotReinsertedEvolutionMutation: (state, result_type) => {
    state.params.not_reinserted_evolution.result_type = result_type;
  },
  setFilterArticulatorNotReinsertedEvolutionMutation: (state, articulator) => {
    state.params.not_reinserted_evolution.articulator = articulator;
  },
  setFilterInitialDateNotReinsertedEvolutionMutation: (state, initial_date) => {
    state.params.not_reinserted_evolution.initial_date = initial_date;
  },
  setFilterFinalDateNotReinsertedEvolutionMutation: (state, final_date) => {
    state.params.not_reinserted_evolution.final_date = final_date;
  },
  setFilterInstitutionalReferralNotReinsertedMainPageMutation: (state, institutional_referral) => {
    state.params.not_reinserted_main_page.institutional_referral = institutional_referral;
  },
  setFilterAccompanimentStatusNotReinsertedMainPageMutation: (state, accompaniment_status) => {
    state.params.not_reinserted_main_page.accompaniment_status = accompaniment_status;
  },

  setFilterTeachingModalityReinsertedMainPageMutation: (state, teaching_modality) => {
    state.params.reinserted_main_page.teaching_modality = teaching_modality;
  },
  setFilterEnrollmentProofReinsertedMainPageMutation: (state, enrollment_proof) => {
    state.params.reinserted_main_page.enrollment_proof = enrollment_proof;
  },
  setFilterFacilityReinsertedMainPageMutation: (state, facility) => {
    state.params.reinserted_main_page.facility = facility;
  },
  setFilterSerieReinsertedMainPageMutation: (state, serie) => {
    state.params.reinserted_main_page.serie = serie;
  },
  setFilterInitialDateEnrollmentReinsertedMainPageMutation: (state, initial_date_enrollment) => {
    state.params.reinserted_main_page.initial_date_enrollment = initial_date_enrollment;
  },
  setFilterFinalDateEnrollmentReinsertedMainPageMutation: (state, final_date_enrollment) => {
    state.params.reinserted_main_page.final_date_enrollment = final_date_enrollment;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddSchoolRensertion: (state) => {
    state.modalToggleAddSchoolReinsertion = !state.modalToggleAddSchoolReinsertion;
  },
  toggleModalDeleteSchoolReinsertion: (state) => {
    state.modalToggleDeleteSchoolReinsertion = !state.modalToggleDeleteSchoolReinsertion;
  },
  toggleModalViewSchoolReinsertion: (state) => {
    state.modalToggleViewSchoolReinsertion = !state.modalToggleViewSchoolReinsertion;
  },
  toggleEditSchoolReinsertionDeadlineAttachProofOfEnrollment: (state) => {
    state.modalToggleEditSchoolReinsertionDeadlineAttachProofOfEnrollment =
      !state.modalToggleEditSchoolReinsertionDeadlineAttachProofOfEnrollment;
  },
  toggleEditSchoolReinsertionReturnLetterDeadline: (state) => {
    state.modalToggleEditSchoolReinsertionReturnLetterDeadline =
      !state.modalToggleEditSchoolReinsertionReturnLetterDeadline;
  },

  toggleSchoolReinsertionDeadlineAttachProofOfEnrollment: (state) => {
    state.toggleSchoolReinsertionDeadlineAttachProofOfEnrollment =
      !state.toggleSchoolReinsertionDeadlineAttachProofOfEnrollment;
  },

  toggleSchoolReinsertionNotReinserted: (state, value) => {
    state.toggleSchoolReinsertionNotReinserted = value;
  },
  toggleSchoolReinsertionReinserted: (state, value) => {
    state.toggleSchoolReinsertionReinserted = value;
  },
  toggleSchoolReinsertionPlannedAwarenessActivity: (state, value) => {
    state.toggleSchoolReinsertionPlannedAwarenessActivity = value;
  },
  toggleSchoolReinsertionVacancyRequest: (state, value) => {
    state.toggleSchoolReinsertionVacancyRequest = value;
  },

  toggleSchoolReinsertionNotReinsertedPersonalData: (state, value) => {
    state.toggleSchoolReinsertionNotReinsertedPersonalData = value;
  },
  toggleSchoolReinsertionNotReinsertedEvolution: (state, value) => {
    state.toggleSchoolReinsertionNotReinsertedEvolution = value;
  },
  toggleSchoolReinsertionNotReinsertedVacancyRequest: (state, value) => {
    state.toggleSchoolReinsertionNotReinsertedVacancyRequest = value;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportSchoolReinsertionstEvolutionSpreadsheet: (state) => {
    state.modalToggleExportSchoolReinsertionsEvolutionSpreadsheet =
      !state.modalToggleExportSchoolReinsertionsEvolutionSpreadsheet;
  },
  toggleModalToggleExportSchoolReinsertionstInscriptionsSpreadsheet: (state) => {
    state.modalToggleExportSchoolReinsertionsInscriptionsSpreadsheet =
      !state.modalToggleExportSchoolReinsertionsInscriptionsSpreadsheet;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetSchoolReinsertionsEvolutionResponseMutation: (
    state,
    hasSpreadsheetSchoolReinsertionsEvolutionResponse
  ) => {
    state.hasSpreadsheetSchoolReinsertionsEvolutionResponse =
      hasSpreadsheetSchoolReinsertionsEvolutionResponse;
  },
  hasSpreadsheetSchoolReinsertionsInscriptionsResponseMutation: (
    state,
    hasSpreadsheetSchoolReinsertionsInscriptionsResponse
  ) => {
    state.hasSpreadsheetSchoolReinsertionsInscriptionsResponse =
      hasSpreadsheetSchoolReinsertionsInscriptionsResponse;
  },
  setSchoolReinsertionsEvolutionPageToExportMutation: (state, school_reinsertion) => {
    state.json_data_school_reinsertion_evolution = null;
    const arrayData = new Array();
    school_reinsertion.forEach((element, index, array) => {
      let activity_type = array[index].activity_type ? array[index].activity_type.name : "";
      let result_type = array[index].result_type ? array[index].result_type.name : "";
      let reinsertion_date = array[index].reinsertion_date
        ? moment(array[index].reinsertion_date).format("DD/MM/YYYY")
        : "";
      let letter_delivery_date = array[index].letter_delivery_date
        ? moment(array[index].letter_delivery_date).format("DD/MM/YYYY")
        : "";
      let deadline_attach_proof_of_enrollment = array[index].deadline_attach_proof_of_enrollment
        ? moment(array[index].deadline_attach_proof_of_enrollment).format("DD/MM/YYYY")
        : "";
      let return_letter_deadline = array[index].return_letter_deadline
        ? moment(array[index].return_letter_deadline).format("DD/MM/YYYY")
        : "";
      let articulator = array[index].articulator ? array[index].articulator : "";
      let reasons_for_non_reinsertion = array[index].reasons_for_non_reinsertion
        ? array[index].reasons_for_non_reinsertion.name
        : "";
      let forwardings = array[index].forwardings ? array[index].forwardings : "";
      let facility = array[index].facility ? array[index].facility.name : "";
      let shift = array[index].shift ? array[index].shift.name : "";
      let serie = array[index].serie ? array[index].serie.name : "";
      let administrative_dependency = array[index].administrative_dependency
        ? array[index].administrative_dependency.name
        : "";
      let teaching_modality = array[index].teaching_modality ? array[index].teaching_modality.name : "";
      let enrollment_date = array[index].enrollment_date
        ? moment(array[index].enrollment_date).format("DD/MM/YYYY")
        : "";
      let student_name = array[index].student ? array[index].student.name : "-";
      let observations = array[index].observations ? array[index].observations : "-";

      const arrayItem = new Array();
      arrayItem["student_name"] = student_name;
      arrayItem["activity_type"] = activity_type;
      arrayItem["result_type"] = result_type;
      arrayItem["reinsertion_date"] = reinsertion_date;
      arrayItem["letter_delivery_date"] = letter_delivery_date;
      arrayItem["articulator"] = articulator;
      arrayItem["reasons_for_non_reinsertion"] = reasons_for_non_reinsertion;
      arrayItem["observations"] = observations;
      arrayItem["forwardings"] = forwardings;
      arrayItem["facility"] = facility;
      arrayItem["shift"] = shift;
      arrayItem["serie"] = serie;
      arrayItem["administrative_dependency"] = administrative_dependency;
      arrayItem["teaching_modality"] = teaching_modality;
      arrayItem["enrollment_date"] = enrollment_date;
      arrayItem["deadline_attach_proof_of_enrollment"] = deadline_attach_proof_of_enrollment;
      arrayItem["return_letter_deadline"] = return_letter_deadline;
      arrayData.push(arrayItem);
    });
    state.json_data_school_reinsertion_evolution = arrayData;
    state.hasSpreadsheetSchoolReinsertionsEvolutionResponse = true;
    state.modalToggleExportSchoolReinsertionsEvolutionSpreadsheet = true;
  },
  setSchoolReinsertionsInscriptionsToExportMutation: (state, inscriptions) => {
    state.json_data_school_reinsertion_inscriptions = null;
    const arrayData = new Array();
    inscriptions.forEach((element, index, array) => {
      let status = util_function.processInscriptionStatuses(array[index].status);
      let equipment = array[index].student.equipment ? array[index].student.equipment.name : "";
      let name = array[index].student.name ? array[index].student.name : "";
      let completed_series = array[index].student.completed_series
        ? array[index].student.completed_series.name
        : "";
      let administrative_dependency = array[index].administrative_dependency
        ? array[index].administrative_dependency.name
        : "";
      let last_school_studied = array[index].student.last_school_studied
        ? array[index].student.last_school_studied.name
        : "";
      let year_of_last_enrollment = array[index].student.year_of_last_enrollment
        ? array[index].student.year_of_last_enrollment.name
        : "";
      let enrollment_date = array[index].enrollment_date ? array[index].enrollment_date : "";
      let teaching_modality = array[index].teaching_modality ? array[index].teaching_modality.name : "";
      let serie = array[index].serie ? array[index].serie.name : "";
      let facility = array[index].facility ? array[index].facility.name : "";
      let area = array[index].student.neighborhood.area ? array[index].student.neighborhood.area.name : "";
      let declaration_document_id = array[index].declaration_document_id ? "Anexado" : "Pendente";
      let array_dates = [];
      if (array[index].student?.teams_attributes) {
        array[index].student.teams_attributes.forEach((team) => {
          if (team.last_presence.updated_at) {
            array_dates.push(team.last_presence.updated_at);
          }
        });
      }
      let last_presence = array_dates.length > 0 ? util_function.getLastPresence(array_dates) : "-";

      const arrayItem = new Array();
      arrayItem["status"] = status;
      arrayItem["area"] = area;
      arrayItem["equipment"] = equipment;
      arrayItem["last_presence"] = last_presence;
      arrayItem["name"] = name;
      arrayItem["completed_series"] = completed_series;
      arrayItem["last_school_studied"] = last_school_studied;
      arrayItem["year_of_last_enrollment"] = year_of_last_enrollment;
      arrayItem["enrollment_date"] = enrollment_date;
      arrayItem["teaching_modality"] = teaching_modality;
      arrayItem["serie"] = serie;
      arrayItem["administrative_dependency"] = administrative_dependency;
      arrayItem["facility"] = facility;
      arrayItem["declaration_document_id"] = declaration_document_id;
      arrayData.push(arrayItem);
    });
    state.json_data_school_reinsertion_inscriptions = arrayData;
    state.hasSpreadsheetSchoolReinsertionsInscriptionsResponse = true;
    state.modalToggleExportSchoolReinsertionsInscriptionsSpreadsheet = true;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setTotalPerPage: (state, totalPerPage) => {
    state.totalPerPage = totalPerPage;
  },
  setOffset: (state, offset) => {
    state.offset = offset;
  },
  setOffsetReinserteds: (state, offsetReinserteds) => {
    state.offsetReinserteds = offsetReinserteds;
  },
  setOffsetNotReinserteds: (state, offsetNotReinserteds) => {
    state.offsetNotReinserteds = offsetNotReinserteds;
  },
  setInscriptionsTotal: (state, inscriptionsTotal) => {
    state.inscriptionsTotal = inscriptionsTotal;
  },

  setSchoolReinsertionsByInscriptionTotalPerPage: (state, schoolReinsertionsByInscriptionTotalPerPage) => {
    state.schoolReinsertionsByInscriptionTotalPerPage = schoolReinsertionsByInscriptionTotalPerPage;
  },
  setSchoolReinsertionsByInscriptionOffset: (state, schoolReinsertionsByInscriptionOffset) => {
    state.schoolReinsertionsByInscriptionOffset = schoolReinsertionsByInscriptionOffset;
  },
  setSchoolReinsertionsByInscriptionTotal: (state, schoolReinsertionsByInscriptionTotal) => {
    state.schoolReinsertionsByInscriptionTotal = schoolReinsertionsByInscriptionTotal;
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setTabSchoolReinsertionViewMutation: (state, type) => {
    state.tabSchoolReinsertionView = type;
  },
  setTabSchoolReinsertionNotReinsertedViewMutation: (state, type) => {
    state.tabSchoolReinsertionNotReinsertedView = type;
  },
  setSeasonMutation: (state, season) => {
    state.season = season;
    let season_information = {
      id: null,
      name: null,
    };
    if (season) {
      (season_information.id = season.id), (season_information.name = season.name);
    }
    sessionStorage.setItem("season_information", JSON.stringify(season_information));
  },
  setSchoolReinsertionsEvolutionSpreadsheetMutation: (state, school_reinsertions_evolution_spreadsheet) => {
    state.school_reinsertions_evolution_spreadsheet = school_reinsertions_evolution_spreadsheet;
  },
  setSchoolReinsertionsInscriptionsSpreadsheetMutation: (
    state,
    school_reinsertions_inscriptions_spreadsheet
  ) => {
    state.school_reinsertions_inscriptions_spreadsheet = school_reinsertions_inscriptions_spreadsheet;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    state.school_reinsertion.id = null;
    state.school_reinsertion.articulator = null;
    state.school_reinsertion.activity_type = null;
    state.school_reinsertion.reinsertion_date = null;
    state.school_reinsertion.result_type = null;
    state.school_reinsertion.serie = null;
    state.school_reinsertion.shift = null;
    state.school_reinsertion.teaching_modality = null;
    state.school_reinsertion.administrative_dependency = null;
    state.school_reinsertion.reasons_for_non_reinsertion_id = null;
    state.school_reinsertion.forwardings = null;
    state.school_reinsertion.letter_delivery_date = null;
    state.school_reinsertion.facility_id = null;
    state.school_reinsertion.enrollment_date = null;
    state.school_reinsertion.school_reinsertion_origin_id = null;
    state.school_reinsertion.observations = null;
    state.school_reinsertion.deadline_attach_proof_of_enrollment = null;
    state.school_reinsertion.return_letter_deadline = null;
    state.activity_type = null;
    state.result_type = null;
    state.serie = null;
    state.shift = null;
    state.teaching_modality = null;
    state.administrative_dependency = null;
    state.articulator = null;
    state.reasons_for_non_reinsertion = null;
    state.facility = null;
  },
  clearQueryNotReinserteds: (state) => {
    state.params.not_reinserted_main_page.status = null;
    state.params.not_reinserted_main_page.inscription = null;
    state.params.not_reinserted_main_page.equipment = null;
    state.params.not_reinserted_main_page.articulator = null;
    state.params.not_reinserted_main_page.institutional_referral = null;
    state.params.not_reinserted_main_page.accompaniment_status = null;
    state.params.reinserted_main_page.teaching_modality = null;
    state.params.reinserted_main_page.enrollment_proof = null;
    state.params.reinserted_main_page.facility = null;
    state.params.reinserted_main_page.serie = null;
    state.params.reinserted_main_page.initial_date_enrollment = null;
    state.params.reinserted_main_page.final_date_enrollment = null;

    state.inscriptions = [];
  },
  clearQueryNotReinsertedsEvolution: (state) => {
    state.params.not_reinserted_evolution.activity_type = null;
    state.params.not_reinserted_evolution.result_type = null;
    state.params.not_reinserted_evolution.articulator = null;
    state.params.not_reinserted_evolution.initial_date = null;
    state.params.not_reinserted_evolution.final_date = null;
    state.school_reinsertions = [];
  },
};

function getUsersAndEquipments() {
  let user_areas_ids = [];
  let user_equipments_ids = [];
  if (state.user_areas) {
    state.user_areas.forEach((item) => {
      user_areas_ids.push(item.id);
    });
  }
  if (state.user_equipments) {
    state.user_equipments.forEach((item) => {
      user_equipments_ids.push(item.id);
    });
  }

  if (state.user_areas_filtered) {
    user_areas_ids = state.user_areas_filtered.id;
  }

  if (state.params.not_reinserted_main_page.equipment) {
    user_equipments_ids = state.params.not_reinserted_main_page.equipment.id;
  }
  return { user_areas_ids, user_equipments_ids };
}

async function getLoadInscriptionsParams(user_areas_ids, user_equipments_ids, payload) {
  while (!state.season) {
    await new Promise((resolve) => setTimeout(resolve, 100));
  }

  return {
    season_id: state.season.id,
    area_id: user_areas_ids,
    equipment_id: user_equipments_ids,
    name_student: state.params.not_reinserted_main_page.inscription
      ? state.params.not_reinserted_main_page.inscription.name
      : null,
    status_inscription: state.params.not_reinserted_main_page.status
      ? state.params.not_reinserted_main_page.status.id
      : null,
    accompaniment_status_id: state.params.not_reinserted_main_page.accompaniment_status
      ? state.params.not_reinserted_main_page.accompaniment_status.id
      : null,
    institutional_referral_id: state.params.not_reinserted_main_page.institutional_referral
      ? state.params.not_reinserted_main_page.institutional_referral.id
      : null,
    articulator: state.params.not_reinserted_main_page.articulator,
    teaching_modality: state.params.reinserted_main_page.teaching_modality
      ? state.params.reinserted_main_page.teaching_modality.id
      : null,
    enrollment_proof_id: state.params.reinserted_main_page.enrollment_proof
      ? state.params.reinserted_main_page.enrollment_proof.id
      : null,
    facility: state.params.reinserted_main_page.facility
      ? state.params.reinserted_main_page.facility.id
      : null,
    serie: state.params.reinserted_main_page.serie ? state.params.reinserted_main_page.serie.id : null,
    initial_date_enrollment: state.params.reinserted_main_page.initial_date_enrollment,
    final_date_enrollment: state.params.reinserted_main_page.final_date_enrollment,
    city_id: localStorage.city_id,
    resource: "school_reinsertions",
    type: payload.type ? payload.type : null,
    perPage: state.totalPerPage,
    offset: state.toggleSchoolReinsertionNotReinserted ? state.offsetNotReinserteds : state.offsetReinserteds,
    by: "students.name",
    order: "ASC",
  };
}

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadSchoolReinsertions: ({ commit }) => {
    getSchoolReinsertions()
      .then((response) => {
        commit("loadSchoolReinsertions", response.data.school_reinsertions);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadSchoolReinsertionsByInscription: async ({ commit }, type) => {
    if (type === "loadDataSpreadsheet" || type === "loadAllDataSpreadsheet") {
      commit("hasSpreadsheetSchoolReinsertionsEvolutionResponseMutation", false);
    }
    const params = {
      inscription_id: type === "loadAllDataSpreadsheet" ? null : sessionStorage.inscription_id,
      activity_type: state.params.not_reinserted_evolution.activity_type
        ? state.params.not_reinserted_evolution.activity_type.id
        : null,
      result_type: state.params.not_reinserted_evolution.result_type
        ? state.params.not_reinserted_evolution.result_type.id
        : null,
      articulator: state.params.not_reinserted_evolution.articulator,
      initial_date: state.params.not_reinserted_evolution.initial_date,
      final_date: state.params.not_reinserted_evolution.final_date,
      perPage: state.schoolReinsertionsByInscriptionTotalPerPage,
      city_id: localStorage.city_id,
      offset: state.schoolReinsertionsByInscriptionOffset,
      type: type,
      tabSchoolReinsertion: type === "loadAllDataSpreadsheet" ? state.tabSchoolReinsertionView : null,
    };
    await getSchoolReinsertionByInscription(params)
      .then(async (response) => {
        if (type === "loadData") {
          if (response.data.school_reinsertions_total < 1) {
            Vue.$toast.warning("Nenhum resultado para o filtro informado!");
          }
          await commit("loadSchoolReinsertions", response.data.school_reinsertions);
          await commit("setSchoolReinsertionsByInscriptionTotal", response.data.school_reinsertions_total);
        } else {
          commit("setSchoolReinsertionsEvolutionSpreadsheetMutation", response.data.school_reinsertions);
          if (state.school_reinsertions_evolution_spreadsheet.length > 0) {
            await commit(
              "setSchoolReinsertionsEvolutionPageToExportMutation",
              response.data.school_reinsertions
            );
          } else {
            commit("hasSpreadsheetSchoolReinsertionsEvolutionResponseMutation", true);
            commit("toggleModalToggleExportSchoolReinsertionstEvolutionSpreadsheet");
          }
        }
      })
      .catch((e) => {
        console.log(e);
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadArticulators: ({ commit }) => {
    getArticulators()
      .then((response) => {
        commit("loadArticulators", response.data.articulators);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadActivityTypes: ({ commit }) => {
    getSchoolReinsertionsActivityTypes()
      .then((response) => {
        commit("loadActivityTypes", response.data.activity_type);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadResultTypes: ({ commit }) => {
    getSchoolReinsertionsResultTypes()
      .then((response) => {
        commit("loadResultTypes", response.data.result_type);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadSeries: ({ commit }) => {
    getSchoolReinsertionsSeries()
      .then((response) => {
        commit("loadSeries", response.data.serie);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadShifts: ({ commit }) => {
    getSchoolReinsertionsShifts()
      .then((response) => {
        commit("loadShifts", response.data.shift);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadTeachingModalities: ({ commit }) => {
    getSchoolReinsertionsTeachingModalities()
      .then((response) => {
        commit("loadTeachingModalities", response.data.teaching_modality);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadAdministrativeDependencies: ({ commit }) => {
    getSchoolReinsertionsAdministrativeDependencies()
      .then((response) => {
        commit("loadAdministrativeDependencies", response.data.administrative_dependency);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadInstitutionalReferrals: ({ commit }) => {
    getInstitutionalReferrals()
      .then((response) => {
        commit("loadInstitutionalReferrals", response.data.institutional_referral);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadReasonsForNonReinsertions: ({ commit }) => {
    getReasonsForNorReinsertions()
      .then((response) => {
        commit("loadReasonsForNonReinsertions", response.data.reasons_for_non_reinsertions);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadFacilities: ({ commit }) => {
    const params = {
      forwarding_policy_id: 2,
      by: "facilities.name",
      order: "ASC",
    };
    getFacilitiesByForwardingPolicy(params)
      .then((response) => {
        commit("loadFacilities", response.data.facilities);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadEquipments: ({ commit }) => {
    const params = {
      city_id: localStorage.city_id,
      area_id: state.user_areas_filtered.id,
    };
    getEquipmentByAreaId(params)
      .then((response) => {
        let equipments = [];
        if (state.user_equipments.length > 0) {
          response.data.equipment.forEach((item) => {
            state.user_equipments.forEach((element) => {
              if (item.id === element.id) {
                equipments.push(item);
              }
            });
          });
        } else {
          equipments = response.data.equipment;
        }
        commit("setEquipmentsForSchoolReinsertionMutation", equipments);
        commit("setFilterEquipmentNotReinsertedMainPageMutation", null);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadSeasons: ({ commit }) => {
    const cityId = localStorage.city_id;
    getSeasonsByCityIdForMenus(cityId)
      .then((response) => {
        commit("setSeasonsMutation", response.data.seasons);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadInscriptions: async ({ commit }, payload) => {
    if (payload.data === "spreadsheet") {
      commit("hasSpreadsheetSchoolReinsertionsInscriptionsResponseMutation", false);
    }

    let { user_areas_ids, user_equipments_ids } = getUsersAndEquipments();

    const params = await getLoadInscriptionsParams(user_areas_ids, user_equipments_ids, payload);
    if (payload.data === "spreadsheet") {
      await getInscriptionsForSchoolReinsertionSpreadsheet(params)
        .then(async (response) => {
          commit("setSchoolReinsertionsInscriptionsSpreadsheetMutation", response.data.inscriptions);
          if (state.school_reinsertions_inscriptions_spreadsheet.length > 0) {
            await commit("setSchoolReinsertionsInscriptionsToExportMutation", response.data.inscriptions);
          } else {
            commit("hasSpreadsheetSchoolReinsertionsInscriptionsResponseMutation", true);
            commit("toggleModalToggleExportSchoolReinsertionstInscriptionsSpreadsheet");
          }
        })
        .catch((e) => {
          Vue.$toast.error(e.response.data.error);
        });
    } else {
      await getInscriptionBySeasonAndArea(params)
        .then(async (response) => {
          if (response.data.inscriptionsTotal < 1) {
            Vue.$toast.warning("Nenhum resultado para o filtro informado!");
          }
          await commit("setInscriptionsMutation", response.data.inscriptions);
          await commit("setInscriptionsTotal", response.data.inscriptionsTotal);
        })
        .catch((e) => {
          Vue.$toast.error(e.response.data.error);
        });
    }
  },
  loadInscriptionsForGeneralMenus: ({ commit }, value) => {
    if (state.season) {
      let { user_areas_ids } = getUsersAndEquipments();
      const params = {
        participant_name: value,
        area_id: user_areas_ids,
        season_id: state.season.id,
        perPage: 10,
        is_filter: true,
      };
      getInscriptionForGeneralMenus(params)
        .then((response) => {
          commit("loadInscriptionsForGeneralMenus", response.data.inscriptions);
        })
        .catch((e) => {
          console.log("error", e.response);
        });
    }
  },
  loadInscriptionByIdForSchoolReinsertion: async ({ commit }, inscription_id) => {
    const params = {
      id: inscription_id,
      resource: "school_reinsertion",
    };
    await getInscriptionByIdAndResource(params)
      .then(async (response) => {
        await commit("setInscriptionMutation", response.data.inscription);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getSchoolReinsertionById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.school_reinsertion.id);
        commit("setActivityTypeMutation", response.data.school_reinsertion.activity_type);
        commit("setEnrollmentDateMutation", response.data.school_reinsertion.enrollment_date);
        commit(
          "setDeadlineAttachProofOfEnrollmentMutation",
          response.data.school_reinsertion.deadline_attach_proof_of_enrollment
        );
        commit("setReturnLetterDeadlineMutation", response.data.school_reinsertion.return_letter_deadline);
        commit("setForwardingsMutation", response.data.school_reinsertion.forwardings);
        commit("setObservationsMutation", response.data.school_reinsertion.observations);
        commit("setInscriptionMutation", response.data.school_reinsertion.inscription);
        commit("setLetterDeliveryDateMutation", response.data.school_reinsertion.letter_delivery_date);
        commit("setReinsertionDateMutation", response.data.school_reinsertion.reinsertion_date);
        commit("setFacilityMutation", response.data.school_reinsertion.facility);
        commit("setSerieMutation", response.data.school_reinsertion.serie);
        commit("setShiftMutation", response.data.school_reinsertion.shift);
        commit("setTeachingModalityMutation", response.data.school_reinsertion.teaching_modality);
        commit(
          "setAdministrativeDependencyMutation",
          response.data.school_reinsertion.administrative_dependency
        );
        commit("setUserMutation", response.data.school_reinsertion.user);
        commit("setResultTypeMutation", response.data.school_reinsertion.result_type);
        commit("setArticulatorMutation", response.data.school_reinsertion.articulator);
        commit(
          "setReasonsForNorReinsertionMutation",
          response.data.school_reinsertion.reasons_for_non_reinsertion
        );
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setUserAreas: ({ commit }, payload) => {
    commit("setUserAreasMutation", payload);
  },
  setActivityType: ({ commit }, payload) => {
    commit("setActivityTypeMutation", payload);
  },
  setResultType: ({ commit }, payload) => {
    commit("setResultTypeMutation", payload);
  },
  setSerie: ({ commit }, payload) => {
    commit("setSerieMutation", payload);
  },
  setShift: ({ commit }, payload) => {
    commit("setShiftMutation", payload);
  },
  setTeachingModality: ({ commit }, payload) => {
    commit("setTeachingModalityMutation", payload);
  },
  setAdministrativeDependency: ({ commit }, payload) => {
    commit("setAdministrativeDependencyMutation", payload);
  },
  setArticulator: ({ commit }, payload) => {
    commit("setArticulatorMutation", payload.value);
  },
  setReinsertionDate: ({ commit }, payload) => {
    commit("setReinsertionDateMutation", payload.value);
  },
  setReasonsForNorReinsertion: ({ commit }, payload) => {
    commit("setReasonsForNorReinsertionMutation", payload);
  },
  setForwardings: ({ commit }, payload) => {
    commit("setForwardingsMutation", payload.value);
  },
  setObservations: ({ commit }, payload) => {
    commit("setObservationsMutation", payload.value);
  },
  setLetterDeliveryDate: ({ commit }, payload) => {
    commit("setLetterDeliveryDateMutation", payload.value);
  },
  setFacility: ({ commit }, payload) => {
    commit("setFacilityMutation", payload);
  },
  setEnrollmentDate: ({ commit }, payload) => {
    commit("setEnrollmentDateMutation", payload.value);
  },
  setDeadlineAttachProofOfEnrollment: ({ commit }, payload) => {
    commit("setDeadlineAttachProofOfEnrollmentMutation", payload.value);
  },
  setReturnLetterDeadline: ({ commit }, payload) => {
    commit("setReturnLetterDeadlineMutation", payload.value);
  },
  setUser: ({ commit }, payload) => {
    commit("setUserMutation", payload);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddActivity: async ({ commit }) => {
    commit("toggleModalAddSchoolRensertion");
  },
  closeModalAddActivity: ({ commit }) => {
    commit("toggleModalAddSchoolRensertion");
    commit("clearFields");
  },
  openEditModalAddActivity: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddSchoolRensertion");
  },
  closeEditModalAddActivity: ({ commit }) => {
    commit("toggleModalAddSchoolRensertion");
    commit("clearFields");
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewSchoolReinsertion");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewSchoolReinsertion");
    commit("clearFields");
  },
  toggleModalDeleteSchoolReinsertion: ({ commit }, payload) => {
    commit("toggleModalDeleteSchoolReinsertion");
    if (state.modalToggleDeleteSchoolReinsertion && payload) {
      commit("schoolReinsertionForDeletion", payload);
    } else {
      commit("schoolReinsertionForDeletion", null);
    }
  },

  openModalSchoolReinsertionDeadlineAttachProofOfEnrollment: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleEditSchoolReinsertionDeadlineAttachProofOfEnrollment");
  },
  closeModalSchoolReinsertionDeadlineAttachProofOfEnrollment: ({ commit, dispatch }) => {
    commit("toggleEditSchoolReinsertionDeadlineAttachProofOfEnrollment");

    if (state.toggleSchoolReinsertionDeadlineAttachProofOfEnrollment) {
      dispatch("toggleSchoolReinsertionDeadlineAttachProofOfEnrollment");
    }
    commit("clearFields");
  },

  openModalSchoolReinsertionReturnLetterDeadline: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleEditSchoolReinsertionReturnLetterDeadline");
  },
  closeModalSchoolReinsertionReturnLetterDeadline: ({ commit }) => {
    commit("toggleEditSchoolReinsertionReturnLetterDeadline");
    commit("clearFields");
  },

  toggleSchoolReinsertionDeadlineAttachProofOfEnrollment: ({ commit }) => {
    commit("toggleSchoolReinsertionDeadlineAttachProofOfEnrollment");
  },

  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportSchoolReinsertionsEvolutionSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportSchoolReinsertionstEvolutionSpreadsheet");
  },
  closeExportSchoolReinsertionsEvolutionSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportSchoolReinsertionstEvolutionSpreadsheet");
  },
  closeExportSchoolReinsertionsInscriptionSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportSchoolReinsertionstInscriptionsSpreadsheet");
  },

  // --> ACTIONS - FILTERLIST
  setFilterStatusNotReinsertedMainPage: ({ commit }, payload) => {
    commit("setFilterStatusNotReinsertedMainPageMutation", payload);
  },
  setFilterInscriptionNotReinsertedMainPage: ({ commit }, payload) => {
    commit("setFilterInscriptionNotReinsertedMainPageMutation", payload);
  },
  setFilterEquipmentNotReinsertedMainPage: ({ commit }, payload) => {
    commit("setFilterEquipmentNotReinsertedMainPageMutation", payload);
  },
  setFilterArticulatorNotReinsertedMainPage: ({ commit }, payload) => {
    commit("setFilterArticulatorNotReinsertedMainPageMutation", payload.value);
  },
  setUserAreasFiltered: ({ commit }, payload) => {
    commit("setUserAreasFilteredMutation", payload);
    if (!payload) {
      commit("setEquipmentsForSchoolReinsertionMutation", []);
      commit("setFilterEquipmentNotReinsertedMainPageMutation", null);
    }
  },

  setFilterActivityTypeNotReinsertedEvolution: ({ commit }, payload) => {
    commit("setFilterActivityTypeNotReinsertedEvolutionMutation", payload);
  },
  setFilterResultTypeNotReinsertedEvolution: ({ commit }, payload) => {
    commit("setFilterResultTypeNotReinsertedEvolutionMutation", payload);
  },
  setFilterArticulatorNotReinsertedEvolution: ({ commit }, payload) => {
    commit("setFilterArticulatorNotReinsertedEvolutionMutation", payload.value);
  },
  setFilterInitialDateNotReinsertedEvolution: ({ commit }, payload) => {
    commit("setFilterInitialDateNotReinsertedEvolutionMutation", payload.value);
  },
  setFilterFinalDateNotReinsertedEvolution: ({ commit }, payload) => {
    commit("setFilterFinalDateNotReinsertedEvolutionMutation", payload.value);
  },
  setFilterInstitutionalReferralNotReinsertedMainPage: ({ commit }, payload) => {
    commit("setFilterInstitutionalReferralNotReinsertedMainPageMutation", payload);
  },
  setFilterAccompanimentStatusNotReinsertedMainPage: ({ commit }, payload) => {
    commit("setFilterAccompanimentStatusNotReinsertedMainPageMutation", payload);
  },

  setFilterTeachingModalityReinsertedMainPage: ({ commit }, payload) => {
    commit("setFilterTeachingModalityReinsertedMainPageMutation", payload);
  },
  setFilterEnrollmentProofReinsertedMainPage: ({ commit }, payload) => {
    commit("setFilterEnrollmentProofReinsertedMainPageMutation", payload);
  },
  setFilterFacilityReinsertedMainPage: ({ commit }, payload) => {
    commit("setFilterFacilityReinsertedMainPageMutation", payload);
  },
  setFilterSerieReinsertedMainPage: ({ commit }, payload) => {
    commit("setFilterSerieReinsertedMainPageMutation", payload);
  },
  setFilterInitialDateEnrollmentReinsertedMainPage: ({ commit }, payload) => {
    commit("setFilterInitialDateEnrollmentReinsertedMainPageMutation", payload.value);
  },
  setFilterFinalDateEnrollmentReinsertedMainPage: ({ commit }, payload) => {
    commit("setFilterFinalDateEnrollmentReinsertedMainPageMutation", payload.value);
  },

  // --> ACTIONS - SETTING TABS <--
  setTabSchoolReinsertionView: ({ commit }, payload) => {
    commit("setTabSchoolReinsertionViewMutation", payload);
    switch (payload) {
      case "notReinserted":
        commit("toggleSchoolReinsertionNotReinserted", true);
        commit("toggleSchoolReinsertionReinserted", false);
        commit("toggleSchoolReinsertionNotReinsertedVacancyRequest", false);
        break;
      case "reinserted":
        commit("toggleSchoolReinsertionNotReinserted", false);
        commit("toggleSchoolReinsertionReinserted", true);
        commit("toggleSchoolReinsertionNotReinsertedVacancyRequest", false);
        break;
      case "vacancy_request":
        commit("toggleSchoolReinsertionNotReinserted", false);
        commit("toggleSchoolReinsertionReinserted", false);
        commit("toggleSchoolReinsertionNotReinsertedVacancyRequest", true);
        break;
      // case "plannedAwarenessActivity":
      //   commit("toggleSchoolReinsertionNotReinserted", false);
      //   commit("toggleSchoolReinsertionReinserted", false);
      //   commit("toggleSchoolReinsertionPlannedAwarenessActivity", true);
      //   break;
    }
  },
  setTabSchoolReinsertionNotReinsertedView: async ({ commit, dispatch }, payload) => {
    const inscription_id = sessionStorage.inscription_id;
    await dispatch("loadInscriptionByIdForSchoolReinsertion", inscription_id);
    commit("setTabSchoolReinsertionNotReinsertedViewMutation", payload);
    switch (payload) {
      case "personal_data":
        commit("toggleSchoolReinsertionNotReinsertedPersonalData", true);
        commit("toggleSchoolReinsertionNotReinsertedEvolution", false);
        commit("toggleSchoolReinsertionNotReinsertedVacancyRequest", false);
        break;
      case "evolution":
        commit("toggleSchoolReinsertionNotReinsertedPersonalData", false);
        commit("toggleSchoolReinsertionNotReinsertedEvolution", true);
        commit("toggleSchoolReinsertionNotReinsertedVacancyRequest", false);
        break;
      case "vacancy_request":
        commit("toggleSchoolReinsertionNotReinsertedPersonalData", false);
        commit("toggleSchoolReinsertionNotReinsertedEvolution", false);
        commit("toggleSchoolReinsertionNotReinsertedVacancyRequest", true);
        break;
    }
  },
  // --> ACTIONS - PAGINATIONS <--
  setTotalPerPage: ({ commit }, payload) => {
    commit("setTotalPerPage", payload.totalPerPage);
  },
  setOffset: ({ commit }, payload) => {
    commit("setOffset", payload.offset);
  },
  setOffsetReinserteds: ({ commit }, payload) => {
    commit("setOffsetReinserteds", payload.offset);
  },
  setOffsetNotReinserteds: ({ commit }, payload) => {
    commit("setOffsetNotReinserteds", payload.offset);
  },
  setSchoolReinsertionsByInscriptionTotalPerPage: ({ commit }, payload) => {
    commit("setSchoolReinsertionsByInscriptionTotalPerPage", payload.totalPerPage);
  },
  setSchoolReinsertionsByInscriptionOffset: ({ commit }, payload) => {
    commit("setSchoolReinsertionsByInscriptionOffset", payload.offset);
  },
  // --> OTHERS ACTIONS <--
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },
  setUserEquipments: ({ commit }, payload) => {
    commit("setUserEquipmentsMutation", payload);
  },
  setInscription: async ({ commit }, payload) => {
    await commit("setInscriptionMutation", payload);
  },
  clearQueryNotReinserteds: ({ commit }) => {
    commit("clearQueryNotReinserteds");
  },
  clearQueryNotReinsertedsEvolution: ({ commit }) => {
    commit("clearQueryNotReinsertedsEvolution");
  },

  //ACTIONS - CRUD
  save: ({ dispatch, commit }) => {
    let isRequestedReferralLetter = state.school_reinsertion.result_type === 1;
    let wasReinsertionEffected = state.school_reinsertion.result_type === 2;
    createSchoolReinsertion(state.school_reinsertion)
      .then((response) => {
        if (isRequestedReferralLetter) {
          commit("setActivityTypeMutation", { id: 2 });
          commit("setResultTypeMutation", { id: 1 });
          commit("setReinsertionDateMutation", response.data.data.reinsertion_date);
          commit("setLetterDeliveryDateMutation", response.data.data.letter_delivery_date);
          commit("setReturnLetterDeadlineMutation", response.data.data.return_letter_deadline);
          commit("setArticulatorMutation", response.data.data.articulator);
          commit("setInscriptionMutation", { id: response.data.data.inscription_id });
          commit("setSchoolReinsertionOriginIdMutation", response.data.data.id);
          commit("setUserMutation", { id: response.data.data.user_id });

          createSchoolReinsertion(state.school_reinsertion)
            .then(() => {
              dispatch("loadSchoolReinsertionsByInscription", "loadData");
            })
            .catch((e) => {
              Vue.$toast.error("Não foi possivel cadastrar a atividade! " + e.response.data.error);
              console.log(e);
            });
        }

        if (wasReinsertionEffected) {
          commit("setActivityTypeMutation", { id: 3 });
          commit("setResultTypeMutation", { id: 2 });
          commit("setReinsertionDateMutation", response.data.data.reinsertion_date);
          commit("setFacilityMutation", { id: response.data.data.facility_id });
          commit("setShiftMutation", { id: response.data.data.shift });
          commit("setSerieMutation", { id: response.data.data.serie });
          commit("setTeachingModalityMutation", { id: response.data.data.teaching_modality });
          commit("setAdministrativeDependencyMutation", { id: response.data.data.administrative_dependency });
          commit("setEnrollmentDateMutation", response.data.data.enrollment_date);
          commit(
            "setDeadlineAttachProofOfEnrollment",
            response.data.data.deadline_attach_proof_of_enrollment
          );
          commit("setUserMutation", { id: response.data.data.user_id });
          commit("setArticulatorMutation", response.data.data.articulator);
          commit("setInscriptionMutation", { id: response.data.data.inscription_id });
          commit("setSchoolReinsertionOriginIdMutation", response.data.data.id);

          createSchoolReinsertion(state.school_reinsertion)
            .then(() => {
              dispatch("loadSchoolReinsertionsByInscription", "loadData");
            })
            .catch((e) => {
              Vue.$toast.error("Não foi possivel cadastrar a atividade! " + e.response.data.error);
              console.log(e);
            });
        }
        Vue.$toast.success("Atividade cadastrada com sucesso!");
        dispatch("closeModalAddActivity");
        dispatch("loadSchoolReinsertionsByInscription", "loadData");
        if (state.toggleSchoolReinsertionDeadlineAttachProofOfEnrollment) {
          dispatch("toggleSchoolReinsertionDeadlineAttachProofOfEnrollment");
        }
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar a atividade! " + e.response.data.error);
        console.log(e);
      });
  },
  update: ({ dispatch }) => {
    updateSchoolReinsertion(state.school_reinsertion.id, state.school_reinsertion)
      .then(() => {
        dispatch("loadSchoolReinsertionsByInscription", "loadData");
        if (state.modalToggleAddSchoolReinsertion) {
          dispatch("closeEditModalAddActivity");
        }
        if (state.toggleSchoolReinsertionDeadlineAttachProofOfEnrollment) {
          dispatch("toggleSchoolReinsertionDeadlineAttachProofOfEnrollment");
        }
        if (state.modalToggleEditSchoolReinsertionDeadlineAttachProofOfEnrollment) {
          dispatch("closeModalSchoolReinsertionDeadlineAttachProofOfEnrollment");
        }
        if (state.modalToggleEditSchoolReinsertionReturnLetterDeadline) {
          dispatch("closeModalSchoolReinsertionReturnLetterDeadline");
        }

        Vue.$toast.success("Atividade atualizada com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar a atividade! ");
        console.log(e);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroySchoolReinsertion(state.schoolReinsertionId)
      .then(() => {
        Vue.$toast.success("Atividade deletada com sucesso!");
        commit("toggleModalDeleteSchoolReinsertion");
        dispatch("loadSchoolReinsertionsByInscription", "loadData");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },

  activeMonitoring: ({ dispatch }, inscription) => {
    const params = {
      id: inscription.id,
    };
    activeMonitoringSchoolReinsertion(params)
      .then((response) => {
        dispatch("loadSchoolReinsertionsByInscription", "loadData");
        Vue.$toast.success(response?.data?.message);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error + "!");
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
