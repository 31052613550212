var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.payment_sheet.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar Folha de Pagamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Cadastrar Folha de Pagamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch: "PaymentSheets/closeModalAddPaymentSheet",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações Gerais",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _vm.isScholarshipPage
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "label-text": "Matriz",
                                "show-label": "",
                                "clear-on-select": "",
                                required: "",
                                "select-placeholder": "Selecione a matriz",
                                "multiple-option": false,
                                value: _vm.scholarship_matrix,
                                options: _vm.scholarship_matrices_for_menus,
                                dispatch: _vm.setScholarshipMatrix,
                                "error-message":
                                  _vm.getErrorMessage("matriz de bolsa"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isScholarshipPage
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                id: "payment_sheet_start_date",
                                "input-name": "start_date",
                                "input-type": "date",
                                "show-label": "",
                                required: "",
                                "label-text": "Data Inicial",
                                value: _vm.payment_sheet.start_date,
                                dispatch: "PaymentSheets/setStartDate",
                                "error-message":
                                  _vm.getErrorMessage("data inicial"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isScholarshipPage
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                id: "payment_sheet_end_date",
                                "input-name": "end_date",
                                "input-type": "date",
                                "show-label": "",
                                required: "",
                                "label-text": "Data Final",
                                value: _vm.payment_sheet.end_date,
                                dispatch: "PaymentSheets/setEndDate",
                                "error-message":
                                  _vm.getErrorMessage("data final"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isTransportationVoucherPage
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                id: "payment_sheet_reference",
                                "input-name": "reference",
                                "input-type": "text",
                                "show-label": "",
                                required: "",
                                "label-text": "Referência",
                                value: _vm.payment_sheet.reference,
                                dispatch: "PaymentSheets/setReference",
                                "error-message":
                                  _vm.getErrorMessage("referência"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "payment_sheet_process_number",
                            "input-name": "process_number",
                            "input-type": "text",
                            "show-label": "",
                            required: "",
                            "label-text": "Nº do Processo",
                            value: _vm.payment_sheet.process_number,
                            dispatch: "PaymentSheets/setProcessNumber",
                            "error-message":
                              _vm.getErrorMessage("nº do processo"),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.isScholarshipPage
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "label-text": "Parcela",
                                "show-label": "",
                                "clear-on-select": "",
                                required: "",
                                "select-placeholder": "Selecione a parcela",
                                "multiple-option": false,
                                value: _vm.installment,
                                options: _vm.payment_sheet_installment_options,
                                dispatch: _vm.setInstallment,
                                "error-message": _vm.getErrorMessage("parcela"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.isScholarshipPage
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "label-text": "Remessa",
                                "show-label": "",
                                "clear-on-select": "",
                                required: "",
                                "select-placeholder": "Selecione a remessa",
                                "multiple-option": false,
                                value: _vm.remittance,
                                options: _vm.payment_sheet_remittance_options,
                                dispatch: _vm.setRemittance,
                                "error-message": _vm.getErrorMessage("remessa"),
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch: "PaymentSheets/closeModalAddPaymentSheet",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.payment_sheet.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Atualizar",
                                typeButton: "success",
                                dispatch: "PaymentSheets/update",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "Criar",
                                typeButton: "success",
                                dispatch: "PaymentSheets/save",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3136168595
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }