var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("Label", {
                          staticClass: "text-ashes-dark",
                          attrs: {
                            text: "Selecionar campos para Exportação",
                            icon: null,
                            "icon-type": null,
                            type: "text-2xl",
                            weight: "bold",
                            normal: "",
                            mode: "normal-case",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "SelectFieldsToExport/closeModalSelectFieldsToExport",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Campos",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "grid grid-cols-3 gap-4 mt-2" },
                    _vm._l(_vm.fields, function (field, index) {
                      return _c(
                        "div",
                        {
                          key: index,
                          staticClass:
                            "app-checkbox flex items-center border-b pb-2",
                        },
                        [
                          _c("input", {
                            staticClass: "mr-2",
                            attrs: {
                              id: _vm.checkboxId(field, index),
                              type: "checkbox",
                              name: "fields",
                            },
                            domProps: { value: field, checked: field.checked },
                            on: {
                              click: function ($event) {
                                return _vm.onCheck(field, index, $event)
                              },
                            },
                          }),
                          _c("BaseText", {
                            staticClass: "flex",
                            attrs: {
                              text: index,
                              typeText: "custom",
                              weight: "semibold",
                              italic: false,
                              mode: "capitalize",
                              size: "lg",
                              target: _vm.checkboxId(field, index),
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "SelectFieldsToExport/closeModalSelectFieldsToExport",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "Avançar",
                            typeButton: "success",
                            dispatch: "SelectFieldsToExport/move_on",
                            payload: _vm.openModalDispatch,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2217476470
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }