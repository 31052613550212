var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "transition-group",
    _vm._b(
      {
        attrs: { name: "staggered-fade", css: false, appear: "" },
        on: {
          "before-enter": _vm.beforeEnter,
          enter: _vm.enter,
          leave: _vm.leave,
        },
      },
      "transition-group",
      _vm.$attrs,
      false
    ),
    [_vm._t("default")],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }