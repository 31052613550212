var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
    _c(
      "div",
      { staticClass: "justify-self-start" },
      [
        _c("AppButtonEmpty", {
          attrs: {
            text: "descartar",
            typeButton: "danger",
            dispatch: _vm.closeDispatch,
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "justify-self-end" },
      [
        _vm.psychosocial_accompaniment?.id
          ? _c("AppButtonEmpty", {
              attrs: {
                text: "Salvar",
                typeButton: "success",
                dispatch: _vm.updateDispatch,
              },
            })
          : _c("AppButtonEmpty", {
              attrs: {
                text: "criar",
                typeButton: "success",
                dispatch: _vm.saveDispatch,
              },
            }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }