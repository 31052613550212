var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { class: _vm.liClass }, [
    _c("div", { staticClass: "grid grid-cols-6 items-center" }, [
      _c(
        "div",
        { staticClass: "flex col-span-3 items-center w-100" },
        [
          _c("div", { class: _vm.iconClass }, [
            _c("img", {
              attrs: { src: require("../assets/images/profile-vj.png") },
            }),
          ]),
          _c("BaseText", {
            staticClass: "mr-1",
            attrs: {
              text: _vm.getNameStudent(),
              typeText: "custom",
              mode: "underline",
              weight: "bold",
              size: "2xl",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "items-center w-60 ml-0" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.student.cellphone_number,
              typeText: "custom",
              size: "sm",
              color: "ashes-dark",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "flex items-center ml-5 col-span-2" }, [
        _c(
          "div",
          { staticClass: "justify-items-end ml-0" },
          [
            _c("BaseText", {
              attrs: {
                text:
                  typeof _vm.student.presence_percent !== "undefined"
                    ? _vm.student.presence_percent + "%"
                    : "0%",
                typeText: "custom",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _vm.student.presence_percent < 25
          ? _c(
              "div",
              { staticClass: "flex items-center ml-1 pr-1 col-span-1 tooltip" },
              [
                _c(
                  "button",
                  {
                    ref: "btnRef",
                    on: {
                      mouseenter: function ($event) {
                        return _vm.toggleTooltip()
                      },
                      mouseleave: function ($event) {
                        return _vm.toggleTooltip()
                      },
                    },
                  },
                  [
                    _c(
                      "span",
                      { staticClass: "material-icons mt-2 text-yellow-400" },
                      [
                        _c("span", { staticClass: "material-icons" }, [
                          _vm._v(" warning "),
                        ]),
                      ]
                    ),
                  ]
                ),
                _c(
                  "div",
                  {
                    ref: "tooltipRef",
                    staticClass:
                      "bg-white border border-black-600 mr-3 block font-normal leading-normal text-xs text-left break-words rounded-lg tooltiptext shadow-xl w-40",
                    class: { hidden: !_vm.tooltipShow, block: _vm.tooltipShow },
                  },
                  [
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "text-blue-700 font-semibold p-2" },
                        [
                          _c(
                            "span",
                            { staticClass: "material-icons text-yellow-400" },
                            [
                              _c("span", { staticClass: "material-icons" }, [
                                _vm._v(" warning "),
                              ]),
                            ]
                          ),
                          _c("span", { staticClass: "tolltiptext-position" }, [
                            _vm._v(" Risco de Evasão!"),
                          ]),
                        ]
                      ),
                    ]),
                  ]
                ),
              ]
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }