import { render, staticRenderFns } from "./AtendentsCards.vue?vue&type=template&id=4bd2d61a&scoped=true"
import script from "./AtendentsCards.vue?vue&type=script&lang=js"
export * from "./AtendentsCards.vue?vue&type=script&lang=js"
import style0 from "./AtendentsCards.vue?vue&type=style&index=0&id=4bd2d61a&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4bd2d61a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4bd2d61a')) {
      api.createRecord('4bd2d61a', component.options)
    } else {
      api.reload('4bd2d61a', component.options)
    }
    module.hot.accept("./AtendentsCards.vue?vue&type=template&id=4bd2d61a&scoped=true", function () {
      api.rerender('4bd2d61a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/list/AtendentsCards.vue"
export default component.exports