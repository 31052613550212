var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-ashes-hover font-medium text-3xl w-max mb-7",
                      },
                      [
                        _vm._v(
                          " " +
                            _vm._s(_vm.city.name) +
                            " | Transferência entre Turmas "
                        ),
                      ]
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch: "Season/closeModalTransferStudentOpen",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "w-auto mr-4" }, [
                    _c(
                      "div",
                      { staticClass: "grid grid-cols-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            id: "select-phase",
                            "show-label": "",
                            "close-on-select": "",
                            "label-text":
                              "Selecione a fase para que as turmas sejam carregadas",
                            required: "",
                            "select-placeholder": "Selecione as opções",
                            "clear-on-select": false,
                            "multiple-option": false,
                            value: _vm.phase,
                            options: _vm.phases,
                            dispatch: _vm.setPhase,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("br"),
                  _c("br"),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _vm.phase?.id
                    ? _c("div", [
                        _vm.isLoadedTeam
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "justify-self-start col-span-2 mb-5",
                              },
                              [
                                _c("Label", {
                                  staticClass: "text-ashes-dark",
                                  attrs: {
                                    text: "Selecione a Turma de Origem:",
                                    icon: null,
                                    "icon-type": null,
                                    type: "text-lg",
                                    weight: "bold",
                                    normal: "",
                                    mode: "normal-case",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isLoadedTeam
                          ? _c(
                              "div",
                              {
                                staticClass: "grid grid-flow-row auto-rows-max",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-flow-row auto-rows-max",
                                  },
                                  [
                                    _c("TransferStudentCardsTeamSource", {
                                      attrs: {
                                        data: _vm.inscriptionStudentTeamsAttributes,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm.isLoadedTeam
                          ? _c(
                              "div",
                              {
                                staticClass:
                                  "justify-self-start col-span-2 mb-5",
                              },
                              [
                                _c("Label", {
                                  staticClass: "text-ashes-dark",
                                  attrs: {
                                    text: "Selecione a Turma de Destino:",
                                    icon: null,
                                    "icon-type": null,
                                    type: "text-lg",
                                    weight: "bold",
                                    normal: "",
                                    mode: "normal-case",
                                  },
                                }),
                              ],
                              1
                            )
                          : _vm._e(),
                        _vm.isLoadedTeam
                          ? _c(
                              "div",
                              {
                                staticClass: "grid grid-flow-row auto-rows-max",
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "grid grid-flow-row auto-rows-max",
                                  },
                                  [
                                    _c("TransferStudentCardsTeamTarget", {
                                      attrs: { data: _vm.teams },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            )
                          : _vm._e(),
                      ])
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch: "Season/closeModalTransferStudentOpen",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "justify-self-end" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.transferStudent()
                            },
                          },
                        },
                        [
                          _c("AppButtonEmpty", {
                            attrs: {
                              text: "Salvar Alterações",
                              typeButton: "success",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1784521293
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }