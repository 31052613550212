import { getAxios, baseURL } from "./Api";

export async function getAtendents(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/atendentes_psicossocial`;
  return api.get(url, { params: filters });
}

export async function getPsychosocialAttendantsForPsychosocial() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`atendentes_psicossocial/listar_tecnicos_psicossocial`);
}

export async function getAtendentsByCity(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let url = `/cidades/${id}/atendentes_psicossocial/`;

  return api.get(url);
}

export async function getAtendent(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let url = `/atendentes_psicossocial/${id}`;

  return api.get(url);
}

export async function createAtendent(atendent) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.post("/atendentes_psicossocial", atendent);
}
export async function createAtendentEquip(params) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.post("/equipamentos_por_atendente", params);
}

export async function destroyAtendentEquip(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`equipamentos_por_atendente/${id}`);
}

export async function resetPassword(id, password, password_confirmation) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post(`usuarios/${id}/reset_password`, {
    password: password,
    password_confirmation: password_confirmation,
  });
}

export async function destroyAtendent(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`atendentes_psicossocial/${id}`);
}

export async function updateAtendent(id, atendent) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`atendentes_psicossocial/${id}`, atendent);
}
