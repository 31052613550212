<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="course.id"
            text="Editar curso"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar curso"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Course/closeModal" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-rols-5 gap-5">
        <div class="grid grid-rols-2">
          <div>
            <BaseText text="informações gerais" typeText="title-forms" :italic="false" />
          </div>
          <div class="border border-ashes-hover w-full"></div>
        </div>
        <div>
          <BaseInput
            id="nome"
            active
            inputName="nome"
            inputType="text"
            required
            labelText="Nome"
            showLabel
            :value="course.name"
            dispatch="Course/setName"
            :error-message="getErrorMessage('name', course.name)"
          />
        </div>
        <div>
          <BaseTextArea
            active
            inputName="description"
            required
            labelText="Descrição"
            showLabel
            rows="4"
            :value="course.description"
            dispatch="Course/setDescription"
            :error-message="getErrorMessage('description', course.description)"
          />
        </div>
        <!--        <div class="grid grid-cols-2 gap-5">-->
        <!--          <div>-->
        <!--            <div>-->
        <!--              <Label-->
        <!--                text="Fase 02"-->
        <!--                :icon="null"-->
        <!--                :icon-type="null"-->
        <!--                type="text-2xl"-->
        <!--                weight="bold"-->
        <!--                normal-->
        <!--                mode="normal-case"-->
        <!--                class="text-ashes-dark"-->
        <!--              />-->
        <!--            </div>-->
        <!--          </div>-->
        <!--          <div>-->
        <!--            <Multiselect-->
        <!--              id="discipline"-->
        <!--              closeOnSelect-->
        <!--              showLabel-->
        <!--              selectPlaceholder="Selecione a opção"-->
        <!--              labelText="Módulo"-->
        <!--              :multipleOption="false"-->
        <!--              :value="{ id: course.discipline.id, name: course.discipline.name }"-->
        <!--              :options="disciplines"-->
        <!--              :dispatch="setDiscipline"-->
        <!--              :error-message="getErrorMessage('discipline', course.discipline_id)"-->
        <!--              required-->
        <!--            />-->
        <!--          </div>-->
        <!--        </div>-->
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Course/closeModal" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="course.id"
            text="Salvar"
            typeButton="success"
            dispatch="Course/updateCourse"
          />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="Course/createCourse" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseTextArea from "../components/forms/BaseTextArea.vue";
// import Multiselect from "../components/forms/Multiselect.vue";
import Label from "../components/Label.vue";
import BaseText from "../components/BaseText.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapActions, mapGetters } from "vuex";

export default {
  components: {
    BaseModal,
    BaseInput,
    BaseTextArea,
    // Multiselect,
    Label,
    BaseText,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.$store.dispatch("Courses/loadDisciplines");
  },
  computed: {
    ...mapState({
      disciplines: (state) => state.Courses.disciplines,
      course: (state) => state.Course.course,
    }),
    ...mapGetters({
      getErrorMessage: "Course/getErrorMessage",
    }),
  },
  methods: {
    ...mapActions({
      setDiscipline: "Course/setDiscipline",
    }),
  },
};
</script>
