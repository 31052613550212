var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { class: _vm.left },
      [
        _c("BaseText", {
          attrs: {
            text: _vm.getTotalRegisters(),
            typeText: "custom",
            italic: false,
            size: "base",
            color: "burnedYellow",
          },
        }),
      ],
      1
    ),
    _c("div", { class: _vm.paginationTableCss }, [
      _c(
        "div",
        {
          class: _vm.controlFirstCss,
          on: {
            click: function ($event) {
              return _vm.goTo(1)
            },
          },
        },
        [_c("Label", { attrs: { text: "", icon: "angle-double-left" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "previous",
          class: _vm.controlsCss,
          on: {
            click: function ($event) {
              return _vm.previous()
            },
          },
        },
        [_c("Label", { attrs: { text: "", icon: "angle-left" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "numbers-page", class: _vm.controlsCss },
        _vm._l(_vm.pages, function (i) {
          return _c(
            "div",
            {
              key: i,
              class: _vm.numberPageClass(i),
              on: {
                click: function ($event) {
                  return _vm.goTo(i)
                },
              },
            },
            [_vm._v(" " + _vm._s(i) + " ")]
          )
        }),
        0
      ),
      this.currentPage != this.totalPage() && this.totalPage() >= 10
        ? _c("div", { staticClass: "numbers-page" }, [
            _c("div", [_vm._v(" " + _vm._s(" ...") + " ")]),
          ])
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "next",
          class: _vm.controlsCss,
          on: {
            click: function ($event) {
              return _vm.next()
            },
          },
        },
        [_c("Label", { attrs: { text: "", icon: "angle-right" } })],
        1
      ),
      _c(
        "div",
        {
          staticClass: "last",
          class: _vm.controlsCss,
          on: {
            click: function ($event) {
              _vm.goTo(_vm.totalPage())
            },
          },
        },
        [_c("Label", { attrs: { text: "", icon: "angle-double-right" } })],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }