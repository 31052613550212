var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex items-center ml-1 pr-1 col-span-1 tooltip" },
    [
      _c(
        "button",
        {
          ref: "btnRef",
          class: _vm.buttonClass(),
          attrs: { type: "button" },
          on: {
            mouseenter: function ($event) {
              return _vm.toggleTooltip()
            },
            mouseleave: function ($event) {
              return _vm.toggleTooltip()
            },
          },
        },
        [
          _c("AppMaterialIconStudent", {
            attrs: {
              iconName: _vm.whatIsMyName(),
              iconType: _vm.iconType,
              iconSize: _vm.iconSize,
            },
          }),
        ],
        1
      ),
      _vm.whatIsMyName() == "warning"
        ? _c(
            "div",
            {
              ref: "tooltipRef",
              staticClass:
                "bg-white text-center border border-black-600 mr-3 block font-normal leading-normal text-xs text-left break-words rounded-lg tooltiptext shadow-xl w-52",
              class: { hidden: !_vm.tooltipShow, block: _vm.tooltipShow },
            },
            [_vm._m(0)]
          )
        : _vm._e(),
      _vm.whatIsMyName() == "more_vert"
        ? _c(
            "div",
            {
              ref: "tooltipRef",
              staticClass:
                "bg-white text-left border border-black-600 mr-3 block font-normal leading-normal text-sm rounded-lg tooltiptext shadow-xl w-40",
              class: { hidden: !_vm.tooltipShow, block: _vm.tooltipShow },
            },
            [_vm._m(1)]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "text-gray-400 font-semibold p-2" }, [
        _c("span", [
          _vm._v(" Aluno(a) em risco de evasão! "),
          _c("br"),
          _vm._v(" Frequência abaixo de 25% !"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", [
        _c("ul", { staticClass: "text-gray-400 font-semibold p-3" }, [
          _c("li", [_c("button", [_vm._v("Transferir")])]),
          _c("li", [_c("button", [_vm._v("Desligar")])]),
          _c("li", [_c("button", [_vm._v("Remover de Turma")])]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }