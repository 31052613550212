<template>
  <li :class="liClass" @click="openEdition">
    <!--    <div :class="divClass">-->
    <!--      <div class="flex items-center">-->
    <!--        <Label-->
    <!--          :text="edition.name"-->
    <!--          :icon="null"-->
    <!--          :iconType="null"-->
    <!--          type="text-xl"-->
    <!--          :weight="edition.date_manual_execution_end ? 'medium' : 'extrabold'"-->
    <!--          normal-->
    <!--          mode="normal-case"-->
    <!--          class="ml-2"-->
    <!--        />-->
    <!--        <AppMaterialIcon-->
    <!--          v-tooltip="{ content: areasTooltipContent, classes: 'custom-tooltip' }"-->
    <!--          v-if="true"-->
    <!--          iconName="info"-->
    <!--          class="icon-input text-quantum cursor-pointer inline-flex relative"-->
    <!--        />-->
    <!--      </div>-->
    <!--      <div>-->
    <!--        <span v-for="area in edition.areas_attributes" :key="area.code">-->
    <!--          |-->
    <!--          <Label-->
    <!--            :text="area.name"-->
    <!--            :icon="null"-->
    <!--            :iconType="null"-->
    <!--            type="text-xs"-->
    <!--            weight="medium"-->
    <!--            normal-->
    <!--            mode="uppercase"-->
    <!--            class="text-burnedYellow"-->
    <!--          />-->
    <!--        </span>-->
    <!--        |-->
    <!--      </div>-->
    <!--    </div>-->
    <div class="grid grid-cols-custom items-center">
      <div class="flex items-center">
        <Label
          :text="edition.name"
          :icon="null"
          :iconType="null"
          type="text-xl"
          :weight="edition.date_manual_execution_end ? 'medium' : 'extrabold'"
          normal
          mode="normal-case"
          class="ml-2 text-burnedYellow"
        />
        <AppMaterialIcon
          v-tooltip="{ content: areasTooltipContent, classes: 'custom-tooltip' }"
          v-if="true"
          iconName="info"
          class="icon-input text-quantum cursor-pointer inline-flex relative"
        />
      </div>
      <div>
        <Label
          :text="edition.date_manual_execution_end ? 'Encerrada' : 'Ativa'"
          :iconType="null"
          type="text-xs"
          icon="none"
          weight="medium"
          normal
          mode="uppercase"
          class="text-ashes-dark"
        />
      </div>
      <div>
        <Label
          :text="edition.date_inscription_start"
          :iconType="null"
          type="text-xs"
          icon="none"
          weight="medium"
          normal
          mode="uppercase"
          class="text-ashes-dark"
        />
        -
        <Label
          :text="edition.date_inscription_end"
          :iconType="null"
          type="text-xs"
          icon="none"
          weight="medium"
          normal
          mode="uppercase"
          class="text-ashes-dark"
        />
      </div>
      <div>
        <Label
          :text="edition.inscription_status"
          :iconType="null"
          type="text-xs"
          icon="none"
          weight="bold"
          normal
          mode="uppercase"
          :class="statusClassInscription"
        />
      </div>
      <div>
        <Label
          :text="edition.date_execution_start"
          :iconType="null"
          type="text-xs"
          icon="none"
          weight="medium"
          normal
          mode="uppercase"
          class="text-ashes-dark"
        />
        -
        <Label
          :text="edition.date_execution_end"
          :iconType="null"
          type="text-xs"
          icon="none"
          weight="medium"
          normal
          mode="uppercase"
          class="text-ashes-dark"
        />
      </div>
      <div>
        <Label
          :text="edition.execution_status"
          :iconType="null"
          type="text-xs"
          icon="none"
          weight="bold"
          normal
          mode="uppercase"
          :class="statusClassExecution"
        />
      </div>
      <div>
        <Label
          :text="edition.qtdInscriptions + ' Alunos'"
          :iconType="null"
          type="text-xs"
          icon="none"
          :weight="edition.date_manual_execution_end ? 'medium' : 'bold'"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          :text="edition.qtdInscriptionsAccepted + ' Alunos'"
          :iconType="null"
          type="text-xs"
          icon="none"
          :weight="edition.date_manual_execution_end ? 'medium' : 'bold'"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          :text="edition.qtdInscriptionsNotAccepted + ' Alunos'"
          :iconType="null"
          type="text-xs"
          icon="none"
          :weight="edition.date_manual_execution_end ? 'medium' : 'bold'"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          :text="edition.qtdInscriptionsGraduated + ' Alunos'"
          :iconType="null"
          type="text-xs"
          icon="none"
          :weight="edition.date_manual_execution_end ? 'medium' : 'bold'"
          normal
          mode="uppercase"
        />
      </div>
      <div :class="editBtn" v-if="!edition.date_manual_execution_end && permitUpdate">
        <AppButtonIcon iconName="edit" dispatch="Seasons/openEditModal" :payload="edition" />
      </div>
    </div>
  </li>
</template>

<script>
import Label from "../Label.vue";
import AppButtonIcon from "../AppButtonIcon.vue";
import AppMaterialIcon from "@/components/AppMaterialIcon.vue";
import { mapActions } from "vuex";

export default {
  name: "app-edition",

  components: {
    Label,
    AppButtonIcon,
    AppMaterialIcon,
  },
  props: {
    edition: {
      type: Object,
    },
  },
  data() {
    return {
      permitUpdate: false,
    };
  },
  created() {
    this.updateSeason();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    updateSeason() {
      this.canAcces("season_update").then((response) => {
        this.permitUpdate = response;
      });
    },
    openEdition() {
      this.$emit("toSeason", { id: this.edition.id });
      localStorage.setItem("season_id", this.edition.id);
      localStorage.setItem("season_date_manual_execution_end", this.edition.date_manual_execution_end);
    },
  },
  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-6": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
        "cursor-pointer": true,
      };
    },
    divClass() {
      return {
        grid: true,
        "grid-rows-2": true,
        "content-center": true,
        "mr-6": true,
      };
    },
    editBtn() {
      return {
        "text-right": true,
      };
    },
    statusClassExecution() {
      let css = {};

      switch (this.statusExecution) {
        case "Encerrado":
          css = "text-black";
          break;
        case "Em andamento":
          css = "text-burnedYellow-classic";
          break;
        case "Não iniciado":
          css = "text-ashes-hover";
          break;
      }

      return css;
    },
    statusClassInscription() {
      let css = {};

      switch (this.statusInscription) {
        case "Encerrado":
          css = "text-black";
          break;
        case "Em andamento":
          css = "text-burnedYellow-classic";
          break;
        case "Não iniciado":
          css = "text-ashes-hover";
          break;
      }

      return css;
    },
    teste() {
      return "teste";
    },
    areasTooltipContent() {
      // Gera o conteúdo HTML
      return (
        this.edition.areas_attributes
          .map(
            (area) => `
        <div>| <span class="text-burnedYellow uppercase font-medium text-xs">${area.name}</span></div>
      `
          )
          .join("") + "<div></div>"
      );
    },
  },
};
</script>
<style scoped>
.grid-cols-custom {
  grid-template-columns: 69% 57% 48% 39% 53% 49% 59% 59% 68% 92% 12%;
}
.custom-tooltip {
  background-color: #333 !important;
}
</style>
