var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "tooltip-container",
      on: { mouseenter: _vm.showTooltip, mouseleave: _vm.hideTooltip },
    },
    [
      _vm._t("default"),
      _c("transition", { attrs: { name: "fade" } }, [
        _vm.visible
          ? _c(
              "div",
              { staticClass: "tooltip-content", style: _vm.tooltipStyle },
              [_vm._v(" " + _vm._s(_vm.text) + " ")]
            )
          : _vm._e(),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }