<template>
  <div class="grid auto-grid-auto p-10" v-if="permitList">
    <div>
      <BaseText
        :text="city.name + ' | Cursos'"
        typeText="custom"
        weight="semibold"
        :italic="false"
        size="3xl"
        color="ashes-hover"
      />
    </div>
    <div class="grid grid-cols-2 justify-items-stretch my-8">
      <div class="grid grid-flow-col items-center gap-5 auto-cols-max">
        <div>
          <BaseText
            text="Módulos"
            typeText="custom"
            weight="bold"
            :italic="false"
            mode="uppercase"
            size="xs"
            color="ashes-dark"
          />
        </div>
        <div class="grid grid-flow-col items-center gap-5 auto-cols-max">
          <div :key="index" v-for="(discipline, index) in disciplines">
            <AppButtonStatus
              :text="discipline.name"
              :active="isActive(discipline)"
              :payload="discipline"
              typeButton="primary"
              dispatch="Courses/filterCourses"
              v-if="permit"
            />
          </div>
        </div>
        <div>
          <a href="javascript:void(0)" @click="clearFilter()">
            <img
              class="inline-block"
              src="../assets/images/filter_alt_black_24dp.svg"
              alt=""
              style="cursor: pointer"
            />
            <BaseText
              text="Limpar"
              typeText="custom"
              weight="normal"
              :italic="false"
              size="xs"
              color="burnedYellow"
            />
          </a>
        </div>
      </div>
      <div class="justify-self-end">
        <AppButtonColorful
          v-if="permit"
          text="Adicionar Curso"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="Course/toggleModal"
        />
      </div>
    </div>
    <div class="p-4">
      <div><CoursesCards :data="courses" /></div>
    </div>
    <ModalCourse :open="toggleModal" />
    <DeleteCourseConfirmation :open="modalToggleDeleteCourse" />
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import ModalCourse from "./ModalCourse.vue";
import CoursesCards from "./list/CoursesCards.vue";
import BaseText from "../components/BaseText.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import AppButtonStatus from "../components/AppButtonStatus.vue";
import ProhibitedAccessPage from "../components/ProhibitedAccessPage.vue";
import DeleteCourseConfirmation from "@/views/alerts/DeleteCourseConfirmation.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    CoursesCards,
    BaseText,
    AppButtonColorful,
    AppButtonStatus,
    ModalCourse,
    ProhibitedAccessPage,
    DeleteCourseConfirmation,
  },
  data() {
    return {
      permitList: false,
      permit: false,
    };
  },
  computed: {
    ...mapState({
      disciplines: (state) => state.Courses.disciplines,
      discipline: (state) => state.Courses.filter.discipline,
      courses: (state) => state.Courses.filteredCourses,
      toggleModal: (state) => state.Course.toggleModal,
      modalToggleDeleteCourse: (state) => state.Course.modalToggleDeleteCourse,
      city: (state) => state.City.city,
    }),
  },
  methods: {
    ...mapActions({
      clearFilter: "Courses/clearFilter",
      canAcces: "AccessControl/canAcces",
    }),
    permitListCourses() {
      this.canAcces("course_index").then((resp) => {
        this.permitList = resp;
      });
    },
    permitChangeCourses() {
      this.canAcces("course_create").then((resp) => {
        this.permit = resp;
      });
    },
    isActive(discipline) {
      if (this.discipline) {
        return this.discipline.id === discipline.id;
      }
    },
  },
  created() {
    this.$store.dispatch("Courses/loadCourses");
    this.$store.dispatch("Courses/loadDisciplines");
    this.permitChangeCourses();
    this.permitListCourses();
  },
};
</script>
