var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("loader", {
    attrs: {
      object: "#ff9633",
      color1: "#ffffff",
      color2: "#17fd3d",
      size: "5",
      speed: "2",
      bg: "#343a40",
      objectbg: "#999793",
      opacity: "80",
      disableScrolling: "false",
      name: "dots",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }