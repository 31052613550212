var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "view-sidebar" }, [_c("SideBar")], 1),
    _c("div", { staticClass: "view-topbar" }, [_c("TopBar")], 1),
    _c(
      "div",
      { staticClass: "view-container" },
      [_c("router-view", { staticClass: "view" })],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }