var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-1" }, [
        _vm.discipline.phase.id == 1 &&
        _vm.permit &&
        _vm.season_date_manual_execution_end
          ? _c(
              "div",
              { staticClass: "justify-self-end" },
              [
                _vm.discipline.id == 1
                  ? _c("AppButtonColorful", {
                      attrs: {
                        text: "Alocar Alunos",
                        typeButton: "success",
                        showIcon: "",
                        iconName: "add",
                        iconType: "outlined",
                        dispatch: "Allocation/openModal",
                      },
                    })
                  : _vm._e(),
              ],
              1
            )
          : _vm.permit && _vm.season_date_manual_execution_end
          ? _c(
              "div",
              { staticClass: "justify-self-end" },
              [
                _c("AppButtonColorful", {
                  attrs: {
                    text: "Alocar Alunos",
                    typeButton: "success",
                    showIcon: "",
                    iconName: "add",
                    iconType: "outlined",
                    dispatch: "Allocation/openModal",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("div", { staticClass: "grid grid-cols-6" }, [
        _c(
          "div",
          { staticClass: "col-span-3 w-100 ml-10" },
          [
            _c("BaseText", {
              attrs: {
                text: "alunos",
                typeText: "custom",
                weight: "medium",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "quantum",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "w-60" },
          [
            _c("BaseText", {
              attrs: {
                text: "contato",
                typeText: "custom",
                weight: "medium",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "quantum",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("BaseText", {
              attrs: {
                text: "% de Frequência",
                typeText: "custom",
                weight: "medium",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "quantum",
              },
            }),
          ],
          1
        ),
      ]),
      _c("AllocationsCards", { attrs: { data: _vm.students } }),
      _c("ModalAllocations", { attrs: { open: _vm.modalOpen } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }