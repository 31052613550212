var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "about" } }, [
    _c("div", { staticClass: "header fixed z-10 w-full" }, [
      _c("div", { staticClass: "flex flex-wrap ml-32 my-24" }, [
        _vm._m(0),
        _c(
          "div",
          {
            staticClass: "flex justify-center items-center w-auto mt-5",
            attrs: { id: "menus" },
          },
          [
            _c(
              "router-link",
              { attrs: { to: "/" } },
              [
                _c("AppButtonEmpty", {
                  staticClass: "w-36 h-10 mr-12",
                  attrs: {
                    text: "Início",
                    "type-button": "default",
                    "type-text": "button-generic",
                  },
                }),
              ],
              1
            ),
            _c("AppButtonEmpty", {
              staticClass: "w-36 h-10 mr-12",
              attrs: {
                active: true,
                text: "Sobre",
                "type-button": "default",
                "type-text": "button-generic",
              },
            }),
            _c(
              "router-link",
              {
                staticClass:
                  "text-white underline hover:text-burnedYellow mr-12",
                attrs: { to: "/admin/entrar" },
              },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Área de Login",
                    "type-text": "custom",
                    weight: "medium",
                  },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { attrs: { id: "body" } }, [
      _c(
        "div",
        { staticClass: "flex flex-wrap", attrs: { id: "text-carrosel" } },
        [
          _c(
            "div",
            {
              staticClass: "flex flex-col justify-center w-3/6",
              attrs: { id: "the-project" },
            },
            [
              _c(
                "div",
                { staticClass: "ml-32 mb-16" },
                [
                  _c("BaseText", {
                    attrs: {
                      text: "O projeto",
                      "type-text": "custom",
                      weight: "extrabold",
                      size: "3xl",
                      color: "burnedYellow-classic",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-32 description text-color" },
                [
                  _c(
                    "BaseText",
                    { attrs: { "type-text": "custom", weight: "normal" } },
                    [
                      _vm._v(
                        " O projeto Virando o Jogo é um dos projetos do Programa Superação (Decreto Nº 33.377 de 29 de novembro de 2019), que reúne uma nova geração de políticas públicas para a juventude cearense. As iniciativas devem ampliar as capacidades e as habilidades desse segmento social, além de reforçar fatores protetivos junto às famílias e às comunidades, promovendo a reinserção escolar, fortalecendo a cidadania e oferecendo cursos de qualificação profissional para os jovens. "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "w-3/6", attrs: { id: "carrosel" } }, [
            _c(
              "div",
              { staticClass: "w-full h-full" },
              [
                _c(
                  "splide",
                  { attrs: { options: _vm.options } },
                  [
                    _c("splide-slide", [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/about-virandoojogo-1.png"),
                        },
                      }),
                    ]),
                    _c("splide-slide", [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/about-virandoojogo-2.png"),
                        },
                      }),
                    ]),
                    _c("splide-slide", [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/about-virandoojogo-3.png"),
                        },
                      }),
                    ]),
                    _c("splide-slide", [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/about-virandoojogo-4.png"),
                        },
                      }),
                    ]),
                    _c("splide-slide", [
                      _c("img", {
                        attrs: {
                          src: require("../assets/images/about-virandoojogo-5.png"),
                        },
                      }),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
          ]),
        ]
      ),
      _c("div", { attrs: { id: "objectives" } }, [
        _c("div", { staticClass: "flex justify-center align-center" }, [
          _c(
            "div",
            { staticClass: "my-20" },
            [
              _c("BaseText", {
                attrs: {
                  text: "Objetivos",
                  "type-text": "custom",
                  weight: "extrabold",
                  size: "3xl",
                  color: "burnedYellow-classic",
                },
              }),
            ],
            1
          ),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-wrap justify-center align-center pb-10" },
          [
            _c(
              "div",
              {
                staticClass:
                  "flex justify-center align-center flex-col text-center p-2 mr-24",
              },
              [
                _vm._m(1),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex justify-center align-center h-32 w-60 mt-5",
                  },
                  [
                    _c("BaseText", {
                      staticClass:
                        "flex justify-center align-center text-color",
                      attrs: {
                        text: "Promoção da cidadania na plenitude das responsabilidades e dos direitos dos jovens",
                        "type-text": "custom",
                        weight: "normal",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "flex justify-center align-center flex-col text-center p-2 mr-24",
              },
              [
                _vm._m(2),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex justify-center align-center h-32 w-60 mt-5",
                  },
                  [
                    _c("BaseText", {
                      staticClass:
                        "flex justify-center align-center text-color",
                      attrs: {
                        text: "Fortalecimento dos vínculos familiares e comunitários",
                        "type-text": "custom",
                        weight: "normal",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "flex justify-center align-center flex-col text-center p-2 mr-24",
              },
              [
                _vm._m(3),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex justify-center align-center h-32 w-60 mt-5",
                  },
                  [
                    _c("BaseText", {
                      staticClass:
                        "flex justify-center align-center text-color",
                      attrs: {
                        text: "Qualificação profissional e reinserção escolar",
                        "type-text": "custom",
                        weight: "normal",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              {
                staticClass:
                  "flex justify-center align-center flex-col text-center p-2 mr-24",
              },
              [
                _vm._m(4),
                _c(
                  "div",
                  {
                    staticClass:
                      "flex justify-center align-center h-32 w-60 mt-5",
                  },
                  [
                    _c("BaseText", {
                      staticClass:
                        "flex justify-center align-center text-color",
                      attrs: {
                        text: "Acesso a práticas esportivas, culturais, ambientais e de lazer",
                        "type-text": "custom",
                        weight: "normal",
                      },
                    }),
                  ],
                  1
                ),
              ]
            ),
          ]
        ),
      ]),
      _c(
        "div",
        { staticClass: "flex flex-wrap", attrs: { id: "accordion-game" } },
        [
          _c(
            "div",
            {
              staticClass: "flex flex-col justify-center w-3/6 py-32",
              attrs: { id: "the-game" },
            },
            [
              _c(
                "div",
                { staticClass: "ml-32 mb-16" },
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Virando o Jogo",
                      "type-text": "custom",
                      weight: "extrabold",
                      size: "3xl",
                      color: "burnedYellow-classic",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-32 description text-color" },
                [
                  _c(
                    "BaseText",
                    { attrs: { "type-text": "custom", weight: "normal" } },
                    [
                      _vm._v(
                        " Os jovens que participam do projeto têm acesso a módulos de formação cidadã, ação comunitária, empreendedorismo e cursos de qualificação profissional em diversas áreas. Há ainda o incentivo de práticas esportivas, culturais, ambientais e de lazer. As ações são divididas em três fases de formação. "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c(
            "div",
            { staticClass: "w-3/6", attrs: { id: "accordion" } },
            [
              _c(
                "vsa-list",
                { staticClass: "bg-burnedYellow-classic accordion-custom" },
                [
                  _c(
                    "vsa-item",
                    { attrs: { "init-active": "" } },
                    [
                      _c(
                        "vsa-heading",
                        [
                          _c("BaseText", {
                            attrs: {
                              text: "1º FASE",
                              "type-text": "custom",
                              weight: "normal",
                              size: "lg",
                              color: "white",
                            },
                          }),
                          _c("BaseText", {
                            attrs: {
                              text: "| FORMAÇÃO CIDADÃ E AÇÃO COMUNITÁRIA",
                              "type-text": "custom",
                              weight: "semibold",
                              size: "lg",
                              color: "white",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vsa-content",
                        [
                          _c(
                            "BaseText",
                            {
                              staticClass: "text-color",
                              attrs: {
                                "type-text": "custom",
                                weight: "normal",
                              },
                            },
                            [
                              _vm._v(
                                " Na Formação Cidadã os jovens têm encontros nos quais são abordados diversos temas como autoconhecimento, autoestima, relações sociais e afetivas, saúde, projeto de vida e primeiros socorros."
                              ),
                              _c("br"),
                              _vm._v(
                                " Já a Ação Comunitária busca fortalecer a participação dos jovens diante da necessidades da sua comunidade/bairro, incentivando a reflexão sobre questões locais e capacidade de intervenção através de um plano de ação comunitária a ser planejado e realizado pelos jovens. "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vsa-item",
                    [
                      _c(
                        "vsa-heading",
                        [
                          _c("BaseText", {
                            attrs: {
                              text: "2º FASE",
                              "type-text": "custom",
                              weight: "normal",
                              size: "lg",
                              color: "white",
                            },
                          }),
                          _c("BaseText", {
                            attrs: {
                              text: "| QUALIFICAÇÃO PROFISSIONAL E ATIVIDADE ESPORTIVA OU CULTURAL",
                              "type-text": "custom",
                              weight: "semibold",
                              size: "lg",
                              color: "white",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vsa-content",
                        [
                          _c(
                            "BaseText",
                            {
                              staticClass: "text-color",
                              attrs: {
                                "type-text": "custom",
                                weight: "normal",
                              },
                            },
                            [
                              _vm._v(
                                " Na 2ª fase o jovem opta pelo curso de qualificação profissional e atividade esportiva ou cultural do seu interesse. São ofertados mais de 10 cursos nas áreas de comércio, cuidados pessoais e beleza, administração, alimentação, moda, produção cultural, construção e reparos. "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "vsa-item",
                    [
                      _c(
                        "vsa-heading",
                        [
                          _c("BaseText", {
                            attrs: {
                              text: "3º FASE",
                              "type-text": "custom",
                              weight: "normal",
                              size: "lg",
                              color: "white",
                            },
                          }),
                          _c("BaseText", {
                            attrs: {
                              text: "| MERCADO DE TRABALHO, EMPREENDEDORISMO E GESTÃO FINANCEIRA",
                              "type-text": "custom",
                              weight: "semibold",
                              size: "lg",
                              color: "white",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "vsa-content",
                        [
                          _c(
                            "BaseText",
                            {
                              staticClass: "text-color",
                              attrs: {
                                "type-text": "custom",
                                weight: "normal",
                              },
                            },
                            [
                              _vm._v(
                                " Essa fase tem como objetivo desenvolver habilidades e competências para aumentar as chances de inclusão produtiva dos jovens, seja no mercado formal, seja como microempreendedor individual/autônomos. "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "flex flex-wrap",
          attrs: { id: "financial-assistance" },
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-col justify-center py-32 w-3/6" },
            [
              _c(
                "div",
                { staticClass: "ml-32 mb-8" },
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Auxílio Financeiro",
                      "type-text": "custom",
                      weight: "bold",
                      size: "2xl",
                      color: "burnedYellow-classic",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "ml-32 description text-color" },
                [
                  _c(
                    "BaseText",
                    { attrs: { "type-text": "custom", weight: "normal" } },
                    [
                      _vm._v(
                        " Todas as fases possuem um auxílio financeiro mensal cujo valor varia de acordo com a participação do jovem nas atividades do Projeto, considerando os valores previstos em cada fase, sendo: "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "flex py-32 w-3/6" }, [
            _c("div", { staticClass: "flex flex-col mr-24" }, [
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("BaseText", {
                    staticClass: "text-color",
                    attrs: {
                      text: "1ª Fase",
                      "type-text": "custom",
                      weight: "normal",
                      size: "1xl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-3 w-48" },
                [
                  _c("BaseText", {
                    staticClass: "text-color",
                    attrs: {
                      text: "Formação Cidadã e Ação Comunitária",
                      "type-text": "custom",
                      weight: "bold",
                      size: "1xl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseText", {
                    staticClass: "text-color",
                    attrs: {
                      text: "R$ 200,00",
                      "type-text": "custom",
                      weight: "bold",
                      size: "3xl",
                      color: "burnedYellow-classic",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "flex flex-col mr-24" }, [
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("BaseText", {
                    staticClass: "text-color",
                    attrs: {
                      text: "2ª Fase",
                      "type-text": "custom",
                      weight: "normal",
                      size: "1xl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-3 w-68" },
                [
                  _c("BaseText", {
                    staticClass: "text-color",
                    attrs: {
                      text: "Qualificação Profissional e Atividade Esportiva ou Cultural",
                      "type-text": "custom",
                      weight: "bold",
                      size: "1xl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseText", {
                    staticClass: "text-color",
                    attrs: {
                      text: "R$ 250,00",
                      "type-text": "custom",
                      weight: "bold",
                      size: "3xl",
                      color: "burnedYellow-classic",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "flex flex-col mr-24" }, [
              _c(
                "div",
                { staticClass: "mb-3" },
                [
                  _c("BaseText", {
                    staticClass: "text-color",
                    attrs: {
                      text: "3ª Fase",
                      "type-text": "custom",
                      weight: "normal",
                      size: "1xl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mb-3 w-48" },
                [
                  _c("BaseText", {
                    staticClass: "text-color",
                    attrs: {
                      text: "Mercado de Trabalho, Empreendedorismo e Gestão Financeira",
                      "type-text": "custom",
                      weight: "bold",
                      size: "1xl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseText", {
                    staticClass: "text-color",
                    attrs: {
                      text: "R$ 300,00",
                      "type-text": "custom",
                      weight: "bold",
                      size: "3xl",
                      color: "burnedYellow-classic",
                    },
                  }),
                ],
                1
              ),
            ]),
          ]),
        ]
      ),
      _c(
        "div",
        { staticClass: "flex flex-wrap footer", attrs: { id: "social" } },
        [
          _c(
            "div",
            { staticClass: "flex justify-between w-full py-24 px-32" },
            [
              _c(
                "div",
                { staticClass: "w-128" },
                [
                  _c(
                    "BaseText",
                    {
                      attrs: {
                        "type-text": "custom",
                        weight: "normal",
                        color: "white",
                      },
                    },
                    [
                      _vm._v(
                        " Todas as informações do Virando o Jogo serão divulgadas nesse site e nas redes sociais do projeto. "
                      ),
                    ]
                  ),
                  _c("br"),
                  _c("BaseText", {
                    attrs: {
                      text: "Acompanhe nossas redes sociais!",
                      "type-text": "custom",
                      weight: "semibold",
                      size: "base",
                      color: "burnedYellow-classic",
                    },
                  }),
                ],
                1
              ),
              _vm._m(5),
            ]
          ),
        ]
      ),
      _c("div", { staticClass: "bot w-full" }),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-3/6", attrs: { id: "logo" } }, [
      _c("img", {
        attrs: { src: require("../assets/images/logo-inicio.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: { src: require("../assets/images/obj1.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: { src: require("../assets/images/obj2.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: { src: require("../assets/images/obj3.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("img", {
        attrs: { src: require("../assets/images/obj4.png"), alt: "" },
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex items-center" }, [
      _c("div", { staticClass: "mr-5" }, [
        _c(
          "a",
          {
            attrs: {
              href: "http://instagram.com/virandoojogo.superacao",
              target: "_blank",
            },
          },
          [
            _c("img", {
              attrs: { src: require("../assets/images/instagram.png") },
            }),
          ]
        ),
      ]),
      _c("div", [
        _c(
          "a",
          {
            attrs: {
              href: "http://fb.com/virandoojogo.superacao",
              target: "_blank",
            },
          },
          [
            _c("img", {
              attrs: { src: require("../assets/images/facebook.png") },
            }),
          ]
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }