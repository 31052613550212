var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("Label", {
                          staticClass: "text-ashes-dark",
                          attrs: {
                            text: "Alocar Alunos",
                            icon: null,
                            "icon-type": null,
                            type: "text-2xl",
                            weight: "bold",
                            normal: "",
                            mode: "normal-case",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch: "Allocation/closeModal",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "grid grid-flow-row auto-rows-max" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-flow-col auto-cols-max gap-10",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "self-center text-ashes-dark mt-4" },
                            [
                              _c("BaseText", {
                                attrs: {
                                  text: "Filtrar:",
                                  typeText: "custom",
                                  weight: "base",
                                  italic: false,
                                  size: "sm",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "w-72 mt-4" },
                            [
                              _c("Multiselect", {
                                attrs: {
                                  id: "filterArea",
                                  selectPlaceholder: "Selecionar",
                                  "label-text": "Área",
                                  closeOnSelect: "",
                                  "show-label": "",
                                  multipleOption: false,
                                  value: _vm.area,
                                  options: _vm.user_areas,
                                  dispatch: _vm.setFilterArea,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "w-96 mt-4" },
                            [
                              _c("Multiselect", {
                                attrs: {
                                  "close-on-select": true,
                                  "show-label": "",
                                  "clear-on-select": "",
                                  "select-placeholder": "Equipamento",
                                  "multiple-option": false,
                                  value: _vm.equipment,
                                  options: _vm.equipments_by_area,
                                  "label-text": _vm.getLabelEquipmentFilter(),
                                  dispatch: _vm.setFilterEquipment,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "w-80 mt-4" },
                            [
                              _c("BaseInput", {
                                attrs: {
                                  active: "",
                                  inputName: "",
                                  inputType: "text",
                                  iconName: "search",
                                  showIcon: "",
                                  "show-label": "",
                                  "label-text": "Nome",
                                  dispatch: "Allocation/setFilterName",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-cols-2 items-center mx-12 my-6",
                          attrs: { id: "tabs-button" },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "mx-2" },
                            [
                              _c("AppButtonColorful", {
                                attrs: {
                                  id: "btn-consult-students",
                                  showIcon: "",
                                  iconName: "search",
                                  text: "Consultar dados",
                                  dispatch:
                                    "Allocation/loadStudentsForAllocation",
                                  payload: "load",
                                  typeButton: "primary",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm.phaseOneStudents.length > 0
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "mx-2 items-center justify-self-end",
                                },
                                [
                                  _c(
                                    "a",
                                    {
                                      attrs: { href: "javascript:void(0)" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.clearFields()
                                        },
                                      },
                                    },
                                    [
                                      _c("AppButtonColorful", {
                                        attrs: {
                                          id: "btn-clean-data",
                                          showIcon: "",
                                          iconName: "remove",
                                          text: "Limpar consulta",
                                          typeButton: "primary",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "grid grid-flow-row auto-rows-max" },
                        _vm._l(_vm.phaseOneStudents, function (student, index) {
                          return _c(
                            "div",
                            {
                              key: index,
                              staticClass:
                                "mt-3 mr-5 app-checkbox grid grid-cols-5 border-b pb-2",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "grid grid-flow-col auto-cols-max gap-6 place-items-center col-span-2",
                                },
                                [
                                  _c("div", [
                                    _c("input", {
                                      attrs: {
                                        id: _vm.checkboxId(student.name, index),
                                        type: "checkbox",
                                        name: "students",
                                      },
                                      domProps: {
                                        value: student.id,
                                        checked: student.checked,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.onCheck(student, $event)
                                        },
                                      },
                                    }),
                                  ]),
                                  _c("div", [
                                    _c("img", {
                                      staticClass: "w-16 h-16",
                                      attrs: {
                                        src: require("../assets/images/profile-vj.png"),
                                        alt: "Maria Antônia Soares",
                                      },
                                    }),
                                  ]),
                                  _c(
                                    "div",
                                    [
                                      _c("BaseText", {
                                        staticClass: "flex flex-wrap w-96",
                                        attrs: {
                                          text: student.social_name
                                            ? student.name +
                                              " - " +
                                              student.social_name
                                            : student.name,
                                          typeText: "custom",
                                          weight: "semibold",
                                          italic: false,
                                          mode: "capitalize",
                                          size: "lg",
                                          target: _vm.checkboxId(
                                            student.name,
                                            index
                                          ),
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "place-self-center" },
                                [
                                  _c("BaseLabel", {
                                    attrs: {
                                      text:
                                        typeof student.equipment != "undefined"
                                          ? student.equipment.name
                                          : "SEM EQUIPAMENTO",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "place-self-center" },
                                [
                                  _c("BaseLabel", {
                                    attrs: { text: _vm.getShift(student) },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "place-self-center" },
                                [
                                  _c("BaseLabel", {
                                    attrs: { text: student.cellphone_number },
                                  }),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                        0
                      ),
                      _vm.phaseOneStudents?.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "mx-12 my-6 pb-3",
                              attrs: { id: "partners-pagination" },
                            },
                            [
                              _c("BasePagination", {
                                attrs: {
                                  total: _vm.totalPhaseOneStudents,
                                  "total-per-page": _vm.totalPerPage,
                                  dispatch:
                                    "Allocation/setPhaseOneStudentsOffset",
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm.loading_modal_allocation_students
                    ? _c("div", [_c("PreLoading")], 1)
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch: "Allocation/closeModal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "Aloca",
                            typeButton: "success",
                            dispatch: "Allocation/allocatePhaseOneStudents",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3463038678
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }