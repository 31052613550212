var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mx-12 my-6" }, [
    _c("div", { staticClass: "flex justify-end" }, [
      _c("ul", [
        _c("li", [
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.toggleMenu()
                },
              },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "border rounded-full px-5 py-3 bg-white relative z-50",
                },
                [
                  _c("div", { staticClass: "flex" }, [
                    _vm._m(0),
                    _c("div", { staticClass: "flex flex-col mx-2" }, [
                      _c(
                        "div",
                        [
                          _c("BaseText", {
                            attrs: {
                              text: _vm.user.name,
                              typeText: "custom",
                              weight: "bold",
                              size: "sm",
                              color: "quantum",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        [
                          _c("BaseText", {
                            attrs: {
                              text: _vm.profileInfo.description,
                              typeText: "custom",
                              weight: "normal",
                              size: "xs",
                              color: "burnedYellow",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c(
                      "div",
                      { staticClass: "flex flex-col justify-center ml-5" },
                      [
                        _c("AppMaterialIcon", {
                          class: _vm.toggleAnimate(),
                          attrs: { iconName: "expand_more" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          ),
          _c("ul", { class: _vm.ulCss }, [
            _vm.permitRoleAccess()
              ? _c(
                  "li",
                  [
                    _c(
                      "router-link",
                      {
                        staticClass:
                          "flex items-center no-underline h-12 mx-3 text-burnedYellow rounded-full",
                        attrs: { to: "/edicoes/ativa" },
                      },
                      [
                        _c(
                          "span",
                          {
                            staticClass:
                              "font-system mx-5 normal font-semibold normal-case flex items-center hover:underline",
                          },
                          [
                            _c("AppMaterialIcon", {
                              staticClass: "mr-5",
                              attrs: {
                                iconName: "perm_identity",
                                "icon-size": "18",
                              },
                            }),
                            _c("BaseText", {
                              attrs: {
                                text: "Perfis de acesso",
                                typeText: "custom",
                                weight: "normal",
                                size: "xs",
                                color: "burnedYellow",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c("li", [
              _c(
                "div",
                {
                  staticClass:
                    "flex items-center no-underline h-12 mx-3 text-burnedYellow rounded-full cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.loggout()
                    },
                  },
                },
                [
                  _c(
                    "span",
                    {
                      staticClass:
                        "font-system mx-5 normal font-semibold normal-case flex items-center hover:underline",
                    },
                    [
                      _c("AppMaterialIcon", {
                        staticClass: "mr-5",
                        attrs: {
                          iconName: "power_settings_new",
                          "icon-size": "18",
                        },
                      }),
                      _c("BaseText", {
                        attrs: {
                          text: "Sair",
                          typeText: "custom",
                          weight: "normal",
                          size: "xs",
                          color: "burnedYellow",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
          ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "rounded-full w-12 h-12" }, [
      _c("img", {
        staticClass: "rounded-full",
        attrs: { src: require("../assets/images/profile-vj.png") },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }