var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    _vm._l(_vm.data, function (obj, index) {
      return _c(
        "li",
        { key: index, staticClass: "m-2" },
        [
          _c("AppCardStudent", { attrs: { student: obj } }),
          _c("div", { staticClass: "w-full border mt-1 border-ashes-hover" }),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }