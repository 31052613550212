var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-full" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    !_vm.isUpdate
                      ? _c(
                          "div",
                          { staticClass: "justify-self-start" },
                          [
                            _c("Label", {
                              staticClass: "text-carmesim",
                              attrs: {
                                text: "Cadastrar Matriz de Problema-Impacto",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "justify-self-start" },
                          [
                            _c("Label", {
                              staticClass: "text-carmesim",
                              attrs: {
                                text: "Atualizar Matriz de Problema-Impacto",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                          ],
                          1
                        ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "MatrixRiskStratifications/descartEditModalMatrixRiskStratification",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Guia de Avaliação de Problema-Impacto",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._l(_vm.matrix_problems, function (matrix_problem, index) {
                    return _c(
                      "div",
                      {
                        key: index,
                        staticClass: "table w-full border-b-4 pb-2",
                      },
                      [
                        _c("div", { staticClass: "table-header-group" }, [
                          _c("div", { staticClass: "table-row" }, [
                            _c(
                              "div",
                              { staticClass: "table-cell" },
                              [
                                _c("BaseText", {
                                  attrs: {
                                    text: "Problema Identificado?",
                                    typeText: "custom",
                                    weight: "bold",
                                    mode: "capitalize",
                                    italic: false,
                                    size: "xs",
                                    color: "ashes-dark",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "table-cell" },
                              [
                                _c("BaseText", {
                                  attrs: {
                                    text: "Situação",
                                    typeText: "custom",
                                    weight: "bold",
                                    mode: "capitalize",
                                    italic: false,
                                    size: "xs",
                                    color: "ashes-dark",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "table-cell" },
                              [
                                _c("BaseText", {
                                  attrs: {
                                    text: "Impacto",
                                    typeText: "custom",
                                    weight: "bold",
                                    mode: "capitalize",
                                    italic: false,
                                    size: "xs",
                                    color: "ashes-dark",
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                        _c("div", { staticClass: "table-row-group" }, [
                          _c("div", { staticClass: "table-row" }, [
                            _c(
                              "div",
                              { staticClass: "table-cell text-left w-0 pb-4" },
                              [
                                _c("BaseText", {
                                  staticClass: "flex flex-wrap w-64",
                                  attrs: {
                                    text: matrix_problem.name,
                                    typeText: "custom",
                                    weight: "semibold",
                                    italic: false,
                                    size: "xs",
                                    color: "ashes-dark",
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "table-cell" },
                              _vm._l(
                                matrix_problem.matrix_situations,
                                function (situations, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "table-row-group",
                                    },
                                    [
                                      _c("div", { staticClass: "table-row" }, [
                                        _c(
                                          "div",
                                          { staticClass: "table-cell pb-2" },
                                          [
                                            _c(
                                              "div",
                                              { staticClass: "inp-left" },
                                              [
                                                _c("input", {
                                                  attrs: {
                                                    id: _vm.checkboxId(
                                                      situations.situation,
                                                      index
                                                    ),
                                                    type: "checkbox",
                                                    name: "students",
                                                  },
                                                  domProps: {
                                                    value: situations.id,
                                                    checked: situations.checked,
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.onCheck(
                                                        $event,
                                                        situations,
                                                        index
                                                      )
                                                    },
                                                  },
                                                }),
                                              ]
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "text-r" },
                                              [
                                                _c("BaseText", {
                                                  staticClass:
                                                    "flex flex-wrap w-68",
                                                  attrs: {
                                                    text: situations.situation,
                                                    typeText: "custom",
                                                    weight: "semibold",
                                                    italic: false,
                                                    size: "xs",
                                                    color: "ashes-dark",
                                                    target: _vm.checkboxId(
                                                      situations.situation,
                                                      index
                                                    ),
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "table-cell pb-5",
                                            class: _vm.chooseRiskSit(
                                              situations.situation_risk
                                            ),
                                          },
                                          [
                                            _c("BaseText", {
                                              staticClass: "flex flex-wrap",
                                              attrs: {
                                                text: situations.situation_risk,
                                                typeText: "custom",
                                                weight: "semibold",
                                                italic: false,
                                                size: "xs",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                            _c(
                              "div",
                              { staticClass: "table-cell text-left" },
                              _vm._l(
                                matrix_problem.matrix_situations,
                                function (impacts, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "table-row-group",
                                    },
                                    [
                                      _c("div", { staticClass: "table-row" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "table-cell text-left pb-2",
                                          },
                                          [
                                            _c("BaseText", {
                                              staticClass:
                                                "flex flex-wrap w-68",
                                              attrs: {
                                                text: impacts.impact,
                                                typeText: "custom",
                                                weight: "semibold",
                                                italic: false,
                                                size: "xs",
                                                color: "ashes-dark",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "table-cell text-left pb-5",
                                            class: _vm.chooseRiskImp(
                                              impacts.impact_level
                                            ),
                                          },
                                          [
                                            _c("BaseText", {
                                              staticClass: "flex flex-wrap",
                                              attrs: {
                                                text: impacts.impact_level,
                                                typeText: "custom",
                                                weight: "semibold",
                                                italic: false,
                                                size: "xs",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ]),
                        ]),
                      ]
                    )
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "MatrixRiskStratifications/descartEditModalMatrixRiskStratification",
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.isUpdate
                      ? _c(
                          "div",
                          { staticClass: "justify-self-end" },
                          [
                            _c("AppButtonEmpty", {
                              attrs: {
                                text: "Salvar",
                                typeButton: "success",
                                dispatch: _vm.getDispatchType,
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "justify-self-end" },
                          [
                            _c("AppButtonEmpty", {
                              attrs: {
                                text: "Atualizar",
                                typeButton: "success",
                                dispatch: "MatrixRiskStratifications/update",
                              },
                            }),
                          ],
                          1
                        ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1647759971
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }