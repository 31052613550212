<template>
  <nav id="sidebar" :class="sidebar">
    <slot>
      <img src="../assets/images/logo.png" class="pl-10 pt-10 pb-5" alt="" />
    </slot>
    <BaseStaggeredFade id="menus" :class="menusClass" :duration="10" tag="ul">
      <div v-for="(menu, index) in menus" :key="menu.id">
        <div :key="menu.id" :data-index="index" :id="menu.id" v-show="checkMenu(menu.name)">
          <li :class="menuClass" v-if="menu.show">
            <a
              :id="menu.id + '-button'"
              @click.prevent="toggleSubmenu(menu.id)"
              :class="button(menu.id)"
              href="/#/"
            >
              <AppMaterialIcon
                style="margin-left: 15px"
                :iconName="menu.iconName"
                :iconType="menu.iconType"
                iconSize="24"
              />
              <Label :text="menu.name" weight="semibold" class="mx-3" />
              <Label icon="angle-down" iconType="solid" :class="[angle, toggleAnimate(menu.id)]" />
            </a>
            <ul :id="menu.id + '-submenus'" :class="submenus(menu.id)">
              <li
                :key="submenu.id"
                v-for="submenu in menu.submenus"
                :id="submenu.id"
                v-show="checkSubMenu(menu.name, submenu.name)"
              >
                <router-link :class="linkSubmenu" :to="submenu.url">
                  <Label
                    :text="submenu.name"
                    icon="angle-right"
                    iconType="solid"
                    weight="semibold"
                    class="mx-5"
                  />
                </router-link>
              </li>
            </ul>
          </li>
        </div>
      </div>
    </BaseStaggeredFade>
    <div :class="bottonMenu" @click="changeCity" v-if="permitChangeCity()">
      <Label text="Trocar de Cidade" icon="angle-right" iconType="solid" weight="semibold" class="mx-5" />
    </div>
    <CitiesCards :cities="cities" dispatch="City/setCity" v-if="!city" />
  </nav>
</template>

<script>
import AppMaterialIcon from "./AppMaterialIcon";
import { mapState, mapActions } from "vuex";
import CitiesCards from "../views/list/CitiesCards.vue";
import BaseStaggeredFade from "../components/BaseStaggeredFade.vue";
import Label from "./Label.vue";

export default {
  components: {
    CitiesCards,
    Label,
    AppMaterialIcon,
    BaseStaggeredFade,
  },

  beforeCreate() {
    this.$store.dispatch("City/loadCities");
  },

  data() {
    return {
      menusObj: {},
      arrMenus: [],
      arrSubMenus: [],
      permit: false,
      checMenuObj: [],
      menus: [
        {
          id: "menu-9",
          name: "Pré-Inscrições",
          show: false,
          active: false,
          iconName: "account_circle",
          iconType: "outlined",
          roles: ["Administrador", "Gerente de Área"],
          url: "/preinscricoes",
          submenus: null,
        },
        // {
        //   id: "menu-3",
        //   name: "Alunos",
        //   show: true,
        //   active: false,
        //   iconName: "account_circle",
        //   iconType: "outlined",
        //   roles: ["Administrador", "Gerente de Área"],
        //   url: "/estudantes",
        //   submenus: [{ id: "menu-1-submenu-1", name: "Ativos", url: "/estudantes" }],
        // },
        {
          id: "menu-1",
          name: "Edições",
          show: true,
          active: false,
          iconName: "star_outline",
          iconType: "outlined",
          roles: ["Administrador", "Gerente de Área"],
          url: null,
          submenus: [
            { id: "menu-1-submenu-1", name: "Ativas", url: "/edicoes/ativa" },
            {
              id: "menu-1-submenu-2",
              name: "Encerradas",
              url: "/edicoes/finalizada",
            },
            { id: "menu-1-submenu-3", name: "Cursos de Qualificação", url: "/cursos" },
          ],
        },
        {
          id: "menu-10",
          name: "Psicossocial",
          show: true,
          active: false,
          iconName: "psychology",
          iconType: "outlined",
          roles: ["Administrador", "Gerente de Área"],
          url: "/psicossocial",
          submenus: [
            { id: "menu-1-submenu-1", name: "Listar Registros", url: "/psicossocial" },
            { id: "menu-1-submenu-2", name: "Encaminhamentos", url: "/encaminhamentos" },
            {
              id: "menu-1-submenu-3",
              name: "Matriz - Problemas",
              url: "/matriz_problema",
            },
            {
              id: "menu-1-submenu-4",
              name: "Matriz - Situações",
              url: "/matriz_situacao",
            },
          ],
        },
        {
          id: "menu-15",
          name: "Técnicos Psicossociais",
          show: true,
          active: false,
          iconName: "group",
          iconType: "outlined",
          roles: ["Administrador", "Gerente de Área"],
          url: null,
          submenus: [{ id: "menu-1-submenu-1", name: "Ativos", url: "/atendentes" }],
        },
        {
          id: "menu-11",
          name: "Reinserção Escolar",
          show: true,
          active: false,
          iconName: "school",
          iconType: "outlined",
          roles: ["Administrador", "Gerente de Área"],
          url: null,
          submenus: [{ id: "menu-1-submenu-1", name: "Listar Registros", url: "/reinsercao_escolar" }],
        },
        {
          id: "menu-20",
          name: "Inserção Produtiva",
          show: true,
          active: false,
          iconName: "engineering",
          iconType: "outlined",
          roles: ["Administrador"],
          url: null,
          submenus: [
            { id: "menu-1-submenu-1", name: "Listar Registros", url: "/insercoes_produtivas" },
            { id: "menu-1-submenu-2", name: "Empresas", url: "/empresas" },
            { id: "menu-1-submenu-3", name: "Parceiros", url: "/parceiros" },
            { id: "menu-1-submenu-4", name: "Segmentos", url: "/segmentos" },
            { id: "menu-1-submenu-5", name: "Ocupações", url: "/ocupacoes" },
          ],
        },
        {
          id: "menu-21",
          name: "Territórios",
          show: true,
          active: false,
          iconName: "map",
          iconType: "outlined",
          roles: ["Administrador"],
          url: null,
          submenus: [
            { id: "menu-1-submenu-1", name: "Áreas", url: "/areas" },
            { id: "menu-1-submenu-2", name: "Equipamentos de Área", url: "/equipamentos" },
            { id: "menu-1-submenu-3", name: "Rede de Atendimento", url: "/rede_de_atendimento" },
          ],
        },
        {
          id: "menu-19",
          name: "Articuladores",
          show: true,
          active: false,
          iconName: "group",
          iconType: "outlined",
          roles: ["Administrador", "Gerente de Área"],
          url: null,
          submenus: [{ id: "menu-1-submenu-1", name: "Listar Articuladores", url: "/articuladores" }],
        },
        {
          id: "menu-2",
          name: "Instrutores",
          show: true,
          active: false,
          iconName: "history_edu",
          iconType: "outlined",
          roles: ["Administrador", "Gerente de Área"],
          url: null,
          submenus: [
            {
              id: "menu-2-submenu-1",
              name: "Ativos",
              url: "/admin/instrutores",
            },
          ],
        },
        {
          id: "menu-16",
          name: "Encaminhamentos",
          show: true,
          active: false,
          iconName: "double_arrow",
          iconType: "outlined",
          roles: ["Administrador", "Gerente de Área"],
          url: null,
          submenus: [
            {
              id: "menu-2-submenu-1",
              name: "Listar Encaminhamentos",
              url: "/encaminhamentos",
            },
          ],
        },
        {
          id: "menu-4",
          name: "Benefícios",
          show: true,
          active: false,
          iconName: "paid",
          iconType: "outlined",
          roles: ["Administrador", "Gerente de Área"],
          url: null,
          submenus: [
            {
              id: "menu-1-submenu-1",
              name: "A pagar",
              url: "/admin/beneficios/pendentes",
            },
            {
              id: "menu-1-submenu-2",
              name: "Pagos",
              url: "/admin/beneficios/pagos",
            },
          ],
        },
        // {
        //   id: "menu-7",
        //   name: "Turmas",
        //   show: true,
        //   active: false,
        //   iconName: "book",
        //   iconType: "outlined",
        //   roles: ["Administrador", "Gerente de Área", "Instrutor"],
        //   url: null,
        //   submenus: [
        //     { id: "menu-1-submenu-1", name: "Ativas", url: "/turmas/ativas" },
        //     {
        //       id: "menu-1-submenu-2",
        //       name: "Encerradas",
        //       url: "/turmas/finalizadas",
        //     },
        //   ],
        // },
        {
          id: "menu-12",
          name: "Dashboard",
          show: false,
          active: false,
          iconName: "account_circle",
          iconType: "outlined",
          roles: ["Administrador", "Gerente de Área"],
          url: null,
          submenus: [],
        },
        {
          id: "menu-14",
          name: "Controle de acesso",
          show: true,
          active: false,
          iconName: "admin_panel_settings",
          iconType: "outlined",
          roles: ["Administrador"],
          url: null,
          // submenus: [{ id: "menu-1-submenu-1", name: "Lista", url: "/usuarios" }],
          submenus: [
            // { id: "menu-1-submenu-2", name: "Articuladores", url: "/articuladores" },
            { id: "menu-1-submenu-3", name: "Instrutores", url: "/admin/instrutores" },
            { id: "menu-1-submenu-5", name: "Perfis", url: "/perfis" },
            { id: "menu-1-submenu-4", name: "Técnicos Psicossociais", url: "/atendentes" },
            { id: "menu-1-submenu-1", name: "Usuários", url: "/usuarios" },
          ],
        },
      ],
      openChangeCity: false,
    };
  },

  methods: {
    ...mapActions({
      setUserMenus: "AccessControl/setUserMenus",
      setUserPermissions: "AccessControl/setUserPermissions",
      canAcces: "AccessControl/canAcces",
    }),
    cityeAcces() {
      this.canAcces("city_index").then((resp) => {
        this.permit = resp;
      });
    },
    checkMenu(menuName) {
      let checkMenu = false;
      this.checMenuObj.forEach((element) => {
        if (element.description === menuName) {
          if (element.ability_actions.length > 0 && this.arrMenus.includes(menuName)) {
            checkMenu = true;
          }
        }
      });
      return checkMenu;
    },

    checkSubMenu(menuName, subMenuName) {
      let find = false;
      this.arrSubMenus.find((item) => {
        if (item.name === menuName) {
          if (JSON.stringify(item).includes(subMenuName)) {
            find = true;
          }
        }
      });
      return find;
    },

    permitChangeCity() {
      return this.permit;
    },
    getRole() {
      const role = this.$store.state.Session.role;
      return role;
    },
    checkRoles(arr) {
      const role = this.$store.state.Session.role;
      return arr.includes(role);
    },
    buttonIsActive(id) {
      let menu = this.menus.find((menu) => menu.id === id);
      return menu.active;
    },
    toggleSubmenu(id) {
      if (id != this.menusObj.id && this.menusObj.open) {
        this.menusObj.open = false;
      }

      this.menusObj = {
        id: id,
        open: !this.menusObj.open,
      };

      this.menus.forEach((menu) => (menu.active = false));
      let menu = this.menus.find((menu) => menu.id === id);
      menu.active = true;
    },
    buttonIsOpen(id) {
      let open = null;

      if (this.menusObj.id && this.menusObj.id === id) {
        open = this.menusObj.open;
      }

      return open;
    },
    toggleAnimate(id) {
      let open = this.buttonIsOpen(id);
      let animate = {};

      if (open != null) {
        animate = {
          "animate-spinningUp": open,
          "animate-spinningDown": !open,
        };
      }
      return animate;
    },
    button(id) {
      let open = this.buttonIsOpen(id);
      let active = this.buttonIsActive(id);

      return {
        relative: true,
        flex: true,
        "items-center": true,
        "no-underline": true,
        "h-12": true,
        "mx-5": true,
        "rounded-full": true,
        "bg-white": open,
        "shadow-bm": open,
        "z-10": open,
        "text-ashes-dark": !active,
        "text-burnedYellow": active,
        "group-hover:bg-white": true,
        "group-hover:relative": true,
        "group-hover:shadow-bm": true,
        "group-hover:z-10": true,
      };
    },
    submenus(id) {
      let open = this.buttonIsOpen(id);

      let css = {
        hidden: !open,
        relative: true,
        "z-0": true,
        "bg-peachPuffDark": true,
        "rounded-b-3xl": true,
        "shadow-bm": true,
      };

      let animate = {
        "animate-growDown": open,
        "origin-top-center": true,
      };

      let margin = {
        "-mt-5": true,
        "ml-5": true,
        "mb-2": true,
        "mr-5": true,
      };

      let padding = {
        "pt-5": true,
        "pl-0": true,
        "pb-0": true,
        "pr-0": true,
      };

      css = Object.assign(css, margin);
      css = Object.assign(css, padding);
      css = Object.assign(css, animate);

      return css;
    },
    changeCity() {
      this.$store.dispatch("City/setCity", null);
    },
  },

  computed: {
    ...mapState({
      city: (state) => state.City.city,
      cities: (state) => state.City.cities,
    }),
    sidebar() {
      return {
        fixed: true,
        "inset-0": true,
        "h-full": true,
        "w-72": true,
        "font-system": true,
        "bg-ashes-classic": true,
        "overflow-y-auto": true,
      };
    },
    menusClass() {
      return {
        "p-0": true,
      };
    },
    menuClass() {
      return {
        "py-1": true,
        "px-2": true,
        "m-0": true,
        group: true,
      };
    },
    submenuClass() {
      return {
        "mb-0": true,
      };
    },
    linkSubmenu() {
      return {
        flex: true,
        "items-center": true,
        "no-underline": true,
        "h-12": true,
        "mx-3": true,
        "text-white": true,
        "rounded-full": true,
        "hover:text-burnedYellow": true,
        "hover:underline": true,
      };
    },
    angle() {
      return {
        absolute: true,
        "right-4": true,
      };
    },
    bottonMenu() {
      return {
        absolute: true,
        "bottom-0": true,
        flex: true,
        "items-center": true,
        "no-underline": true,
        "h-12": true,
        "w-64": true,
        border: true,
        "cursor-pointer": true,
        "text-burnedYellow": true,
        "hover:bg-white": true,
        "hover:relative": true,
        "hover:shadow-bm": true,
      };
    },
  },
  created() {
    this.setUserMenus().then((response) => {
      this.checMenuObj = response.data;
      response.data.forEach((element) => {
        this.arrMenus.push(element.description);
        element.ability_actions.forEach((item) => {
          this.arrSubMenus.push({ name: element.description, action: item.description });
        });
      });
    });
    this.cityeAcces();
  },
};
</script>

<style>
.dropdown_item-1 {
  transform-origin: top center;
  animation: scaleZ 600ms 60ms ease-in-out forwards;
}

.dropdown_item-2 {
  transform-origin: top center;
  animation: scaleZ 600ms 120ms ease-in-out forwards;
}
</style>
