var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        staticClass: "size",
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text:
                              _vm.profile.description + " | Perfis de acesso",
                            typeText: "custom",
                            weight: "semibold",
                            italic: false,
                            size: "3xl",
                            color: "ashes-hover",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch: "AccessControl/openModal",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return _vm._l(_vm.profiles, function (itens) {
                  return _c(
                    "div",
                    { key: itens.description, staticClass: "ml-8 mt-6" },
                    [
                      _c("Label", {
                        attrs: {
                          text: "Recurso: " + itens.description,
                          icon: null,
                          iconType: null,
                          type: "text-sm",
                          weight: "semibold",
                          normal: "",
                          mode: "uppercase",
                        },
                      }),
                      _c("AppCheckBox", {
                        staticClass: "break-all",
                        attrs: {
                          values: itens.permissions,
                          "label-text": "Permissões",
                          "show-label": "",
                          dispatch: "AccessControl/setPermits",
                        },
                      }),
                    ],
                    1
                  )
                })
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "Editar",
                            typeButton: "success",
                            dispatch: "AccessControl/sendPerf",
                            payload: _vm.profile,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "fechar",
                            typeButton: "danger",
                            dispatch: "AccessControl/openModal",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1422689400
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }