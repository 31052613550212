var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main w-full img-back" },
    [
      _c("div", { staticClass: "bg-transparent", attrs: { id: "top" } }, [
        _c(
          "div",
          {
            staticClass:
              "flex flex-wrap justify-between items-center ml-4 md:ml-16 lg:ml-32 pt-16 lg:pt-24 mb-6",
          },
          [
            _vm._m(0),
            _c(
              "div",
              {
                staticClass:
                  "flex justify-center items-center w-full lg:w-auto mt-5 lg:mt-0 sm:mt-2",
                attrs: { id: "menus" },
              },
              [
                _c("AppButtonEmpty", {
                  staticClass: "w-32 lg:w-36 h-10 mr-4 lg:mr-8",
                  attrs: {
                    active: true,
                    text: "Início",
                    "type-button": "default",
                    "type-text": "button-generic",
                  },
                }),
                _c(
                  "router-link",
                  { attrs: { to: "/sobre" } },
                  [
                    _c("AppButtonEmpty", {
                      staticClass: "w-32 lg:w-36 h-10 mr-4 lg:mr-8",
                      attrs: {
                        text: "Sobre",
                        "type-button": "default",
                        "type-text": "button-generic",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "router-link",
                  {
                    staticClass:
                      "text-white underline hover:text-burnedYellow mr-4 lg:mr-8",
                    attrs: { to: "/admin/entrar" },
                  },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Área de Login",
                        "type-text": "custom",
                        weight: "medium",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ]),
      _c("div", { staticClass: "mt-8 md:mt-12", attrs: { id: "mid" } }, [
        _c("div", { staticClass: "flex flex-wrap ml-4 md:ml-16 lg:ml-32" }, [
          _c(
            "div",
            {
              staticClass: "w-full lg:w-1/2 text-white z-10",
              attrs: { id: "text" },
            },
            [
              _c(
                "div",
                { staticClass: "mt-8 md:mt-10", attrs: { id: "description" } },
                [
                  _c(
                    "BaseText",
                    { attrs: { "type-text": "custom", size: "lg" } },
                    [
                      _vm._v(
                        " O Virando o Jogo é um projeto criado pelo Governo do Estado do Ceará voltado para adolescentes e jovens de 15 a 22 anos que não estão estudando e trabalhando. Quer saber se você pode participar? Preenche a ficha de inscrição e vem com a gente! "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-10 md:mt-12" },
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Para participar é só fazer a inscrição!",
                      "type-text": "custom",
                      weight: "bold",
                      size: "lg md:xl",
                    },
                  }),
                  _c("br"),
                  _c("BaseText", {
                    attrs: {
                      text: "Vem com a gente?",
                      "type-text": "custom",
                      weight: "medium",
                      size: "lg md:xl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "grid grid-cols-1 md:grid-cols-2 gap-4" },
                [
                  _c(
                    "div",
                    { staticClass: "flex my-12 mt-16 md:mt-20" },
                    [
                      _c("AppButtonEmpty", {
                        staticClass: "w-48 lg:w-56 h-14 lg:h-16 mr-4 lg:mr-8",
                        attrs: {
                          text: "Inscrição",
                          "type-button": "default",
                          "type-text": "button-generic",
                          dispatch: "Default/toggleModalPreInscription",
                          "call-to-action": "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "flex my-12 mt-16 md:mt-20" }, [
                    _c(
                      "a",
                      {
                        staticClass: "text-ashes-dark hover:text-burnedYellow",
                        attrs: {
                          href: "/docs/LIVRO_SUPERACAO_25X25_CM_DIGITAL.pdf",
                          target: "_blank",
                        },
                      },
                      [
                        _c("AppButtonEmpty", {
                          staticClass: "h-14 lg:h-16 mr-4 lg:mr-8",
                          attrs: {
                            text: "Livro Virando o Jogo",
                            "type-button": "default",
                            "type-text": "button-generic",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "bot w-full" }),
      _c("ModalPreInscription", {
        attrs: { open: _vm.modalPreInscriptionOpen },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "w-full lg:w-1/2 text-center lg:text-left",
        attrs: { id: "logo" },
      },
      [
        _c("img", {
          staticClass: "img-logo mx-auto lg:mx-0",
          attrs: {
            src: require("../assets/images/logo-inicio.png"),
            alt: "Logo",
          },
        }),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }