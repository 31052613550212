var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permitList
    ? _c("div", { staticClass: "grid auto-grid-auto p-10" }, [
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-3 justify-between items-center mx-6 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              {
                staticClass: "text-ashes-hover font-medium text-2xl col-span-2",
              },
              [
                _c("Label", {
                  class: _vm.title,
                  attrs: {
                    text: "Equipamentos",
                    icon: null,
                    iconType: null,
                    type: "text-3xl",
                    weight: "semibold",
                    normal: "",
                    mode: "normal-case",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mx-2 items-center justify-self-end" },
              [
                _vm.permit
                  ? _c("AppButtonColorful", {
                      attrs: {
                        text: "Adicionar equipamento",
                        typeButton: "primary",
                        showIcon: "",
                        iconName: "add",
                        iconType: "outlined",
                        dispatch: "Equipment/openModalEquipment",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "mx-6 mb-2", attrs: { id: "tabs-button" } },
          [
            _c("FilterComponent", [
              _c(
                "div",
                { staticClass: "col-span-4" },
                [
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "name",
                      "input-type": "text",
                      "icon-name": "search",
                      showIcon: "",
                      "show-label": "",
                      value: _vm.nameFiltered,
                      "label-text": "Pesquisar por Nome do Equipamento",
                      "input-placeholder": "Pesquisar por Nome do Equipamento",
                      dispatch: "EquipmentAll/filterName",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-4" },
                [
                  _c("Multiselect", {
                    staticClass: "mr-5",
                    attrs: {
                      id: "filterArea",
                      selectPlaceholder: "Selecionar Área",
                      "label-text": "Selecionar Área",
                      closeOnSelect: "",
                      "show-label": "",
                      multipleOption: false,
                      options: _vm.areas,
                      value: _vm.filteredArea,
                      dispatch: _vm.setAreas,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Município",
                      "multiple-option": false,
                      value: _vm.city,
                      options: _vm.cities,
                      "label-text": "Filtrar por Município",
                      dispatch: _vm.setCity,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    staticClass: "mr-5",
                    attrs: {
                      id: "filterState",
                      selectPlaceholder: "Selecionar Bairro",
                      "label-text": _vm.getLabelNeighborhoodFilter(),
                      closeOnSelect: "",
                      "show-label": "",
                      multipleOption: false,
                      options: _vm.neighborhoods_for_menus,
                      value: _vm.filteredNeighborhood,
                      dispatch: _vm.setNeighborhoods,
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "grid grid-cols-2 items-center mx-6 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              { staticClass: "mx-2" },
              [
                _vm.permitListAll
                  ? _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-consult-equipments",
                        showIcon: "",
                        iconName: "search",
                        text: "Consultar dados",
                        dispatch: "EquipmentAll/loadEquipment",
                        payload: "load",
                        typeButton: "primary",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.equipments.length > 0
              ? _c(
                  "div",
                  { staticClass: "mx-2 items-center justify-self-end" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.clearFilters()
                          },
                        },
                      },
                      [
                        _c("AppButtonColorful", {
                          attrs: {
                            id: "btn-clear-equipments",
                            showIcon: "",
                            iconName: "remove",
                            text: "Limpar consulta",
                            typeButton: "primary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { attrs: { id: "list-areas" } },
          [
            _c(
              "div",
              { staticClass: "mx-6" },
              [_c("EquipmentCards", { attrs: { data: _vm.equipments } })],
              1
            ),
            _vm.equipments.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "mx-12 my-6 pb-3",
                    attrs: { id: "areas-pagination" },
                  },
                  [
                    _c("BasePagination", {
                      attrs: {
                        total: _vm.totalEquipments,
                        "total-per-page": _vm.totalPerPage,
                        dispatch: "EquipmentAll/setEquipmentsOffset",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("ModalEquipment", { attrs: { open: _vm.openModalEquipment } }),
            _c("DeleteEquipmentConfirmation", {
              attrs: { open: _vm.openModalDelete },
            }),
          ],
          1
        ),
      ])
    : _c("ProhibitedAccessPage", {
        attrs: {
          classText:
            "grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }