var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { class: _vm.liClass }, [
    _c("div", { staticClass: "content-center" }, [
      _c(
        "div",
        { staticClass: "justify-self-start" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.area.name,
              typeText: "custom",
              weight: "bold",
              mode: "underline",
              italic: false,
              size: "xl",
            },
          }),
        ],
        1
      ),
    ]),
    _c(
      "div",
      { staticClass: "justify-items-start" },
      [
        _c("BaseText", {
          attrs: {
            text: _vm.area.qtdNeighborhoods + " bairros",
            typeText: "custom",
            italic: false,
            size: "sm",
            color: "ashes-dark",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "justify-items-start" },
      [
        _c("BaseText", {
          attrs: {
            text: _vm.area.qtdEquipment + " equipamentos",
            typeText: "custom",
            italic: false,
            size: "sm",
            color: "ashes-dark",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "text-right justify-self-end" },
      [
        _vm.permitUp
          ? _c("AppButtonIcon", {
              attrs: {
                iconName: "edit",
                dispatch: "Area/editModal",
                payload: _vm.area,
              },
            })
          : _vm._e(),
        _vm.permitDes
          ? _c("AppButtonIcon", {
              attrs: {
                iconName: "delete",
                payload: _vm.area,
                dispatch: "Areas/openCloseDeleteAreaConfirmation",
              },
            })
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }