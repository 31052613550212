<template>
  <div>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4 ml-5">
        <BaseText
          text="Nome"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Data"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Empresa"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Parceiro"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Segmento"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Ocupação"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Contratação"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li :key="opportunity.id" :data-index="index" v-for="(opportunity, index) in data">
        <div>
          <AppCardOpportunity :opportunity="opportunity" />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardOpportunity from "../../components/AppCardOpportunity.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";

export default {
  components: {
    AppCardOpportunity,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 21% 13% 15% 13% 11% 9% 10%;
}
</style>
