var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("Label", {
                          staticClass: "text-ashes-dark",
                          attrs: {
                            text: "Suspender Encontros",
                            icon: null,
                            "icon-type": null,
                            type: "text-2xl",
                            weight: "bold",
                            normal: "",
                            mode: "normal-case",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "Meeting/closeModalSuspendMeetingsByTeamAndDate",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "grid grid-flow-row auto-rows-max" },
                    [
                      _c(
                        "div",
                        { staticClass: "grid grid-flow-row auto-rows-max" },
                        [
                          _c("ExportTeamsPresenceCards", {
                            attrs: { data: _vm.teamsToExportPresences },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "Meeting/closeModalSuspendMeetingsByTeamAndDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "Suspender",
                            typeButton: "success",
                            dispatch: "Meeting/suspendMeetingsByTeamAndDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          4248200810
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }