var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-3" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start col-span-2" },
                      [
                        _c("Label", {
                          staticClass: "text-ashes-dark",
                          attrs: {
                            text: "Postergar Data de Expiração do Encaminhamento",
                            icon: null,
                            "icon-type": null,
                            type: "text-2xl",
                            weight: "bold",
                            normal: "",
                            mode: "normal-case",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "Forwardings/closeModalForwardingPostponeExpirationDate",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-start col-span-2" },
                      [
                        _c("BaseText", {
                          staticClass: "mr-1",
                          attrs: {
                            text: _vm.getUserName(),
                            typeText: "custom",
                            weight: "semibold",
                            color: "herbal",
                            size: "sm",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _vm.forwarding.id && _vm.inscription
                    ? _c("div", { staticClass: "grid grid-cols-2 mt-4" }, [
                        _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Dados de Cadastro do Encaminhamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("BaseText", {
                              staticClass: "mr-1",
                              attrs: {
                                text: _vm.getUserNameTitle(),
                                typeText: "custom",
                                weight: "semibold",
                                color: "burnedYellow-classic",
                                size: "sm",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Nome Aluno(a)",
                                text: _vm.inscription.student.name,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Edição",
                                text: _vm.inscription.season.name,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "CPF",
                                text: _vm._f("VMask")(
                                  _vm.inscription.student.cpf,
                                  "###.###.###-##"
                                ),
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Status do Encaminhamento",
                                text: _vm.forwarding_status.name,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Política do Encaminhamento",
                                text: _vm.forwarding_policy.name,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Equipamento do Encaminhamento",
                                text: _vm.forwarding.forwarding_equipment,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text":
                                  "Data de cadastro do Encaminhamento",
                                text: _vm.formattedDate(_vm.forwardingDate),
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text":
                                  "Data de Expiração do Encaminhamento",
                                text: _vm.formattedDate(
                                  _vm.forwardingExpirationDate
                                ),
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Descrição",
                                text: _vm.getDescription(),
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações de Data",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "return_date",
                            "input-type": "date",
                            value: _vm.forwarding.forwarding_return_date,
                            "show-label": "",
                            readonly: true,
                            "label-text": "Data de Retorno",
                            dispatch: "Forwardings/setForwardingReturnDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "expedition",
                            "input-type": "date",
                            value: _vm.forwardingExpirationDate,
                            "show-label": "",
                            "label-text": "Data de Expiração",
                            dispatch: "Forwardings/setForwardingExpirationDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "dif",
                            "input-type": "number",
                            value: _vm.differenceBetweenDates,
                            "show-label": "",
                            "label-text":
                              "Total de Dias entre as Datas de Cadastro e Expiração",
                            dispatch: "Forwardings/setDifferenceBetweenDates",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-1" },
                      [
                        _c("AppTextField", {
                          attrs: {
                            "show-label": "",
                            "label-text": "Data nominal de Expiração",
                            text: _vm.getFullDate(),
                            color: "carmesim",
                            "type-text": "custom",
                            weight: "semibold",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Observações",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseTextArea", {
                          attrs: {
                            active: "",
                            inputName: "comments",
                            labelText: "Observações",
                            showLabel: "",
                            rows: "4",
                            value:
                              _vm.forwarding_postponement_history
                                .forwarding_postponement_comments,
                            dispatch:
                              "ForwardingPostponementHistories/setForwardingPostponementComments",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "Forwardings/closeModalForwardingPostponeExpirationDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "Salvar",
                            typeButton: "success",
                            dispatch: "Forwardings/update",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2927135690
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }