var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { class: _vm.gridClass, style: _vm.gridStyle }, [
        !_vm.togglePsychosocialAccompanimentUnaccompanied
          ? _c(
              "div",
              { staticClass: "mt-10 ml-5" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Data Criação",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.togglePsychosocialAccompanimentUnaccompanied
          ? _c(
              "div",
              { staticClass: "mt-10 ml-1" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Data Agend. Encont.",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.togglePsychosocialAccompanimentUnaccompanied
          ? _c(
              "div",
              { staticClass: "mt-10 ml-1" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Data Últ. Atualiz.",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.togglePsychosocialAccompanimentUnaccompanied
          ? _c(
              "div",
              { staticClass: "mt-10 ml-1" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Atendimento",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.togglePsychosocialAccompanimentUnaccompanied
          ? _c(
              "div",
              { staticClass: "mt-10 ml-1" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Status",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.togglePsychosocialAccompanimentUnaccompanied
          ? _c(
              "div",
              { staticClass: "mt-10 ml-1" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Tem Encaminhamento?",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.togglePsychosocialAccompanimentUnaccompanied
          ? _c(
              "div",
              { staticClass: "mt-10 ml-1" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Tem Situação de Vulner. ?",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.togglePsychosocialAccompanimentUnaccompanied
          ? _c(
              "div",
              { staticClass: "mt-10 ml-1" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Situação de Vulner.",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        !_vm.togglePsychosocialAccompanimentUnaccompanied
          ? _c(
              "div",
              { staticClass: "mt-10 ml-1" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Origin. de outro atendiment.?",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.togglePsychosocialAccompanimentUnaccompanied
          ? _c(
              "div",
              { staticClass: "mt-10 ml-7" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Data da Justificativa",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.togglePsychosocialAccompanimentUnaccompanied
          ? _c(
              "div",
              { staticClass: "mt-10 ml-1" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Justificativa",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("div", { staticClass: "flex" }),
      ]),
      _c(
        "BaseStaggeredFade",
        { staticClass: "-mx-12", attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.data, function (psychosocial_accompaniment, index) {
          return _c(
            "li",
            {
              key: psychosocial_accompaniment.id,
              attrs: { "data-index": index },
            },
            [
              _c(
                "div",
                [
                  _c("AppCardPsychosocialAccompanimentEvolution", {
                    attrs: {
                      psychosocial_accompaniment: psychosocial_accompaniment,
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "border-ashes w-full border ml-5" }),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }