var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.season.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Atualizar edição",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Adicionar edição",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch: "Seasons/closeModal",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações Gerais",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "grid grid-rols-2" }, [
                      _c(
                        "div",
                        [
                          _c("BaseInput", {
                            attrs: {
                              id: "name",
                              "input-name": "name",
                              "input-placeholder": "Edição XXX - 2000...",
                              "input-type": "text",
                              "show-label": "",
                              "label-text": "nome",
                              value: _vm.season.name,
                              required: "",
                              dispatch: "Seasons/setSeasonName",
                              "error-message": _vm.getErrorMessage(
                                "name",
                                _vm.season.name
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "ml-4 grid grid-rols-2" }, [
                      _c(
                        "div",
                        [
                          _c("Multiselect", {
                            attrs: {
                              closeOnSelect: false,
                              selectPlaceholder: "Selecione as opções",
                              multipleOption: "",
                              options: _vm.areas,
                              "show-label": true,
                              "label-text": "Áreas *",
                              value: _vm.season.areas_attributes,
                              dispatch: _vm.setArea,
                              "error-message": _vm.getErrorMessage(
                                "areas",
                                _vm.season.areas
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 mt-6" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Período de Inscrições",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "grid grid-rols-2" }, [
                      _c(
                        "div",
                        [
                          _c("BaseInput", {
                            attrs: {
                              active: "",
                              "input-name": "date_inscription_start",
                              value: _vm.formatDate(
                                _vm.season.date_inscription_start
                              ),
                              "input-type": "date",
                              "show-icon": "",
                              "icon-name": "today",
                              "show-label": "",
                              "label-text": "início",
                              required: "",
                              dispatch: "Seasons/setDateInscriptionStart",
                              "error-message": _vm.getErrorMessage(
                                "date_inscription_start",
                                _vm.season.date_inscription_start
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "ml-4 grid grid-rols-2" }, [
                      _c(
                        "div",
                        [
                          _c("BaseInput", {
                            attrs: {
                              active: "",
                              "input-name": "date_inscription_end",
                              value: _vm.formatDate(
                                _vm.season.date_inscription_end
                              ),
                              "input-type": "date",
                              "show-icon": "",
                              "icon-name": "today",
                              "show-label": "",
                              "label-text": "Término",
                              required: "",
                              dispatch: "Seasons/setDateInscriptionEnd",
                              "error-message": _vm.getErrorMessage(
                                "date_inscription_end",
                                _vm.season.date_inscription_end
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 mt-6" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Período de Execuções",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "grid grid-rols-2" }, [
                      _c(
                        "div",
                        [
                          _c("BaseInput", {
                            attrs: {
                              active: "",
                              "input-name": "date_execution_start",
                              value: _vm.formatDate(
                                _vm.season.date_execution_start
                              ),
                              "input-type": "date",
                              "show-icon": "",
                              "icon-name": "today",
                              "show-label": "",
                              "label-text": "Início",
                              required: "",
                              dispatch: "Seasons/setDateExecutionStart",
                              "error-message": _vm.getErrorMessage(
                                "date_execution_start",
                                _vm.season.date_execution_start
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "ml-4 grid grid-rols-2" }, [
                      _c(
                        "div",
                        [
                          _c("BaseInput", {
                            attrs: {
                              active: "",
                              "input-name": "date_execution_end",
                              value: _vm.formatDate(
                                _vm.season.date_execution_end
                              ),
                              "input-type": "date",
                              icon: "today",
                              "show-icon": "",
                              "icon-name": "today",
                              "show-label": "",
                              "label-text": "Término",
                              required: "",
                              dispatch: "Seasons/setDateExecutionEnd",
                              "error-message": _vm.getErrorMessage(
                                "date_execution_end",
                                _vm.season.date_execution_end
                              ),
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch: "Seasons/closeModal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.season.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "salvar",
                                typeButton: "success",
                                dispatch: "Seasons/updateSeason",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "criar",
                                typeButton: "success",
                                dispatch: "Seasons/saveSeason",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1005414637
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }