<template>
  <BaseCard :bg_color="getbgType">
    <div class="grid grid-cols-custom items-center container1">
      <div class="mt-4">
        <BaseText
          :text="formattedDate(school_reinsertion.created_at)"
          mode="normal-case"
          :color="getColorText"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="formattedDate(school_reinsertion.reinsertion_date)"
          mode="normal-case"
          :color="getColorText"
          typeText="custom"
          weight="semibold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="formattedDate(school_reinsertion.activity_type.name)"
          mode="normal-case"
          :color="getColorText"
          typeText="custom"
          weight="semibold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="school_reinsertion.result_type ? school_reinsertion.result_type.name : '-'"
          mode="normal-case"
          :color="getColorText"
          typeText="custom"
          weight="semibold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="school_reinsertion.articulator"
          mode="normal-case"
          :color="getColorText"
          typeText="custom"
          weight="semibold"
          size="base"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="permitShow"
          iconName="visibility"
          dispatch="SchoolReinsertions/openViewModal"
          :payload="school_reinsertion"
          :iconColor="getColorText"
        />
        <AppButtonIcon
          v-if="
            permit &&
            (this.school_reinsertion?.result_type?.id === 2 ||
              this.school_reinsertion?.activity_type?.id === 3)
          "
          iconName="event"
          dispatch="SchoolReinsertions/openModalSchoolReinsertionDeadlineAttachProofOfEnrollment"
          :payload="school_reinsertion"
          :iconColor="getColorText"
          title="Editar data para anexo de comprovante de matrícula"
        />
        <AppButtonIcon
          v-if="
            permit &&
            (this.school_reinsertion?.result_type?.id === 1 ||
              this.school_reinsertion?.activity_type?.id === 2)
          "
          iconName="event"
          dispatch="SchoolReinsertions/openModalSchoolReinsertionReturnLetterDeadline"
          :payload="school_reinsertion"
          :iconColor="getColorText"
          title="Editar data de retorno da carta"
        />
        <AppButtonIcon
          v-if="permitUp && toggleSchoolReinsertionNotReinserted"
          iconName="edit"
          :payload="school_reinsertion"
          :iconColor="getColorText"
          dispatch="SchoolReinsertions/openEditModalAddActivity"
          title="Editar Atendimento"
        />
        <AppButtonIcon
          v-if="permitDes && toggleSchoolReinsertionNotReinserted"
          iconName="delete"
          :payload="school_reinsertion"
          dispatch="SchoolReinsertions/toggleModalDeleteSchoolReinsertion"
          :iconColor="getColorText"
          title="Excluir Atendimento"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitShow: false,
      permit: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    school_reinsertion: {
      type: Object,
    },
  },
  created() {
    this.canAcces("school_reinsertion_update").then((response) => {
      this.permitUp = response;
    });
    this.canAcces("school_reinsertion_show").then((response) => {
      this.permitShow = response;
    });
    this.canAcces("school_reinsertion_destroy").then((response) => {
      this.permitDes = response;
    });
    this.canAcces("school_reinsertion_create").then((response) => {
      this.permit = response;
    });
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
  },
  computed: {
    ...mapState({
      toggleSchoolReinsertionNotReinserted: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionNotReinserted,
    }),
    getbgType() {
      let bgType = "";
      switch (this.school_reinsertion.activity_type.id) {
        case 0:
          bgType = "bg-rose";
          break;
        case 1:
          bgType = "bg-cyan";
          break;
        case 2:
          bgType = "bg-ashes-dark";
          break;
        case 3:
          bgType = "bg-herbal-lemon";
          break;
      }
      return bgType;
    },
    getColorText() {
      let colortext = "";
      switch (this.school_reinsertion.activity_type.id) {
        case 0:
        case 1:
        case 2:
        case 3:
          colortext = "white";
          break;
        case 4:
          colortext = "ashes-dark";
          break;
      }
      return colortext;
    },
    getIconColor() {
      let iconColor = "";
      switch (this.school_reinsertion.activity_type.id) {
        case 0:
          iconColor = "ashes-dark";
          break;
        case 1:
        case 2:
        case 3:
          iconColor = "white";
          break;
      }
      return iconColor;
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 13% 20% 22% 22% 17% 10%;
}
</style>
