var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "font-system" },
    [
      _c(
        "div",
        { staticClass: "mx-12 my-6" },
        [_c("Breadcrumbs", { attrs: { breadcrumbs: _vm.breadcrumbs } })],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-3 justify-between items-center mx-12 my-6",
          attrs: { id: "tabs-button" },
        },
        [
          _c("div", {
            staticClass:
              "text-ashes-hover font-medium text-2xl mx-2 col-span-2",
            domProps: { innerHTML: _vm._s(_vm.getTitle) },
          }),
          _c(
            "div",
            { staticClass: "mx-2 items-center justify-self-end" },
            [
              _vm.permitCreate && !_vm.payment_sheet.closed
                ? _c("AppButtonColorful", {
                    attrs: {
                      id: "btn-add-payment_sheet",
                      showIcon: "",
                      iconName: "add",
                      text: "Adicionar Beneficiários",
                      dispatch:
                        "PaymentSheets/openModalAddBeneficiariesScholarship",
                      typeButton: "primary",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "mx-14", attrs: { id: "tabs-button" } },
        [
          _c("FilterComponent", [
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("Multiselect", {
                  attrs: {
                    "close-on-select": true,
                    "show-label": "",
                    "clear-on-select": "",
                    "label-text": "Status do Pagamento",
                    "select-placeholder": "Selecione o status",
                    "multiple-option": false,
                    value: _vm.beneficiary_params.status,
                    options: _vm.beneficiaries_status_options,
                    dispatch: _vm.setFilterStatus,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("Multiselect", {
                  attrs: {
                    "close-on-select": true,
                    "show-label": "",
                    "clear-on-select": "",
                    "label-text": "Município",
                    "select-placeholder": "Selecione o município",
                    "multiple-option": false,
                    value: _vm.beneficiary_params.city,
                    options: _vm.cities,
                    dispatch: _vm.setFilterCity,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("Multiselect", {
                  attrs: {
                    "close-on-select": true,
                    "show-label": "",
                    "clear-on-select": "",
                    "label-text": "Área",
                    "select-placeholder": "Selecione a área",
                    "multiple-option": false,
                    value: _vm.beneficiary_params.area,
                    options: _vm.user_areas,
                    dispatch: _vm.setFilterArea,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("Multiselect", {
                  attrs: {
                    "close-on-select": true,
                    "show-label": "",
                    "clear-on-select": "",
                    "label-text": _vm.getLabelEquipmentFilter(),
                    "select-placeholder": "Selecione o equipamento",
                    "multiple-option": false,
                    value: _vm.beneficiary_params.equipment,
                    options: _vm.equipments,
                    dispatch: _vm.setFilterEquipment,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-4" },
              [
                _c("Multiselect", {
                  attrs: {
                    "close-on-select": true,
                    "show-label": "",
                    "clear-on-select": "",
                    label: "code",
                    "label-text": "Turma",
                    "select-placeholder": "Selecione a Turma",
                    "multiple-option": false,
                    value: _vm.beneficiary_params.team,
                    options: _vm.teams_for_menus,
                    dispatch: _vm.setFilterTeam,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("BaseInput", {
                  attrs: {
                    active: "",
                    "input-name": "name",
                    "input-type": "text",
                    "icon-name": "search",
                    showIcon: "",
                    "show-label": "",
                    value: _vm.beneficiary_params.name,
                    "label-text": "Pesquisar por Nome",
                    "input-placeholder": "Pesquisar Nome",
                    dispatch: "Beneficiaries/setFilterName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("BaseInput", {
                  attrs: {
                    active: "",
                    "input-name": "cpf",
                    "input-type": "text",
                    "icon-name": "search",
                    showIcon: "",
                    "show-label": "",
                    value: _vm.beneficiary_params.cpf,
                    "label-text": "Pesquisar por CPF",
                    "input-placeholder": "Pesquisar CPF",
                    dispatch: "Beneficiaries/setFilterCpf",
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "flex mt-2" }, [
            _vm.permit_export_spreadsheet && _vm.beneficiaries.length
              ? _c("div", { staticClass: "mr-4" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.exportToExcel("beneficiaries_spreadsheet")
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "inline-block",
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          src: require("../../../../assets/images/logout_black_24dp.svg"),
                          alt: "",
                        },
                      }),
                      _c("BaseText", {
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          text: "Exportar Folha de Pagamento",
                          typeText: "custom",
                          italic: false,
                          size: "xs",
                          color: "burnedYellow",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.permitDownloadPdf && _vm.beneficiaries.length
              ? _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.exportToPdf()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "inline-block",
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          src: require("../../../../assets/images/pdf_icon.svg"),
                          alt: "",
                        },
                      }),
                      _c("BaseText", {
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          text: "Exportar Folha de Pagamento em PDF",
                          typeText: "custom",
                          italic: false,
                          size: "xs",
                          color: "burnedYellow",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "grid grid-cols-4 items-center mx-12 my-6",
          attrs: { id: "tabs-button" },
        },
        [
          _c(
            "div",
            { staticClass: "col-span-3 flex space-x-2" },
            [
              _vm.permit
                ? _c("AppButtonColorful", {
                    attrs: {
                      id: "btn-consult-payment_sheets",
                      showIcon: "",
                      iconName: "search",
                      text: "Consultar Beneficiários",
                      dispatch: "Beneficiaries/loadBeneficiaries",
                      payload: "load",
                      typeButton: "primary",
                    },
                  })
                : _vm._e(),
              _vm.beneficiaries.length > 0
                ? _c("AppButtonMinimal", {
                    attrs: {
                      id: "btn-update-all-statuses",
                      text: "Atualizar Todos os Status",
                      iconName: "update",
                      size: "small",
                      variant: "filled",
                      color: "secondary",
                      dispatch:
                        "Beneficiaries/openModalUpdateAllBeneficiariesStatuses",
                      compact: "",
                    },
                  })
                : _vm._e(),
              _vm.beneficiaries.length > 0
                ? _c("AppButtonMinimal", {
                    attrs: {
                      id: "btn-update-all-values",
                      text: "Valor Padrão",
                      iconName: "payments",
                      size: "small",
                      variant: "filled",
                      color: "warning",
                      dispatch:
                        "Beneficiaries/openModalUpdateAllBeneficiariesValues",
                      compact: "",
                    },
                  })
                : _vm._e(),
              _vm.beneficiaries.length > 0 && !_vm.payment_sheet.closed
                ? _c("AppButtonMinimal", {
                    attrs: {
                      id: "btn-close-payment-sheet",
                      text: "Fechar Folha de Pagamento",
                      iconName: "cancel",
                      size: "small",
                      variant: "filled",
                      color: "danger",
                      dispatch: "PaymentSheets/closePaymentSheet",
                      compact: "",
                    },
                  })
                : _vm._e(),
              _vm.payment_sheet.closed
                ? _c("AppButtonMinimal", {
                    attrs: {
                      id: "btn-close-payment-sheet",
                      text: "Abrir Folha de Pagamento",
                      iconName: "check",
                      size: "small",
                      variant: "filled",
                      color: "success",
                      dispatch: "PaymentSheets/openPaymentSheet",
                      compact: "",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
          _c("div", { staticClass: "flex justify-end" }, [
            _vm.beneficiaries.length > 0
              ? _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.clearFields()
                      },
                    },
                  },
                  [
                    _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-consult-payment_sheets",
                        showIcon: "",
                        iconName: "remove",
                        text: "Limpar consulta",
                        typeButton: "primary",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
        ]
      ),
      _c("div", { attrs: { id: "list-payment_sheets" } }, [
        _c(
          "div",
          { staticClass: "mx-12" },
          [
            _c("TransportationVoucherBeneficiariesCards", {
              attrs: { data: _vm.beneficiaries },
            }),
          ],
          1
        ),
        _vm.beneficiaries.length > 0
          ? _c(
              "div",
              {
                staticClass: "mx-12 my-6 pb-3",
                attrs: { id: "payment_sheets-pagination" },
              },
              [
                _c("BasePagination", {
                  attrs: {
                    total: _vm.totalBeneficiaries,
                    "total-per-page": _vm.totalPerPage,
                    dispatch: "Beneficiaries/setBeneficiariesOffset",
                  },
                }),
                _c("div", { staticClass: "info-box" }, [
                  _c("div", { staticClass: "info-text" }, [
                    _vm._v(" Total de Beneficiários: "),
                    _c("span", { staticClass: "info-value" }, [
                      _vm._v(_vm._s(_vm.payment_sheet.total_beneficiaries)),
                    ]),
                  ]),
                  _c("div", { staticClass: "info-text" }, [
                    _vm._v(" Valor Total: "),
                    _c("span", { staticClass: "info-value" }, [
                      _vm._v(
                        "R$ " +
                          _vm._s(
                            _vm.payment_sheet.total_payment_value.toLocaleString(
                              "pt-BR",
                              {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                              }
                            )
                          )
                      ),
                    ]),
                  ]),
                ]),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.getLoadingVariablesState
        ? _c("div", [_c("PreLoading")], 1)
        : _vm._e(),
      _c("ModalAddBeneficiaries", {
        attrs: { open: _vm.modalToggleAddBeneficiariesScholarship },
      }),
      _c("ModalUpdateAllBeneficiariesStatuses", {
        attrs: { open: _vm.modalToggleUpdateAllBeneficiaresStatuses },
      }),
      _c("ModalUpdateAllBeneficiariesValues", {
        attrs: { open: _vm.modalToggleUpdateAllBeneficiaresValues },
      }),
      _c("ConfirmUpdateAllBeneficiariesStatuses", {
        attrs: { open: _vm.modalToggleConfirmUpdateAllBeneficiaresStatuses },
      }),
      _c("ModalExportSpreadsheets", {
        attrs: {
          dataSpreadsheet: _vm.dataSpreadsheet,
          modalToggleExport: _vm.modalToggleExport,
          titleModal: _vm.titleModal,
          dispatchToCloseModal: _vm.dispatchToCloseModal,
          json_data: _vm.json_data,
          sheetTabName: _vm.sheetTabName,
          fileName: _vm.fileName,
          variablePreloading: _vm.variablePreloading,
          json_fields: _vm.json_fields,
          dispatchOpenModal: _vm.dispatchOpenModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }