<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center container1">
      <div class="mt-4">
        <BaseText
          :text="
            vacancy_request?.inscription?.student?.name ? vacancy_request?.inscription?.student?.name : '-'
          "
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="semibold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="
            vacancy_request?.inscription?.student?.neighborhood?.area?.name
              ? vacancy_request?.inscription?.student?.neighborhood?.area?.name
              : '-'
          "
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="semibold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="vacancy_request?.vacancy_type?.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="semibold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="vacancy_request?.accepted_request ? 'Sim' : 'Não'"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="semibold"
          size="base"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="permitShow"
          iconName="visibility"
          dispatch="VacancyRequests/openViewModal"
          :payload="vacancy_request"
          title="Visualizar Solicitação de Vaga"
        />
        <AppButtonIcon
          v-if="permitUp"
          iconName="edit"
          :payload="vacancy_request"
          dispatch="VacancyRequests/openEditModalAddVacancyRequest"
          title="Editar Solicitação de Vaga"
        />
        <AppButtonIcon
          v-if="permitDes"
          iconName="delete"
          :payload="vacancy_request"
          dispatch="VacancyRequests/toggleModalDeleteVacancyRequest"
          title="Excluir Solicitação de Vaga"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitShow: false,
      permit: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    vacancy_request: {
      type: Object,
    },
  },
  created() {
    this.canAcces("vacancy_request_update").then((response) => {
      this.permitUp = response;
    });
    this.canAcces("vacancy_request_show").then((response) => {
      this.permitShow = response;
    });
    this.canAcces("vacancy_request_destroy").then((response) => {
      this.permitDes = response;
    });
    this.canAcces("vacancy_request_create").then((response) => {
      this.permit = response;
    });
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
  },
  computed: {
    ...mapState({
      toggleSchoolReinsertionNotReinserted: (state) =>
        state.SchoolReinsertions.toggleSchoolReinsertionNotReinserted,
    }),
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 32% 15% 19% 25% 15%;
}
</style>
