var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { class: _vm.liClass }, [
    _c("div", { staticClass: "grid grid-flow-col grids-cols-custom2" }, [
      _c("div", { staticClass: "flex justify-start items-center" }, [
        _c("div", { staticClass: "place-self-center" }, [
          _c("div", { class: _vm.iconClass }, [
            _c("img", {
              attrs: {
                src: require("../assets/images/profile-vj.png"),
                alt: "Maria Antônia Soares",
              },
            }),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "justify-self-start place-self-center" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.user.name,
                typeText: "custom",
                mode: "uppercase",
                weight: "bold",
                size: "x1",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "justify-self-start" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.user.email,
              typeText: "custom",
              italic: false,
              mode: "normal-case",
              size: "sm",
              color: "ashes-dark",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "justify-self-end" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.user.cpf,
              typeText: "custom",
              italic: false,
              mode: "normal-case",
              size: "sm",
              color: "ashes-dark",
            },
          }),
        ],
        1
      ),
      _vm.permit
        ? _c("div", [
            _c(
              "div",
              [
                _c("BaseText", {
                  staticClass: "pl-32",
                  attrs: {
                    text:
                      _vm.user.ability_profile.name != undefined
                        ? _vm.user.ability_profile.name
                        : "Não informado",
                    typeText: "custom",
                    italic: false,
                    mode: "normal-case",
                    size: "sm",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            ),
          ])
        : _c("div", [
            _c(
              "div",
              { staticClass: "pl-32" },
              [
                _c("BaseText", {
                  staticClass: "mr-1",
                  attrs: {
                    text: "Não informado",
                    typeText: "custom",
                    italic: false,
                    mode: "normal-case",
                    size: "sm",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            ),
          ]),
      _c(
        "div",
        { staticClass: "pl-28" },
        [
          _c("BaseText", {
            staticClass: "mr-1",
            attrs: {
              text: _vm.user.active ? "Ativo" : "Desativado",
              typeText: "custom",
              italic: false,
              mode: "normal-case",
              size: "sm",
              color: "ashes-dark",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("AppButtonIcon", {
            attrs: {
              iconName: "edit",
              dispatch: "User/openEditModal",
              payload: _vm.user,
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm.permitResetPassword
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "vpn_key",
                  dispatch: "User/openModalResetPassword",
                  payload: _vm.user,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm.permitUp
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: _vm.chooseIcon(),
                  dispatch: "User/openModalActivation",
                  payload: _vm.user,
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm.permitDes
        ? _c(
            "div",
            [
              _c("AppButtonIcon", {
                attrs: {
                  iconName: "delete",
                  dispatch: "User/openCloseDeleteUserConfirmation",
                  payload: _vm.user,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }