import { getUsers } from "../../service/UsersApi";
import Vue from "vue";

const state = {
  users: [],
  filterUsers: [],
  modalDeleteUsersConfirmationOpen: false,
  userIdForDeletion: null,
};

const mutations = {
  setUsersMutation: (state, users) => {
    state.users = users;
    state.filterUsers = users;
  },
  addUserMutation: (state, user) => {
    state.users.push(user);
  },
  filterListMutation: (state, input) => {
    let users = state.users;
    state.filterUsers = users.filter((obj) => {
      let name = obj.name.toLowerCase().indexOf(input.value.toLowerCase());
      let cpf = obj.cpf.toLowerCase().indexOf(input.value.toLowerCase());

      return name > -1 || cpf > -1;
    });
  },
};

const actions = {
  setUsers: ({ commit }, users) => {
    commit("setUsersMutation", users);
  },
  addUser: ({ commit }, user) => {
    commit("addUserMutation", user);
  },
  filterList: ({ commit }, payload) => {
    commit("filterListMutation", payload);
  },
  loadUsers: ({ commit }, payload) => {
    getUsers(payload)
      .then((response) => {
        commit("setUsersMutation", response.data.user);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
};

export default {
  namespaced: true,
  state,
  actions,
  mutations,
};
