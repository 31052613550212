var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.accompanimentOrigin
    ? _c("div", { staticClass: "grid grid-cols-4 gap-4 mt-4" }, [
        _c(
          "div",
          { staticClass: "col-span-4 text-burnedYellow pb-2" },
          [
            _c("Label", {
              attrs: {
                text: "Informações do Atendimento de Origem",
                icon: null,
                "icon-type": null,
                type: "text-sm",
                weight: "semibold",
                normal: "",
                mode: "uppercase",
              },
            }),
            _c("div", { staticClass: "border-ashes-hover border-b-2 w-full" }),
          ],
          1
        ),
        _vm.accompanimentOrigin?.date_accompaniment
          ? _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Data do Encontro",
                    text: _vm.formattedDate(
                      _vm.accompanimentOrigin?.date_accompaniment
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.accompanimentOrigin
          ? _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Tipo do Atendimento",
                    text:
                      _vm.accompanimentOrigin?.accompaniment_type?.name || "",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.accompanimentOrigin
          ? _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Status do Acompanhamento",
                    text:
                      _vm.accompanimentOrigin?.accompaniment_status?.name || "",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.accompanimentOrigin
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Usuário responsável pelo cadastro",
                    text: _vm.accompanimentOrigin?.user?.name || "",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }