var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid gap-6 px-12 bg-gray-50 rounded-lg shadow-lg" },
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-1 pt-4" },
        [
          _c("Label", {
            staticClass: "text-gray-800",
            attrs: {
              text: "Redefinir Senha",
              icon: null,
              "icon-type": null,
              type: "text-2xl",
              weight: "bold",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("Label", {
            staticClass: "text-burnedYellow uppercase",
            attrs: {
              text: "Informações Gerais do Usuário(a)",
              icon: null,
              "icon-type": null,
              type: "text-sm",
              weight: "semibold",
            },
          }),
          _c("div", { staticClass: "border-b-2 border-gray-300 w-full mt-1" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "grid grid-cols-2 gap-6" },
        [
          _c("AppTextField", {
            attrs: {
              "show-label": "",
              "label-text": "Nome",
              text: _vm.user.name,
              "type-text": "topic-details",
            },
          }),
          _c("AppTextField", {
            attrs: {
              "show-label": "",
              "label-text": "Perfil de Acesso",
              text: _vm.user.roles_attributes.name,
              "type-text": "topic-details",
            },
          }),
          _c("AppTextField", {
            attrs: {
              "show-label": "",
              "label-text": "CPF",
              text: _vm.user.cpf,
              "type-text": "topic-details",
            },
          }),
          _c("AppTextField", {
            attrs: {
              "show-label": "",
              "label-text": "Telefone",
              text: _vm.user.phone_number,
              "type-text": "topic-details",
            },
          }),
          _c("AppTextField", {
            attrs: {
              "show-label": "",
              "label-text": "E-mail",
              text: _vm.user.email,
              "type-text": "topic-details",
            },
          }),
          _c("AppTextField", {
            attrs: {
              "show-label": "",
              "label-text": "Usuário(a) Ativo(a)?",
              text: _vm.isUserActive,
              "type-text": "topic-details",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("Label", {
            staticClass: "text-burnedYellow uppercase",
            attrs: {
              text: "Informações de Senha",
              icon: null,
              "icon-type": null,
              type: "text-sm",
              weight: "semibold",
            },
          }),
          _c("div", { staticClass: "border-b-2 border-gray-300 w-full mt-1" }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "grid grid-cols-2 gap-6" },
        [
          _c("BaseInput", {
            attrs: {
              id: "password_user",
              "input-name": "password_user",
              "input-type": "password",
              "show-label": "",
              "label-text": "Senha",
              value: _vm.password,
              required: "",
              dispatch: "User/setPassword",
            },
          }),
          _c("BaseInput", {
            attrs: {
              id: "password_confirmation_user",
              "input-name": "password_confirmation_user",
              "input-type": "password",
              "show-label": "",
              "label-text": "Confirme a Senha",
              required: "",
              value: _vm.password_confirmation,
              dispatch: "User/setPasswordConfirmation",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "validation-feedback space-y-3 p-4 rounded-md bg-white border border-gray-200 shadow-sm",
        },
        [
          _c(
            "div",
            {
              staticClass:
                "flex items-center space-x-2 transition-transform transform hover:scale-105",
            },
            [
              _c(
                "span",
                {
                  class: {
                    "text-green-500": _vm.validations.minLength,
                    "text-red-500": !_vm.validations.minLength,
                  },
                },
                [
                  _c("span", { staticClass: "material-icons" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.validations.minLength ? "check_circle" : "cancel"
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "p",
                {
                  class: {
                    "text-green-600 font-semibold": _vm.validations.minLength,
                    "text-red-600": !_vm.validations.minLength,
                  },
                },
                [_vm._v(" Mínimo de 8 caracteres ")]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "flex items-center space-x-2 transition-transform transform hover:scale-105",
            },
            [
              _c(
                "span",
                {
                  class: {
                    "text-green-500": _vm.validations.uppercase,
                    "text-red-500": !_vm.validations.uppercase,
                  },
                },
                [
                  _c("span", { staticClass: "material-icons" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.validations.uppercase ? "check_circle" : "cancel"
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "p",
                {
                  class: {
                    "text-green-600 font-semibold": _vm.validations.uppercase,
                    "text-red-600": !_vm.validations.uppercase,
                  },
                },
                [_vm._v(" Pelo menos uma letra maiúscula ")]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "flex items-center space-x-2 transition-transform transform hover:scale-105",
            },
            [
              _c(
                "span",
                {
                  class: {
                    "text-green-500": _vm.validations.number,
                    "text-red-500": !_vm.validations.number,
                  },
                },
                [
                  _c("span", { staticClass: "material-icons" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.validations.number ? "check_circle" : "cancel"
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "p",
                {
                  class: {
                    "text-green-600 font-semibold": _vm.validations.number,
                    "text-red-600": !_vm.validations.number,
                  },
                },
                [_vm._v(" Pelo menos um número ")]
              ),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "flex items-center space-x-2 transition-transform transform hover:scale-105",
            },
            [
              _c(
                "span",
                {
                  class: {
                    "text-green-500": _vm.validations.match,
                    "text-red-500": !_vm.validations.match,
                  },
                },
                [
                  _c("span", { staticClass: "material-icons" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.validations.match ? "check_circle" : "cancel"
                        ) +
                        " "
                    ),
                  ]),
                ]
              ),
              _c(
                "p",
                {
                  class: {
                    "text-green-600 font-semibold": _vm.validations.match,
                    "text-red-600": !_vm.validations.match,
                  },
                },
                [_vm._v(" Senhas coincidem ")]
              ),
            ]
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "grid grid-cols-2 gap-4 mt-6 pb-4" },
        [
          _c("AppButtonEmpty", {
            staticClass: "justify-self-start",
            attrs: {
              text: "Descartar",
              typeButton: "danger",
              dispatch: "User/closeModalResetPassword",
            },
          }),
          _c("AppButtonEmpty", {
            staticClass: "justify-self-end",
            attrs: {
              text: "Salvar",
              typeButton: "success",
              dispatch: "User/updatePassword",
              disabled: !_vm.isFormValid,
            },
          }),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }