var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid grid-cols-4" }, [
    _c(
      "div",
      { staticClass: "justify-self-start col-span-3" },
      [
        _c("Label", {
          class: _vm.headerClass,
          attrs: {
            text: _vm.headerText,
            icon: null,
            "icon-type": null,
            type: "text-2xl",
            weight: "bold",
            normal: "",
            mode: "normal-case",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "justify-self-end" },
      [
        _c("AppButtonIcon", {
          attrs: { dispatch: _vm.closeDispatch, forClose: "" },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }