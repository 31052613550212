var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.open
        ? _c("BaseModal", {
            attrs: { headerColor: "#f1f1f1" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "flex flex-row-reverse" },
                        [
                          _c("AppButtonIcon", {
                            attrs: {
                              "for-close": "",
                              dispatch: "ModalInscription/closeModal",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mb-3 justify-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "mr-6", attrs: { id: "avatar" } },
                            [
                              _c("img", {
                                staticClass: "rounded-full w-44 h-44",
                                attrs: {
                                  src: require("../assets/images/profile-vj.png"),
                                  alt: "Foto Profile",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "md:w-full lg:w-full xl:w-5/6",
                              attrs: { id: "options" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { id: "name-student" } },
                                [
                                  _c("BaseText", {
                                    attrs: {
                                      text: `${_vm.student.name}`,
                                      "type-text": "custom",
                                      weight: "medium",
                                      mode: "capitalize",
                                      color: "quantum",
                                      size: "xl",
                                    },
                                  }),
                                  _vm.student.social_name
                                    ? _c("BaseText", {
                                        attrs: {
                                          text: ` - ${_vm.student.social_name}`,
                                          "type-text": "custom",
                                          weight: "medium",
                                          mode: "capitalize",
                                          color: "quantum",
                                          size: "xl",
                                        },
                                      })
                                    : _vm._e(),
                                  _c("BaseText", {
                                    attrs: {
                                      text: `, ${String(_vm.student.age)}`,
                                      "type-text": "custom",
                                      weight: "medium",
                                      color: "quantum",
                                      size: "xl",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "flex items-center mb-3 col-span-5",
                                  attrs: { id: "button-status" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "mt-4",
                                      attrs: { id: "label-status" },
                                    },
                                    [
                                      _c("BaseText", {
                                        staticClass: "mr-4",
                                        attrs: {
                                          text: "status cadastral",
                                          "type-text": "custom",
                                          weight: "semibold",
                                          mode: "uppercase",
                                          color: "ashes-dark",
                                          size: "xs",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "flex flex-wrap",
                                      attrs: { id: "buttons" },
                                    },
                                    [
                                      _c("AppButtonStatus", {
                                        staticClass: "mr-4 mt-4",
                                        attrs: {
                                          text: "Em aberto",
                                          "type-button": "primary",
                                          active: _vm.isActive("open"),
                                          disabled:
                                            _vm.isActive("open") ||
                                            !_vm.season_date_manual_execution_end,
                                          status: "open",
                                          payload: {
                                            mutation:
                                              "toggleModalInscriptionStatusOpenConfirmation",
                                          },
                                          dispatch:
                                            "ModalInscription/toggleModalInscriptionStatus",
                                        },
                                      }),
                                      _c("AppButtonStatus", {
                                        staticClass: "mr-4 mt-4",
                                        attrs: {
                                          text: "Ativo",
                                          icon: "done",
                                          "type-button": "success",
                                          active: _vm.isActive("accepted"),
                                          disabled:
                                            _vm.isActive("accepted") ||
                                            !_vm.season_date_manual_execution_end,
                                          status: "accepted",
                                          payload: {
                                            mutation:
                                              "toggleModalInscriptionStatusAcceptedConfirmation",
                                          },
                                          dispatch:
                                            "ModalInscription/toggleModalInscriptionStatus",
                                        },
                                      }),
                                      _c("AppButtonStatus", {
                                        staticClass: "mr-4 mt-4",
                                        attrs: {
                                          text: "Desligado",
                                          icon: "close",
                                          "type-button": "danger",
                                          active: _vm.isActive("not_accepted"),
                                          disabled:
                                            _vm.isActive("not_accepted") ||
                                            !_vm.season_date_manual_execution_end,
                                          status: "not_accepted",
                                          payload: {
                                            mutation:
                                              "toggleModalInscriptionStatusNotAcceptedConfirmation",
                                          },
                                          dispatch:
                                            "ModalInscription/toggleModalInscriptionStatus",
                                        },
                                      }),
                                      _c("AppButtonStatus", {
                                        staticClass: "mr-4 mt-4",
                                        attrs: {
                                          text: "Concludente",
                                          icon: "close",
                                          "type-button": "primary",
                                          active: _vm.isActive("graduate"),
                                          disabled:
                                            _vm.isActive("graduate") ||
                                            !_vm.season_date_manual_execution_end,
                                          status: "graduate",
                                          payload: {
                                            mutation:
                                              "toggleModalInscriptionStatusGraduateConfirmation",
                                          },
                                          dispatch:
                                            "ModalInscription/toggleModalInscriptionStatus",
                                        },
                                      }),
                                      _c("AppButtonStatus", {
                                        staticClass: "mr-4 mt-4",
                                        attrs: {
                                          text: "Arquivado",
                                          icon: "close",
                                          "type-button": "danger",
                                          active: _vm.isActive("archived"),
                                          disabled:
                                            _vm.isActive("archived") ||
                                            !_vm.season_date_manual_execution_end,
                                          status: "archived",
                                          payload: {
                                            mutation:
                                              "toggleModalInscriptionStatusArchivedConfirmation",
                                          },
                                          dispatch:
                                            "ModalInscription/toggleModalInscriptionStatus",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "mb-5 flex justify-between" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "flex flex-wrap" },
                                    [
                                      !_vm.showFormEditInscription &&
                                      _vm.season_date_manual_execution_end
                                        ? _c("AppRadioButton", {
                                            staticClass:
                                              "mr-10 relative -inset-y-1.5 mt-4",
                                            attrs: {
                                              values: [
                                                {
                                                  name: "contacted",
                                                  value: true,
                                                  description: "sim",
                                                  checked:
                                                    _vm.inscription.contacted,
                                                },
                                                {
                                                  name: "contacted",
                                                  value: false,
                                                  description: "não",
                                                  checked:
                                                    !_vm.inscription.contacted,
                                                },
                                              ],
                                              "label-text":
                                                "feito contato por telefone?",
                                              "show-label": "",
                                              dispatch:
                                                "Inscription/setContacted",
                                            },
                                          })
                                        : _vm._e(),
                                      !_vm.showFormEditInscription &&
                                      !_vm.season_date_manual_execution_end
                                        ? _c("AppTextField", {
                                            staticClass:
                                              "mr-10 relative -inset-y-1.5 mt-5",
                                            attrs: {
                                              "show-label": "",
                                              "label-text":
                                                "feito contato por telefone?",
                                              text: _vm.inscription.contacted
                                                ? "Sim"
                                                : "Não",
                                              "type-text": "topic-details",
                                            },
                                          })
                                        : _vm._e(),
                                      _c(
                                        "div",
                                        [
                                          !_vm.showFormEditInscription &&
                                          _vm.season_date_manual_execution_end
                                            ? _c("BaseInput", {
                                                staticClass:
                                                  "mr-8 mt-4 w-64 bg-transparent",
                                                attrs: {
                                                  active:
                                                    _vm.season_date_manual_execution_end,
                                                  "input-name":
                                                    "attendance-date",
                                                  "input-type": "date",
                                                  "show-icon": "",
                                                  "icon-name": "today",
                                                  "show-label": "",
                                                  value:
                                                    _vm.inscription
                                                      .date_face_to_face_service,
                                                  "label-text":
                                                    "atendimento presencial",
                                                  dispatch:
                                                    "Inscription/setDateFaceToFaceService",
                                                },
                                              })
                                            : _vm._e(),
                                          !_vm.showFormEditInscription &&
                                          !_vm.season_date_manual_execution_end
                                            ? _c("AppTextField", {
                                                staticClass:
                                                  "mr-10 relative -inset-y-1.5 mt-5",
                                                attrs: {
                                                  "show-label": "",
                                                  "label-text":
                                                    "atendimento presencial",
                                                  text: _vm.formattedDate(
                                                    _vm.inscription
                                                      .date_face_to_face_service
                                                  ),
                                                  "type-text": "topic-details",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "mr-10 mt-4 flex self-end show",
                                        },
                                        [
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href: "/docs/ficha_matricula.pdf",
                                                target: "_blank",
                                              },
                                            },
                                            [
                                              !_vm.showFormEditInscription
                                                ? _c("AppButtonColorful", {
                                                    attrs: {
                                                      text: "Imprimir declaração",
                                                      "type-button": "primary",
                                                      "show-icon": "",
                                                      "icon-name":
                                                        "description",
                                                      "icon-type": "outlined",
                                                    },
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm.season_date_manual_execution_end
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "flex flex-row-reverse self-end",
                                        },
                                        [
                                          _c("AppButtonIcon", {
                                            attrs: {
                                              "icon-name": "edit",
                                              "icon-type": "material",
                                              "icon-size": "24",
                                              "for-close": false,
                                              payload: _vm.student,
                                              dispatch:
                                                "ModalInscription/editFormStudent",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "container1" }, [
                        _c(
                          "div",
                          { staticClass: "container2" },
                          [
                            _vm.showFormEditInscription
                              ? _c("FormStudent")
                              : _c("Student"),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c(
                        "form",
                        { attrs: { enctype: "multipart/form-data" } },
                        [
                          !_vm.showFormEditInscription
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "grid grid-cols-5 justify-between p-4 items-end gap-4",
                                  attrs: { id: "filesUpload" },
                                },
                                [
                                  _c(
                                    "div",
                                    [
                                      _c("DeleteDocumentConfirmation", {
                                        attrs: {
                                          open: _vm.modalDeleteDocumentOpen,
                                        },
                                      }),
                                      _c(
                                        "div",
                                        [
                                          _c("AppButtonUploadFile2", {
                                            staticClass: "mr-2",
                                            attrs: {
                                              disabled:
                                                !_vm.season_date_manual_execution_end,
                                              id: "file-rg",
                                              id_file: `${_vm.idFileStudentRG}`,
                                              name: "rg",
                                              text: "+  RG",
                                              dispatch:
                                                "ModalInscription/setDocumentInscriptionRG",
                                              "type-document": "rg",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "buttons-insc" },
                                        [
                                          _vm.season_date_manual_execution_end
                                            ? _c("AppButtonIcon", {
                                                attrs: {
                                                  iconName: "delete",
                                                  payload: _vm.student,
                                                  dispatch:
                                                    "ModalInscription/deleteStudentDocumentRG",
                                                },
                                              })
                                            : _vm._e(),
                                          _c("AppButtonIcon", {
                                            attrs: {
                                              iconName: "download",
                                              payload: _vm.student,
                                              dispatch:
                                                "ModalInscription/showStudentDocumentRG",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("AppButtonUploadFile2", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          disabled:
                                            !_vm.season_date_manual_execution_end,
                                          id: "file-cpf",
                                          id_file: `${_vm.idFileStudentCPF}`,
                                          name: "cpf",
                                          text: "+  CPF",
                                          dispatch:
                                            "ModalInscription/setDocumentInscriptionCPF",
                                          "type-document": "cpf",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "buttons-insc" },
                                        [
                                          _vm.season_date_manual_execution_end
                                            ? _c("AppButtonIcon", {
                                                attrs: {
                                                  iconName: "delete",
                                                  payload: _vm.student,
                                                  dispatch:
                                                    "ModalInscription/deleteStudentDocumentCPF",
                                                },
                                              })
                                            : _vm._e(),
                                          _c("AppButtonIcon", {
                                            attrs: {
                                              iconName: "download",
                                              payload: _vm.student,
                                              dispatch:
                                                "ModalInscription/showStudentDocumentCPF",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("AppButtonUploadFile2", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          disabled:
                                            !_vm.season_date_manual_execution_end,
                                          id: "file-ce",
                                          id_file: `${_vm.idFileStudentCE}`,
                                          name: "ce",
                                          text: "+ Endereço",
                                          dispatch:
                                            "ModalInscription/setDocumentInscriptionCE",
                                          "type-document": "ce",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "buttons-insc" },
                                        [
                                          _vm.season_date_manual_execution_end
                                            ? _c("AppButtonIcon", {
                                                attrs: {
                                                  iconName: "delete",
                                                  payload: _vm.student,
                                                  dispatch:
                                                    "ModalInscription/deleteStudentDocumentCE",
                                                },
                                              })
                                            : _vm._e(),
                                          _c("AppButtonIcon", {
                                            attrs: {
                                              iconName: "download",
                                              payload: _vm.student,
                                              dispatch:
                                                "ModalInscription/showStudentDocumentCE",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("AppButtonUploadFile2", {
                                        staticClass: "mr-2 w-full",
                                        attrs: {
                                          disabled:
                                            !_vm.season_date_manual_execution_end,
                                          id: "file-declaration",
                                          id_file: `${_vm.idFileStudentDeclaracao}`,
                                          name: "declaração",
                                          text: "+  Comprov. Matrícula",
                                          dispatch:
                                            "ModalInscription/setDocumentInscriptionDECLARACAO",
                                          "type-document": "declaration",
                                          color: "burnedYellow",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "buttons-insc" },
                                        [
                                          _vm.season_date_manual_execution_end
                                            ? _c("AppButtonIcon", {
                                                attrs: {
                                                  iconName: "delete",
                                                  payload: _vm.student,
                                                  dispatch:
                                                    "ModalInscription/deleteStudentDocumentDECLARACAO",
                                                },
                                              })
                                            : _vm._e(),
                                          _c("AppButtonIcon", {
                                            attrs: {
                                              iconName: "download",
                                              payload: _vm.student,
                                              dispatch:
                                                "ModalInscription/showStudentDocumentDECLARACAO",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("AppButtonUploadFile2", {
                                        staticClass: "mr-2 w-full",
                                        attrs: {
                                          disabled:
                                            !_vm.season_date_manual_execution_end,
                                          id: "file-school-declaration",
                                          id_file: `${_vm.idFileStudentSchoolDeclaration}`,
                                          name: "schoolDeclaration",
                                          text: "+  Declaração Escolar",
                                          dispatch:
                                            "ModalInscription/setDocumentInscriptionSchoolDeclaration",
                                          "type-document": "SchoolDeclaration",
                                          color: "burnedYellow",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "buttons-insc" },
                                        [
                                          _vm.season_date_manual_execution_end
                                            ? _c("AppButtonIcon", {
                                                attrs: {
                                                  iconName: "delete",
                                                  payload: _vm.student,
                                                  dispatch:
                                                    "ModalInscription/deleteStudentDocumentSchoolDeclaration",
                                                },
                                              })
                                            : _vm._e(),
                                          _c("AppButtonIcon", {
                                            attrs: {
                                              iconName: "download",
                                              payload: _vm.student,
                                              dispatch:
                                                "ModalInscription/showStudentDocumentSchoolDeclaration",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("AppButtonUploadFile2", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          disabled:
                                            !_vm.season_date_manual_execution_end,
                                          id: "file-bankaccount",
                                          id_file: `${_vm.idFileStudentBankAccount}`,
                                          name: "bankaccount",
                                          text: "+  Conta Bancária",
                                          dispatch:
                                            "ModalInscription/setDocumentInscriptionBankAccount",
                                          "type-document": "BankAccount",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "buttons-insc" },
                                        [
                                          _vm.season_date_manual_execution_end
                                            ? _c("AppButtonIcon", {
                                                attrs: {
                                                  iconName: "delete",
                                                  payload: _vm.student,
                                                  dispatch:
                                                    "ModalInscription/deleteStudentDocumentBankAccount",
                                                },
                                              })
                                            : _vm._e(),
                                          _c("AppButtonIcon", {
                                            attrs: {
                                              iconName: "download",
                                              payload: _vm.student,
                                              dispatch:
                                                "ModalInscription/showStudentDocumentBankAccount",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    [
                                      _c("AppButtonUploadFile2", {
                                        staticClass: "mr-2",
                                        attrs: {
                                          disabled:
                                            !_vm.season_date_manual_execution_end,
                                          id: "file-resume",
                                          id_file: `${_vm.idFileStudentResume}`,
                                          name: "bankaccount",
                                          text: "+  Currículo",
                                          dispatch:
                                            "ModalInscription/setDocumentInscriptionResume",
                                          "type-document": "Resume",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "buttons-insc" },
                                        [
                                          _vm.season_date_manual_execution_end
                                            ? _c("AppButtonIcon", {
                                                attrs: {
                                                  iconName: "delete",
                                                  payload: _vm.student,
                                                  dispatch:
                                                    "ModalInscription/deleteStudentDocumentResume",
                                                },
                                              })
                                            : _vm._e(),
                                          _c("AppButtonIcon", {
                                            attrs: {
                                              iconName: "download",
                                              payload: _vm.student,
                                              dispatch:
                                                "ModalInscription/showStudentDocumentResume",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "flex justify-between" },
                        [
                          !_vm.showFormEditInscription &&
                          _vm.season_date_manual_execution_end
                            ? _c("BaseInput", {
                                staticClass: "w-3/6",
                                attrs: {
                                  active: _vm.season_date_manual_execution_end,
                                  id: "justify",
                                  "input-name": "nome",
                                  "input-type": "text",
                                  value: _vm.inscription.justification,
                                  "label-text":
                                    "aluno(a) aprovado(a) sem documentos anexados? justifique.",
                                  "show-label": "",
                                  "input-placeholder": "justificativa",
                                  dispatch: "ModalInscription/setJustification",
                                },
                              })
                            : _vm._e(),
                          !_vm.showFormEditInscription &&
                          !_vm.season_date_manual_execution_end
                            ? _c("AppTextField", {
                                staticClass: "mr-10 relative -inset-y-1.5 mt-5",
                                attrs: {
                                  "show-label": "",
                                  "label-text":
                                    "justificativa para aluno(a) aprovado(a) sem documentos anexados",
                                  text: _vm.inscription.justification
                                    ? _vm.inscription.justification
                                    : "Nada informado",
                                  "type-text": "topic-details",
                                },
                              })
                            : _c("div", { staticClass: "w-3/6" }),
                          !_vm.showFormEditInscription
                            ? _c(
                                "div",
                                { staticClass: "flex justify-end items-end" },
                                [
                                  _c("AppButtonEmpty", {
                                    staticClass: "mr-5 h-12",
                                    attrs: {
                                      text: "Descartar",
                                      "type-button": "danger",
                                      dispatch: "ModalInscription/closeModal",
                                    },
                                  }),
                                  _c("AppButtonEmpty", {
                                    staticClass: "h-12",
                                    attrs: {
                                      text: "Salvar",
                                      "type-button": "success",
                                      dispatch:
                                        "ModalInscription/saveInscription",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _c(
                                "div",
                                { staticClass: "flex justify-end items-end" },
                                [
                                  _c("AppButtonEmpty", {
                                    staticClass: "mr-5 h-12",
                                    attrs: {
                                      text: "Descartar",
                                      "type-button": "danger",
                                      dispatch: "ModalInscription/closeModal",
                                    },
                                  }),
                                  _c("AppButtonEmpty", {
                                    staticClass: "h-12",
                                    attrs: {
                                      text: "Salvar",
                                      "type-button": "success",
                                      dispatch:
                                        "ModalInscription/saveInscriptionFrm",
                                    },
                                  }),
                                ],
                                1
                              ),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1895987876
            ),
          })
        : _vm._e(),
      _c("UnderAnalysisConfirmation", {
        attrs: { open: _vm.modalUnderAnalysisConfirmationOpen },
      }),
      _c("AcceptedConfirmation", {
        attrs: { open: _vm.modalAcceptedConfirmationOpen },
      }),
      _c("NotAcceptedConfirmation", {
        attrs: { open: _vm.modalNotAcceptedConfirmationOpen },
      }),
      _c("OpenConfirmation", {
        attrs: { open: _vm.modalOpenConfirmationOpen },
      }),
      _c("GraduateConfirmation", {
        attrs: { open: _vm.modalGraduateConfirmationOpen },
      }),
      _c("ArchivedConfirmation", {
        attrs: { open: _vm.modalArchivedConfirmationOpen },
      }),
      _c("Alert", { attrs: { open: _vm.modalInscriptionAlertStatusOpen } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }