var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-4" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start col-span-3" },
                      [
                        _c("Label", {
                          staticClass: "text-ashes-dark",
                          attrs: {
                            text: _vm.getTitleName,
                            icon: null,
                            "icon-type": null,
                            type: "text-2xl",
                            weight: "bold",
                            normal: "",
                            mode: "normal-case",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "PsychosocialAccompaniments/closeModalAddAttendance",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-start col-span-2" },
                      [
                        _c("BaseText", {
                          staticClass: "mr-1",
                          attrs: {
                            text: _vm.getUserName,
                            typeText: "custom",
                            weight: "semibold",
                            color: "herbal",
                            size: "sm",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 gap-4" }, [
                    _vm.hasRealizedFirstPsychosocialAccompaniment
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Tipo de Atendimento",
                                "label-text": "Tipo",
                                "multiple-option": false,
                                value: _vm.accompaniments_type,
                                dispatch: _vm.setPsychosocialAccompanimentsType,
                                options:
                                  _vm.acommpaniment_options_if_has_realized_first_psychosocial_accompaniment,
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Tipo de Atendimento",
                                "label-text": "Tipo",
                                "multiple-option": false,
                                value: _vm.accompaniments_type,
                                options: _vm.getPsychosocialAccompanimentsTypes,
                                required: "",
                                dispatch: _vm.setPsychosocialAccompanimentsType,
                              },
                            }),
                          ],
                          1
                        ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "birthdate",
                            "input-type": "date",
                            value: _vm.date_accompaniment,
                            "show-label": "",
                            "label-text":
                              _vm.accompaniment_type === 3
                                ? "Data da Justificativa"
                                : "Data do Encontro",
                            required: "",
                            dispatch:
                              "PsychosocialAccompaniments/setDateAccompaniment",
                            readonly: _vm.dateIsOnlyRead,
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.verifyDate()
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "text-carmesim font-medium text-sm decoration-yellow-600 col-span-2",
                          },
                          [
                            _vm._v(
                              " A data informada é maior que a data atual, portanto o atendimento será classificado com o status de Agendado. "
                            ),
                          ]
                        )
                      : _vm._e(),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "PsychosocialAccompaniments/closeModalAddAttendance",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.verifyDate()
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Agendar",
                                typeButton: "success",
                                dispatch: "PsychosocialAccompaniments/save",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "Avançar",
                                typeButton: "success",
                                dispatch:
                                  "PsychosocialAccompaniments/chooseModalToOpen",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3367204138
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }