var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { attrs: { bg_color: _vm.getbgType } }, [
    _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.getMatrixProblemName(),
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.getSituation(),
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.matrix_situation.situation_risk,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.getImpact(),
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.matrix_situation.impact_level,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4 flex" },
        [
          _vm.permitShow
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "visibility",
                  dispatch: "MatrixSituation/openViewModal",
                  payload: _vm.matrix_situation,
                  title: "Visualizar mais informações",
                },
              })
            : _vm._e(),
          _vm.permitUpdate
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "edit",
                  payload: _vm.matrix_situation,
                  dispatch: "MatrixSituation/openEditModalMatrixSituations",
                  title: "Editar informações da situação",
                },
              })
            : _vm._e(),
          _vm.permitDelete
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "delete",
                  payload: _vm.matrix_situation,
                  dispatch: "MatrixSituation/toggleModalDeleteMatrixSituation",
                  title: "Excluir situação",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }