var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.classText },
    [
      _c("BaseText", {
        attrs: {
          text: _vm.text,
          color: _vm.color,
          weight: _vm.weight,
          mode: _vm.mode,
        },
      }),
      _c("hr"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }