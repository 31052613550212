var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.opportunity.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar Seleção",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Criar Seleção",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch: "Opportunities/closeModalAddOpportunity",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações Gerais",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "opportunity_date",
                            "input-name": "date",
                            "input-type": "date",
                            "show-label": "",
                            required: "",
                            "label-text": "Data da Seleção",
                            value: _vm.opportunity.opportunity_date,
                            dispatch: "Opportunities/setOpportunityDate",
                            "error-message": _vm.getErrorMessage(
                              "data da oportunidade"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "Nome",
                            "input-name": "name",
                            "input-type": "text",
                            "show-label": "",
                            required: "",
                            "label-text": "Nome",
                            value: _vm.opportunity.name,
                            dispatch: "Opportunities/setName",
                            "error-message": _vm.getErrorMessage("nome"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Município",
                            "multiple-option": false,
                            value: _vm.city,
                            options: _vm.cities,
                            "label-text": "Município",
                            dispatch: _vm.setCity,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Empresa",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder":
                              "Digite o nome da empresa para a busca",
                            "multiple-option": false,
                            value: _vm.company,
                            options: _vm.companies,
                            dispatch: _vm.setCompany,
                            "load-dispatch": "Opportunities/loadCompanies",
                            "error-message": _vm.getErrorMessage("empresa"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Parceiro",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder":
                              "Digite o nome do parceiro para a busca",
                            "multiple-option": false,
                            value: _vm.partner,
                            options: _vm.partners,
                            dispatch: _vm.setPartner,
                            "load-dispatch": "Opportunities/loadPartners",
                            "error-message": _vm.getErrorMessage("parceiro"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Segmento",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder":
                              "Digite o nome do segmento para a busca",
                            "multiple-option": false,
                            value: _vm.segment,
                            options: _vm.segments,
                            dispatch: _vm.setSegment,
                            "load-dispatch": "Opportunities/loadSegments",
                            "error-message": _vm.getErrorMessage("segmento"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Ocupação",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder":
                              "Digite o nome da ocupação para a busca",
                            "multiple-option": false,
                            value: _vm.occupation,
                            options: _vm.occupations,
                            dispatch: _vm.setOccupation,
                            "load-dispatch": "Opportunities/loadOccupations",
                            "error-message": _vm.getErrorMessage("ocupação"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Tipo de Contrato",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder": "Informe o tipo de contrato",
                            "multiple-option": false,
                            value: _vm.contracting_type,
                            options: _vm.contracting_types,
                            dispatch: _vm.setContractingType,
                            "error-message": _vm.getErrorMessage(
                              "tipo da contratação"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "vacancy_quantity",
                            "input-name": "name",
                            "input-type": "number",
                            "show-label": "",
                            inputMin: "1",
                            inputMax: "10000",
                            "label-text": "Quantidade de Vagas",
                            value: _vm.opportunity.vacancy_quantity,
                            dispatch: "Opportunities/setVacancyQuantity",
                            "error-message": _vm.getErrorMessage(
                              "quantidade de vagas"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch: "Opportunities/closeModalAddOpportunity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.opportunity.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Atualizar",
                                typeButton: "success",
                                dispatch: "Opportunities/update",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "Criar",
                                typeButton: "success",
                                dispatch: "Opportunities/save",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          526962911
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }