<template>
  <div class="flex ml-20">
    <div class="w-168">
      <div class="flex flex-col justify-center h-screen">
        <div>
          <BaseText type-text="custom" weight="semibold" size="3xl" color="herbal">
            <p>Olá administrador(a)!</p>
            <p>Bem-vindo(a) ao sistema</p>
          </BaseText>
          <BaseText type-text="custom" text="Virando o Jogo!" weight="extrabold" size="3xl" color="herbal" />
          <div class="mt-5">
            <BaseText type-text="custom" text="Vamos começar?" weight="normal" size="lg" color="herbal" />
          </div>
        </div>
      </div>
    </div>
    <div class="background w-screen"></div>
  </div>
</template>

<script>
import BaseText from "../components/BaseText.vue";
import { mapActions, mapState } from "vuex";

export default {
  components: {
    BaseText,
  },
  props: {},
  methods: {
    ...mapActions({
      getPermits: "AccessControl/getPermits",
      getProfPermits: "AccessControl/getProfilePermits",
    }),
  },

  computed: {
    ...mapState({
      perm: (state) => state.AccessControl.perm,
    }),
  },
  created() {
    this.$store.dispatch("AccessControl/getPermits");
  },
};
</script>

<style scoped>
.background {
  background-image: url("../assets/images/background-home-admin.png");
  background-repeat: no-repeat;
  background-size: cover;
}

.height {
  min-height: 120vh;
}
</style>
