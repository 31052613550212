var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permitList
    ? _c(
        "div",
        { staticClass: "grid auto-grid-auto p-10" },
        [
          _c(
            "div",
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.city.name + " | Áreas",
                  typeText: "custom",
                  weight: "semibold",
                  italic: false,
                  size: "3xl",
                  color: "ashes-hover",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "grid grid-cols-2 justify-items-stretch my-8" },
            [
              _c(
                "div",
                [
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      inputName: "",
                      inputType: "text",
                      iconName: "search",
                      showIcon: "",
                      dispatch: "Areas/filterList",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "justify-self-end" },
                [
                  _vm.permit
                    ? _c("AppButtonColorful", {
                        attrs: {
                          text: "Adicionar área",
                          typeButton: "primary",
                          showIcon: "",
                          iconName: "add",
                          iconType: "outlined",
                          dispatch: "Area/createModal",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c("div", {}, [
            _c("div", { staticClass: "grid grid-cols-4" }, [
              _c(
                "div",
                { staticClass: "col-span-1 ml-7" },
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Área de Abrangência",
                      typeText: "custom",
                      weight: "bold",
                      italic: false,
                      mode: "uppercase",
                      size: "xs",
                      color: "ashes-dark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "justify-items-start ml-4" },
                [
                  _c("BaseText", {
                    attrs: {
                      text: "N° de Bairros",
                      typeText: "custom",
                      weight: "bold",
                      italic: false,
                      mode: "uppercase",
                      size: "xs",
                      color: "ashes-dark",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "justify-items-start ml-1" },
                [
                  _c("BaseText", {
                    attrs: {
                      text: "N° De Equipamentos",
                      typeText: "custom",
                      weight: "bold",
                      italic: false,
                      mode: "uppercase",
                      size: "xs",
                      color: "ashes-dark",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", [_c("AreaCards", { attrs: { data: _vm.areas } })], 1),
          ]),
          _c("ModalArea", { attrs: { open: _vm.openModal } }),
          _c("DeleteAreaConfirmation", {
            attrs: { open: _vm.openModalDelete },
          }),
        ],
        1
      )
    : _c("ProhibitedAccessPage", {
        attrs: {
          classText:
            "grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }