import { getAxios, baseURL } from "./Api";

export async function getNeighborhoodsByCityId(city_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`cidades/${city_id}/bairros`);
}

export async function getNeighborhoodsByCityIdWithoutArea(city_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`cidades/${city_id}/bairros/sem_areas`);
}

export async function getNeighborhoodsByAreaId(area_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`areas/${area_id}/bairros`);
}
