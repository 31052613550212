var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", [
    _c(
      "div",
      { staticClass: "grid grid-cols-custom items-center container1" },
      [
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.verifyStatus(),
                mode: "normal-case",
                color: "quantum",
                typeText: "custom",
                weight: "bold",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.inscription?.student?.name,
                mode: "normal-case",
                color: "quantum",
                typeText: "custom",
                weight: "bold",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.inscription?.student?.season?.name,
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm._f("VMask")(
                  _vm.inscription?.student?.cpf,
                  "###.###.###-##"
                ),
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.inscription?.student?.city?.name,
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.inscription?.student?.neighborhood?.area?.name,
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.getEquipmentName(),
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.formatDate(
                  _vm.inscription?.last_psychosocial_accompaniment
                    ?.justification_for_non_acceptance_date
                ),
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 flex justify-center" },
          [
            _c(
              "router-link",
              { attrs: { to: "/psicossocial/pagina_principal" } },
              [
                _c(
                  "a",
                  {
                    staticClass: "group",
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.setInscription()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "transition duration-200 ease-in-out transform hover:scale-105 hover:bg-red-600 bg-red-500 rounded-full p-2 shadow-md flex items-center justify-center",
                        class: _vm.classButtonAssignment,
                      },
                      [
                        _c("AppMaterialIcon", {
                          staticClass: "text-white",
                          attrs: {
                            iconName: "badge",
                            iconType: "round",
                            iconSize: "24",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }