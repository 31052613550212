import { getCourse, createCourse, updateCourse } from "../../service/CourseApi";
import Vue from "vue";
import { destroyCourse } from "@/service/CourseApi";

const state = {
  course: {
    id: null,
    name: "",
    description: "",
    city: {
      name: "",
    },
    city_id: null,
    discipline: {
      id: "",
      name: "",
    },
    discipline_id: 3,
  },
  emptyCourse: {
    id: null,
    name: "",
    description: "",
    city: {
      name: "",
    },
    city_id: null,
    course_type_id: null,
    discipline: {
      name: "",
    },
    discipline_id: null,
  },
  errorMessages: [],
  toggleModal: false,
  modalToggleDeleteCourse: false,
  courseId: null,
};

const mutations = {
  setNameMutation: (state, name) => {
    state.course.name = name;
  },
  setDescriptionMutation: (state, description) => {
    state.course.description = description;
  },
  setCityMutation: (state, city) => {
    state.course.city_id = city;
  },
  setDisciplineMutation: (state, discipline) => {
    state.course.discipline_id = discipline.id;
    state.course.discipline = discipline;
  },
  setCourseTypeMutation: (state, course_type) => {
    state.curse_type_id = course_type;
  },
  setCourse: (state, course) => {
    state.course = course;
  },
  toggleModal: (state) => {
    if (state.toggleModal) state.course = state.emptyCourse;
    state.toggleModal = !state.toggleModal;
  },
  toggleModalDeleteCourse: (state) => {
    state.modalToggleDeleteCourse = !state.modalToggleDeleteCourse;
  },
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
  // --> MUTATIONS - DELETION <--
  courseForDeletion: (state, payload) => {
    if (payload) {
      state.courseId = payload.id;
    }
  },
};

const actions = {
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setDescription: ({ commit }, payload) => {
    commit("setDescriptionMutation", payload.value);
  },
  setCity: ({ commit, rootState }) => {
    const city_id = rootState.City.city.id;
    commit("setCityMutation", city_id);
  },
  setDiscipline: ({ commit }, payload) => {
    commit("setDisciplineMutation", payload);
  },
  setCourseType: ({ commit }, payload) => {
    commit("setCourseTypeMutation", payload.id);
  },
  toggleModal: ({ commit }) => {
    commit("toggleModal");
  },
  closeModal: ({ commit }) => {
    commit("setErrorMessagesMutation", []);
    commit("toggleModal");
  },
  openEditModal: ({ commit }, payload) => {
    commit("setCourse", payload);
    commit("toggleModal");
  },
  toggleModalDeleteCourse: ({ commit }, payload) => {
    commit("toggleModalDeleteCourse");
    if (state.modalToggleDeleteCourse && payload) {
      commit("courseForDeletion", payload);
    } else {
      commit("courseForDeletion", null);
    }
  },
  loadCourse: ({ commit }, id) => {
    getCourse(id)
      .then((response) => {
        commit("setCourse", response.data.course);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  createCourse: ({ commit, state, dispatch }) => {
    dispatch("setCity");
    createCourse(state.course)
      .then((response) => {
        commit("toggleModal");
        Vue.$toast.success(response.data.message);
        let course = [];
        course = response.data;
        dispatch("Courses/addCourse", course, { root: true });
        commit("setNameMutation", "");
        commit("setDescriptionMutation", "");
        commit("setDisciplineMutation", "");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.message);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  updateCourse: ({ commit, state, dispatch }) => {
    dispatch("setCity");
    updateCourse(state.course.id, state.course)
      .then((response) => {
        commit("toggleModal");
        Vue.$toast.success(response.data.message);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.message);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  delete: ({ commit, dispatch }) => {
    dispatch("setCity");
    destroyCourse(state.courseId)
      .then(() => {
        Vue.$toast.success("Curso deletado com sucesso!");
        commit("toggleModalDeleteCourse");
        dispatch("Courses/loadCourses", null, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      switch (error.type) {
        case "blank":
          return error.message;

        case "wrong_length":
          return error.message;

        case "invalid_cpf":
          return error.message;

        case "invalid_cep":
          return error.message;

        case "invalid":
          return error.message;

        case "taken":
          return error.message;

        default:
          return null;
      }
    } else {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
