var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    { staticClass: "font-system", class: _vm.classes },
    [
      _vm.icon && _vm.iconType
        ? _c("font-awesome-icon", {
            attrs: { icon: [_vm.prefixIconType, _vm.icon] },
          })
        : _vm._e(),
      _vm._v(" " + _vm._s(_vm.text) + " "),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }