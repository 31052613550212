<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            text="Selecionar campos para Exportação"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="SelectFieldsToExport/closeModalSelectFieldsToExport" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Campos"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-3 gap-4 mt-2">
        <div
          v-for="(field, index) in fields"
          :key="index"
          class="app-checkbox flex items-center border-b pb-2"
        >
          <input
            :id="checkboxId(field, index)"
            type="checkbox"
            name="fields"
            :value="field"
            @click="onCheck(field, index, $event)"
            :checked="field.checked"
            class="mr-2"
          />
          <BaseText
            class="flex"
            :text="index"
            typeText="custom"
            weight="semibold"
            :italic="false"
            mode="capitalize"
            size="lg"
            :target="checkboxId(field, index)"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="SelectFieldsToExport/closeModalSelectFieldsToExport"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            text="Avançar"
            typeButton="success"
            dispatch="SelectFieldsToExport/move_on"
            :payload="openModalDispatch"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import BaseText from "@/components/BaseText.vue";

export default {
  components: {
    BaseText,
    BaseModal,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
    fields: {
      type: Object,
      default: null,
    },
    openModalDispatch: {
      type: String,
    },
  },
  methods: {
    checkboxId(name, index) {
      return `${name}-${index}`;
    },
    onCheck(field, index, event) {
      let object = {
        [index]: field,
      };
      let dispatch = event.target.checked
        ? "SelectFieldsToExport/addFields"
        : "SelectFieldsToExport/removeFields";
      this.$store.dispatch(dispatch, object);
    },
  },
};
</script>
