var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "font-system mb-16" },
    [
      _c("div", { staticClass: "mx-12 my-6", attrs: { id: "title-page" } }, [
        _c("div", { staticClass: "text-ashes-hover font-medium text-3xl" }, [
          _vm._v(_vm._s(_vm.season.name)),
        ]),
      ]),
      _vm.city
        ? _c(
            "div",
            { staticClass: "mx-12 my-6", attrs: { id: "breadcrumb" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text-ashes font-medium text-sm uppercase flex flex-row flex-wrap",
                },
                [
                  _c("div", { staticClass: "mx-1" }, [
                    _vm._v(_vm._s(_vm.city.name)),
                  ]),
                  _c("div", { staticClass: "mx-1" }, [_vm._v("|")]),
                  _c("div", { staticClass: "mx-1" }, [
                    _vm._v(_vm._s(_vm.type_edition)),
                  ]),
                  _c("div", { staticClass: "mx-1" }, [_vm._v("|")]),
                  _c("div", { staticClass: "mx-1 text-burnedYellow" }, [
                    _vm._v(_vm._s(_vm.season.name)),
                  ]),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass:
            "mx-12 my-8 text-burnedYellow hover:text-burnedYellow-hover grid grid-flow-col auto-cols-max",
        },
        [
          _c(
            "router-link",
            {
              staticClass: "flex items-center",
              attrs: { to: _vm.go_back_url },
            },
            [
              _c("AppMaterialIcon", {
                staticClass: "mr-1",
                attrs: {
                  iconName: "arrow_back",
                  iconType: "outlined",
                  iconSize: "18",
                },
              }),
              _c("BaseText", {
                staticClass: "hover:underline",
                attrs: {
                  text: "Voltar",
                  "type-text": "custom",
                  weight: "semibold",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mx-12 my-6", attrs: { id: "edition" } },
        [_c("AppCardSeason", { attrs: { season: _vm.season } })],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "mx-12 my-6 flex flex-row flex-wrap justify-center items-center",
          attrs: { id: "fases" },
        },
        [
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.setPartial("inscriptions")
                },
              },
            },
            [
              _c(
                "div",
                { class: _vm.classButtonAssignment },
                [
                  _c("AppMaterialIcon", {
                    attrs: {
                      iconName: "assignment",
                      iconType: "outlined",
                      iconSize: "48",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm._l(_vm.phases, function (phase, index) {
            return _c(
              "div",
              { key: index, class: _vm.classButtonPhases(phase.name) },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.setPhase(phase)
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass:
                          "flex flex-col justify-center items-center mx-12 my-4",
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "uppercase font-black text-3xl" },
                          [_vm._v(_vm._s(phase.name))]
                        ),
                        _c("div", { staticClass: "font-light text-sm" }, [
                          _vm._v(
                            " " + _vm._s(_vm.getNamesDisciplines(phase)) + " "
                          ),
                        ]),
                      ]
                    ),
                  ]
                ),
              ]
            )
          }),
        ],
        2
      ),
      _vm.partial === "inscriptions"
        ? _c(
            "div",
            [
              _c(
                "div",
                {
                  staticClass: "mx-12 my-6",
                  attrs: { id: "title-pre-inscriptions" },
                },
                [
                  _c("BaseText", {
                    attrs: {
                      text: "relação de inscritos e participantes",
                      typeText: "title-forms",
                    },
                  }),
                  _c("hr", {
                    staticClass:
                      "border-t-0 border-b-2 border-ashes opacity-50",
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mx-14", attrs: { id: "filters" } },
                [
                  _c("FilterComponent", [
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          staticClass: "mr-5",
                          attrs: {
                            id: "filterArea",
                            selectPlaceholder: "Selecionar",
                            "label-text": "filtrar por área",
                            closeOnSelect: "",
                            "show-label": "",
                            multipleOption: false,
                            options: _vm.user_areas,
                            value: _vm.area,
                            dispatch: _vm.setArea,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Equipamento",
                            "multiple-option": false,
                            value: _vm.equipment,
                            options: _vm.equipments,
                            "label-text": _vm.getLabelEquipmentFilter(),
                            dispatch: _vm.filterListByEquipment,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseLabel", {
                          attrs: { text: "Filtrar por Bairro" },
                        }),
                        _c("BaseInput", {
                          ref: "neighborhood",
                          attrs: {
                            active: "",
                            id: "filter-student-neighborhood",
                            inputName: "",
                            inputType: "text",
                            iconName: "search",
                            showIcon: "",
                            dispatch: "Season/filterListByNeighborhood",
                            value: _vm.neighborhood,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Filtrar por Município",
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Selecione o Município",
                            "multiple-option": false,
                            value: _vm.city_filtered,
                            options: _vm.cities,
                            dispatch: _vm.filterListByCity,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Filtrar por Nome do Aluno(a)",
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder":
                              "Digite o nome do aluno(a) para busca",
                            "multiple-option": false,
                            value: _vm.inscription_filtered,
                            options: _vm.inscriptionsForGeneralMenus,
                            dispatch: _vm.filterListByName,
                            "load-dispatch":
                              "Season/loadInscriptionsForGeneralMenus",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Possui CPF?",
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Possui CPF?",
                            "multiple-option": false,
                            value: _vm.has_cpf,
                            options: _vm.positive_negative_options,
                            dispatch: _vm.filterListByHasCpf,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseLabel", { attrs: { text: "Filtrar por CPF" } }),
                        _c("BaseInput", {
                          ref: "cpf",
                          attrs: {
                            active: "",
                            id: "filter-student-cpf",
                            inputName: "",
                            inputType: "text",
                            iconName: "search",
                            showIcon: "",
                            dispatch: "Season/filterListByCPF",
                            value: _vm.cpf,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Possui RG?",
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Possui RG?",
                            "multiple-option": false,
                            value: _vm.has_rg,
                            options: _vm.positive_negative_options,
                            dispatch: _vm.filterListByHasRg,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Possui Conta Bancária?",
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Possui Conta Bancária?",
                            "multiple-option": false,
                            value: _vm.has_bank_account,
                            options: _vm.positive_negative_options,
                            dispatch: _vm.filterListByHasBankAccount,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Origem da Inscrição",
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Selecione a opção desejada",
                            "multiple-option": false,
                            value: _vm.inscription_origin_filtered,
                            options: _vm.inscription_origin_options,
                            dispatch: _vm.filterListByInscriptionOrigin,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "mt-5 flex items-end flex-wrap col-span-5",
                      },
                      [
                        _c(
                          "div",
                          [
                            _c("AppButtonStatus", {
                              staticClass: "w-52",
                              attrs: {
                                text: "Em aberto",
                                active: _vm.isActive("open"),
                                status: "open",
                                number: String(_vm.amountInscriptionOpen),
                                typeButton: "primary",
                                dispatch: "Season/setFilterStatusInscriptions",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "ml-2" },
                          [
                            _c("AppButtonStatus", {
                              staticClass: "w-52",
                              attrs: {
                                text: "Ativo",
                                active: _vm.isActive("accepted"),
                                status: "accepted",
                                number: String(_vm.amountInscriptionAccepted),
                                typeButton: "success",
                                dispatch: "Season/setFilterStatusInscriptions",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "ml-2" },
                          [
                            _c("AppButtonStatus", {
                              staticClass: "w-52",
                              attrs: {
                                text: "Desligado",
                                active: _vm.isActive("not_accepted"),
                                status: "not_accepted",
                                number: String(
                                  _vm.amountInscriptionNotAccepted
                                ),
                                typeButton: "danger",
                                dispatch: "Season/setFilterStatusInscriptions",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "ml-2" },
                          [
                            _c("AppButtonStatus", {
                              staticClass: "w-52",
                              attrs: {
                                text: "Concludente",
                                active: _vm.isActive("graduate"),
                                status: "graduate",
                                number: String(_vm.amountInscriptionGraduate),
                                typeButton: "primary",
                                dispatch: "Season/setFilterStatusInscriptions",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "ml-2" },
                          [
                            _c("AppButtonStatus", {
                              staticClass: "w-52",
                              attrs: {
                                text: "Arquivado",
                                active: _vm.isActive("archived"),
                                status: "archived",
                                number: String(_vm.amountInscriptionArchived),
                                typeButton: "danger",
                                dispatch: "Season/setFilterStatusInscriptions",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                  _c("div", { staticClass: "mt-5 flex items-end flex-wrap" }, [
                    _c(
                      "div",
                      { staticClass: "mr-5" },
                      [
                        _vm.permit
                          ? _c("AppButtonColorful", {
                              attrs: {
                                id: "btn-consult-inscriptions",
                                showIcon: "",
                                iconName: "search",
                                text: "Consultar dados",
                                dispatch: "Season/loadDataSeason",
                                typeButton: "primary",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "mr-5" }, [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.clearFilter()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "inline-block",
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              src: require("../assets/images/filter_alt_black_24dp.svg"),
                              alt: "",
                            },
                          }),
                          _c("BaseText", {
                            attrs: {
                              text: "Limpar",
                              typeText: "custom",
                              weight: "normal",
                              italic: false,
                              size: "xs",
                              color: "burnedYellow",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.exportToExcel(
                                "inscriptions_spreadsheet"
                              )
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "inline-block",
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              src: require("../assets/images/logout_black_24dp.svg"),
                              alt: "",
                            },
                          }),
                          _c("BaseText", {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              text: "Exportar Planilha de Inscritos",
                              typeText: "custom",
                              italic: false,
                              size: "xs",
                              color: "burnedYellow",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mx-12 my-6", attrs: { id: "table-young" } },
                [
                  _c("BaseTable", {
                    attrs: {
                      header: _vm.mountHeader(),
                      body: _vm.bodyTable,
                      total: _vm.inscriptionTotal,
                      "total-per-page": _vm.totalPerPage,
                      dispatch: "Season/setOffset",
                    },
                  }),
                ],
                1
              ),
              _vm._m(0),
              _c("ModalInscription", {
                attrs: { open: _vm.modalInscriptionOpen },
              }),
            ],
            1
          )
        : _c("div", [_c("Phase")], 1),
      _vm.loading_data_season ? _c("div", [_c("PreLoading")], 1) : _vm._e(),
      _c("ModalSeason", { attrs: { open: _vm.showModal } }),
      _c("EndSeasonConfirmation", {
        attrs: { open: _vm.endSeasonConfirmation },
      }),
      _c("ReactivateSeasonConfirmation", {
        attrs: { open: _vm.reactivateSeasonConfirmation },
      }),
      _c("ModalInscriptionStudent", {
        attrs: { open: _vm.modalInscriptionStudentOpen },
      }),
      _c("ModalInscription", { attrs: { open: _vm.modalInscriptionOpen } }),
      _c("ModalTransferStudentToClass", {
        attrs: { open: _vm.showModalTransferStudent },
      }),
      _c("ModalRemoveStudentFromTeam", {
        attrs: { open: _vm.showModalRemoveStudentFromTeam },
      }),
      _c("RemoveClassConfirmation", {
        attrs: { open: _vm.showModalConfirmationRemoveStudent },
      }),
      _c("ModalExportSpreadsheets", {
        attrs: {
          dataSpreadsheet: _vm.dataSpreadsheet,
          modalToggleExport: _vm.modalToggleExport,
          titleModal: _vm.titleModal,
          dispatchToCloseModal: _vm.dispatchToCloseModal,
          json_data: _vm.json_data,
          sheetTabName: _vm.sheetTabName,
          fileName: _vm.fileName,
          variablePreloading: _vm.variablePreloading,
          json_fields: _vm.json_fields,
          dispatchOpenModal: _vm.dispatchOpenModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "mx-12 my-6" }, [_c("br"), _c("br")])
  },
]
render._withStripped = true

export { render, staticRenderFns }