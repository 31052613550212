import Vue from "vue";
import router from "../../router/index";
import { getStates } from "../../service/StateApi";
import { getAdress } from "../../service/CepApi";
import {
  destroyEquipment,
  createEquipment,
  updateEquipment,
  showEquipment,
} from "../../service/EquipmentApi";

const equipmentEmpty = {
  id: null,
  name: "",
  cep: "",
  street: "",
  street_number: "",
  community: "",
  complement: "",
  latitude: "",
  longitude: "",
  state: { id: 1, name: "Ceará" },
  city: {},
  neighborhood: {},
  state_id: 1,
  city_id: null,
  neighborhood_id: null,
  areas_attributes: [],
};

const state = {
  errorMessages: [],
  equipmentIdForDeletion: null,
  modalDeleteEquipmentConfirmationOpen: false,
  modalEquipmentOpen: false,
  states: [],
  equipment: {
    id: null,
    name: "",
    cep: "",
    street: "",
    street_number: "",
    community: "",
    complement: "",
    latitude: "",
    longitude: "",
    state: { id: 1, name: "Ceará" },
    city: {},
    neighborhood: {},
    state_id: 1,
    city_id: null,
    neighborhood_id: null,
    areas_attributes: [],
  },
  insideEquipment: {
    id: null,
    name: "",
    cep: "",
    street: "",
    street_number: "",
    community: "",
    complement: "",
    latitude: "",
    longitude: "",
    state: { id: 1, name: "Ceará" },
    city: {},
    neighborhood: {},
    state_id: 1,
    city_id: null,
    neighborhood_id: null,
    areas_attributes: [],
  },
};

const mutations = {
  loadStates: (state, states) => {
    state.states = states;
  },
  toggleModalDeleteEquipmentConfirmation: (state) => {
    state.modalDeleteEquipmentConfirmationOpen = !state.modalDeleteEquipmentConfirmationOpen;
  },
  toggleModalEquipment: (state) => {
    state.modalEquipmentOpen = !state.modalEquipmentOpen;
  },
  equipmentForDeletion: (state, equipment) => {
    state.equipmentIdForDeletion = equipment;
  },
  setEquipment: (state, equipment) => {
    state.equipment = Object.assign(state.equipment, equipment);
  },
  setInsideEquipmentMutation: (state, equipment) => {
    state.insideEquipment = equipment;
  },
  clearEquipment: (state) => {
    state.equipment = Object.assign(state.equipment, equipmentEmpty);
  },
  setName: (state, name) => {
    state.equipment.name = name;
  },
  setCep: (state, cep) => {
    state.equipment.cep = cep;
  },
  setStreet: (state, street) => {
    state.equipment.street = street;
  },
  setStreetNumber: (state, street_number) => {
    state.equipment.street_number = street_number;
  },
  setCommunity: (state, community) => {
    state.equipment.community = community;
  },
  setComplement: (state, complement) => {
    state.equipment.complement = complement;
  },
  setLatitude: (state, latitude) => {
    state.equipment.latitude = latitude;
  },
  setLongitude: (state, longitude) => {
    state.equipment.longitude = longitude;
  },
  setState: (state, stateCountry) => {
    state.equipment.state = stateCountry;
    state.equipment.state_id = stateCountry.id;
  },
  setCity: (state, city) => {
    state.equipment.city = city;
    state.equipment.city_id = city.id;
  },
  setNeighborhood: (state, neighborhood) => {
    state.equipment.neighborhood = neighborhood;
    state.equipment.neighborhood_id = neighborhood.id;
  },
  setArea: (state, area) => {
    state.equipment.areas_attributes = area;
  },
  setErrorMessages: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
  addErrorMessages: (state, errorMessages) => {
    state.errorMessages.push(errorMessages);
  },
};

const actions = {
  setName: ({ commit }, payload) => {
    commit("setName", payload.value);
  },
  setEquipment: ({ commit }, payload) => {
    commit("setEquipment", payload);
  },
  setCep: ({ commit, rootState }, payload) => {
    let cep = payload.value;
    if (cep.length === 9) {
      const regex = /\d+/g;
      getAdress(cep.match(regex).join(""))
        .then((response) => {
          let data = response.data;

          const neighborhood = rootState.Neighborhoods.neighborhoods.filter((obj) => {
            return obj.name == data.bairro;
          })[0];

          const city = rootState.City.cities.filter((obj) => {
            return obj.name == data.localidade;
          })[0];
          if (data.erro) {
            commit("addErrorMessages", { attribute: "cep", message: "CEP inválido", type: "invalid_cep" });
            cep = "";
          } else {
            let removeError = state.errorMessages.filter((obj) => {
              return obj.message !== "CEP inválido";
            });
            commit("setErrorMessages", removeError);
          }
          commit("setStreet", data.logradouro);
          commit("setNeighborhood", neighborhood);
          commit("setComplement", data.complemento);
          commit("setCity", city);
          commit("setCep", cep);
        })
        .catch((e) => {
          console.log("error", e.response); //TODO: tratar esse error
        });
    }
    commit("setCep", cep);
  },
  setStreet: ({ commit }, payload) => {
    commit("setStreet", payload.value);
  },
  setStreetNumber: ({ commit }, payload) => {
    commit("setStreetNumber", payload.value);
  },
  setCommunity: ({ commit }, payload) => {
    commit("setCommunity", payload.value);
  },
  setComplement: ({ commit }, payload) => {
    commit("setComplement", payload.value);
  },
  setLatitude: ({ commit }, payload) => {
    commit("setLatitude", payload.value);
  },
  setLongitude: ({ commit }, payload) => {
    commit("setLongitude", payload.value);
  },
  setState: ({ commit }, payload) => {
    commit("setState", payload);
    commit("setNeighborhood", {});
    commit("setCity", {});
  },
  setCity: ({ commit, dispatch }, payload) => {
    commit("setCity", payload);
    commit("setNeighborhood", {});

    dispatch("Neighborhoods/loadNeighborhoods", payload.id, { root: true });
  },
  setNeighborhood: ({ commit }, payload) => {
    commit("setNeighborhood", payload);
  },
  showEquiment: ({ commit }, id) => {
    showEquipment(id)
      .then((response) => {
        commit("setEquipment", response.data.equipment);
        commit("setInsideEquipmentMutation", response.data.equipment);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadStates: ({ commit }) => {
    getStates()
      .then((response) => {
        commit("loadStates", response.data.states);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  openCloseDeleteEquipmentConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteEquipmentConfirmation");
    if (state.modalDeleteEquipmentConfirmationOpen) {
      commit("equipmentForDeletion", payload.id);
    } else {
      commit("equipmentForDeletion", null);
    }
  },
  openModalEquipment: ({ commit }) => {
    commit("toggleModalEquipment");
  },
  openEditModalEquipment: ({ commit }, payload) => {
    commit("toggleModalEquipment");
    commit("setEquipment", payload);
  },
  clearEquipment: ({ commit }) => {
    commit("clearEquipment");
  },
  closeModalEquipment: ({ commit }) => {
    commit("setErrorMessages", []);
    commit("toggleModalEquipment");
    commit("clearEquipment");
  },
  saveEquipment: ({ commit, state, dispatch, rootState }) => {
    const id = rootState.City.city.id;
    createEquipment(state.equipment)
      .then((response) => {
        Vue.$toast.success(response.data.message);
        if (id == state.equipment.city_id)
          dispatch("EquipmentAll/addEquipment", response.data.equipment, { root: true });

        dispatch("closeModalEquipment");
      })
      .catch((e) => {
        Vue.$toast.error(
          `Não foi possivel cadastrar o equipamento! Por favor verifique se todos os campos com ( * ) foram preenchidos`
        );
        console.log(e.response.data.errors);
        commit("setErrorMessages", e.response.data.errors);
      });
  },
  updateEquipment: ({ commit, state, dispatch }) => {
    updateEquipment(state.equipment.id, state.equipment)
      .then((response) => {
        Vue.$toast.success(response.data.message);
        commit("setEquipment", response.data.equipment);
        commit("setInsideEquipmentMutation", response.data.equipment);
        dispatch("closeModalEquipment");
        dispatch("EquipmentAll/loadEquipment", null, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(
          `Não foi possivel atualizar o equipamento! Por favor verifique se todos os campos com ( * ) foram preenchidos corretamente.`
        );
        console.log(e.response.data.errors);
        commit("setErrorMessages", e.response.data.errors);
      });
  },
  deleteEquipment: ({ commit, state, dispatch }) => {
    const equipment_id = state.equipmentIdForDeletion;

    destroyEquipment(equipment_id)
      .then((response) => {
        Vue.$toast.success(response.data.message);
        dispatch("EquipmentAll/loadEquipment", null, { root: true });
        if (window.location.pathname != "/equipamentos") {
          router.push({ name: "equipamentos" });
        }
        commit("toggleModalDeleteEquipmentConfirmation");
      })
      .catch((e) => {
        console.log("error", e.data);
        Vue.$toast.error(e.response.data.errors);
      });
  },
  setArea: ({ commit }, area) => {
    commit("setArea", area);
  },
};

const getters = {
  getState: (state) => (id) => {
    return state.states.filter((state) => {
      return state.id === id;
    });
  },
  getAreas: () => (areas) => {
    let areasData = areas.map((area) => {
      return { id: area.id, name: area.name };
    });

    return areasData;
  },
  getErrorMessage: (state) => (key, field) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      switch (error.type) {
        case "blank":
          if (field === "" || field === " " || field === undefined || field === null || field.length === 0) {
            return error.message;
          } else {
            return null;
          }
        case "wrong_length":
          return error.message;

        case "invalid_cep":
          return error.message;

        default:
          return null;
      }
    } else {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
