var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.showLabel
        ? _c("BaseLabel", {
            attrs: {
              text: _vm.labelText,
              required: _vm.required,
              target: _vm.id,
            },
          })
        : _vm._e(),
      _c(
        "label",
        { class: _vm.classCss, attrs: { for: _vm.id } },
        [
          _c("span", [_vm._v(_vm._s(_vm.text))]),
          _c("AppMaterialIcon", {
            staticClass: "absolute top-0 right-1",
            attrs: { iconName: _vm.iconName },
          }),
        ],
        1
      ),
      _c("input", {
        ref: "documents",
        staticClass: "opacity-0 w-0",
        attrs: {
          id: _vm.id,
          id_file: _vm.id_file,
          type: "file",
          name: _vm.name,
          multiple: _vm.isMultiple,
          disabled: _vm.disabled,
        },
        on: { change: _vm.onFileSelected },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }