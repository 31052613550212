var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", [
    _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.company.name,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.company.city.name,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.company.modality ? _vm.company.modality.name : "-",
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text:
                _vm.company.total_opportunities_offered === 0
                  ? "0"
                  : _vm.company.total_opportunities_offered,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text:
                _vm.company.total_vacancies_offered === 0
                  ? "0"
                  : _vm.company.total_vacancies_offered,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text:
                _vm.company.total_registered_referrals === 0
                  ? "0"
                  : _vm.company.total_registered_referrals,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text:
                _vm.company.total_registered_referrals_made === 0
                  ? "0"
                  : _vm.company.total_registered_referrals_made,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4 flex" },
        [
          _vm.permitUpdate
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "edit",
                  payload: _vm.company,
                  dispatch: "Companies/openEditModalAddCompany",
                  title: "Atualizar Empresa",
                },
              })
            : _vm._e(),
          _vm.permitShow
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "visibility",
                  dispatch: "Companies/openViewModal",
                  payload: _vm.company,
                  title: "Visualizar Empresa",
                },
              })
            : _vm._e(),
          _vm.permitDelete
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "delete",
                  payload: _vm.company,
                  dispatch: "Companies/toggleModalDeleteCompany",
                  title: "Deletar Empresa",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }