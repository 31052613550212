var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col items-start font-system" },
    [
      _vm.showLabel
        ? _c("BaseLabel", {
            attrs: {
              text: _vm.labelText,
              required: _vm.required,
              target: _vm.id,
            },
          })
        : _vm._e(),
      _c("BaseText", {
        attrs: {
          id: _vm.id,
          text: _vm.text,
          typeText: _vm.typeText,
          color: _vm.color,
          weight: _vm.weight,
          mode: _vm.mode,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }