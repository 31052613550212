var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.equipment.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar Equipamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Adicionar Equipamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch: "Equipment/closeModalEquipment",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações Gerais",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "name",
                            "input-name": "name",
                            "input-placeholder": "Equipamento XX...",
                            "input-type": "text",
                            "show-label": "",
                            "label-text": "nome",
                            required: "",
                            value: _vm.equipment.name,
                            "error-message": _vm.getErrorMessage(
                              "name",
                              _vm.equipment.name
                            ),
                            dispatch: "Equipment/setName",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            id: "areas",
                            "select-placeholder": "Selecione as opções",
                            "label-text": "Vincular Áreas",
                            "close-on-select": false,
                            "show-label": "",
                            value: _vm.equipment.areas_attributes,
                            options: _vm.getAreas(_vm.areas),
                            dispatch: _vm.setAreas,
                            "error-message": _vm.getErrorMessage(
                              "areas",
                              _vm.equipment.areas_attributes
                            ),
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 mt-6" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Endereço",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "cep",
                            "input-name": "cep",
                            "input-placeholder": "00000-000",
                            mask: "#####-###",
                            "input-type": "text",
                            "show-label": "",
                            "label-text": "Cep",
                            value: _vm.equipment.cep,
                            "error-message": _vm.getErrorMessage(
                              "cep",
                              _vm.equipment.cep
                            ),
                            required: "",
                            dispatch: "Equipment/setCep",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "grid grid-cols-2 gap-4" },
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "latitude",
                            active: "",
                            "input-name": "latidude",
                            "input-placeholder": "-0.0000000000000000",
                            "input-type": "text",
                            "show-label": "",
                            value: _vm.equipment.latitude,
                            "label-text": "Latitude",
                            dispatch: "Equipment/setLatitude",
                          },
                        }),
                        _c("BaseInput", {
                          attrs: {
                            id: "longitude",
                            active: "",
                            "input-name": "longitude",
                            "input-placeholder": "-0.0000000000000000",
                            "input-type": "text",
                            "show-label": "",
                            value: _vm.equipment.longitude,
                            "label-text": "Longitude",
                            dispatch: "Equipment/setLongitude",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "street",
                            "input-name": "name",
                            "input-placeholder": "Escrever...",
                            "input-type": "text",
                            "show-label": "",
                            value: _vm.equipment.street,
                            "error-message": _vm.getErrorMessage(
                              "street",
                              _vm.equipment.street
                            ),
                            "label-text": "Rua",
                            required: "",
                            dispatch: "Equipment/setStreet",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "streat-number",
                            "input-name": "streat_number",
                            "input-placeholder": "Escrever...",
                            "input-type": "text",
                            "show-label": "",
                            value: _vm.equipment.street_number,
                            "error-message": _vm.getErrorMessage(
                              "street_number",
                              _vm.equipment.street_number
                            ),
                            "label-text": "Número",
                            required: "",
                            dispatch: "Equipment/setStreetNumber",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            id: "state",
                            "close-on-select": true,
                            "hide-selected-option": false,
                            "clear-on-select": null,
                            "select-placeholder": "Selecione as opções",
                            "multiple-option": false,
                            "show-tag-selected": "",
                            "input-type": "text",
                            required: "",
                            "label-text": "Estado",
                            "show-label": true,
                            value: _vm.equipment.state,
                            options: [{ id: 1, name: "Ceará" }],
                            "error-message": _vm.getErrorMessage(
                              "state",
                              _vm.equipment.state_id
                            ),
                            dispatch: _vm.setState,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            id: "city",
                            "close-on-select": true,
                            "hide-selected-option": false,
                            "clear-on-select": null,
                            "select-placeholder": "Selecione as opções",
                            "multiple-option": false,
                            "show-tag-selected": "",
                            "input-type": "text",
                            "show-label": true,
                            "label-text": "Município",
                            required: "",
                            value: _vm.equipment.city,
                            options: _vm.cities,
                            "error-message": _vm.getErrorMessage(
                              "city",
                              _vm.equipment.city_id
                            ),
                            dispatch: _vm.setCity,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            id: "neighborhood",
                            "close-on-select": true,
                            "hide-selected-option": false,
                            "clear-on-select": null,
                            "select-placeholder": "Selecione as opções",
                            "multiple-option": false,
                            "show-tag-selected": "",
                            "input-type": "text",
                            "show-label": true,
                            "label-text": "Bairro",
                            required: "",
                            value: _vm.equipment.neighborhood,
                            options: _vm.neighborhoods,
                            "error-message": _vm.getErrorMessage(
                              "neighborhood",
                              _vm.equipment.neighborhood_id
                            ),
                            dispatch: _vm.setNeighborhood,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "community",
                            "input-name": "community",
                            "input-placeholder": "Escrever...",
                            "input-type": "text",
                            "show-label": "",
                            "label-text": "Comunidade",
                            value: _vm.equipment.community,
                            dispatch: "Equipment/setCommunity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "complement",
                            "input-name": "complement",
                            "input-placeholder": "Escrever...",
                            "input-type": "text",
                            "show-label": "",
                            "label-text": "Complemento",
                            value: _vm.equipment.complement,
                            dispatch: "Equipment/setComplement",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch: "Equipment/closeModalEquipment",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.equipment.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "salvar",
                                typeButton: "success",
                                dispatch: "Equipment/updateEquipment",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "criar",
                                typeButton: "success",
                                dispatch: "Equipment/saveEquipment",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1020859109
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }