import { isEmpty } from "lodash";

const state = {
  id: null,
  status: null,
  contacted: null,
  date_face_to_face_service: null,
  justification: null,
  season: null,
  equipment: null,
  active: null,
  documents: [],
  students: [],
};

const mutations = {
  setIdMutation: (state, id) => {
    state.id = id;
  },
  setStatusMutation: (state, status) => {
    state.status = status;
  },
  setContactedMutation: (state, contacted) => {
    state.contacted = contacted;
  },
  setDateFaceToSaceServiceMutation: (state, dateFaceToSaceServiceMutation) => {
    state.date_face_to_face_service = dateFaceToSaceServiceMutation;
  },
  setJustificationMutation: (state, justification) => {
    state.justification = justification;
  },
  setSeasonMutation: (state, season) => {
    state.season = season;
  },
  setEquipmentMutation: (state, equipment) => {
    state.equipment = equipment;
  },
  setActiveMutation: (state, active) => {
    state.active = active;
  },
  setDocumentInInscription: (state, document) => {
    // remover o código abaixo quando a API estiver retornando os documentos junto com a inscrição;
    state.documents.push(document);
  },
  setStudents: (state, students) => {
    state.students = students;
  },
};

const actions = {
  setId: ({ commit }, payload) => {
    commit("setIdMutation", payload);
  },
  setStatus: ({ commit }, payload) => {
    commit("setStatusMutation", payload);
  },
  setContacted: ({ commit }, payload) => {
    commit("setContactedMutation", payload.value);
  },
  setDateFaceToFaceService: ({ commit }, payload) => {
    commit("setDateFaceToSaceServiceMutation", payload.value);
  },
  setJustification: ({ commit }, payload) => {
    commit("setJustificationMutation", payload);
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },
  setEquipment: ({ commit }, payload) => {
    commit("setEquipmentMutation", payload);
  },
  setActive: ({ commit }, payload) => {
    commit("setActiveMutation", payload);
  },
  setDocumentInInscription: ({ commit }, payload) => {
    commit("setDocumentInInscription", payload);
  },
  load: ({ commit, dispatch }, payload) => {
    if (payload && !isEmpty(payload)) {
      commit("setIdMutation", payload.id);
      commit("setStatusMutation", payload.status);
      commit("setActiveMutation", payload.active);
      commit("setContactedMutation", payload.contacted);
      commit("setDateFaceToSaceServiceMutation", payload.date_face_to_face_service);
      commit("setJustificationMutation", payload.justification);
      dispatch("Student/load", payload.student, { root: true });
      commit("setSeasonMutation", payload.season);
      commit("setEquipmentMutation", payload.equipment);
    } else {
      commit("setIdMutation", null);
      commit("setStatusMutation", null);
      commit("setActiveMutation", null);
      commit("setContactedMutation", null);
      commit("setDateFaceToSaceServiceMutation", null);
      commit("setJustificationMutation", null);
      dispatch("Student/load", null, { root: true });
      commit("setSeasonMutation", null);
      commit("setEquipmentMutation", null);
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
