var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.psychosocial_accompaniment?.meeting_happened
    ? _c(
        "div",
        { staticClass: "grid grid-cols-2 gap-4 mt-2" },
        [
          _c(
            "div",
            { staticClass: "col-span-2 text-burnedYellow pb-2" },
            [
              _c("Label", {
                attrs: {
                  text: "Situação de Moradia",
                  icon: null,
                  "icon-type": null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
              _c("div", {
                staticClass: "border-ashes-hover border-b-2 w-full",
              }),
            ],
            1
          ),
          _c("Multiselect", {
            attrs: {
              "close-on-select": true,
              "show-label": "",
              "clear-on-select": "",
              "select-placeholder": "Situação de Moradia",
              "label-text": "Situação de Moradia",
              "multiple-option": false,
              value: _vm.housing_situation,
              options: _vm.housing_situations,
              dispatch: _vm.setHousingSituation,
            },
          }),
          _c(
            "div",
            { staticClass: "col-span-1" },
            [
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "number_of_residents",
                  "input-type": "number",
                  value: _vm.psychosocial_accompaniment?.number_of_residents,
                  "show-label": "",
                  "label-text": "Quantas pessoas residem com você?",
                  inputMin: "0",
                  dispatch: "PsychosocialAccompaniments/setNumberOfResidents",
                },
              }),
            ],
            1
          ),
          _c("Multiselect", {
            attrs: {
              "close-on-select": true,
              "show-label": "",
              "clear-on-select": "",
              "select-placeholder": "Selecione uma opção",
              "label-text": "Situação do Imóvel",
              "multiple-option": false,
              value: _vm.housing_building_situation,
              options: _vm.housing_building_situations,
              dispatch: _vm.setHousingBuildingSituation,
            },
          }),
          _c("Multiselect", {
            attrs: {
              "close-on-select": true,
              "show-label": "",
              "clear-on-select": "",
              "select-placeholder": "Selecione uma opção",
              "label-text": "Estrutura do Imóvel",
              "multiple-option": false,
              value: _vm.housing_building_material,
              options: _vm.housing_building_materials,
              dispatch: _vm.setHousingBuildingMaterial,
            },
          }),
          _c("Multiselect", {
            attrs: {
              "close-on-select": true,
              "show-label": "",
              "clear-on-select": "",
              "select-placeholder": "Selecione uma opção",
              "label-text":
                "Qual a forma do abastecimento de energia elétrica?",
              "multiple-option": false,
              value: _vm.housing_has_electricity,
              options: _vm.housing_has_electricities,
              dispatch: _vm.setHousingHasElectricity,
            },
          }),
          _c("Multiselect", {
            attrs: {
              "close-on-select": true,
              "show-label": "",
              "clear-on-select": "",
              "select-placeholder": "Selecione uma opção",
              "label-text": "Qual a forma do abastecimento de água?",
              "multiple-option": false,
              value: _vm.housing_has_water_supply,
              options: _vm.housing_has_water_supplies,
              dispatch: _vm.setHousingHasWaterSupply,
            },
          }),
          _c("Multiselect", {
            attrs: {
              "close-on-select": true,
              "show-label": "",
              "clear-on-select": "",
              "select-placeholder": "Selecione uma opção",
              "label-text": "Fornecimento de Gás",
              "multiple-option": false,
              value: _vm.housing_has_gas_supply,
              options: _vm.housing_has_gas_supplies,
              dispatch: _vm.setHousingHasGasSupply,
            },
          }),
          _c("Multiselect", {
            attrs: {
              "close-on-select": true,
              "show-label": "",
              "clear-on-select": "",
              "select-placeholder": "Selecione uma opção",
              "label-text":
                "De que forma é feito o escoamento do banheiro ou sanitário?",
              "multiple-option": false,
              value: _vm.housing_bathroom_drain_type,
              options: _vm.housing_bathroom_drain_types,
              dispatch: _vm.setHousingBathroomDrainType,
            },
          }),
          _c("AppRadioButton", {
            attrs: {
              values: [
                {
                  name: "housing_has_latrine",
                  value: true,
                  description: "Sim",
                  checked:
                    _vm.psychosocial_accompaniment?.housing_has_latrine ===
                    true,
                },
                {
                  name: "housing_has_latrine",
                  value: false,
                  description: "Não",
                  checked:
                    _vm.psychosocial_accompaniment?.housing_has_latrine ===
                    false,
                },
              ],
              labelText: "Tem saneamento básico?",
              showLabel: "",
              dispatch: "PsychosocialAccompaniments/setHousingHasLatrine",
            },
          }),
          _c("AppRadioButton", {
            attrs: {
              values: [
                {
                  name: "housing_has_bathroom",
                  value: true,
                  description: "Sim",
                  checked:
                    _vm.psychosocial_accompaniment?.housing_has_bathroom ===
                    true,
                },
                {
                  name: "housing_has_bathroom",
                  value: false,
                  description: "Não",
                  checked:
                    _vm.psychosocial_accompaniment?.housing_has_bathroom ===
                    false,
                },
              ],
              labelText: "Tem banheiro?",
              showLabel: "",
              dispatch: "PsychosocialAccompaniments/setHousingHasBathroom",
            },
          }),
          _c("AppRadioButton", {
            attrs: {
              values: [
                {
                  name: "housing_has_garbage_collection",
                  value: true,
                  description: "Sim",
                  checked:
                    _vm.psychosocial_accompaniment
                      ?.housing_has_garbage_collection === true,
                },
                {
                  name: "housing_has_garbage_collection",
                  value: false,
                  description: "Não",
                  checked:
                    _vm.psychosocial_accompaniment
                      ?.housing_has_garbage_collection === false,
                },
              ],
              labelText: "Tem coleta de lixo?",
              showLabel: "",
              dispatch:
                "PsychosocialAccompaniments/setHousingHasGarbageCollection",
            },
          }),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("BaseTextArea", {
                attrs: {
                  active: "",
                  inputName: "Comentários sobre situação de Moradia",
                  labelText: "Comentários sobre situação de Moradia",
                  showLabel: "",
                  rows: "4",
                  value:
                    _vm.psychosocial_accompaniment?.housing_situation_comments,
                  dispatch:
                    "PsychosocialAccompaniments/setHousingSituationComments",
                },
              }),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }