var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permit
    ? _c("div", { staticClass: "font-system" }, [
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-3 justify-between items-center mx-12 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "text-ashes-hover font-medium text-3xl mx-2 col-span-2",
              },
              [_vm._v("Segmentos")]
            ),
            _c(
              "div",
              { staticClass: "mx-2 items-center justify-self-end" },
              [
                _vm.permitCreate
                  ? _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-add-segment",
                        showIcon: "",
                        iconName: "add",
                        text: "Cadastrar Segmento",
                        dispatch: "Segments/openModalAddSegment",
                        typeButton: "primary",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "mx-14", attrs: { id: "tabs-button" } },
          [
            _c("FilterComponent", [
              _c(
                "div",
                { staticClass: "col-span-4" },
                [
                  _c("BaseLabel", {
                    attrs: {
                      text: "Pesquisar por nome do Segmento",
                      typeText: "custom",
                      weight: "normal",
                      italic: false,
                      size: "xs",
                    },
                  }),
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      inputName: "",
                      inputType: "text",
                      iconName: "search",
                      showIcon: "",
                      "input-placeholder": "Pesquisar por nome",
                      dispatch: "Segments/setFilterName",
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "grid grid-cols-2 items-center mx-12 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              { staticClass: "mx-2" },
              [
                _vm.permit
                  ? _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-consult-segments",
                        showIcon: "",
                        iconName: "search",
                        text: "Consultar dados",
                        dispatch: "Segments/loadSegments",
                        payload: "load",
                        typeButton: "primary",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.segments.length > 0
              ? _c(
                  "div",
                  { staticClass: "mx-2 items-center justify-self-end" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.clearFields()
                          },
                        },
                      },
                      [
                        _c("AppButtonColorful", {
                          attrs: {
                            id: "btn-consult-segments",
                            showIcon: "",
                            iconName: "remove",
                            text: "Limpar consulta",
                            typeButton: "primary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { attrs: { id: "list-segments" } },
          [
            _c(
              "div",
              { staticClass: "mx-12" },
              [_c("SegmentsCards", { attrs: { data: _vm.segments } })],
              1
            ),
            _vm.segments.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "mx-12 my-6 pb-3",
                    attrs: { id: "segments-pagination" },
                  },
                  [
                    _c("BasePagination", {
                      attrs: {
                        total: _vm.totalSegments,
                        "total-per-page": _vm.totalPerPage,
                        dispatch: "Segments/setSegmentsOffset",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("ModalSegment", { attrs: { open: _vm.modalToggleAddSegment } }),
            _c("DeleteSegmentConfirmation", {
              attrs: { open: _vm.modalToggleDeleteSegment },
            }),
          ],
          1
        ),
      ])
    : _c("ProhibitedAccessPage", {
        attrs: {
          classText:
            "grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }