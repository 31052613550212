<template>
  <div>
    <BaseModal headerColor="#fff" v-if="open">
      <template v-slot:header>
        <div class="flex flex-row-reverse">
          <div class="bg-white rounded-full w-7 h-7 justify-self-center">
            <AppButtonIcon for-close dispatch="Default/toggleModalPreInscription" />
          </div>
        </div>
      </template>
      <template v-slot:body>
        <div class="container1">
          <div class="container2">
            <div class="m-5 my-0">
              <BaseText
                text="Cadastro de Aluno"
                type-text="custom"
                weight="bold"
                mode="capitalize"
                color="burnedYellow"
                size="3xl"
              />
            </div>
            <!-- <div class="mr-6 flex justify-center items-center" v-if="false">
              <AppButtonUploadImage
                id="foto"
                text="Fotos"
                typeDocument="cpf"
                name="foto"
                dispatch="Student/setAvatar"
              />
            </div> -->
            <div class="flex justify-end items-center">
              <div class="mr-5">
                <a
                  href="/docs/FICHA_DE_PRE_CADASTRO_ATUALIZADA_2024.pdf"
                  target="_blank"
                  class="text-ashes-dark underline hover:text-burnedYellow"
                >
                  <BaseText
                    text="Imprimir formulário em branco"
                    type-text="custom"
                    weight="medium"
                    size="sm"
                    class="align-middle mr-2"
                  />
                  <AppMaterialIcon icon-name="print" class="align-middle" />
                </a>
              </div>
            </div>
            <FormYoungStudent />
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <div class="grid grid-cols-2 col-auto mx-5">
          <div class="justify-self-start">
            <AppButtonEmpty
              text="descartar"
              typeButton="danger"
              dispatch="Default/toggleModalPreInscription"
            />
          </div>
          <div class="justify-self-end">
            <AppButtonEmpty text="Finalizar" typeButton="success" dispatch="Default/saveInscription" />
          </div>
        </div>
      </template>
    </BaseModal>
  </div>
</template>

<script>
import FormYoungStudent from "./FormYoungStudent.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
// import AppButtonUploadImage from "../components/AppButtonUploadImage.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import AppMaterialIcon from "../components/AppMaterialIcon.vue";
import BaseModal from "../components/BaseModal.vue";
import BaseText from "../components/BaseText.vue";

export default {
  components: {
    AppButtonEmpty,
    AppButtonIcon,
    // AppButtonUploadImage,
    AppMaterialIcon,
    BaseModal,
    BaseText,
    FormYoungStudent,
  },

  props: {
    open: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>
.container1 {
  height: 750px;
  width: 100%;
  overflow: hidden;
}

.container2 {
  width: 105%;
  height: 100%;
  overflow: auto;
  padding-right: 4%;
  padding-left: 4px;
}
</style>
