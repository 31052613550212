import Vue from "vue";
import JsonExcel from "vue-json-excel";
import { getSeasonsByCityIdForMenus } from "../../service/SeasonApi";
Vue.component("downloadExcel", JsonExcel);

const state = {
  season: null,
  user: null,

  seasons: [],
  user_areas: [],
  user_equipments: [],

  tabProductiveInsertionView: "opportunities",
  toggleOpportunities: true,
  toggleReferredParcipants: false,
  toggleSpontaneousInsertions: false,
  toggleProfileSearch: false,
  toggleForwardingReservationRegistration: false,
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  setSeasonsMutation: (state, seasons) => {
    state.seasons = seasons;
  },
  setSeasonMutation: (state, season) => {
    state.season = season;
    localStorage.setItem("season", JSON.stringify(season));
  },
  setUserMutation: (state, user) => {
    state.user = user;
  },
  setUserAreasMutation: (state, user_areas) => {
    state.user_areas = user_areas;
  },
  setUserEquipmentsMutation: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },

  // MUTATIONS - TOGGLES
  toggleOpportunities: (state, value) => {
    state.toggleOpportunities = value;
  },
  toggleReferredParcipants: (state, value) => {
    state.toggleReferredParcipants = value;
  },
  toggleSpontaneousInsertions: (state, value) => {
    state.toggleSpontaneousInsertions = value;
  },
  toggleProfileSearch: (state, value) => {
    state.toggleProfileSearch = value;
  },
  toggleForwardingReservationRegistration: (state, value) => {
    state.toggleForwardingReservationRegistration = value;
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setTabProductiveInsertionViewMutation: (state, type) => {
    state.tabProductiveInsertionView = type;
  },
};

const actions = {
  // --> DATA COMING FROM API
  loadSeasons: ({ commit }) => {
    const cityId = localStorage.city_id;
    getSeasonsByCityIdForMenus(cityId)
      .then((response) => {
        commit("setSeasonsMutation", response.data.seasons);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },

  // --> ACTIONS - SETTING TABS <--
  setTabProductiveInsertionView: ({ commit }, payload) => {
    commit("setTabProductiveInsertionViewMutation", payload);
    switch (payload) {
      case "opportunities":
        commit("toggleOpportunities", true);
        commit("toggleReferredParcipants", false);
        commit("toggleSpontaneousInsertions", false);
        commit("toggleProfileSearch", false);
        commit("toggleForwardingReservationRegistration", false);
        break;
      case "referredParcipants":
        commit("toggleOpportunities", false);
        commit("toggleReferredParcipants", true);
        commit("toggleSpontaneousInsertions", false);
        commit("toggleProfileSearch", false);
        commit("toggleForwardingReservationRegistration", false);
        break;
      case "spontaneousInsertions":
        commit("toggleOpportunities", false);
        commit("toggleReferredParcipants", false);
        commit("toggleSpontaneousInsertions", true);
        commit("toggleProfileSearch", false);
        commit("toggleForwardingReservationRegistration", false);
        break;
      case "profileSearch":
        commit("toggleOpportunities", false);
        commit("toggleReferredParcipants", false);
        commit("toggleSpontaneousInsertions", false);
        commit("toggleProfileSearch", true);
        commit("toggleForwardingReservationRegistration", false);
        break;
      case "forwardingReservationRegistration":
        commit("toggleOpportunities", false);
        commit("toggleReferredParcipants", false);
        commit("toggleSpontaneousInsertions", false);
        commit("toggleProfileSearch", false);
        commit("toggleForwardingReservationRegistration", true);
        break;
    }
  },

  // --> ACTIONS - OTHER ACTIONS <--
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },
  setUser: ({ commit }, payload) => {
    commit("setUserMutation", payload);
  },
  setUserAreas: ({ commit }, payload) => {
    commit("setUserAreasMutation", payload);
  },
  setUserEquipments: ({ commit }, payload) => {
    commit("setUserEquipmentsMutation", payload);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
