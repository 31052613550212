var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.editionsRow },
    [
      _c("Label", {
        class: _vm.title,
        attrs: {
          text: _vm.city.name + " | Edições",
          icon: null,
          iconType: null,
          type: "text-3xl",
          weight: "semibold",
          normal: "",
          mode: "normal-case",
        },
      }),
      _c("div", { staticClass: "grid grid-cols-5 my-8" }, [
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.searchEdition,
                expression: "searchEdition",
              },
            ],
            staticClass: "rounded-lg w-full py-3 px-6 border border-black",
            attrs: { type: "text", placeholder: "Procurar por Edição..." },
            domProps: { value: _vm.searchEdition },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.searchEdition = $event.target.value
              },
            },
          }),
        ]),
        _c(
          "div",
          { staticClass: "col-span-4 justify-self-end" },
          [
            _vm.permitCreate
              ? _c("AppButtonColorful", {
                  attrs: {
                    text: "Adicionar edição",
                    typeButton: "primary",
                    showIcon: "",
                    iconName: "add",
                    iconType: "outlined",
                    dispatch: "Seasons/openModal",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "grid grid-cols-custom mt-12 ml-6 text-ashes-dark" },
        [
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Edição",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Status",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Período de inscrição",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Período de execução",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Nº de Matrículas",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Nº de Ativos",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Nº de Desligados",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Nº de Concludentes",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c("div"),
        ]
      ),
      _c(
        "BaseStaggeredFade",
        { attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.filteredSeasons, function (season, index) {
          return _c(
            "div",
            {
              key: season.id,
              staticClass: "mt-6",
              attrs: { "data-index": index },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goToSeason(season)
                },
              },
            },
            [
              _c("AppEdition", { attrs: { edition: season } }),
              _c("div", { staticClass: "my-6 border-ashes w-full border" }),
            ],
            1
          )
        }),
        0
      ),
      _c("ModalSeason", { attrs: { open: _vm.showModal } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }