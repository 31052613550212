import { render, staticRenderFns } from "./VacancyRequest.vue?vue&type=template&id=4ea55da3"
import script from "./VacancyRequest.vue?vue&type=script&lang=js"
export * from "./VacancyRequest.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('4ea55da3')) {
      api.createRecord('4ea55da3', component.options)
    } else {
      api.reload('4ea55da3', component.options)
    }
    module.hot.accept("./VacancyRequest.vue?vue&type=template&id=4ea55da3", function () {
      api.rerender('4ea55da3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/school_reinsertion_pages/VacancyRequest.vue"
export default component.exports