var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "font-system relative" }, [
    _c(
      "label",
      {
        staticClass: "absolute pt-4 pb-3 px-5 opacity-75 cursor-text",
        class: [_vm.dynamicClass, _vm.textColorClass],
        attrs: { for: _vm.id },
      },
      [_vm._v(" " + _vm._s(_vm.labelText) + " ")]
    ),
    _vm.typeInput === "checkbox"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.valueInput,
              expression: "valueInput",
            },
          ],
          staticClass:
            "border focus:outline-none rounded-full pt-4 pb-3 pl-5 pr-12 w-full bg-transparent",
          class: [_vm.textColorClass, _vm.borderColorClass],
          attrs: { id: _vm.id, type: "checkbox" },
          domProps: {
            checked: Array.isArray(_vm.valueInput)
              ? _vm._i(_vm.valueInput, null) > -1
              : _vm.valueInput,
          },
          on: {
            focus: _vm.moveLabelUp,
            blur: _vm.moveLabelDown,
            input: function ($event) {
              return _vm.onInput()
            },
            change: function ($event) {
              var $$a = _vm.valueInput,
                $$el = $event.target,
                $$c = $$el.checked ? true : false
              if (Array.isArray($$a)) {
                var $$v = null,
                  $$i = _vm._i($$a, $$v)
                if ($$el.checked) {
                  $$i < 0 && (_vm.valueInput = $$a.concat([$$v]))
                } else {
                  $$i > -1 &&
                    (_vm.valueInput = $$a
                      .slice(0, $$i)
                      .concat($$a.slice($$i + 1)))
                }
              } else {
                _vm.valueInput = $$c
              }
            },
          },
        })
      : _vm.typeInput === "radio"
      ? _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.valueInput,
              expression: "valueInput",
            },
          ],
          staticClass:
            "border focus:outline-none rounded-full pt-4 pb-3 pl-5 pr-12 w-full bg-transparent",
          class: [_vm.textColorClass, _vm.borderColorClass],
          attrs: { id: _vm.id, type: "radio" },
          domProps: { checked: _vm._q(_vm.valueInput, null) },
          on: {
            focus: _vm.moveLabelUp,
            blur: _vm.moveLabelDown,
            input: function ($event) {
              return _vm.onInput()
            },
            change: function ($event) {
              _vm.valueInput = null
            },
          },
        })
      : _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.valueInput,
              expression: "valueInput",
            },
          ],
          staticClass:
            "border focus:outline-none rounded-full pt-4 pb-3 pl-5 pr-12 w-full bg-transparent",
          class: [_vm.textColorClass, _vm.borderColorClass],
          attrs: { id: _vm.id, type: _vm.typeInput },
          domProps: { value: _vm.valueInput },
          on: {
            focus: _vm.moveLabelUp,
            blur: _vm.moveLabelDown,
            input: [
              function ($event) {
                if ($event.target.composing) return
                _vm.valueInput = $event.target.value
              },
              function ($event) {
                return _vm.onInput()
              },
            ],
          },
        }),
    _c(
      "button",
      {
        class: { "cursor-default": !_vm.hiddenValue },
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.showPassword.apply(null, arguments)
          },
        },
      },
      [
        _c("AppMaterialIcon", {
          staticClass: "icon-rigth",
          class: [_vm.textColorClass],
          attrs: { iconName: _vm.icon, iconType: _vm.iconType },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }