<template>
  <BaseModal v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-4">
        <div class="justify-self-start col-span-3">
          <Label
            v-if="psychosocial_accompaniment?.id"
            text="Realizar Acolhimento Inicial"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar Acolhimento Inicial"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon
            dispatch="PsychosocialAccompaniments/closeModalFirstPsychosocialAccompaniment"
            forClose
          />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-4 gap-4 mt-4" v-if="psychosocial_accompaniment?.id && accompaniment_origin">
        <div class="col-span-4 text-burnedYellow pb-2">
          <Label
            text="Informações do Atendimento de Origem"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Data do Encontro"
            :text="formattedDate(accompaniment_origin.date_accompaniment)"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Tipo do Atendimento"
            :text="accompaniment_origin.accompaniment_type.name"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Status do Acompanhamento"
            :text="accompaniment_origin.accompaniment_status.name"
            type-text="topic-details"
          />
        </div>
        <div class="col-span-2">
          <AppTextField
            show-label
            label-text="Usuário responsável pelo cadastro"
            :text="accompaniment_origin.user.name"
            type-text="topic-details"
          />
        </div>
      </div>
      <div div class="grid grid-cols-4 gap-4 mt-4" v-if="psychosocial_accompaniment?.id">
        <div class="col-span-4 text-burnedYellow pb-2">
          <Label
            text="Informações do Aluno(a)"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <AppTextField
            show-label
            label-text="Nome"
            :text="inscription.student.name"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Status do Aluno(a) no sistema"
            v-bind:text="verifyStatus()"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="CPF"
            v-bind:text="verifyInscription('cpf') | VMask('###.###.###-##')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Data de Nascimento"
            v-bind:text="verifyInscription('birthdate')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Idade"
            v-bind:text="verifyInscription('age')"
            type-text="topic-details"
          />
        </div>
        <div>
          <AppTextField
            show-label
            label-text="Número Contato"
            v-bind:text="verifyInscription('cellphone_number') | VMask('(##) #####-####')"
            type-text="topic-details"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Dados cadastrais do encontro"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <BaseInput
            active
            input-name="birthdate"
            input-type="date"
            :value="psychosocial_accompaniment?.date_accompaniment"
            show-label
            label-text="Data do Encontro"
            required
            dispatch="PsychosocialAccompaniments/setDateAccompaniment"
            :readonly="true"
          />
        </div>
        <div class="col-span-1">
          <AppRadioButton
            :values="[
              {
                name: 'has_meeting_happened',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.meeting_happened === true,
              },
              {
                name: 'has_meeting_happened',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.meeting_happened === false,
              },
            ]"
            labelText="Encontro aconteceu?"
            showLabel
            dispatch="PsychosocialAccompaniments/setMeetingHappened"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'meeting_format',
                value: 'Presencial',
                description: 'Presencial',
                checked: psychosocial_accompaniment?.meeting_format === 'Presencial',
              },
              {
                name: 'meeting_format',
                value: 'Remoto',
                description: 'Remoto',
                checked: psychosocial_accompaniment?.meeting_format === 'Remoto',
              },
            ]"
            labelText="Formato do Encontro"
            showLabel
            dispatch="PsychosocialAccompaniments/setMeetingFormat"
          />
        </div>
        <div v-if="!psychosocial_accompaniment?.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Motivo do Encontro Não ter Ocorrido"
            label-text="Motivo do Encontro Não ter Ocorrido"
            :multiple-option="false"
            :value="meeting_not_happened_reason"
            :options="meeting_not_happened_reasons"
            :dispatch="setMeetingNotHappenedReason"
            required
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <BaseInput
            active
            input-name="psychosocial_accompaniment_meeting_place"
            input-type="text"
            :value="psychosocial_accompaniment?.meeting_place"
            show-label
            label-text="Local do Encontro"
            dispatch="PsychosocialAccompaniments/setMeetingPlace"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <BaseInput
            active
            input-name="psychosocial_accompaniment_meeting_remote_resource"
            input-type="text"
            :value="psychosocial_accompaniment?.meeting_remote_resource"
            show-label
            label-text="Recurso do encontro remoto"
            dispatch="PsychosocialAccompaniments/setMeetingRemoteResource"
            :readonly="psychosocial_accompaniment?.meeting_format === 'Presencial'"
          />
        </div>
        <div class="col-span-2 text-burnedYellow pb-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <Label
            text="Profissionais que acompanham"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'register_attendant_teams',
                value: true,
                description: 'Sim',
                checked: register_attendant_teams === true,
              },
              {
                name: 'register_attendant_teams',
                value: false,
                description: 'Não',
                checked: register_attendant_teams === false,
              },
            ]"
            labelText="Informar profissionais que acompanham o Atendimento?"
            showLabel
            dispatch="PsychosocialAccompaniments/setRegisterAttendatTeams"
          />
        </div>
        <div class="col-span-2" v-if="register_attendant_teams">
          <div
            class="grid grid-cols-12 gap-4 mt-2"
            v-for="(attendant, index) in attendant_teams_attributes"
            :key="index"
          >
            <div class="col-span-11" v-if="psychosocial_accompaniment?.meeting_happened">
              <Multiselect
                :close-on-select="true"
                show-label
                clear-on-select
                select-placeholder="Selecione a opção"
                label-text="Profissional"
                :multiple-option="false"
                :value="psychosocial_attendant_attributes[index]"
                :options="mountPsychosocialAttendants(index)"
                required
                :dispatch="setPsychosocialAttendant"
              />
            </div>
            <div
              class="mt-5"
              v-if="attendant_teams_attributes.length > 1 && psychosocial_accompaniment.meeting_happened"
            >
              <a href="javascript:void(0)" class="text-burnedYellow underline" @click="deleteAttendantTeam">
                <AppMaterialIcon icon-name="delete" icon-size="36" class="align-middle" />
              </a>
            </div>
          </div>
          <div
            class="col-span-4 mt-5"
            v-if="attendant_teams_attributes.length > 1 && psychosocial_accompaniment.meeting_happened"
          >
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </div>
        </div>
        <div class="mt-5" v-if="psychosocial_accompaniment?.meeting_happened && register_attendant_teams">
          <a href="javascript:void(0)" class="text-burnedYellow" @click="addAttendantTeam">
            <AppMaterialIcon icon-name="add_circle" icon-size="36" class="align-middle" />
            <BaseText
              text="Adicionar Profissional"
              type-text="custom"
              weight="medium"
              size="sm"
              class="align-middle mr-2"
            />
          </a>
        </div>
        <div class="col-span-2 text-burnedYellow pb-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <Label
            text="Situação de Moradia"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Situação de Moradia"
            label-text="Situação de Moradia"
            :multiple-option="false"
            :value="housing_situation"
            :options="housing_situations"
            :dispatch="setHousingSituation"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <BaseInput
            active
            input-name="number_of_residents"
            input-type="number"
            :value="psychosocial_accompaniment?.number_of_residents"
            show-label
            label-text="Quantas pessoas residem com você?"
            inputMin="0"
            dispatch="PsychosocialAccompaniments/setNumberOfResidents"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="Situação do Imóvel"
            :multiple-option="false"
            :value="housing_building_situation"
            :options="housing_building_situations"
            :dispatch="setHousingBuildingSituation"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="Estrutura do Imóvel"
            :multiple-option="false"
            :value="housing_building_material"
            :options="housing_building_materials"
            :dispatch="setHousingBuildingMaterial"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="Qual a forma do abastecimento de energia elétrica?"
            :multiple-option="false"
            :value="housing_has_electricity"
            :options="housing_has_electricities"
            :dispatch="setHousingHasElectricity"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="Qual a forma do abastecimento de água?"
            :multiple-option="false"
            :value="housing_has_water_supply"
            :options="housing_has_water_supplies"
            :dispatch="setHousingHasWaterSupply"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="Fornecimento de Gás"
            :multiple-option="false"
            :value="housing_has_gas_supply"
            :options="housing_has_gas_supplies"
            :dispatch="setHousingHasGasSupply"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="De que forma é feito o escoamento do banheiro ou sanitário?"
            :multiple-option="false"
            :value="housing_bathroom_drain_type"
            :options="housing_bathroom_drain_types"
            :dispatch="setHousingBathroomDrainType"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'housing_has_latrine',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.housing_has_latrine === true,
              },
              {
                name: 'housing_has_latrine',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.housing_has_latrine === false,
              },
            ]"
            labelText="Tem saneamento básico?"
            showLabel
            dispatch="PsychosocialAccompaniments/setHousingHasLatrine"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'housing_has_bathroom',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.housing_has_bathroom === true,
              },
              {
                name: 'housing_has_bathroom',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.housing_has_bathroom === false,
              },
            ]"
            labelText="Tem banheiro?"
            showLabel
            dispatch="PsychosocialAccompaniments/setHousingHasBathroom"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'housing_has_garbage_collection',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.housing_has_garbage_collection === true,
              },
              {
                name: 'housing_has_garbage_collection',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.housing_has_garbage_collection === false,
              },
            ]"
            labelText="Tem coleta de lixo?"
            showLabel
            dispatch="PsychosocialAccompaniments/setHousingHasGarbageCollection"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <BaseTextArea
            active
            inputName="Comentários sobre situação de Moradia"
            labelText="Comentários sobre situação de Moradia"
            showLabel
            rows="4"
            :value="psychosocial_accompaniment?.housing_situation_comments"
            dispatch="PsychosocialAccompaniments/setHousingSituationComments"
          />
        </div>
        <div class="col-span-2 text-burnedYellow pb-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <Label
            text="Cumprimento de Medida Socioeducativa"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="Situação do adolescente/jovem em relação ao cumprimento de medidas socioeducativas"
            :multiple-option="false"
            :value="socio_educational_measure_situation"
            :options="socio_educational_measure_situations"
            :dispatch="setSocioEducationalMeasureSituation"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'is_half_open_socio_educational_measure',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.is_half_open_socio_educational_measure === true,
              },
              {
                name: 'is_half_open_socio_educational_measure',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.is_half_open_socio_educational_measure === false,
              },
            ]"
            labelText="Meio Aberto?"
            showLabel
            dispatch="PsychosocialAccompaniments/setIsHalfOpenSocioEducationalMeasure"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'is_half_closed_socio_educational_measure',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.is_half_closed_socio_educational_measure === true,
              },
              {
                name: 'is_half_closed_socio_educational_measure',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.is_half_closed_socio_educational_measure === false,
              },
            ]"
            labelText="Meio Fechado?"
            showLabel
            dispatch="PsychosocialAccompaniments/setIsHalfClosedSocioEducationalMeasure"
          />
        </div>
        <div class="col-span-2 text-burnedYellow pb-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <Label
            text="Cumprimento de Pena"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione uma opção"
            label-text="Situação do adolescente/jovem em relação ao cumprimento de pena"
            :multiple-option="false"
            :value="sentence_serving_situation"
            :options="socio_educational_measure_situations"
            :dispatch="setSentenceServingSituation"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'is_half_open_sentence_serving_situation',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.is_half_open_sentence_serving_situation === true,
              },
              {
                name: 'is_half_open_sentence_serving_situation',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.is_half_open_sentence_serving_situation === false,
              },
            ]"
            labelText="Meio Aberto?"
            showLabel
            dispatch="PsychosocialAccompaniments/setIsHalfOpenSentenceServingSituation"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'is_half_closed_sentence_serving_situation',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.is_half_closed_sentence_serving_situation === true,
              },
              {
                name: 'is_half_closed_sentence_serving_situation',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.is_half_closed_sentence_serving_situation === false,
              },
            ]"
            labelText="Meio Fechado?"
            showLabel
            dispatch="PsychosocialAccompaniments/setIsHalfClosedSentenceServingSituation"
          />
        </div>
        <div class="col-span-2 text-burnedYellow pb-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <Label
            text="Situação Escolar"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'is_studying',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.is_studying === true,
              },
              {
                name: 'is_studying',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.is_studying === false,
              },
            ]"
            labelText="Está estudando?"
            showLabel
            dispatch="PsychosocialAccompaniments/setIsStudying"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <BaseTextArea
            active
            inputName="reason_for_not_studying"
            labelText="Razão para não estar estudando"
            showLabel
            rows="4"
            :value="psychosocial_accompaniment?.reason_for_not_studying"
            dispatch="PsychosocialAccompaniments/setReasonForNotStudying"
            :readonly="psychosocial_accompaniment?.is_studying"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <BaseInput
            active
            input-name="last_grade_studied"
            input-type="text"
            :value="psychosocial_accompaniment?.last_grade_studied"
            show-label
            label-text="Última série estudada"
            dispatch="PsychosocialAccompaniments/setLastGradeStudied"
            :readonly="psychosocial_accompaniment?.is_studying"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <BaseInput
            active
            input-name="last_school_studied"
            input-type="text"
            :value="psychosocial_accompaniment?.last_school_studied"
            show-label
            label-text="Última escola em que estudou"
            dispatch="PsychosocialAccompaniments/setLastSchoolStudied"
            :readonly="psychosocial_accompaniment?.is_studying"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'need_school_reinsertion',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.need_school_reinsertion === true,
              },
              {
                name: 'need_school_reinsertion',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.need_school_reinsertion === false,
              },
            ]"
            labelText="Necessita de reinserção escolar?"
            showLabel
            dispatch="PsychosocialAccompaniments/setNeedSchoolReinsertion"
          />
        </div>
        <div class="col-span-2 text-burnedYellow pb-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <Label
            text="Identidade de Gênero e Orientação Sexual"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Gênero"
            label-text="Gênero"
            :multiple-option="false"
            :value="gender"
            :options="genders"
            :dispatch="setGender"
          />
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Se identifica como LGBTQIAPN+"
            label-text="Se identifica como LGBTQIAPN+"
            :multiple-option="false"
            :value="identifies_as_lgbtqiapn"
            :options="identifies_as_lgbtqiapns"
            :dispatch="setidentifiesAsLgbtqiapn"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <BaseTextArea
            active
            inputName="gender_identity_observations"
            labelText="Observações"
            showLabel
            rows="4"
            :value="psychosocial_accompaniment?.gender_identity_observations"
            dispatch="PsychosocialAccompaniments/setGenderIdentityObservations"
          />
        </div>
        <div class="col-span-2 text-burnedYellow pb-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <Label
            text="Situação de Saúde"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'is_pregnant',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.is_pregnant === true,
              },
              {
                name: 'is_pregnant',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.is_pregnant === false,
              },
              // {
              //  name: 'is_pregnant',
              //  value: null,
              //  description: 'Não se aplica',
              //  checked: psychosocial_accompaniment?.is_pregnant === null,
              // },
            ]"
            labelText="Apresenta-se gestante?"
            showLabel
            dispatch="PsychosocialAccompaniments/setIsPregnant"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <BaseInput
            active
            input-name="gestational_age"
            input-type="number"
            :value="psychosocial_accompaniment?.gestational_age"
            show-label
            label-text="Idade Gestacional"
            dispatch="PsychosocialAccompaniments/setGestationalAge"
            inputMin="0"
            :readonly="!psychosocial_accompaniment?.is_pregnant"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'is_puerperium',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.is_puerperium === true,
              },
              {
                name: 'is_puerperium',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.is_puerperium === false,
              },
              // {
              //  name: 'is_puerperium',
              //  value: null,
              //  description: 'Não se aplica',
              //  checked: psychosocial_accompaniment?.is_puerperium === null,
              // },
            ]"
            labelText="Período Puerpério"
            showLabel
            dispatch="PsychosocialAccompaniments/setIsPuerperium"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'uses_psychoactive_substances',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.uses_psychoactive_substances === true,
              },
              {
                name: 'uses_psychoactive_substances',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.uses_psychoactive_substances === false,
              },
            ]"
            labelText="Faz uso de substâncias psicoativas"
            showLabel
            dispatch="PsychosocialAccompaniments/setUsesPsychoactiveSubstances"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'uses_psychoactive_substances_recreationally',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.uses_psychoactive_substances_recreationally === true,
              },
              {
                name: 'uses_psychoactive_substances_recreationally',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.uses_psychoactive_substances_recreationally === false,
              },
            ]"
            labelText="Faz uso recreativo de substâncias psicoativas"
            showLabel
            dispatch="PsychosocialAccompaniments/setUsesPsychoactiveSubstancesRecreationally"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'self_medicate',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.self_medicate === true,
              },
              {
                name: 'self_medicate',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.self_medicate === false,
              },
            ]"
            labelText="Se automedica"
            showLabel
            dispatch="PsychosocialAccompaniments/setSelfMedicate"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'show_signs_of_self_harm',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.show_signs_of_self_harm === true,
              },
              {
                name: 'show_signs_of_self_harm',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.show_signs_of_self_harm === false,
              },
            ]"
            labelText="Apresenta indícios de violência autoprovocada"
            showLabel
            dispatch="PsychosocialAccompaniments/setShowSignsOfSelfHarm"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'show_signs_of_compromised_physical_health',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.show_signs_of_compromised_physical_health === true,
              },
              {
                name: 'show_signs_of_compromised_physical_health',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.show_signs_of_compromised_physical_health === false,
              },
            ]"
            labelText="Apresenta indícios de saúde física comprometida"
            showLabel
            dispatch="PsychosocialAccompaniments/setShowSignsOfCompromisedPhysicalHealth"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'show_signs_of_compromised_mental_health',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.show_signs_of_compromised_mental_health === true,
              },
              {
                name: 'show_signs_of_compromised_mental_health',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.show_signs_of_compromised_mental_health === false,
              },
            ]"
            labelText="Apresenta indícios de saúde mental comprometida"
            showLabel
            dispatch="PsychosocialAccompaniments/setShowSignsOfCompromisedMentalHealth"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'frequently_uses_health_network',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.frequently_uses_health_network === true,
              },
              {
                name: 'frequently_uses_health_network',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.frequently_uses_health_network === false,
              },
            ]"
            labelText="Realiza com frequência atendimentos ou consultas na rede de saúde?"
            showLabel
            dispatch="PsychosocialAccompaniments/setFrequentlyUsesHealthNetwork"
          />
        </div>
        <!-- <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'is_young_mother',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.is_young_mother === true,
              },
              {
                name: 'is_young_mother',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.is_young_mother === false,
              },
            ]"
            labelText="Adolescente/jovem mãe?"
            showLabel
            dispatch="PsychosocialAccompaniments/setIsYoungMother"
          />
        </div>
        <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'is_young_father',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.is_young_father === true,
              },
              {
                name: 'is_young_father',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.is_young_father === false,
              },
            ]"
            labelText="Adolescente/jovem pai?"
            showLabel
            dispatch="PsychosocialAccompaniments/setIsYoungFather"
          />
        </div> -->
        <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <BaseInput
            active
            input-name="health_place_frequently"
            input-type="text"
            :value="psychosocial_accompaniment?.health_place_frequently"
            show-label
            label-text="Local de atendimento"
            dispatch="PsychosocialAccompaniments/setHealthPlaceFrequently"
            :readonly="!psychosocial_accompaniment?.frequently_uses_health_network"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <BaseTextArea
            active
            inputName="motivation_uses_health_frequently"
            labelText="Motivo do atendimento"
            showLabel
            rows="4"
            :value="psychosocial_accompaniment?.motivation_uses_health_frequently"
            dispatch="PsychosocialAccompaniments/setMotivationUsesHealthFrquently"
            :readonly="!psychosocial_accompaniment?.frequently_uses_health_network"
          />
        </div>
        <div class="col-span-2 mt-0" v-if="psychosocial_accompaniment?.meeting_happened">
          <div class="flex">
            <BaseLabel text="Observações" class="flex items-end" />
            <button
              ref="btnHealthSituationObservationsRef"
              v-on:mouseenter="toggleTooltip('btnHealthSituationObservationsRef')"
              v-on:mouseleave="toggleTooltip('btnHealthSituationObservationsRef')"
              class="uppercase rounded outline-none ease-linear transition-all duration-150"
            >
              <span class="material-icons mt-6 text-burnedYellow">
                <span class="material-icons">info</span>
              </span>
            </button>
          </div>
          <div
            ref="tooltipHealthSituationObservationsRef"
            v-bind:class="{
              hidden: !tooltipHealthSituationObservations,
              block: tooltipHealthSituationObservations,
            }"
            class="bg-burnedYellow border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg"
          >
            <div>
              <div
                class="text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg"
              >
                Utilizar este espaço para detalhar a situação de saúde apreendida, se faz tratamento, uso de
                medicações, etc.
              </div>
            </div>
          </div>
          <BaseTextArea
            active
            inputName="health_situation_observations"
            showLabel
            rows="4"
            :value="psychosocial_accompaniment?.health_situation_observations"
            dispatch="PsychosocialAccompaniments/setHealthSituationObservations"
          />
        </div>
        <div class="col-span-2 text-burnedYellow pb-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <Label
            text="Situação de Vulnerabilidade"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'register_vulnerability_matrix',
                value: true,
                description: 'Sim',
                checked: register_vulnerability_matrix === true,
              },
              {
                name: 'register_vulnerability_matrix',
                value: false,
                description: 'Não',
                checked: register_vulnerability_matrix === false,
              },
            ]"
            labelText="Cadastrar Matriz de Vulnerabilidade?"
            showLabel
            dispatch="PsychosocialAccompaniments/setRegisterVulnerabilityMatrix"
          />
        </div>
        <div
          v-if="
            psychosocial_accompaniment?.meeting_happened &&
            register_vulnerability_matrix &&
            !matrix_risk_stratifications_by_psychosocial
          "
        >
          <AppButtonColorful
            text="Abrir Matriz"
            typeButton="danger"
            showIcon
            iconName="add"
            iconType="outlined"
            dispatch="MatrixRiskStratifications/openEditModalMatrixRiskStratification"
          />
        </div>
        <div
          v-if="
            psychosocial_accompaniment?.meeting_happened &&
            register_vulnerability_matrix &&
            matrix_risk_stratifications_by_psychosocial
          "
        >
          <AppButtonColorful
            text="Editar Matriz"
            typeButton="danger"
            showIcon
            iconName="add"
            iconType="outlined"
            dispatch="MatrixRiskStratifications/openEditModalMatrixRiskStratification"
            :payload="matrix_risk_stratifications_by_psychosocial"
          />
        </div>
        <div
          class="col-span-1"
          v-if="
            psychosocial_accompaniment?.meeting_happened &&
            register_vulnerability_matrix &&
            (matrix_situations_array.length > 0 || matrix_risk_stratifications_by_psychosocial)
          "
        >
          <AppTextField
            show-label
            label-text="Situação de acordo com dados marcados até o momento"
            :text="getSituation()"
            color="carmesim"
            type-text="custom"
            weight="semibold"
          />
        </div>
        <div class="col-span-2 text-burnedYellow pb-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <Label
            text="Encaminhamento"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'register_forwarding',
                value: true,
                description: 'Sim',
                checked: register_forwarding === true,
              },
              {
                name: 'register_forwarding',
                value: false,
                description: 'Não',
                checked: register_forwarding === false,
              },
            ]"
            labelText="Cadastrar Encaminhamento?"
            showLabel
            dispatch="PsychosocialAccompaniments/setRegisterForwarding"
          />
        </div>
        <div class="col-span-2" v-if="register_forwarding">
          <div
            class="grid grid-cols-2 gap-4 mt-2"
            v-for="(forwarding, index) in forwarding_attributes"
            :key="index"
          >
            <div v-if="psychosocial_accompaniment?.meeting_happened">
              <Multiselect
                :close-on-select="true"
                show-label
                clear-on-select
                select-placeholder="Selecione as opções"
                label-text="Políticas de Encaminhamento"
                :multiple-option="false"
                :value="forwarding_policy_attributes[index]"
                :options="mountForwardingPolicies(index)"
                required
                :dispatch="setForwardingPolicy"
              />
            </div>
            <div v-if="psychosocial_accompaniment?.meeting_happened">
              <Multiselect
                :close-on-select="true"
                show-label
                clear-on-select
                select-placeholder="Selecione as opções"
                label-text="Equipamentos"
                :multiple-option="false"
                :value="forwarding_facilities_attributes[index]"
                :options="mountForwardingFacilities(index)"
                required
                :dispatch="setForwardingFacility"
              />
            </div>
            <div
              class="col-span-2 text-burnedYellow-classic pb-2"
              v-if="psychosocial_accompaniment?.meeting_happened"
            >
              <Label
                text="Informações de Data"
                :icon="null"
                :icon-type="null"
                type="text-xs"
                weight="semibold"
                normal
                mode="uppercase"
              />
              <div class="border-ashes-hover border-b-2 w-full"></div>
            </div>
            <div v-if="psychosocial_accompaniment?.meeting_happened">
              <BaseInput
                active
                input-name="expedition"
                input-type="date"
                :value="forwarding_attributes[index].forwarding_date"
                show-label
                required
                label-text="Data do Encaminhamento"
                :payload="{ index }"
                dispatch="PsychosocialAccompaniments/setForwardingDate"
              />
            </div>
            <div v-if="psychosocial_accompaniment?.meeting_happened">
              <BaseInput
                active
                input-name="expedition"
                input-type="date"
                :value="forwarding_attributes[index].forwarding_expiration_date"
                show-label
                required
                :payload="{ index }"
                label-text="Data de Expiração"
                dispatch="PsychosocialAccompaniments/setForwardingExpirationDate"
              />
            </div>
            <div v-if="psychosocial_accompaniment?.meeting_happened">
              <BaseInput
                active
                input-name="dif"
                input-type="number"
                :value="forwarding_attributes[index].differenceBetweenDates"
                :payload="{ index }"
                show-label
                label-text="Total de Dias entre as Datas"
                dispatch="PsychosocialAccompaniments/setForwardingDifferenceBetweenDates"
              />
            </div>
            <div class="col-span-1" v-if="psychosocial_accompaniment?.meeting_happened">
              <AppTextField
                show-label
                label-text="Data nominal de Expiração"
                :text="getFullDate(forwarding_attributes[index].forwarding_expiration_date)"
                color="carmesim"
                type-text="custom"
                weight="semibold"
              />
            </div>
            <div
              class="col-span-2 text-burnedYellow-classic pb-2"
              v-if="psychosocial_accompaniment?.meeting_happened"
            >
              <Label
                text="Descrição do Encaminhamento"
                :icon="null"
                :icon-type="null"
                type="text-xs"
                weight="semibold"
                normal
                mode="uppercase"
              />
              <div class="border-ashes-hover border-b-2 w-full"></div>
            </div>
            <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
              <BaseTextArea
                active
                inputName="description"
                labelText="Descrição"
                showLabel
                rows="4"
                :value="forwarding_attributes[index].forwarding_description"
                :payload="{ index }"
                dispatch="PsychosocialAccompaniments/setForwardingDescription"
              />
              <div
                class="mt-5"
                v-if="
                  forwarding_attributes.length > 1 &&
                  register_forwarding &&
                  psychosocial_accompaniment?.meeting_happened
                "
              >
                <a href="javascript:void(0)" class="text-burnedYellow underline" @click="deleteForwarding">
                  <AppMaterialIcon icon-name="delete" icon-size="36" class="align-middle" />
                </a>
              </div>
            </div>
          </div>
          <div
            class="col-span-4 mt-5"
            v-if="
              forwarding_attributes.length > 1 &&
              register_forwarding &&
              psychosocial_accompaniment?.meeting_happened
            "
          >
            <hr class="border-t-0 border-b-2 border-ashes opacity-50" />
          </div>
        </div>
        <div class="mt-5" v-if="register_forwarding && psychosocial_accompaniment?.meeting_happened">
          <a href="javascript:void(0)" class="text-burnedYellow" @click="addForwarding">
            <AppMaterialIcon icon-name="add_circle" icon-size="36" class="align-middle" />
            <BaseText
              text="Adicionar Encaminhamento"
              type-text="custom"
              weight="medium"
              size="sm"
              class="align-middle mr-2"
            />
          </a>
        </div>
        <div class="col-span-2 text-burnedYellow pb-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <Label
            text="Plano de Acompanhamento"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <AppRadioButton
            :values="[
              {
                name: 'accompaniment_will_continue',
                value: true,
                description: 'Sim',
                checked: psychosocial_accompaniment?.accompaniment_will_continue === true,
              },
              {
                name: 'accompaniment_will_continue',
                value: false,
                description: 'Não',
                checked: psychosocial_accompaniment?.accompaniment_will_continue === false,
              },
            ]"
            labelText="Acompanhamento vai continuar?"
            showLabel
            dispatch="PsychosocialAccompaniments/setAccompanimentWillContinue"
          />
        </div>
        <div
          v-if="
            psychosocial_accompaniment?.meeting_happened &&
            psychosocial_accompaniment?.accompaniment_will_continue
          "
        >
          <BaseInput
            active
            input-name="birthdate"
            input-type="date"
            :value="continued_accompaniment.date_accompaniment"
            show-label
            label-text="Data do Próximo Encontro"
            required
            dispatch="PsychosocialAccompaniments/setDateAccompanimentContinuedAccompaniment"
          />
        </div>
        <div
          v-if="
            psychosocial_accompaniment?.meeting_happened &&
            psychosocial_accompaniment?.accompaniment_will_continue
          "
        >
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Tipo do Atendimento"
            label-text="Tipo do Atendimento"
            :multiple-option="false"
            :value="continued_accompaniment_selected.accompaniments_type"
            :options="type_accompaniment_select"
            required
            :dispatch="setPsychosocialAccompanimentsTypeContinuedAccompaniment"
          />
        </div>
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Comentários e Conclusão do Encontro"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
        <div class="col-span-2">
          <BaseTextArea
            active
            inputName="Situação apresentada"
            labelText="Situação apresentada"
            showLabel
            rows="4"
            :value="psychosocial_accompaniment?.meeting_conclusion_description"
            dispatch="PsychosocialAccompaniments/setMeetingConclusionDescription"
          />
        </div>
        <div class="col-span-2" v-if="psychosocial_accompaniment?.meeting_happened">
          <BaseTextArea
            active
            inputName="accompaniment_comments"
            labelText="Observações técnicas sobre o
acompanhamento"
            showLabel
            rows="4"
            :value="psychosocial_accompaniment?.accompaniment_comments"
            dispatch="PsychosocialAccompaniments/setAccompanimentComments"
          />
        </div>
      </div>
      <ModalMatrixRiskStratifications :open="modalToggleMatrixRiskStratifications" />
      <SavePsychosocialAccompanimentWithoutMatrixConfirmation
        :open="modalConfirmSavePsychosocialAccompanimentWithoutMatrix"
      />
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="PsychosocialAccompaniments/closeModalFirstPsychosocialAccompaniment"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="psychosocial_accompaniment?.id"
            text="Salvar"
            typeButton="success"
            dispatch="PsychosocialAccompaniments/update"
          />
          <AppButtonEmpty
            v-else
            text="criar"
            typeButton="success"
            dispatch="PsychosocialAccompaniments/save"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import AppRadioButton from "../../components/AppRadioButton.vue";
import AppMaterialIcon from "../../components/AppMaterialIcon.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import AppTextField from "../../components/AppTextField.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseLabel from "../../components/BaseLabel.vue";
import BaseModal from "../../components/BaseModal.vue";
import BaseText from "../../components/BaseText.vue";
import BaseTextArea from "../../components/forms/BaseTextArea.vue";
import Label from "../../components/Label.vue";
import Multiselect from "../../components/forms/Multiselect.vue";
import ModalMatrixRiskStratifications from "../ModalMatrixRiskStratifications.vue";
import SavePsychosocialAccompanimentWithoutMatrixConfirmation from "../alerts/SavePsychosocialAccompanimentWithoutMatrixConfirmation.vue";
import moment from "moment";
import { mapState, mapActions } from "vuex";
import { createPopper } from "@popperjs/core";

export default {
  components: {
    AppTextField,
    AppRadioButton,
    AppMaterialIcon,
    AppButtonColorful,
    ModalMatrixRiskStratifications,
    SavePsychosocialAccompanimentWithoutMatrixConfirmation,
    BaseModal,
    BaseInput,
    BaseLabel,
    BaseText,
    BaseTextArea,
    Label,
    Multiselect,
    AppButtonIcon,
    AppButtonEmpty,
  },
  data() {
    return {
      type_accompaniment_select: [
        {
          id: 1,
          name: "Atendimento Individual",
        },
        {
          id: 2,
          name: "Visita Domiciliar",
        },
      ],
      input: 1,
      tooltipHealthSituationObservations: false,
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapState({
      inscription: (state) => state.PsychosocialAccompaniments.inscription,
      psychosocial_accompaniments: (state) => state.Forwardings.psychosocial_accompaniments,
      psychosocial_accompaniment: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniment,
      continued_accompaniment: (state) => state.PsychosocialAccompaniments.continued_accompaniment,
      housing_situations: (state) => state.PsychosocialAccompaniments.housing_situations,
      housing_situation: (state) => state.PsychosocialAccompaniments.housing_situation,
      housing_building_situations: (state) => state.PsychosocialAccompaniments.housing_building_situations,
      housing_building_situation: (state) => state.PsychosocialAccompaniments.housing_building_situation,
      housing_building_materials: (state) => state.PsychosocialAccompaniments.housing_building_materials,
      housing_building_material: (state) => state.PsychosocialAccompaniments.housing_building_material,
      housing_has_electricities: (state) => state.PsychosocialAccompaniments.housing_has_electricities,
      housing_has_electricity: (state) => state.PsychosocialAccompaniments.housing_has_electricity,
      housing_has_water_supplies: (state) => state.PsychosocialAccompaniments.housing_has_water_supplies,
      housing_has_water_supply: (state) => state.PsychosocialAccompaniments.housing_has_water_supply,
      housing_has_gas_supplies: (state) => state.PsychosocialAccompaniments.housing_has_gas_supplies,
      housing_has_gas_supply: (state) => state.PsychosocialAccompaniments.housing_has_gas_supply,
      housing_bathroom_drain_types: (state) => state.PsychosocialAccompaniments.housing_bathroom_drain_types,
      housing_bathroom_drain_type: (state) => state.PsychosocialAccompaniments.housing_bathroom_drain_type,
      meeting_not_happened_reasons: (state) => state.PsychosocialAccompaniments.meeting_not_happened_reasons,
      meeting_not_happened_reason: (state) => state.PsychosocialAccompaniments.meeting_not_happened_reason,
      socio_educational_measure_situations: (state) =>
        state.PsychosocialAccompaniments.socio_educational_measure_situations,
      socio_educational_measure_situation: (state) =>
        state.PsychosocialAccompaniments.socio_educational_measure_situation,
      genders: (state) => state.PsychosocialAccompaniments.genders,
      gender: (state) => state.PsychosocialAccompaniments.gender,
      identifies_as_lgbtqiapns: (state) => state.PsychosocialAccompaniments.identifies_as_lgbtqiapns,
      identifies_as_lgbtqiapn: (state) => state.PsychosocialAccompaniments.identifies_as_lgbtqiapn,
      register_vulnerability_matrix: (state) =>
        state.PsychosocialAccompaniments.register_vulnerability_matrix,
      accompaniment_origin: (state) => state.PsychosocialAccompaniments.accompaniment_origin,
      register_forwarding: (state) => state.PsychosocialAccompaniments.register_forwarding,
      forwarding_attributes: (state) => state.PsychosocialAccompaniments.forwarding_attributes,
      forwarding_policy_attributes: (state) => state.PsychosocialAccompaniments.forwarding_policy_attributes,
      forwarding_facilities_attributes: (state) =>
        state.PsychosocialAccompaniments.forwarding_facilities_attributes,
      facilities: (state) => state.PsychosocialAccompaniments.facilities,
      matrix_risk_stratifications_by_psychosocial: (state) =>
        state.PsychosocialAccompaniments.matrix_risk_stratifications_by_psychosocial,
      sentence_serving_situation: (state) => state.PsychosocialAccompaniments.sentence_serving_situation,

      continued_accompaniment_selected: (state) =>
        state.PsychosocialAccompaniments.continued_accompaniment_selected,

      modalToggleMatrixRiskStratifications: (state) =>
        state.MatrixRiskStratifications.modalToggleMatrixRiskStratifications,
      matrix_situations_array: (state) => state.MatrixRiskStratifications.matrix_situations_array,

      forwarding: (state) => state.Forwardings.forwarding,
      forwardingDate: (state) => state.Forwardings.forwarding.forwarding_date,
      forwardingExpirationDate: (state) => state.Forwardings.forwarding.forwarding_expiration_date,
      differenceBetweenDates: (state) => state.Forwardings.differenceBetweenDates,
      forwarding_policies: (state) => state.Forwardings.forwarding_policies,
      forwarding_policy: (state) => state.Forwardings.forwarding_policy,

      register_attendant_teams: (state) => state.PsychosocialAccompaniments.register_attendant_teams,
      attendant_teams_attributes: (state) => state.PsychosocialAccompaniments.attendant_teams_attributes,
      psychosocial_attendants_for_psychosocial: (state) =>
        state.PsychosocialAccompaniments.psychosocial_attendants_for_psychosocial,
      psychosocial_attendant_attributes: (state) =>
        state.PsychosocialAccompaniments.psychosocial_attendant_attributes,
      modalConfirmSavePsychosocialAccompanimentWithoutMatrix: (state) =>
        state.PsychosocialAccompaniments.modalConfirmSavePsychosocialAccompanimentWithoutMatrix,
    }),
  },
  methods: {
    ...mapActions({
      setHousingSituation: "PsychosocialAccompaniments/setHousingSituation",
      setHousingBuildingSituation: "PsychosocialAccompaniments/setHousingBuildingSituation",
      setHousingBuildingMaterial: "PsychosocialAccompaniments/setHousingBuildingMaterial",
      setHousingHasElectricity: "PsychosocialAccompaniments/setHousingHasElectricity",
      setHousingHasWaterSupply: "PsychosocialAccompaniments/setHousingHasWaterSupply",
      setHousingHasGasSupply: "PsychosocialAccompaniments/setHousingHasGasSupply",
      setHousingBathroomDrainType: "PsychosocialAccompaniments/setHousingBathroomDrainType",
      setMeetingNotHappenedReason: "PsychosocialAccompaniments/setMeetingNotHappenedReason",
      setSocioEducationalMeasureSituation: "PsychosocialAccompaniments/setSocioEducationalMeasureSituation",
      setPsychosocialAccompanimentsTypeContinuedAccompaniment:
        "PsychosocialAccompaniments/setPsychosocialAccompanimentsTypeContinuedAccompaniment",
      setForwardingPolicy: "PsychosocialAccompaniments/setForwardingPolicy",
      setForwardingFacility: "PsychosocialAccompaniments/setForwardingFacility",
      setPsychosocialAttendant: "PsychosocialAccompaniments/setPsychosocialAttendant",
      setGender: "PsychosocialAccompaniments/setGender",
      setidentifiesAsLgbtqiapn: "PsychosocialAccompaniments/setidentifiesAsLgbtqiapn",
      setSentenceServingSituation: "PsychosocialAccompaniments/setSentenceServingSituation",
    }),
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
    verifyMeetingHappened() {
      return this.psychosocial_accompaniment.meeting_happened ? "Sim" : "Não";
    },
    verifyIsForwarded() {
      return this.psychosocial_accompaniment.forwarded ? "Sim" : "Não";
    },
    verifyStatus() {
      return this.$util_function.processInscriptionStatuses(this.inscription?.status);
    },
    verifyInscription(type) {
      if (this.inscription.student) {
        if (type === "cpf" && this.inscription.student.cpf) {
          return this.inscription.student.cpf;
        } else if (type === "birthdate" && this.inscription.student.birthdate) {
          return this.formattedDate(this.inscription.student.birthdate);
        } else if (type === "cellphone_number" && this.inscription.student.cellphone_number) {
          return this.inscription.student.cellphone_number;
        } else if (type === "age" && this.inscription.student.age) {
          return this.inscription.student.age + " anos";
        } else if (type === "equipment" && this.inscription.student.equipment) {
          return this.inscription.student.equipment.name;
        }
      }
    },
    getFullDate(date) {
      moment.locale("pt");
      return moment(date).format("LLLL");
    },
    addForwarding() {
      this.$store.dispatch("PsychosocialAccompaniments/addForwarding");
    },
    deleteForwarding() {
      this.$store.dispatch("PsychosocialAccompaniments/deleteForwarding");
    },
    addAttendantTeam() {
      this.$store.dispatch("PsychosocialAccompaniments/addAttendantTeam");
    },
    deleteAttendantTeam() {
      this.$store.dispatch("PsychosocialAccompaniments/deleteAttendantTeam");
    },
    mountForwardingPolicies(index) {
      return this.forwarding_policies.map((forwarding_policy) => {
        return { index, ...forwarding_policy };
      });
    },
    mountPsychosocialAttendants(index) {
      return this.psychosocial_attendants_for_psychosocial.map((psychosocial_attendant) => {
        return { index, ...psychosocial_attendant };
      });
    },
    mountForwardingFacilities(index) {
      let response = [];
      this.facilities.forEach((item, i) => {
        if (index == i) {
          if (item.facilities) {
            item.facilities.map((facility) => {
              response.push({ index, ...facility });
            });
          }
        }
      });
      return response;
    },
    getSituation() {
      let situations_risk = [];
      if (this.matrix_situations_array.length > 0) {
        this.matrix_situations_array.forEach((item) => {
          situations_risk.push(item.situation_risk);
        });

        if (situations_risk.includes("Gravíssima")) {
          return "Gravíssima";
        } else if (situations_risk.includes("Grave")) {
          return "Grave";
        } else {
          return "Moderada";
        }
      } else if (
        this.matrix_risk_stratifications_by_psychosocial.matrix_risk_stratifications &&
        this.matrix_risk_stratifications_by_psychosocial.matrix_risk_stratifications.length > 0
      ) {
        this.matrix_risk_stratifications_by_psychosocial.matrix_risk_stratifications.forEach((item) => {
          situations_risk.push(item.situation_risk);
        });

        if (situations_risk.includes("Gravíssima")) {
          return "Gravíssima";
        } else if (situations_risk.includes("Grave")) {
          return "Grave";
        } else {
          return "Moderada";
        }
      } else {
        return "-";
      }
    },
    getIdentifiesAsLgbtqiapn() {
      if (this.inscription.student.identifies_as_lgbtqiapn) {
        return this.inscription.student.identifies_as_lgbtqiapn;
      } else {
        return "Não informado no Pré-Cadastro";
      }
    },
    toggleTooltip: function (type) {
      switch (type) {
        case "btnHealthSituationObservationsRef":
          if (this.tooltipHealthSituationObservations) {
            this.tooltipHealthSituationObservations = false;
          } else {
            this.tooltipHealthSituationObservations = true;
            createPopper(
              this.$refs.btnHealthSituationObservationsRef,
              this.$refs.tooltipHealthSituationObservationsRef,
              {
                placement: "left",
              }
            );
          }
          break;
      }
    },
  },
};
</script>
<style>
.tooltip-text {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  top: -1.5rem;
  left: 50%;
  transform: translateX(-50%);
  background-color: #000;
  color: #fff;
  font-size: 0.75rem;
  padding: 0.5rem;
  border-radius: 0.25rem;
  z-index: 1;
  transition: opacity 0.3s;
}

.opacity-100 {
  visibility: visible;
  opacity: 1;
}
</style>
