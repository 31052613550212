var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", [
    _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.payment_sheet.status
                ? _vm.payment_sheet.status.name
                : "-",
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.payment_sheet.process_number
                ? _vm.payment_sheet.process_number
                : "-",
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.payment_sheet.reference
                ? _vm.payment_sheet.reference
                : "-",
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.payment_sheet.total_beneficiaries
                ? _vm.payment_sheet.total_beneficiaries
                : "-",
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.payment_sheet.total_payment_value
                ? _vm.payment_sheet.total_payment_value
                : "-",
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4 flex" },
        [
          _vm.permitUpdate
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "edit",
                  payload: _vm.payment_sheet,
                  dispatch: "PaymentSheets/openEditModalAddPaymentSheet",
                  title: "Atualizar Folha de Pagamento",
                },
              })
            : _vm._e(),
          _vm.permitDelete
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "delete",
                  payload: _vm.payment_sheet,
                  dispatch: "PaymentSheets/toggleModalDeletePaymentSheet",
                  title: "Deletar Folha de Pagamento",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }