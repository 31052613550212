var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-184", headerColor: "#f1f1f1" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "flex justify-between mb-4" }, [
                    _c(
                      "div",
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "Matriz geral de cálculo de valores da bolsa",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "normal",
                            size: "2xl",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch: "Benefits/toggleModalMatrixInfoOpen",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-4 gap-4 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "% de frequência",
                            typeText: "custom",
                            weight: "bold",
                            mode: "uppercase",
                            size: "lg",
                            color: "burnedYellow",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "FASE 1",
                            typeText: "custom",
                            weight: "bold",
                            mode: "uppercase",
                            size: "lg",
                            color: "burnedYellow",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "FASE 2",
                            typeText: "custom",
                            weight: "bold",
                            mode: "uppercase",
                            size: "lg",
                            color: "burnedYellow",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "FASE 3",
                            typeText: "custom",
                            weight: "bold",
                            mode: "uppercase",
                            size: "lg",
                            color: "burnedYellow",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass:
                        "border-ashes-hover border-b-2 w-full col-span-4",
                    }),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "Abaixo de 25%",
                            typeText: "custom",
                            weight: "bold",
                            mode: "normal",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "-",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "-",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "-",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass:
                        "border-ashes-hover border-b-2 w-full col-span-4",
                    }),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "Entre 25% e 50%",
                            typeText: "custom",
                            weight: "bold",
                            mode: "normal",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "R$ 100,00",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "R$ 150,00",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "R$ 200,00",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass:
                        "border-ashes-hover border-b-2 w-full col-span-4",
                    }),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "Entre 50% e 75%",
                            typeText: "custom",
                            weight: "bold",
                            mode: "normal",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "R$ 150,00",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "R$ 200,00",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "R$ 250,00",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c("div", {
                      staticClass:
                        "border-ashes-hover border-b-2 w-full col-span-4",
                    }),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "A partir de 75%",
                            typeText: "custom",
                            weight: "bold",
                            mode: "normal",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "R$ 200,00",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "R$ 250,00",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "flex justify-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "R$ 300,00",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "lg",
                            color: "quantum",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2906835903
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }