var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-5" }, [
    _c(
      "div",
      {
        staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
        attrs: { id: "person-data" },
      },
      [
        _c(
          "div",
          {
            class: [
              { active_new: _vm.forwardingData },
              "tab_new",
              "col-span-2",
            ],
          },
          [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.openTab("forwarding_data")
                  },
                },
              },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Dados do Encaminhamento",
                    "type-text": "custom",
                    weight: "semibold",
                    color: "burnedYellow-classic",
                    size: "lg",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            ),
          ]
        ),
        _vm.permitShowHistoric
          ? _c(
              "div",
              {
                class: [
                  { active_new: _vm.forwardingPostponementData },
                  "tab_new",
                  "col-span-3",
                ],
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openTab("forwarding_postponemente_data")
                      },
                    },
                  },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Histórico de Postergação",
                        "type-text": "custom",
                        weight: "semibold",
                        color: "burnedYellow-classic",
                        size: "lg",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "border-t-0 border-b-2 border-ashes opacity-50",
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Dados do Aluno(a)",
                    "type-text": "title-forms",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Nome do Aluno(a)",
                    text: _vm.inscription.student.name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Contato",
                    text: _vm.inscription.student.cellphone_number,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Email",
                    text: _vm.verifyEmail(),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Edição",
                    text: _vm.inscription.season.name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Status do Aluno(a)",
                    text: _vm.verifyStatus(),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Equipamento",
                    text: _vm.verifyEquipment(),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Dados do Encaminhamento",
                    "type-text": "title-forms",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Data do Cadastro do Encaminhamento",
                    text: _vm.formattedDate(_vm.forwarding.forwarding_date),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Data de Expiração Atual",
                    text: _vm.formattedDate(
                      _vm.forwarding.forwarding_expiration_date
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Política de Encaminhamento",
                    text: _vm.forwarding_policy.name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Status Atual",
                    text: _vm.forwarding_status.name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-4" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Equipamento do Encaminhamento",
                    text: _vm.facility.name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-4" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Endereço do Equipamento",
                    text: _vm.facility.address,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-3" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Cadastrado pelo Usuário(a)",
                    text: _vm.forwarding_user.name,
                    "type-text": "topic-details",
                    classe: "break-words",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Descrição",
                    text: _vm.forwarding.forwarding_description,
                    "type-text": "topic-details",
                    classe: "break-words",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwarding.forwarding_return_date && _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Dados de Retorno",
                    "type-text": "title-forms",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwarding.forwarding_return_date && _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Data do Retorno Atual",
                    text: _vm.formattedDate(
                      _vm.forwarding.forwarding_return_date
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwarding_return_user && _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Usuário(a) responsável pelo retorno",
                    text: _vm.forwarding_return_user.name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwarding.forwarding_return_date && _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Status Atual",
                    text: _vm.forwarding_status.name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwarding_return_reason_of_unrealized && _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Motivo da não efetivação",
                    text: _vm.forwarding_return_reason_of_unrealized.name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.forwarding.forwarding_return_date && _vm.forwardingData
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Observações",
                    text: _vm.forwarding.forwarding_return_comments,
                    "type-text": "topic-details",
                    classe: "break-words",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _vm.forwardingData
      ? _c("div", [
          this.psychosocial_accompaniment
            ? _c(
                "div",
                { staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12" },
                [
                  _c(
                    "div",
                    { staticClass: "col-span-5" },
                    [
                      _c("BaseText", {
                        attrs: {
                          text: "Dados do Acompanhamento Psicossocial",
                          "type-text": "title-forms",
                        },
                      }),
                      _c("hr", {
                        staticClass:
                          "border-t-0 border-b-2 border-ashes opacity-50",
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-1" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "Data do Acompanhamento",
                          text: _vm.formattedDate(
                            _vm.psychosocial_accompaniment.date_accompaniment
                          ),
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-1" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "Tipo do Atendimento",
                          text: _vm.psychosocial_accompaniment
                            .accompaniment_type.name,
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-1" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "Status do Acompanhamento",
                          text: _vm.psychosocial_accompaniment
                            .accompaniment_status.name,
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-1" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "Encontro aconteceu?",
                          text: _vm.verifyMeetingHappened(),
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-1" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "Formato do Encontro",
                          text: _vm.psychosocial_accompaniment.meeting_format,
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.psychosocial_accompaniment.meeting_format != "Remoto"
                    ? _c(
                        "div",
                        { staticClass: "col-span-1" },
                        [
                          _c("AppTextField", {
                            attrs: {
                              "show-label": "",
                              "label-text": "Local do Encontro",
                              text: _vm.psychosocial_accompaniment
                                .meeting_place,
                              "type-text": "topic-details",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.psychosocial_accompaniment.meeting_format != "Presencial"
                    ? _c(
                        "div",
                        { staticClass: "col-span-1" },
                        [
                          _c("AppTextField", {
                            attrs: {
                              "show-label": "",
                              "label-text": "Recurso do Encontro Remoto",
                              text: _vm.psychosocial_accompaniment
                                .meeting_remote_resource,
                              "type-text": "topic-details",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]
              )
            : _vm._e(),
        ])
      : _vm._e(),
    _vm.forwardingPostponementData &&
    _vm.forwarding_postponement_histories_by_forwarding.length > 0 &&
    _vm.permitShowHistoric
      ? _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("BaseText", {
              attrs: { text: "Dados do Histórico", "type-text": "title-forms" },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.forwardingPostponementData &&
    _vm.forwarding_postponement_histories_by_forwarding.length > 0 &&
    _vm.permitShowHistoric
      ? _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("ForwardingPostponementHistoriesByForwardingCards", {
              attrs: {
                data: _vm.forwarding_postponement_histories_by_forwarding,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.forwardingPostponementData &&
    _vm.forwarding_postponement_histories_by_forwarding.length < 1 &&
    _vm.permitShowHistoric
      ? _c("div", [
          _c(
            "div",
            { staticClass: "col-span-5" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text":
                    "Não há Histórico de Postergação para esse Encaminhamento.",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }