<template>
  <div :class="cardCity">
    <BaseText :text="name" typeText="custom" weight="normal" :italic="false" mode="capitalize" />
  </div>
</template>

<script>
import BaseText from "./BaseText.vue";

export default {
  components: {
    BaseText,
  },

  props: {
    name: String,
    dispatch: String,
  },

  computed: {
    cardCity() {
      return {
        "rounded-full": true,
        "h-40": true,
        "w-40": true,
        "border-black": true,
        flex: true,
        "items-center": true,
        "justify-center": true,
        "text-herbal-hover": true,
        "bg-white": true,
        transition: true,
        transform: true,
        "hover:scale-110": true,
        "hover:bg-herbal-hover": true,
        "hover:text-white": true,
        "cursor-pointer": true,
      };
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 45% 15% 10% 15% 10%;
}
.min-w-1 {
  min-width: 3rem;
}
</style>
