var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", [
    _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.getArea(),
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.profile_search.student.equipment
                ? _vm.profile_search.student.equipment.name
                : "-",
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.profile_search.neighborhood
                ? _vm.profile_search.neighborhood.name
                : "-",
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.profile_search.student.name,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.profile_search.student.age,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.profile_search.student.completed_series
                ? _vm.profile_search.student.completed_series.name
                : "-",
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.profile_search.student.course.name
                ? _vm.profile_search.student.course.name
                : "-",
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.getForwardingQuantityStatus(),
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          !_vm.hasResume()
            ? _c("BaseText", {
                attrs: {
                  text: "Não",
                  mode: "normal-case",
                  color: "quantum",
                  typeText: "custom",
                  weight: "normal",
                  size: "base",
                },
              })
            : _c("AppButtonIcon", {
                attrs: {
                  iconName: "download",
                  payload: _vm.profile_search.resume_document_id,
                  dispatch: "ModalInscription/downloadResume",
                },
              }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }