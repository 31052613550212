var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-2 items-center" }, [
        _c(
          "div",
          { staticClass: "ml-20" },
          [
            _c("BaseText", {
              attrs: {
                text: "Nome",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c("div", [
          _c("div", { staticClass: "grid grid-cols-2" }, [
            _c(
              "div",
              { staticClass: "justify-self-end" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Nº de Encontros",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "justify-self-center" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Período",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "ashes-dark",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "BaseStaggeredFade",
        { attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.data, function (item, index) {
          return _c("li", { key: item.id, attrs: { "data-index": index } }, [
            _c(
              "div",
              [
                _c("AppCardAllocationBenefitTeam", {
                  attrs: {
                    team: item,
                    benefit_type: item.benefit_type,
                    dispatch: "ModalAllocationTeamBenefit/addTeamsAllocation",
                  },
                }),
              ],
              1
            ),
            _c("div", { staticClass: "border-ashes w-full border" }),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }