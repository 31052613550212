var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    { class: _vm.sidebar, attrs: { id: "sidebar" } },
    [
      _vm._t("default", function () {
        return [
          _c("img", {
            staticClass: "pl-10 pt-10 pb-5",
            attrs: { src: require("../assets/images/logo.png"), alt: "" },
          }),
        ]
      }),
      _c(
        "BaseStaggeredFade",
        {
          class: _vm.menusClass,
          attrs: { id: "menus", duration: 10, tag: "ul" },
        },
        _vm._l(_vm.menus, function (menu, index) {
          return _c("div", { key: menu.id }, [
            _c(
              "div",
              {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.checkMenu(menu.name),
                    expression: "checkMenu(menu.name)",
                  },
                ],
                key: menu.id,
                attrs: { "data-index": index, id: menu.id },
              },
              [
                menu.show
                  ? _c("li", { class: _vm.menuClass }, [
                      _c(
                        "a",
                        {
                          class: _vm.button(menu.id),
                          attrs: { id: menu.id + "-button", href: "/#/" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.toggleSubmenu(menu.id)
                            },
                          },
                        },
                        [
                          _c("AppMaterialIcon", {
                            staticStyle: { "margin-left": "15px" },
                            attrs: {
                              iconName: menu.iconName,
                              iconType: menu.iconType,
                              iconSize: "24",
                            },
                          }),
                          _c("Label", {
                            staticClass: "mx-3",
                            attrs: { text: menu.name, weight: "semibold" },
                          }),
                          _c("Label", {
                            class: [_vm.angle, _vm.toggleAnimate(menu.id)],
                            attrs: { icon: "angle-down", iconType: "solid" },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "ul",
                        {
                          class: _vm.submenus(menu.id),
                          attrs: { id: menu.id + "-submenus" },
                        },
                        _vm._l(menu.submenus, function (submenu) {
                          return _c(
                            "li",
                            {
                              directives: [
                                {
                                  name: "show",
                                  rawName: "v-show",
                                  value: _vm.checkSubMenu(
                                    menu.name,
                                    submenu.name
                                  ),
                                  expression:
                                    "checkSubMenu(menu.name, submenu.name)",
                                },
                              ],
                              key: submenu.id,
                              attrs: { id: submenu.id },
                            },
                            [
                              _c(
                                "router-link",
                                {
                                  class: _vm.linkSubmenu,
                                  attrs: { to: submenu.url },
                                },
                                [
                                  _c("Label", {
                                    staticClass: "mx-5",
                                    attrs: {
                                      text: submenu.name,
                                      icon: "angle-right",
                                      iconType: "solid",
                                      weight: "semibold",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        0
                      ),
                    ])
                  : _vm._e(),
              ]
            ),
          ])
        }),
        0
      ),
      _vm.permitChangeCity()
        ? _c(
            "div",
            { class: _vm.bottonMenu, on: { click: _vm.changeCity } },
            [
              _c("Label", {
                staticClass: "mx-5",
                attrs: {
                  text: "Trocar de Cidade",
                  icon: "angle-right",
                  iconType: "solid",
                  weight: "semibold",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      !_vm.city
        ? _c("CitiesCards", {
            attrs: { cities: _vm.cities, dispatch: "City/setCity" },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }