var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-5" }, [
    _c(
      "div",
      {
        staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
        attrs: { id: "student-data" },
      },
      [
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Dados do Aluno(a)",
                "type-text": "custom",
                weight: "semibold",
                color: _vm.getStyles("headerColor"),
                size: "lg",
              },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _vm.inscription
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Nome do Aluno(a)",
                    text: _vm.inscription?.student?.name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.inscription
          ? _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "CPF",
                    text: _vm._f("VMask")(
                      _vm.inscription?.student?.cpf,
                      "###.###.###-##"
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.inscription
          ? _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Data de Nascimento",
                    text: _vm.formattedDate(
                      _vm.inscription?.student?.birthdate
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.inscription
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Email",
                    text: _vm.inscription?.student?.email,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.inscription
          ? _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Telefone",
                    text: _vm._f("VMask")(
                      _vm.inscription?.student?.cellphone_number,
                      "(##) #####-####"
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
        attrs: { id: "psychosocial-title" },
      },
      [
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Dados do Acompanhamento",
                "type-text": "custom",
                weight: "semibold",
                color: _vm.getStyles("headerColor"),
                size: "lg",
              },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      {
        staticClass: "grid grid-cols-8 gap-x-1 gap-y-6 mb-12",
        attrs: { id: "psychosocial-titles" },
      },
      [
        _c(
          "div",
          { class: [{ active_new: _vm.generalData }, "tab_new", "col-span-1"] },
          [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.openTab("generalData")
                  },
                },
              },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Dados gerais",
                    "type-text": "custom",
                    weight: "medium",
                    color: _vm.getStyles("colorText"),
                    mode: "uppercase",
                    size: "sm",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            ),
          ]
        ),
        _vm.psychosocial_accompaniment?.meeting_happened &&
        _vm.accompaniments_type?.id === 2
          ? _c(
              "div",
              {
                class: [
                  { active_new: _vm.socialContext },
                  "tab_new",
                  "col-span-1",
                ],
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openTab("socialContext")
                      },
                    },
                  },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Contextos Sociais",
                        "type-text": "custom",
                        weight: "medium",
                        color: _vm.getStyles("colorText"),
                        mode: "uppercase",
                        size: "sm",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "border-t-0 border-b-2 border-ashes opacity-50",
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.psychosocial_accompaniment?.meeting_happened
          ? _c(
              "div",
              {
                class: [
                  { active_new: _vm.housingData },
                  "tab_new",
                  "col-span-1",
                ],
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openTab("housingData")
                      },
                    },
                  },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Moradia",
                        "type-text": "custom",
                        weight: "medium",
                        color: _vm.getStyles("colorText"),
                        mode: "uppercase",
                        size: "sm",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "border-t-0 border-b-2 border-ashes opacity-50",
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.psychosocial_accompaniment?.meeting_happened
          ? _c(
              "div",
              {
                class: [
                  { active_new: _vm.socioEducationalData },
                  "tab_new",
                  "col-span-1",
                ],
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openTab("socioEducationalData")
                      },
                    },
                  },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "SocioEducativo",
                        "type-text": "custom",
                        weight: "medium",
                        color: _vm.getStyles("colorText"),
                        mode: "uppercase",
                        size: "sm",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "border-t-0 border-b-2 border-ashes opacity-50",
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.psychosocial_accompaniment?.meeting_happened
          ? _c(
              "div",
              {
                class: [
                  { active_new: _vm.sentenceServingData },
                  "tab_new",
                  "col-span-1",
                ],
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openTab("sentenceServingData")
                      },
                    },
                  },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Cumprim. Pena",
                        "type-text": "custom",
                        weight: "medium",
                        color: _vm.getStyles("colorText"),
                        mode: "uppercase",
                        size: "sm",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "border-t-0 border-b-2 border-ashes opacity-50",
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.psychosocial_accompaniment?.meeting_happened
          ? _c(
              "div",
              {
                class: [
                  { active_new: _vm.studyingData },
                  "tab_new",
                  "col-span-1",
                ],
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openTab("studyingData")
                      },
                    },
                  },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Situação Escolar",
                        "type-text": "custom",
                        weight: "medium",
                        color: _vm.getStyles("colorText"),
                        mode: "uppercase",
                        size: "sm",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "border-t-0 border-b-2 border-ashes opacity-50",
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.psychosocial_accompaniment?.meeting_happened
          ? _c(
              "div",
              {
                class: [
                  { active_new: _vm.genderIdentityData },
                  "tab_new",
                  "col-span-1",
                ],
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openTab("genderIdentityData")
                      },
                    },
                  },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Identid. de Gênero",
                        "type-text": "custom",
                        weight: "medium",
                        color: _vm.getStyles("colorText"),
                        mode: "uppercase",
                        size: "sm",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "border-t-0 border-b-2 border-ashes opacity-50",
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.psychosocial_accompaniment?.meeting_happened
          ? _c(
              "div",
              {
                class: [
                  { active_new: _vm.healthData },
                  "tab_new",
                  "col-span-1",
                ],
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openTab("healthData")
                      },
                    },
                  },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Saúde",
                        "type-text": "custom",
                        weight: "medium",
                        color: _vm.getStyles("colorText"),
                        mode: "uppercase",
                        size: "sm",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "border-t-0 border-b-2 border-ashes opacity-50",
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.psychosocial_accompaniment?.meeting_happened
          ? _c(
              "div",
              {
                class: [
                  { active_new: _vm.forwardingData },
                  "tab_new",
                  "col-span-1",
                ],
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openTab("forwardingData")
                      },
                    },
                  },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Encaminhamento",
                        "type-text": "custom",
                        weight: "medium",
                        color: _vm.getStyles("colorText"),
                        mode: "uppercase",
                        size: "sm",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "border-t-0 border-b-2 border-ashes opacity-50",
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _vm.psychosocial_accompaniment.meeting_happened
          ? _c(
              "div",
              {
                class: [
                  { active_new: _vm.accompanimentPlanData },
                  "tab_new",
                  "col-span-1",
                ],
              },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.openTab("accompanimentPlanData")
                      },
                    },
                  },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Acompanhamento",
                        "type-text": "custom",
                        weight: "medium",
                        color: _vm.getStyles("colorText"),
                        mode: "uppercase",
                        size: "sm",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "border-t-0 border-b-2 border-ashes opacity-50",
                    }),
                  ],
                  1
                ),
              ]
            )
          : _vm._e(),
        _c(
          "div",
          {
            class: [
              { active_new: _vm.conclusionData },
              "tab_new",
              "col-span-1",
            ],
          },
          [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.openTab("conclusionData")
                  },
                },
              },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Conclusão",
                    "type-text": "custom",
                    weight: "medium",
                    color: _vm.getStyles("colorText"),
                    mode: "uppercase",
                    size: "sm",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            ),
          ]
        ),
      ]
    ),
    _vm.generalData
      ? _c(
          "div",
          {
            staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
            attrs: { id: "psychosocial-general-data" },
          },
          [
            _vm.generalData
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Data",
                        text: _vm.formattedDate(
                          _vm.psychosocial_accompaniment.date_accompaniment
                        ),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Tipo do Atendimento",
                        text: _vm.accompaniments_type.name,
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Status do Acompanhamento",
                        text: _vm.accompaniment_status.name,
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Encontro aconteceu?",
                        text: _vm.verifyMeetingHappened(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData &&
            _vm.psychosocial_accompaniment.meeting_happened &&
            _vm.accompaniments_type.id !== 2
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Formato do Encontro",
                        text: _vm.psychosocial_accompaniment.meeting_format,
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            (_vm.generalData &&
              _vm.psychosocial_accompaniment.meeting_happened &&
              _vm.psychosocial_accompaniment.meeting_format === "Presencial") ||
            _vm.accompaniments_type.id === 2
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": _vm.getLocalName(),
                        text: _vm.getMeetingPlace(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData &&
            _vm.psychosocial_accompaniment.meeting_happened &&
            _vm.psychosocial_accompaniment.meeting_format === "Remoto" &&
            _vm.accompaniments_type.id !== 2
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Recurso do Encontro Remoto",
                        text: _vm.getMeetingRemoteResource(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData &&
            _vm.psychosocial_accompaniment.meeting_happened &&
            _vm.accompaniments_type.id === 2
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Objetivo da Visita Familiar",
                        text: _vm.getPurposeHomeVisit(),
                        "type-text": "topic-details",
                        classe: "break-words",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData && _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Profissionais que acompanham",
                        "type-text": "custom",
                        weight: "medium",
                        color: _vm.getStyles("colorText"),
                        mode: "uppercase",
                        size: "sm",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "border-t-0 border-b-2 border-ashes opacity-50",
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData &&
            _vm.psychosocial_accompaniment.meeting_happened &&
            _vm.attendant_teams.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "col-span-5",
                    attrs: { id: "attendant-team-data" },
                  },
                  _vm._l(_vm.attendant_teams, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "grid grid-cols-5 gap-x-1 mb-6",
                        attrs: { "data-index": index },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Nome do Profissional",
                                text: item.name,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Código CRESS ou CRP",
                                text: item.number_cress_crp,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._m(0, true),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.generalData &&
            _vm.psychosocial_accompaniment.meeting_happened &&
            _vm.attendant_teams.length <= 0
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "col-span-5" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "Não Informado.",
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.generalData &&
            _vm.psychosocial_accompaniment.meeting_happened &&
            _vm.accompaniments_type.id === 2
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Participantes Presentes",
                        "type-text": "custom",
                        weight: "medium",
                        color: _vm.getStyles("colorText"),
                        mode: "uppercase",
                        size: "sm",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "border-t-0 border-b-2 border-ashes opacity-50",
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData &&
            _vm.psychosocial_accompaniment.meeting_happened &&
            _vm.participant_presences.length > 0 &&
            _vm.accompaniments_type.id === 2
              ? _c(
                  "div",
                  {
                    staticClass: "col-span-5",
                    attrs: { id: "participant-presence-data" },
                  },
                  _vm._l(_vm.participant_presences, function (item, index) {
                    return _c(
                      "div",
                      {
                        key: item.id,
                        staticClass: "grid grid-cols-5 gap-x-1 mb-6",
                        attrs: { "data-index": index },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Nome da Pessoa",
                                text: item.name,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Grau de Parentesco",
                                text: item.relationship_degree.name,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._m(1, true),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
            _vm.generalData &&
            _vm.psychosocial_accompaniment.meeting_happened &&
            _vm.participant_presences.length <= 0 &&
            _vm.accompaniments_type.id === 2
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "col-span-5" },
                    [
                      _c("AppTextField", {
                        attrs: {
                          "show-label": "",
                          "label-text": "Não Informado.",
                          "type-text": "topic-details",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _vm.generalData && !_vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Motivo do encontro não ter ocorrido",
                        text: _vm.getMeetingNotHappenedReason(),
                        "type-text": "topic-details",
                        classe: "break-words",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData && _vm.accompaniment_origin
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Informações de Atendimento de Origem",
                        "type-text": "custom",
                        weight: "medium",
                        color: _vm.getStyles("colorText"),
                        mode: "uppercase",
                        size: "sm",
                      },
                    }),
                    _c("hr", {
                      staticClass:
                        "border-t-0 border-b-2 border-ashes opacity-50",
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData && _vm.accompaniment_origin
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Data do Encontro",
                        text: _vm.formattedDate(
                          _vm.accompaniment_origin.date_accompaniment
                        ),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData && _vm.accompaniment_origin
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Tipo do Atendimento",
                        text: _vm.accompaniment_origin.accompaniment_type.name,
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData && _vm.accompaniment_origin
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Status do Acompanhamento",
                        text: _vm.accompaniment_origin.accompaniment_status
                          .name,
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.generalData && _vm.accompaniment_origin
              ? _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Usuário responsável pelo cadastro",
                        text: _vm.accompaniment_origin.user.name,
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm.socialContext
      ? _c(
          "div",
          {
            staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
            attrs: { id: "social-context" },
          },
          [
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text":
                          "Contexto familiar, socioeconômico e comunitário",
                        text: _vm.getSocialFamilyContext(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm.housingData
      ? _c(
          "div",
          {
            staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
            attrs: { id: "housing-data" },
          },
          [
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Situação de Moradia",
                        text: _vm.getHousingSituationName(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Quantidade de Residentes",
                        text: _vm.getNumberOfResidents(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Tem saneamento básico?",
                        text: _vm.verifyIfHasLatrine(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Tem banheiro?",
                        text: _vm.verifyIfHasBathroom(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Tem coleta de lixo?",
                        text: _vm.verifyIfHasGarbageCollection(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Situação do Imóvel",
                        text: _vm.getHousingBuildingSituationName(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Estrutura do Imóvel",
                        text: _vm.getHousingBuildingMaterialName(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Fornecimento de Energia",
                        text: _vm.getHousingEletricityName(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Fornecimento de Água",
                        text: _vm.getHousingWaterSupplyName(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Fornecimento de Gás",
                        text: _vm.getHousingGasSupplyName(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Comentários sobre situação de moradia",
                        text: _vm.getHousingSituationComments(),
                        "type-text": "topic-details",
                        classe: "break-words",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm.socioEducationalData && _vm.psychosocial_accompaniment.meeting_happened
      ? _c(
          "div",
          {
            staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
            attrs: { id: "socio-educational-data" },
          },
          [
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text":
                          "Situação do adolescente/jovem em relação ao cumprimento de medidas socioeducativas",
                        text: _vm.getSocioEducationalMeasureSituationName(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-1" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Meio Aberto?",
                        text: _vm.verifyIfIsHalfOpenSocioEducationalMeasure(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-1" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Meio Fechado?",
                        text: _vm.verifyIfIsHalfClosedSocioEducationalMeasure(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm.sentenceServingData && _vm.psychosocial_accompaniment.meeting_happened
      ? _c(
          "div",
          {
            staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
            attrs: { id: "sentence-serving-data" },
          },
          [
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text":
                          "Situação do adolescente/jovem em relação ao cumprimento de pena",
                        text: _vm.getSentenceServingSituationName(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-1" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Meio Aberto?",
                        text: _vm.verifyIfIsHalfOpenSentenceServingSituation(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-1" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Meio Fechado?",
                        text: _vm.verifyIfIsHalfClosedSentenceServingSituation(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm.studyingData && _vm.psychosocial_accompaniment.meeting_happened
      ? _c(
          "div",
          {
            staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
            attrs: { id: "studying-data" },
          },
          [
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Está estudando?",
                        text: _vm.verifyIfIsStudying(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened &&
            !_vm.psychosocial_accompaniment.is_studying
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Razão para não estar estudando",
                        text: _vm.getReasonForNotStudying(),
                        "type-text": "topic-details",
                        classe: "break-words",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened &&
            !_vm.psychosocial_accompaniment.is_studying
              ? _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Última série estudada",
                        text: _vm.getLastGradeStudied(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened &&
            !_vm.psychosocial_accompaniment.is_studying
              ? _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Última escola em que estudou",
                        text: _vm.getLastSchoolStudied(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Necessita de Reinserção Escolar?",
                        text: _vm.verifyIfNeedsSchoolReinsertion(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm.genderIdentityData && _vm.psychosocial_accompaniment.meeting_happened
      ? _c(
          "div",
          {
            staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
            attrs: { id: "health-data" },
          },
          [
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Gênero",
                        text: _vm.getGender(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Se identifica como LGBTQIAPN+",
                        text: _vm.verifyIfIdentifiesAsLgbtqiapn(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Observações",
                        text: _vm.getGenderIdentityObservations(),
                        "type-text": "topic-details",
                        classe: "break-words",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm.healthData && _vm.psychosocial_accompaniment.meeting_happened
      ? _c(
          "div",
          {
            staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
            attrs: { id: "health-data" },
          },
          [
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Apresenta-se gestante?",
                        text: _vm.verifyIfIsPregnant(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened &&
            _vm.psychosocial_accompaniment.is_pregnant
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Idade Gestacional",
                        text: _vm.psychosocial_accompaniment.gestational_age,
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Período Puerpério",
                        text: _vm.verifyIfIsPuerperium(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Faz uso de substâncias psicoativas",
                        text: _vm.verifyIfUsesPsychoactiveSubstances(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text":
                          "Faz uso recreativo de substâncias psicoativas",
                        text: _vm.verifyIfUsesPsychoactiveSubstancesRecreationally(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Se automedica",
                        text: _vm.verifyIfSelfMedicate(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text":
                          "Apresenta indícios de violência autoprovocada",
                        text: _vm.verifyIfShowSignsOfSelfHarm(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text":
                          "Apresenta indícios de saúde física comprometida",
                        text: _vm.verifyIfShowSignsOfCompromisedPhysicalHealth(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text":
                          "Apresenta indícios de saúde mental comprometida",
                        text: _vm.verifyIfShowSignsOfCompromisedMentalHealth(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-3" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text":
                          "Realiza com frequência atendimentos ou consultas na rede de saúde?",
                        text: _vm.verifyIfIsFrequentlyUserHealthNetwork(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened &&
            _vm.psychosocial_accompaniment.frequently_uses_health_network
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Local de atendimento",
                        text: _vm.getMotivationHealthPlaceFrequently(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened &&
            _vm.psychosocial_accompaniment.frequently_uses_health_network
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Motivo do atendimento",
                        text: _vm.getMotivationUsesHealthFrequently(),
                        "type-text": "topic-details",
                        classe: "break-words",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Observações",
                        text: _vm.getHealthSituationObservations(),
                        "type-text": "topic-details",
                        classe: "break-words",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm.forwardingData && _vm.psychosocial_accompaniment.meeting_happened
      ? _c(
          "div",
          { attrs: { id: "forwarding-data" } },
          _vm._l(_vm.forwarding, function (item, index) {
            return _c(
              "div",
              {
                key: item.id,
                staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
                attrs: { "data-index": index },
              },
              [
                _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Data do Encaminhamento",
                        text: _vm.formattedDate(item.forwarding_date),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Data de Expiração",
                        text: _vm.formattedDate(
                          item.forwarding_expiration_date
                        ),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Política de Encaminhamento",
                        text: item.forwarding_policy.name,
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-span-4" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Equipamento do Encaminhamento",
                        text: item.facility.name,
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-span-4" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Endereço do Equipamento",
                        text: item.facility.address,
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Descrição",
                        text: item.forwarding_description,
                        "type-text": "topic-details",
                        classe: "break-words",
                      },
                    }),
                  ],
                  1
                ),
                _vm._m(2, true),
              ]
            )
          }),
          0
        )
      : _vm._e(),
    _vm.accompanimentPlanData & _vm.psychosocial_accompaniment.meeting_happened
      ? _c(
          "div",
          {
            staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
            attrs: { id: "accompaniment-plan-data" },
          },
          [
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c(
                  "div",
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "Acompanhamento vai continuar?",
                        text: _vm.verifyIfAccompanimentWillContinue(),
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _vm.conclusionData
      ? _c(
          "div",
          {
            staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
            attrs: { id: "conclusion-data" },
          },
          [
            _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Situação apresentada",
                    text: _vm.getMeetingConclusionDescription(),
                    "type-text": "topic-details",
                    classe: "break-words",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text":
                      "Observações técnicas sobre o\nacompanhamento",
                    text: _vm.getAccompanimentComments(),
                    "type-text": "topic-details",
                    classe: "break-words",
                  },
                }),
              ],
              1
            ),
          ]
        )
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-span-5" }, [
      _c("hr", {
        staticClass: "border-t-0 border-b-2 border-ashes opacity-50 col-span-5",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-span-5" }, [
      _c("hr", {
        staticClass: "border-t-0 border-b-2 border-ashes opacity-50 col-span-5",
      }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "col-span-5" }, [
      _c("hr", {
        staticClass: "border-t-0 border-b-2 border-ashes opacity-50 col-span-5",
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }