var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permitList
    ? _c(
        "div",
        { staticClass: "grid auto-grid-auto p-10" },
        [
          _c(
            "div",
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.city.name + " | Cursos",
                  typeText: "custom",
                  weight: "semibold",
                  italic: false,
                  size: "3xl",
                  color: "ashes-hover",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "grid grid-cols-2 justify-items-stretch my-8" },
            [
              _c(
                "div",
                {
                  staticClass:
                    "grid grid-flow-col items-center gap-5 auto-cols-max",
                },
                [
                  _c(
                    "div",
                    [
                      _c("BaseText", {
                        attrs: {
                          text: "Módulos",
                          typeText: "custom",
                          weight: "bold",
                          italic: false,
                          mode: "uppercase",
                          size: "xs",
                          color: "ashes-dark",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "grid grid-flow-col items-center gap-5 auto-cols-max",
                    },
                    _vm._l(_vm.disciplines, function (discipline, index) {
                      return _c(
                        "div",
                        { key: index },
                        [
                          _vm.permit
                            ? _c("AppButtonStatus", {
                                attrs: {
                                  text: discipline.name,
                                  active: _vm.isActive(discipline),
                                  payload: discipline,
                                  typeButton: "primary",
                                  dispatch: "Courses/filterCourses",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c("div", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.clearFilter()
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "inline-block",
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            src: require("../assets/images/filter_alt_black_24dp.svg"),
                            alt: "",
                          },
                        }),
                        _c("BaseText", {
                          attrs: {
                            text: "Limpar",
                            typeText: "custom",
                            weight: "normal",
                            italic: false,
                            size: "xs",
                            color: "burnedYellow",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              ),
              _c(
                "div",
                { staticClass: "justify-self-end" },
                [
                  _vm.permit
                    ? _c("AppButtonColorful", {
                        attrs: {
                          text: "Adicionar Curso",
                          typeButton: "primary",
                          showIcon: "",
                          iconName: "add",
                          iconType: "outlined",
                          dispatch: "Course/toggleModal",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "p-4" }, [
            _c(
              "div",
              [_c("CoursesCards", { attrs: { data: _vm.courses } })],
              1
            ),
          ]),
          _c("ModalCourse", { attrs: { open: _vm.toggleModal } }),
          _c("DeleteCourseConfirmation", {
            attrs: { open: _vm.modalToggleDeleteCourse },
          }),
        ],
        1
      )
    : _c("ProhibitedAccessPage", {
        attrs: {
          classText:
            "grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }