var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.dataSpreadsheet?.length > 0 && _vm.modalToggleExport
        ? _c("BaseModal", {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "grid grid-cols-2" }, [
                        _c(
                          "div",
                          { staticClass: "justify-self-start" },
                          [
                            _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: _vm.titleModal,
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "justify-self-end" },
                          [
                            _c("AppButtonIcon", {
                              attrs: {
                                dispatch: _vm.dispatchToCloseModal,
                                forClose: "",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "footer",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "grid grid-rows-2" }, [
                        _c(
                          "div",
                          { staticClass: "grid justify-center items-center" },
                          [
                            _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Planilha gerada com sucesso!",
                                icon: null,
                                "icon-type": null,
                                type: "text-2x1",
                                weight: "normal",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                          ],
                          1
                        ),
                        _c("div", { staticClass: "grid grid-cols-2" }, [
                          _c(
                            "div",
                            { staticClass: "justify-self-start" },
                            [
                              _c("AppButtonEmpty", {
                                attrs: {
                                  text: "descartar",
                                  typeButton: "danger",
                                  dispatch: _vm.dispatchToCloseModal,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "justify-self-end" },
                            [
                              _c(
                                "download-excel",
                                {
                                  attrs: {
                                    type: "xls",
                                    meta: _vm.json_meta,
                                    fields: _vm.formattedFields,
                                    data: _vm.json_data,
                                    "before-finish":
                                      _vm.closeExportSpreadsheetModal,
                                    worksheet: _vm.sheetTabName,
                                    name: _vm.fileName,
                                    escapeCsv: false,
                                  },
                                },
                                [
                                  _c("AppButtonEmpty", {
                                    attrs: {
                                      text: "Download da planilha",
                                      typeButton: "success",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1415902612
            ),
          })
        : _vm._e(),
      !_vm.variablePreloading ? _c("div", [_c("PreLoading")], 1) : _vm._e(),
      _c("ModalSelectFieldsToExport", {
        attrs: {
          open: _vm.modalToggleSelectFieldsToExport,
          fields: _vm.json_fields,
          openModalDispatch: _vm.dispatchOpenModal,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }