import Vue from "vue";
import { createInscription } from "../../service/InscriptionApi";

const state = {
  modalPreInscriptionOpen: false,
  modalForgotPassword: false,
};

const mutations = {
  toggleModalPreInscription: (state) => {
    state.modalPreInscriptionOpen = !state.modalPreInscriptionOpen;
  },
  toggleModalForgotPasswordMutation: (state) => {
    state.modalForgotPassword = !state.modalForgotPassword;
  },
};

const actions = {
  toggleModalPreInscription: ({ commit, dispatch }) => {
    commit("toggleModalPreInscription");
    dispatch("Student/load", null, { root: true });
    dispatch("Student/setErrorMessages", [], { root: true });
  },
  toggleModalForgotPassword: ({ commit }) => {
    commit("toggleModalForgotPasswordMutation");
  },
  saveInscription: ({ rootState, dispatch, rootGetters }) => {
    let inscription = {
      student_attributes: rootGetters["Student/getStudentForAPI"],
      equipment_id: rootState.Student.equipment?.id,
      season_id: rootState.Student.season?.id,
      avatar: rootState.Student.avatar,
    };
    createInscription(inscription)
      .then((response) => {
        if (response) {
          Vue.$toast.success(response.data.message);
          dispatch("toggleModalPreInscription");
        } else {
          Vue.$toast.error(
            "Não foi possível finalizar a pré-inscrição! " +
              "Por favor, verifique se todos os campos obrigatórios (*) foram preenchidos."
          );
        }
      })
      .catch((e) => {
        let qtdResponseErrors = e.response.data.errors.length;
        let remaining_errors = 0;
        let error_mom = e.response.data.errors.find((errorMessage) => {
          return errorMessage.attribute === "degree_kinship.mom";
        });
        if (e.response.data.errors[0].message == "Edição deve ser informado(a)") {
          Vue.$toast.error("E se há alguma Edição cadastrada com período de inscrição compatível!");
        }
        if (error_mom) {
          remaining_errors++;
        }
        if (error_mom && remaining_errors == qtdResponseErrors) {
          Vue.$toast.error("Informe os dados da MÃE em DADOS DO RESPONSÁVEL E FAMILIARES.");
        } else {
          let error_number_sons = e.response.data.errors.find((errorMessage) => {
            return errorMessage.attribute === "student.number_sons";
          });
          if (error_number_sons) {
            remaining_errors++;
          }
          if (error_number_sons && remaining_errors == qtdResponseErrors) {
            Vue.$toast.error("Descreva os filhos citados na sessão 'DADOS DO RESPONSÁVEL E FAMILIARES'!");
          } else {
            let error_relative_degree_kinship = e.response.data.errors.find((errorMessage) => {
              return errorMessage.attribute === "student.relatives.degree_kinship";
            });
            if (error_relative_degree_kinship) {
              remaining_errors++;
            }
            let error_relative_name = e.response.data.errors.find((errorMessage) => {
              return errorMessage.attribute === "student.relatives.name";
            });
            if (error_relative_name) {
              remaining_errors++;
            }
            let error_relative_cellphone_number = e.response.data.errors.find((errorMessage) => {
              return errorMessage.attribute === "student.relatives.cellphone_number";
            });
            if (error_relative_cellphone_number) {
              remaining_errors++;
            }
            if (
              (error_relative_degree_kinship || error_relative_name || error_relative_cellphone_number) &&
              qtdResponseErrors == remaining_errors
            ) {
              Vue.$toast.error(
                "Informe os DADOS DO RESPONSÁVEL E FAMILIARES. Os dados da MÃE são OBRIGATÓRIOS."
              );
            } else {
              Vue.$toast.error(
                "Não foi possível finalizar a pré-inscrição! " +
                  "Por favor, verifique se todos os campos obrigatórios (*) foram preenchidos."
              );
            }
          }
        }
        dispatch("Student/setErrorMessages", e.response.data.errors, { root: true });
      });
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
