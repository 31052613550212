var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open && _vm.season
    ? _c("div", [
        _c(
          "div",
          { staticClass: "mt-3 ml-2", attrs: { id: "tabs-button" } },
          [
            _c("FilterComponent", [
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "label-text": "Status",
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Status",
                      "multiple-option": false,
                      value: _vm.status_student_filtered,
                      options: _vm.status_student,
                      dispatch: _vm.setFilterStatusNotReinsertedMainPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "label-text": "Área",
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Área",
                      "multiple-option": false,
                      value: _vm.user_areas_filtered,
                      options: _vm.user_areas,
                      dispatch: _vm.setUserAreasFiltered,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Equipamento",
                      "multiple-option": false,
                      value: _vm.user_equipments_filtered,
                      options: _vm.equipments_school_reinsertions,
                      "label-text": _vm.getLabelEquipmentFilter(),
                      dispatch: _vm.setFilterEquipmentNotReinsertedMainPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "label-text": "Filtrar por Nome do Aluno(a)",
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder":
                        "Digite o nome do aluno(a) para busca",
                      "multiple-option": false,
                      value: _vm.inscription_filtered,
                      options: _vm.inscriptionsForGeneralMenus,
                      dispatch: _vm.setFilterInscriptionNotReinsertedMainPage,
                      "load-dispatch":
                        "SchoolReinsertions/loadInscriptionsForGeneralMenus",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "label-text": "Modalidade de Ensino",
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Modalidade de Ensino",
                      "multiple-option": false,
                      value: _vm.teaching_modality_filtered,
                      options: _vm.teaching_modalities,
                      dispatch: _vm.setFilterTeachingModalityReinsertedMainPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "label-text": "Nome da Escola",
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Nome da Escola",
                      "multiple-option": false,
                      value: _vm.facility_filtered,
                      options: _vm.facilities,
                      dispatch: _vm.setFilterFacilityReinsertedMainPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "label-text": "Série/Ano",
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Série/Ano",
                      "multiple-option": false,
                      value: _vm.serie_filtered,
                      options: _vm.series,
                      dispatch: _vm.setFilterSerieReinsertedMainPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "label-text": "Encaminhamentos Institucionais",
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Encaminhamentos Institucionais",
                      "multiple-option": false,
                      value: _vm.institutional_referral_filtered,
                      options: _vm.institutional_referrals,
                      dispatch:
                        _vm.setFilterInstitutionalReferralNotReinsertedMainPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "label-text": "Comprovante de Matrícula",
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Comprovante de Matrícula",
                      "multiple-option": false,
                      value: _vm.enrollment_proof_filtered,
                      options: _vm.enrollment_proof_options,
                      dispatch: _vm.setFilterEnrollmentProofReinsertedMainPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "filter_by_initial_date",
                      "input-type": "date",
                      value: _vm.initial_date_enrollment,
                      "show-label": "",
                      "label-text": "Data de Matrícula [Início]",
                      dispatch:
                        "SchoolReinsertions/setFilterInitialDateEnrollmentReinsertedMainPage",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "filter_by_final_date",
                      "input-type": "date",
                      value: _vm.final_date_enrollment,
                      "show-label": "",
                      "label-text": "Data de Matrícula [Final]",
                      dispatch:
                        "SchoolReinsertions/setFilterFinalDateEnrollmentReinsertedMainPage",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-span-2 mt-3 mb-2" },
              [
                _vm.permitExportInscriptionSpreadsheet
                  ? _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.exportToExcel("inscriptions")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "inline-block",
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            src: require("../../../assets/images/logout_black_24dp.svg"),
                            alt: "",
                          },
                        }),
                        _c("BaseText", {
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            text: "Exportar planilha de Reinseridos",
                            typeText: "custom",
                            italic: false,
                            size: "xs",
                            color: "burnedYellow",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.modalToggleExportSchoolReinsertionsInscriptionsSpreadsheet
                  ? _c("BaseModal", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "grid grid-cols-3" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "justify-self-start col-span-2",
                                    },
                                    [
                                      _c("Label", {
                                        staticClass: "text-ashes-dark",
                                        attrs: {
                                          text: "Exportar Planilha de Reinseridos",
                                          icon: null,
                                          "icon-type": null,
                                          type: "text-2xl",
                                          weight: "bold",
                                          normal: "",
                                          mode: "normal-case",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "justify-self-end" },
                                    [
                                      _c("AppButtonIcon", {
                                        attrs: {
                                          dispatch:
                                            "SchoolReinsertions/closeExportSchoolReinsertionsInscriptionSpreadsheetModal",
                                          forClose: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "grid grid-rows-2" }, [
                                  _vm
                                    .school_reinsertions_inscriptions_spreadsheet
                                    .length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grid justify-center items-center",
                                        },
                                        [
                                          _c("Label", {
                                            staticClass: "text-ashes-dark",
                                            attrs: {
                                              text: "Planilha gerada com sucesso!",
                                              icon: null,
                                              "icon-type": null,
                                              type: "text-2x1",
                                              weight: "normal",
                                              normal: "",
                                              mode: "normal-case",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grid justify-center items-center",
                                        },
                                        [
                                          _c("Label", {
                                            staticClass: "text-ashes-dark",
                                            attrs: {
                                              text: "Nenhum registro encontrado!",
                                              icon: null,
                                              "icon-type": null,
                                              type: "text-2x1",
                                              weight: "normal",
                                              normal: "",
                                              mode: "normal-case",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                  _vm
                                    .school_reinsertions_inscriptions_spreadsheet
                                    .length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "grid grid-cols-2" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "justify-self-start",
                                            },
                                            [
                                              _c("AppButtonEmpty", {
                                                attrs: {
                                                  text: "descartar",
                                                  typeButton: "danger",
                                                  dispatch:
                                                    "SchoolReinsertions/closeExportSchoolReinsertionsInscriptionSpreadsheetModal",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "justify-self-end" },
                                            [
                                              _c(
                                                "download-excel",
                                                {
                                                  attrs: {
                                                    type: "xls",
                                                    meta: _vm.json_meta,
                                                    fields:
                                                      _vm.json_fields_inscriptions,
                                                    data: _vm.json_data_school_reinsertion_inscriptions,
                                                    "before-finish":
                                                      _vm.closeExportSchoolReinsertionsInscriptionSpreadsheetModal,
                                                    worksheet: "Inscritos",
                                                    name: "inscritos_reinseridos.xls",
                                                    escapeCsv: false,
                                                  },
                                                },
                                                [
                                                  _c("AppButtonEmpty", {
                                                    attrs: {
                                                      text: "Download da planilha",
                                                      typeButton: "success",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        20886597
                      ),
                    })
                  : _vm._e(),
                !_vm.hasSpreadsheetSchoolReinsertionsInscriptionsResponse
                  ? _c("div", [_c("PreLoading")], 1)
                  : _vm._e(),
                _vm.permitExportSchoolReinsertionSpreadsheet
                  ? _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.exportToExcel("school_reinsertions")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "inline-block",
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            src: require("../../../assets/images/logout_black_24dp.svg"),
                            alt: "",
                          },
                        }),
                        _c("BaseText", {
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            text: "Exportar Planilha de Atividades",
                            typeText: "custom",
                            italic: false,
                            size: "xs",
                            color: "burnedYellow",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.modalToggleExportSchoolReinsertionsEvolutionSpreadsheet
                  ? _c("BaseModal", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "grid grid-cols-3" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "justify-self-start col-span-2",
                                    },
                                    [
                                      _c("Label", {
                                        staticClass: "text-ashes-dark",
                                        attrs: {
                                          text: "Exportar Planilha de Atividades",
                                          icon: null,
                                          "icon-type": null,
                                          type: "text-2xl",
                                          weight: "bold",
                                          normal: "",
                                          mode: "normal-case",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "justify-self-end" },
                                    [
                                      _c("AppButtonIcon", {
                                        attrs: {
                                          dispatch:
                                            "SchoolReinsertions/closeExportSchoolReinsertionsEvolutionSpreadsheetModal",
                                          forClose: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "grid grid-rows-2" }, [
                                  _vm.school_reinsertions_evolution_spreadsheet
                                    .length > 0
                                    ? _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grid justify-center items-center",
                                        },
                                        [
                                          _c("Label", {
                                            staticClass: "text-ashes-dark",
                                            attrs: {
                                              text: "Planilha gerada com sucesso!",
                                              icon: null,
                                              "icon-type": null,
                                              type: "text-2x1",
                                              weight: "normal",
                                              normal: "",
                                              mode: "normal-case",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grid justify-center items-center",
                                        },
                                        [
                                          _c("Label", {
                                            staticClass: "text-ashes-dark",
                                            attrs: {
                                              text: "Nenhum registro encontrado!",
                                              icon: null,
                                              "icon-type": null,
                                              type: "text-2x1",
                                              weight: "normal",
                                              normal: "",
                                              mode: "normal-case",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                  _vm.school_reinsertions_evolution_spreadsheet
                                    .length > 0
                                    ? _c(
                                        "div",
                                        { staticClass: "grid grid-cols-2" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "justify-self-start",
                                            },
                                            [
                                              _c("AppButtonEmpty", {
                                                attrs: {
                                                  text: "descartar",
                                                  typeButton: "danger",
                                                  dispatch:
                                                    "SchoolReinsertions/closeExportSchoolReinsertionsEvolutionSpreadsheetModal",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "justify-self-end" },
                                            [
                                              _c(
                                                "download-excel",
                                                {
                                                  attrs: {
                                                    type: "xls",
                                                    meta: _vm.json_meta,
                                                    fields: _vm.json_fields,
                                                    data: _vm.json_data_school_reinsertion_evolution,
                                                    "before-finish":
                                                      _vm.closeExportSchoolReinsertionsEvolutionSpreadsheetModal,
                                                    worksheet: "Atividades",
                                                    name: "atividades_por_inscricao.xls",
                                                    escapeCsv: false,
                                                  },
                                                },
                                                [
                                                  _c("AppButtonEmpty", {
                                                    attrs: {
                                                      text: "Download da planilha",
                                                      typeButton: "success",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        902437565
                      ),
                    })
                  : _vm._e(),
                !_vm.hasSpreadsheetSchoolReinsertionsEvolutionResponse
                  ? _c("div", [_c("PreLoading")], 1)
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "grid grid-cols-2 items-center",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              { staticClass: "mx-2" },
              [
                _c("AppButtonColorful", {
                  attrs: {
                    id: "btn-consult-inscriptions",
                    showIcon: "",
                    iconName: "search",
                    text: "Consultar dados",
                    dispatch: "SchoolReinsertions/loadInscriptions",
                    payload: _vm.payload_load,
                    typeButton: "primary",
                  },
                }),
              ],
              1
            ),
            _vm.inscriptions.length > 0
              ? _c(
                  "div",
                  { staticClass: "mx-2 items-center justify-self-end" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.clearFields()
                          },
                        },
                      },
                      [
                        _c("AppButtonColorful", {
                          attrs: {
                            id: "btn-consult-incident_reports",
                            showIcon: "",
                            iconName: "remove",
                            text: "Limpar consulta",
                            typeButton: "primary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c("div", { attrs: { id: "list-psychosocials" } }, [
          _c(
            "div",
            { staticClass: "mx-12" },
            [
              _c("SchoolReinsertionReinsertedsCards", {
                attrs: { data: _vm.inscriptions },
              }),
            ],
            1
          ),
          _vm.inscriptions.length > 0
            ? _c(
                "div",
                {
                  staticClass: "mx-12 my-6 pb-3",
                  attrs: { id: "school-reinsertion-pagination" },
                },
                [
                  _c("BasePagination", {
                    attrs: {
                      total: _vm.inscriptionsTotal,
                      "total-per-page": _vm.totalPerPage,
                      dispatch: "SchoolReinsertions/setOffsetReinserteds",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }