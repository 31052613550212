var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
    _vm.psychosocial_accompaniment?.meeting_happened
      ? _c(
          "div",
          { staticClass: "col-span-2 text-burnedYellow pb-2" },
          [
            _c("Label", {
              attrs: {
                text: "Profissionais que acompanham",
                icon: null,
                "icon-type": null,
                type: "text-sm",
                weight: "semibold",
                normal: "",
                mode: "uppercase",
              },
            }),
            _c("div", { staticClass: "border-ashes-hover border-b-2 w-full" }),
          ],
          1
        )
      : _vm._e(),
    _vm.psychosocial_accompaniment?.meeting_happened
      ? _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppRadioButton", {
              attrs: {
                values: _vm.attendantTeamsOptions,
                labelText:
                  "Informar profissionais que acompanham o Atendimento?",
                showLabel: "",
                dispatch: "PsychosocialAccompaniments/setRegisterAttendatTeams",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.register_attendant_teams
      ? _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _vm._l(_vm.attendant_teams_attributes, function (attendant, index) {
              return _c(
                "div",
                { key: index, staticClass: "grid grid-cols-12 gap-4 mt-2" },
                [
                  _vm.psychosocial_accompaniment?.meeting_happened
                    ? _c(
                        "div",
                        { staticClass: "col-span-11" },
                        [
                          _c("Multiselect", {
                            attrs: {
                              "close-on-select": true,
                              "show-label": "",
                              "clear-on-select": "",
                              "select-placeholder": "Selecione a opção",
                              "label-text": "Profissional",
                              "multiple-option": false,
                              value:
                                _vm.psychosocial_attendant_attributes[index],
                              options: _vm.mountPsychosocialAttendants(index),
                              required: "",
                              dispatch: _vm.setPsychosocialAttendant,
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.attendant_teams_attributes.length > 1 &&
                  _vm.psychosocial_accompaniment.meeting_happened
                    ? _c("div", { staticClass: "mt-5" }, [
                        _c(
                          "a",
                          {
                            staticClass: "text-burnedYellow underline",
                            attrs: { href: "javascript:void(0)" },
                            on: { click: _vm.deleteAttendantTeam },
                          },
                          [
                            _c("AppMaterialIcon", {
                              staticClass: "align-middle",
                              attrs: {
                                "icon-name": "delete",
                                "icon-size": "36",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                ]
              )
            }),
            _vm.attendant_teams_attributes.length > 1 &&
            _vm.psychosocial_accompaniment.meeting_happened
              ? _c("div", { staticClass: "col-span-4 mt-5" }, [
                  _c("hr", {
                    staticClass:
                      "border-t-0 border-b-2 border-ashes opacity-50",
                  }),
                ])
              : _vm._e(),
          ],
          2
        )
      : _vm._e(),
    _vm.psychosocial_accompaniment?.meeting_happened &&
    _vm.register_attendant_teams
      ? _c("div", { staticClass: "mt-5" }, [
          _c(
            "a",
            {
              staticClass: "text-burnedYellow",
              attrs: { href: "javascript:void(0)" },
              on: { click: _vm.addAttendantTeam },
            },
            [
              _c("AppMaterialIcon", {
                staticClass: "align-middle",
                attrs: { "icon-name": "add_circle", "icon-size": "36" },
              }),
              _c("BaseText", {
                staticClass: "align-middle mr-2",
                attrs: {
                  text: "Adicionar Profissional",
                  "type-text": "custom",
                  weight: "medium",
                  size: "sm",
                },
              }),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }