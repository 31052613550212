var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid auto-grid-auto p-10" }, [
    _c(
      "div",
      {
        staticClass: "container1 h-[80vh] md:h-[500px] w-full overflow-hidden",
      },
      [
        _vm._m(0),
        _c(
          "div",
          { staticClass: "container2 w-full h-full overflow-y-auto px-4" },
          [
            _c(
              "div",
              { staticClass: "m-5" },
              [
                _c("BaseText", {
                  staticClass: "text-center md:text-left",
                  attrs: {
                    text: "Inscrição Interna",
                    "type-text": "custom",
                    weight: "medium",
                    mode: "capitalize",
                    color: "burnedYellow",
                    size: "2xl md:3xl",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "m-5 text-sm md:text-base leading-relaxed" },
              [
                _c(
                  "BaseText",
                  {
                    attrs: {
                      "type-text": "custom",
                      weight: "normal",
                      color: "quantum",
                      size: "base",
                    },
                  },
                  [
                    _vm._v(
                      " Preencha os dados abaixo e fique atento aos dados obrigatórios. Vale lembrar que a "
                    ),
                    _c(
                      "BaseText",
                      {
                        attrs: {
                          "type-text": "custom",
                          weight: "bold",
                          color: "quantum",
                          size: "base",
                        },
                      },
                      [
                        _vm._v(
                          " faixa etária para participar do programa é de 15 a 22 anos, "
                        ),
                      ]
                    ),
                    _vm._v(" e que "),
                    _c(
                      "BaseText",
                      {
                        attrs: {
                          "type-text": "custom",
                          weight: "bold",
                          color: "quantum",
                          size: "base",
                        },
                      },
                      [
                        _vm._v(
                          " o jovem não deve estar estudando ou trabalhando. "
                        ),
                        _c("br"),
                      ]
                    ),
                    _vm._v(" Vamos começar? "),
                  ],
                  1
                ),
              ],
              1
            ),
            false
              ? _c(
                  "div",
                  { staticClass: "mr-6 flex justify-center items-center" },
                  [
                    _c("AppButtonUploadImage", {
                      attrs: {
                        id: "foto",
                        text: "Fotos",
                        typeDocument: "cpf",
                        name: "foto",
                        dispatch: "Student/setAvatar",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("div", { staticClass: "flex justify-end items-center" }, [
              _c("div", { staticClass: "mr-5" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "text-ashes-dark underline hover:text-burnedYellow",
                    attrs: {
                      href: "/docs/FICHA_DE_PRE_CADASTRO_ATUALIZADA_2024.pdf",
                      target: "_blank",
                    },
                  },
                  [
                    _c("BaseText", {
                      staticClass: "align-middle mr-2",
                      attrs: {
                        text: "Imprimir formulário em branco",
                        "type-text": "custom",
                        weight: "medium",
                        size: "sm",
                      },
                    }),
                    _c("AppMaterialIcon", {
                      staticClass: "align-middle",
                      attrs: { "icon-name": "print" },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
            _c("FormStudent"),
          ],
          1
        ),
      ]
    ),
    _c("div", { staticClass: "grid grid-cols-2 gap-4 mx-5" }, [
      _c(
        "div",
        { staticClass: "justify-self-start" },
        [
          _c("AppButtonEmpty", {
            attrs: {
              text: "descartar",
              typeButton: "danger",
              dispatch: "Default/toggleModalPreInscription",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "justify-self-end" },
        [
          _c("AppButtonEmpty", {
            attrs: {
              text: "Finalizar",
              typeButton: "success",
              dispatch: "Default/saveInscription",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "px-8 pb-7 text-center md:text-left" }, [
      _c("img", {
        staticClass: "w-3/4 md:w-1/2 mx-auto md:mx-0",
        attrs: {
          src: require("../assets/images/logo-name-green.png"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }