var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col items-start font-system" },
    [
      _vm.showLabel
        ? _c("BaseLabel", {
            attrs: {
              text: _vm.labelText,
              required: _vm.required,
              target: _vm.id,
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "relative w-full" },
        [
          _vm.mask
            ? _c("input", {
                directives: [
                  {
                    name: "the-mask",
                    rawName: "v-the-mask",
                    value: _vm.mask,
                    expression: "mask",
                  },
                ],
                class: _vm.classes,
                attrs: {
                  id: _vm.id,
                  type: _vm.inputType,
                  name: _vm.inputName,
                  placeholder: _vm.inputPlaceholder,
                  min: _vm.inputMin,
                  max: _vm.inputMax,
                  readonly: _vm.readonly,
                },
                domProps: { value: _vm.value },
                on: {
                  input: function ($event) {
                    return _vm.onInput($event)
                  },
                },
              })
            : _c("input", {
                class: _vm.classes,
                attrs: {
                  id: _vm.id,
                  type: _vm.inputType,
                  name: _vm.inputName,
                  placeholder: _vm.inputPlaceholder,
                  min: _vm.inputMin,
                  max: _vm.inputMax,
                  readonly: _vm.readonly,
                },
                domProps: { value: _vm.value },
                on: {
                  input: function ($event) {
                    return _vm.onInput($event)
                  },
                },
              }),
          _vm.showIcon
            ? _c("AppMaterialIcon", {
                staticClass: "icon-input text-quantum",
                attrs: { iconName: _vm.iconName },
              })
            : _vm._e(),
          _vm.errorMessage
            ? _c("span", { staticClass: "text-carmesim-hover text-xs" }, [
                _vm._v(_vm._s(_vm.errorMessage)),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }