var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid auto-grid-auto p-10 gap-5" }, [
    _c(
      "div",
      [
        _c("BaseText", {
          attrs: {
            text: _vm.course.name,
            typeText: "custom",
            weight: "semibold",
            mode: "capitalize",
            italic: false,
            size: "3xl",
            color: "ashes-hover",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c(
          "router-link",
          { attrs: { to: "/cursos" } },
          [
            _c("BaseText", {
              attrs: {
                text: "<- Voltar",
                typeText: "custom",
                weight: "medium",
                italic: false,
                mode: "capitalize",
                size: "sm",
                color: "carmesim",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
    _c("div", { staticClass: "grid grid-cols-2" }, [
      _c("div", { staticClass: "grid grid-flow-col auto-cols-max gap-2" }, [
        _c("div", { staticClass: "place-self-center" }, [
          _c(
            "div",
            { class: _vm.iconClass },
            [
              _c("AppMaterialIcon", {
                staticClass: "place-self-center text-white",
                attrs: {
                  iconName: "book",
                  iconType: "outlined",
                  iconSize: "36",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "gridf grid-rols-3" }, [
          _c(
            "div",
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.course.name,
                  typeText: "custom",
                  weight: "extrabold",
                  italic: false,
                  size: "2xl",
                  color: "ashes-dark",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass:
                "grid grid-flow-col auto-cols-max gap-2 text-burnedYellow",
            },
            [
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Fase 02 | ",
                      typeText: "custom",
                      weight: "medium",
                      italic: false,
                      size: "sm",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("BaseText", {
                    attrs: {
                      text: _vm.course.discipline.name,
                      typeText: "custom",
                      weight: "bold",
                      italic: false,
                      size: "sm",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _c("div", { staticClass: "grid grid-flow-col auto-cols-max gap-2" }, [
            _c(
              "div",
              [
                _c("BaseText", {
                  attrs: {
                    text: "Turmas Vinculadas |",
                    typeText: "custom",
                    weight: "medium",
                    italic: false,
                    size: "sm",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "text-burnedYellow" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "05",
                    typeText: "custom",
                    weight: "bold",
                    italic: false,
                    size: "sm",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
    ]),
    _c("div", { staticClass: "grid grid-flow-row auto-rows-max" }, [
      _c(
        "div",
        [
          _c("BaseText", {
            attrs: {
              text: "Descrição",
              typeText: "custom",
              weight: "semibold",
              mode: "uppercase",
              italic: false,
              size: "sm",
              color: "burnedYellow",
            },
          }),
        ],
        1
      ),
      _c("div", { staticClass: "border-t bg-ashes-hover" }),
      _c(
        "div",
        [
          _vm.course.description
            ? _c("BaseText", {
                attrs: {
                  text: _vm.course.description,
                  typeText: "custom",
                  weight: "semibold",
                  mode: "uppercase",
                  italic: false,
                  size: "md",
                  color: "ashes-dark",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }