<template>
  <div class="font-system" v-if="permit">
    <div id="tabs-button" class="grid grid-cols-3 justify-between items-center mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl mx-2 col-span-2">
        {{ city.name }} | Rede de Atendimento
      </div>
      <div class="mx-2 items-center justify-self-end">
        <AppButtonColorful
          v-if="permitCreate"
          id="btn-add-facility"
          showIcon
          iconName="add"
          text="Cadastrar Equipamento"
          dispatch="Facilities/toggleModalFacilities"
          typeButton="primary"
        />
      </div>
    </div>
    <div id="tabs-button" class="mx-14">
      <FilterComponent>
        <div>
          <BaseLabel
            text="Pesquisar por nome do Equipamento"
            typeText="custom"
            weight="normal"
            :italic="false"
            size="xs"
          />
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por nome"
            dispatch="Facilities/filterListByName"
          />
        </div>
        <div>
          <BaseLabel
            text="Pesquisar pelo Endereço"
            typeText="custom"
            weight="normal"
            :italic="false"
            size="xs"
          />
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar pelo endereço"
            dispatch="Facilities/filterListByAddress"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Selecione a opção"
            label-text="Tipo de Política"
            :multiple-option="false"
            :value="forwarding_policy_filtered"
            :options="forwarding_policies"
            :dispatch="filterListByForwardingPolicy"
          />
        </div>
      </FilterComponent>
      <div class="mt-3">
        <a
          href="javascript:void(0)"
          @click="exportToExcel()"
          v-if="facilities.length > 0 && permitExportSpreadsheet"
        >
          <img
            class="inline-block"
            src="@/assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
      </div>

      <BaseModal v-if="facilities_spreadsheet.length > 0 && modalToggleExportFacilitySpreadsheet">
        <template v-slot:header>
          <div class="grid grid-cols-3">
            <div class="justify-self-start col-span-2">
              <Label
                text="Exportar Planilha de Rede de Atendimento"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon dispatch="Facilities/closeExportSpreadsheetModal" forClose />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="Facilities/closeExportSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data"
                  :before-finish="closeExportSpreadsheetModal"
                  worksheet="Rede de Atendimento"
                  name="rede_de_atendimento.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetResponse">
        <PreLoading />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center mx-12 my-6">
      <div class="mx-2">
        <AppButtonColorful
          v-if="permit"
          id="btn-consult-facilities"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="Facilities/loadFacilities"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="facilities.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-incident_reports"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-facilities">
      <div class="mx-12">
        <FacilitiesCards :data="facilities" />
      </div>
      <div id="facilities-pagination" class="mx-12 my-6 pb-3" v-if="facilities.length > 0">
        <BasePagination
          :total="totalFacilities"
          :total-per-page="totalPerPage"
          dispatch="Facilities/setFacilityOffset"
        />
      </div>
      <ModalFacility :open="modalToggleFacilities" />
      <DeleteFacilityConfirmation :open="modalConfirmDeleteFacility" />
    </div>
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import FacilitiesCards from "./list/FacilitiesCards.vue";
import BasePagination from "../components/BasePagination.vue";
import ModalFacility from "./ModalFacility.vue";
import DeleteFacilityConfirmation from "./alerts/DeleteFacilityConfirmation.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import BaseLabel from "../components/BaseLabel.vue";
import ProhibitedAccessPage from "../components/ProhibitedAccessPage.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";
import BaseText from "@/components/BaseText.vue";
import AppButtonIcon from "@/components/AppButtonIcon.vue";
import PreLoading from "@/components/PreLoading.vue";
import BaseModal from "@/components/BaseModal.vue";
import AppButtonEmpty from "@/components/AppButtonEmpty.vue";
import Label from "@/components/Label.vue";

export default {
  components: {
    Label,
    AppButtonEmpty,
    BaseModal,
    PreLoading,
    AppButtonIcon,
    BaseText,
    FilterComponent,
    BasePagination,
    BaseLabel,
    AppButtonColorful,
    FacilitiesCards,
    ModalFacility,
    DeleteFacilityConfirmation,
    Multiselect,
    BaseInput,
    ProhibitedAccessPage,
  },
  data() {
    return {
      permit: false,
      permitCreate: false,
      permitExportSpreadsheet: false,
      json_fields: {
        Nome: "name",
        Endereço: "address",
        Política: "forwarding_policy",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      filterListByForwardingPolicy: "Facilities/filterListByForwardingPolicy",
      closeExportSpreadsheetModal: "Facilities/closeExportSpreadsheetModal",
      clearFields() {
        this.$store.dispatch("Facilities/clearQuery");
      },
    }),
    backToTop() {
      window.scrollTo(0, 0);
    },
    async exportToExcel() {
      await this.$store.dispatch("Facilities/loadFacilitiesSpreadsheet");
    },
  },

  created() {
    this.canAcces("facility_index").then((response) => {
      this.permit = response;
    });
    this.canAcces("facility_create").then((response) => {
      this.permitCreate = response;
    });
    this.canAcces("facility_export_spreadsheet").then((response) => {
      this.permitExportSpreadsheet = response;
    });
    this.$store.dispatch("Facilities/loadForwardingPolicies");
  },

  computed: {
    ...mapState({
      facilities: (state) => state.Facilities.facilities,
      forwarding_policies: (state) => state.Facilities.forwarding_policies,
      forwarding_policy_filtered: (state) => state.Facilities.params.forwarding_policy,

      modalToggleFacilities: (state) => state.Facilities.modalToggleFacilities,
      modalConfirmDeleteFacility: (state) => state.Facilities.modalConfirmDeleteFacility,

      city: (state) => state.City.city,

      offset: (state) => state.Facilities.facilityOffset,
      totalPerPage: (state) => state.Facilities.facilityTotalPerPage,
      totalFacilities: (state) => state.Facilities.facilityTotal,
      facilities_spreadsheet: (state) => state.Facilities.facilities_spreadsheet,
      hasSpreadsheetResponse: (state) => state.Facilities.hasSpreadsheetResponse,
      json_data: (state) => state.Facilities.json_data,
      modalToggleExportFacilitySpreadsheet: (state) => state.Facilities.modalToggleExportFacilitySpreadsheet,
    }),
  },
  watch: {
    offset: function () {
      this.$store.dispatch("Facilities/loadFacilities");
      this.backToTop();
    },
  },
};
</script>
