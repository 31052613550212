var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.forwarding_reservation_registration.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar Encaminhamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Criar Encaminhamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "ForwardingReservationRegistrations/closeModalAddForwardingReservationRegistration",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações Gerais",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "forwarding_date",
                            "input-name": "forwarding_date",
                            "input-type": "date",
                            "show-label": "",
                            required: "",
                            "label-text": "Data do Encaminhamento",
                            value:
                              _vm.forwarding_reservation_registration
                                .forwarding_date,
                            dispatch:
                              "ForwardingReservationRegistrations/setForwardingDate",
                            "error-message": _vm.getErrorMessage(
                              "data do encaminhamento"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Nome do Participante",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder":
                              "Digite o nome do participante para busca",
                            "multiple-option": false,
                            value: _vm.inscription,
                            options: _vm.inscriptions,
                            dispatch: _vm.setInscription,
                            "load-dispatch":
                              "ForwardingReservationRegistrations/loadInscriptions",
                            "error-message": _vm.getErrorMessage("inscrição"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Município",
                            "multiple-option": false,
                            value: _vm.city,
                            options: _vm.cities,
                            "label-text": "Município",
                            dispatch: _vm.setCity,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Empresa",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder":
                              "Digite o nome da empresa para busca",
                            "multiple-option": false,
                            value: _vm.company,
                            options: _vm.companies,
                            dispatch: _vm.setCompany,
                            "load-dispatch":
                              "ForwardingReservationRegistrations/loadCompanies",
                            "error-message": _vm.getErrorMessage("empresa"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Parceiro",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder":
                              "Digite o nome do parceiro para busca",
                            "multiple-option": false,
                            value: _vm.partner,
                            options: _vm.partners,
                            dispatch: _vm.setPartner,
                            "load-dispatch":
                              "ForwardingReservationRegistrations/loadPartners",
                            "error-message": _vm.getErrorMessage("parceiro"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "ForwardingReservationRegistrations/closeModalAddForwardingReservationRegistration",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.forwarding_reservation_registration.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Atualizar",
                                typeButton: "success",
                                dispatch:
                                  "ForwardingReservationRegistrations/update",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "Criar",
                                typeButton: "success",
                                dispatch:
                                  "ForwardingReservationRegistrations/save",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          4013786800
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }