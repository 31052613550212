<template>
  <div :class="editionsRow">
    <Label
      :text="city.name + ' | Edições'"
      :icon="null"
      :iconType="null"
      type="text-3xl"
      weight="semibold"
      normal
      mode="normal-case"
      :class="title"
    />

    <div class="grid grid-cols-5 my-8">
      <div>
        <input
          class="rounded-lg w-full py-3 px-6 border border-black"
          type="text"
          v-model="searchEdition"
          placeholder="Procurar por Edição..."
        />
      </div>
      <div class="col-span-4 justify-self-end">
        <AppButtonColorful
          text="Adicionar edição"
          typeButton="primary"
          showIcon
          iconName="add"
          iconType="outlined"
          dispatch="Seasons/openModal"
          v-if="permitCreate"
        />
      </div>
    </div>
    <div class="grid grid-cols-custom mt-12 ml-6 text-ashes-dark">
      <div>
        <Label
          text="Edição"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Status"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Período de inscrição"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Período de execução"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Nº de Matrículas"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Nº de Ativos"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Nº de Desligados"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div>
        <Label
          text="Nº de Concludentes"
          :icon="null"
          :iconType="null"
          type="text-sm"
          weight="semibold"
          normal
          mode="uppercase"
        />
      </div>
      <div></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <!-- Cada elemento requer um atributo data-index para que a transição funcione corretamente -->
      <div
        v-for="(season, index) in filteredSeasons"
        :data-index="index"
        :key="season.id"
        class="mt-6"
        @click.prevent="goToSeason(season)"
      >
        <AppEdition :edition="season" />
        <div class="my-6 border-ashes w-full border"></div>
      </div>
    </BaseStaggeredFade>
    <ModalSeason :open="showModal" />
  </div>
</template>

<script>
import AppEdition from "../components/content/AppEdition.vue";
import Label from "../components/Label.vue";
import BaseStaggeredFade from "../components/BaseStaggeredFade.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import ModalSeason from "./ModalSeason.vue";
import router from "../router/index.js";
import { mapState, mapActions } from "vuex";

export default {
  name: "seasons",

  components: {
    AppEdition,
    Label,
    AppButtonColorful,
    ModalSeason,
    BaseStaggeredFade,
  },
  data: () => ({
    searchEdition: "",
    edit: false,
    edition: null,
    permit: false,
    permitUp: false,
    permitCreate: false,
    type_request: null,
    url: window.location.href,
  }),
  computed: {
    ...mapState({
      showModal: (state) => state.Seasons.modalSeasonOpen,
      seasons: (state) => state.Seasons.seasons,
      city: (state) => state.City.city,
    }),
    editionsRow() {
      return {
        "p-10": true,
      };
    },
    title() {
      return {
        "text-ashes-hover": true,
      };
    },
    filteredSeasons() {
      return this.seasons.filter((obj) => {
        return obj.name.toLowerCase().indexOf(this.searchEdition.toLowerCase()) > -1;
      });
    },
  },
  created() {
    let type_request = null;
    type_request = this.url.includes("/edicoes/finalizada") ? "closed" : null;
    this.$store.dispatch("Seasons/loadSeasons", type_request);

    this.seasonAcces();
    this.seasonCreate();
    this.seasonChange();
  },
  beforeRouteUpdate(to, from, next) {
    let type_request = null;
    type_request = to.path == "/edicoes/finalizada" ? "closed" : null;
    this.$store.dispatch("Seasons/loadSeasons", type_request);

    next();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    seasonAcces() {
      this.canAcces("season_index").then((resp) => {
        this.permit = resp;
      });
    },
    seasonCreate() {
      this.canAcces("season_create").then((resp) => {
        this.permitCreate = resp;
      });
    },
    seasonChange() {
      this.canAcces("season_update").then((resp) => {
        this.permitUp = resp;
      });
    },
    goToSeason(params) {
      router.push({ name: "season", params: { id: params.id } });
    },
  },
};
</script>
<style scoped>
.grid-cols-custom {
  grid-template-columns: 11% 9% 14% 15% 10% 8% 11% 20%;
}
</style>
