import Vue from "vue";
import { getBenefits } from "../../service/BenefitApi";
import { getSeasonsByCityIdForMenus } from "../../service/SeasonApi";

const state = {
  all: [],
  benefitsScholarship: [],
  benefitsPublicTransport: [],
  tabBenefitType: "scholarship",
  seasons: [],
  modalMatrixInfoOpen: false,
};

const mutations = {
  setTabBenefitTypeMutation: (state, tabBenefitType) => {
    state.tabBenefitType = tabBenefitType;
  },
  setAllMutation: (state, benefits) => {
    state.all = benefits;
  },
  setSeasonsMutation: (state, seasons) => {
    state.seasons = seasons;
  },
  setBenefitsScholarshipMutation: (state, benefitsScholarship) => {
    state.benefitsScholarship = benefitsScholarship;
  },
  setBenefitsPublicTransportMutation: (state, benefitsPublicTransport) => {
    state.benefitsPublicTransport = benefitsPublicTransport;
  },
  addBenefitMutation: (state, benefit) => {
    state.all = [benefit, ...state.all];
  },
  addBenefitsScholarshipMutation: (state, benefit) => {
    state.benefitsScholarship = [benefit, ...state.benefitsScholarship];
  },
  addBenefitsPublicTransportMutation: (state, benefit) => {
    state.benefitsPublicTransport = [benefit, ...state.benefitsPublicTransport];
  },
  updateBenefitMutation: (state, benefit) => {
    state.all = state.all.map((item) => {
      return item.id === benefit.id ? benefit : item;
    });
  },
  updateBenefitsScholarshipMutation: (state, benefit) => {
    state.benefitsScholarship = state.benefitsScholarship.map((item) => {
      return item.id === benefit.id ? benefit : item;
    });
  },
  updateBenefitsPublicTransportMutation: (state, benefit) => {
    state.benefitsPublicTransport = state.benefitsPublicTransport.map((item) => {
      return item.id === benefit.id ? benefit : item;
    });
  },
  toggleModalMatrixInfoOpenMutation: (state) => {
    state.modalMatrixInfoOpen = !state.modalMatrixInfoOpen;
  },
};

const actions = {
  loadBenefits: ({ commit }, payload) => {
    getBenefits(payload)
      .then((response) => {
        const benefitsScholarship = response.data.benefits.filter((benefit) => {
          return benefit.type_benefit === "scholarship";
        });

        const benefitsPublicTransport = response.data.benefits.filter((benefit) => {
          return benefit.type_benefit === "public_transport";
        });

        commit("setAllMutation", response.data.benefits);
        commit("setBenefitsScholarshipMutation", benefitsScholarship);
        commit("setBenefitsPublicTransportMutation", benefitsPublicTransport);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
        console.log("error", e);
      });
  },
  loadSeasons: ({ commit, rootState }) => {
    const city_id = rootState.City.city.id;

    getSeasonsByCityIdForMenus(city_id)
      .then((response) => {
        commit("setSeasonsMutation", response.data.seasons);
      })
      .catch((e) => {
        console.log("error", e);
      });
  },
  setTabBenefitType: ({ commit }, payload) => {
    commit("setTabBenefitTypeMutation", payload);
  },
  setAll: ({ commit }, payload) => {
    commit("setAllMutation", payload);
  },
  setBenefitsPublicTransport: ({ commit }, payload) => {
    commit("setBenefitsPublicTransportMutation", payload);
  },
  setBenefitsScholarship: ({ commit }, payload) => {
    commit("setBenefitsScholarshipMutation", payload);
  },
  addBenefit: ({ commit }, payload) => {
    commit("addBenefitMutation", payload);
    if (payload.type_benefit === "public_transport") {
      commit("addBenefitsPublicTransportMutation", payload);
    } else {
      if (payload.type_benefit === "scholarship") {
        commit("addBenefitsScholarshipMutation", payload);
      }
    }
  },
  updateBenefit: ({ commit }, payload) => {
    commit("updateBenefitMutation", payload);
    if (payload.type_benefit === "public_transport") {
      commit("updateBenefitsPublicTransportMutation", payload);
    } else {
      if (payload.type_benefit === "scholarship") {
        commit("updateBenefitsScholarshipMutation", payload);
      }
    }
  },
  toggleModalMatrixInfoOpen: ({ commit }) => {
    commit("toggleModalMatrixInfoOpenMutation");
  },
};

const getters = {};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
