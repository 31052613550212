var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", [
    _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.offset + _vm.index + 1,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.beneficiary.student?.name,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm._f("VMask")(
                _vm.beneficiary.student?.cpf,
                "###.###.###-##"
              ),
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.beneficiary.value,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          !_vm.isEditingStatus
            ? _c("BaseText", {
                attrs: {
                  text: _vm.beneficiary.status
                    ? _vm.beneficiary.status.name
                    : "-",
                  mode: "normal-case",
                  color: "quantum",
                  typeText: "custom",
                  weight: "normal",
                  size: "base",
                },
              })
            : _vm._e(),
          _vm.isEditingStatus
            ? _c("Multiselect", {
                attrs: {
                  options: _vm.beneficiaries_status_options,
                  "close-on-select": true,
                  "clear-on-select": true,
                  "multiple-option": false,
                  value: _vm.beneficiary.status,
                  label: "name",
                  placeholder: "Selecione o Status",
                  dispatch: _vm.updateBeneficiaryStatus,
                },
              })
            : _vm._e(),
          !_vm.isEditingStatus
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "edit",
                  iconSize: "16",
                  title: "Editar Status",
                },
                nativeOn: {
                  click: function ($event) {
                    return _vm.toggleEditStatus.apply(null, arguments)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }