var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { class: _vm.liClass }, [
    _c(
      "div",
      { staticClass: "grid grid-cols-custom gap-10 items-center pt-2" },
      [
        _c("div", [
          _c(
            "div",
            { staticClass: "flex justify-start items-center" },
            [
              _c(
                "div",
                { class: _vm.iconClass },
                [
                  _c("AppMaterialIcon", {
                    staticClass: "place-self-center text-white",
                    attrs: {
                      iconName: "book",
                      iconType: "outlined",
                      iconSize: "36",
                    },
                  }),
                ],
                1
              ),
              _c("BaseText", {
                staticClass: "x1",
                attrs: {
                  text: _vm.team.code,
                  typeText: "custom",
                  mode: "underline",
                  weight: "bold",
                  size: "base",
                },
              }),
              _c("BaseText", {
                staticClass: "mr-1",
                attrs: {
                  text: "|",
                  typeText: "custom",
                  weight: "normal",
                  size: "base",
                },
              }),
              _c(
                "div",
                [
                  _c("BaseText", {
                    staticClass: "mr-1",
                    attrs: {
                      text:
                        _vm.team.discipline.phase.id == 2
                          ? _vm.team.discipline.name +
                            " - " +
                            _vm.team.course.name
                          : _vm.team.discipline.name,
                      typeText: "custom",
                      mode: "underline",
                      weight: "bold",
                      size: "sm",
                    },
                  }),
                ],
                1
              ),
              _vm.team.has_unregistered_meeting
                ? _c(
                    "div",
                    {
                      staticClass:
                        "flex items-center ml-1 pr-1 col-span-2 tooltip",
                    },
                    [
                      _c(
                        "button",
                        {
                          ref: "btnRef",
                          on: {
                            mouseenter: function ($event) {
                              return _vm.toggleTooltip()
                            },
                            mouseleave: function ($event) {
                              return _vm.toggleTooltip()
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticClass:
                                "material-icons mt-2 text-yellow-400",
                            },
                            [
                              _c("span", { staticClass: "material-icons" }, [
                                _vm._v(" warning "),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        {
                          ref: "tooltipRef",
                          staticClass:
                            "bg-white border border-black-600 mr-3 block font-normal leading-normal text-sm text-left break-words rounded-lg tooltiptext shadow-xl w-auto",
                          class: {
                            hidden: !_vm.tooltipShow,
                            block: _vm.tooltipShow,
                          },
                        },
                        [
                          _c("div", { staticClass: "w-full" }, [
                            _c(
                              "div",
                              { staticClass: "text-red-500 font-semibold p-2" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "tolltiptext-position" },
                                  [
                                    _vm._v(
                                      " Encontro(s) com presenças pendentes de registro! "
                                    ),
                                  ]
                                ),
                              ]
                            ),
                          ]),
                        ]
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ]),
        _c(
          "div",
          [
            _c("BaseText", {
              attrs: {
                text: _vm.getQtdStudents(),
                typeText: "custom",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseText", {
              attrs: {
                text: `${_vm.team.start} - ${_vm.team.closing}`,
                typeText: "custom",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseText", {
              attrs: {
                text: _vm.getTeacherName(),
                typeText: "custom",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("BaseText", {
              attrs: {
                text: _vm.getEquipmentName(),
                typeText: "custom",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _vm.permitUp &&
        _vm.season_date_manual_execution_end &&
        _vm.team.in_progress
          ? _c(
              "div",
              [
                _c("AppButtonIcon", {
                  attrs: {
                    iconName: "edit",
                    dispatch: "Team/openEditModal",
                    payload: _vm.team,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }