var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { class: _vm.liClass }, [
    _c("div", { staticClass: "grid grid-cols-2 gap-10 items-center pt-2" }, [
      _c("div", [
        _c(
          "div",
          { staticClass: "flex justify-start items-center" },
          [
            _c(
              "div",
              { class: _vm.iconClass },
              [
                _c("AppMaterialIcon", {
                  staticClass: "place-self-center text-white",
                  attrs: {
                    iconName: "book",
                    iconType: "outlined",
                    iconSize: "36",
                  },
                }),
              ],
              1
            ),
            _c("BaseText", {
              staticClass: "mr-1",
              attrs: {
                text: _vm.course.name,
                typeText: "custom",
                mode: "underline",
                weight: "bold",
                size: "xl",
              },
            }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "grid grid-cols-2 gap-10 items-center justify-items-end",
        },
        [
          _c(
            "div",
            { staticClass: "justify-self-start" },
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.course.discipline.name,
                  typeText: "custom",
                  size: "sm",
                  color: "ashes-dark",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "justify-items-end" },
            [
              _vm.permitUp
                ? _c("AppButtonIcon", {
                    attrs: {
                      iconName: "edit",
                      dispatch: "Course/openEditModal",
                      payload: _vm.course,
                    },
                  })
                : _vm._e(),
              _vm.permitDes
                ? _c("AppButtonIcon", {
                    attrs: {
                      iconName: "delete",
                      dispatch: "Course/toggleModalDeleteCourse",
                      payload: _vm.course,
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }