var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.forwarding_productive_insertion.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar Encaminhamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Criar Encaminhamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "ForwardingProductiveInsertions/closeModalAddForwardingProductiveInsertion",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações Gerais",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Seleção",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder":
                              "Digite o nome da seleção para a busca",
                            "multiple-option": false,
                            value: _vm.opportunity,
                            options: _vm.opportunities,
                            dispatch: _vm.setOpportunity,
                            "load-dispatch":
                              "ForwardingProductiveInsertions/loadOpportunities",
                            "error-message":
                              _vm.getErrorMessage("oportunidade"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "forwarding_date",
                            "input-name": "forwarding_date",
                            "input-type": "date",
                            "show-label": "",
                            required: "",
                            "label-text": "Data do Encaminhamento",
                            value:
                              _vm.forwarding_productive_insertion
                                .forwarding_date,
                            dispatch:
                              "ForwardingProductiveInsertions/setForwardingDate",
                            "error-message": _vm.getErrorMessage(
                              "data do encaminhamento"
                            ),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Município",
                            "multiple-option": false,
                            value: _vm.city,
                            options: _vm.cities,
                            "label-text": "Município",
                            dispatch: _vm.setCity,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Nome do Participante",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder":
                              "Digite o nome do participante para busca",
                            "multiple-option": false,
                            value: _vm.inscription,
                            options: _vm.inscriptions,
                            dispatch: _vm.setInscription,
                            "load-dispatch":
                              "ForwardingProductiveInsertions/loadInscriptions",
                            "error-message": _vm.getErrorMessage("inscrição"),
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.inscription
                      ? _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Encaminhamentos registrados",
                                text:
                                  _vm.inscription.forwardings_quantity === 0
                                    ? "0"
                                    : _vm.inscription.forwardings_quantity,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Tipo da Devolução",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder": "Escolha o Tipo da Devolução",
                            "multiple-option": false,
                            value: _vm.feedback_type,
                            options: _vm.feedback_types,
                            dispatch: _vm.setFeedbackType,
                            "error-message":
                              _vm.getErrorMessage("tipo da devolução"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "ForwardingProductiveInsertions/closeModalAddForwardingProductiveInsertion",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.forwarding_productive_insertion.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Atualizar",
                                typeButton: "success",
                                dispatch:
                                  "ForwardingProductiveInsertions/update",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "Criar",
                                typeButton: "success",
                                dispatch: "ForwardingProductiveInsertions/save",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2945549515
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }