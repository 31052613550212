var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permit
    ? _c("div", { staticClass: "font-system" }, [
        _c("div", { staticClass: "mx-12 my-6", attrs: { id: "title-page" } }, [
          _c("div", { staticClass: "text-ashes-hover font-medium text-3xl" }, [
            _vm._v(_vm._s(_vm.city.name) + " | Instrutores"),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "flex justify-between mx-12 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c("div", { staticClass: "flex" }, [
              _c(
                "div",
                { staticClass: "w-104" },
                [
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      inputName: "",
                      inputType: "text",
                      iconName: "search",
                      showIcon: "",
                      "input-placeholder": "Pesquisar por nome ou cpf",
                      dispatch: "Teachers/filterList",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              { staticClass: "float-right" },
              [
                _vm.permitCreate
                  ? _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-add-teacher",
                        showIcon: "",
                        iconName: "add",
                        text: "Adicionar Instrutor",
                        dispatch: "Teachers/openModalTeacher",
                        typeButton: "primary",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { attrs: { id: "list-teacher" } },
          [
            _c(
              "div",
              { staticClass: "mx-12" },
              [_c("TeachersCards", { attrs: { data: _vm.teachers } })],
              1
            ),
            _c("ModalTeacher", { attrs: { open: _vm.modalTeacherOpen } }),
            _c("ModalResetTeacherPassword", {
              attrs: { open: _vm.modalResetPassword },
            }),
            _c("DeleteTeacherConfirmation", {
              attrs: { open: _vm.modalDeleteTeacherOpen },
            }),
          ],
          1
        ),
      ])
    : _c("ProhibitedAccessPage", {
        attrs: {
          classText:
            "grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }