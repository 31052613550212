var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: _vm.buttonClass,
      attrs: { type: "button" },
      on: { click: _vm.onClick },
    },
    [
      _c("BaseText", { attrs: { text: _vm.text, typeText: "button-generic" } }),
      _vm.number
        ? _c("span", { staticClass: "font-system" }, [_vm._v("|")])
        : _vm._e(),
      _vm.number
        ? _c("BaseText", {
            attrs: { text: _vm.number, typeText: "button-generic" },
          })
        : _vm._e(),
      _vm.icon
        ? _c("AppMaterialIcon", {
            staticClass: "align-text-bottom",
            attrs: { iconName: _vm.icon },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }