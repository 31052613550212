var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-12" },
    [
      _c("div", { staticClass: "my-6", attrs: { id: "title-page" } }, [
        _c("div", { staticClass: "text-ashes-hover font-medium text-3xl" }, [
          _vm._v(
            " " +
              _vm._s(_vm.city.name) +
              " | Benefícios " +
              _vm._s(_vm.titleStatusBenefits()) +
              " "
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "my-6", attrs: { id: "back" } },
        [
          _c(
            "router-link",
            {
              staticClass:
                "text-burnedYellow hover:text-burnedYellow-hover flex items-center",
              attrs: { to: _vm.linkReturn },
            },
            [
              _c("AppMaterialIcon", {
                staticClass: "mr-1",
                attrs: {
                  iconName: "arrow_back",
                  iconType: "outlined",
                  iconSize: "18",
                },
              }),
              _c("BaseText", {
                staticClass: "hover:underline",
                attrs: {
                  text: "Voltar",
                  "type-text": "custom",
                  weight: "semibold",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "my-6", attrs: { id: "subtitle" } }, [
        _c(
          "div",
          { staticClass: "text-ashes-hover font-medium text-3xl" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.benefit.description,
                "type-text": "custom",
                weight: "semibold",
                size: "3xl",
                color: "ashes-dark",
              },
            }),
            !_vm.itsPaidBenefits() && _vm.permitUp
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "edit",
                    payload: _vm.benefit,
                    dispatch: "Benefit/openEditModal",
                  },
                })
              : _vm._e(),
            !_vm.itsPaidBenefits() && _vm.permitDes
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "delete",
                    payload: _vm.benefit,
                    dispatch: "Benefit/openCloseModalDeleteBenefitConfirmation",
                  },
                })
              : _vm._e(),
            _c("ModalBenefit", { attrs: { open: _vm.modalBenefitOpen } }),
            _c("DeleteBenefitConfirmation", {
              attrs: { open: _vm.openModalDelete },
            }),
            _c("ModalMatrix", { attrs: { open: _vm.modalMatrixInfoOpen } }),
          ],
          1
        ),
      ]),
      _c(
        "div",
        { staticClass: "my-10 flex justify-between", attrs: { id: "filter" } },
        [
          _c("div", { staticClass: "flex items-center" }, [
            false
              ? _c("div", [
                  _c(
                    "div",
                    { staticClass: "text-ashes-dark font-medium text-sm mr-4" },
                    [_vm._v("FILTRAR")]
                  ),
                  _c(
                    "div",
                    { staticClass: "w-52 mr-4" },
                    [
                      _c("Multiselect", {
                        attrs: {
                          id: "filter-team",
                          "select-placeholder": "Turma...",
                          "close-on-select": "",
                          "multiple-option": false,
                          value: "",
                          options: _vm.teams,
                          dispatch: "",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "w-52 mr-4" },
                    [
                      _c("Multiselect", {
                        attrs: {
                          id: "filter-fase",
                          "select-placeholder": "Fase...",
                          "close-on-select": "",
                          "multiple-option": false,
                          value: _vm.filteredFase,
                          options: _vm.fases,
                          dispatch: _vm.setFases,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.clearFilter()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "inline-block",
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          src: require("../assets/images/filter_alt_black_24dp.svg"),
                          alt: "",
                        },
                      }),
                      _c("BaseText", {
                        attrs: {
                          text: "Limpar",
                          typeText: "custom",
                          weight: "normal",
                          italic: false,
                          mode: "underline",
                          size: "xs",
                          color: "burnedYellow",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _c(
            "div",
            [
              !_vm.itsPaidBenefits()
                ? _c("AppButtonColorful", {
                    attrs: {
                      showIcon: "",
                      iconName: "add",
                      text: "Importar Alunos",
                      dispatch: "ModalAllocationTeamBenefit/openModal",
                      typeButton: "primary",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "my-6", attrs: { id: "list-benefit-payments" } },
        [
          _c("BenefitsPaymentsCards", {
            attrs: { students: _vm.listStudents },
          }),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "relative inset-x-0 bottom-0 h-64",
          attrs: { id: "footer" },
        },
        [
          _c("div", { staticClass: "grid grid-flow-col" }, [
            _c("div", { staticClass: "flex justify-start items-center" }, [
              _c(
                "div",
                { staticClass: "mx-12 my-6" },
                [
                  _c(
                    "download-excel",
                    {
                      attrs: {
                        type: "xls",
                        name: _vm.benefit.description + ".xls",
                        escapeCsv: false,
                        meta: _vm.json_meta,
                        fields: _vm.json_fields,
                        data: _vm.json_data,
                        worksheet: "Beneficios",
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "inline-block",
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          src: require("../assets/images/logout_black_24dp.svg"),
                          alt: "",
                        },
                      }),
                      _c("BaseText", {
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          text: "Exportar planilha",
                          typeText: "custom",
                          italic: false,
                          color: "burnedYellow",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "flex justify-end gap-10" }, [
              !_vm.itsPaidBenefits()
                ? _c("div", { staticClass: "flex justify-end gap-10" }, [
                    _vm.benefit.type_benefit === "public_transport"
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                inputName: "request_number",
                                inputType: "text",
                                labelText: "nº do pedido (SINDIÔNIBUS)",
                                dispatch: "Benefit/setRequestNumber",
                                showLabel: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            inputName: "payday",
                            value: _vm.benefit.payday,
                            inputType: "date",
                            labelText: "Data de Pagamento *",
                            showLabel: "",
                            dispatch: "Benefit/setPayday",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "place-self-center",
                        on: {
                          click: function ($event) {
                            return _vm.goToBenefits()
                          },
                        },
                      },
                      [
                        _c("AppButtonColorful", {
                          attrs: {
                            text: "Finalizar Pagamento",
                            dispatch: "Benefit/closeBenefit",
                            typeButton: "primary",
                          },
                        }),
                      ],
                      1
                    ),
                  ])
                : _c("div", { staticClass: "flex justify-end gap-10" }, [
                    _vm.benefit.type_benefit === "public_transport"
                      ? _c(
                          "div",
                          { staticClass: "place-self-center" },
                          [
                            _c("BaseText", {
                              attrs: {
                                text: "nº do pedido (SINDIÔNIBUS): ",
                                typeText: "custom",
                                italic: false,
                                color: "burnedYellow",
                              },
                            }),
                            _c("BaseText", {
                              attrs: {
                                text: _vm.benefit.request_number,
                                typeText: "custom",
                                italic: false,
                                color: "burnedYellow",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "place-self-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "Data de Pagamento: ",
                            typeText: "custom",
                            italic: false,
                            color: "burnedYellow",
                          },
                        }),
                        _c("BaseText", {
                          attrs: {
                            text: _vm.benefit.payday,
                            typeText: "custom",
                            italic: false,
                            color: "burnedYellow",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "place-self-center" },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "Pagamento Finalizado",
                            typeText: "custom",
                            italic: false,
                            color: "burnedYellow",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
            ]),
          ]),
        ]
      ),
      _c("ModalAllocationTeamBenefit", {
        attrs: { open: _vm.modalAloccationsOpen },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }