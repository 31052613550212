var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "label",
    {
      staticClass:
        "mb-1 font-system uppercase font-medium text-xs text-ashes-dark",
      attrs: { for: _vm.target },
    },
    [
      _vm._v(" " + _vm._s(_vm.text) + " "),
      _vm.required
        ? _c("span", { staticClass: "text-burnedYellow" }, [_vm._v("*")])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }