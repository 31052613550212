import Vue from "vue";
import JsonExcel from "vue-json-excel";
Vue.component("downloadExcel", JsonExcel);

const state = {
  fields: null,
  modalToggleSelectFieldsToExport: false,
  dataSpreadsheet: [],
  modalToggleExport: false,
  titleModal: null,
  dispatchToCloseModal: null,
  json_data: null,
  sheetTabName: null,
  fileName: null,
  variablePreloading: true,
  json_fields: null,
  dispatchOpenModal: null,
};

const mutations = {
  // --> MUTATIONS - TOGGLES FROM MODALS AND SCREENS <--
  toggleSelectFieldsToExport: (state) => {
    state.modalToggleSelectFieldsToExport = !state.modalToggleSelectFieldsToExport;
  },
  setFieldsMutation: (state, fields) => {
    state.fields = fields;
  },
  setDataSpreadsheetMutation: (state, dataSpreadsheet) => {
    state.dataSpreadsheet = dataSpreadsheet;
  },
  setModalToggleExportMutation: (state, modalToggleExport) => {
    state.modalToggleExport = modalToggleExport;
  },
  setTitleModalMutation: (state, titleModal) => {
    state.titleModal = titleModal;
  },
  setDispatchToCloseModalMutation: (state, dispatchToCloseModal) => {
    state.dispatchToCloseModal = dispatchToCloseModal;
  },
  setJsonDataMutation: (state, json_data) => {
    state.json_data = json_data;
  },
  setSheetTabNameMutation: (state, sheetTabName) => {
    state.sheetTabName = sheetTabName;
  },
  setFileNameMutation: (state, fileName) => {
    state.fileName = fileName;
  },
  setVariablePreloadingMutation: (state, variablePreloading) => {
    state.variablePreloading = variablePreloading;
  },
  setJsonFieldsMutation: (state, json_fields) => {
    state.json_fields = json_fields;
  },
  setDispatchOpenModalMutation: (state, dispatchOpenModal) => {
    state.dispatchOpenModal = dispatchOpenModal;
  },
  // --> OTHER MUTATIONS <--
  clearFieldsMutation: (state) => {
    state.fields = null;
    state.dataSpreadsheet = [];
    state.modalToggleExport = false;
    state.titleModal = null;
    state.dispatchToCloseModal = null;
    state.json_data = null;
    state.sheetTabName = null;
    state.fileName = null;
    state.variablePreloading = true;
    state.json_fields = null;
    state.dispatchOpenModal = null;
  },
};

const actions = {
  // --> ACTIONS - SET STATE FIELDS <--
  setDataSpreadsheet: async ({ commit }, payload) => {
    commit("setDataSpreadsheetMutation", payload);
  },
  setModalToggleExport: async ({ commit }, payload) => {
    commit("setModalToggleExportMutation", payload);
  },
  setTitleModal: async ({ commit }, payload) => {
    commit("setTitleModalMutation", payload);
  },
  setDispatchToCloseModal: async ({ commit }, payload) => {
    commit("setDispatchToCloseModalMutation", payload);
  },
  setJsonData: async ({ commit }, payload) => {
    commit("setJsonDataMutation", payload);
  },
  setSheetTabName: async ({ commit }, payload) => {
    commit("setSheetTabNameMutation", payload);
  },
  setFileName: async ({ commit }, payload) => {
    commit("setFileNameMutation", payload);
  },
  setVariablePreloading: async ({ commit }, payload) => {
    commit("setVariablePreloadingMutation", payload);
  },
  setJsonFields: async ({ commit }, payload) => {
    commit("setJsonFieldsMutation", payload);
  },
  setDispatchOpenModal: async ({ commit }, payload) => {
    commit("setDispatchOpenModalMutation", payload);
  },

  // --> ACTIONS - TOGGLES FROM MODALS AND SCREENS <--
  openModalSelectFieldsToExport: async ({ commit }) => {
    commit("toggleSelectFieldsToExport");
  },
  closeModalSelectFieldsToExport: async ({ commit }) => {
    commit("toggleSelectFieldsToExport");
  },
  // --> OTHER ACTIONS <--
  addFields: async ({ commit }, payload) => {
    if (payload) {
      const newFields = { ...state.fields, ...payload };
      commit("setFieldsMutation", newFields);
    }
  },
  removeFields: async ({ commit }, payload) => {
    for (const key in payload) {
      if (payload[key]) {
        delete state.fields[key];
        commit("setFieldsMutation", state.fields);
      }
    }
  },
  move_on: async ({ dispatch }, payload) => {
    dispatch("closeModalSelectFieldsToExport");
    await dispatch(payload, null, { root: true });
  },
  clearFields: async ({ commit }) => {
    commit("clearFieldsMutation");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
