var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col items-start font-system w-full" },
    [
      _vm.showLabel
        ? _c("BaseLabel", {
            attrs: {
              text: _vm.labelText,
              required: _vm.required,
              target: _vm.id,
            },
          })
        : _vm._e(),
      _c(
        "multiselect",
        {
          staticClass: "rounded-xl",
          attrs: {
            id: _vm.id,
            label: _vm.label,
            "track-by": _vm.trackBy,
            placeholder: _vm.selectPlaceholder,
            "close-on-select": _vm.closeOnSelect,
            "clear-on-select": _vm.clearOnSelect,
            options: _vm.options,
            multiple: _vm.multipleOption,
            optionHeight: 39,
            hideSelected: _vm.hideSelectedOption,
            showLabels: false,
            taggable: _vm.showTagSelected,
            value: _vm.value,
            disabled: _vm.readonly,
          },
          on: { input: _vm.dispatch, "search-change": _vm.handleSearchChange },
        },
        [
          _c("span", { attrs: { slot: "noResult" }, slot: "noResult" }, [
            _vm._v(" Nenhum registro encontrado para o valor informado "),
          ]),
          _c("template", { slot: "noOptions" }, [
            _vm._v(_vm._s(this.noOptionsMessage)),
          ]),
        ],
        2
      ),
      _vm.errorMessage
        ? _c("span", { staticClass: "text-carmesim-hover text-xs mt-1" }, [
            _vm._v(_vm._s(_vm.errorMessage)),
          ])
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }