var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", [
    _c(
      "div",
      { staticClass: "grid grid-cols-custom items-center container1" },
      [
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.verifyStatus(),
                mode: "normal-case",
                color: "quantum",
                typeText: "custom",
                weight: "bold",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.inscription.student.neighborhood.area.name,
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.getEquipmentName(),
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.inscription.student.name,
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.inscription.articulator
                  ? _vm.inscription.articulator
                  : "-",
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.inscription.reason_for_non_reinsertion
                  ? _vm.inscription.reason_for_non_reinsertion.name
                  : "-",
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "normal",
                size: "sm",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 flex" },
          [
            _c(
              "router-link",
              { attrs: { to: "/reinsercao_escolar/nao_reinserido_info" } },
              [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.setInscription()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { class: _vm.classButtonAssignment },
                      [
                        _c("AppMaterialIcon", {
                          attrs: {
                            iconName: "school",
                            iconType: "round",
                            iconSize: "36",
                            title: "Acessar Detalhes",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                ),
              ]
            ),
          ],
          1
        ),
        !_vm.inscription.has_active_monitoring &&
        _vm.permiteactiveMonitoringSchoolReinsertion
          ? _c("div", { staticClass: "mt-4 flex" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.activeMonitoring()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { class: _vm.classButtonAssignment },
                    [
                      _c("AppMaterialIcon", {
                        attrs: {
                          iconName: "undo",
                          iconType: "round",
                          iconSize: "36",
                          title: "Reabilitar Acompanhamento",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ])
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }