var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-5" }, [
    _c(
      "div",
      {
        staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
        attrs: { id: "person-data" },
      },
      [
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("BaseText", {
              attrs: { text: "Dados da Atividade", "type-text": "title-forms" },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Tipo da Atividade",
                text: _vm.activity_type
                  ? _vm.activity_type.name
                  : "Não informado",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Tipo do Resultado",
                text: _vm.result_type ? _vm.result_type.name : "Não informado",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Data da Atividade",
                text: _vm.formattedDate(
                  _vm.school_reinsertion.reinsertion_date
                ),
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Articulador(a) Responsável",
                text: _vm.school_reinsertion.articulator
                  ? _vm.school_reinsertion.articulator
                  : "Não informado",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _vm.result_type?.id === 1 || _vm.activity_type?.id === 2
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Data da Entrega da Carta",
                    text: _vm.formattedDate(
                      _vm.school_reinsertion.letter_delivery_date
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.result_type?.id === 1 || _vm.activity_type?.id === 2
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Data de Retorno da Carta",
                    text: _vm.formattedDate(
                      _vm.school_reinsertion.return_letter_deadline
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.result_type?.id === 0
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Motivo para Não Reinserção",
                    text: _vm.reasons_for_non_reinsertion
                      ? _vm.reasons_for_non_reinsertion.name
                      : "Não informado",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.result_type?.id === 2 || _vm.activity_type?.id === 3
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text":
                      "Data para/de Anexo de Comprovante de Matrícula",
                    text: _vm.formattedDate(
                      _vm.school_reinsertion.deadline_attach_proof_of_enrollment
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.result_type?.id === 0
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Encaminhamentos [Antigo]",
                    text: _vm.school_reinsertion.forwardings
                      ? _vm.school_reinsertion.forwardings
                      : "Nada informado",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.mustShowFacilityField()
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": _vm.returnIfIsForwardingOrFacilityLabel(),
                    text: _vm.facility ? _vm.facility.name : "Não informada",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.result_type?.id === 2
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Turno",
                    text: _vm.shift ? _vm.shift.name : "Não informado",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.result_type?.id === 2
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Ano/Série",
                    text: _vm.serie ? _vm.serie.name : "Não informada",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.result_type?.id === 2
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Modalidade de Ensino",
                    text: _vm.teaching_modality
                      ? _vm.teaching_modality.name
                      : "Não informada",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.result_type?.id === 2
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Data da Matrícula",
                    text: _vm.formattedDate(
                      _vm.school_reinsertion.enrollment_date
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Observações",
                text: _vm.school_reinsertion.observations
                  ? _vm.school_reinsertion.observations
                  : "Não informado",
                "type-text": "topic-details",
                classe: "break-words",
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }