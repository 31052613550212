var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-5" }, [
    _c(
      "div",
      {
        staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
        attrs: { id: "person-data" },
      },
      [
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("BaseText", {
              attrs: { text: "Dados da Empresa", "type-text": "title-forms" },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Nome",
                text: _vm.company.name ? _vm.company.name : "Não informado",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Endereço",
                text: _vm.company.address
                  ? _vm.company.address
                  : "Não informado",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Modalidade",
                text: _vm.modality ? _vm.modality.name : "Não informada",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Nº de Seleções Ofertadas",
                text:
                  _vm.total_opportunities_offered === 0
                    ? "0"
                    : _vm.total_opportunities_offered,
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Nº Total de Vagas Ofertadas",
                text:
                  _vm.total_vacancies_offered === 0
                    ? "0"
                    : _vm.total_vacancies_offered,
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Nº de Encaminhamentos Registrados",
                text:
                  _vm.total_registered_referrals === 0
                    ? "0"
                    : _vm.total_registered_referrals,
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Nº de Efetivados",
                text:
                  _vm.total_registered_referrals_made === 0
                    ? "0"
                    : _vm.total_registered_referrals_made,
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }