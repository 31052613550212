var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permit
    ? _c("div", { staticClass: "font-system" }, [
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-3 justify-between items-center mx-12 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "text-ashes-hover font-medium text-3xl mx-2 col-span-2",
              },
              [_vm._v(" Matriz Problema-Impacto - Situações ")]
            ),
            _c(
              "div",
              { staticClass: "mx-2 items-center justify-self-end" },
              [
                _vm.permitCreate
                  ? _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-add-facility",
                        showIcon: "",
                        iconName: "add",
                        text: "Cadastrar Situação",
                        dispatch: "MatrixSituation/toggleModalMatrixSituations",
                        typeButton: "primary",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "mx-14", attrs: { id: "tabs-button" } },
          [
            _c("FilterComponent", [
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    staticClass: "mr-4",
                    attrs: {
                      id: "select-problem",
                      "select-placeholder": "Problema",
                      "label-text": "Problema",
                      "close-on-select": "",
                      "show-label": "",
                      required: "",
                      value: _vm.problem_filtered,
                      options: _vm.matrix_problems,
                      dispatch: _vm.filterListByProblem,
                      "multiple-option": false,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("BaseLabel", {
                    attrs: {
                      text: "Pesquisar por situação",
                      typeText: "custom",
                      weight: "normal",
                      italic: false,
                      size: "xs",
                    },
                  }),
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      inputName: "",
                      inputType: "text",
                      iconName: "search",
                      showIcon: "",
                      "input-placeholder": "Pesquisar por situação",
                      dispatch: "MatrixSituation/filterListBySituation",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    staticClass: "mr-4",
                    attrs: {
                      id: "select-situation-risk",
                      "select-placeholder": "Nível da Situação",
                      "label-text": "Nível da Situação",
                      "close-on-select": "",
                      "show-label": "",
                      required: "",
                      value: _vm.situation_risk_filtered,
                      options: _vm.situations_risks,
                      dispatch: _vm.filterListBySituationRisk,
                      "multiple-option": false,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("BaseLabel", {
                    attrs: {
                      text: "Pesquisar por impacto",
                      typeText: "custom",
                      weight: "normal",
                      italic: false,
                      size: "xs",
                    },
                  }),
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      inputName: "",
                      inputType: "text",
                      iconName: "search",
                      showIcon: "",
                      "input-placeholder": "Pesquisar por impacto",
                      dispatch: "MatrixSituation/filterListByImpact",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    staticClass: "mr-4",
                    attrs: {
                      id: "select-impact-level",
                      "select-placeholder": "Nível do Impacto",
                      "label-text": "Nível do Impacto",
                      "close-on-select": "",
                      "show-label": "",
                      required: "",
                      value: _vm.impact_level_filtered,
                      options: _vm.impact_levels,
                      dispatch: _vm.filterListByImpactLevel,
                      "multiple-option": false,
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "grid grid-cols-2 items-center mx-12 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              { staticClass: "mx-2" },
              [
                _vm.permit
                  ? _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-consult-matrix-situations",
                        showIcon: "",
                        iconName: "search",
                        text: "Consultar dados",
                        dispatch: "MatrixSituation/loadMatrixSituations",
                        typeButton: "primary",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.matrix_situations.length > 0
              ? _c(
                  "div",
                  { staticClass: "mx-2 items-center justify-self-end" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.clearFields()
                          },
                        },
                      },
                      [
                        _c("AppButtonColorful", {
                          attrs: {
                            id: "btn-consult-incident_reports",
                            showIcon: "",
                            iconName: "remove",
                            text: "Limpar consulta",
                            typeButton: "primary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { attrs: { id: "list-matrix-situations" } },
          [
            _c(
              "div",
              { staticClass: "mx-12" },
              [
                _c("MatrixSituationsCards", {
                  attrs: { data: _vm.matrix_situations },
                }),
              ],
              1
            ),
            _vm.matrix_situations.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "mx-12 my-6 pb-3",
                    attrs: { id: "matrix_situations-pagination" },
                  },
                  [
                    _c("BasePagination", {
                      attrs: {
                        total: _vm.totalMatrixSituations,
                        "total-per-page": _vm.totalPerPage,
                        dispatch: "MatrixSituation/setMatrixSituationOffset",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("ModalMatrixSituation", {
              attrs: { open: _vm.modalToggleMatrixSituations },
            }),
            _c("DeleteMatrixSituationConfirmation", {
              attrs: { open: _vm.modalConfirmDeleteMatrixSituation },
            }),
            _c("ModalViewMatrixSituationData", {
              attrs: { open: _vm.modalToggleViewMatrixSituations },
            }),
          ],
          1
        ),
      ])
    : _c("ProhibitedAccessPage", {
        attrs: {
          classText:
            "grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }