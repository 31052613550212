var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "font-system" }, [
    _c(
      "div",
      {
        staticClass: "grid grid-cols-3 justify-between items-center mx-12 my-6",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "text-ashes-hover font-medium text-3xl mx-2 col-span-2",
          },
          [_vm._v("Suporte")]
        ),
        _c(
          "div",
          { staticClass: "mx-2 items-center justify-self-end" },
          [
            _vm.permitCreate
              ? _c("AppButtonColorful", {
                  attrs: {
                    id: "btn-add-support",
                    showIcon: "",
                    iconName: "add",
                    text: "Cadastrar Email de Suporte",
                    dispatch: "Supports/openModalAddSupport",
                    typeButton: "primary",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
    _c(
      "div",
      { staticClass: "mx-14", attrs: { id: "tabs-button" } },
      [
        _c("FilterComponent", [
          _c(
            "div",
            { staticClass: "col-span-4" },
            [
              _c("BaseLabel", {
                attrs: {
                  text: "Filtrar por Email",
                  typeText: "custom",
                  weight: "normal",
                  italic: false,
                  size: "xs",
                },
              }),
              _c("BaseInput", {
                attrs: {
                  active: "",
                  inputName: "",
                  inputType: "text",
                  iconName: "search",
                  showIcon: "",
                  "input-placeholder": "Filtrar poe Email",
                  dispatch: "Supports/setFilterEmail",
                  value: _vm.filter_params.email,
                },
              }),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _c(
      "div",
      {
        staticClass: "grid grid-cols-2 items-center mx-12 my-6",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          { staticClass: "mx-2" },
          [
            _vm.permit
              ? _c("AppButtonColorful", {
                  attrs: {
                    id: "btn-consult-supports",
                    showIcon: "",
                    iconName: "search",
                    text: "Consultar dados",
                    dispatch: "Supports/loadSupports",
                    payload: "load",
                    typeButton: "primary",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
        _vm.supports.length > 0
          ? _c("div", { staticClass: "mx-2 items-center justify-self-end" }, [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.clearFields()
                    },
                  },
                },
                [
                  _c("AppButtonColorful", {
                    attrs: {
                      id: "btn-consult-supports",
                      showIcon: "",
                      iconName: "remove",
                      text: "Limpar consulta",
                      typeButton: "primary",
                    },
                  }),
                ],
                1
              ),
            ])
          : _vm._e(),
      ]
    ),
    _c(
      "div",
      { attrs: { id: "list-supports" } },
      [
        _c(
          "div",
          { staticClass: "mx-12" },
          [_c("SupportsCards", { attrs: { data: _vm.supports } })],
          1
        ),
        _vm.supports.length > 0
          ? _c(
              "div",
              {
                staticClass: "mx-12 my-6 pb-3",
                attrs: { id: "supports-pagination" },
              },
              [
                _c("BasePagination", {
                  attrs: {
                    total: _vm.totalSupports,
                    "total-per-page": _vm.totalPerPage,
                    dispatch: "Supports/setSupportsOffset",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c("ModalSupport", { attrs: { open: _vm.modalToggleAddSupport } }),
        _c("DeleteSupportConfirmation", {
          attrs: { open: _vm.modalToggleDeleteSupport },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }