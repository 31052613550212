var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { class: _vm.classesModal }, [
      _c(
        "div",
        {
          staticClass: "relative my-6 mx-auto max-w-7xl",
          class: [_vm.width],
          attrs: { id: "content" },
        },
        [
          _c("div", { class: _vm.classesContent }, [
            _c(
              "div",
              {
                class: _vm.classesHeader,
                style: _vm.styleHeaderObject,
                attrs: { id: "header" },
              },
              [_vm._t("header")],
              2
            ),
            _c(
              "div",
              { staticClass: "relative px-6 py-3 flex-auto" },
              [_vm._t("body")],
              2
            ),
            _c("div", { staticClass: "p-6" }, [_vm._t("footer")], 2),
          ]),
        ]
      ),
    ]),
    _c("div", { staticClass: "opacity-50 fixed inset-0 z-40 bg-black" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }