<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="equipment.id"
            text="Editar Equipamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Adicionar Equipamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Equipment/closeModalEquipment" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div>
          <BaseInput
            id="name"
            input-name="name"
            input-placeholder="Equipamento XX..."
            input-type="text"
            show-label
            label-text="nome"
            required
            :value="equipment.name"
            :error-message="getErrorMessage('name', equipment.name)"
            dispatch="Equipment/setName"
          />
        </div>
        <div>
          <Multiselect
            id="areas"
            select-placeholder="Selecione as opções"
            label-text="Vincular Áreas"
            :close-on-select="false"
            show-label
            :value="equipment.areas_attributes"
            :options="getAreas(areas)"
            :dispatch="setAreas"
            :error-message="getErrorMessage('areas', equipment.areas_attributes)"
            required
          />
        </div>
      </div>
      <div class="grid grid-cols-2 mt-6">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Endereço"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div>
          <BaseInput
            id="cep"
            input-name="cep"
            input-placeholder="00000-000"
            mask="#####-###"
            input-type="text"
            show-label
            label-text="Cep"
            :value="equipment.cep"
            :error-message="getErrorMessage('cep', equipment.cep)"
            required
            dispatch="Equipment/setCep"
          />
        </div>
        <div class="grid grid-cols-2 gap-4">
          <BaseInput
            id="latitude"
            active
            input-name="latidude"
            input-placeholder="-0.0000000000000000"
            input-type="text"
            show-label
            :value="equipment.latitude"
            label-text="Latitude"
            dispatch="Equipment/setLatitude"
          />
          <BaseInput
            id="longitude"
            active
            input-name="longitude"
            input-placeholder="-0.0000000000000000"
            input-type="text"
            show-label
            :value="equipment.longitude"
            label-text="Longitude"
            dispatch="Equipment/setLongitude"
          />
        </div>
        <div>
          <BaseInput
            id="street"
            input-name="name"
            input-placeholder="Escrever..."
            input-type="text"
            show-label
            :value="equipment.street"
            :error-message="getErrorMessage('street', equipment.street)"
            label-text="Rua"
            required
            dispatch="Equipment/setStreet"
          />
        </div>
        <div>
          <BaseInput
            id="streat-number"
            input-name="streat_number"
            input-placeholder="Escrever..."
            input-type="text"
            show-label
            :value="equipment.street_number"
            :error-message="getErrorMessage('street_number', equipment.street_number)"
            label-text="Número"
            required
            dispatch="Equipment/setStreetNumber"
          />
        </div>
        <div>
          <Multiselect
            id="state"
            :close-on-select="true"
            :hide-selected-option="false"
            :clear-on-select="null"
            select-placeholder="Selecione as opções"
            :multiple-option="false"
            show-tag-selected
            input-type="text"
            required
            label-text="Estado"
            :show-label="true"
            :value="equipment.state"
            :options="[{ id: 1, name: 'Ceará' }]"
            :error-message="getErrorMessage('state', equipment.state_id)"
            :dispatch="setState"
          />
        </div>
        <div>
          <Multiselect
            id="city"
            :close-on-select="true"
            :hide-selected-option="false"
            :clear-on-select="null"
            select-placeholder="Selecione as opções"
            :multiple-option="false"
            show-tag-selected
            input-type="text"
            :show-label="true"
            label-text="Município"
            required
            :value="equipment.city"
            :options="cities"
            :error-message="getErrorMessage('city', equipment.city_id)"
            :dispatch="setCity"
          />
        </div>
        <div>
          <Multiselect
            id="neighborhood"
            :close-on-select="true"
            :hide-selected-option="false"
            :clear-on-select="null"
            select-placeholder="Selecione as opções"
            :multiple-option="false"
            show-tag-selected
            input-type="text"
            :show-label="true"
            label-text="Bairro"
            required
            :value="equipment.neighborhood"
            :options="neighborhoods"
            :error-message="getErrorMessage('neighborhood', equipment.neighborhood_id)"
            :dispatch="setNeighborhood"
          />
        </div>
        <div>
          <BaseInput
            id="community"
            input-name="community"
            input-placeholder="Escrever..."
            input-type="text"
            show-label
            label-text="Comunidade"
            :value="equipment.community"
            dispatch="Equipment/setCommunity"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            id="complement"
            input-name="complement"
            input-placeholder="Escrever..."
            input-type="text"
            show-label
            label-text="Complemento"
            :value="equipment.complement"
            dispatch="Equipment/setComplement"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Equipment/closeModalEquipment" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="equipment.id"
            text="salvar"
            typeButton="success"
            dispatch="Equipment/updateEquipment"
          />
          <AppButtonEmpty v-else text="criar" typeButton="success" dispatch="Equipment/saveEquipment" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BaseModal,
    BaseInput,
    Multiselect,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("Areas/loadAreas");
    this.$store.dispatch("City/loadCities");
    this.$store.dispatch("Neighborhoods/loadNeighborhoods");
    this.$store.dispatch("EquipmentAll/loadEquipment");
  },
  computed: {
    ...mapGetters({
      getState: "Equipment/getState",
      getAreas: "Equipment/getAreas",
      getErrorMessage: "Equipment/getErrorMessage",
    }),
    ...mapState({
      equipment: (state) => state.Equipment.equipment,
      states: (state) => state.Equipment.states,
      cities: (state) => state.City.cities,
      neighborhoods: (state) => state.Neighborhoods.neighborhoods,
      areas: (state) => state.Areas.areas,
    }),
  },
  methods: {
    ...mapActions({
      setAreas: "Equipment/setArea",
      setState: "Equipment/setState",
      setCity: "Equipment/setCity",
      setNeighborhood: "Equipment/setNeighborhood",
    }),
  },
};
</script>
