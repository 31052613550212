// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_AT_RULE_IMPORT_0___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-3-2!./fonts/Poppins/style.css");
var ___CSS_LOADER_AT_RULE_IMPORT_1___ = require("-!../../node_modules/css-loader/dist/cjs.js??ref--7-oneOf-3-1!../../node_modules/postcss-loader/src/index.js??ref--7-oneOf-3-2!./fonts/MaterialIcon/style.css");
exports = ___CSS_LOADER_API_IMPORT___(false);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
exports.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
// Module
exports.push([module.id, "* {\n  padding: 0px;\n  margin: 0px;\n}\n\nbody, html{\n  width: 100%;\n  height: 100%;\n}\r\n", ""]);
// Exports
module.exports = exports;
