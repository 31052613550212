import { getTeachersByCityId } from "../../service/TeacherApi";
import Vue from "vue";

const state = {
  teachers: [],
  filterTeachers: [],
  modalDeleteTeacherConfirmationOpen: false,
  modalTeacherOpen: false,
  teacherIdForDeletion: null,
};

const mutations = {
  setTeachers: (state, teachers) => {
    state.teachers = teachers;
    state.filterTeachers = teachers;
  },
  filterList: (state, input) => {
    let teachers = state.teachers;
    state.filterTeachers = teachers.filter((obj) => {
      let name = obj.name.toLowerCase().indexOf(input.value.toLowerCase());
      let cpf = obj.cpf.toLowerCase().indexOf(input.value.toLowerCase());

      return name > -1 || cpf > -1;
    });
  },
  toggleModalDeleteTeacherConfirmation: (state) => {
    state.modalDeleteTeacherConfirmationOpen = !state.modalDeleteTeacherConfirmationOpen;
  },

  toggleModalTeacher: (state) => {
    state.modalTeacherOpen = !state.modalTeacherOpen;
  },
  addTeacher: (state, teacher) => {
    state.teachers.push(teacher);
  },
  updateTeacher: (state, teacher) => {
    state.teachers = state.teachers.map((item) => {
      return item.id === teacher.id ? teacher : item;
    });
  },
  teacherForDeletion: (state, document_id) => {
    state.teacherIdForDeletion = document_id;
  },
};

const actions = {
  setTeachers: ({ commit }, teachers) => {
    commit("setTeachers", teachers);
  },
  filterList: ({ commit }, payload) => {
    commit("filterList", payload);
  },
  loadTeachers: ({ commit }, payload) => {
    getTeachersByCityId(payload)
      .then((response) => {
        commit("setTeachers", response.data.teachers);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
        console.log("error", e);
      });
  },
  addTeacher: ({ commit }, payload) => {
    commit("addTeacher", payload);
  },
  updateTeacher: ({ commit }, payload) => {
    commit("updateTeacher", payload);
  },
  openModalTeacher: ({ commit, dispatch }) => {
    dispatch("Teacher/clearTeacher", null, { root: true });
    commit("toggleModalTeacher");
  },
  closeModalTeacher: ({ commit, dispatch }) => {
    commit("toggleModalTeacher");
    dispatch("Teacher/clearErrorMessages", null, { root: true });
  },
  openCloseDeleteTeacherConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteTeacherConfirmation");
    if (state.modalDeleteTeacherConfirmationOpen) {
      commit("teacherForDeletion", payload.id);
    } else {
      commit("teacherForDeletion", null);
    }
  },
  openEditModalTeacher: ({ commit, dispatch }, teacher) => {
    dispatch("Teacher/load", teacher, { root: true });
    commit("toggleModalTeacher");
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
