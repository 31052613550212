var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        staticClass: "max-w-3xl mx-auto p-6 bg-white rounded-lg shadow-lg",
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "flex justify-between items-center mb-4" },
                    [
                      _c("BaseText", {
                        attrs: {
                          text: _vm.profile.description + " | Perfis de Acesso",
                          typeText: "custom",
                          weight: "semibold",
                          italic: false,
                          size: "3xl",
                          color: "text-gray-800",
                        },
                      }),
                      _c("AppButtonIcon", {
                        attrs: {
                          dispatch: "AccessControl/openModal",
                          forClose: "",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "space-y-6" },
                    _vm._l(_vm.profiles, function (itens, index) {
                      return _c(
                        "div",
                        {
                          key: itens.description,
                          staticClass: "bg-gray-50 p-4 rounded-lg shadow-md",
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "flex justify-between items-center cursor-pointer",
                              on: {
                                click: function ($event) {
                                  return _vm.toggleExpand(index)
                                },
                              },
                            },
                            [
                              _c(
                                "div",
                                [
                                  _c("Label", {
                                    staticClass: "text-gray-900",
                                    attrs: {
                                      text: "Recurso: " + itens.description,
                                      icon: null,
                                      iconType: null,
                                      type: "text-sm",
                                      weight: "semibold",
                                      normal: "",
                                      mode: "uppercase",
                                    },
                                  }),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "text-sm text-gray-600 mt-1",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.countCheckedPermissions(
                                              itens.permissions
                                            )
                                          ) +
                                          " de " +
                                          _vm._s(itens.permissions.length) +
                                          " permissões liberadas "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "span",
                                {
                                  class: _vm.expanded[index]
                                    ? "transform rotate-180"
                                    : "transform rotate-0",
                                },
                                [_vm._v("▼")]
                              ),
                            ]
                          ),
                          _vm.expanded[index]
                            ? _c(
                                "div",
                                { staticClass: "flex flex-wrap mt-2" },
                                _vm._l(
                                  itens.permissions,
                                  function (permission) {
                                    return _c(
                                      "label",
                                      {
                                        key: permission.action_code,
                                        staticClass:
                                          "flex items-center mr-4 mb-2 cursor-pointer",
                                      },
                                      [
                                        _c("input", {
                                          staticClass: "mr-2",
                                          attrs: { type: "checkbox" },
                                          domProps: {
                                            checked: permission.checked,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.updatePermit(
                                                permission.action_code,
                                                $event.target.checked
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "ml-2 whitespace-nowrap text-gray-700",
                                          },
                                          [_vm._v(_vm._s(permission.name))]
                                        ),
                                      ]
                                    )
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "flex justify-between mt-6" },
                    [
                      _c("AppButtonEmpty", {
                        attrs: {
                          text: "Fechar",
                          typeButton: "danger",
                          dispatch: "AccessControl/openModal",
                        },
                      }),
                      _c("AppButtonEmpty", {
                        staticClass: "mr-2",
                        attrs: {
                          text: "Editar",
                          typeButton: "success",
                          dispatch: "AccessControl/sendPerf",
                          payload: _vm.profile,
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          830850652
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }