var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-5" }, [
    _c(
      "div",
      {
        staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
        attrs: { id: "person-data" },
      },
      [
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("BaseText", {
              attrs: { text: "Dados da Inserção", "type-text": "title-forms" },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Nome do Participante",
                text: _vm.inscription.name,
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Área",
                text: _vm.inscription.neighborhood.area.name,
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Data da Inserção",
                text: _vm.formattedDate(
                  _vm.spontaneous_productive_insertion.insertion_date
                ),
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Tipo da Inserção",
                text: _vm.insertion_type
                  ? _vm.insertion_type.name
                  : "Não informada",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Observações",
                text: _vm.spontaneous_productive_insertion.observations,
                "type-text": "topic-details",
                classe: "break-words",
              },
            }),
          ],
          1
        ),
        _vm.insertionTypeIsJob()
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Dados - Tipo Emprego",
                    "type-text": "title-forms",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsJob()
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Empresa",
                    text: _vm.company ? _vm.company.name : "Não informada",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsJob()
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Tipo da Contratação",
                    text: _vm.contracting_type
                      ? _vm.contracting_type.name
                      : "Não informada",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsEntrepreneurship()
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Dados - Tipo Empreendedorismo",
                    "type-text": "title-forms",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsEntrepreneurship()
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Segmento",
                    text: _vm.segment ? _vm.segment.name : "Não informado",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsEntrepreneurship()
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Ocupação",
                    text: _vm.occupation
                      ? _vm.occupation.name
                      : "Não informado",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsEntrepreneurship()
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Teve acesso à financiamento?",
                    text:
                      _vm.spontaneous_productive_insertion
                        .had_access_credit_for_financing === true
                        ? "Sim"
                        : "Não",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsEntrepreneurship()
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Tem estabelecimento?",
                    text:
                      _vm.spontaneous_productive_insertion.has_establishment ===
                      true
                        ? "Sim"
                        : "Não",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsEntrepreneurship() && _vm.hasEstablishment()
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Dados - Estabelecimento",
                    "type-text": "title-forms",
                  },
                }),
                _c("hr", {
                  staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsEntrepreneurship() && _vm.hasEstablishment()
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Nome",
                    text: _vm.spontaneous_productive_insertion
                      .establishment_name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsEntrepreneurship() && _vm.hasEstablishment()
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Endereço",
                    text: _vm.spontaneous_productive_insertion
                      .establishment_address,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsEntrepreneurship() && _vm.hasEstablishment()
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Tem CNPJ?",
                    text:
                      _vm.spontaneous_productive_insertion.has_cnpj === true
                        ? "Sim"
                        : "Não",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsEntrepreneurship() && _vm.hasEstablishment()
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Possui relação com o segmento do curso?",
                    text:
                      _vm.spontaneous_productive_insertion
                        .is_related_to_the_course_segment === true
                        ? "Sim"
                        : "Não",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsEntrepreneurship() && _vm.hasEstablishment()
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Possui relação com o segmento do curso?",
                    text:
                      _vm.spontaneous_productive_insertion
                        .is_related_to_the_course_segment === true
                        ? "Sim"
                        : "Não",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.insertionTypeIsEntrepreneurship() &&
        _vm.hasEstablishment() &&
        _vm.photos_urls &&
        _vm.photos_urls.length > 0
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "Fotos do Estabelecimento",
                    "type-text": "topic-details",
                  },
                }),
                _c(
                  "div",
                  { staticClass: "photos-container" },
                  _vm._l(_vm.photos_urls, function (photoUrl, index) {
                    return _c(
                      "a",
                      {
                        key: index,
                        attrs: { href: photoUrl.url, download: "" },
                      },
                      [
                        _c("img", {
                          attrs: {
                            src: photoUrl.url,
                            alt: "foto_estabelecimento",
                          },
                        }),
                      ]
                    )
                  }),
                  0
                ),
              ],
              1
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }