var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("Label", {
                          staticClass: "text-ashes-dark",
                          attrs: {
                            text: "Alocar Alunos por Turma",
                            icon: null,
                            "icon-type": null,
                            type: "text-2xl",
                            weight: "bold",
                            normal: "",
                            mode: "normal-case",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch: "ModalAllocationTeamBenefit/closeModal",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "grid grid-flow-row auto-rows-max" },
                    [
                      _c(
                        "div",
                        {
                          staticClass:
                            "grid grid-flow-col auto-cols-max gap-10",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "self-center text-ashes-dark" },
                            [
                              _c("BaseText", {
                                attrs: {
                                  text: "Filtrar:",
                                  typeText: "custom",
                                  weight: "base",
                                  italic: false,
                                  size: "sm",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("Multiselect", {
                                staticClass: "mr-5",
                                attrs: {
                                  id: "orderByArea",
                                  selectPlaceholder: "Area",
                                  closeOnSelect: "",
                                  multipleOption: false,
                                  value: _vm.filters.area,
                                  options: _vm.areas,
                                  dispatch: _vm.filterArea,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("Multiselect", {
                                staticClass: "mr-5",
                                attrs: {
                                  id: "orderByArea",
                                  selectPlaceholder: "Bairro",
                                  closeOnSelect: "",
                                  multipleOption: false,
                                  value: _vm.filters.neighborhood,
                                  options: _vm.neighborhoods,
                                  dispatch: _vm.filterNeighborhood,
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("Multiselect", {
                                staticClass: "mr-5",
                                attrs: {
                                  id: "orderByArea",
                                  selectPlaceholder: "Equipamento",
                                  closeOnSelect: "",
                                  multipleOption: false,
                                  value: _vm.filters.equipment,
                                  options: _vm.equipment,
                                  dispatch: _vm.filterEquipment,
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      ),
                      _c(
                        "div",
                        { staticClass: "grid grid-flow-row auto-rows-max" },
                        [
                          _c("BenefitsTeamsCards", {
                            attrs: { data: _vm.teams },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch: "ModalAllocationTeamBenefit/closeModal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "Alocar",
                            typeButton: "success",
                            dispatch:
                              "ModalAllocationTeamBenefit/addStudentForPayment",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3487952760
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }