var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "font-system" }, [
    _c(
      "div",
      {
        staticClass:
          "mx-12 my-8 text-burnedYellow hover:text-burnedYellow-hover grid grid-flow-col auto-cols-max",
      },
      [
        _c(
          "router-link",
          {
            staticClass: "flex items-center",
            attrs: { to: "/reinsercao_escolar" },
          },
          [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.goBack("personal_data")
                  },
                },
              },
              [
                _c("AppMaterialIcon", {
                  staticClass: "mr-1",
                  attrs: {
                    iconName: "arrow_back",
                    iconType: "outlined",
                    iconSize: "18",
                  },
                }),
                _c("BaseText", {
                  staticClass: "hover:underline",
                  attrs: {
                    text: "Voltar",
                    "type-text": "custom",
                    weight: "semibold",
                  },
                }),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
    _vm._m(0),
    _vm.inscription
      ? _c(
          "div",
          {
            staticClass:
              "grid grid-cols-1 justify-between p-4 items-end mx-12 my-6 gap-4",
            attrs: { id: "tabs-button" },
          },
          [
            _c("div", [
              _vm.inscription.student
                ? _c(
                    "span",
                    [
                      _c("Label", {
                        staticClass: "text-burnedYellow",
                        attrs: {
                          text: _vm.inscription.student.season.name,
                          icon: null,
                          iconType: null,
                          type: "text-xs",
                          weight: "medium",
                          mode: "uppercase",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" | "),
              _vm.inscription
                ? _c(
                    "span",
                    [
                      _c("Label", {
                        staticClass: "text-burnedYellow",
                        attrs: {
                          text: _vm.getEquipmentName(),
                          icon: null,
                          iconType: null,
                          type: "text-xs",
                          weight: "medium",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" | "),
              _vm.inscription.student
                ? _c(
                    "span",
                    [
                      _c("Label", {
                        staticClass: "text-burnedYellow",
                        attrs: {
                          text: _vm.getFrequency(),
                          icon: null,
                          iconType: null,
                          type: "text-xs",
                          weight: "medium",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" | "),
              _vm.inscription?.student
                ? _c(
                    "span",
                    [
                      _c("Label", {
                        staticClass: "text-burnedYellow",
                        attrs: {
                          text: _vm.getLastPresence(),
                          icon: null,
                          iconType: null,
                          type: "text-xs",
                          weight: "medium",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.inscription.student
                ? _c(
                    "div",
                    [
                      _c("BaseText", {
                        staticClass: "mr-1",
                        attrs: {
                          text: _vm.inscription.student.name,
                          typeText: "custom",
                          weight: "medium",
                          size: "2xl",
                        },
                      }),
                      _c("span", [_vm._v(" - ")]),
                      _c("BaseText", {
                        staticClass: "mr-1",
                        attrs: {
                          text: _vm._f("VMask")(
                            _vm.inscription.student.cpf,
                            "###.###.###-##"
                          ),
                          typeText: "custom",
                          weight: "medium",
                          size: "2xl",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.inscription
                ? _c(
                    "div",
                    { staticClass: "w-full" },
                    [
                      _c("BaseText", {
                        staticClass: "mr-1",
                        attrs: {
                          text: "Situação -",
                          typeText: "custom",
                          weight: "normal",
                          size: "sm",
                        },
                      }),
                      _c("Label", {
                        staticClass: "text-burnedYellow",
                        attrs: {
                          text: _vm.getSeverity(),
                          icon: null,
                          iconType: null,
                          type: "text-xs",
                          weight: "medium",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
          ]
        )
      : _vm._e(),
    _c(
      "div",
      {
        staticClass: "my-10 mx-16 my-6 flex",
        attrs: { id: "menus_psychosocial_main_page" },
      },
      [
        _c("div", [
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.changeTab("personal_data")
                },
              },
            },
            [
              _c("BaseText", {
                attrs: {
                  text: "Dados Pessoais",
                  typeText: "custom",
                  weight: "semibold",
                  mode: "uppercase",
                  size: "sm",
                  color: _vm.setColorPersonalData,
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "border-l mx-10" }),
        _c("div", [
          _c(
            "a",
            {
              attrs: { href: "javascript:void(0)" },
              on: {
                click: function ($event) {
                  return _vm.changeTab("evolution")
                },
              },
            },
            [
              _c("BaseText", {
                attrs: {
                  text: "Evolução",
                  typeText: "custom",
                  weight: "semibold",
                  mode: "uppercase",
                  size: "sm",
                  color: _vm.setColorEvolution,
                },
              }),
            ],
            1
          ),
        ]),
      ]
    ),
    _c("div", { attrs: { id: "list-psychosocial" } }, [
      _c(
        "div",
        { staticClass: "mx-12" },
        [
          _c("PersonalData", {
            attrs: {
              open: _vm.toggleSchoolReinsertionNotReinsertedPersonalData,
            },
          }),
          _c("Evolution", {
            attrs: { open: _vm.toggleSchoolReinsertionNotReinsertedEvolution },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "grid grid-cols-3 justify-between items-center mx-12 my-6",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "text-ashes-hover font-medium text-3xl mx-2 col-span-3",
          },
          [_vm._v(" Reinsercão Escolar | Não Reinseridos ")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }