var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", [
    _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.season_previous_inscription?.student?.name,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.season_previous_inscription?.student?.social_name,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.season_previous_inscription.season?.name,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.season_previous_inscription.is_active_inscription
                ? "Sim"
                : "Não",
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.season_previous_inscription?.student?.city?.name,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.season_previous_inscription?.neighborhood?.area?.name,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.verifyStatus(_vm.season_previous_inscription.status),
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "normal",
              size: "base",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4 flex gap-4" },
        [
          !_vm.season_previous_inscription.is_active_inscription
            ? _c("AppButtonMinimal", {
                attrs: {
                  id: "btn-activate_inscription",
                  text: "Ativar Inscrição",
                  iconName: "check",
                  size: "small",
                  variant: "filled",
                  color: "success",
                  payload: _vm.season_previous_inscription,
                  dispatch:
                    "SeasonPreviousInscriptions/activateInscriptionLoad",
                  compact: "",
                },
              })
            : _vm._e(),
          _vm.season_previous_inscription.is_active_inscription
            ? _c("AppButtonMinimal", {
                attrs: {
                  id: "btn-inactivate_inscription",
                  text: "Inativar Inscrição",
                  iconName: "cancel",
                  size: "small",
                  variant: "filled",
                  color: "danger",
                  payload: _vm.season_previous_inscription,
                  dispatch:
                    "SeasonPreviousInscriptions/deactivateInscriptionLoad",
                  compact: "",
                },
              })
            : _vm._e(),
          _vm.season_previous_inscription.is_active_inscription
            ? _c("AppButtonMinimal", {
                attrs: {
                  id: "btn-transfer_inscription",
                  text: "Transferir Inscrito(a)",
                  iconName: "transfer_within_a_station",
                  size: "small",
                  variant: "filled",
                  color: "info",
                  payload: _vm.season_previous_inscription,
                  dispatch:
                    "SeasonPreviousInscriptions/transferInscriptionLoad",
                  compact: "",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }