var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.hasTargetTeams > 0
        ? _c("div", { staticClass: "grid grid-cols-2 items-center" }, [
            _c(
              "div",
              { staticClass: "ml-20" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Nome",
                    typeText: "custom",
                    weight: "medium",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "quantum",
                  },
                }),
              ],
              1
            ),
            _c("div", [
              _c("div", { staticClass: "grid grid-cols-2" }, [
                _c(
                  "div",
                  { staticClass: "justify-self-end" },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Nº de Estudantes",
                        typeText: "custom",
                        weight: "medium",
                        italic: false,
                        mode: "uppercase",
                        size: "xs",
                        color: "quantum",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "justify-self-center" },
                  [
                    _c("BaseText", {
                      attrs: {
                        text: "Período",
                        typeText: "custom",
                        weight: "medium",
                        italic: false,
                        mode: "uppercase",
                        size: "xs",
                        color: "quantum",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ]),
          ])
        : _vm._e(),
      _vm.hasTargetTeams === 0
        ? _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  text: _vm.getInvalidTeamsMessage(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "BaseStaggeredFade",
        { attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.data, function (item, index) {
          return _c(
            "li",
            { key: item.id, attrs: { "data-index": index } },
            [
              _vm.checkTeamCode(item)
                ? _c("BaseCard", { class: _vm.liClass }, [
                    _c(
                      "div",
                      { staticClass: "grid grid-cols-2 items-center pt-2" },
                      [
                        _c("div", [
                          _c(
                            "div",
                            { staticClass: "flex justify-start items-center" },
                            [
                              _c("div", { staticClass: "mr-10" }, [
                                _c("input", {
                                  attrs: {
                                    id: item.code,
                                    type: "radio",
                                    name: "transferStudentTargetTeam",
                                    disabled:
                                      !_vm.isValidClosingTeam(item) ||
                                      !_vm.isTeamInProgress(item),
                                  },
                                  domProps: { value: item.id },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onCheck(item)
                                    },
                                  },
                                }),
                              ]),
                              _c(
                                "div",
                                { class: _vm.getCSS(item) },
                                [
                                  _c("AppMaterialIcon", {
                                    staticClass: "place-self-center text-white",
                                    attrs: {
                                      iconName: "book",
                                      iconType: "outlined",
                                      iconSize: "36",
                                      color: _vm.getColorFields(item),
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("BaseText", {
                                staticClass: "mr-1",
                                attrs: {
                                  text: item.code,
                                  typeText: "custom",
                                  mode: "underline",
                                  weight: "bold",
                                  size: "base",
                                  color: _vm.getColorFields(item),
                                },
                              }),
                              _c("BaseText", {
                                staticClass: "mr-1",
                                attrs: {
                                  text: "|",
                                  typeText: "custom",
                                  weight: "normal",
                                  size: "2xl",
                                  color: _vm.getColorFields(item),
                                },
                              }),
                              _c("BaseText", {
                                staticClass: "mr-1",
                                attrs: {
                                  text:
                                    item.discipline.name +
                                    ` ${_vm.isTeamFinishedMessage(item)}`,
                                  typeText: "custom",
                                  mode: "underline",
                                  weight: "bold",
                                  size: "base",
                                  color: _vm.getColorFields(item),
                                },
                              }),
                            ],
                            1
                          ),
                        ]),
                        _c("div", { staticClass: "justify-self-end" }, [
                          _c("div", { staticClass: "grid grid-cols-2" }, [
                            _c(
                              "div",
                              [
                                _c("BaseText", {
                                  attrs: {
                                    text: `${item.qtd_students}`,
                                    typeText: "custom",
                                    size: "sm",
                                    color: _vm.getColorItemClosing(item),
                                  },
                                }),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              [
                                _c("BaseText", {
                                  attrs: {
                                    text: `${item.start} - ${item.closing}`,
                                    typeText: "custom",
                                    size: "sm",
                                    color: _vm.getColorItemClosing(item),
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]
                    ),
                  ])
                : _vm._e(),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }