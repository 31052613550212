import Vue from "vue";
import {
  createTeacher,
  destroyTeacher,
  updateTeacher,
  getTeacherByUserId,
  resetPassword,
} from "../../service/TeacherApi";
import { createUser } from "../../service/UserApi";
import { profiles } from "../../service/AccessControlApi";
import { isEmpty } from "lodash";

const state = {
  id: null,
  user_id: null,
  name: "",
  active: true,
  cpf: null,
  cellphone_number: null,
  phone_number: null,
  email: null,
  city_id: null,
  roles_attributes: [{ name: "Instrutor" }],
  password: null,
  password_confirmation: null,
  modalResetPassword: false,
  errorMessages: [],
  profiles: [],
};

const mutations = {
  setIdMutation: (state, id) => {
    state.id = id;
  },
  setUserIdMutation: (state, id) => {
    state.user_id = id;
  },
  setNameMutation: (state, name) => {
    state.name = name;
  },
  setCpfMutation: (state, cpf) => {
    state.cpf = cpf;
  },
  setCellphoneNumberMutation: (state, cellphoneNumber) => {
    state.cellphone_number = cellphoneNumber;
    state.phone_number = cellphoneNumber;
  },
  setEmailMutation: (state, email) => {
    state.email = email;
  },
  setPasswordMutation: (state, password) => {
    state.password = password;
  },
  setPasswordConfirmationMutation: (state, password_confirmation) => {
    state.password_confirmation = password_confirmation;
  },
  setProfilesMutation: (state, profiles) => {
    state.profiles = profiles;
  },
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
  toggleModalResetPasswordMutation: (state) => {
    state.modalResetPassword = !state.modalResetPassword;
  },
};

const actions = {
  clearErrorMessages: ({ commit }) => {
    commit("setErrorMessagesMutation", []);
  },
  setName: ({ commit }, payload) => {
    commit("setNameMutation", payload.value);
  },
  setUserId: ({ commit }, payload) => {
    commit("setUserIdMutation", payload);
  },
  setCpf: ({ commit }, payload) => {
    commit("setCpfMutation", payload.value);
  },
  setCellphoneNumber: ({ commit }, payload) => {
    commit("setCellphoneNumberMutation", payload.value);
  },
  setEmail: ({ commit }, payload) => {
    commit("setEmailMutation", payload.value);
  },
  setPassword: ({ commit }, payload) => {
    commit("setPasswordMutation", payload.value);
  },
  setPasswordConfirmation: ({ commit }, payload) => {
    commit("setPasswordConfirmationMutation", payload.value);
  },
  clearTeacher: ({ dispatch }) => {
    dispatch("load", null);
  },
  clearModalResetPassword: ({ commit }) => {
    commit("setPasswordMutation", null);
    commit("setPasswordConfirmationMutation", null);
  },
  openModalResetPassword: ({ dispatch, commit }, payload) => {
    dispatch("load", payload);
    commit("toggleModalResetPasswordMutation");
  },
  closeModalResetPassword: ({ commit }) => {
    commit("toggleModalResetPasswordMutation");
  },
  loadProfiles: ({ commit }) => {
    profiles()
      .then((response) => {
        commit("setProfilesMutation", response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  },
  saveTeacher: ({ commit, dispatch, getters }) => {
    let teacher = getters.getTeacherForApi;
    let ability_profileID;
    state.profiles.forEach((item) => {
      if (item.description == "Instrutor") {
        ability_profileID = item.id;
      }
    });
    const objUser = {
      id: teacher.id,
      user_id: teacher.user_id,
      name: teacher.name,
      cpf: teacher.cpf,
      active: teacher.active,
      cellphone_number: teacher.cellphone_number,
      city_id: teacher.city_id,
      email: teacher.email,
      confirmed_at: new Date(),
      modalResetPassword: teacher.modalResetPassword,
      password: teacher.password,
      password_confirmation: teacher.password_confirmation,
      phone_number: teacher.phone_number,
      errorMessages: teacher.errorMessages,
      ability_profile_id: ability_profileID,
    };
    createUser(objUser)
      .then((response) => {
        dispatch("setUserId", response.data.data.id);
        teacher = getters.getTeacherForApi;
        const objTeacher = {
          user_id: teacher.user_id,
          name: teacher.name,
          cpf: teacher.cpf,
          cellphone_number: teacher.cellphone_number,
          city_id: teacher.city_id,
          email: teacher.email,
        };
        createTeacher(objTeacher)
          .then((response) => {
            Vue.$toast.success(response.data.message);
            dispatch("Teachers/addTeacher", response.data.teacher, { root: true });
            dispatch("Teachers/closeModalTeacher", null, { root: true });
          })
          .catch((e) => {
            Vue.$toast.error(
              "Não foi possivel cadastrar o instrutor! " + e.response.data.errors.full_messages + "!"
            );

            console.log(e);
            commit("setErrorMessagesMutation", e.response.data.errors);
          });
      })
      .catch((e) => {
        console.log(e);

        Vue.$toast.error(
          "Não foi possivel cadastrar o usuário! " + e.response.data.errors.full_messages + "!"
        );

        commit("setErrorMessagesMutation", e.response.errors);
      });
  },
  updateTeacher: ({ commit, state, dispatch, getters }) => {
    const teacher = getters.getTeacherForApi;

    updateTeacher(state.id, teacher)
      .then((response) => {
        Vue.$toast.success(response.data.message);
        dispatch("Teachers/updateTeacher", response.data.teacher, { root: true });
        dispatch("Teachers/closeModalTeacher", null, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(
          `Não foi possivel atualizar o mentor! Por favor verifique se todos os campos com ( * ) foram preenchidos`
        );
        console.log(e.response.data.errors);
        commit("setErrorMessagesMutation", e.response.data.errors);
      });
  },
  deleteTeacher: ({ rootState, dispatch }) => {
    const teacher_id = rootState.Teachers.teacherIdForDeletion;

    destroyTeacher(teacher_id)
      .then((response) => {
        const teachers = rootState.Teachers.teachers.filter((e) => {
          return e.id !== teacher_id;
        });
        dispatch("Teachers/setTeachers", teachers, { root: true });
        Vue.$toast.success(response.data.message);
      })
      .catch((e) => {
        console.log(e.response.data.errors);
        Vue.$toast.error(e.response.data.error);
      });
    dispatch("Teachers/openCloseDeleteTeacherConfirmation", null, { root: true });
  },
  load: ({ commit }, payload) => {
    if (payload && !isEmpty(payload)) {
      commit("setIdMutation", payload.id);
      commit("setUserIdMutation", payload.user_id);
      commit("setNameMutation", payload.name);
      commit("setCpfMutation", payload.cpf);
      commit("setCellphoneNumberMutation", payload.cellphone_number);
      commit("setEmailMutation", payload.email);
    } else {
      commit("setIdMutation", null);
      commit("setUserIdMutation", null);
      commit("setNameMutation", null);
      commit("setCpfMutation", null);
      commit("setCellphoneNumberMutation", null);
      commit("setEmailMutation", null);
    }
  },
  resetPassword: ({ commit, dispatch }) => {
    if (state.password == null || state.password_confirmation == null) {
      Vue.$toast.error("Campos de senha e confirmação de senha devem ser preenchidos!");
    } else if (state.password != state.password_confirmation) {
      Vue.$toast.error("Campos de senha e confirmação de senha foram preenchidos com valores diferentes!");
    } else {
      resetPassword(state.user_id, state.password, state.password_confirmation)
        .then(() => {
          Vue.$toast.success("Senha atualizada com sucesso!");
          dispatch("closeModalResetPassword");
          dispatch("Users/loadUsers", null, { root: true });
        })
        .catch((e) => {
          Vue.$toast.error("Não foi possível atualizar a senha!");
          console.log(e);
          commit("setErrorMessagesMutation", e.response.data.errors);
        });
    }
  },
  getByUserId: (user_id) => {
    getTeacherByUserId(user_id)
      .then(() => {})
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
};

const getters = {
  getTeacherForApi: (state, getters, rootState) => {
    let teacher = {
      ...rootState.Teacher,
      city_id: rootState.City.city.id,
    };

    return teacher;
  },
  getErrorMessage: (state) => (key, field) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      if (error.type === "blank") {
        if (field === "" || field === " " || field === undefined || field === null) {
          return error.message;
        } else {
          return null;
        }
      } else {
        return error.message;
      }
    } else {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
