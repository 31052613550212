var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-5" }, [
    _c(
      "div",
      {
        staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
        attrs: { id: "person-data" },
      },
      [
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Dados da Solicitação da Vaga",
                "type-text": "title-forms",
              },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Nome do Participante",
                text: _vm.inscription_filtered?.name
                  ? _vm.inscription_filtered?.name
                  : "Não informado",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-1" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Tipo da Vaga Solicitada",
                text: _vm.vacancy_type
                  ? _vm.vacancy_type.name
                  : "Não informada",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Articulador(a) Responsável",
                text: _vm.vacancy_request.articulator
                  ? _vm.vacancy_request.articulator
                  : "Não informado",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _vm.vacancy_type?.id == 0
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "CPF DA MÃE OU RESPONSÁVEL DO JOVEM",
                    text: _vm._f("VMask")(
                      _vm.vacancy_request.cpf_responsible_young_person
                        ? _vm.vacancy_request.cpf_responsible_young_person
                        : "Não informado",
                      "###.###.###-##"
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id == 0
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "ENDEREÇO DA MÃE OU RESPONSÁVEL DO JOVEM",
                    text: _vm.vacancy_request.address_responsible_young_person
                      ? _vm.vacancy_request.address_responsible_young_person
                      : "Não informado",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id == 0
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "CONTATO ATUALIZADO DO JOVEM\n        ",
                    text: _vm._f("VMask")(
                      _vm.vacancy_request.updated_contact_young_person
                        ? _vm.vacancy_request.updated_contact_young_person
                        : "Não informado",
                      "(##) #####-####"
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id == 0
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "ESCOLA DE INTERESSE",
                    text: _vm.facility ? _vm.facility.name : "Não informada",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id == 0
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "TURNO DE INTERESSE",
                    text: _vm.interest_shift
                      ? _vm.interest_shift.name
                      : "Não informada",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id == 0
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "SÉRIE DE INTERESSE",
                    text: _vm.interest_serie
                      ? _vm.interest_serie.name
                      : "Não informada",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id == 0
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "REDE A QUE PERTENCE A ESCOLA",
                    text: _vm.school_network
                      ? _vm.school_network.name
                      : "Não informada",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id == 0
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "DESCRIÇÃO DO CASO",
                    text: _vm.vacancy_request.case_description
                      ? _vm.vacancy_request.case_description
                      : "Não informado",
                    "type-text": "topic-details",
                    classe: "break-words",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id === 0
          ? _c(
              "div",
              { staticClass: "col-span-5" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "INTERVENÇÕES JÁ REALIZADAS",
                    text: _vm.vacancy_request.interventions_performed
                      ? _vm.vacancy_request.interventions_performed
                      : "Não informado",
                    "type-text": "topic-details",
                    classe: "break-words",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id === 0
          ? _c(
              "div",
              { staticClass: "col-span-3" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "TIPO DE ENCAMINHAMENTO",
                    text: _vm.vacancy_request.forwarding_type
                      ? _vm.vacancy_request.forwarding_type
                      : "Não informado",
                    "type-text": "topic-details",
                    classe: "break-words",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id === 1
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "NOME COMPLETO DO RESPONSÁVEL PELA CRIANÇA",
                    text: _vm.vacancy_request.full_name_responsible_child
                      ? _vm.vacancy_request.full_name_responsible_child
                      : "Não informado",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id == 1
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "NOME COMPLETO DA CRIANÇA",
                    text: _vm.vacancy_request.child_full_name
                      ? _vm.vacancy_request.child_full_name
                      : "Não informado",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id == 1
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "CPF DA MÃE OU RESPONSÁVEL PELA CRIANÇA",
                    text: _vm._f("VMask")(
                      _vm.vacancy_request.cpf_responsible_child
                        ? _vm.vacancy_request.cpf_responsible_child
                        : "Não informado",
                      "###.###.###-##"
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id == 1
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "DATA DE NASCIMENTO DA CRIANÇA",
                    text: _vm.vacancy_request.child_birthdate
                      ? _vm.formattedDate(_vm.vacancy_request.child_birthdate)
                      : "Não informado",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id == 1
          ? _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "ENDEREÇO DO RESPONSÁVEL PELA CRIANÇA",
                    text: _vm.vacancy_request.address_responsible_child
                      ? _vm.vacancy_request.address_responsible_child
                      : "Não informado",
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _vm.vacancy_type?.id == 1
          ? _c(
              "div",
              { staticClass: "col-span-1" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "CONTATO DO RESPONSÁVEL PELA CRIANÇA",
                    text: _vm._f("VMask")(
                      _vm.vacancy_request.contact_responsible_child
                        ? _vm.vacancy_request.contact_responsible_child
                        : "Não informado",
                      "(##) #####-####"
                    ),
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "SOLICITAÇÃO JÁ FOI ATENDIDA?",
                text: _vm.vacancy_request.accepted_request ? "Sim" : "Não",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "DEVOLUTIVA",
                text: _vm.vacancy_request.feedback
                  ? _vm.vacancy_request.feedback
                  : "Não informado",
                "type-text": "topic-details",
                classe: "break-words",
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }