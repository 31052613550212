import { getAxios, baseURL } from "./Api";

export async function getCoursesByCity(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let url = `/cidades/${id}/cursos/`;

  return api.get(url);
}

export async function getCourse(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let url = `/cursos/${id}`;

  return api.get(url);
}

export async function createCourse(course) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.post("/cursos", { courses: course });
}

export async function destroyCourse(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`cursos/${id}`);
}

export async function updateCourse(id, course) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`cursos/${id}`, { courses: course });
}
