var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Status",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Data Inicial",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Data Final",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Nº do Processo",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Matriz",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Parcela",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Remessa",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Nº de Beneficiários",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Valor Total (R$)",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "mt-4 flex" }),
      ]),
      _c(
        "BaseStaggeredFade",
        { attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.data, function (payment_sheet, index) {
          return _c(
            "li",
            { key: payment_sheet.id, attrs: { "data-index": index } },
            [
              _c(
                "router-link",
                {
                  attrs: {
                    to: {
                      name: "ScholarshipPaymentSheetDetail",
                      params: { id: payment_sheet.id },
                    },
                  },
                },
                [
                  _c(
                    "div",
                    [
                      _c("AppCardScholarshipPaymentSheet", {
                        attrs: { payment_sheet: payment_sheet },
                      }),
                    ],
                    1
                  ),
                  _c("div", { staticClass: "border-ashes w-full border" }),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }