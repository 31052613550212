var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.scholarship_matrix.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar Matriz",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Criar Matriz",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "ScholarshipMatrices/closeModalAddScholarshipMatrix",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações Gerais",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "Nome",
                            "input-name": "name",
                            "input-type": "text",
                            "show-label": "",
                            required: "",
                            "label-text": "Nome",
                            value: _vm.scholarship_matrix.name,
                            dispatch: "ScholarshipMatrices/setName",
                            "error-message": _vm.getErrorMessage("nome"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c(
                    "div",
                    { staticClass: "grid grid-cols-1 gap-4 mt-4" },
                    [
                      _c(
                        "div",
                        { staticClass: "col-span-1 text-burnedYellow pb-2" },
                        [
                          _c("Label", {
                            attrs: {
                              text: "Intervalos de Frequência e Valores",
                              icon: null,
                              "icon-type": null,
                              type: "text-sm",
                              weight: "semibold",
                              normal: "",
                              mode: "uppercase",
                            },
                          }),
                          _c("div", {
                            staticClass: "border-ashes-hover border-b-2 w-full",
                          }),
                        ],
                        1
                      ),
                      _vm._l(_vm.visibleIntervals, function (interval, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "grid grid-cols-3 gap-4 items-center",
                          },
                          [
                            _c("BaseInput", {
                              staticClass: "col-span-1",
                              attrs: {
                                "input-name": "frequency_min",
                                "input-type": "number",
                                "show-label": "",
                                inputMin: "0",
                                inputMax: "100",
                                required: "",
                                "label-text": "Frequência Mínima",
                                value: interval.frequency_min,
                                payload: { index, field: "frequency_min" },
                                dispatch: "ScholarshipMatrices/updateInterval",
                                "error-message": _vm.getErrorMessage(
                                  "intervalo de frequência mínimo"
                                ),
                              },
                            }),
                            _c("BaseInput", {
                              staticClass: "col-span-1",
                              attrs: {
                                "input-name": "frequency_max",
                                "input-type": "number",
                                "show-label": "",
                                inputMin: "0",
                                inputMax: "100",
                                required: "",
                                "label-text": "Frequência Máxima",
                                value: interval.frequency_max,
                                payload: { index, field: "frequency_max" },
                                dispatch: "ScholarshipMatrices/updateInterval",
                                "error-message": _vm.getErrorMessage(
                                  "intervalo de frequência máximo"
                                ),
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "col-span-1 flex items-end" },
                              [
                                _c("BaseInput", {
                                  staticClass: "flex-grow",
                                  attrs: {
                                    "input-name": "value",
                                    "input-type": "number",
                                    "show-label": "",
                                    inputMin: "0",
                                    required: "",
                                    "label-text": "Valor",
                                    value: interval.value,
                                    payload: { index, field: "value" },
                                    dispatch:
                                      "ScholarshipMatrices/updateInterval",
                                    "error-message":
                                      _vm.getErrorMessage("valor"),
                                  },
                                }),
                                index >= 1
                                  ? _c(
                                      "a",
                                      {
                                        staticClass:
                                          "ml-2 text-burnedYellow underline",
                                        attrs: { href: "javascript:void(0)" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.removeInterval(index)
                                          },
                                        },
                                      },
                                      [
                                        _c("AppMaterialIcon", {
                                          attrs: {
                                            "icon-name": "delete",
                                            "icon-size": "36",
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      _c("div", { staticClass: "text-left mt-4" }, [
                        _c(
                          "a",
                          {
                            staticClass: "text-burnedYellow underline",
                            attrs: { href: "javascript:void(0)" },
                            on: { click: _vm.addInterval },
                          },
                          [
                            _c("AppMaterialIcon", {
                              staticClass: "align-bottom",
                              attrs: {
                                "icon-name": "add_circle",
                                "icon-size": "36",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "ScholarshipMatrices/closeModalAddScholarshipMatrix",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.scholarship_matrix.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Atualizar",
                                typeButton: "success",
                                dispatch: "ScholarshipMatrices/update",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "Criar",
                                typeButton: "success",
                                dispatch: "ScholarshipMatrices/save",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          223598833
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }