var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      this.data
        ? _c(
            "div",
            {
              staticClass:
                "mr-8 font-system uppercase font-medium text-xs text-ashes-dark",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.allChecked,
                    expression: "allChecked",
                  },
                ],
                staticClass: "mr-2",
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.allChecked)
                    ? _vm._i(_vm.allChecked, null) > -1
                    : _vm.allChecked,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.allChecked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.allChecked = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.allChecked = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.allChecked = $$c
                      }
                    },
                    _vm.onCheckAll,
                  ],
                },
              }),
              _c("label", { attrs: { for: "checkAll" } }, [
                _vm._v("Marcar todas as turmas"),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "my-6", attrs: { id: "tabs-button" } },
        [
          _c(
            "FilterComponent",
            [
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "initial_date",
                  "input-type": "date",
                  "show-label": "",
                  "label-text": "Data Inicial",
                  dispatch: "ModalExportTeamPresence/setInitialDate",
                },
              }),
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "initial_date",
                  "input-type": "date",
                  "show-label": "",
                  "label-text": "Data Final",
                  dispatch: "ModalExportTeamPresence/setFinalDate",
                },
              }),
              _vm.showExport
                ? _c("Multiselect", {
                    staticClass: "mr-5",
                    attrs: {
                      id: "filterArea",
                      selectPlaceholder: "Selecionar",
                      "label-text": "% de Frequência",
                      closeOnSelect: "",
                      required: "",
                      "show-label": "",
                      multipleOption: false,
                      options: _vm.period_presence_options,
                      value: _vm.period_presence,
                      dispatch: _vm.setPeriodPresence,
                    },
                  })
                : _vm._e(),
              _vm.period_presence?.id === 2 && _vm.showExport
                ? _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "initial_date",
                      "input-type": "date",
                      "show-label": "",
                      "label-text": "Porcentagem - Data Inicial",
                      dispatch:
                        "ModalExportTeamPresence/setPercentageInitialDate",
                    },
                  })
                : _vm._e(),
              _vm.period_presence?.id === 2 && _vm.showExport
                ? _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "initial_date",
                      "input-type": "date",
                      "show-label": "",
                      "label-text": "Porcentagem - Data Final",
                      dispatch:
                        "ModalExportTeamPresence/setPercentageFinalDate",
                    },
                  })
                : _vm._e(),
              _vm.showExport
                ? _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "min_percentage",
                      "input-type": "number",
                      "show-label": "",
                      inputMin: "0",
                      inputMax: "100",
                      "label-text": "% Inicial",
                      dispatch: "ModalExportTeamPresence/setMinPercentageDate",
                    },
                  })
                : _vm._e(),
              _vm.showExport
                ? _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "max_percentage",
                      "input-type": "number",
                      "show-label": "",
                      inputMin: "0",
                      inputMax: "100",
                      "label-text": "% Final",
                      dispatch: "ModalExportTeamPresence/setMaxPercentageDate",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _c("div", { staticClass: "grid grid-cols-2 items-center" }, [
        _c(
          "div",
          { staticClass: "ml-20" },
          [
            _c("BaseText", {
              attrs: {
                text: "Nome",
                typeText: "custom",
                weight: "medium",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "quantum",
              },
            }),
          ],
          1
        ),
        _c("div", [
          _c("div", { staticClass: "grid grid-cols-2" }, [
            _c(
              "div",
              { staticClass: "justify-self-end" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Nº de Estudantes",
                    typeText: "custom",
                    weight: "medium",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "quantum",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "justify-self-center" },
              [
                _c("BaseText", {
                  attrs: {
                    text: "Período",
                    typeText: "custom",
                    weight: "medium",
                    italic: false,
                    mode: "uppercase",
                    size: "xs",
                    color: "quantum",
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
      ]),
      _c(
        "BaseStaggeredFade",
        { attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.data, function (item, index) {
          return _c(
            "li",
            { key: item.id, attrs: { "data-index": index } },
            [
              _c("BaseCard", { class: _vm.liClass }, [
                _c(
                  "div",
                  { staticClass: "grid grid-cols-2 items-center pt-2" },
                  [
                    _c("div", [
                      _c(
                        "div",
                        { staticClass: "flex justify-start items-center" },
                        [
                          _c("div", { staticClass: "mr-10" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedItems,
                                  expression: "selectedItems",
                                },
                              ],
                              attrs: {
                                id: item.code,
                                type: "checkbox",
                                name: "teamsAllocations",
                              },
                              domProps: {
                                value: item.id,
                                checked: Array.isArray(_vm.selectedItems)
                                  ? _vm._i(_vm.selectedItems, item.id) > -1
                                  : _vm.selectedItems,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.onCheck(item, $event)
                                },
                                change: function ($event) {
                                  var $$a = _vm.selectedItems,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = item.id,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.selectedItems = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.selectedItems = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.selectedItems = $$c
                                  }
                                },
                              },
                            }),
                          ]),
                          _c(
                            "div",
                            { class: _vm.iconClass },
                            [
                              _c("AppMaterialIcon", {
                                staticClass: "place-self-center text-white",
                                attrs: {
                                  iconName: "book",
                                  iconType: "outlined",
                                  iconSize: "36",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("BaseText", {
                            staticClass: "mr-1",
                            attrs: {
                              text: item.code,
                              typeText: "custom",
                              mode: "underline",
                              weight: "bold",
                              size: "xl",
                            },
                          }),
                          _c("BaseText", {
                            staticClass: "mr-1",
                            attrs: {
                              text: "|",
                              typeText: "custom",
                              weight: "normal",
                              size: "2xl",
                            },
                          }),
                          _c("BaseText", {
                            staticClass: "mr-1",
                            attrs: {
                              text: item.discipline.name,
                              typeText: "custom",
                              mode: "underline",
                              weight: "bold",
                              size: "xl",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "justify-self-end" }, [
                      _c("div", { staticClass: "grid grid-cols-2" }, [
                        _c(
                          "div",
                          [
                            _c("BaseText", {
                              attrs: {
                                text: `${item.qtd_students}`,
                                typeText: "custom",
                                size: "sm",
                                color: "ashes-dark",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("BaseText", {
                              attrs: {
                                text: `${item.start} - ${item.closing}`,
                                typeText: "custom",
                                size: "sm",
                                color: "ashes-dark",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          )
        }),
        0
      ),
      _vm.loading ? _c("div", [_c("PreLoading")], 1) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }