var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", [
    _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.verifyStatus(),
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.inscription?.student?.name,
              mode: "normal-case",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.inscription?.student?.season?.name,
              mode: "normal-case",
              color: "ashes-dark",
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm._f("VMask")(
                _vm.inscription?.student?.cpf,
                "###.###.###-##"
              ),
              mode: "normal-case",
              color: "ashes-dark",
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.getSituation(),
              mode: "normal-case",
              color: "ashes-dark",
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.inscription?.last_psychosocial_accompaniment
                ?.accompaniment_status?.name,
              mode: "normal-case",
              color: "ashes-dark",
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.formattedDate(
                _vm.inscription?.last_psychosocial_accompaniment
                  ?.date_accompaniment
              ),
              mode: "normal-case",
              color: "ashes-dark",
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.inscription?.student?.neighborhood?.area?.name,
              mode: "normal-case",
              color: "ashes-dark",
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.getEquipmentName(),
              mode: "normal-case",
              color: "ashes-dark",
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4 flex" },
        [
          _c(
            "router-link",
            { attrs: { to: "/psicossocial/pagina_principal" } },
            [
              _c(
                "a",
                {
                  attrs: { href: "javascript:void(0)" },
                  on: {
                    click: function ($event) {
                      return _vm.setInscription()
                    },
                  },
                },
                [
                  _c(
                    "div",
                    { class: _vm.classButtonAssignment },
                    [
                      _c("AppMaterialIcon", {
                        attrs: {
                          iconName: "badge",
                          iconType: "round",
                          iconSize: "36",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }