<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="opportunity.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="formattedDate(opportunity.opportunity_date)"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="opportunity.company ? opportunity.company.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="opportunity.partner ? opportunity.partner.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="opportunity.segment ? opportunity.segment.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="opportunity.occupation ? opportunity.occupation.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="opportunity.contracting_type ? opportunity.contracting_type.name : '-'"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="permitUpdate"
          iconName="edit"
          :payload="opportunity"
          dispatch="Opportunities/openEditModalAddOpportunity"
          title="Atualizar Seleção"
        />
        <AppButtonIcon
          v-if="permitShow"
          iconName="visibility"
          dispatch="Opportunities/openViewModal"
          :payload="opportunity"
          title="Visualizar Seleção"
        />
        <AppButtonIcon
          v-if="permitDelete"
          iconName="delete"
          :payload="opportunity"
          dispatch="Opportunities/toggleModalDeleteOpportunity"
          title="Deletar Seleção"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permit: false,
      permitUpdate: false,
      permitDelete: false,
      permitShow: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    opportunity: {
      type: Object,
    },
  },
  created() {
    this.opportunityAccess();
    this.opportunityDelete();
    this.opportunityUpdate();
    this.opportunityShow();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    opportunityAccess() {
      this.canAcces("opportunity_index").then((response) => {
        this.permit = response;
      });
    },
    opportunityDelete() {
      this.canAcces("opportunity_destroy").then((response) => {
        this.permitDelete = response;
      });
    },
    opportunityShow() {
      this.canAcces("opportunity_show").then((response) => {
        this.permitShow = response;
      });
    },
    opportunityUpdate() {
      this.canAcces("opportunity_update").then((response) => {
        this.permitUpdate = response;
      });
    },
    formattedDate: (data) => {
      if (data) {
        return data.split("-").reverse().join("/");
      }
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 22% 13% 16% 13% 12% 10% 8% 5%;
}
</style>
