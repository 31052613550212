import {
  createForwardingReservationRegistration,
  destroyForwardingReservationRegistration,
  getForwardingReservationRegistrationById,
  getForwardingReservationRegistrations,
  getForwardingReservationRegistrationsForExport,
  updateForwardingReservationRegistration,
} from "@/service/ForwardingReservationRegistrationsApi";
import { getCompaniesForMenus } from "@/service/CompaniesApi";
import { getPartnersForMenus } from "@/service/PartnersApi";
import { getInscriptionForProductiveInsertion } from "@/service/InscriptionApi";
import Vue from "vue";
import JsonExcel from "vue-json-excel";
import moment from "moment";
import { getEquipmentByAreaId } from "@/service/EquipmentApi";
import { getUsersForProductiveInsertion } from "@/service/UsersApi";
import { getQualificationStatus } from "@/service/StudentApi";

Vue.component("downloadExcel", JsonExcel);

const state = {
  forwarding_reservation_registration: {
    id: null,
    forwarding_date: null,
    city_id: localStorage.city_id,
    company_id: null,
    partner_id: null,
    inscription_id: null,
    user_id: localStorage.user_id,
    season_id: null,
  },
  incriptions_status: [
    {
      id: 0,
      name: "Desligado(a)",
    },
    {
      id: 3,
      name: "Ativo(a)",
    },
    {
      id: 5,
      name: "Concludente",
    },
  ],

  forwarding_reservation_registrations: [],
  inscriptions: [],
  users: [],
  companies: [],
  partners: [],
  segments: [],
  occupations: [],
  contracting_types: [],
  equipments_forwarding_reservation_registrations: [],
  user_areas: [],
  user_equipments: [],
  qualification_statuses: [],
  forwarding_reservation_registrations_spreadsheet: [],

  errorMessages: [],
  forwarding_reservation_registrationId: null,
  city: null,
  inscription: null,
  company: null,
  partner: null,
  user: null,
  season: null,
  user_areas_filtered: null,
  user_equipments_filtered: null,
  qualification_status: null,

  modalToggleAddForwardingReservationRegistration: false,
  modalToggleDeleteForwardingReservationRegistration: false,
  modalToggleViewForwardingReservationRegistration: false,
  modalToggleExportForwardingReservationRegistrationsSpreadsheet: false,

  hasSpreadsheetForwardingReservationRegistrationsResponse: true,
  json_data_forwarding_reservation_registration: null,

  forwarding_reservation_registrationsTotal: 0,
  forwarding_reservation_registrationsTotalPerPage: 10,
  forwarding_reservation_registrationsOffset: 0,

  params: {
    inscription_status: null,
    qualification_status: null,
    area: null,
    user: null,
    equipment: null,
    inscription: null,
    partner: null,
    company: null,
    initial_date: null,
    final_date: null,
  },
};

const mutations = {
  // MUTATIONS - LOADS DATA FROM API
  loadForwardingReservationRegistrations: (state, forwarding_reservation_registrations) => {
    state.forwarding_reservation_registrations = forwarding_reservation_registrations;
  },
  loadCompanies: (state, companies) => {
    state.companies = companies;
  },
  loadPartners: (state, partners) => {
    state.partners = partners;
  },
  loadUsers: (state, users) => {
    state.users = users;
  },
  loadInscriptions: (state, inscriptions) => {
    state.inscriptions = inscriptions;
  },
  loadQualificationStatuses: (state, qualification_statuses) => {
    state.qualification_statuses = qualification_statuses;
  },
  setUserAreasMutation: (state, user_areas) => {
    state.user_areas = user_areas;
  },
  setUserEquipmentsMutation: (state, user_equipments) => {
    state.user_equipments = user_equipments;
  },
  setEquipmentsMutation: (state, equipments_forwarding_reservation_registrations) => {
    state.equipments_forwarding_reservation_registrations = equipments_forwarding_reservation_registrations;
  },

  // MUTATIONS - FIELDS TO/FROM API
  setIdMutation: (state, id) => {
    state.forwarding_reservation_registration.id = id;
  },
  setForwardingDateMutation: (state, forwarding_date) => {
    state.forwarding_reservation_registration.forwarding_date = forwarding_date;
  },
  setCityMutation: (state, city) => {
    state.city = city;
    state.forwarding_reservation_registration.city_id = city ? city.id : null;
  },
  setInscriptionMutation: (state, inscription) => {
    state.inscription = inscription;
    state.forwarding_reservation_registration.inscription_id = inscription ? inscription.id : null;
  },
  setCompanyMutation: (state, company) => {
    state.company = company;
    state.forwarding_reservation_registration.company_id = company ? company.id : null;
  },
  setPartnerMutation: (state, partner) => {
    state.partner = partner;
    state.forwarding_reservation_registration.partner_id = partner ? partner.id : null;
  },
  setSeasonMutation: (state, season) => {
    state.season = season;
    state.forwarding_reservation_registration.season_id = season ? season.id : null;
  },
  setUserMutation: (state, user) => {
    state.user = user;
    if (user) {
      if (user.id) {
        state.forwarding_reservation_registration.user_id = user.id;
      } else {
        state.forwarding_reservation_registration.user_id = user.userId;
      }
    }
  },

  // --> MUTATIONS - DELETION <--
  forwarding_reservation_registrationForDeletion: (state, payload) => {
    if (payload) {
      state.forwarding_reservation_registrationId = payload.id;
    }
  },

  // --> MUTATIONS - FILTERLIST
  setFilterInscriptionStatusMutation: (state, inscription_status) => {
    state.params.inscription_status = inscription_status;
  },
  setFilterQualificationStatusMutation: (state, qualification_status) => {
    state.params.qualification_status = qualification_status;
  },
  setFilterAreaMutation: (state, area) => {
    state.params.area = area;
  },
  setFilterInscriptionMutation: (state, inscription) => {
    state.params.inscription = inscription;
  },
  setFilterPartnerMutation: (state, partner) => {
    state.params.partner = partner;
  },
  setFilterUserMutation: (state, user) => {
    state.params.user = user;
  },
  setFilterCompanyMutation: (state, company) => {
    state.params.company = company;
  },
  setFilterInitialDateMutation: (state, initial_date) => {
    state.params.initial_date = initial_date;
  },
  setFilterFinalDateMutation: (state, final_date) => {
    state.params.final_date = final_date;
  },
  setUserAreasFilteredMutation: (state, user_areas_filtered) => {
    state.user_areas_filtered = user_areas_filtered;
  },
  setFilterEquipmentMutation: (state, user_equipments_filtered) => {
    state.user_equipments_filtered = user_equipments_filtered;
  },

  // MUTATIONS - TOGGLES AND MODALS
  toggleModalAddForwardingReservationRegistration: (state) => {
    state.modalToggleAddForwardingReservationRegistration =
      !state.modalToggleAddForwardingReservationRegistration;
  },
  toggleModalDeleteForwardingReservationRegistration: (state) => {
    state.modalToggleDeleteForwardingReservationRegistration =
      !state.modalToggleDeleteForwardingReservationRegistration;
  },
  toggleModalViewForwardingReservationRegistration: (state) => {
    state.modalToggleViewForwardingReservationRegistration =
      !state.modalToggleViewForwardingReservationRegistration;
  },

  // TOGGLE ANDS MODALS - EXPORT SPREADSHEETS
  toggleModalToggleExportForwardingReservationRegistrationsSpreadsheet: (state) => {
    state.modalToggleExportForwardingReservationRegistrationsSpreadsheet =
      !state.modalToggleExportForwardingReservationRegistrationsSpreadsheet;
  },

  // --> MUTATIONS - SPREADSHEET <--
  hasSpreadsheetForwardingReservationRegistrationsResponseMutation: (
    state,
    hasSpreadsheetForwardingReservationRegistrationsResponse
  ) => {
    state.hasSpreadsheetForwardingReservationRegistrationsResponse =
      hasSpreadsheetForwardingReservationRegistrationsResponse;
  },

  setForwardingReservationRegistrationsPageToExportMutation: (state, forwarding_reservation_registration) => {
    state.json_data_forwarding_reservation_registration = null;
    const arrayData = [];
    forwarding_reservation_registration.forEach((element, index, array) => {
      let company = array[index].company ? array[index].company : "-";
      let equipment = array[index].equipment ? array[index].equipment.name : "-";
      let area =
        array[index].neighborhood && array[index].neighborhood.area
          ? array[index].neighborhood.area.name
          : "-";
      let participant_name = array[index].participant_name ? array[index].participant_name : null;
      let participant_status = array[index].participant_status ? array[index].participant_status : null;
      let partner = array[index].partner ? array[index].partner : null;
      let user = array[index].user ? array[index].user : null;
      let forwarding_date = array[index].forwarding_date
        ? moment(array[index].forwarding_date).format("DD/MM/YYYY")
        : "-";
      let forwarding_position =
        array[index].forwarding_position !== null ? array[index].forwarding_position + "ª vez" : null;

      const arrayItem = [];
      arrayItem["participant_status"] = participant_status;
      arrayItem["area"] = area;
      arrayItem["equipment"] = equipment;
      arrayItem["participant_name"] = participant_name;
      arrayItem["partner"] = partner;
      arrayItem["company"] = company;
      arrayItem["user"] = user;
      arrayItem["forwarding_date"] = forwarding_date;
      arrayItem["forwarding_position"] = forwarding_position;
      arrayData.push(arrayItem);
    });
    state.json_data_forwarding_reservation_registration = arrayData;
    state.hasSpreadsheetForwardingReservationRegistrationsResponse = true;
    state.modalToggleExportForwardingReservationRegistrationsSpreadsheet = true;
  },

  // --> MUTATIONS - PAGINATION FIELDS <--
  setForwardingReservationRegistrationsTotalPerPage: (
    state,
    forwarding_reservation_registrationsTotalPerPage
  ) => {
    state.forwarding_reservation_registrationsTotalPerPage = forwarding_reservation_registrationsTotalPerPage;
  },
  setForwardingReservationRegistrationsOffset: (state, forwarding_reservation_registrationsOffset) => {
    state.forwarding_reservation_registrationsOffset = forwarding_reservation_registrationsOffset;
  },
  setForwardingReservationRegistrationsTotal: (state, forwarding_reservation_registrationsTotal) => {
    state.forwarding_reservation_registrationsTotal = forwarding_reservation_registrationsTotal;
  },

  // --> MUTATIONS - CLEARS <--
  clearFields: (state) => {
    for (const prop in state.forwarding_reservation_registration) {
      state.forwarding_reservation_registration[prop] = null;
    }
    state.forwarding_reservation_registration.city_id = localStorage.city_id;
    state.forwarding_reservation_registration.season_id = JSON.parse(localStorage.season).id;
    state.forwarding_reservation_registration.user_id = localStorage.user_id;
    state.inscription = null;
    state.company = null;
    state.partner = null;
  },
  clearQuery: (state) => {
    state.params.inscription_status = null;
    state.params.area = null;
    state.params.equipment = null;
    state.params.inscription = null;
    state.params.opportunity = null;
    state.params.partner = null;
    state.params.company = null;
    state.params.user = null;
    state.params.qualification_status = null;
    state.params.initial_date = null;
    state.params.final_date = null;
    state.user_areas_filtered = null;
    state.user_equipments_filtered = null;
    state.forwarding_reservation_registrations = [];
  },
  clearLoads: (state) => {
    state.inscriptions = [];
    state.users = [];
    state.partners = [];
    state.companies = [];
  },

  // --> MUTATIONS - OTHER MUTATIONS <--
  setForwardingReservationRegistrationsSpreadsheetMutation: (
    state,
    forwarding_reservation_registrations_spreadsheet
  ) => {
    state.forwarding_reservation_registrations_spreadsheet = forwarding_reservation_registrations_spreadsheet;
  },

  // --> MUTATIONS - ERRORS <--
  setErrorMessagesMutation: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

// --> HELPER METHODS <--
function getUserAndEquipments() {
  let user_areas_ids = [];
  let user_equipments_ids = [];
  if (state.user_areas) {
    state.user_areas.forEach((item) => {
      user_areas_ids.push(item.id);
    });
  }
  if (state.user_equipments) {
    state.user_equipments.forEach((item) => {
      user_equipments_ids.push(item.id);
    });
  }

  if (state.user_areas_filtered) {
    user_areas_ids = state.user_areas_filtered.id;
  }
  if (state.user_equipments_filtered) {
    user_equipments_ids = state.user_equipments_filtered.id;
  }
  return { user_areas_ids, user_equipments_ids };
}

function getFilterParams(user_areas_ids, user_equipments_ids) {
  return {
    inscription_status: state.params.inscription_status ? state.params.inscription_status.id : null,
    city_id: localStorage.city_id,
    area_id: user_areas_ids,
    user_id: state.params.user ? state.params.user.id : null,
    qualification_status_id: state.params.qualification_status ? state.params.qualification_status.id : null,
    equipment_id: user_equipments_ids,
    company_id: state.params.company ? state.params.company.id : null,
    partner_id: state.params.partner ? state.params.partner.id : null,
    participant_name: state.params.inscription ? state.params.inscription.name : null,
    initial_date: state.params.initial_date,
    final_date: state.params.final_date,
    per_page: state.forwarding_reservation_registrationsTotalPerPage,
    offset: state.forwarding_reservation_registrationsOffset,
  };
}

const actions = {
  // ACTIONS - LOADS DATA FROM API
  loadForwardingReservationRegistrations: ({ commit }, from) => {
    let { user_areas_ids, user_equipments_ids } = getUserAndEquipments();
    const params = getFilterParams(user_areas_ids, user_equipments_ids);
    getForwardingReservationRegistrations(params)
      .then((response) => {
        commit("loadForwardingReservationRegistrations", response.data.forwarding_reservation_registrations);
        commit("setForwardingReservationRegistrationsTotal", response.data.total);
        if (from && parseInt(response.data.total, 10) < 1 && from !== "delete") {
          Vue.$toast.warning("Nenhum resultado para o filtro informado!");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadForwardingReservationRegistrationsForExport: ({ commit }) => {
    commit("hasSpreadsheetForwardingReservationRegistrationsResponseMutation", false);
    let { user_areas_ids, user_equipments_ids } = getUserAndEquipments();
    const params = getFilterParams(user_areas_ids, user_equipments_ids);
    getForwardingReservationRegistrationsForExport(params)
      .then(async (response) => {
        commit(
          "setForwardingReservationRegistrationsSpreadsheetMutation",
          response.data.forwarding_reservation_registrations
        );
        if (state.forwarding_reservation_registrations_spreadsheet.length > 0) {
          await commit(
            "setForwardingReservationRegistrationsPageToExportMutation",
            response.data.forwarding_reservation_registrations
          );
        } else {
          commit("hasSpreadsheetForwardingReservationRegistrationsResponseMutation", true);
          commit("toggleModalToggleExportForwardingReservationRegistrationsSpreadsheet");
        }
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadCompanies: ({ commit }, value) => {
    const params = {
      name: value,
      city_id: localStorage.city_id,
      perPage: 10,
    };
    getCompaniesForMenus(params)
      .then((response) => {
        commit("loadCompanies", response.data.companies);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadPartners: ({ commit }, value) => {
    const params = {
      name: value,
      city_id: localStorage.city_id,
      per_page: 10,
    };
    getPartnersForMenus(params)
      .then((response) => {
        commit("loadPartners", response.data.partners);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadUsers: ({ commit }, value) => {
    const ID_PERFIL_SUPERVISAO_TECNICA = 2;
    const ID_PERFIL_GERENTE_DE_AREA = 5;
    const ID_PERFIL_ADM_DE_AREA = 6;

    const params = {
      name: value,
      ability_profile_id: [ID_PERFIL_SUPERVISAO_TECNICA, ID_PERFIL_GERENTE_DE_AREA, ID_PERFIL_ADM_DE_AREA],
      per_page: 10,
    };
    getUsersForProductiveInsertion(params)
      .then((response) => {
        commit("loadUsers", response.data.user);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadInscriptions: ({ commit }, value) => {
    let { user_areas_ids, user_equipments_ids } = getUserAndEquipments();
    const params = {
      participant_name: value,
      area_id: user_areas_ids,
      equipment_id: user_equipments_ids,
      season_id: state.forwarding_reservation_registration.season_id,
      perPage: 10,
      is_filter: true,
    };
    getInscriptionForProductiveInsertion(params)
      .then((response) => {
        commit("loadInscriptions", response.data.inscriptions);
      })
      .catch((e) => {
        console.log("error", e.response);
      });
  },
  loadQualificationStatuses: ({ commit }) => {
    getQualificationStatus()
      .then((response) => {
        commit("loadQualificationStatuses", response.data.qualification_status);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  loadEquipments: ({ commit }) => {
    const params = {
      city_id: localStorage.city_id,
      area_id: state.user_areas_filtered.id,
    };
    getEquipmentByAreaId(params)
      .then((response) => {
        let equipments = [];
        if (state.user_equipments.length > 0) {
          response.data.equipment.forEach((item) => {
            state.user_equipments.forEach((element) => {
              if (item.id === element.id) {
                equipments.push(item);
              }
            });
          });
        } else {
          equipments = response.data.equipment;
        }
        commit("setEquipmentsMutation", equipments);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
  loadData: async ({ commit }, payload) => {
    await getForwardingReservationRegistrationById(payload.id)
      .then((response) => {
        commit("setIdMutation", response.data.forwarding_reservation_registrations.id);
        commit("setInscriptionMutation", response.data.forwarding_reservation_registrations.inscription);
        commit(
          "setForwardingDateMutation",
          response.data.forwarding_reservation_registrations.forwarding_date
        );
        commit("setCityMutation", response.data.forwarding_reservation_registrations.city);
        commit("setSeasonMutation", response.data.forwarding_reservation_registrations.season);
        commit("setUserMutation", response.data.forwarding_reservation_registrations.user);
        commit("setCompanyMutation", response.data.forwarding_reservation_registrations.company);
        commit("setPartnerMutation", response.data.forwarding_reservation_registrations.partner);
      })
      .catch((e) => {
        console.log("error", e); //TODO: tratar esse error
      });
  },

  // ACTIONS - FIELDS TO API
  setForwardingDate: ({ commit }, payload) => {
    commit("setForwardingDateMutation", payload.value);
  },
  setInscription: ({ commit }, payload) => {
    commit("setInscriptionMutation", payload);
  },
  setCompany: ({ commit }, payload) => {
    commit("setCompanyMutation", payload);
  },
  setPartner: ({ commit }, payload) => {
    commit("setPartnerMutation", payload);
  },
  setUser: ({ commit }, payload) => {
    commit("setUserMutation", payload);
  },
  setSeason: ({ commit }, payload) => {
    commit("setSeasonMutation", payload);
  },

  // ACTIONS - TOGGLES AND MODALS
  openModalAddForwardingReservationRegistration: async ({ commit }) => {
    commit("toggleModalAddForwardingReservationRegistration");
  },
  closeModalAddForwardingReservationRegistration: ({ commit }) => {
    commit("toggleModalAddForwardingReservationRegistration");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openEditModalAddForwardingReservationRegistration: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalAddForwardingReservationRegistration");
  },
  closeEditModalAddForwardingReservationRegistration: ({ commit }) => {
    commit("toggleModalAddForwardingReservationRegistration");
    commit("clearFields");
    commit("setErrorMessagesMutation", []);
  },
  openViewModal: async ({ commit, dispatch }, payload) => {
    await dispatch("loadData", payload);
    commit("toggleModalViewForwardingReservationRegistration");
  },
  closeViewModal: ({ commit }) => {
    commit("toggleModalViewForwardingReservationRegistration");
    commit("clearFields");
  },
  toggleModalDeleteForwardingReservationRegistration: ({ commit }, payload) => {
    commit("toggleModalDeleteForwardingReservationRegistration");
    if (state.modalToggleDeleteForwardingReservationRegistration && payload) {
      commit("forwarding_reservation_registrationForDeletion", payload);
    } else {
      commit("forwarding_reservation_registrationForDeletion", null);
    }
  },

  // TOGGLE AND MODALS - EXPORT SPREADSHEETS
  openExportForwardingReservationRegistrationsSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportForwardingReservationRegistrationsSpreadsheet");
  },
  closeExportForwardingReservationRegistrationsSpreadsheetModal: ({ commit }) => {
    commit("toggleModalToggleExportForwardingReservationRegistrationsSpreadsheet");
  },

  // --> ACTIONS - FILTERLIST
  setFilterInscription: ({ commit }, payload) => {
    commit("setFilterInscriptionMutation", payload);
  },
  setFilterInscriptionStatus: ({ commit }, payload) => {
    commit("setFilterInscriptionStatusMutation", payload);
  },
  setFilterQualificationStatus: ({ commit }, payload) => {
    commit("setFilterQualificationStatusMutation", payload);
  },
  setFilterArea: ({ commit }, payload) => {
    commit("setFilterAreaMutation", payload);
  },
  setFilterEquipment: ({ commit }, payload) => {
    commit("setFilterEquipmentMutation", payload);
  },
  setFilterCompany: ({ commit }, payload) => {
    commit("setFilterCompanyMutation", payload);
  },
  setFilterPartner: ({ commit }, payload) => {
    commit("setFilterPartnerMutation", payload);
  },
  setFilterUser: ({ commit }, payload) => {
    commit("setFilterUserMutation", payload);
  },
  setFilterInitialDate: ({ commit }, payload) => {
    commit("setFilterInitialDateMutation", payload.value);
  },
  setFilterFinalDate: ({ commit }, payload) => {
    commit("setFilterFinalDateMutation", payload.value);
  },
  setUserAreasFiltered: ({ commit }, payload) => {
    commit("setUserAreasFilteredMutation", payload);
    if (!payload) {
      commit("setEquipmentsMutation", []);
      commit("setFilterEquipmentMutation", null);
    }
  },

  // --> ACTIONS - PAGINATIONS <--
  setForwardingReservationRegistrationsTotalPerPage: ({ commit }, payload) => {
    commit("setForwardingReservationRegistrationsTotalPerPage", payload.totalPerPage);
  },
  setForwardingReservationRegistrationsOffset: ({ commit }, payload) => {
    commit("setForwardingReservationRegistrationsOffset", payload.offset);
  },

  // --> OTHERS ACTIONS <--
  clearQuery: ({ commit }) => {
    commit("clearQuery");
  },
  setUserAreas: ({ commit }, payload) => {
    commit("setUserAreasMutation", payload);
  },
  setUserEquipments: ({ commit }, payload) => {
    commit("setUserEquipmentsMutation", payload);
  },

  //ACTIONS - CRUD
  save: ({ commit, dispatch }) => {
    createForwardingReservationRegistration(state.forwarding_reservation_registration)
      .then(() => {
        Vue.$toast.success("Encaminhamento cadastrado com sucesso!");
        dispatch("closeModalAddForwardingReservationRegistration");
        dispatch("loadForwardingReservationRegistrations");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel cadastrar o encaminhamento! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  update: ({ commit, dispatch, state }) => {
    updateForwardingReservationRegistration(
      state.forwarding_reservation_registration.id,
      state.forwarding_reservation_registration
    )
      .then(() => {
        dispatch("loadForwardingReservationRegistrations");
        dispatch("closeEditModalAddForwardingReservationRegistration");
        Vue.$toast.success("Encaminhamento atualizado com sucesso!");
      })
      .catch((e) => {
        Vue.$toast.error("Não foi possivel atualizar a seleção! " + e.response.data.userMessage);
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
  delete: ({ commit, dispatch }) => {
    destroyForwardingReservationRegistration(state.forwarding_reservation_registrationId)
      .then(() => {
        const from = "delete";
        Vue.$toast.success("Encaminhamento atualizado com sucesso!");
        commit("toggleModalDeleteForwardingReservationRegistration");
        dispatch("loadForwardingReservationRegistrations", from);
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.userMessage + "!");
        commit("setErrorMessagesMutation", e.response.data);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key) => {
    let field = null;
    if (state.errorMessages.fields && state.errorMessages.fields.length > 0) {
      state.errorMessages.fields.forEach((item) => {
        if (item.name === key) {
          field = item.userMessage;
        }
      });
    }
    return field;
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
