var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { class: _vm.liClass }, [
    _c("div", { staticClass: "grid grid-cols-2 items-center pt-2" }, [
      _c("div", [
        _c(
          "div",
          { staticClass: "flex justify-start items-center" },
          [
            _c("div", { staticClass: "mr-10" }, [
              _c("input", {
                attrs: {
                  id: _vm.team.code,
                  type: "checkbox",
                  name: "teamsAllocations",
                },
                domProps: { value: _vm.team.id },
                on: {
                  click: function ($event) {
                    return _vm.onClick(_vm.team, $event)
                  },
                },
              }),
            ]),
            _c(
              "div",
              { class: _vm.iconClass },
              [
                _c("AppMaterialIcon", {
                  staticClass: "place-self-center text-white",
                  attrs: {
                    iconName: "book",
                    iconType: "outlined",
                    iconSize: "36",
                  },
                }),
              ],
              1
            ),
            _c("BaseText", {
              staticClass: "mr-1",
              attrs: {
                text: _vm.team.code,
                typeText: "custom",
                mode: "underline",
                weight: "bold",
                size: "xl",
              },
            }),
            _c("BaseText", {
              staticClass: "mr-1",
              attrs: {
                text: "|",
                typeText: "custom",
                weight: "normal",
                size: "2xl",
              },
            }),
            _c("BaseText", {
              staticClass: "mr-1",
              attrs: {
                text: _vm.team.discipline.name,
                typeText: "custom",
                mode: "underline",
                weight: "bold",
                size: "xl",
              },
            }),
          ],
          1
        ),
      ]),
      _c("div", { staticClass: "justify-self-end" }, [
        _c("div", { staticClass: "grid grid-cols-2" }, [
          _c(
            "div",
            [
              _vm.checkBenefitType
                ? _c("BaseText", {
                    attrs: {
                      text: "5",
                      typeText: "custom",
                      size: "sm",
                      color: "ashes-dark",
                    },
                  })
                : _c("BaseText", {
                    attrs: {
                      text: "20",
                      typeText: "custom",
                      size: "sm",
                      color: "ashes-dark",
                    },
                  }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseText", {
                attrs: {
                  text: `${_vm.team.start} - ${_vm.team.closing}`,
                  typeText: "custom",
                  size: "sm",
                  color: "ashes-dark",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }