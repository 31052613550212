var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.forwarding.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar Encaminhamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Cadastrar Encaminhamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch: "Forwardings/closeModal",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-start col-span-2" },
                      [
                        _c("BaseText", {
                          staticClass: "mr-1",
                          attrs: {
                            text: _vm.getUserName(),
                            typeText: "custom",
                            weight: "semibold",
                            color: "herbal",
                            size: "sm",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  !_vm.forwarding.id && !_vm.getTypePage()
                    ? _c("div", { staticClass: "grid grid-cols-2 gap-4" }, [
                        _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Selecionar Aluno(a)",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "-mt-2" },
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Selecione a Edição",
                                "label-text": "Edição",
                                "multiple-option": false,
                                value: _vm.season_filtered,
                                options: _vm.seasons,
                                required: "",
                                dispatch: _vm.setSeason,
                                readonly: true,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "-mt-2" },
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Selecione a Área",
                                "label-text": "Área",
                                "multiple-option": true,
                                value: _vm.user_areas_filtered,
                                options: _vm.user_areas,
                                required: "",
                                dispatch: _vm.setUserAreasFiltered,
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "-mt-2 col-span-2" },
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Selecione o aluno(a)",
                                "label-text": "Aluno(a)",
                                "multiple-option": false,
                                value: _vm.inscription,
                                options: _vm.inscriptions,
                                required: "",
                                dispatch: _vm.setInscription,
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  !_vm.forwarding.id &&
                  _vm.inscription &&
                  !_vm.togglePsychosocialAccompanimentForwardings
                    ? _c(
                        "div",
                        {
                          staticClass: "grid grid-cols-2 gap-4 mt-4",
                          attrs: { div: "" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col-span-2 text-burnedYellow pb-2",
                            },
                            [
                              _c("Label", {
                                attrs: {
                                  text: "Informações do Aluno(a) Selecionado",
                                  icon: null,
                                  "icon-type": null,
                                  type: "text-sm",
                                  weight: "semibold",
                                  normal: "",
                                  mode: "uppercase",
                                },
                              }),
                              _c("div", {
                                staticClass:
                                  "border-ashes-hover border-b-2 w-full",
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Nome",
                                  text: _vm.inscription.name,
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Status do Aluno(a) no sistema",
                                  text: _vm.verifyStatus(),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "CPF",
                                  text: _vm._f("VMask")(
                                    _vm.verifyInscription("cpf"),
                                    "###.###.###-##"
                                  ),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Data de Nascimento",
                                  text: _vm.verifyInscription("birthdate"),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Idade",
                                  text: _vm.verifyInscription("age"),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Número Contato",
                                  text: _vm._f("VMask")(
                                    _vm.verifyInscription("cellphone_number"),
                                    "(##) #####-####"
                                  ),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.forwarding.id && _vm.inscription
                    ? _c("div", { staticClass: "grid grid-cols-2 mt-4" }, [
                        _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Informações do Aluno(a)",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Nome Aluno(a)",
                                text: _vm.inscription.student.name,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Edição",
                                text: _vm.inscription.season.name,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Equipamento",
                                text: _vm.verifyInscription("equipment"),
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Status do Encaminhamento",
                                text: _vm.forwarding.forwarding_status.name,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.forwarding.id && _vm.psychosocial_accompaniment
                    ? _c("div", { staticClass: "grid grid-cols-2 mt-4" }, [
                        _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Informações do Acompanhamento Psicossocial",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Data do Encaminhamento",
                                text: _vm.formattedDate(
                                  _vm.psychosocial_accompaniment
                                    .date_accompaniment
                                ),
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Encontro aconteceu?",
                                text: _vm.verifyMeetingHappened(),
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Formato do Encontro",
                                text: _vm.psychosocial_accompaniment
                                  .meeting_format,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Local do Encontro",
                                text: _vm.psychosocial_accompaniment
                                  .meeting_place,
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                        _c(
                          "div",
                          { staticClass: "col-span-1" },
                          [
                            _c("AppTextField", {
                              attrs: {
                                "show-label": "",
                                "label-text": "Encaminhado?",
                                text: _vm.verifyIsForwarded(),
                                "type-text": "topic-details",
                              },
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações do Encaminhamento",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Selecione as opções",
                            "label-text": "Políticas de Encaminhamento",
                            "multiple-option": false,
                            value: _vm.forwarding_policy,
                            options: _vm.forwarding_policies,
                            required: "",
                            dispatch: _vm.setForwardingPolicy,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Selecione as opções",
                            "label-text": "Equipamentos",
                            "multiple-option": false,
                            value: _vm.facility,
                            options: _vm.facilities,
                            dispatch: _vm.setForwardingFacility,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Município",
                            "multiple-option": false,
                            value: _vm.city,
                            options: _vm.cities,
                            "label-text": "Município",
                            dispatch: _vm.setCity,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações de Data",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "expedition",
                            "input-type": "date",
                            value: _vm.forwardingDate,
                            "show-label": "",
                            required: "",
                            "label-text": "Data do Encaminhamento",
                            dispatch: "Forwardings/setForwardingDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "expedition",
                            "input-type": "date",
                            value: _vm.forwardingExpirationDate,
                            "show-label": "",
                            required: "",
                            "label-text": "Data de Expiração",
                            dispatch: "Forwardings/setForwardingExpirationDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "dif",
                            "input-type": "number",
                            value: _vm.differenceBetweenDates,
                            "show-label": "",
                            "label-text": "Total de Dias entre as Datas",
                            dispatch: "Forwardings/setDifferenceBetweenDates",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-1" },
                      [
                        _c("AppTextField", {
                          attrs: {
                            "show-label": "",
                            "label-text": "Data nominal de Expiração",
                            text: _vm.getFullDate(),
                            color: "carmesim",
                            "type-text": "custom",
                            weight: "semibold",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Descrição do Encaminhamento",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseTextArea", {
                          attrs: {
                            active: "",
                            inputName: "description",
                            labelText: "Descrição",
                            showLabel: "",
                            rows: "4",
                            value: _vm.forwarding.forwarding_description,
                            dispatch: "Forwardings/setForwardingDescription",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch: "Forwardings/closeModal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.forwarding.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Salvar",
                                typeButton: "success",
                                dispatch: "Forwardings/update",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "criar",
                                typeButton: "success",
                                dispatch: "Forwardings/save",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          27485457
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }