var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid grid-cols-7 mt-8" }, [
    _c(
      "div",
      { staticClass: "flex justify-start col-span-2" },
      [
        _c("div", { class: _vm.iconClass }, [
          _c("img", {
            attrs: {
              src: require("../assets/images/profile-vj.png"),
              alt: "Maria Antônia Soares",
            },
          }),
        ]),
        _c("BaseText", {
          staticClass: "mr-1",
          attrs: {
            text: _vm.student.teams_student.student.social_name
              ? _vm.student.teams_student.student.name +
                " - " +
                _vm.student.teams_student.student.social_name
              : _vm.student.teams_student.student.name,
            typeText: "custom",
            mode: "normal",
            weight: "semibold",
            size: "xl",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("BaseText", {
          staticClass: "mr-1",
          attrs: {
            text: _vm.student.team.code,
            typeText: "custom",
            mode: "normal",
            weight: "normal",
            size: "xl",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("BaseText", {
          staticClass: "mr-1",
          attrs: {
            text: _vm.student.phase.name,
            typeText: "custom",
            mode: "normal",
            weight: "normal",
            size: "xl",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("BaseText", {
          staticClass: "mr-1",
          attrs: {
            text: _vm.student.amount_meetings,
            typeText: "custom",
            mode: "normal",
            weight: "normal",
            size: "xl",
          },
        }),
      ],
      1
    ),
    _c(
      "div",
      [
        !(_vm.benefit.type_benefit === "public_transport")
          ? _c("BaseText", {
              staticClass: "mr-1",
              attrs: {
                text: _vm.student.presence_percent,
                typeText: "custom",
                mode: "normal",
                weight: "normal",
                size: "xl",
              },
            })
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      [
        _c("BaseText", {
          staticClass: "mr-1",
          attrs: {
            text: "R$" + _vm.student.value,
            typeText: "custom",
            mode: "normal",
            weight: "normal",
            size: "xl",
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }