import {
  saveSeason as saveSeasonApi,
  getSeasonsByCityId,
  updateSeason as updateSeasonApi,
} from "../../service/SeasonApi";
import Vue from "vue";

const state = {
  modalSeasonOpen: false,
  errorMessages: [],
  season: {
    id: null,
    name: null,
    date_inscription_start: null,
    date_inscription_end: null,
    date_execution_start: null,
    date_execution_end: null,
    areas_attributes: [],
    areas: [],
    city_id: 1,
  },
  emptySeason: {
    id: null,
    name: null,
    date_inscription_start: null,
    date_inscription_end: null,
    date_execution_start: null,
    date_execution_end: null,
    areas_attributes: [],
    areas: [],
    city_id: 1,
  },
  seasons: [],
  filteredSeasons: [],
};

const mutations = {
  toggleModalSeason: (state) => {
    state.modalSeasonOpen = !state.modalSeasonOpen;
  },
  setSeasonName: (state, name) => {
    state.season.name = name.value;
  },
  setSeasons: (state, seasons) => {
    state.seasons = seasons;
  },
  setSeasonAreas: (state, areas) => {
    state.season.areas_attributes = areas;
  },
  setDateInscriptionStart: (state, dateInscriptionStart) => {
    state.season.date_inscription_start = dateInscriptionStart.value;
  },
  setDateInscriptionEnd: (state, dateInscriptionEnd) => {
    state.season.date_inscription_end = dateInscriptionEnd.value;
  },
  setDateExecutionStart: (state, dateExecutionStart) => {
    state.season.date_execution_start = dateExecutionStart.value;
  },
  setDateExecutionEnd: (state, dateExecutionEnd) => {
    state.season.date_execution_end = dateExecutionEnd.value;
  },
  setCity: (state, city) => {
    state.season.city_id = city;
  },
  setSeason: (state, season) => {
    state.season = Object.assign(state.season, season);
  },
  clearSeasonMutation: (state) => {
    state.season = Object.assign(state.season, state.emptySeason);
  },
  setErrorMessages: (state, errorMessages) => {
    state.errorMessages = errorMessages;
  },
};

const actions = {
  closeModal: ({ commit }) => {
    commit("setErrorMessages", []);
    commit("clearSeasonMutation");
    commit("toggleModalSeason");
  },
  openModal: ({ commit }) => {
    commit("toggleModalSeason");
  },
  openEditModal: ({ commit }, payload) => {
    commit("toggleModalSeason");
    commit("setSeason", payload);
  },
  setSeasonName: ({ commit }, payload) => {
    commit("setSeasonName", payload);
  },
  setSeasonAreas: ({ commit }, payload) => {
    commit("setSeasonAreas", payload);
  },
  setDateInscriptionStart: ({ commit }, payload) => {
    commit("setDateInscriptionStart", payload);
  },
  setDateInscriptionEnd: ({ commit }, payload) => {
    commit("setDateInscriptionEnd", payload);
  },
  setDateExecutionStart: ({ commit }, payload) => {
    commit("setDateExecutionStart", payload);
  },
  setDateExecutionEnd: ({ commit }, payload) => {
    commit("setDateExecutionEnd", payload);
  },
  setSeasons: ({ commit }, payload) => {
    commit("setSeasons", payload);
  },
  loadSeasons: ({ commit, rootState }, payload) => {
    const params = {
      id: rootState.City.city.id,
      type: payload,
    };
    getSeasonsByCityId(params)
      .then((response) => {
        commit("setSeasons", response.data.seasons);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  updateSeason: ({ commit, state, dispatch, rootState }) => {
    commit("setCity", rootState.City.city.id);
    updateSeasonApi(state.season.id, state.season)
      .then((response) => {
        dispatch("closeModal");
        Vue.$toast.success(response.data.message);
        dispatch("loadSeasons");
        dispatch("Season/loadDataSeason", null, { root: true });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.errors[0].message);
        commit("setErrorMessages", e.response.data.errors);
        console.error("errors", e.response.data.errors);
      });
  },
  saveSeason: ({ commit, state, dispatch, rootState }) => {
    commit("setCity", rootState.City.city.id);
    saveSeasonApi(state.season)
      .then((response) => {
        dispatch("closeModal");
        Vue.$toast.success(response.data.message);
        dispatch("loadSeasons");
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.message);
        commit("setErrorMessages", e.response.data.errors);
        console.error(e.response.data.errors);
      });
  },
};

const getters = {
  getErrorMessage: (state) => (key, field) => {
    let error = state.errorMessages.find((errorMessage) => {
      return errorMessage.attribute === key;
    });

    if (error) {
      switch (error.type) {
        case "blank":
          if (field === "" || field === " " || field === undefined || field === null || field.length === 0) {
            return error.message;
          } else {
            return null;
          }
        case "invalid":
          return error.message;

        default:
          return null;
      }
    } else {
      return null;
    }
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};
