var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open && _vm.season
    ? _c("div", [
        _c(
          "div",
          { staticClass: "mt-3 ml-2", attrs: { id: "tabs-button" } },
          [
            _c("FilterComponent", [
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "label-text": "Filtrar por Status",
                      "clear-on-select": "",
                      "select-placeholder": "Status",
                      "multiple-option": false,
                      value: _vm.status_student_filtered,
                      options: _vm.status_student,
                      dispatch: _vm.setFilterStatusNotReinsertedMainPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-3" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "label-text": "Filtrar por Nome do Aluno(a)",
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder":
                        "Digite o nome do aluno(a) para busca",
                      "multiple-option": false,
                      value: _vm.inscription_filtered,
                      options: _vm.inscriptionsForGeneralMenus,
                      dispatch: _vm.setFilterInscriptionNotReinsertedMainPage,
                      "load-dispatch":
                        "SchoolReinsertions/loadInscriptionsForGeneralMenus",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Município",
                      "multiple-option": false,
                      value: _vm.city,
                      options: _vm.cities,
                      "label-text": "Filtrar por Município",
                      dispatch: _vm.setFilterCityNotReinsertedMainPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "label-text": "Selecionar Área",
                      "clear-on-select": "",
                      "select-placeholder": "Área",
                      "multiple-option": false,
                      value: _vm.user_areas_filtered,
                      options: _vm.user_areas,
                      dispatch: _vm.setUserAreasFiltered,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Equipamento",
                      "multiple-option": false,
                      value: _vm.user_equipments_filtered,
                      options: _vm.equipments_school_reinsertions,
                      "label-text": _vm.getLabelEquipmentFilter(),
                      dispatch: _vm.setFilterEquipmentNotReinsertedMainPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "articulator",
                      "input-type": "text",
                      value: _vm.articulator_filtered,
                      "show-label": "",
                      "label-text": "Articulador(a) Responsável",
                      dispatch:
                        "SchoolReinsertions/setFilterArticulatorNotReinsertedMainPage",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "label-text": "Encaminhamentos Institucionais",
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Encaminhamentos Institucionais",
                      "multiple-option": false,
                      value: _vm.institutional_referral_filtered,
                      options: _vm.institutional_referrals,
                      dispatch:
                        _vm.setFilterInstitutionalReferralNotReinsertedMainPage,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "label-text": "Status dos Acompanhamentos",
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Status dos Acompanhamentos",
                      "multiple-option": false,
                      value: _vm.accompaniment_status_filtered,
                      options: _vm.accompaniment_statuses,
                      dispatch:
                        _vm.setFilterAccompanimentStatusNotReinsertedMainPage,
                    },
                  }),
                ],
                1
              ),
            ]),
            _vm.permitExportInscriptionSpreadsheet
              ? _c(
                  "div",
                  { staticClass: "col-span-2 mt-3 mb-2" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.exportToExcel("inscriptions")
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticClass: "inline-block",
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            src: require("../../../assets/images/logout_black_24dp.svg"),
                            alt: "",
                          },
                        }),
                        _c("BaseText", {
                          staticStyle: { cursor: "pointer" },
                          attrs: {
                            text: "Exportar planilha de Não Reinseridos",
                            typeText: "custom",
                            italic: false,
                            size: "xs",
                            color: "burnedYellow",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.modalToggleExportSchoolReinsertionsInscriptionsSpreadsheet
                      ? _c("BaseModal", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "grid grid-cols-3" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "justify-self-start col-span-2",
                                          },
                                          [
                                            _c("Label", {
                                              staticClass: "text-ashes-dark",
                                              attrs: {
                                                text: "Exportar Planilha de Não Reinseridos",
                                                icon: null,
                                                "icon-type": null,
                                                type: "text-2xl",
                                                weight: "bold",
                                                normal: "",
                                                mode: "normal-case",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "justify-self-end" },
                                          [
                                            _c("AppButtonIcon", {
                                              attrs: {
                                                dispatch:
                                                  "SchoolReinsertions/closeExportSchoolReinsertionsInscriptionSpreadsheetModal",
                                                forClose: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "footer",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "grid grid-rows-2" },
                                      [
                                        _vm
                                          .school_reinsertions_inscriptions_spreadsheet
                                          .length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid justify-center items-center",
                                              },
                                              [
                                                _c("Label", {
                                                  staticClass:
                                                    "text-ashes-dark",
                                                  attrs: {
                                                    text: "Planilha gerada com sucesso!",
                                                    icon: null,
                                                    "icon-type": null,
                                                    type: "text-2x1",
                                                    weight: "normal",
                                                    normal: "",
                                                    mode: "normal-case",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid justify-center items-center",
                                              },
                                              [
                                                _c("Label", {
                                                  staticClass:
                                                    "text-ashes-dark",
                                                  attrs: {
                                                    text: "Nenhum registro encontrado!",
                                                    icon: null,
                                                    "icon-type": null,
                                                    type: "text-2x1",
                                                    weight: "normal",
                                                    normal: "",
                                                    mode: "normal-case",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                        _vm
                                          .school_reinsertions_inscriptions_spreadsheet
                                          .length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "grid grid-cols-2",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "justify-self-start",
                                                  },
                                                  [
                                                    _c("AppButtonEmpty", {
                                                      attrs: {
                                                        text: "descartar",
                                                        typeButton: "danger",
                                                        dispatch:
                                                          "SchoolReinsertions/closeExportSchoolReinsertionsInscriptionSpreadsheetModal",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "justify-self-end",
                                                  },
                                                  [
                                                    _c(
                                                      "download-excel",
                                                      {
                                                        attrs: {
                                                          type: "xls",
                                                          meta: _vm.json_meta,
                                                          fields:
                                                            _vm.json_fields_inscriptions,
                                                          data: _vm.json_data_school_reinsertion_inscriptions,
                                                          "before-finish":
                                                            _vm.closeExportSchoolReinsertionsInscriptionSpreadsheetModal,
                                                          worksheet:
                                                            "Inscritos",
                                                          name: "inscritos_nao_reinseridos.xls",
                                                          escapeCsv: false,
                                                        },
                                                      },
                                                      [
                                                        _c("AppButtonEmpty", {
                                                          attrs: {
                                                            text: "Download da planilha",
                                                            typeButton:
                                                              "success",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            3214416088
                          ),
                        })
                      : _vm._e(),
                    !_vm.hasSpreadsheetSchoolReinsertionsInscriptionsResponse
                      ? _c("div", [_c("PreLoading")], 1)
                      : _vm._e(),
                    _vm.permitExportSchoolReinsertionSpreadsheet
                      ? _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function ($event) {
                                return _vm.exportToExcel("school_reinsertions")
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "inline-block ml-4",
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                src: require("../../../assets/images/logout_black_24dp.svg"),
                                alt: "",
                              },
                            }),
                            _c("BaseText", {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                text: "Exportar planilha de Atividades",
                                typeText: "custom",
                                italic: false,
                                size: "xs",
                                color: "burnedYellow",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.modalToggleExportSchoolReinsertionsEvolutionSpreadsheet
                      ? _c("BaseModal", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "grid grid-cols-3" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "justify-self-start col-span-2",
                                          },
                                          [
                                            _c("Label", {
                                              staticClass: "text-ashes-dark",
                                              attrs: {
                                                text: "Exportar Planilha de Atividades",
                                                icon: null,
                                                "icon-type": null,
                                                type: "text-2xl",
                                                weight: "bold",
                                                normal: "",
                                                mode: "normal-case",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "justify-self-end" },
                                          [
                                            _c("AppButtonIcon", {
                                              attrs: {
                                                dispatch:
                                                  "SchoolReinsertions/closeExportSchoolReinsertionsEvolutionSpreadsheetModal",
                                                forClose: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "footer",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "grid grid-rows-2" },
                                      [
                                        _vm
                                          .school_reinsertions_evolution_spreadsheet
                                          .length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid justify-center items-center",
                                              },
                                              [
                                                _c("Label", {
                                                  staticClass:
                                                    "text-ashes-dark",
                                                  attrs: {
                                                    text: "Planilha gerada com sucesso!",
                                                    icon: null,
                                                    "icon-type": null,
                                                    type: "text-2x1",
                                                    weight: "normal",
                                                    normal: "",
                                                    mode: "normal-case",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid justify-center items-center",
                                              },
                                              [
                                                _c("Label", {
                                                  staticClass:
                                                    "text-ashes-dark",
                                                  attrs: {
                                                    text: "Nenhum registro encontrado!",
                                                    icon: null,
                                                    "icon-type": null,
                                                    type: "text-2x1",
                                                    weight: "normal",
                                                    normal: "",
                                                    mode: "normal-case",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                        _vm
                                          .school_reinsertions_evolution_spreadsheet
                                          .length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass: "grid grid-cols-2",
                                              },
                                              [
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "justify-self-start",
                                                  },
                                                  [
                                                    _c("AppButtonEmpty", {
                                                      attrs: {
                                                        text: "descartar",
                                                        typeButton: "danger",
                                                        dispatch:
                                                          "SchoolReinsertions/closeExportSchoolReinsertionsEvolutionSpreadsheetModal",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                                _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "justify-self-end",
                                                  },
                                                  [
                                                    _c(
                                                      "download-excel",
                                                      {
                                                        attrs: {
                                                          type: "xls",
                                                          meta: _vm.json_meta,
                                                          fields:
                                                            _vm.json_fields,
                                                          data: _vm.json_data_school_reinsertion_evolution,
                                                          "before-finish":
                                                            _vm.closeExportSchoolReinsertionsEvolutionSpreadsheetModal,
                                                          worksheet:
                                                            "Atividades",
                                                          name: "atividades_nao_reinseridos.xls",
                                                          escapeCsv: false,
                                                        },
                                                      },
                                                      [
                                                        _c("AppButtonEmpty", {
                                                          attrs: {
                                                            text: "Download da planilha",
                                                            typeButton:
                                                              "success",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            4151859348
                          ),
                        })
                      : _vm._e(),
                    !_vm.hasSpreadsheetSchoolReinsertionsEvolutionResponse
                      ? _c("div", [_c("PreLoading")], 1)
                      : _vm._e(),
                    _vm.permitExportVacationRequestSpreadsheet
                      ? _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function ($event) {
                                return _vm.exportToExcel("vacancy_requests")
                              },
                            },
                          },
                          [
                            _c("img", {
                              staticClass: "inline-block ml-4",
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                src: require("../../../assets/images/logout_black_24dp.svg"),
                                alt: "",
                              },
                            }),
                            _c("BaseText", {
                              staticStyle: { cursor: "pointer" },
                              attrs: {
                                text: "Exportar planilha de Solicitações de Vagas",
                                typeText: "custom",
                                italic: false,
                                size: "xs",
                                color: "burnedYellow",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.modalToggleExportVacancyRequestsSpreadsheet
                      ? _c("BaseModal", {
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "header",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "grid grid-cols-3" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "justify-self-start col-span-2",
                                          },
                                          [
                                            _c("Label", {
                                              staticClass: "text-ashes-dark",
                                              attrs: {
                                                text: "Exportar Planilha de Solicitações de Vagas",
                                                icon: null,
                                                "icon-type": null,
                                                type: "text-2xl",
                                                weight: "bold",
                                                normal: "",
                                                mode: "normal-case",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "justify-self-end" },
                                          [
                                            _c("AppButtonIcon", {
                                              attrs: {
                                                dispatch:
                                                  "VacancyRequests/closeExportVacancyRequestsSpreadsheetModal",
                                                forClose: "",
                                              },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                              {
                                key: "footer",
                                fn: function () {
                                  return [
                                    _c(
                                      "div",
                                      { staticClass: "grid grid-rows-2" },
                                      [
                                        _vm.vacancy_requests_spreadsheet
                                          .length > 0
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid justify-center items-center",
                                              },
                                              [
                                                _c("Label", {
                                                  staticClass:
                                                    "text-ashes-dark",
                                                  attrs: {
                                                    text: "Planilha gerada com sucesso!",
                                                    icon: null,
                                                    "icon-type": null,
                                                    type: "text-2x1",
                                                    weight: "normal",
                                                    normal: "",
                                                    mode: "normal-case",
                                                  },
                                                }),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "grid justify-center items-center",
                                              },
                                              [
                                                _c("Label", {
                                                  staticClass:
                                                    "text-ashes-dark",
                                                  attrs: {
                                                    text: "Nenhum registro encontrado!",
                                                    icon: null,
                                                    "icon-type": null,
                                                    type: "text-2x1",
                                                    weight: "normal",
                                                    normal: "",
                                                    mode: "normal-case",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                        _c(
                                          "div",
                                          { staticClass: "grid grid-cols-2" },
                                          [
                                            _vm.vacancy_requests_spreadsheet
                                              .length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "justify-self-start",
                                                  },
                                                  [
                                                    _c("AppButtonEmpty", {
                                                      attrs: {
                                                        text: "descartar",
                                                        typeButton: "danger",
                                                        dispatch:
                                                          "VacancyRequests/closeExportVacancyRequestsSpreadsheetModal",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                            _vm.vacancy_requests_spreadsheet
                                              .length > 0
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "justify-self-end",
                                                  },
                                                  [
                                                    _c(
                                                      "download-excel",
                                                      {
                                                        attrs: {
                                                          type: "xls",
                                                          meta: _vm.json_meta,
                                                          fields:
                                                            _vm.json_fields_vacancy_requests,
                                                          data: _vm.json_data_vacancy_request,
                                                          "before-finish":
                                                            _vm.closeExportVacancyRequestsSpreadsheetModal,
                                                          worksheet:
                                                            "Solicitacoes_vagas",
                                                          name: "solicitacoes_vagas.xls",
                                                          escapeCsv: false,
                                                        },
                                                      },
                                                      [
                                                        _c("AppButtonEmpty", {
                                                          attrs: {
                                                            text: "Download da planilha",
                                                            typeButton:
                                                              "success",
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            452344038
                          ),
                        })
                      : _vm._e(),
                    !_vm.hasSpreadsheetVacancyRequestsResponse
                      ? _c("div", [_c("PreLoading")], 1)
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "grid grid-cols-2 items-center",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              { staticClass: "mx-2" },
              [
                _c("AppButtonColorful", {
                  attrs: {
                    id: "btn-consult-inscriptions",
                    showIcon: "",
                    iconName: "search",
                    text: "Consultar dados",
                    dispatch: "SchoolReinsertions/loadInscriptions",
                    payload: _vm.payload_load,
                    typeButton: "primary",
                  },
                }),
              ],
              1
            ),
            _vm.inscriptions.length > 0
              ? _c(
                  "div",
                  { staticClass: "mx-2 items-center justify-self-end" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.clearFields()
                          },
                        },
                      },
                      [
                        _c("AppButtonColorful", {
                          attrs: {
                            id: "btn-consult-incident_reports",
                            showIcon: "",
                            iconName: "remove",
                            text: "Limpar consulta",
                            typeButton: "primary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c("div", { attrs: { id: "list-psychosocials" } }, [
          _c(
            "div",
            { staticClass: "mx-12" },
            [
              _c("SchoolReinsertionNotReinsertedsCards", {
                attrs: { data: _vm.inscriptions },
              }),
            ],
            1
          ),
          _vm.inscriptions.length > 0
            ? _c(
                "div",
                {
                  staticClass: "mx-12 my-6 pb-3",
                  attrs: { id: "school-reinsertion-pagination" },
                },
                [
                  _c("BasePagination", {
                    attrs: {
                      total: _vm.inscriptionsTotal,
                      "total-per-page": _vm.totalPerPage,
                      dispatch: "SchoolReinsertions/setOffsetNotReinserteds",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }