var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-2 gap-10 items-center mt-4" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "ml-7" },
            [
              _c("BaseText", {
                attrs: {
                  text: "Nome",
                  typeText: "custom",
                  weight: "bold",
                  italic: false,
                  mode: "uppercase",
                  size: "xs",
                  color: "ashes-dark",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "grid grid-cols-4 justify-items-end" }, [
          _c(
            "div",
            { staticClass: "justify-self-start" },
            [
              _c("BaseText", {
                attrs: {
                  text: "Módulo",
                  typeText: "custom",
                  weight: "bold",
                  italic: false,
                  mode: "uppercase",
                  size: "xs",
                  color: "ashes-dark",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "BaseStaggeredFade",
        { attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.data, function (course, index) {
          return _c(
            "li",
            {
              key: course.id,
              attrs: { "data-index": index },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.gotToCourse(course)
                },
              },
            },
            [
              _c(
                "div",
                [_c("AppCardCourse", { attrs: { course: course } })],
                1
              ),
              _c("div", { staticClass: "border-ashes w-full border" }),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }