var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.spontaneous_productive_insertion.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar Inserção",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Criar Inserção",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "SpontaneousProductiveInsertions/closeModalAddSpontaneousProductiveInsertion",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações Gerais",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Nome do Participante",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder":
                              "Digite o nome do participante para busca",
                            "multiple-option": false,
                            value: _vm.inscription,
                            options: _vm.inscriptions,
                            dispatch: _vm.setInscription,
                            "load-dispatch":
                              "SpontaneousProductiveInsertions/loadInscriptions",
                            "error-message": _vm.getErrorMessage("inscrição"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Município",
                            "multiple-option": false,
                            value: _vm.city,
                            options: _vm.cities,
                            "label-text": "Município",
                            dispatch: _vm.setCity,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            id: "insertion_date",
                            "input-name": "insertion_date",
                            "input-type": "date",
                            "show-label": "",
                            required: "",
                            "label-text": "Data da Inserção",
                            value:
                              _vm.spontaneous_productive_insertion
                                .insertion_date,
                            dispatch:
                              "SpontaneousProductiveInsertions/setInsertionDate",
                            "error-message":
                              _vm.getErrorMessage("data da inserção"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Tipo da Inserção",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder": "Escolha o Tipo da Inserção",
                            "multiple-option": false,
                            value: _vm.insertion_type,
                            options: _vm.insertion_types,
                            dispatch: _vm.setInsertionType,
                            "error-message":
                              _vm.getErrorMessage("tipo da inserção"),
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseTextArea", {
                          attrs: {
                            active: "",
                            showIcon: true,
                            iconName: "help",
                            inputName: "observations",
                            labelText: "Observações",
                            tooltipMessage: _vm.tooltipObservationMessage,
                            required: "",
                            showLabel: "",
                            rows: "4",
                            value:
                              _vm.spontaneous_productive_insertion.observations,
                            dispatch:
                              "SpontaneousProductiveInsertions/setObservations",
                            "error-message": _vm.getErrorMessage("observações"),
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm.insertionTypeIsJob()
                    ? _c("div", { staticClass: "grid grid-cols-2 mt-4" }, [
                        _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Informações - Emprego",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.insertionTypeIsJob()
                    ? _c(
                        "div",
                        { staticClass: "grid grid-cols-2 gap-4 mt-2" },
                        [
                          _c(
                            "div",
                            [
                              _c("Multiselect", {
                                attrs: {
                                  "close-on-select": true,
                                  "label-text": "Empresa",
                                  "show-label": "",
                                  "clear-on-select": "",
                                  required: "",
                                  "select-placeholder":
                                    "Digite o nome da empresa para busca",
                                  "multiple-option": false,
                                  value: _vm.company,
                                  options: _vm.companies,
                                  dispatch: _vm.setCompany,
                                  "load-dispatch":
                                    "SpontaneousProductiveInsertions/loadCompanies",
                                  "error-message":
                                    _vm.getErrorMessage("empresa"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("Multiselect", {
                                attrs: {
                                  "close-on-select": true,
                                  "label-text": "Tipo da Contratação",
                                  "show-label": "",
                                  "clear-on-select": "",
                                  required: "",
                                  "select-placeholder":
                                    "Escolha o Tipo da Contratação",
                                  "multiple-option": false,
                                  value: _vm.contracting_type,
                                  options: _vm.contracting_types,
                                  dispatch: _vm.setContractingType,
                                  "error-message": _vm.getErrorMessage(
                                    "tipo da contratação"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.insertionTypeIsEntrepreneurship()
                    ? _c("div", { staticClass: "grid grid-cols-2 mt-4" }, [
                        _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Informações Gerais - Empreendedorismo",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.insertionTypeIsEntrepreneurship()
                    ? _c(
                        "div",
                        { staticClass: "grid grid-cols-2 gap-4 mt-2" },
                        [
                          _c(
                            "div",
                            [
                              _c("AppRadioButton", {
                                attrs: {
                                  values: [
                                    {
                                      name: "had_access_credit_for_financing",
                                      value: true,
                                      description: "Sim",
                                      checked:
                                        _vm.spontaneous_productive_insertion
                                          .had_access_credit_for_financing ===
                                        true,
                                    },
                                    {
                                      name: "had_access_credit_for_financing",
                                      value: false,
                                      description: "Não",
                                      checked:
                                        _vm.spontaneous_productive_insertion
                                          .had_access_credit_for_financing ===
                                        false,
                                    },
                                  ],
                                  labelText:
                                    "Teve acesso a crédito para financiamento?",
                                  showLabel: "",
                                  dispatch:
                                    "SpontaneousProductiveInsertions/setHadAccessCreditForFinancing",
                                  "error-message": _vm.getErrorMessage(
                                    "teve acesso a crédito para financiamento?"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppRadioButton", {
                                attrs: {
                                  values: [
                                    {
                                      name: "has_establishment",
                                      value: true,
                                      description: "Sim",
                                      checked:
                                        _vm.spontaneous_productive_insertion
                                          .has_establishment === true,
                                    },
                                    {
                                      name: "has_establishment",
                                      value: false,
                                      description: "Não",
                                      checked:
                                        _vm.spontaneous_productive_insertion
                                          .has_establishment === false,
                                    },
                                  ],
                                  labelText: "Possui estabelecimento?",
                                  showLabel: "",
                                  dispatch:
                                    "SpontaneousProductiveInsertions/setHasEstablishment",
                                  "error-message": _vm.getErrorMessage(
                                    "tem estabelecimento?"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.hasEstablishment()
                    ? _c("div", { staticClass: "grid grid-cols-2 mt-4" }, [
                        _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Informações - Estabelecimento",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        ),
                      ])
                    : _vm._e(),
                  _vm.hasEstablishment()
                    ? _c(
                        "div",
                        { staticClass: "grid grid-cols-2 gap-4 mt-2" },
                        [
                          _c(
                            "div",
                            { staticClass: "col-span-2" },
                            [
                              _c("BaseInput", {
                                attrs: {
                                  id: "establishment_name",
                                  "input-name": "establishment_name",
                                  "input-type": "text",
                                  "show-label": "",
                                  required: "",
                                  "label-text": "Nome",
                                  value:
                                    _vm.spontaneous_productive_insertion
                                      .establishment_name,
                                  dispatch:
                                    "SpontaneousProductiveInsertions/setEstablishmentName",
                                  "error-message": _vm.getErrorMessage(
                                    "nome do estabelecimento"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-2" },
                            [
                              _c("BaseInput", {
                                attrs: {
                                  id: "establishment_address",
                                  "input-name": "establishment_address",
                                  "input-type": "text",
                                  "show-label": "",
                                  required: "",
                                  "label-text": "Endereço",
                                  value:
                                    _vm.spontaneous_productive_insertion
                                      .establishment_address,
                                  dispatch:
                                    "SpontaneousProductiveInsertions/setEstablishmentAddress",
                                  "error-message": _vm.getErrorMessage(
                                    "endereço do estabelecimento"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppRadioButton", {
                                attrs: {
                                  values: [
                                    {
                                      name: "has_cnpj",
                                      value: true,
                                      description: "Sim",
                                      checked:
                                        _vm.spontaneous_productive_insertion
                                          .has_cnpj === true,
                                    },
                                    {
                                      name: "has_cnpj",
                                      value: false,
                                      description: "Não",
                                      checked:
                                        _vm.spontaneous_productive_insertion
                                          .has_cnpj === false,
                                    },
                                  ],
                                  labelText: "Possui CNPJ?",
                                  showLabel: "",
                                  dispatch:
                                    "SpontaneousProductiveInsertions/setHasCnpj",
                                  "error-message":
                                    _vm.getErrorMessage("tem cnpj?"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppRadioButton", {
                                attrs: {
                                  values: [
                                    {
                                      name: "is_related_to_the_course_segment",
                                      value: true,
                                      description: "Sim",
                                      checked:
                                        _vm.spontaneous_productive_insertion
                                          .is_related_to_the_course_segment ===
                                        true,
                                    },
                                    {
                                      name: "is_related_to_the_course_segment",
                                      value: false,
                                      description: "Não",
                                      checked:
                                        _vm.spontaneous_productive_insertion
                                          .is_related_to_the_course_segment ===
                                        false,
                                    },
                                  ],
                                  labelText:
                                    "Possui relação com o segmento do curso?",
                                  showLabel: "",
                                  dispatch:
                                    "SpontaneousProductiveInsertions/setIsRelatedToTheCourseSegment",
                                  "error-message": _vm.getErrorMessage(
                                    "possui relação com o segmento do curso?"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-2 mb-3" },
                            [
                              _c("BaseLabel", {
                                attrs: { text: "Anexar Fotos" },
                              }),
                              _c(
                                "span",
                                { staticClass: "text-burnedYellow text-sm" },
                                [
                                  _vm._v(
                                    " [Atenção - Arquivos anexados com tamanho superior a 5MB terão automaticamente seus tamanhos reduzidos, podendo haver perda de qualidade.] "
                                  ),
                                ]
                              ),
                              _c("input", {
                                staticClass: "mt-1 block w-full",
                                attrs: {
                                  type: "file",
                                  id: "photos",
                                  name: "photos[files][]",
                                  multiple: "",
                                },
                                on: { change: _vm.handleFileUpload },
                              }),
                            ],
                            1
                          ),
                          _vm.previews.length
                            ? _c(
                                "div",
                                { staticClass: "col-span-2 mb-4" },
                                _vm._l(_vm.previews, function (preview, index) {
                                  return _c(
                                    "div",
                                    {
                                      key: index,
                                      staticClass: "inline-block mr-2",
                                    },
                                    [
                                      _c("img", {
                                        staticClass: "h-20 w-20 object-cover",
                                        attrs: { src: preview },
                                      }),
                                    ]
                                  )
                                }),
                                0
                              )
                            : _vm._e(),
                        ]
                      )
                    : _vm._e(),
                  _vm.insertionTypeIsJob() ||
                  _vm.insertionTypeIsEntrepreneurship()
                    ? _c(
                        "div",
                        { staticClass: "grid grid-cols-2 gap-4 mt-2" },
                        [
                          _c(
                            "div",
                            [
                              _c("Multiselect", {
                                attrs: {
                                  "close-on-select": true,
                                  "label-text": "Segmento",
                                  "show-label": "",
                                  "clear-on-select": "",
                                  required: "",
                                  "select-placeholder":
                                    "Digite o nome do segmento para busca",
                                  "multiple-option": false,
                                  value: _vm.segment,
                                  options: _vm.segments,
                                  dispatch: _vm.setSegment,
                                  "load-dispatch":
                                    "SpontaneousProductiveInsertions/loadSegments",
                                  "error-message":
                                    _vm.getErrorMessage("segmento"),
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("Multiselect", {
                                attrs: {
                                  "close-on-select": true,
                                  "label-text": "Ocupação",
                                  "show-label": "",
                                  "clear-on-select": "",
                                  required: "",
                                  "select-placeholder":
                                    "Digite o nome da ocupação para busca",
                                  "multiple-option": false,
                                  value: _vm.occupation,
                                  options: _vm.occupations,
                                  dispatch: _vm.setOccupation,
                                  "load-dispatch":
                                    "SpontaneousProductiveInsertions/loadOccupations",
                                  "error-message":
                                    _vm.getErrorMessage("ocupação"),
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "SpontaneousProductiveInsertions/closeModalAddSpontaneousProductiveInsertion",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.spontaneous_productive_insertion.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Atualizar",
                                typeButton: "success",
                                dispatch:
                                  "SpontaneousProductiveInsertions/update",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "Criar",
                                typeButton: "success",
                                dispatch:
                                  "SpontaneousProductiveInsertions/save",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          479337341
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }