var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 items-center" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-ashes-hover font-medium text-3xl w-max mb-7",
                      },
                      [_vm._v("Transferência entre Edições")]
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "SeasonPreviousInscriptions/closeModalTransferInscription",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "div",
                    {
                      staticClass: "bg-gray-100 p-6 rounded-lg shadow-lg mb-8",
                    },
                    [
                      _c("h3", { staticClass: "text-lg font-semibold mb-3" }, [
                        _vm._v("Informações da Inscrição"),
                      ]),
                      _c("p", { staticClass: "mb-1" }, [
                        _c("strong", [_vm._v("Nome:")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.seasonPreviousInscription.student.name)
                        ),
                      ]),
                      _c("p", { staticClass: "mb-1" }, [
                        _c("strong", [_vm._v("Nome Social:")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.seasonPreviousInscription.student.social_name
                            )
                        ),
                      ]),
                      _c("p", { staticClass: "mb-1" }, [
                        _c("strong", [_vm._v("Cidade:")]),
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.seasonPreviousInscription.student.city.name
                            )
                        ),
                      ]),
                      _c("p", [
                        _c("strong", [_vm._v("Edição Atual:")]),
                        _vm._v(
                          " " +
                            _vm._s(_vm.seasonPreviousInscription.season.name)
                        ),
                      ]),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "mb-4" },
                    [
                      _c("Label", {
                        staticClass: "text-ashes-dark mb-4",
                        attrs: {
                          text: "Selecione a Edição de Destino:",
                          icon: null,
                          "icon-type": null,
                          type: "text-lg",
                          weight: "bold",
                          normal: "",
                          mode: "normal-case",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "bg-white p-6 rounded-lg shadow-md" },
                    [
                      _c("AppRadioButton", {
                        attrs: {
                          values: _vm.radioButtonValues,
                          "show-label": false,
                          dispatch:
                            "SeasonPreviousInscriptions/setTargetSeason",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 mt-6" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "Descartar",
                            typeButton: "danger",
                            dispatch:
                              "SeasonPreviousInscriptions/closeModalTransferInscription",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "Salvar",
                            "type-button": "success",
                            dispatch:
                              "SeasonPreviousInscriptions/transferInscription",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1398907891
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }