import { getAxios, baseURL } from "./Api";

export async function getTeamsForBenefits(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/turmas/pesquisar", { benefit_id: id });
}

export async function getTeamsByTeacher(id, filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/instrutores/${id}/turmas`, { params: filters });
}

export async function getShifts() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get("/turmas/turnos");
}

export async function createTeam(teams, teamFinish = null) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.post("/turmas", { teams, team_finish: teamFinish });
}

export async function updateTeam(id, teams) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`/turmas/${id}`, { teams });
}

export async function finish(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/turmas/${id}/finish`);
}

export async function reactivate(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/turmas/${id}/reactivate`);
}

export async function getTeamsBySeasonIdAndPhaseId(season_id, phase_id, area_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  if (area_id) {
    return api.get(`/edicoes/${season_id}/fases/${phase_id}/turmas?area_id=${area_id}`);
  }
  return api.get(`/edicoes/${season_id}/fases/${phase_id}/turmas`);
}

export async function getTeamsBySeason(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/turmas/teams_by_season/`;
  return api.get(url, { params: filters });
}

export async function getTeamsBySeasonExport(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/turmas/teams_by_season_export/`;
  return api.get(url, { params: filters });
}

export async function getTeamsForExport(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/turmas/exportar_planilha_turmas/`;
  return api.get(url, { params: filters });
}

export async function getTeamsForExportPendings(filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/turmas/exportar_pendencias_turmas/`;
  return api.get(url, { params: filters });
}

export async function transferTeamStudent(student_id, team_id_origin, team_id_target) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/turmas/transfer/?student_id=${student_id}&team_id_origin=${team_id_origin}&team_id_target=${team_id_target}`;
  return api.get(url);
}

export async function removeTeamStudent(student_id, team_id_origin) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/turmas/remove/?student_id=${student_id}&team_id_origin=${team_id_origin}`;
  return api.get(url);
}

export async function showTeam(id, filters) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/turmas/${id}`, { params: filters });
}

export async function getTeams() {
  let city_id = localStorage.city_id;
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`/turmas/`, { params: { city_id: city_id } });
}

export async function getAllTeams(filters) {
  filters.city_id = localStorage.city_id;
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  let url = `/turmas/teams_all/`;
  return api.get(url, { params: filters });
}
