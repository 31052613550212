var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("li", { class: _vm.liClass, on: { click: _vm.openEdition } }, [
    _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
      _c(
        "div",
        { staticClass: "flex items-center" },
        [
          _c("Label", {
            staticClass: "ml-2 text-burnedYellow",
            attrs: {
              text: _vm.edition.name,
              icon: null,
              iconType: null,
              type: "text-xl",
              weight: _vm.edition.date_manual_execution_end
                ? "medium"
                : "extrabold",
              normal: "",
              mode: "normal-case",
            },
          }),
          true
            ? _c("AppMaterialIcon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      content: _vm.areasTooltipContent,
                      classes: "custom-tooltip",
                    },
                    expression:
                      "{ content: areasTooltipContent, classes: 'custom-tooltip' }",
                  },
                ],
                staticClass:
                  "icon-input text-quantum cursor-pointer inline-flex relative",
                attrs: { iconName: "info" },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("Label", {
            staticClass: "text-ashes-dark",
            attrs: {
              text: _vm.edition.date_manual_execution_end
                ? "Encerrada"
                : "Ativa",
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: "medium",
              normal: "",
              mode: "uppercase",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("Label", {
            staticClass: "text-ashes-dark",
            attrs: {
              text: _vm.edition.date_inscription_start,
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: "medium",
              normal: "",
              mode: "uppercase",
            },
          }),
          _vm._v(" - "),
          _c("Label", {
            staticClass: "text-ashes-dark",
            attrs: {
              text: _vm.edition.date_inscription_end,
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: "medium",
              normal: "",
              mode: "uppercase",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("Label", {
            class: _vm.statusClassInscription,
            attrs: {
              text: _vm.edition.inscription_status,
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: "bold",
              normal: "",
              mode: "uppercase",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("Label", {
            staticClass: "text-ashes-dark",
            attrs: {
              text: _vm.edition.date_execution_start,
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: "medium",
              normal: "",
              mode: "uppercase",
            },
          }),
          _vm._v(" - "),
          _c("Label", {
            staticClass: "text-ashes-dark",
            attrs: {
              text: _vm.edition.date_execution_end,
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: "medium",
              normal: "",
              mode: "uppercase",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("Label", {
            class: _vm.statusClassExecution,
            attrs: {
              text: _vm.edition.execution_status,
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: "bold",
              normal: "",
              mode: "uppercase",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("Label", {
            attrs: {
              text: _vm.edition.qtdInscriptions + " Alunos",
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: _vm.edition.date_manual_execution_end ? "medium" : "bold",
              normal: "",
              mode: "uppercase",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("Label", {
            attrs: {
              text: _vm.edition.qtdInscriptionsAccepted + " Alunos",
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: _vm.edition.date_manual_execution_end ? "medium" : "bold",
              normal: "",
              mode: "uppercase",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("Label", {
            attrs: {
              text: _vm.edition.qtdInscriptionsNotAccepted + " Alunos",
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: _vm.edition.date_manual_execution_end ? "medium" : "bold",
              normal: "",
              mode: "uppercase",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("Label", {
            attrs: {
              text: _vm.edition.qtdInscriptionsGraduated + " Alunos",
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: _vm.edition.date_manual_execution_end ? "medium" : "bold",
              normal: "",
              mode: "uppercase",
            },
          }),
        ],
        1
      ),
      !_vm.edition.date_manual_execution_end && _vm.permitUpdate
        ? _c(
            "div",
            { class: _vm.editBtn },
            [
              _c("AppButtonIcon", {
                attrs: {
                  iconName: "edit",
                  dispatch: "Seasons/openEditModal",
                  payload: _vm.edition,
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }