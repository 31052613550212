var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "mx-12 my-6", attrs: { id: "title-page" } },
      [
        _c("div", { staticClass: "text-ashes-hover font-medium text-3xl" }, [
          _vm._v("Benefícios " + _vm._s(_vm.titleStatusBenefits)),
        ]),
        _c("div", { staticClass: "my-5 flex", attrs: { id: "menus" } }, [
          _c("div", [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.changeTab("scholarship")
                  },
                },
              },
              [
                _c("BaseText", {
                  attrs: {
                    text: "bolsa",
                    typeText: "custom",
                    weight: "semibold",
                    mode: "uppercase",
                    size: "sm",
                    color: _vm.setColorScholharship,
                  },
                }),
              ],
              1
            ),
          ]),
          _c("div", { staticClass: "border-l mx-10" }),
          _c("div", [
            _c(
              "a",
              {
                attrs: { href: "javascript:void(0)" },
                on: {
                  click: function ($event) {
                    return _vm.changeTab("publicTransport")
                  },
                },
              },
              [
                _c("BaseText", {
                  attrs: {
                    text: "vale-transporte",
                    typeText: "custom",
                    weight: "semibold",
                    mode: "uppercase",
                    size: "sm",
                    color: _vm.setColorPublicTransport,
                  },
                }),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "flex justify-between items-center my-6",
            attrs: { id: "fee-button" },
          },
          [
            _c("div", [
              _c(
                "div",
                {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.tabBenefitType === "scholarship",
                      expression: "tabBenefitType === 'scholarship'",
                    },
                  ],
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "flex items-center",
                      attrs: { href: "javascript:void(0)" },
                      on: { click: _vm.toggleModalMatrixInfoOpen },
                    },
                    [
                      _c("AppMaterialIcon", {
                        staticClass: "text-burnedYellow mr-1",
                        attrs: { iconName: "table_view", iconType: "outlined" },
                      }),
                      _c("BaseText", {
                        staticClass: "hover:underline",
                        attrs: {
                          text: "Ver matriz de valores",
                          typeText: "custom",
                          weight: "semibold",
                          mode: "normal",
                          size: "sm",
                          color: "burnedYellow",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              ),
            ]),
            this.$route.params.status === "pendentes"
              ? _c(
                  "div",
                  [
                    _vm.tabBenefitType === "scholarship" && _vm.permit
                      ? _c("AppButtonColorful", {
                          attrs: {
                            showIcon: "",
                            iconName: "add",
                            text: "Pagamento de Bolsa",
                            dispatch: "Benefit/openModal",
                            typeButton: "primary",
                          },
                        })
                      : _vm._e(),
                    _vm.tabBenefitType === "publicTransport" && _vm.permit
                      ? _c("AppButtonColorful", {
                          attrs: {
                            showIcon: "",
                            iconName: "add",
                            text: "Pagamento de Transporte",
                            dispatch: "Benefit/openModal",
                            typeButton: "primary",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _c("div", { attrs: { id: "list-benefits" } }, [
          _c(
            "div",
            [_c("BenefitsCards", { attrs: { data: _vm.getBenefits } })],
            1
          ),
        ]),
        _c("ModalBenefit", { attrs: { open: _vm.modalBenefitOpen } }),
        _c("DeleteBenefitConfirmation", {
          attrs: { open: _vm.openModalDelete },
        }),
        _c("ModalMatrix", { attrs: { open: _vm.modalMatrixInfoOpen } }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }