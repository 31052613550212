import { getAxios, baseURL } from "./Api";

export async function getUsers() {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get("/usuarios");
}

export async function getUser(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.get(`/usuarios/${id}`);
}

export async function createUser(user) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api_auth"]);

  return api.post("/auth", user);
}

export async function deleteUSer(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`usuarios/${id}`);
}

export async function updateUser(id, user) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`usuarios/${id}`, { user });
}

export async function resetPassword(id, password, password_confirmation) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post(`usuarios/${id}/reset_password`, {
    password: password,
    password_confirmation: password_confirmation,
  });
}

export async function createUserArea(obj) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.post("/areas_por_usuario", obj);
}

export async function updateUserArea(id, user_area) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`areas_por_usuario/${id}`, user_area);
}

export async function destroyUserArea(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`areas_por_usuario/${id}`);
}
