var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "grid auto-grid-auto p-10" },
    [
      _c(
        "div",
        [
          _c("BaseText", {
            attrs: {
              text: _vm.area.city.name + " | Áreas",
              typeText: "custom",
              weight: "semibold",
              italic: false,
              size: "3xl",
              color: "ashes-hover",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "my-8" },
        [
          _c(
            "router-link",
            { attrs: { to: "/areas" } },
            [
              _c("BaseText", {
                attrs: {
                  text: "<- Voltar",
                  typeText: "custom",
                  weight: "medium",
                  italic: false,
                  mode: "capitalize",
                  size: "sm",
                  color: "carmesim",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        [
          _c("BaseText", {
            attrs: {
              text: _vm.area.name + " - " + _vm.area.city.name,
              typeText: "custom",
              weight: "semibold",
              italic: false,
              mode: "capitalize",
              size: "3xl",
              color: "ashes-dark",
            },
          }),
          _vm.permitUp
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "edit",
                  dispatch: "Area/editModal",
                  payload: _vm.area,
                },
              })
            : _vm._e(),
          _vm.permitDes
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "delete",
                  payload: _vm.area,
                  dispatch: "Areas/openCloseDeleteAreaConfirmation",
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "border-b border-ashes-hover my-8" },
        [
          _c("BaseText", {
            attrs: {
              text: "Bairros de Abrangência",
              typeText: "custom",
              weight: "medium",
              italic: false,
              mode: "uppercase",
              size: "md",
              color: "carmesim",
            },
          }),
        ],
        1
      ),
      _c(
        "ul",
        _vm._l(this.area.neighborhoods_attributes, function (neighborhood) {
          return _c(
            "li",
            { key: neighborhood.id },
            [
              _vm._v(" - "),
              _c("BaseText", {
                attrs: {
                  text: neighborhood.name,
                  typeText: "custom",
                  weight: "bold",
                  italic: false,
                  mode: "capitalize",
                  size: "md",
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "div",
        { staticClass: "border-b border-ashes-hover my-8" },
        [
          _c("BaseText", {
            attrs: {
              text: "Equipamentos",
              typeText: "custom",
              weight: "medium",
              italic: false,
              mode: "uppercase",
              size: "md",
              color: "carmesim",
            },
          }),
        ],
        1
      ),
      _c(
        "ul",
        _vm._l(this.area.equipment, function (equipment) {
          return _c(
            "li",
            { key: equipment.id },
            [
              _vm._v(" - "),
              _c("BaseText", {
                attrs: {
                  text: equipment.name,
                  typeText: "custom",
                  weight: "bold",
                  italic: false,
                  mode: "capitalize",
                  size: "md",
                },
              }),
              _vm._v(" - "),
              _c("BaseText", {
                attrs: {
                  text: equipment.neighborhood.name,
                  typeText: "custom",
                  weight: "bold",
                  italic: false,
                  mode: "capitalize",
                  size: "md",
                },
              }),
            ],
            1
          )
        }),
        0
      ),
      _c("ModalArea", { attrs: { open: _vm.openModal } }),
      _c("DeleteAreaConfirmation", { attrs: { open: _vm.openModalDelete } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }