<template>
  <div>
    <div class="grid grid-cols-4 items-center">
      <div class="mt-4 ml-5">
        <BaseText
          text="equipamentos"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-3">
        <BaseText
          text="áreas"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4">
        <BaseText
          text="bairro"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li
        :key="equipment.id"
        :data-index="index"
        v-for="(equipment, index) in data"
        @click.prevent="gotToEquipment(equipment)"
      >
        <div>
          <AppCardEquipment :equipment="equipment" />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardEquipment from "../../components/AppCardEquipment.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";
import router from "../../router/index";

export default {
  components: {
    AppCardEquipment,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },

  methods: {
    gotToEquipment(params) {
      router.push({ name: "equipamento", params: { id: params.id } });
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 36% 34% 25% 25%;
}
</style>
