var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-4" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start col-span-3" },
                      [
                        _vm.psychosocial_accompaniment.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar Justificativa Para Não Acolhimento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Adicionar Justificativa Para Não Acolhimento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "PsychosocialAccompaniments/closeEditModalPsychosocialAccompanimentJustifyNonReception",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _vm.psychosocial_accompaniment.id
                    ? _c(
                        "div",
                        { staticClass: "grid grid-cols-4 gap-4 mt-4" },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "col-span-4 text-burnedYellow pb-2",
                            },
                            [
                              _c("Label", {
                                attrs: {
                                  text: "Informações do Aluno(a)",
                                  icon: null,
                                  "icon-type": null,
                                  type: "text-sm",
                                  weight: "semibold",
                                  normal: "",
                                  mode: "uppercase",
                                },
                              }),
                              _c("div", {
                                staticClass:
                                  "border-ashes-hover border-b-2 w-full",
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            { staticClass: "col-span-2" },
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Nome",
                                  text: _vm.inscription.student.name,
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Status do Aluno(a) no sistema",
                                  text: _vm.verifyStatus(),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "CPF",
                                  text: _vm._f("VMask")(
                                    _vm.verifyInscription("cpf"),
                                    "###.###.###-##"
                                  ),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Data de Nascimento",
                                  text: _vm.verifyInscription("birthdate"),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Idade",
                                  text: _vm.verifyInscription("age"),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            [
                              _c("AppTextField", {
                                attrs: {
                                  "show-label": "",
                                  "label-text": "Número Contato",
                                  text: _vm._f("VMask")(
                                    _vm.verifyInscription("cellphone_number"),
                                    "(##) #####-####"
                                  ),
                                  "type-text": "topic-details",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Justificativa",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseTextArea", {
                          attrs: {
                            active: "",
                            inputName: "Justificar Não Acolhimento",
                            labelText: "Justificar Não Acolhimento",
                            showLabel: "",
                            rows: "4",
                            value:
                              _vm.psychosocial_accompaniment
                                ?.justification_for_non_acceptance,
                            dispatch:
                              "PsychosocialAccompaniments/setJustificationForNonAcceptance",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "PsychosocialAccompaniments/closeEditModalPsychosocialAccompanimentJustifyNonReception",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.psychosocial_accompaniment.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Salvar",
                                typeButton: "success",
                                dispatch: "PsychosocialAccompaniments/update",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "criar",
                                typeButton: "success",
                                dispatch: "PsychosocialAccompaniments/save",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1664575132
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }