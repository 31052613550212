var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _vm.area.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar área",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Adicionar área",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: { dispatch: "Area/closeModal", forClose: "" },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Informações Gerais",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c("div", { staticClass: "grid grid-rols-2" }, [
                      _c(
                        "div",
                        [
                          _c("BaseInput", {
                            attrs: {
                              id: "name",
                              "input-name": "name",
                              "input-placeholder": "Área XX ...",
                              "input-type": "text",
                              "show-label": "",
                              "label-text": "nome",
                              value: _vm.area.name,
                              "error-message": _vm.getErrorMessage(
                                "name",
                                _vm.area.name
                              ),
                              required: "",
                              dispatch: "Area/setName",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                    _c("div", { staticClass: "ml-4 grid grid-rols-2" }, [
                      _c(
                        "div",
                        [
                          _c("Multiselect", {
                            attrs: {
                              id: "bairros",
                              "select-placeholder": "Selecione as opções",
                              "label-text": "Vincular Bairros",
                              "close-on-select": false,
                              "show-label": "",
                              multipleOption: "",
                              value: _vm.area.neighborhoods_attributes,
                              options: _vm.getNeighborhoods(_vm.neighborhoods),
                              "error-message": _vm.getErrorMessage(
                                "has_neighborhoods",
                                _vm.area.neighborhoods_attributes
                              ),
                              dispatch: _vm.setNeighborhoods,
                              "no-options-message":
                                "Todos os bairros já foram vinculados",
                              required: "",
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch: "Area/closeModal",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.area.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Salvar",
                                typeButton: "success",
                                dispatch: "Area/updateArea",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "Criar",
                                typeButton: "success",
                                dispatch: "Area/saveArea",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          3961718439
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }