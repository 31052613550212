var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "flex flex-col items-start font-system" },
    [
      _vm.showLabel
        ? _c("BaseLabel", {
            attrs: {
              text: _vm.labelText,
              required: _vm.required,
              target: _vm.id,
            },
          })
        : _vm._e(),
      _c(
        "div",
        { staticClass: "relative w-full" },
        [
          _c(
            "div",
            { class: _vm.classes, on: { click: _vm.focusInput } },
            [
              _vm._l(_vm.values, function (value, index) {
                return _c("span", { key: index, staticClass: "tag" }, [
                  _vm._v(" " + _vm._s(value) + " "),
                  _c(
                    "button",
                    {
                      staticClass: "remove-tag",
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          return _vm.removeValue(index)
                        },
                      },
                    },
                    [_vm._v("x")]
                  ),
                ])
              }),
              _vm.inputType === "checkbox" && _vm.mask
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentInput,
                        expression: "currentInput",
                      },
                      {
                        name: "the-mask",
                        rawName: "v-the-mask",
                        value: _vm.mask,
                        expression: "mask",
                      },
                    ],
                    staticClass: "input-tag",
                    attrs: {
                      id: _vm.id,
                      name: _vm.inputName,
                      placeholder: _vm.inputPlaceholder,
                      readonly: _vm.readonly,
                      type: "checkbox",
                    },
                    domProps: {
                      checked: Array.isArray(_vm.currentInput)
                        ? _vm._i(_vm.currentInput, null) > -1
                        : _vm.currentInput,
                    },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.onEnter.apply(null, arguments)
                      },
                      input: _vm.onInput,
                      change: function ($event) {
                        var $$a = _vm.currentInput,
                          $$el = $event.target,
                          $$c = $$el.checked ? true : false
                        if (Array.isArray($$a)) {
                          var $$v = null,
                            $$i = _vm._i($$a, $$v)
                          if ($$el.checked) {
                            $$i < 0 && (_vm.currentInput = $$a.concat([$$v]))
                          } else {
                            $$i > -1 &&
                              (_vm.currentInput = $$a
                                .slice(0, $$i)
                                .concat($$a.slice($$i + 1)))
                          }
                        } else {
                          _vm.currentInput = $$c
                        }
                      },
                    },
                  })
                : _vm.inputType === "radio" && _vm.mask
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentInput,
                        expression: "currentInput",
                      },
                      {
                        name: "the-mask",
                        rawName: "v-the-mask",
                        value: _vm.mask,
                        expression: "mask",
                      },
                    ],
                    staticClass: "input-tag",
                    attrs: {
                      id: _vm.id,
                      name: _vm.inputName,
                      placeholder: _vm.inputPlaceholder,
                      readonly: _vm.readonly,
                      type: "radio",
                    },
                    domProps: { checked: _vm._q(_vm.currentInput, null) },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.onEnter.apply(null, arguments)
                      },
                      input: _vm.onInput,
                      change: function ($event) {
                        _vm.currentInput = null
                      },
                    },
                  })
                : _vm.mask
                ? _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentInput,
                        expression: "currentInput",
                      },
                      {
                        name: "the-mask",
                        rawName: "v-the-mask",
                        value: _vm.mask,
                        expression: "mask",
                      },
                    ],
                    staticClass: "input-tag",
                    attrs: {
                      id: _vm.id,
                      name: _vm.inputName,
                      placeholder: _vm.inputPlaceholder,
                      readonly: _vm.readonly,
                      type: _vm.inputType,
                    },
                    domProps: { value: _vm.currentInput },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.onEnter.apply(null, arguments)
                      },
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.currentInput = $event.target.value
                        },
                        _vm.onInput,
                      ],
                    },
                  })
                : _c("textarea", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.currentInput,
                        expression: "currentInput",
                      },
                    ],
                    staticClass: "input-tag",
                    attrs: {
                      id: _vm.id,
                      name: _vm.inputName,
                      placeholder: _vm.inputPlaceholder,
                      rows: _vm.rows,
                      readonly: _vm.readonly,
                    },
                    domProps: { value: _vm.currentInput },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.onEnter.apply(null, arguments)
                      },
                      input: [
                        function ($event) {
                          if ($event.target.composing) return
                          _vm.currentInput = $event.target.value
                        },
                        _vm.onInput,
                      ],
                    },
                  }),
            ],
            2
          ),
          _vm.showIcon
            ? _c("AppMaterialIcon", {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: {
                      content: _vm.tooltipMessage,
                      classes: _vm.tooltipClass,
                    },
                    expression:
                      "{ content: tooltipMessage, classes: tooltipClass }",
                  },
                ],
                staticClass: "icon-input text-quantum cursor-pointer",
                attrs: { iconName: _vm.iconName },
              })
            : _vm._e(),
          _vm.errorMessage
            ? _c("span", { staticClass: "text-carmesim-hover text-xs" }, [
                _vm._v(_vm._s(_vm.errorMessage)),
              ])
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }