var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-128" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "flex flex-row-reverse" },
                    [
                      _c("AppButtonIcon", {
                        attrs: {
                          "for-close": "",
                          dispatch: "Facilities/toggleModalDeleteFacility",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("img", {
                        staticClass: "mr-2",
                        attrs: {
                          src: require("../../assets/images/icon-alerta.svg"),
                        },
                      }),
                      _c("BaseText", {
                        attrs: {
                          text: "Excluir Equipamento",
                          typeText: "custom",
                          weight: "semibold",
                          size: "2xl",
                          color: "ashes-dark",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "mt-5" },
                    [
                      _c("BaseText", {
                        attrs: {
                          text: "Tem certeza que deseja excluir o equipamento?",
                          typeText: "custom",
                          weight: "extrabold",
                          color: "quantum",
                          size: "lg",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "flex justify-end" },
                    [
                      _c("AppButtonEmpty", {
                        staticClass: "mr-5",
                        attrs: {
                          text: "Não",
                          "type-button": "danger",
                          dispatch: "Facilities/toggleModalDeleteFacility",
                        },
                      }),
                      _c("AppButtonEmpty", {
                        attrs: {
                          text: "Sim",
                          "type-button": "success",
                          dispatch: "Facilities/delete",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1467333118
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }