<template>
  <div>
    <div class="grid grid-cols-custom items-center -mx-12">
      <div class="mt-10 ml-5">
        <BaseText
          text="Status Cadastral"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Área"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Equipamento"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Nome do Participante"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Modalidade de Ensino"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Nome da Escola"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Série/Ano"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Data da Matrícula"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul" class="-mx-12">
      <li :key="inscription.id" :data-index="index" v-for="(inscription, index) in data">
        <div>
          <AppCardSchoolReinsertionReinserteds :inscription="inscription" />
        </div>
        <div class="border-ashes w-full border ml-5"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardSchoolReinsertionReinserteds from "../../components/AppCardSchoolReinsertionReinserteds.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";

export default {
  components: {
    AppCardSchoolReinsertionReinserteds,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 9% 11% 16% 16% 9% 10% 11% 10%;
}
</style>
