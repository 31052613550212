var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "toggle-container" }, [
        _c(
          "button",
          {
            class: { "toggle-button": true, "full-width": _vm.showFilters },
            on: { click: _vm.toggleFilters },
          },
          [
            _c("Label", {
              staticClass: "label-text",
              attrs: {
                text: _vm.showFilters ? "Esconder filtros" : "Mostrar filtros",
                icon: null,
                "icon-type": null,
                type: "text-base",
                weight: "medium",
                normal: "",
                mode: "normal-case",
              },
            }),
            _c(
              "span",
              {
                class: {
                  "toggle-icon": true,
                  "full-width-icon": _vm.showFilters,
                },
              },
              [_vm._v(" " + _vm._s(_vm.showFilters ? "▲" : "▼") + " ")]
            ),
          ],
          1
        ),
      ]),
      _c("transition", { attrs: { name: "fade" } }, [
        _c(
          "div",
          {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.showFilters,
                expression: "showFilters",
              },
            ],
            staticClass: "filters-container",
          },
          [_vm._t("default")],
          2
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }