var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permit
    ? _c("div", { staticClass: "font-system" }, [
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-3 justify-between items-center mx-12 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "text-ashes-hover font-medium text-3xl mx-2 col-span-2",
              },
              [_vm._v(" " + _vm._s(_vm.city.name) + " | Encaminhamentos ")]
            ),
            _vm.forwardings.length > 0
              ? _c(
                  "div",
                  {
                    staticClass:
                      "text-ashes-hover font-medium text-xl mx-2 col-span-2",
                  },
                  [_vm._v(" " + _vm._s(_vm.getTotalRegisters) + " ")]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-2 justify-between p-4 items-end mx-12 my-6 gap-4",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              { staticClass: "w-52" },
              [
                _c("Multiselect", {
                  attrs: {
                    "close-on-select": true,
                    "show-label": "",
                    "clear-on-select": "",
                    "select-placeholder": "Selecionar edição",
                    "multiple-option": false,
                    value: _vm.season_filtered,
                    options: _vm.seasons,
                    "label-text": "Edição",
                    dispatch: _vm.filterListBySeason,
                    required: "",
                  },
                }),
              ],
              1
            ),
            _vm.permitShowPaginate && _vm.season_filtered
              ? _c(
                  "div",
                  { staticClass: "mx-2 items-center justify-self-end" },
                  [
                    _vm.permitCreate
                      ? _c("AppButtonColorful", {
                          attrs: {
                            id: "btn-add-forwarding",
                            showIcon: "",
                            iconName: "add",
                            text: "Cadastrar Encaminhamento",
                            dispatch: "Forwardings/toggleModalForwardings",
                            typeButton: "primary",
                          },
                        })
                      : _vm._e(),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
        _vm.season_filtered
          ? _c(
              "div",
              { staticClass: "mx-14", attrs: { id: "tabs-button" } },
              [
                _c("FilterComponent", [
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c("Multiselect", {
                        attrs: {
                          "close-on-select": true,
                          "label-text": "Pesquisar por Nome do Aluno(a)",
                          "show-label": "",
                          "clear-on-select": "",
                          "select-placeholder":
                            "Digite o nome do aluno(a) para busca",
                          "multiple-option": false,
                          value: _vm.inscription_filtered,
                          options: _vm.inscriptionsForGeneralMenus,
                          dispatch: _vm.filterListByInscription,
                          "load-dispatch":
                            "Forwardings/loadInscriptionsForGeneralMenus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c("BaseLabel", {
                        attrs: {
                          text: "Pesquisar por nome do Responsável pelo Cadastro",
                          typeText: "custom",
                          weight: "normal",
                          italic: false,
                          size: "xs",
                        },
                      }),
                      _c("BaseInput", {
                        attrs: {
                          active: "",
                          inputName: "",
                          inputType: "text",
                          iconName: "search",
                          showIcon: "",
                          "input-placeholder": "Pesquisar por nome",
                          dispatch: "Forwardings/filterListByUserName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("Multiselect", {
                        attrs: {
                          "close-on-select": true,
                          "show-label": "",
                          "clear-on-select": "",
                          "select-placeholder": "Selecione a opção",
                          "label-text":
                            "Pesquisar por Política de Encaminhamento",
                          "multiple-option": false,
                          value: _vm.forwarding_policy_filtered,
                          options: _vm.forwarding_policies,
                          dispatch: _vm.filterListByForwardingPolicy,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("Multiselect", {
                        attrs: {
                          "close-on-select": true,
                          "show-label": "",
                          "clear-on-select": "",
                          "select-placeholder": "Selecione a opção",
                          "label-text":
                            "Pesquisar por Status de Encaminhamento",
                          "multiple-option": false,
                          value: _vm.forwarding_status_filtered,
                          options: _vm.forwarding_statuses,
                          dispatch: _vm.filterListByForwardingStatus,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "col-span-2" },
                    [
                      _c("Multiselect", {
                        attrs: {
                          "close-on-select": true,
                          "show-label": "",
                          "clear-on-select": "",
                          "select-placeholder": "Selecione a opção",
                          "label-text": "Pesquisar por Área",
                          "multiple-option": false,
                          value: _vm.area_filtered,
                          options: _vm.user_areas,
                          dispatch: _vm.filterListByArea,
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("BaseInput", {
                        attrs: {
                          active: "",
                          "input-name": "filter_by_initial_date",
                          "input-type": "date",
                          value: _vm.forwarding_initial_date,
                          "show-label": "",
                          "label-text": "Data do Encaminhamento - Data Inicial",
                          dispatch:
                            "Forwardings/filterListByForwardingInitialDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("BaseInput", {
                        attrs: {
                          active: "",
                          "input-name": "filter_by_final_date",
                          "input-type": "date",
                          value: _vm.forwarding_final_date,
                          "show-label": "",
                          "label-text": "Data do Encaminhamento - Data Final",
                          dispatch:
                            "Forwardings/filterListByForwardingFinalDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("BaseInput", {
                        attrs: {
                          active: "",
                          "input-name": "filter_by_initial_date",
                          "input-type": "date",
                          value: _vm.forwarding_expiration_initial_date,
                          "show-label": "",
                          "label-text": "Data de Expiração - Data Inicial",
                          dispatch:
                            "Forwardings/filterListByForwardingExpirationInitialDate",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    [
                      _c("BaseInput", {
                        attrs: {
                          active: "",
                          "input-name": "filter_by_final_date",
                          "input-type": "date",
                          value: _vm.forwarding_expiration_final_date,
                          "show-label": "",
                          "label-text": "Data de Expiração - Data Final",
                          dispatch:
                            "Forwardings/filterListByForwardingExpirationFinalDate",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "mt-3" }, [
                  _vm.forwardings.length > 0 && _vm.permitExportSpreadsheet
                    ? _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.exportToExcel()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "inline-block",
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              src: require("../assets/images/logout_black_24dp.svg"),
                              alt: "",
                            },
                          }),
                          _c("BaseText", {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              text: "Exportar planilha",
                              typeText: "custom",
                              italic: false,
                              size: "xs",
                              color: "burnedYellow",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _vm.forwardings_spreadsheet.length > 0 &&
                _vm.modalToggleExportForwardingSpreadsheet &&
                _vm.season_filtered
                  ? _c("BaseModal", {
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "header",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "grid grid-cols-2" }, [
                                  _c(
                                    "div",
                                    { staticClass: "justify-self-start" },
                                    [
                                      _c("Label", {
                                        staticClass: "text-ashes-dark",
                                        attrs: {
                                          text: "Exportar Planilha de Encaminhamentos",
                                          icon: null,
                                          "icon-type": null,
                                          type: "text-2xl",
                                          weight: "bold",
                                          normal: "",
                                          mode: "normal-case",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "justify-self-end" },
                                    [
                                      _c("AppButtonIcon", {
                                        attrs: {
                                          dispatch:
                                            "Forwardings/closeExportSpreadsheetModal",
                                          forClose: "",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "footer",
                            fn: function () {
                              return [
                                _c("div", { staticClass: "grid grid-rows-2" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "grid justify-center items-center",
                                    },
                                    [
                                      _c("Label", {
                                        staticClass: "text-ashes-dark",
                                        attrs: {
                                          text: "Planilha gerada com sucesso!",
                                          icon: null,
                                          "icon-type": null,
                                          type: "text-2x1",
                                          weight: "normal",
                                          normal: "",
                                          mode: "normal-case",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "grid grid-cols-2" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "justify-self-start" },
                                        [
                                          _c("AppButtonEmpty", {
                                            attrs: {
                                              text: "descartar",
                                              typeButton: "danger",
                                              dispatch:
                                                "Forwardings/closeExportSpreadsheetModal",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "justify-self-end" },
                                        [
                                          _c(
                                            "download-excel",
                                            {
                                              attrs: {
                                                type: "xls",
                                                meta: _vm.json_meta,
                                                fields: _vm.json_fields,
                                                data: _vm.json_data,
                                                "before-finish":
                                                  _vm.closeExportSpreadsheetModal,
                                                worksheet: "Encaminhamentos",
                                                name: "encaminhamentos.xls",
                                                escapeCsv: false,
                                              },
                                            },
                                            [
                                              _c("AppButtonEmpty", {
                                                attrs: {
                                                  text: "Download da planilha",
                                                  typeButton: "success",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            },
                            proxy: true,
                          },
                        ],
                        null,
                        false,
                        1082601876
                      ),
                    })
                  : _vm._e(),
                !_vm.hasSpreadsheetResponse
                  ? _c("div", [_c("PreLoading")], 1)
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.season_filtered
          ? _c(
              "div",
              {
                staticClass: "grid grid-cols-2 items-center mx-12 my-6",
                attrs: { id: "tabs-button" },
              },
              [
                _c(
                  "div",
                  { staticClass: "mx-2" },
                  [
                    _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-consult-forwardings",
                        showIcon: "",
                        iconName: "search",
                        text: "Consultar dados",
                        dispatch: "Forwardings/loadForwardings",
                        typeButton: "primary",
                      },
                    }),
                  ],
                  1
                ),
                _vm.forwardings.length > 0 && _vm.season_filtered
                  ? _c(
                      "div",
                      { staticClass: "mx-2 items-center justify-self-end" },
                      [
                        _c(
                          "a",
                          {
                            attrs: { href: "javascript:void(0)" },
                            on: {
                              click: function ($event) {
                                return _vm.clearFields()
                              },
                            },
                          },
                          [
                            _c("AppButtonColorful", {
                              attrs: {
                                id: "btn-consult-incident_reports",
                                showIcon: "",
                                iconName: "remove",
                                text: "Limpar consulta",
                                typeButton: "primary",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
        _vm.season_filtered
          ? _c(
              "div",
              { attrs: { id: "list-forwardings" } },
              [
                _c(
                  "div",
                  { staticClass: "mx-12" },
                  [
                    _c("ForwardingsCards", {
                      attrs: { data: _vm.forwardings },
                    }),
                  ],
                  1
                ),
                _vm.forwardings.length > 0
                  ? _c(
                      "div",
                      {
                        staticClass: "mx-12 my-6 pb-3",
                        attrs: { id: "forwarding-pagination" },
                      },
                      [
                        _c("BasePagination", {
                          attrs: {
                            total: _vm.totalForwardings,
                            "total-per-page": _vm.totalPerPage,
                            dispatch: "Forwardings/setOffset",
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _c("ModalForwarding", {
                  attrs: { open: _vm.modalToggleForwardings },
                }),
                _c("ModalReturnForwarding", {
                  attrs: { open: _vm.modalToggleReturnForwardings },
                }),
                _c("ModalForwardingPostponeExpirationDate", {
                  attrs: {
                    open: _vm.modalToggleForwardingPostponeExpirationDate,
                  },
                }),
                _c("ModalViewForwarding", {
                  attrs: { open: _vm.modalViewForwarding },
                }),
                _c("ModalViewForwardingPostponementHistoriesByForwarding", {
                  attrs: { open: _vm.modalToggleViewHistoricForwarding },
                }),
                _c("DeleteForwarding", {
                  attrs: { open: _vm.modalConfirmDeleteForwarding },
                }),
              ],
              1
            )
          : _vm._e(),
      ])
    : _c("ProhibitedAccessPage", {
        attrs: {
          classText:
            "grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }