<template>
  <BaseCard :class="liClass">
    <div class="grid grid-cols-2 gap-10 items-center pt-2">
      <div>
        <div class="flex justify-start items-center">
          <div :class="iconClass">
            <AppMaterialIcon
              iconName="book"
              iconType="outlined"
              class="place-self-center text-white"
              iconSize="36"
            />
          </div>
          <BaseText
            :text="course.name"
            typeText="custom"
            mode="underline"
            weight="bold"
            size="xl"
            class="mr-1"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 gap-10 items-center justify-items-end">
        <div class="justify-self-start">
          <BaseText :text="course.discipline.name" typeText="custom" size="sm" color="ashes-dark" />
        </div>
        <!--
        <div>
          <BaseText text="05" typeText="custom" size="sm" color="ashes-dark" />
        </div>
        -->
        <div class="justify-items-end">
          <AppButtonIcon iconName="edit" dispatch="Course/openEditModal" :payload="course" v-if="permitUp" />
          <AppButtonIcon
            iconName="delete"
            dispatch="Course/toggleModalDeleteCourse"
            :payload="course"
            v-if="permitDes"
          />
        </div>
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import AppMaterialIcon from "./AppMaterialIcon.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
    AppMaterialIcon,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    course: {
      type: Object,
    },
  },
  created() {
    this.courseAcces();
    this.courseChange();
    this.courseDelete();
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
    };
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    courseAcces() {
      this.canAcces("course_index").then((resp) => {
        this.permit = resp;
      });
    },
    courseDelete() {
      this.canAcces("course_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    courseChange() {
      this.canAcces("course_update").then((resp) => {
        this.permitUp = resp;
      });
    },
  },

  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        "p-6": true,
        "cursor-pointer": true,
      };
    },
    iconClass() {
      let css = [];
      let bgColor = this.course.discipline.id == 3 ? "bg-yellow-300" : "bg-burnedYellow-classic";

      css.push(bgColor);
      css.push("rounded-full");
      css.push("w-12");
      css.push("h-12");
      css.push("grid");
      css.push("mr-3");
      css.push("items-center");
      css.push("justify-center");
      css.push("min-w-1");

      return css;
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 45% 15% 10% 15% 10%;
}
.min-w-1 {
  min-width: 3rem;
}
</style>
