var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", [
    _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
      _c(
        "div",
        { staticClass: "flex justify-start items-center" },
        [
          _c(
            "div",
            { class: _vm.iconClass },
            [
              _c("AppMaterialIcon", {
                staticClass: "place-self-center text-white",
                attrs: {
                  iconName: "book",
                  iconType: "outlined",
                  iconSize: "36",
                },
              }),
            ],
            1
          ),
          _c("BaseText", {
            staticClass: "mr-1",
            attrs: {
              text: _vm.course.name,
              typeText: "custom",
              mode: "underline",
              weight: "bold",
              size: "xl",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.course.discipline.name,
              typeText: "custom",
              size: "sm",
              color: "ashes-dark",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.course.city.name,
              typeText: "custom",
              size: "sm",
              color: "ashes-dark",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4 flex" },
        [
          _vm.permitUp
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "edit",
                  dispatch: "Course/openEditModal",
                  payload: _vm.course,
                },
              })
            : _vm._e(),
          _vm.permitDes
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "delete",
                  dispatch: "Course/toggleModalDeleteCourse",
                  payload: _vm.course,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }