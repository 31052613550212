import { getAxios, baseURL } from "./Api";

export async function getAreasByCity(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let url = `/cidades/${id}/areas/`;

  return api.get(url);
}

export async function getArea(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  let url = `/areas/${id}`;

  return api.get(url);
}

export async function createArea(area) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);

  return api.post("/areas", { areas: area });
}

export async function destroyArea(id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.delete(`areas/${id}`);
}

export async function updateArea(id, area) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.put(`areas/${id}`, { areas: area });
}

export async function getAreasByCityId(city_id) {
  const token = localStorage.token;
  const api = getAxios({ token }, baseURL["virando_jogo_api"]);
  return api.get(`cidades/${city_id}/areas`);
}
