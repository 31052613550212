var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("HeaderComponent", {
                    attrs: {
                      headerText: _vm.psychosocial_accompaniment?.id
                        ? "Realizar Atendimento - Atendimento Individual"
                        : "Adicionar Atendimento Individual",
                      headerClass: "text-ashes-dark",
                      closeDispatch:
                        "PsychosocialAccompaniments/closeEditModalPsychosocialIndividualMonitoring",
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("AccompanimentInfo", {
                    attrs: { accompanimentOrigin: _vm.accompaniment_origin },
                  }),
                  _c("StudentInfo", {
                    attrs: { inscription: _vm.inscription },
                  }),
                  _c("MeetingDetails", {
                    attrs: {
                      psychosocial_accompaniment:
                        _vm.psychosocial_accompaniment,
                      meeting_not_happened_reason:
                        _vm.meeting_not_happened_reason,
                      meeting_not_happened_reasons:
                        _vm.meeting_not_happened_reasons,
                    },
                  }),
                  _c("AttendantTeams"),
                  _c("HousingSituation"),
                  _c("DisciplinarySanctions"),
                  _c("SchoolSituation"),
                  _c("GenderIdentity"),
                  _c("HealthSituation"),
                  _c("VulnerabilitySituation", {
                    attrs: {
                      psychosocial_accompaniment:
                        _vm.psychosocial_accompaniment,
                    },
                  }),
                  _c("ForwardingDetails", {
                    attrs: {
                      psychosocial_accompaniment:
                        _vm.psychosocial_accompaniment,
                    },
                  }),
                  _c("AccompanimentPlan", {
                    attrs: {
                      psychosocial_accompaniment:
                        _vm.psychosocial_accompaniment,
                    },
                  }),
                  _c("MeetingConclusion", {
                    attrs: {
                      psychosocial_accompaniment:
                        _vm.psychosocial_accompaniment,
                    },
                  }),
                  _c("ModalMatrixRiskStratifications", {
                    attrs: { open: _vm.modalToggleMatrixRiskStratifications },
                  }),
                  _c("SavePsychosocialAccompanimentWithoutMatrixConfirmation", {
                    attrs: {
                      open: _vm.modalConfirmSavePsychosocialAccompanimentWithoutMatrix,
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("ModalFooterActions", {
                    attrs: {
                      closeDispatch:
                        "PsychosocialAccompaniments/closeEditModalPsychosocialIndividualMonitoring",
                      updateDispatch: "PsychosocialAccompaniments/update",
                      saveDispatch: "PsychosocialAccompaniments/save",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2748691753
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }