<template>
  <BaseCard class="cursor-pointer">
    <div class="grid grid-cols-4 items-center">
      <div class="mt-4">
        <BaseText
          :text="equipment.name"
          mode="underline"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="xl"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="listAreas(equipment.areas_attributes)"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="equipment.neighborhood.name"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          iconName="edit"
          :payload="equipment"
          dispatch="Equipment/openEditModalEquipment"
          v-if="permitUp"
        />
        <AppButtonIcon
          iconName="delete"
          :payload="equipment"
          dispatch="Equipment/openCloseDeleteEquipmentConfirmation"
          v-if="permitDes"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
    };
  },
  created() {
    this.equipmentAcces();
    this.equipmentChange();
    this.equipmentDelete();
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    equipment: {
      type: Object,
    },
  },

  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-4": true,
        "justify-items-stretch": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
        "cursor-pointer": true,
      };
    },
  },

  methods: {
    listAreas(areas) {
      return areas.map((area) => area.name).join(" | ");
    },
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    equipmentAcces() {
      this.canAcces("equipment_index").then((resp) => {
        this.permit = resp;
      });
    },
    equipmentDelete() {
      this.canAcces("equipment_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    equipmentChange() {
      this.canAcces("equipment_update").then((resp) => {
        this.permitUp = resp;
      });
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 3fr 3fr 2fr 0fr;
}
</style>
