<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="facility.name"
          mode="normal-case"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="base"
        />
      </div>
      <div class="mt-4 flex">
        <BaseText
          :text="facility.forwarding_policy.name"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="facility.address"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="base"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          v-if="permitUpdate"
          iconName="edit"
          :payload="facility"
          dispatch="Facilities/openEditModalFacilities"
        />
        <AppButtonIcon
          v-if="permitDelete"
          iconName="delete"
          :payload="facility"
          dispatch="Facilities/toggleModalDeleteFacility"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permit: false,
      permitUpdate: false,
      permitDelete: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    facility: {
      type: Object,
    },
  },
  created() {
    this.facilityAccess();
    this.facilityDelete();
    this.facilityUpdate();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    facilityAccess() {
      this.canAcces("facility_index").then((response) => {
        this.permit = response;
      });
    },
    facilityDelete() {
      this.canAcces("facility_destroy").then((response) => {
        this.permitDelete = response;
      });
    },
    facilityUpdate() {
      this.canAcces("facility_update").then((response) => {
        this.permitUpdate = response;
      });
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 30% 17% 50% 5% 18%;
}
</style>
