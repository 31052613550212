var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { staticClass: "cursor-pointer" }, [
    _c("div", { staticClass: "grid grid-cols-4 items-center" }, [
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.equipment.name,
              mode: "underline",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "xl",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.listAreas(_vm.equipment.areas_attributes),
              color: "ashes-dark",
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.equipment.neighborhood.name,
              color: "ashes-dark",
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4 flex" },
        [
          _vm.permitUp
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "edit",
                  payload: _vm.equipment,
                  dispatch: "Equipment/openEditModalEquipment",
                },
              })
            : _vm._e(),
          _vm.permitDes
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "delete",
                  payload: _vm.equipment,
                  dispatch: "Equipment/openCloseDeleteEquipmentConfirmation",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }