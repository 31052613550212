<template>
  <div v-if="open">
    <div id="tabs-button" class="mt-3 ml-2">
      <FilterComponent>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Digite o nome do aluno(a) para busca"
            :multiple-option="false"
            :value="inscription_filtered"
            :options="inscriptionsForGeneralMenus"
            :dispatch="filterListByInscription"
            load-dispatch="PsychosocialAccompaniments/loadInscriptionsForGeneralMenus"
          />
        </div>
        <div>
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por cpf"
            dispatch="PsychosocialAccompaniments/filterListByCPF"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Área"
            :multiple-option="false"
            :value="user_areas_filtered"
            :options="user_areas"
            :dispatch="setUserAreasFiltered"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Equipamento"
            :multiple-option="false"
            :value="user_equipments_filtered"
            :options="equipments_psychosocial"
            :label-text="getLabelEquipmentFilter()"
            :dispatch="filterListByEquipment"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Encaminhamentos Institucionais"
            :multiple-option="false"
            :value="institutional_referral_filtered"
            :options="institutional_referrals"
            :dispatch="filterListByInstitutionalReferral"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            show-label
            clear-on-select
            select-placeholder="Técnico"
            :multiple-option="false"
            :value="user_filtered"
            :options="users"
            :dispatch="filterListByUser"
          />
        </div>
      </FilterComponent>
      <div class="mt-3 mb-2">
        <a href="javascript:void(0)" @click="exportToExcel()" v-if="inscriptions.length > 0">
          <img
            class="inline-block"
            src="../../assets/images/logout_black_24dp.svg"
            alt=""
            style="cursor: pointer"
          />
          <BaseText
            text="Exportar planilha"
            typeText="custom"
            :italic="false"
            size="xs"
            color="burnedYellow"
            style="cursor: pointer"
          />
        </a>
      </div>
      <BaseModal
        v-if="
          psychosocial_accompaniments_unaccompanied_spreadsheet.length > 0 &&
          modalToggleExportPsychosocialAccompanimentUnaccompaniedSpreadsheet
        "
      >
        <template v-slot:header>
          <div class="grid grid-cols-3">
            <div class="justify-self-start col-span-2">
              <Label
                text="Exportar Planilha de Inscritos - Não Acolhidos"
                :icon="null"
                :icon-type="null"
                type="text-2xl"
                weight="bold"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="justify-self-end">
              <AppButtonIcon
                dispatch="PsychosocialAccompaniments/closeExportPsychosocialAccompanimentUnaccompaniedSpreadsheetModal"
                forClose
              />
            </div>
          </div>
        </template>
        <template v-slot:footer>
          <div class="grid grid-rows-2">
            <div class="grid justify-center items-center">
              <Label
                text="Planilha gerada com sucesso!"
                :icon="null"
                :icon-type="null"
                type="text-2x1"
                weight="normal"
                normal
                mode="normal-case"
                class="text-ashes-dark"
              />
            </div>
            <div class="grid grid-cols-2">
              <div class="justify-self-start">
                <AppButtonEmpty
                  text="descartar"
                  typeButton="danger"
                  dispatch="PsychosocialAccompaniments/closeExportPsychosocialAccompanimentUnaccompaniedSpreadsheetModal"
                />
              </div>

              <div class="justify-self-end">
                <download-excel
                  type="xls"
                  :meta="json_meta"
                  :fields="json_fields"
                  :data="json_data_psychosocial_unaccompanied"
                  :before-finish="closeExportPsychosocialAccompanimentUnaccompaniedSpreadsheetModal"
                  worksheet="Inscritos - Não Acolhidos"
                  name="inscritos_nao_acolhidos.xls"
                  :escapeCsv="false"
                >
                  <AppButtonEmpty text="Download da planilha" typeButton="success" />
                </download-excel>
              </div>
            </div>
          </div>
        </template>
      </BaseModal>
      <div v-if="!hasSpreadsheetPsychosocialAccompanimentUnaccompaniedResponse">
        <PreLoading />
      </div>
    </div>
    <div id="tabs-button" class="grid grid-cols-2 items-center">
      <div class="mx-2">
        <AppButtonColorful
          id="btn-consult-inscriptions"
          showIcon
          iconName="search"
          text="Consultar dados"
          dispatch="PsychosocialAccompaniments/loadInscriptions"
          typeButton="primary"
        />
      </div>
      <div class="mx-2 items-center justify-self-end" v-if="inscriptions.length > 0">
        <a href="javascript:void(0)" @click="clearFields()">
          <AppButtonColorful
            id="btn-consult-incident_reports"
            showIcon
            iconName="remove"
            text="Limpar consulta"
            typeButton="primary"
          />
        </a>
      </div>
    </div>
    <div id="list-psychosocials">
      <div class="mx-12">
        <PsychosocialAccompanimentsUnaccompaniedCards :data="inscriptions" />
      </div>
      <div
        id="psychosocial-acommpaniment-pagination"
        class="mx-12 my-6 pb-3"
        v-if="
          inscriptions.length > 0 &&
          (togglePsychosocialAccompanimentUnaccompanied || togglePsychosocialAccompanimentInAccompaniment)
        "
      >
        <BasePagination
          :total="inscriptionsTotal"
          :total-per-page="totalPerPage"
          dispatch="PsychosocialAccompaniments/setOffset"
        />
      </div>
    </div>
  </div>
</template>

<script>
import PsychosocialAccompanimentsUnaccompaniedCards from "../list/PsychosocialAccompanimentsUnaccompaniedCards.vue";
import BasePagination from "../../components/BasePagination.vue";
import AppButtonColorful from "../../components/AppButtonColorful.vue";
import BaseInput from "../../components/forms/BaseInput.vue";
import BaseText from "../../components/BaseText.vue";
import BaseModal from "../../components/BaseModal.vue";
import Label from "../../components/Label.vue";
import AppButtonEmpty from "../../components/AppButtonEmpty.vue";
import AppButtonIcon from "../../components/AppButtonIcon.vue";
import Multiselect from "../../components/forms/Multiselect.vue";
import PreLoading from "../../components/PreLoading.vue";
import { mapState, mapActions } from "vuex";
import FilterComponent from "@/components/FilterComponent.vue";

export default {
  components: {
    FilterComponent,
    BasePagination,
    AppButtonColorful,
    PsychosocialAccompanimentsUnaccompaniedCards,
    Multiselect,
    BaseInput,
    BaseText,
    BaseModal,
    Label,
    AppButtonEmpty,
    AppButtonIcon,
    PreLoading,
  },
  data() {
    return {
      currentPage: 1,
      first_service_select: [
        {
          id: 0,
          name: "Sim",
        },
        {
          id: 1,
          name: "Não",
        },
      ],
      permitExportSpreadsheet: false,
      json_fields: {
        Edicao: "season_name",
        "Nome do Aluno": "student_name",
        "CPF do Aluno": "student_cpf",
        "Email do Aluno": "student_email",
        Bairro: "student_neighborhood",
        Área: "student_area",
        Equipamento: "student_equipment",
        "Data de Justificativa": "justification_for_non_acceptance_date",
        "Justificativa para Não Acolhimento": "justification_for_non_acceptance",
        "Usuário(s) que registrou justificativa": "user",
      },
      json_meta: [[{ key: "charset", value: "utf-8" }]],
    };
  },
  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setSeason: "PsychosocialAccompaniments/setSeason",
      setUserAreasFiltered: "PsychosocialAccompaniments/setUserAreasFiltered",
      filterListByInscription: "PsychosocialAccompaniments/filterListByInscription",
      filterListByFirstService: "PsychosocialAccompaniments/filterListByFirstService",
      filterListByEquipment: "PsychosocialAccompaniments/filterListByEquipment",
      filterListByUser: "PsychosocialAccompaniments/filterListByUser",
      filterListByInstitutionalReferral: "PsychosocialAccompaniments/filterListByInstitutionalReferral",
      closeExportPsychosocialAccompanimentUnaccompaniedSpreadsheetModal:
        "PsychosocialAccompaniments/closeExportPsychosocialAccompanimentUnaccompaniedSpreadsheetModal",
    }),
    async clearFields() {
      await this.$store.dispatch("PsychosocialAccompaniments/clearQueryMainPage");
    },
    backToTop() {
      window.scrollTo(0, 0);
    },
    changeTab(value) {
      this.$store.dispatch("PsychosocialAccompaniments/setTabPsychosocialAccompanimentView", value);
    },
    getLabelEquipmentFilter() {
      return this.user_areas_filtered ? "" : "Selecione a área para listar os equipamentos";
    },
    async exportToExcel() {
      await this.$store.dispatch("PsychosocialAccompaniments/loadPsychosocialMaingPageSpreadsheet");
    },
  },
  created() {
    this.$store.dispatch("PsychosocialAccompaniments/loadSeasons");
  },

  computed: {
    ...mapState({
      psychosocial_accompaniments: (state) => state.PsychosocialAccompaniments.psychosocial_accompaniments,
      modalConfirmDeletePsychosocialAccompaniment: (state) =>
        state.PsychosocialAccompaniments.modalConfirmDeletePsychosocialAccompaniment,
      modalTogglePsychosocialAccompaniments: (state) =>
        state.PsychosocialAccompaniments.modalTogglePsychosocialAccompaniments,
      modalViewPsychosocialAccompaniment: (state) => state.PsychosocialAccompaniments.modalViewForwarding,
      city: (state) => state.City.city,
      seasons: (state) => state.PsychosocialAccompaniments.seasons,
      season: (state) => state.PsychosocialAccompaniments.season,
      inscriptions: (state) => state.PsychosocialAccompaniments.inscriptions,
      users: (state) => state.PsychosocialAccompaniments.users,
      user_areas: (state) => state.PsychosocialAccompaniments.user_areas,

      user_areas_filtered: (state) => state.PsychosocialAccompaniments.user_areas_filtered,
      user_equipments_filtered: (state) => state.PsychosocialAccompaniments.params.main_page.equipment,
      first_service_filtered: (state) => state.PsychosocialAccompaniments.params.main_page.first_service,
      user_filtered: (state) => state.PsychosocialAccompaniments.params.main_page.user,
      equipments_psychosocial: (state) => state.PsychosocialAccompaniments.equipments_psychosocial,
      institutional_referrals: (state) => state.PsychosocialAccompaniments.institutional_referrals,
      institutional_referral_filtered: (state) =>
        state.PsychosocialAccompaniments.params.main_page.institutional_referral,
      inscriptionsForGeneralMenus: (state) => state.PsychosocialAccompaniments.inscriptionsForGeneralMenus,
      inscription_filtered: (state) => state.PsychosocialAccompaniments.params.main_page.inscription,

      psychosocial_accompaniments_unaccompanied_spreadsheet: (state) =>
        state.PsychosocialAccompaniments.psychosocial_accompaniments_unaccompanied_spreadsheet,
      json_data_psychosocial_unaccompanied: (state) =>
        state.PsychosocialAccompaniments.json_data_psychosocial_unaccompanied,
      hasSpreadsheetPsychosocialAccompanimentUnaccompaniedResponse: (state) =>
        state.PsychosocialAccompaniments.hasSpreadsheetPsychosocialAccompanimentUnaccompaniedResponse,

      tabPsychosocialAccompanimentView: (state) =>
        state.PsychosocialAccompaniments.tabPsychosocialAccompanimentView,
      togglePsychosocialAccompanimentUnaccompanied: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentUnaccompanied,
      togglePsychosocialAccompanimentInAccompaniment: (state) =>
        state.PsychosocialAccompaniments.togglePsychosocialAccompanimentInAccompaniment,
      modalToggleAddAttendance: (state) => state.PsychosocialAccompaniments.modalToggleAddAttendance,
      modalToggleExportPsychosocialAccompanimentUnaccompaniedSpreadsheet: (state) =>
        state.PsychosocialAccompaniments.modalToggleExportPsychosocialAccompanimentUnaccompaniedSpreadsheet,

      offset: (state) => state.PsychosocialAccompaniments.offset,
      totalPerPage: (state) => state.PsychosocialAccompaniments.totalPerPage,
      inscriptionsTotal: (state) => state.PsychosocialAccompaniments.inscriptionsTotal,
    }),
  },
  watch: {
    user_areas_filtered: function () {
      this.$store.dispatch("PsychosocialAccompaniments/loadEquipments");
    },
    offset: function () {
      this.$store.dispatch("PsychosocialAccompaniments/loadInscriptions");
      this.backToTop();
    },
  },
};
</script>
