var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.inscription.student
    ? _c("div", { staticClass: "grid grid-cols-4 gap-4 mt-4" }, [
        _c(
          "div",
          { staticClass: "col-span-4 text-burnedYellow pb-2" },
          [
            _c("Label", {
              attrs: {
                text: "Informações do Aluno(a)",
                icon: null,
                "icon-type": null,
                type: "text-sm",
                weight: "semibold",
                normal: "",
                mode: "uppercase",
              },
            }),
            _c("div", { staticClass: "border-ashes-hover border-b-2 w-full" }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Nome",
                text: _vm.inscription.student.name || "",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Status do Aluno(a) no sistema",
                text: _vm.verifyStatus(),
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "CPF",
                text: _vm._f("VMask")(
                  _vm.verifyInscription("cpf"),
                  "###.###.###-##"
                ),
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Data de Nascimento",
                text: _vm.verifyInscription("birthdate"),
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Idade",
                text: _vm.verifyInscription("age"),
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Número Contato",
                text: _vm._f("VMask")(
                  _vm.verifyInscription("cellphone_number"),
                  "(##) #####-####"
                ),
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }