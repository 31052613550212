var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-2 items-center mt-4" }, [
        _c("div", [
          _c(
            "div",
            { staticClass: "ml-7" },
            [
              _c("BaseText", {
                attrs: {
                  text: "Nome",
                  typeText: "custom",
                  weight: "medium",
                  italic: false,
                  mode: "uppercase",
                  size: "xs",
                  color: "quantum",
                },
              }),
            ],
            1
          ),
        ]),
        _c("div", { staticClass: "flex justify-start gap-20" }, [
          _c(
            "div",
            [
              _c("BaseText", {
                attrs: {
                  text: "Presença",
                  typeText: "custom",
                  weight: "medium",
                  italic: false,
                  mode: "uppercase",
                  size: "xs",
                  color: "quantum",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseText", {
                attrs: {
                  text: "Falta Justificada",
                  typeText: "custom",
                  weight: "medium",
                  italic: false,
                  mode: "uppercase",
                  size: "xs",
                  color: "quantum",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "BaseStaggeredFade",
        { attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.data, function (presence, index) {
          return _c(
            "li",
            { key: presence.id, attrs: { "data-index": index } },
            [
              _c(
                "div",
                [_c("AppCardPresence", { attrs: { presence: presence } })],
                1
              ),
              _c("div", { staticClass: "border-ashes w-full border" }),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }