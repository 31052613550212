var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-4" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start col-span-3" },
                      [
                        _vm.planned_awareness_activity.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar Planejamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Adicionar Planejamento",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "PlannedAwarenessActivities/closeModalAddPlannedAwarenessActivity",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Dados cadastrais do Planejamento da Atividade",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            id: "areas",
                            "select-placeholder": "Selecionar a opção",
                            "label-text": "Selecionar Área",
                            "close-on-select": true,
                            "show-label": "",
                            "multiple-option": false,
                            value: _vm.area,
                            options: _vm.user_areas,
                            dispatch: _vm.setArea,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            id: "equipamentos",
                            "select-placeholder": "Selecionar a opção",
                            "label-text": _vm.getLabelEquipmentFilter(),
                            "close-on-select": true,
                            "show-label": "",
                            "multiple-option": false,
                            value: _vm.equipment,
                            options:
                              _vm.equipments_planned_awareness_activities,
                            dispatch: _vm.setEquipment,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "articulator",
                            "input-type": "text",
                            value: _vm.planned_awareness_activity.articulator,
                            "show-label": "",
                            "label-text": "Articulador(a) Responsável",
                            dispatch:
                              "PlannedAwarenessActivities/setArticulator",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            required: "",
                            "input-name": "activity_date",
                            "input-type": "date",
                            value: _vm.planned_awareness_activity.activity_date,
                            "show-label": "",
                            "label-text": "Data da Atividade",
                            dispatch:
                              "PlannedAwarenessActivities/setActivityDate",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseTextArea", {
                          attrs: {
                            active: "",
                            "input-name": "purpose",
                            "input-type": "text",
                            value: _vm.planned_awareness_activity.purpose,
                            "show-label": "",
                            rows: "4",
                            "label-text": "Objetivo",
                            dispatch: "PlannedAwarenessActivities/setPurpose",
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseTextArea", {
                          attrs: {
                            active: "",
                            "input-name": "methodology",
                            "input-type": "text",
                            value: _vm.planned_awareness_activity.methodology,
                            "show-label": "",
                            rows: "4",
                            "label-text": "Metodologia",
                            dispatch:
                              "PlannedAwarenessActivities/setMethodology",
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseTextArea", {
                          attrs: {
                            active: "",
                            "input-name": "used_resources",
                            "input-type": "text",
                            value:
                              _vm.planned_awareness_activity.used_resources,
                            "show-label": "",
                            rows: "4",
                            "label-text": "Recursos Utilizados",
                            dispatch:
                              "PlannedAwarenessActivities/setUsedResources",
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.isDateExpired() && _vm.planned_awareness_activity.id
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseTextArea", {
                              attrs: {
                                active: "",
                                "input-name": "activity_evaluation",
                                "input-type": "text",
                                value:
                                  _vm.planned_awareness_activity
                                    .activity_evaluation,
                                "show-label": "",
                                rows: "4",
                                "label-text": "Avaliação da Atividade",
                                dispatch:
                                  "PlannedAwarenessActivities/setActivityEvaluation",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "PlannedAwarenessActivities/closeModalAddPlannedAwarenessActivity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.planned_awareness_activity.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Salvar",
                                typeButton: "success",
                                dispatch: "PlannedAwarenessActivities/update",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "criar",
                                typeButton: "success",
                                dispatch: "PlannedAwarenessActivities/save",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1060972324
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }