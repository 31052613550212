var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: [_vm.buttonClass, "flex", "justify-center", "items-center"],
      attrs: { type: "button" },
      on: { click: _vm.onClick },
    },
    [_c("BaseText", { attrs: { text: _vm.text, typeText: _vm.typeText } })],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }