<template>
  <BaseCard>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4">
        <BaseText
          :text="teacher.name"
          mode="underline"
          color="quantum"
          typeText="custom"
          weight="bold"
          size="xl"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="teacher.cpf"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="teacher.cellphone_number"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4">
        <BaseText
          :text="teacher.email"
          mode="normal-case"
          color="ashes-dark"
          typeText="custom"
          weight="normal"
          size="sm"
        />
      </div>
      <div class="mt-4 flex">
        <AppButtonIcon
          iconName="edit"
          :payload="teacher"
          dispatch="Teachers/openEditModalTeacher"
          v-if="permitUp"
        />
        <div>
          <AppButtonIcon
            v-if="permitResetPassword"
            iconName="vpn_key"
            dispatch="Teacher/openModalResetPassword"
            :payload="teacher"
          />
        </div>
        <AppButtonIcon
          iconName="delete"
          :payload="teacher"
          dispatch="Teachers/openCloseDeleteTeacherConfirmation"
          v-if="permitDes"
        />
      </div>
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permitResetPassword: false,
      permit: false,
    };
  },
  props: {
    active: {
      type: Boolean,
      default: false,
    },
    teacher: {
      type: Object,
    },
  },
  created() {
    this.teacherAcces();
    this.teacherChange();
    this.teacherDelete();
    this.resetUserPassword();
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
    teacherAcces() {
      this.canAcces("teacher_index").then((resp) => {
        this.permit = resp;
      });
    },
    teacherDelete() {
      this.canAcces("teacher_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    teacherChange() {
      this.canAcces("teacher_update").then((resp) => {
        this.permitUp = resp;
      });
    },
    resetUserPassword() {
      this.canAcces("user_reset_password").then((resp) => {
        this.permitResetPassword = resp;
      });
    },
  },
  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-4": true,
        "justify-items-stretch": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
        "cursor-pointer": true,
      };
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 3fr 2fr 2fr 3fr 0fr;
}
</style>
