<template>
  <div>
    <div class="grid grid-cols-custom items-center -mx-12">
      <div class="mt-10 ml-5">
        <BaseText
          text="Nome do Participante"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Área"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Tipo da Vaga"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Solicitação foi Atendida?"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul" class="-mx-12">
      <li :key="vacancy_request.id" :data-index="index" v-for="(vacancy_request, index) in data">
        <div>
          <AppCardSchoolReinsertionNotReinsertedVacancyRequest :vacancy_request="vacancy_request" />
        </div>
        <div class="border-ashes w-full border ml-5"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardSchoolReinsertionNotReinsertedVacancyRequest from "../../components/AppCardSchoolReinsertionNotReinsertedVacancyRequest.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";

export default {
  components: {
    AppCardSchoolReinsertionNotReinsertedVacancyRequest,
    BaseText,
    BaseStaggeredFade,
  },
  props: {
    data: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 33% 14% 18% 20% 10% 15%;
}
.bg-blue-primary {
  background-color: #24c2ce;
}
.bg-green-primary {
  background-color: #3cbb66;
}
.bg-white-primary {
  background-color: #ffffff;
}
</style>
