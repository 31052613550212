var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
    _c(
      "div",
      { staticClass: "col-span-2 text-burnedYellow pb-2" },
      [
        _c("Label", {
          attrs: {
            text: "Comentários e Conclusão do Encontro",
            icon: null,
            "icon-type": null,
            type: "text-sm",
            weight: "semibold",
            normal: "",
            mode: "uppercase",
          },
        }),
        _c("div", { staticClass: "border-ashes-hover border-b-2 w-full" }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "col-span-2" },
      [
        _c("BaseTextArea", {
          attrs: {
            active: "",
            inputName: "Situação apresentada",
            labelText: "Situação apresentada",
            showLabel: "",
            rows: "4",
            value:
              _vm.psychosocial_accompaniment?.meeting_conclusion_description,
            dispatch:
              "PsychosocialAccompaniments/setMeetingConclusionDescription",
          },
        }),
      ],
      1
    ),
    _vm.psychosocial_accompaniment?.meeting_happened
      ? _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("BaseTextArea", {
              attrs: {
                active: "",
                inputName: "accompaniment_comments",
                labelText: "Observações técnicas sobre o acompanhamento",
                showLabel: "",
                rows: "4",
                value: _vm.psychosocial_accompaniment?.accompaniment_comments,
                dispatch: "PsychosocialAccompaniments/setAccompanimentComments",
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }