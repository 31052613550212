<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="spontaneous_productive_insertion.id"
            text="Editar Inserção"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Criar Inserção"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon
            dispatch="SpontaneousProductiveInsertions/closeModalAddSpontaneousProductiveInsertion"
            forClose
          />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div class="col-span-2">
          <Multiselect
            :close-on-select="true"
            label-text="Nome do Participante"
            show-label
            clear-on-select
            required
            select-placeholder="Digite o nome do participante para busca"
            :multiple-option="false"
            :value="inscription"
            :options="inscriptions"
            :dispatch="setInscription"
            load-dispatch="SpontaneousProductiveInsertions/loadInscriptions"
            :error-message="getErrorMessage('inscrição')"
          />
        </div>
        <div>
          <BaseInput
            id="insertion_date"
            input-name="insertion_date"
            input-type="date"
            show-label
            required
            label-text="Data da Inserção"
            :value="spontaneous_productive_insertion.insertion_date"
            dispatch="SpontaneousProductiveInsertions/setInsertionDate"
            :error-message="getErrorMessage('data da inserção')"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Tipo da Inserção"
            show-label
            clear-on-select
            required
            select-placeholder="Escolha o Tipo da Inserção"
            :multiple-option="false"
            :value="insertion_type"
            :options="insertion_types"
            :dispatch="setInsertionType"
            :error-message="getErrorMessage('tipo da inserção')"
          />
        </div>
        <div class="col-span-2">
          <BaseTextArea
            active
            :showIcon="true"
            iconName="help"
            inputName="observations"
            labelText="Observações"
            :tooltipMessage="tooltipObservationMessage"
            required
            showLabel
            rows="4"
            :value="spontaneous_productive_insertion.observations"
            dispatch="SpontaneousProductiveInsertions/setObservations"
            :error-message="getErrorMessage('observações')"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 mt-4" v-if="insertionTypeIsJob()">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações - Emprego"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2" v-if="insertionTypeIsJob()">
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Empresa"
            show-label
            clear-on-select
            required
            select-placeholder="Digite o nome da empresa para busca"
            :multiple-option="false"
            :value="company"
            :options="companies"
            :dispatch="setCompany"
            load-dispatch="SpontaneousProductiveInsertions/loadCompanies"
            :error-message="getErrorMessage('empresa')"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Tipo da Contratação"
            show-label
            clear-on-select
            required
            select-placeholder="Escolha o Tipo da Contratação"
            :multiple-option="false"
            :value="contracting_type"
            :options="contracting_types"
            :dispatch="setContractingType"
            :error-message="getErrorMessage('tipo da contratação')"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 mt-4" v-if="insertionTypeIsEntrepreneurship()">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais - Empreendedorismo"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2" v-if="insertionTypeIsEntrepreneurship()">
        <div>
          <AppRadioButton
            :values="[
              {
                name: 'had_access_credit_for_financing',
                value: true,
                description: 'Sim',
                checked: spontaneous_productive_insertion.had_access_credit_for_financing === true,
              },
              {
                name: 'had_access_credit_for_financing',
                value: false,
                description: 'Não',
                checked: spontaneous_productive_insertion.had_access_credit_for_financing === false,
              },
            ]"
            labelText="Teve acesso a crédito para financiamento?"
            showLabel
            dispatch="SpontaneousProductiveInsertions/setHadAccessCreditForFinancing"
            :error-message="getErrorMessage('teve acesso a crédito para financiamento?')"
          />
        </div>
        <div>
          <AppRadioButton
            :values="[
              {
                name: 'has_establishment',
                value: true,
                description: 'Sim',
                checked: spontaneous_productive_insertion.has_establishment === true,
              },
              {
                name: 'has_establishment',
                value: false,
                description: 'Não',
                checked: spontaneous_productive_insertion.has_establishment === false,
              },
            ]"
            labelText="Possui estabelecimento?"
            showLabel
            dispatch="SpontaneousProductiveInsertions/setHasEstablishment"
            :error-message="getErrorMessage('tem estabelecimento?')"
          />
        </div>
      </div>
      <div class="grid grid-cols-2 mt-4" v-if="hasEstablishment()">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações - Estabelecimento"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2" v-if="hasEstablishment()">
        <div class="col-span-2">
          <BaseInput
            id="establishment_name"
            input-name="establishment_name"
            input-type="text"
            show-label
            required
            label-text="Nome"
            :value="spontaneous_productive_insertion.establishment_name"
            dispatch="SpontaneousProductiveInsertions/setEstablishmentName"
            :error-message="getErrorMessage('nome do estabelecimento')"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            id="establishment_address"
            input-name="establishment_address"
            input-type="text"
            show-label
            required
            label-text="Endereço"
            :value="spontaneous_productive_insertion.establishment_address"
            dispatch="SpontaneousProductiveInsertions/setEstablishmentAddress"
            :error-message="getErrorMessage('endereço do estabelecimento')"
          />
        </div>
        <div>
          <AppRadioButton
            :values="[
              {
                name: 'has_cnpj',
                value: true,
                description: 'Sim',
                checked: spontaneous_productive_insertion.has_cnpj === true,
              },
              {
                name: 'has_cnpj',
                value: false,
                description: 'Não',
                checked: spontaneous_productive_insertion.has_cnpj === false,
              },
            ]"
            labelText="Possui CNPJ?"
            showLabel
            dispatch="SpontaneousProductiveInsertions/setHasCnpj"
            :error-message="getErrorMessage('tem cnpj?')"
          />
        </div>
        <div>
          <AppRadioButton
            :values="[
              {
                name: 'is_related_to_the_course_segment',
                value: true,
                description: 'Sim',
                checked: spontaneous_productive_insertion.is_related_to_the_course_segment === true,
              },
              {
                name: 'is_related_to_the_course_segment',
                value: false,
                description: 'Não',
                checked: spontaneous_productive_insertion.is_related_to_the_course_segment === false,
              },
            ]"
            labelText="Possui relação com o segmento do curso?"
            showLabel
            dispatch="SpontaneousProductiveInsertions/setIsRelatedToTheCourseSegment"
            :error-message="getErrorMessage('possui relação com o segmento do curso?')"
          />
        </div>
        <div class="col-span-2 mb-3">
          <BaseLabel text="Anexar Fotos" />
          <span class="text-burnedYellow text-sm">
            [Atenção - Arquivos anexados com tamanho superior a 5MB terão automaticamente seus tamanhos
            reduzidos, podendo haver perda de qualidade.]
          </span>
          <input
            type="file"
            id="photos"
            name="photos[files][]"
            multiple
            @change="handleFileUpload"
            class="mt-1 block w-full"
          />
        </div>

        <div v-if="previews.length" class="col-span-2 mb-4">
          <div v-for="(preview, index) in previews" :key="index" class="inline-block mr-2">
            <img :src="preview" class="h-20 w-20 object-cover" />
          </div>
        </div>
      </div>
      <div
        class="grid grid-cols-2 gap-4 mt-2"
        v-if="insertionTypeIsJob() || insertionTypeIsEntrepreneurship()"
      >
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Segmento"
            show-label
            clear-on-select
            required
            select-placeholder="Digite o nome do segmento para busca"
            :multiple-option="false"
            :value="segment"
            :options="segments"
            :dispatch="setSegment"
            load-dispatch="SpontaneousProductiveInsertions/loadSegments"
            :error-message="getErrorMessage('segmento')"
          />
        </div>
        <div>
          <Multiselect
            :close-on-select="true"
            label-text="Ocupação"
            show-label
            clear-on-select
            required
            select-placeholder="Digite o nome da ocupação para busca"
            :multiple-option="false"
            :value="occupation"
            :options="occupations"
            :dispatch="setOccupation"
            load-dispatch="SpontaneousProductiveInsertions/loadOccupations"
            :error-message="getErrorMessage('ocupação')"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty
            text="descartar"
            typeButton="danger"
            dispatch="SpontaneousProductiveInsertions/closeModalAddSpontaneousProductiveInsertion"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="spontaneous_productive_insertion.id"
            text="Atualizar"
            typeButton="success"
            dispatch="SpontaneousProductiveInsertions/update"
          />
          <AppButtonEmpty
            v-else
            text="Criar"
            typeButton="success"
            dispatch="SpontaneousProductiveInsertions/save"
          />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import AppRadioButton from "@/components/AppRadioButton.vue";
import BaseModal from "../../../components/BaseModal.vue";
import BaseInput from "../../../components/forms/BaseInput.vue";
import Label from "../../../components/Label.vue";
import AppButtonIcon from "../../../components/AppButtonIcon.vue";
import AppButtonEmpty from "../../../components/AppButtonEmpty.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import Multiselect from "../../../components/forms/Multiselect.vue";
import BaseTextArea from "@/components/forms/BaseTextArea.vue";
import BaseLabel from "@/components/BaseLabel.vue";

export default {
  components: {
    BaseLabel,
    BaseTextArea,
    AppRadioButton,
    Multiselect,
    BaseModal,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      tooltipObservationMessage:
        "Anotar neste\n" +
        "campo as observações qualitativas pertinentes à inserção que não sejam pedidas\n" +
        "nos campos abaixo. Se for emprego, informar como o participante soube da vaga,\n" +
        "detalhes sobre a ocupação, há quanto tempo etc. Se for empreendedorismo,\n" +
        "informar se a atividade é executada em casa, se o participante já trabalhava com\n" +
        "isso antes do curso, se trabalha com outras pessoas etc.",
      photos: [],
    };
  },
  computed: {
    ...mapState({
      spontaneous_productive_insertion: (state) =>
        state.SpontaneousProductiveInsertions.spontaneous_productive_insertion,
      contracting_type: (state) => state.SpontaneousProductiveInsertions.contracting_type,
      contracting_types: (state) => state.SpontaneousProductiveInsertions.contracting_types,
      insertion_type: (state) => state.SpontaneousProductiveInsertions.insertion_type,
      insertion_types: (state) => state.SpontaneousProductiveInsertions.insertion_types,
      inscription: (state) => state.SpontaneousProductiveInsertions.inscription,
      inscriptions: (state) => state.SpontaneousProductiveInsertions.inscriptions,
      company: (state) => state.SpontaneousProductiveInsertions.company,
      companies: (state) => state.SpontaneousProductiveInsertions.companies,
      segment: (state) => state.SpontaneousProductiveInsertions.segment,
      segments: (state) => state.SpontaneousProductiveInsertions.segments,
      occupation: (state) => state.SpontaneousProductiveInsertions.occupation,
      occupations: (state) => state.SpontaneousProductiveInsertions.occupations,
      previews: (state) => state.SpontaneousProductiveInsertions.previews,
    }),
    ...mapGetters({
      getErrorMessage: "SpontaneousProductiveInsertions/getErrorMessage",
    }),
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
      setInscription: "SpontaneousProductiveInsertions/setInscription",
      setContractingType: "SpontaneousProductiveInsertions/setContractingType",
      setInsertionType: "SpontaneousProductiveInsertions/setInsertionType",
      setCompany: "SpontaneousProductiveInsertions/setCompany",
      setSegment: "SpontaneousProductiveInsertions/setSegment",
      setOccupation: "SpontaneousProductiveInsertions/setOccupation",
    }),
    insertionTypeIsJob() {
      return this.insertion_type && this.insertion_type.id === 0;
    },
    insertionTypeIsEntrepreneurship() {
      return this.insertion_type && this.insertion_type.id === 1;
    },
    hasEstablishment() {
      if (this.spontaneous_productive_insertion.has_establishment) {
        return this.spontaneous_productive_insertion.has_establishment;
      }
      return false;
    },
    handleFileUpload(event) {
      const files = Array.from(event.target.files);
      let previews_files = [];

      const fileReadPromises = files.map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onload = (e) => {
            previews_files.push(e.target.result);
            resolve();
          };
          reader.onerror = reject;
          reader.readAsDataURL(file);
        });
      });
      Promise.all(fileReadPromises)
        .then(() => {
          this.$store.dispatch("SpontaneousProductiveInsertions/setPhotos", files);
          this.$store.dispatch("SpontaneousProductiveInsertions/setPreviews", previews_files);
        })
        .catch((error) => {
          console.error("Erro ao ler os arquivos: ", error);
        });
    },
  },
};
</script>
