var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.psychosocial_accompaniment?.meeting_happened
    ? _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
        _c(
          "div",
          { staticClass: "col-span-2 text-burnedYellow pb-2" },
          [
            _c("Label", {
              attrs: {
                text: "Situação Escolar",
                icon: null,
                "icon-type": null,
                type: "text-sm",
                weight: "semibold",
                normal: "",
                mode: "uppercase",
              },
            }),
            _c("div", { staticClass: "border-ashes-hover border-b-2 w-full" }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppRadioButton", {
              attrs: {
                values: _vm.isStudyingOptions,
                labelText: "Está estudando?",
                showLabel: "",
                dispatch: "PsychosocialAccompaniments/setIsStudying",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("BaseTextArea", {
              attrs: {
                active: "",
                inputName: "reason_for_not_studying",
                labelText: "Razão para não estar estudando",
                showLabel: "",
                rows: "4",
                value: _vm.psychosocial_accompaniment?.reason_for_not_studying,
                dispatch: "PsychosocialAccompaniments/setReasonForNotStudying",
                readonly: _vm.psychosocial_accompaniment?.is_studying,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-1" },
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "last_grade_studied",
                "input-type": "text",
                value: _vm.psychosocial_accompaniment?.last_grade_studied,
                "show-label": "",
                "label-text": "Última série estudada",
                dispatch: "PsychosocialAccompaniments/setLastGradeStudied",
                readonly: _vm.psychosocial_accompaniment?.is_studying,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-1" },
          [
            _c("BaseInput", {
              attrs: {
                active: "",
                "input-name": "last_school_studied",
                "input-type": "text",
                value: _vm.psychosocial_accompaniment?.last_school_studied,
                "show-label": "",
                "label-text": "Última escola em que estudou",
                dispatch: "PsychosocialAccompaniments/setLastSchoolStudied",
                readonly: _vm.psychosocial_accompaniment?.is_studying,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppRadioButton", {
              attrs: {
                values: _vm.needReinsertionOptions,
                labelText: "Necessita de reinserção escolar?",
                showLabel: "",
                dispatch: "PsychosocialAccompaniments/setNeedSchoolReinsertion",
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }