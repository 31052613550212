<template>
  <div class="main w-full img-back">
    <div id="top" class="bg-transparent">
      <div class="flex flex-wrap justify-between items-center ml-4 md:ml-16 lg:ml-32 pt-16 lg:pt-24 mb-6">
        <div id="logo" class="w-full lg:w-1/2 text-center lg:text-left">
          <img class="img-logo mx-auto lg:mx-0" src="../assets/images/logo-inicio.png" alt="Logo" />
        </div>
        <div id="menus" class="flex justify-center items-center w-full lg:w-auto mt-5 lg:mt-0 sm:mt-2">
          <AppButtonEmpty
            :active="true"
            text="Início"
            class="w-32 lg:w-36 h-10 mr-4 lg:mr-8"
            type-button="default"
            type-text="button-generic"
          />
          <router-link to="/sobre">
            <AppButtonEmpty
              text="Sobre"
              class="w-32 lg:w-36 h-10 mr-4 lg:mr-8"
              type-button="default"
              type-text="button-generic"
            />
          </router-link>
          <router-link class="text-white underline hover:text-burnedYellow mr-4 lg:mr-8" to="/admin/entrar">
            <BaseText text="Área de Login" type-text="custom" weight="medium" />
          </router-link>
        </div>
      </div>
    </div>

    <div id="mid" class="mt-8 md:mt-12">
      <div class="flex flex-wrap ml-4 md:ml-16 lg:ml-32">
        <div id="text" class="w-full lg:w-1/2 text-white z-10">
          <div class="mt-12 md:mt-16 lg:mt-20">
            <BaseText text="Olá!" type-text="custom" weight="extrabold" size="2xl md:3xl" />
          </div>
          <div class="mt-1">
            <BaseText
              text="Conhece o projeto"
              color="herbal-hover"
              type-text="custom"
              weight="normal"
              size="2xl md:3xl"
            />
            <BaseText text="Virando o Jogo?" type-text="custom" weight="bold" size="2xl md:3xl" />
          </div>
          <div id="description" class="mt-8 md:mt-10">
            <BaseText type-text="custom" size="lg">
              O Virando o Jogo é um projeto criado pelo Governo do Estado do Ceará voltado para jovens que não
              estudam e não trabalham...
            </BaseText>
          </div>
          <div class="mt-10 md:mt-12">
            <BaseText
              text="Para participar é só fazer o pré-cadastro!"
              type-text="custom"
              weight="bold"
              size="lg md:xl"
            />
            <br />
            <BaseText text="Vem com a gente?" type-text="custom" weight="medium" size="lg md:xl" />
          </div>
          <div class="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div class="flex my-12 mt-16 md:mt-20">
              <AppButtonEmpty
                text="Pré-cadastro"
                class="w-48 lg:w-56 h-14 lg:h-16 mr-4 lg:mr-8"
                type-button="default"
                type-text="button-generic"
                dispatch="Default/toggleModalPreInscription"
                call-to-action
              />
            </div>
            <div class="flex my-12 mt-16 md:mt-20">
              <a
                href="/docs/LIVRO_SUPERACAO_25X25_CM_DIGITAL.pdf"
                target="_blank"
                class="text-ashes-dark hover:text-burnedYellow"
              >
                <AppButtonEmpty
                  text="Livro Virando o Jogo"
                  class="h-14 lg:h-16 mr-4 lg:mr-8"
                  type-button="default"
                  type-text="button-generic"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bot w-full"></div>
    <ModalPreInscription :open="modalPreInscriptionOpen" />
  </div>
</template>

<script>
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import BaseText from "../components/BaseText.vue";
import ModalPreInscription from "./ModalPreInscription.vue";
import { mapState } from "vuex";

export default {
  components: {
    AppButtonEmpty,
    BaseText,
    ModalPreInscription,
  },

  computed: {
    ...mapState({
      modalPreInscriptionOpen: (state) => state.Default.modalPreInscriptionOpen,
    }),
  },
};
</script>

<style scoped>
#description {
  width: 100%;
  max-width: 600px;
}

.flex ::v-deep(.flex) {
  display: flex;
  flex-wrap: wrap;
}

.img-logo {
  max-width: 100%;
  min-width: 200px;
}

.main {
  background-color: #3ba543;
  background-image: linear-gradient(45deg, #3ba543 49%, #69c968 70%);
  width: 100%;
  min-height: 100vh;
  position: relative;
}

.img-back {
  background-image: url("../assets/images/background-young.png");
  background-repeat: no-repeat;
  background-position: bottom right;
  background-size: contain;
}

.bot {
  background-image: url("../assets/images/img-rodape.png");
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 55px;
  padding: 10px 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 10;
}
</style>
