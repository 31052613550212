var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permitList
    ? _c("div", { staticClass: "font-system" }, [
        _c("div", { staticClass: "mx-12 my-6", attrs: { id: "title-page" } }, [
          _c("div", { staticClass: "text-ashes-hover font-medium text-3xl" }, [
            _vm._v(_vm._s(_vm.city.name) + " | Equipamentos"),
          ]),
        ]),
        _c(
          "div",
          {
            staticClass: "flex justify-between mx-12 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c("div", { staticClass: "flex" }),
            _c(
              "div",
              { staticClass: "float-right" },
              [
                _vm.permit
                  ? _c("AppButtonColorful", {
                      attrs: {
                        showIcon: "",
                        iconName: "add",
                        text: "Adicionar Equipamento",
                        dispatch: "Equipment/openModalEquipment",
                        typeButton: "primary",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { attrs: { id: "list-equipment" } },
          [
            _c(
              "div",
              {
                staticClass: "mx-12 my-6 flex items-center",
                attrs: { id: "filter" },
              },
              [
                _c(
                  "div",
                  { staticClass: "text-ashes-dark font-medium text-sm mr-4" },
                  [_vm._v("FILTRAR")]
                ),
                _c(
                  "div",
                  { staticClass: "w-52 mr-4" },
                  [
                    _c("Multiselect", {
                      attrs: {
                        id: "filter-area",
                        "select-placeholder": "Área...",
                        "close-on-select": "",
                        "multiple-option": false,
                        value: _vm.filteredArea,
                        options: _vm.areas,
                        dispatch: _vm.setAreas,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "w-52 mr-4" },
                  [
                    _c("Multiselect", {
                      attrs: {
                        id: "filter-neighborhood",
                        "select-placeholder": "Bairro...",
                        "close-on-select": "",
                        "multiple-option": false,
                        value: _vm.filteredNeighborhood,
                        options: _vm.neighborhoods,
                        dispatch: _vm.setNeighborhoods,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.clearFilters()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "inline-block",
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          src: require("../assets/images/filter_alt_black_24dp.svg"),
                          alt: "",
                        },
                      }),
                      _c("BaseText", {
                        attrs: {
                          text: "Limpar",
                          typeText: "custom",
                          weight: "normal",
                          italic: false,
                          size: "xs",
                          color: "burnedYellow",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            ),
            _c(
              "div",
              { staticClass: "mx-12" },
              [_c("EquipmentCards", { attrs: { data: _vm.equipment } })],
              1
            ),
            _c("ModalEquipment", { attrs: { open: _vm.openModalEquipment } }),
            _c("DeleteEquipmentConfirmation", {
              attrs: { open: _vm.openModalDelete },
            }),
          ],
          1
        ),
      ])
    : _c("ProhibitedAccessPage", {
        attrs: {
          classText:
            "grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }