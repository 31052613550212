var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { staticClass: "cursor-pointer" }, [
    _c("div", { staticClass: "grid grid-cols-5" }, [
      _c(
        "div",
        { staticClass: "mt-4 col-span-2" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.benefit.description,
              mode: "underline",
              color: "quantum",
              typeText: "custom",
              weight: "bold",
              size: "xl",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: `${_vm.benefit.student_amount}`,
              mode: "normal",
              color: "ashes-dark",
              typeText: "custom",
              weight: "normal",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4 ml-2" },
        [
          _c("BaseText", {
            attrs: {
              text: `R$ ${_vm.benefit.total}`,
              mode: "normal",
              color: "burnedYellow",
              typeText: "custom",
              weight: "extrabold",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4 flex" },
        [
          !_vm.benefit.payday && _vm.permitUp
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "edit",
                  payload: _vm.benefit,
                  dispatch: "Benefit/openEditModal",
                },
              })
            : _vm._e(),
          !_vm.benefit.payday && _vm.permitDes
            ? _c("AppButtonIcon", {
                attrs: {
                  iconName: "delete",
                  payload: _vm.benefit,
                  dispatch: "Benefit/openCloseModalDeleteBenefitConfirmation",
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }