var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("HeaderComponent", {
                    attrs: {
                      headerText: _vm.psychosocial_accompaniment?.id
                        ? "Realizar Atendimento - Visita Domiciliar"
                        : "Adicionar Visita Domiciliar",
                      headerClass: "text-ashes-dark",
                      closeDispatch:
                        "PsychosocialAccompaniments/closeEditModalPsychosocialHomeVisit",
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("AccompanimentInfo", {
                    attrs: { accompanimentOrigin: _vm.accompaniment_origin },
                  }),
                  _c("StudentInfo", {
                    attrs: { inscription: _vm.inscription },
                  }),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Dados cadastrais do encontro",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "birthdate",
                            "input-type": "date",
                            value:
                              _vm.psychosocial_accompaniment.date_accompaniment,
                            "show-label": "",
                            "label-text": "Data do Encontro",
                            required: "",
                            dispatch:
                              "PsychosocialAccompaniments/setDateAccompaniment",
                            readonly: true,
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-1" },
                      [
                        _c("AppRadioButton", {
                          attrs: {
                            values: [
                              {
                                name: "has_meeting_happened",
                                value: true,
                                description: "Sim",
                                checked:
                                  _vm.psychosocial_accompaniment
                                    .meeting_happened === true,
                              },
                              {
                                name: "has_meeting_happened",
                                value: false,
                                description: "Não",
                                checked:
                                  _vm.psychosocial_accompaniment
                                    .meeting_happened === false,
                              },
                            ],
                            labelText: "Encontro aconteceu?",
                            showLabel: "",
                            dispatch:
                              "PsychosocialAccompaniments/setMeetingHappened",
                          },
                        }),
                      ],
                      1
                    ),
                    !_vm.psychosocial_accompaniment.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder":
                                  "Motivo do Encontro Não ter Ocorrido",
                                "label-text":
                                  "Motivo do Encontro Não ter Ocorrido",
                                "multiple-option": false,
                                value: _vm.meeting_not_happened_reason,
                                options: _vm.meeting_not_happened_reasons,
                                dispatch: _vm.setMeetingNotHappenedReason,
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment.meeting_happened
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name":
                                  "psychosocial_accompaniment_meeting_place",
                                "input-type": "text",
                                value:
                                  _vm.psychosocial_accompaniment.meeting_place,
                                "show-label": "",
                                "label-text": "Local da Visita Domiciliar",
                                dispatch:
                                  "PsychosocialAccompaniments/setMeetingPlace",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseTextArea", {
                              attrs: {
                                active: "",
                                inputName: "purpose_home_visit",
                                labelText: "Objetivo da Visita Domiciliar",
                                showLabel: "",
                                rows: "4",
                                value:
                                  _vm.psychosocial_accompaniment
                                    .purpose_home_visit,
                                dispatch:
                                  "PsychosocialAccompaniments/setPurposeHomeVisit",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("AttendantTeams"),
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _vm.psychosocial_accompaniment.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Participantes Presentes na Visitia Domiciliar",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "register_participant_presence",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.register_participant_presence ===
                                      true,
                                  },
                                  {
                                    name: "register_participant_presence",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.register_participant_presence ===
                                      false,
                                  },
                                ],
                                labelText:
                                  "Informar pessoas presentes na visita domiciliar?",
                                showLabel: "",
                                dispatch:
                                  "PsychosocialAccompaniments/setRegisterParticipantPresence",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.register_participant_presence
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _vm._l(
                              _vm.participant_presence_attributes,
                              function (participant, index) {
                                return _c(
                                  "div",
                                  {
                                    key: index,
                                    staticClass: "grid grid-cols-11 gap-4 mt-2",
                                  },
                                  [
                                    _vm.psychosocial_accompaniment
                                      .meeting_happened
                                      ? _c(
                                          "div",
                                          { staticClass: "col-span-5" },
                                          [
                                            _c("Multiselect", {
                                              attrs: {
                                                "close-on-select": true,
                                                "show-label": "",
                                                "clear-on-select": "",
                                                "select-placeholder":
                                                  "Selecione a opção",
                                                "label-text":
                                                  "Graus de Parentesco",
                                                "multiple-option": false,
                                                value:
                                                  _vm
                                                    .relationship_degree_attributes[
                                                    index
                                                  ],
                                                options:
                                                  _vm.mountRelationshipDegrees(
                                                    index
                                                  ),
                                                required: "",
                                                dispatch:
                                                  _vm.setRelationshipDegree,
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.psychosocial_accompaniment
                                      .meeting_happened
                                      ? _c(
                                          "div",
                                          { staticClass: "col-span-5" },
                                          [
                                            _c("BaseInput", {
                                              attrs: {
                                                active: "",
                                                "input-name": "name",
                                                "input-type": "text",
                                                value:
                                                  _vm
                                                    .participant_presence_attributes[
                                                    index
                                                  ].name,
                                                "show-label": "",
                                                required: "",
                                                payload: { index },
                                                "label-text": "Nome",
                                                dispatch:
                                                  "PsychosocialAccompaniments/setParticipantPresenceName",
                                              },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    _vm.participant_presence_attributes.length >
                                      1 &&
                                    _vm.psychosocial_accompaniment
                                      .meeting_happened
                                      ? _c("div", { staticClass: "mt-5" }, [
                                          _c(
                                            "a",
                                            {
                                              staticClass:
                                                "text-burnedYellow underline",
                                              attrs: {
                                                href: "javascript:void(0)",
                                              },
                                              on: {
                                                click:
                                                  _vm.deleteParticipantPresence,
                                              },
                                            },
                                            [
                                              _c("AppMaterialIcon", {
                                                staticClass: "align-middle",
                                                attrs: {
                                                  "icon-name": "delete",
                                                  "icon-size": "36",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ])
                                      : _vm._e(),
                                  ]
                                )
                              }
                            ),
                            _vm.participant_presence_attributes.length > 1 &&
                            _vm.psychosocial_accompaniment.meeting_happened
                              ? _c("div", { staticClass: "col-span-4 mt-5" }, [
                                  _c("hr", {
                                    staticClass:
                                      "border-t-0 border-b-2 border-ashes opacity-50",
                                  }),
                                ])
                              : _vm._e(),
                          ],
                          2
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment.meeting_happened &&
                    _vm.register_participant_presence
                      ? _c("div", { staticClass: "mt-5" }, [
                          _c(
                            "a",
                            {
                              staticClass: "text-burnedYellow",
                              attrs: { href: "javascript:void(0)" },
                              on: { click: _vm.addParticipantPresence },
                            },
                            [
                              _c("AppMaterialIcon", {
                                staticClass: "align-middle",
                                attrs: {
                                  "icon-name": "add_circle",
                                  "icon-size": "36",
                                },
                              }),
                              _c("BaseText", {
                                staticClass: "align-middle mr-2",
                                attrs: {
                                  text: "Adicionar Participante Presente na Visita Domiciliar",
                                  "type-text": "custom",
                                  weight: "medium",
                                  size: "sm",
                                },
                              }),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm.psychosocial_accompaniment.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2 text-burnedYellow pb-2" },
                          [
                            _c("Label", {
                              attrs: {
                                text: "Contexto familiar, socioeconômico e comunitário",
                                icon: null,
                                "icon-type": null,
                                type: "text-sm",
                                weight: "semibold",
                                normal: "",
                                mode: "uppercase",
                              },
                            }),
                            _c(
                              "button",
                              {
                                ref: "btnSocialFamilyRef",
                                staticClass:
                                  "uppercase rounded outline-none ease-linear transition-all duration-150",
                                on: {
                                  mouseenter: function ($event) {
                                    return _vm.toggleTooltip(
                                      "btnSocialFamilyRef"
                                    )
                                  },
                                  mouseleave: function ($event) {
                                    return _vm.toggleTooltip(
                                      "btnSocialFamilyRef"
                                    )
                                  },
                                },
                              },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "material-icons mt-6 text-burnedYellow",
                                  },
                                  [
                                    _c(
                                      "span",
                                      { staticClass: "material-icons" },
                                      [_vm._v(" info ")]
                                    ),
                                  ]
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              {
                                ref: "tooltipSocialFamilyRef",
                                staticClass:
                                  "bg-burnedYellow border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg",
                                class: {
                                  hidden: !_vm.tooltipSocialFamilyShow,
                                  block: _vm.tooltipSocialFamilyShow,
                                },
                              },
                              [
                                _c("div", [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg",
                                    },
                                    [
                                      _vm._v(
                                        " Informar com quem mora, como quantos membros da famílias, se reside no mesmo ambiente que suas referências familiares, se for adolescente, existe responsável de legal, sinalizar se o adolescente/filhos já têm filhos, caso sim quantos, reside com parceiras/ companheiras ou parceiros/ companheiros, apresenta vínculos familiares fragilizados e/ou rompidos. "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            ),
                            _c("div", {
                              staticClass:
                                "border-ashes-hover border-b-2 w-full",
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.psychosocial_accompaniment.meeting_happened
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseTextArea", {
                              attrs: {
                                active: "",
                                inputName: "socio_economic_context",
                                labelText:
                                  "Contexto familiar, socioeconômico e comunitário",
                                showLabel: "",
                                rows: "4",
                                value:
                                  _vm.psychosocial_accompaniment
                                    .social_family_context,
                                dispatch:
                                  "PsychosocialAccompaniments/setSocialFamilyContext",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                  _c("HousingSituation"),
                  _c("DisciplinarySanctions"),
                  _c("SchoolSituation"),
                  _c("GenderIdentity"),
                  _c("HealthSituation"),
                  _c("VulnerabilitySituation", {
                    attrs: {
                      psychosocial_accompaniment:
                        _vm.psychosocial_accompaniment,
                    },
                  }),
                  _c("ForwardingDetails", {
                    attrs: {
                      psychosocial_accompaniment:
                        _vm.psychosocial_accompaniment,
                    },
                  }),
                  _c("AccompanimentPlan", {
                    attrs: {
                      psychosocial_accompaniment:
                        _vm.psychosocial_accompaniment,
                    },
                  }),
                  _c("MeetingConclusion", {
                    attrs: {
                      psychosocial_accompaniment:
                        _vm.psychosocial_accompaniment,
                    },
                  }),
                  _c("ModalMatrixRiskStratifications", {
                    attrs: { open: _vm.modalToggleMatrixRiskStratifications },
                  }),
                  _c("SavePsychosocialAccompanimentWithoutMatrixConfirmation", {
                    attrs: {
                      open: _vm.modalConfirmSavePsychosocialAccompanimentWithoutMatrix,
                    },
                  }),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("ModalFooterActions", {
                    attrs: {
                      closeDispatch:
                        "PsychosocialAccompaniments/closeEditModalPsychosocialHomeVisit",
                      updateDispatch: "PsychosocialAccompaniments/update",
                      saveDispatch: "PsychosocialAccompaniments/save",
                    },
                  }),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2854543772
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }