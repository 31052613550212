<template>
  <BaseModal width="w-264" v-if="open">
    <template v-slot:header>
      <div class="grid grid-cols-2">
        <div class="justify-self-start">
          <Label
            v-if="facility.id"
            text="Editar Equipamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
          <Label
            v-else
            text="Criar Equipamento"
            :icon="null"
            :icon-type="null"
            type="text-2xl"
            weight="bold"
            normal
            mode="normal-case"
            class="text-ashes-dark"
          />
        </div>
        <div class="justify-self-end">
          <AppButtonIcon dispatch="Facilities/closeModalFacilities" forClose />
        </div>
      </div>
    </template>
    <template v-slot:body>
      <div class="grid grid-cols-2">
        <div class="col-span-2 text-burnedYellow pb-2">
          <Label
            text="Informações Gerais"
            :icon="null"
            :icon-type="null"
            type="text-sm"
            weight="semibold"
            normal
            mode="uppercase"
          />
          <div class="border-ashes-hover border-b-2 w-full"></div>
        </div>
      </div>
      <div class="grid grid-cols-2 gap-4 mt-2">
        <div>
          <BaseInput
            id="Nome"
            input-name="name"
            input-type="text"
            show-label
            required
            label-text="Nome"
            :value="facility.name"
            dispatch="Facilities/setName"
          />
        </div>
        <div>
          <Multiselect
            id="select-forwarding-policy"
            select-placeholder="Tipo de Política"
            label-text="Tipo de Política"
            close-on-select
            show-label
            required
            :value="forwarding_policy"
            :options="forwarding_policies"
            :dispatch="setForwardingPolicy"
            :multiple-option="false"
            class="mr-4"
          />
        </div>
        <div class="col-span-2">
          <BaseInput
            id="address"
            input-name="address"
            input-type="text"
            show-label
            required
            label-text="Endereço"
            :value="facility.address"
            dispatch="Facilities/setAddress"
          />
        </div>
      </div>
    </template>
    <template v-slot:footer>
      <div class="grid grid-cols-2 col-auto">
        <div class="justify-self-start">
          <AppButtonEmpty text="descartar" typeButton="danger" dispatch="Facilities/closeModalFacilities" />
        </div>
        <div class="justify-self-end">
          <AppButtonEmpty
            v-if="facility.id"
            text="Atualizar"
            typeButton="success"
            dispatch="Facilities/update"
          />
          <AppButtonEmpty v-else text="Criar" typeButton="success" dispatch="Facilities/save" />
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>
import BaseModal from "../components/BaseModal.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import Label from "../components/Label.vue";
import AppButtonIcon from "../components/AppButtonIcon.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    BaseModal,
    BaseInput,
    Label,
    AppButtonIcon,
    AppButtonEmpty,
    Multiselect,
  },

  props: {
    open: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    this.$store.dispatch("Facilities/loadForwardingPolicies");
  },
  computed: {
    ...mapState({
      facility: (state) => state.Facilities.facility,
      forwarding_policies: (state) => state.Facilities.forwarding_policies,
      forwarding_policy: (state) => state.Facilities.forwarding_policy,
    }),
  },

  methods: {
    ...mapActions({
      setForwardingPolicy: "Facilities/setForwardingPolicy",
    }),
  },
};
</script>
