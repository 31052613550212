var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-4" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start col-span-3" },
                      [
                        _vm.vacancy_request.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar Solicitação de Vaga",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Adicionar Solicitação de Vaga",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "VacancyRequests/closeModalAddVacancyRequest",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Dados cadastrais da Solicitação de Vaga",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "label-text": "Nome do Participante",
                            "show-label": "",
                            "clear-on-select": "",
                            required: "",
                            "select-placeholder":
                              "Digite o nome do participante para busca",
                            "multiple-option": false,
                            value: _vm.inscription,
                            options: _vm.inscriptionsForGeneralMenus,
                            dispatch: _vm.setInscription,
                            "load-dispatch":
                              "VacancyRequests/loadInscriptionsForGeneralMenus",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Tipo da Vaga Solicitada",
                            "label-text": "Tipo da Vaga Solicitada",
                            "multiple-option": false,
                            value: _vm.vacancy_type,
                            options: _vm.vacancy_types,
                            dispatch: _vm.setVacancyType,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Município",
                            "multiple-option": false,
                            value: _vm.city,
                            options: _vm.cities,
                            "label-text": "Município",
                            dispatch: _vm.setCity,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "articulator",
                            "input-type": "text",
                            value: _vm.vacancy_request.articulator,
                            "show-label": "",
                            "label-text": "Articulador(a) Responsável",
                            dispatch: "VacancyRequests/setArticulator",
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.vacancy_type?.id === 0
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                mask: "###.###.###-##",
                                "input-name": "cpf_responsible_young_person",
                                "input-type": "text",
                                value:
                                  _vm.vacancy_request
                                    .cpf_responsible_young_person,
                                "show-label": "",
                                "label-text":
                                  "CPF da Mãe ou Responsável do Jovem",
                                dispatch:
                                  "VacancyRequests/setCpfResponsibleYoungPerson",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 0
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name":
                                  "address_responsible_young_person",
                                "input-type": "text",
                                value:
                                  _vm.vacancy_request
                                    .address_responsible_young_person,
                                "show-label": "",
                                "label-text":
                                  "Endereço da Mãe ou Responsável do Jovem",
                                dispatch:
                                  "VacancyRequests/setAddressResponsibleYoungPerson",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 0
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "updated_contact_young_person",
                                "input-type": "text",
                                value:
                                  _vm.vacancy_request
                                    .updated_contact_young_person,
                                "show-label": "",
                                mask: "(##) #####.####",
                                "label-text": "Contato Atualizado do Jovem",
                                dispatch:
                                  "VacancyRequests/setUpdatedContactYoungPerson",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 0
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Escola de Interesse",
                                "label-text": "Escola de Interesse",
                                "multiple-option": false,
                                value: _vm.facility,
                                options: _vm.facilities,
                                dispatch: _vm.setFacility,
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 0
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Turno de Interesse",
                                "label-text": "Turno de Interesse",
                                "multiple-option": false,
                                value: _vm.interest_shift,
                                options: _vm.interest_shifts,
                                dispatch: _vm.setInterestShift,
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 0
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Série/ano de interesse",
                                "label-text": "Série/ano de interesse",
                                "multiple-option": false,
                                value: _vm.interest_serie,
                                options: _vm.interest_series,
                                dispatch: _vm.setInterestSerie,
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 0
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder":
                                  "Rede a que Pertence a Escola",
                                "label-text": "Rede a que Pertence a Escola",
                                "multiple-option": false,
                                value: _vm.school_network,
                                options: _vm.school_networks,
                                dispatch: _vm.setSchoolNetwork,
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 0
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseTextArea", {
                              attrs: {
                                active: "",
                                inputName: "case_description",
                                labelText: "Descrição do Caso",
                                showLabel: "",
                                rows: "4",
                                value: _vm.vacancy_request.case_description,
                                dispatch: "VacancyRequests/setCaseDescription",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 0
                      ? _c(
                          "div",
                          [
                            _c("BaseTextArea", {
                              attrs: {
                                active: "",
                                inputName: "interventions_performed",
                                labelText: "Intervenções já Realizadas",
                                showLabel: "",
                                rows: "4",
                                value:
                                  _vm.vacancy_request.interventions_performed,
                                dispatch:
                                  "VacancyRequests/setInterventionsPerformed",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 0
                      ? _c(
                          "div",
                          [
                            _c("BaseTextArea", {
                              attrs: {
                                active: "",
                                inputName: "interventions_performed",
                                labelText: "Tipo de Encaminhamento",
                                showLabel: "",
                                rows: "4",
                                value: _vm.vacancy_request.forwarding_type,
                                dispatch: "VacancyRequests/setForwardingType",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 1
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "full_name_responsible_child",
                                "input-type": "text",
                                value:
                                  _vm.vacancy_request
                                    .full_name_responsible_child,
                                "show-label": "",
                                "label-text":
                                  "Nome Completo do Responsável pela Criança",
                                dispatch:
                                  "VacancyRequests/setFullNameResponsibleChild",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 1
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "child_full_name",
                                "input-type": "text",
                                value: _vm.vacancy_request.child_full_name,
                                "show-label": "",
                                "label-text": "Nome Completo da Criança",
                                dispatch: "VacancyRequests/setChildFullName",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 1
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                mask: "###.###.###-##",
                                "input-name": "cpf_responsible_child",
                                "input-type": "text",
                                value:
                                  _vm.vacancy_request.cpf_responsible_child,
                                "show-label": "",
                                "label-text":
                                  "CPF da mãe ou Responsável pela Criança",
                                dispatch:
                                  "VacancyRequests/setCpfResponsibleChild",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 1
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "child_birthdate",
                                "input-type": "date",
                                value: _vm.vacancy_request.child_birthdate,
                                "show-label": "",
                                "label-text": "Data de Nascimento da Criança",
                                dispatch: "VacancyRequests/setChildBirthdate",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 1
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "address_responsible_child",
                                "input-type": "text",
                                value:
                                  _vm.vacancy_request.address_responsible_child,
                                "show-label": "",
                                "label-text":
                                  "Endereço do Responsável pela Criança",
                                dispatch:
                                  "VacancyRequests/setAddressResponsibleChild",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 1
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "contact_responsible_child",
                                "input-type": "text",
                                value:
                                  _vm.vacancy_request.contact_responsible_child,
                                "show-label": "",
                                mask: "(##) #####.####",
                                "label-text":
                                  "Contato do Responsável pela Criança",
                                dispatch:
                                  "VacancyRequests/setContactResponsibleChild",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_type?.id === 1
                      ? _c(
                          "div",
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "interest_kindergarten",
                                "input-type": "text",
                                value:
                                  _vm.vacancy_request.interest_kindergarten,
                                "show-label": "",
                                "label-text":
                                  "Creche de Interesse que já foi Articulada",
                                dispatch:
                                  "VacancyRequests/setInterestKindergarten",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_request.id
                      ? _c(
                          "div",
                          [
                            _c("AppRadioButton", {
                              attrs: {
                                values: [
                                  {
                                    name: "vacancy_request_accepted_request",
                                    value: true,
                                    description: "Sim",
                                    checked:
                                      _vm.vacancy_request.accepted_request ===
                                      true,
                                  },
                                  {
                                    name: "vacancy_request_accepted_request",
                                    value: false,
                                    description: "Não",
                                    checked:
                                      _vm.vacancy_request.accepted_request ===
                                      false,
                                  },
                                ],
                                labelText: "Solicitação já foi atendida?",
                                showLabel: "",
                                dispatch: "VacancyRequests/setAcceptedRequest",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.vacancy_request.id
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseTextArea", {
                              attrs: {
                                active: "",
                                inputName: "feedback",
                                labelText: "Devolutiva",
                                showLabel: "",
                                rows: "5",
                                value: _vm.vacancy_request.feedback,
                                dispatch: "VacancyRequests/setFeedback",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "VacancyRequests/closeModalAddVacancyRequest",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.vacancy_request.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Salvar",
                                typeButton: "success",
                                dispatch: "VacancyRequests/update",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "criar",
                                typeButton: "success",
                                dispatch: "VacancyRequests/save",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          553949296
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }