<template>
  <div>
    <div class="grid grid-cols-custom items-center -mx-12">
      <div class="mt-10 ml-6">
        <BaseText
          text="Status"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Nome do Jovem"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Edição"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="CPF"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Área"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="mt-10 ml-1">
        <BaseText
          text="Equipamento"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="xs"
          color="ashes-dark"
        />
      </div>
      <div class="flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul" class="-mx-12">
      <li :key="inscription.id" :data-index="index" v-for="(inscription, index) in data">
        <div>
          <AppCardPsychosocialAccompanimentPendingReception :inscription="inscription" />
        </div>
        <div class="border-ashes w-full border ml-5"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardPsychosocialAccompanimentPendingReception from "../../components/AppCardPsychosocialAccompanimentPendingReception.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";

export default {
  components: {
    AppCardPsychosocialAccompanimentPendingReception,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 12% 27% 9% 15% 12% 5%;
}
</style>
