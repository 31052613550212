// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/background-young.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/img-rodape.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, "#description[data-v-73f42477] {\n  width: 100%;\n  max-width: 600px;\n}\n.flex[data-v-73f42477] .flex {\n  display: flex;\n  flex-wrap: wrap;\n}\n.img-logo[data-v-73f42477] {\n  max-width: 100%;\n  min-width: 200px;\n}\n.main[data-v-73f42477] {\n  background-color: #3ba543;\n  background-image: linear-gradient(45deg, #3ba543 49%, #69c968 70%);\n  width: 100%;\n  min-height: 100vh;\n  position: relative;\n}\n.img-back[data-v-73f42477] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-repeat: no-repeat;\n  background-position: bottom right;\n  background-size: contain;\n}\n.bot[data-v-73f42477] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  position: fixed;\n  bottom: 0;\n  left: 0;\n  width: 100%;\n  height: 55px;\n  padding: 10px 0;\n  background-size: cover;\n  background-repeat: no-repeat;\n  background-position: center;\n  z-index: 10;\n}\r\n", ""]);
// Exports
module.exports = exports;
