var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-custom items-center -mx-12" }, [
        _c(
          "div",
          { staticClass: "mt-10 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Última atualização",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-10 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "Situação",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-10 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "Impacto",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "flex" }),
      ]),
      _c(
        "BaseStaggeredFade",
        { staticClass: "-mx-12", attrs: { duration: 10, tag: "ul" } },
        _vm._l(
          _vm.data,
          function (matrix_risk_stratification_by_inscription, index) {
            return _c(
              "li",
              {
                key: matrix_risk_stratification_by_inscription.token_group,
                attrs: { "data-index": index },
              },
              [
                _c(
                  "div",
                  [
                    _c("AppCardMatrixRiskStratificationByInscription", {
                      attrs: {
                        matrix_risk_stratification_by_inscription:
                          matrix_risk_stratification_by_inscription,
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "border-ashes w-full border ml-5" }),
              ]
            )
          }
        ),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }