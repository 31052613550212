var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { display: "contents" } },
    _vm._l(_vm.dataColumns, function (datacolumn) {
      return _c(
        "tr",
        { key: datacolumn.id },
        _vm._l(_vm.headerColumns, function (headerColumn) {
          return _c(
            "td",
            {
              key: `${datacolumn.id}-${headerColumn.key}`,
              staticClass:
                "py-3 border-b-2 border-solid border-gray-100 align-middle",
            },
            [
              _c(
                "div",
                { staticClass: "flex justify-left items-center" },
                _vm._l(
                  _vm.filterDataByHeaderColumnKey(datacolumn, headerColumn.key),
                  function (item, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "mr-3" },
                      [
                        item.isImage
                          ? _c("img", {
                              staticClass: "inline rounded-lg h-12 w-12",
                              attrs: {
                                src: require(`../assets/images/profile-vj.png`),
                              },
                            })
                          : _vm._e(),
                        item.isLabel
                          ? _c("Label", {
                              attrs: { text: item.text, weight: item.weight },
                            })
                          : _vm._e(),
                        item.isButton && item.label
                          ? _c("AppButtonColorful", {
                              class: item.classCss,
                              attrs: {
                                text: item.label,
                                "type-text": item.typeText,
                                "type-button": item.styleButton,
                                "show-icon": item.showIcon,
                                "icon-name": item.iconName,
                                "icon-type": item.iconType,
                                payload: item.payload,
                                dispatch: item.dispatch,
                                showButton: item.showButton,
                                abbr: item.abbr,
                              },
                            })
                          : _vm._e(),
                        item.isButtonIcon
                          ? _c("AppButtonIconStudent", {
                              attrs: { "icon-name": item.iconName },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }
                ),
                0
              ),
            ]
          )
        }),
        0
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }