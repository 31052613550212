var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    { staticClass: "font-system" },
    [
      _c(
        "table",
        { staticClass: "table-auto w-full text-ashes-dark border-collapse" },
        [
          _c(
            "thead",
            [
              _c("BaseTableHeaderCell", {
                attrs: { "header-columns": _vm.header },
              }),
            ],
            1
          ),
          _c(
            "tbody",
            [
              _c("BaseTableDataCellStudent", {
                attrs: {
                  "data-columns": _vm.body,
                  "header-columns": _vm.header,
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c("BasePagination", {
        attrs: {
          total: _vm.total,
          "total-per-page": _vm.totalPerPage,
          dispatch: _vm.dispatch,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }