import { getAreasByCity, destroyArea } from "../../service/AreaApi";
import Vue from "vue";
import router from "../../router/index";

const state = {
  areas: [],
  filterAreas: [],
  modalDeleteAreaConfirmationOpen: false,
  areaIdForDeletion: null,
};

const mutations = {
  setAreas: (state, areas) => {
    state.areas = areas;
    state.filterAreas = areas;
  },
  filterList: (state, input) => {
    let areas = state.areas;
    state.filterAreas = areas.filter((obj) => {
      return obj.name.toLowerCase().indexOf(input.value.toLowerCase()) > -1;
    });
  },
  toggleModalDeleteAreaConfirmation: (state) => {
    state.modalDeleteAreaConfirmationOpen = !state.modalDeleteAreaConfirmationOpen;
  },
  areaForDeletion: (state, area) => {
    state.areaIdForDeletion = area;
  },
  addArea: (state, area) => {
    state.areas.push(area);
  },
};

const actions = {
  filterList: ({ commit }, payload) => {
    commit("filterList", payload);
  },
  loadAreas: ({ commit, rootState }) => {
    let id = rootState.City.city.id;
    getAreasByCity(id)
      .then((response) => {
        commit("setAreas", response.data.areas);
      })
      .catch((e) => {
        console.log("error", e.response); //TODO: tratar esse error
      });
  },
  setAreas: ({ commit }, payload) => {
    commit("setAreas", payload);
  },
  addArea: ({ commit }, payload) => {
    commit("addArea", payload);
  },
  openCloseDeleteAreaConfirmation: ({ commit, state }, payload) => {
    commit("toggleModalDeleteAreaConfirmation");
    if (state.modalDeleteAreaConfirmationOpen) {
      commit("areaForDeletion", payload.id);
    } else {
      commit("areaForDeletion", null);
    }
  },
  deleteArea: ({ commit, state, dispatch, rootState }) => {
    const area_id = state.areaIdForDeletion;

    destroyArea(area_id)
      .then((response) => {
        const areas = state.areas.filter((e) => {
          return e.id !== area_id;
        });
        dispatch("setAreas", areas);
        Vue.$toast.success(response.data.success);
        commit("toggleModalDeleteAreaConfirmation");
        dispatch("Neighborhoods/loadNeighborhoodsWithoutArea", null, { root: true });
        if (rootState.Area.insideArea.id) router.push({ name: "areas" });
      })
      .catch((e) => {
        Vue.$toast.error(e.response.data.error);
      });
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
