<template>
  <div class="font-system" v-if="permitList">
    <div id="title-page" class="mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl">{{ city.name }} | Equipamentos</div>
    </div>
    <div id="tabs-button" class="flex justify-between mx-12 my-6">
      <div class="flex"></div>
      <div class="float-right">
        <AppButtonColorful
          showIcon
          iconName="add"
          text="Adicionar Equipamento"
          dispatch="Equipment/openModalEquipment"
          typeButton="primary"
          v-if="permit"
        />
      </div>
    </div>
    <div id="list-equipment">
      <div id="filter" class="mx-12 my-6 flex items-center">
        <div class="text-ashes-dark font-medium text-sm mr-4">FILTRAR</div>
        <div class="w-52 mr-4">
          <Multiselect
            id="filter-area"
            select-placeholder="Área..."
            close-on-select
            :multiple-option="false"
            :value="filteredArea"
            :options="areas"
            :dispatch="setAreas"
          />
        </div>
        <div class="w-52 mr-4">
          <Multiselect
            id="filter-neighborhood"
            select-placeholder="Bairro..."
            close-on-select
            :multiple-option="false"
            :value="filteredNeighborhood"
            :options="neighborhoods"
            :dispatch="setNeighborhoods"
          />
        </div>
        <div>
          <a href="javascript:void(0)" @click="clearFilters()">
            <img
              class="inline-block"
              src="../assets/images/filter_alt_black_24dp.svg"
              alt=""
              style="cursor: pointer"
            />
            <BaseText
              text="Limpar"
              typeText="custom"
              weight="normal"
              :italic="false"
              size="xs"
              color="burnedYellow"
            />
          </a>
        </div>
      </div>

      <div class="mx-12">
        <EquipmentCards :data="equipment" />
      </div>
      <ModalEquipment :open="openModalEquipment" />
      <DeleteEquipmentConfirmation :open="openModalDelete" />
    </div>
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import EquipmentCards from "./list/EquipmentCards.vue";
import ModalEquipment from "./ModalEquipment.vue";
import DeleteEquipmentConfirmation from "./alerts/DeleteEquipmentConfirmation.vue";
import Multiselect from "../components/forms/Multiselect.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseText from "../components/BaseText.vue";
import ProhibitedAccessPage from "../components/ProhibitedAccessPage.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    Multiselect,
    AppButtonColorful,
    EquipmentCards,
    ModalEquipment,
    DeleteEquipmentConfirmation,
    BaseText,
    ProhibitedAccessPage,
  },

  data() {
    return {
      permitList: false,
      permit: false,
    };
  },

  created() {
    this.$store.dispatch("Areas/loadAreas");
    this.$store.dispatch("Neighborhoods/loadNeighborhoods");
    this.$store.dispatch("EquipmentAll/loadEquipment");
    this.$store.dispatch("Equipment/clearEquipment");
    this.permitChangeEquip();
    this.permitListAll();
  },

  computed: {
    ...mapState({
      areas: (state) => state.Areas.areas,
      neighborhoods: (state) => state.Neighborhoods.neighborhoods,
      equipment: (state) => state.EquipmentAll.filteredEquipmentAll,
      filteredArea: (state) => state.EquipmentAll.filters.area,
      filteredNeighborhood: (state) => state.EquipmentAll.filters.neighborhood,
      openModalDelete: (state) => state.Equipment.modalDeleteEquipmentConfirmationOpen,
      openModalEquipment: (state) => state.Equipment.modalEquipmentOpen,
      city: (state) => state.City.city,
    }),
  },
  methods: {
    ...mapActions({
      setNeighborhoods: "EquipmentAll/filterNeighborhood",
      setAreas: "EquipmentAll/filterArea",
      canAcces: "AccessControl/canAcces",
    }),
    permitListAll() {
      this.canAcces("equipment_index").then((resp) => {
        this.permitList = resp;
      });
    },
    permitChangeEquip() {
      this.canAcces("equipment_create").then((resp) => {
        this.permit = resp;
      });
    },
    clearFilters() {
      this.$store.dispatch("EquipmentAll/clearFilters");
    },
  },
};
</script>
