var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permit
    ? _c(
        "div",
        { staticClass: "font-system" },
        [
          _c(
            "div",
            {
              staticClass:
                "grid grid-cols-3 justify-between items-center mx-12 my-6",
              attrs: { id: "tabs-button" },
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "text-ashes-hover font-medium text-3xl mx-2 col-span-2",
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.city.name) +
                      " | Psicossocial - Atendimento Individual "
                  ),
                ]
              ),
              _vm.inscriptions.length > 0
                ? _c(
                    "div",
                    {
                      staticClass:
                        "text-ashes-hover font-medium text-xl mx-2 col-span-2",
                    },
                    [_vm._v(" " + _vm._s(_vm.getTotalRegisters) + " ")]
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              staticClass:
                "grid grid-cols-3 justify-between p-4 items-end mx-12 my-6 gap-4",
              attrs: { id: "tabs-button" },
            },
            [
              _c(
                "div",
                { staticClass: "w-52" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Selecionar edição",
                      "multiple-option": false,
                      value: _vm.season,
                      options: _vm.seasons,
                      "label-text": "Edição",
                      dispatch: _vm.setSeason,
                      required: "",
                    },
                  }),
                ],
                1
              ),
            ]
          ),
          _vm.season
            ? _c(
                "div",
                {
                  staticClass: "mx-16 flex",
                  attrs: { id: "menus_psychosocial_main_page" },
                },
                [
                  _c("div", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.changeTab("inAccompaniment")
                          },
                        },
                      },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "Acolhidos",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "sm",
                            color: _vm.setColorInAccompaniment,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "border-l mx-10" }),
                  _c("div", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.changeTab("pendingReception")
                          },
                        },
                      },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "Acolhimentos pendentes",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "sm",
                            color: _vm.setColorPendingReception,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _c("div", { staticClass: "border-l mx-10" }),
                  _c("div", [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.changeTab("unaccompanied")
                          },
                        },
                      },
                      [
                        _c("BaseText", {
                          attrs: {
                            text: "Não acolhidos",
                            typeText: "custom",
                            weight: "semibold",
                            mode: "uppercase",
                            size: "sm",
                            color: _vm.setColorUnaccompanied,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _c("ModalMatrixRiskStratifications", {
            attrs: { open: _vm.modalToggleMatrixRiskStratifications },
          }),
          _vm.season
            ? _c("div", { attrs: { id: "list-psychosocials" } }, [
                _c(
                  "div",
                  { staticClass: "mx-14" },
                  [
                    _c("PsychosocialAccompanimentPendingReception", {
                      attrs: {
                        data: _vm.inscriptions,
                        open: _vm.togglePsychosocialAccompanimentPendingReception,
                      },
                    }),
                    _c("PsychosocialAccompanimentUnaccompanied", {
                      attrs: {
                        data: _vm.inscriptions,
                        open: _vm.togglePsychosocialAccompanimentUnaccompanied,
                      },
                    }),
                    _c("PsychosocialAccompanimentInAccompaniment", {
                      attrs: {
                        data: _vm.inscriptions,
                        open: _vm.togglePsychosocialAccompanimentInAccompaniment,
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ],
        1
      )
    : _c("ProhibitedAccessPage", {
        attrs: {
          classText:
            "grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2",
        },
      })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }