var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.psychosocial_accompaniment?.meeting_happened
    ? _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
        _c(
          "div",
          { staticClass: "col-span-2 text-burnedYellow pb-2" },
          [
            _c("Label", {
              attrs: {
                text: "Identidade de Gênero e Orientação Sexual",
                icon: null,
                "icon-type": null,
                type: "text-sm",
                weight: "semibold",
                normal: "",
                mode: "uppercase",
              },
            }),
            _c("div", { staticClass: "border-ashes-hover border-b-2 w-full" }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("Multiselect", {
              attrs: {
                "close-on-select": true,
                "show-label": "",
                "clear-on-select": "",
                "select-placeholder": "Gênero",
                "label-text": "Gênero",
                "multiple-option": false,
                value: _vm.gender,
                options: _vm.genders,
                dispatch: _vm.setGender,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _c("Multiselect", {
              attrs: {
                "close-on-select": true,
                "show-label": "",
                "clear-on-select": "",
                "select-placeholder": "Se identifica como LGBTQIAPN+",
                "label-text": "Se identifica como LGBTQIAPN+",
                "multiple-option": false,
                value: _vm.identifies_as_lgbtqiapn,
                options: _vm.identifies_as_lgbtqiapns,
                dispatch: _vm.setidentifiesAsLgbtqiapn,
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("BaseTextArea", {
              attrs: {
                active: "",
                inputName: "gender_identity_observations",
                labelText: "Observações",
                showLabel: "",
                rows: "4",
                value:
                  _vm.psychosocial_accompaniment?.gender_identity_observations,
                dispatch:
                  "PsychosocialAccompaniments/setGenderIdentityObservations",
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }