var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.cardCity },
    [
      _c("BaseText", {
        attrs: {
          text: _vm.name,
          typeText: "custom",
          weight: "normal",
          italic: false,
          mode: "capitalize",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }