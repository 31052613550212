var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.psychosocial_accompaniment?.meeting_happened
    ? _c(
        "div",
        { staticClass: "grid grid-cols-2 gap-4 mt-2" },
        [
          _c(
            "div",
            { staticClass: "col-span-2 text-burnedYellow pb-2" },
            [
              _c("Label", {
                attrs: {
                  text: "Situação de Saúde",
                  icon: null,
                  "icon-type": null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
              _c("div", {
                staticClass: "border-ashes-hover border-b-2 w-full",
              }),
            ],
            1
          ),
          _vm._l(_vm.healthRadios, function (radio, index) {
            return _c(
              "div",
              { key: index, staticClass: "col-span-1" },
              [
                _c("AppRadioButton", {
                  attrs: {
                    values: radio.values,
                    labelText: radio.labelText,
                    showLabel: "",
                    dispatch: radio.dispatch,
                  },
                }),
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "col-span-1" },
            [
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "gestational_age",
                  "input-type": "number",
                  value: _vm.psychosocial_accompaniment?.gestational_age,
                  "show-label": "",
                  "label-text": "Idade Gestacional",
                  dispatch: "PsychosocialAccompaniments/setGestationalAge",
                  inputMin: "0",
                  readonly: !_vm.psychosocial_accompaniment?.is_pregnant,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "health_place_frequently",
                  "input-type": "text",
                  value:
                    _vm.psychosocial_accompaniment?.health_place_frequently,
                  "show-label": "",
                  "label-text": "Local de atendimento",
                  dispatch:
                    "PsychosocialAccompaniments/setHealthPlaceFrequently",
                  readonly:
                    !_vm.psychosocial_accompaniment
                      ?.frequently_uses_health_network,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("BaseTextArea", {
                attrs: {
                  active: "",
                  inputName: "motivation_uses_health_frequently",
                  labelText: "Motivo do atendimento",
                  showLabel: "",
                  rows: "4",
                  value:
                    _vm.psychosocial_accompaniment
                      ?.motivation_uses_health_frequently,
                  dispatch:
                    "PsychosocialAccompaniments/setMotivationUsesHealthFrquently",
                  readonly:
                    !_vm.psychosocial_accompaniment
                      ?.frequently_uses_health_network,
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2 mt-0" },
            [
              _c(
                "div",
                { staticClass: "flex" },
                [
                  _c("BaseLabel", {
                    staticClass: "flex items-end",
                    attrs: { text: "Observações" },
                  }),
                  _c(
                    "button",
                    {
                      ref: "btnHealthSituationObservationsRef",
                      staticClass:
                        "uppercase rounded outline-none ease-linear transition-all duration-150",
                      on: {
                        mouseenter: function ($event) {
                          return _vm.toggleTooltip(
                            "btnHealthSituationObservationsRef"
                          )
                        },
                        mouseleave: function ($event) {
                          return _vm.toggleTooltip(
                            "btnHealthSituationObservationsRef"
                          )
                        },
                      },
                    },
                    [_vm._m(0)]
                  ),
                ],
                1
              ),
              _c(
                "div",
                {
                  ref: "tooltipHealthSituationObservationsRef",
                  staticClass:
                    "bg-burnedYellow border-0 mr-3 block z-50 font-normal leading-normal text-sm max-w-xs text-left no-underline break-words rounded-lg",
                  class: {
                    hidden: !_vm.tooltipHealthSituationObservations,
                    block: _vm.tooltipHealthSituationObservations,
                  },
                },
                [_vm._m(1)]
              ),
              _c("BaseTextArea", {
                attrs: {
                  active: "",
                  inputName: "health_situation_observations",
                  showLabel: "",
                  rows: "4",
                  value:
                    _vm.psychosocial_accompaniment
                      ?.health_situation_observations,
                  dispatch:
                    "PsychosocialAccompaniments/setHealthSituationObservations",
                },
              }),
            ],
            1
          ),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "span",
      { staticClass: "material-icons mt-6 text-burnedYellow" },
      [_c("span", { staticClass: "material-icons" }, [_vm._v("info")])]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c(
        "div",
        {
          staticClass:
            "text-white opacity-75 font-semibold p-3 mb-0 border-b border-solid border-blueGray-100 uppercase rounded-t-lg",
        },
        [
          _vm._v(
            " Utilizar este espaço para detalhar a situação de saúde apreendida, se faz tratamento, uso de medicações, etc. "
          ),
        ]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }