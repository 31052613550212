var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open && _vm.season
    ? _c(
        "div",
        { staticClass: "mt-2" },
        [
          _c(
            "div",
            { staticClass: "mx-4", attrs: { id: "tabs-button" } },
            [
              _c("FilterComponent", [
                _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("Multiselect", {
                      attrs: {
                        "close-on-select": true,
                        "show-label": "",
                        "clear-on-select": "",
                        "select-placeholder": "Município",
                        "multiple-option": false,
                        value: _vm.city,
                        options: _vm.cities,
                        "label-text": "Filtrar por Município",
                        dispatch: _vm.setFilterCity,
                        required: "",
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("Multiselect", {
                      attrs: {
                        "close-on-select": true,
                        "show-label": "",
                        "label-text": "Tipo da Vaga Solicitada",
                        "clear-on-select": "",
                        "select-placeholder": "Tipo da Vaga Solicitada",
                        "multiple-option": false,
                        value: _vm.vacancy_type_filtered,
                        options: _vm.vacancy_types,
                        dispatch: _vm.setFilterVacancyType,
                      },
                    }),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col-span-2" },
                  [
                    _c("BaseInput", {
                      attrs: {
                        active: "",
                        "input-name": "filter_by_articulator",
                        "input-type": "text",
                        value: _vm.articulator_filtered,
                        "show-label": "",
                        "label-text": "Articulador Responsável",
                        dispatch: "VacancyRequests/setFilterArticulator",
                      },
                    }),
                  ],
                  1
                ),
              ]),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "grid grid-cols-2 items-center mx-6 my-6",
              attrs: { id: "tabs-button" },
            },
            [
              _c(
                "div",
                [
                  _vm.vacancy_requests.length > 0 && _vm.permitExportSpreadsheet
                    ? _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.exportToExcel()
                            },
                          },
                        },
                        [
                          _c("img", {
                            staticClass: "inline-block",
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              src: require("../../assets/images/logout_black_24dp.svg"),
                              alt: "",
                            },
                          }),
                          _c("BaseText", {
                            staticStyle: { cursor: "pointer" },
                            attrs: {
                              text: "Exportar planilha",
                              typeText: "custom",
                              italic: false,
                              size: "xs",
                              color: "burnedYellow",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm.vacancy_requests_spreadsheet.length > 0 &&
                  _vm.modalToggleExportVacancyRequestsSpreadsheet
                    ? _c("BaseModal", {
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "header",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "grid grid-cols-3" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "justify-self-start col-span-2",
                                        },
                                        [
                                          _c("Label", {
                                            staticClass: "text-ashes-dark",
                                            attrs: {
                                              text: "Exportar Planilha de Solicitações de Vagas",
                                              icon: null,
                                              "icon-type": null,
                                              type: "text-2xl",
                                              weight: "bold",
                                              normal: "",
                                              mode: "normal-case",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "justify-self-end" },
                                        [
                                          _c("AppButtonIcon", {
                                            attrs: {
                                              dispatch:
                                                "VacancyRequests/closeExportVacancyRequestsSpreadsheetModal",
                                              forClose: "",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            {
                              key: "footer",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "grid grid-rows-2" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "grid justify-center items-center",
                                        },
                                        [
                                          _c("Label", {
                                            staticClass: "text-ashes-dark",
                                            attrs: {
                                              text: "Planilha gerada com sucesso!",
                                              icon: null,
                                              "icon-type": null,
                                              type: "text-2x1",
                                              weight: "normal",
                                              normal: "",
                                              mode: "normal-case",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "grid grid-cols-2" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "justify-self-start",
                                            },
                                            [
                                              _c("AppButtonEmpty", {
                                                attrs: {
                                                  text: "descartar",
                                                  typeButton: "danger",
                                                  dispatch:
                                                    "VacancyRequests/closeExportSchoolReinsertionsEvolutionSpreadsheetModal",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "justify-self-end" },
                                            [
                                              _c(
                                                "download-excel",
                                                {
                                                  attrs: {
                                                    type: "xls",
                                                    meta: _vm.json_meta,
                                                    fields: _vm.json_fields,
                                                    data: _vm.json_data_vacancy_request,
                                                    "before-finish":
                                                      _vm.closeExportVacancyRequestsSpreadsheetModal,
                                                    worksheet:
                                                      "Solicitacoes_vagas",
                                                    name: "solicitacoes_vagas_por_inscricao.xls",
                                                    escapeCsv: false,
                                                  },
                                                },
                                                [
                                                  _c("AppButtonEmpty", {
                                                    attrs: {
                                                      text: "Download da planilha",
                                                      typeButton: "success",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          776890468
                        ),
                      })
                    : _vm._e(),
                  !_vm.hasSpreadsheetVacancyRequestsResponse
                    ? _c("div", [_c("PreLoading")], 1)
                    : _vm._e(),
                ],
                1
              ),
              _vm.permitCreate
                ? _c(
                    "div",
                    { staticClass: "justify-self-end" },
                    [
                      _c("AppButtonColorful", {
                        attrs: {
                          text: "Solicitação de Vaga",
                          typeButton: "primary",
                          showIcon: "",
                          iconName: "add",
                          iconType: "outlined",
                          dispatch:
                            "VacancyRequests/openModalAddVacancyRequest",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "grid grid-cols-2 items-center",
              attrs: { id: "tabs-button" },
            },
            [
              _c(
                "div",
                { staticClass: "mx-2" },
                [
                  _c("AppButtonColorful", {
                    attrs: {
                      id: "btn-consult-vacancy-requests",
                      showIcon: "",
                      iconName: "search",
                      text: "Consultar dados",
                      dispatch: "VacancyRequests/loadVacancyRequests",
                      payload: "loadData",
                      typeButton: "primary",
                    },
                  }),
                ],
                1
              ),
              _vm.vacancy_requests.length > 0
                ? _c(
                    "div",
                    { staticClass: "mx-2 items-center justify-self-end" },
                    [
                      _c(
                        "a",
                        {
                          attrs: { href: "javascript:void(0)" },
                          on: {
                            click: function ($event) {
                              return _vm.clearFields()
                            },
                          },
                        },
                        [
                          _c("AppButtonColorful", {
                            attrs: {
                              id: "btn-consult-school-reinsertions",
                              showIcon: "",
                              iconName: "remove",
                              text: "Limpar consulta",
                              typeButton: "primary",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                : _vm._e(),
            ]
          ),
          _c("div", { attrs: { id: "list-school-reinsertions" } }, [
            _c(
              "div",
              { staticClass: "mx-12" },
              [
                _c("SchoolReinsertionsNotReinsertedVacancyRequestCards", {
                  attrs: { data: _vm.vacancy_requests },
                }),
              ],
              1
            ),
            _vm.vacancy_requests.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "mx-12 my-6 pb-3",
                    attrs: { id: "vacancy-request-pagination" },
                  },
                  [
                    _c("BasePagination", {
                      attrs: {
                        total: _vm.vacancyRequestsTotal,
                        "total-per-page": _vm.vacancyRequestsTotalPerPage,
                        dispatch: "VacancyRequests/setVacancyRequestsOffset",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
          ]),
          _c("ModalAddVacancyRequest", {
            attrs: { open: _vm.modalToggleAddVacancyRequest },
          }),
          _c("DeleteSchoolReinsertionNotReinsertedVacancyRequestConfirmation", {
            attrs: { open: _vm.modalToggleDeleteVacancyRequest },
          }),
          _c("ModalViewSchoolReinsertionVacancyRequestData", {
            attrs: { open: _vm.modalToggleViewVacancyRequest },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }