var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", [
    _c(
      "div",
      { staticClass: "grid grid-cols-custom items-center container1" },
      [
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.vacancy_request?.inscription?.student?.name
                  ? _vm.vacancy_request?.inscription?.student?.name
                  : "-",
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "semibold",
                size: "base",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.vacancy_request?.inscription?.student?.neighborhood
                  ?.area?.name
                  ? _vm.vacancy_request?.inscription?.student?.neighborhood
                      ?.area?.name
                  : "-",
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "semibold",
                size: "base",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.vacancy_request?.vacancy_type?.name,
                mode: "normal-case",
                color: "quantum",
                typeText: "custom",
                weight: "semibold",
                size: "base",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.vacancy_request?.accepted_request ? "Sim" : "Não",
                mode: "normal-case",
                color: "ashes-dark",
                typeText: "custom",
                weight: "semibold",
                size: "base",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 flex" },
          [
            _vm.permitShow
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "visibility",
                    dispatch: "VacancyRequests/openViewModal",
                    payload: _vm.vacancy_request,
                    title: "Visualizar Solicitação de Vaga",
                  },
                })
              : _vm._e(),
            _vm.permitUp
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "edit",
                    payload: _vm.vacancy_request,
                    dispatch: "VacancyRequests/openEditModalAddVacancyRequest",
                    title: "Editar Solicitação de Vaga",
                  },
                })
              : _vm._e(),
            _vm.permitDes
              ? _c("AppButtonIcon", {
                  attrs: {
                    iconName: "delete",
                    payload: _vm.vacancy_request,
                    dispatch: "VacancyRequests/toggleModalDeleteVacancyRequest",
                    title: "Excluir Solicitação de Vaga",
                  },
                })
              : _vm._e(),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }