var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-264" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-4" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start col-span-3" },
                      [
                        _vm.school_reinsertion.id
                          ? _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Editar Atividade",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            })
                          : _c("Label", {
                              staticClass: "text-ashes-dark",
                              attrs: {
                                text: "Adicionar Atividade",
                                icon: null,
                                "icon-type": null,
                                type: "text-2xl",
                                weight: "bold",
                                normal: "",
                                mode: "normal-case",
                              },
                            }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch:
                              "SchoolReinsertions/closeModalAddActivity",
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 gap-4 mt-2" }, [
                    _c(
                      "div",
                      { staticClass: "col-span-2 text-burnedYellow pb-2" },
                      [
                        _c("Label", {
                          attrs: {
                            text: "Dados cadastrais da atividade",
                            icon: null,
                            "icon-type": null,
                            type: "text-sm",
                            weight: "semibold",
                            normal: "",
                            mode: "uppercase",
                          },
                        }),
                        _c("div", {
                          staticClass: "border-ashes-hover border-b-2 w-full",
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Tipo da Atividade",
                            "label-text": "Tipo da Atividade",
                            "multiple-option": false,
                            value: _vm.activity_type,
                            options: _vm.activity_types,
                            dispatch: _vm.setActivityType,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("Multiselect", {
                          attrs: {
                            "close-on-select": true,
                            "show-label": "",
                            "clear-on-select": "",
                            "select-placeholder": "Município",
                            "multiple-option": false,
                            value: _vm.city,
                            options: _vm.cities,
                            "label-text": "Município",
                            dispatch: _vm.setCity,
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.activity_type?.id === 0 || _vm.activity_type?.id === 1
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Resultado da Atividade",
                                "label-text": "Resultado da Atividade",
                                "multiple-option": false,
                                value: _vm.result_type,
                                options: _vm.result_types,
                                dispatch: _vm.setResultType,
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "reinsertion_date",
                            "input-type": "date",
                            value: _vm.school_reinsertion.reinsertion_date,
                            "show-label": "",
                            "label-text": "Data da Atividade",
                            dispatch: "SchoolReinsertions/setReinsertionDate",
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.result_type?.id === 0 || _vm.activity_type?.id === 4
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder":
                                  "Motivo da Não Reinserção",
                                "label-text": "Motivo da Não Reinserção",
                                "multiple-option": false,
                                value: _vm.reasons_for_non_reinsertion,
                                options: _vm.reasons_for_non_reinsertions,
                                dispatch: _vm.setReasonsForNorReinsertion,
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseTextArea", {
                          attrs: {
                            active: "",
                            showIcon: true,
                            iconName: "help",
                            inputName: "observations",
                            labelText: "Observações",
                            tooltipMessage: _vm.tooltipObservationMessage,
                            required: "",
                            showLabel: "",
                            rows: "4",
                            value: _vm.school_reinsertion.observations,
                            dispatch: "SchoolReinsertions/setObservations",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.result_type?.id === 1 || _vm.activity_type?.id === 2
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "letter_delivery_date",
                                "input-type": "date",
                                value:
                                  _vm.school_reinsertion.letter_delivery_date,
                                "show-label": "",
                                "label-text": "Data da Entrega da Carta",
                                dispatch:
                                  "SchoolReinsertions/setLetterDeliveryDate",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.mustShowFacilityField()
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder":
                                  _vm.returnIfIsForwardingOrFacilityLabel(),
                                "label-text":
                                  _vm.returnIfIsForwardingOrFacilityLabel(),
                                "multiple-option": false,
                                value: _vm.facility,
                                options: _vm.facilities,
                                dispatch: _vm.setFacility,
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.result_type?.id === 1 || _vm.activity_type?.id === 2
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "return_letter_deadline",
                                "input-type": "date",
                                value:
                                  _vm.school_reinsertion.return_letter_deadline,
                                "show-label": "",
                                "label-text": "Data de Retorno da Carta",
                                dispatch:
                                  "SchoolReinsertions/setReturnLetterDeadline",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.result_type?.id === 2 || _vm.activity_type?.id === 3
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Turno",
                                "label-text": "Turno",
                                "multiple-option": false,
                                value: _vm.shift,
                                options: _vm.shifts,
                                dispatch: _vm.setShift,
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.result_type?.id === 2 || _vm.activity_type?.id === 3
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Ano/Série",
                                "label-text": "Ano/Série",
                                "multiple-option": false,
                                value: _vm.serie,
                                options: _vm.series,
                                dispatch: _vm.setSerie,
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.result_type?.id === 2 || _vm.activity_type?.id === 3
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder": "Modalidade de Ensino",
                                "label-text": "Modalidade de Ensino",
                                "multiple-option": false,
                                value: _vm.teaching_modality,
                                options: _vm.teaching_modalities,
                                dispatch: _vm.setTeachingModality,
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.result_type?.id === 2 || _vm.activity_type?.id === 3
                      ? _c(
                          "div",
                          [
                            _c("Multiselect", {
                              attrs: {
                                "close-on-select": true,
                                "show-label": "",
                                "clear-on-select": "",
                                "select-placeholder":
                                  "Dependência Administrativa",
                                "label-text": "Dependência Administrativa",
                                "multiple-option": false,
                                value: _vm.administrative_dependency,
                                options: _vm.administrative_dependencies,
                                dispatch: _vm.setAdministrativeDependency,
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.result_type?.id === 2 || _vm.activity_type?.id === 3
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name": "enrollment_date",
                                "input-type": "date",
                                value: _vm.school_reinsertion.enrollment_date,
                                "show-label": "",
                                "label-text": "Data da Matrícula",
                                dispatch:
                                  "SchoolReinsertions/setEnrollmentDate",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "div",
                      { staticClass: "col-span-2" },
                      [
                        _c("BaseInput", {
                          attrs: {
                            active: "",
                            "input-name": "articulator",
                            "input-type": "text",
                            value: _vm.school_reinsertion.articulator,
                            "show-label": "",
                            "label-text": "Articulador(a) Responsável",
                            dispatch: "SchoolReinsertions/setArticulator",
                            required: "",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.result_type?.id === 2 || _vm.activity_type?.id === 3
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("AppButtonUploadFile2", {
                              attrs: {
                                id: "file-declaration",
                                id_file: `${_vm.idFileStudentDeclaracao}`,
                                name: "declaração",
                                text: "+  Comprovante de Matrícula",
                                dispatch:
                                  "ModalInscription/setDocumentInscriptionDECLARACAO",
                                "type-document": "declaration",
                                "label-text": "Anexar Documento",
                                "show-label": "",
                                color: "ashes-dark",
                              },
                            }),
                            _c(
                              "div",
                              { staticClass: "-mt-8" },
                              [
                                _c("AppButtonIcon", {
                                  attrs: {
                                    iconName: "delete",
                                    payload: _vm.inscription.student,
                                    dispatch:
                                      "ModalInscription/deleteStudentDocumentDECLARACAO",
                                  },
                                }),
                                _c("AppButtonIcon", {
                                  attrs: {
                                    iconName: "download",
                                    payload: _vm.inscription.student,
                                    dispatch:
                                      "ModalInscription/showStudentDocumentDECLARACAO",
                                  },
                                }),
                                _c("AppButtonIcon", {
                                  attrs: {
                                    iconName: "event",
                                    dispatch:
                                      "SchoolReinsertions/toggleSchoolReinsertionDeadlineAttachProofOfEnrollment",
                                    title:
                                      "Informar data para/de anexo de comprovante de matrícula",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    (_vm.result_type?.id === 2 ||
                      _vm.activity_type?.id === 3) &&
                    _vm.toggleSchoolReinsertionDeadlineAttachProofOfEnrollment
                      ? _c(
                          "div",
                          { staticClass: "col-span-2" },
                          [
                            _c("BaseInput", {
                              attrs: {
                                active: "",
                                "input-name":
                                  "deadline_attach_proof_of_enrollment",
                                "input-type": "date",
                                value:
                                  _vm.school_reinsertion
                                    .deadline_attach_proof_of_enrollment,
                                "show-label": "",
                                "label-text":
                                  "Data Para/De Anexo de Comprovante de Matrícula",
                                dispatch:
                                  "SchoolReinsertions/setDeadlineAttachProofOfEnrollment",
                                required: "",
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("AppButtonEmpty", {
                          attrs: {
                            text: "descartar",
                            typeButton: "danger",
                            dispatch:
                              "SchoolReinsertions/closeModalAddActivity",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _vm.school_reinsertion.id
                          ? _c("AppButtonEmpty", {
                              attrs: {
                                text: "Salvar",
                                typeButton: "success",
                                dispatch: "SchoolReinsertions/update",
                              },
                            })
                          : _c("AppButtonEmpty", {
                              attrs: {
                                text: "criar",
                                typeButton: "success",
                                dispatch: "SchoolReinsertions/save",
                              },
                            }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          685231390
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }