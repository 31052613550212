var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "font-system" }, [
    _c(
      "div",
      { staticClass: "mx-12 my-6" },
      [_c("Breadcrumbs", { attrs: { breadcrumbs: _vm.breadcrumbs } })],
      1
    ),
    _vm._m(0),
    _c(
      "div",
      {
        staticClass:
          "grid grid-cols-3 justify-between p-4 items-end mx-12 my-6 gap-4",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          { staticClass: "w-52" },
          [
            _c("Multiselect", {
              attrs: {
                "close-on-select": true,
                "show-label": "",
                "clear-on-select": "",
                "select-placeholder": "Selecionar edição",
                "multiple-option": false,
                value: _vm.season,
                options: _vm.seasons,
                "label-text": "Edição",
                dispatch: _vm.setSeason,
                required: "",
              },
            }),
          ],
          1
        ),
      ]
    ),
    _vm.season
      ? _c(
          "div",
          {
            staticClass: "mx-16 flex",
            attrs: { id: "menus_productive_insertion_main_page" },
          },
          [
            _vm.permitListScholarshipPaymentSheetTab
              ? _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab("scholarship_payment_sheet")
                        },
                      },
                    },
                    [
                      _c("BaseText", {
                        attrs: {
                          text: "Folha de Pagamento",
                          typeText: "custom",
                          weight: "semibold",
                          mode: "uppercase",
                          size: "sm",
                          color: _vm.setColorScholarshipPaymentSheets,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
            _c("div", { staticClass: "border-l mx-10" }),
            _vm.permitListScholarshipPaymentControlTab
              ? _c("div", [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.changeTab("scholarship_payment_control")
                        },
                      },
                    },
                    [
                      _c("BaseText", {
                        attrs: {
                          text: "Controle de Pagamento",
                          typeText: "custom",
                          weight: "semibold",
                          mode: "uppercase",
                          size: "sm",
                          color: _vm.setColorScholarshipPaymentControls,
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]
        )
      : _vm._e(),
    _c("div", { attrs: { id: "list-productive-insertion-types" } }, [
      _vm.season
        ? _c(
            "div",
            [
              _c("ScholarshipPaymentSheetMainPage", {
                attrs: { open: _vm.toggleScholarshipPaymentSheets },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.season
        ? _c(
            "div",
            [
              _c("ScholarshipPaymentControlMainPage", {
                attrs: { open: _vm.toggleScholarshipPaymentControls },
              }),
            ],
            1
          )
        : _vm._e(),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "grid grid-cols-3 justify-between items-center mx-12 my-6",
        attrs: { id: "tabs-button" },
      },
      [
        _c(
          "div",
          {
            staticClass:
              "text-ashes-hover font-medium text-3xl mx-2 col-span-2",
          },
          [_vm._v("Bolsas")]
        ),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }