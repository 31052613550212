var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { class: _vm.classesModal }, [
      _c(
        "div",
        {
          staticClass:
            "grid grid-flow-row auto-rows-max md:auto-rows-min z-1000000000000",
        },
        [
          _c(
            "div",
            { class: _vm.classPosition },
            [
              _c("BaseText", {
                staticClass: "underline",
                attrs: {
                  color: "white",
                  text: "Selecione a cidade",
                  typeText: "custom",
                  weight: "semibold",
                  italic: false,
                  mode: "capitalize",
                  size: "2xl",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { class: _vm.classPosition, attrs: { id: "content" } },
            _vm._l(_vm.cities, function (city) {
              return _c(
                "div",
                {
                  key: city.id,
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.setCity(city)
                    },
                  },
                },
                [_c("AppCardCity", { attrs: { name: city.name } })],
                1
              )
            }),
            0
          ),
        ]
      ),
    ]),
    _c("div", { staticClass: "opacity-50 fixed inset-0 z-40 bg-black" }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }