var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-custom items-center -mx-12" }, [
        _c(
          "div",
          { staticClass: "mt-10 ml-6" },
          [
            _c("BaseText", {
              attrs: {
                text: "Status",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-10 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "Nome do Jovem",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-10 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "Edição",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-10 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "CPF",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-10 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "Área",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-10 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "Equipamento",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-10 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "Data de Justificativa",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "flex" }),
      ]),
      _c(
        "BaseStaggeredFade",
        { staticClass: "-mx-12", attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.data, function (inscription, index) {
          return _c(
            "li",
            { key: inscription.id, attrs: { "data-index": index } },
            [
              _c(
                "div",
                [
                  _c("AppCardPsychosocialAccompanimentUnaccompanied", {
                    attrs: { inscription: inscription },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "border-ashes w-full border ml-5" }),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }