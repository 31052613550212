var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-1" }, [
        _vm.permit &&
        _vm.season_date_manual_execution_end &&
        _vm.isTeamsInProgress()
          ? _c(
              "div",
              { staticClass: "justify-self-end" },
              [
                _c("AppButtonColorful", {
                  attrs: {
                    text: "Adicionar Encontro",
                    typeButton: "primary",
                    showIcon: "",
                    iconName: "add",
                    iconType: "outlined",
                    dispatch: "Meeting/toggleModal",
                  },
                }),
              ],
              1
            )
          : _vm._e(),
      ]),
      _c("MeetingsCards", { attrs: { data: _vm.meetings } }),
      _c("ModalMeeting", {
        attrs: { open: _vm.modalOpen, data: _vm.meetings },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }