var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { class: _vm.editionsRow },
    [
      _c(
        "div",
        {
          staticClass:
            "grid grid-cols-3 justify-between items-center mx-6 my-6",
          attrs: { id: "tabs-button" },
        },
        [
          _c(
            "div",
            { staticClass: "text-ashes-hover font-medium text-2xl col-span-2" },
            [
              _c("Label", {
                class: _vm.title,
                attrs: {
                  text: "Edições",
                  icon: null,
                  iconType: null,
                  type: "text-3xl",
                  weight: "semibold",
                  normal: "",
                  mode: "normal-case",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "mx-2 items-center justify-self-end" },
            [
              _vm.permitCreate
                ? _c("AppButtonColorful", {
                    attrs: {
                      text: "Adicionar edição",
                      typeButton: "primary",
                      showIcon: "",
                      iconName: "add",
                      iconType: "outlined",
                      dispatch: "Seasons/openModal",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        { staticClass: "mx-6", attrs: { id: "tabs-button" } },
        [
          _c("FilterComponent", [
            _c(
              "div",
              { staticClass: "col-span-4" },
              [
                _c("BaseInput", {
                  attrs: {
                    active: "",
                    "input-name": "name",
                    "input-type": "text",
                    "icon-name": "search",
                    showIcon: "",
                    "show-label": "",
                    value: _vm.nameFiltered,
                    "label-text": "Pesquisar por Nome da Edição",
                    "input-placeholder": "Pesquisar por Nome da Edição",
                    dispatch: "Seasons/setFilterName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-4" },
              [
                _c("Multiselect", {
                  attrs: {
                    "close-on-select": true,
                    "show-label": "",
                    "clear-on-select": "",
                    "select-placeholder": "Município",
                    "multiple-option": false,
                    value: _vm.city_filtered,
                    options: _vm.cities,
                    "label-text": "Município",
                    dispatch: _vm.setFilterCity,
                  },
                }),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "grid grid-cols-2 items-center mx-6 my-6",
          attrs: { id: "tabs-button" },
        },
        [
          _c(
            "div",
            [
              _c("AppButtonColorful", {
                attrs: {
                  id: "btn-consult-seasons",
                  showIcon: "",
                  iconName: "search",
                  text: "Consultar dados",
                  dispatch: "Seasons/loadSeasons",
                  payload: _vm.typeRequest,
                  typeButton: "primary",
                },
              }),
            ],
            1
          ),
          _vm.seasons.length > 0
            ? _c("div", { staticClass: "items-center justify-self-end" }, [
                _c(
                  "a",
                  {
                    attrs: { href: "javascript:void(0)" },
                    on: {
                      click: function ($event) {
                        return _vm.clearFields()
                      },
                    },
                  },
                  [
                    _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-consult-seasons",
                        showIcon: "",
                        iconName: "remove",
                        text: "Limpar consulta",
                        typeButton: "primary",
                      },
                    }),
                  ],
                  1
                ),
              ])
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        { staticClass: "grid grid-cols-custom mt-12 ml-6 text-ashes-dark" },
        [
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Edição",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Status",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Período de inscrição",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Período de execução",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Nº de Matrículas",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Nº de Ativos",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Nº de Desligados",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("Label", {
                attrs: {
                  text: "Nº de Concludentes",
                  icon: null,
                  iconType: null,
                  type: "text-sm",
                  weight: "semibold",
                  normal: "",
                  mode: "uppercase",
                },
              }),
            ],
            1
          ),
          _c("div"),
        ]
      ),
      _c(
        "BaseStaggeredFade",
        { attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.filteredSeasons, function (season, index) {
          return _c(
            "div",
            {
              key: season.id,
              staticClass: "mt-6",
              attrs: { "data-index": index },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.goToSeason(season)
                },
              },
            },
            [
              _c("AppEdition", { attrs: { edition: season } }),
              _c("div", { staticClass: "my-6 border-ashes w-full border" }),
            ],
            1
          )
        }),
        0
      ),
      _vm.seasons.length > 0
        ? _c(
            "div",
            {
              staticClass: "mx-12 my-6 pb-3",
              attrs: { id: "seasons-pagination" },
            },
            [
              _c("BasePagination", {
                attrs: {
                  total: _vm.seasonsTotal,
                  "total-per-page": _vm.seasonsTotalPerPage,
                  dispatch: "Seasons/setSeasonsOffset",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("ModalSeason", { attrs: { open: _vm.showModal } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }