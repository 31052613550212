var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.data && _vm.data?.length > 0
        ? _c(
            "div",
            {
              staticClass:
                "mr-8 font-system uppercase font-medium text-xs text-ashes-dark",
            },
            [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.allChecked,
                    expression: "allChecked",
                  },
                ],
                staticClass: "mr-2",
                attrs: { type: "checkbox" },
                domProps: {
                  checked: Array.isArray(_vm.allChecked)
                    ? _vm._i(_vm.allChecked, null) > -1
                    : _vm.allChecked,
                },
                on: {
                  change: [
                    function ($event) {
                      var $$a = _vm.allChecked,
                        $$el = $event.target,
                        $$c = $$el.checked ? true : false
                      if (Array.isArray($$a)) {
                        var $$v = null,
                          $$i = _vm._i($$a, $$v)
                        if ($$el.checked) {
                          $$i < 0 && (_vm.allChecked = $$a.concat([$$v]))
                        } else {
                          $$i > -1 &&
                            (_vm.allChecked = $$a
                              .slice(0, $$i)
                              .concat($$a.slice($$i + 1)))
                        }
                      } else {
                        _vm.allChecked = $$c
                      }
                    },
                    _vm.onCheckAll,
                  ],
                },
              }),
              _c("label", { attrs: { for: "checkAll" } }, [
                _vm._v("Marcar todas as turmas"),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "div",
        {
          staticClass: "my-6 tabs-button-container",
          attrs: { id: "tabs-button" },
        },
        [
          _c(
            "FilterComponent",
            [
              _c("Multiselect", {
                attrs: {
                  "close-on-select": true,
                  "show-label": "",
                  "clear-on-select": "",
                  "label-text": "Filtrar por Cidade",
                  "select-placeholder": "Filtrar por Cidade",
                  "multiple-option": false,
                  value: _vm.params_teams.city,
                  options: _vm.cities,
                  dispatch: _vm.setFilterTeamCity,
                },
              }),
              _c("Multiselect", {
                attrs: {
                  "close-on-select": true,
                  "show-label": "",
                  "clear-on-select": "",
                  "label-text": "Filtrar por Área",
                  "select-placeholder": "Filtrar por Área",
                  "multiple-option": false,
                  value: _vm.params_teams.area,
                  options: _vm.user_areas,
                  dispatch: _vm.setFilterTeamArea,
                },
              }),
              _c("BaseInput", {
                attrs: {
                  active: "",
                  "input-name": "code",
                  "input-type": "text",
                  "icon-name": "search",
                  showIcon: "",
                  "show-label": "",
                  value: _vm.params_teams.code,
                  "label-text": "Filtrar pelo Código da Turma",
                  "input-placeholder": "Filtrar pelo Código da Turma",
                  dispatch: "Advantages/setFilterTeamCode",
                },
              }),
            ],
            1
          ),
          _c("AppButtonColorful", {
            attrs: {
              id: "btn-consult-teams-benefits",
              showIcon: "",
              iconName: "search",
              text: "Filtrar Turmas",
              dispatch: "Advantages/loadTeams",
              typeButton: "primary",
            },
          }),
        ],
        1
      ),
      _c(
        "BaseStaggeredFade",
        { attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.data, function (team, index) {
          return _c(
            "li",
            { key: team.id, staticClass: "mb-4" },
            [
              _c(
                "BaseCard",
                { class: _vm.liClass },
                [
                  _c(
                    "div",
                    { staticClass: "flex justify-between items-center pt-2" },
                    [
                      _c(
                        "div",
                        { staticClass: "flex items-center" },
                        [
                          _c("input", {
                            staticClass: "mr-4",
                            attrs: {
                              id: team.code,
                              type: "checkbox",
                              name: "teamsAllocations",
                            },
                            domProps: {
                              value: team.id,
                              checked: _vm.isTeamFullySelected(team),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onCheck(team, $event)
                              },
                            },
                          }),
                          _c(
                            "div",
                            { class: _vm.iconClass },
                            [
                              _c("AppMaterialIcon", {
                                staticClass: "place-self-center text-white",
                                attrs: {
                                  iconName: "book",
                                  iconType: "outlined",
                                  iconSize: "36",
                                },
                              }),
                            ],
                            1
                          ),
                          _c("BaseText", {
                            staticClass: "mr-1",
                            attrs: {
                              text: team.code,
                              typeText: "custom",
                              mode: "underline",
                              weight: "bold",
                              size: "xl",
                            },
                          }),
                          _c(
                            "span",
                            { staticClass: "text-sm text-gray-600 ml-2" },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(_vm.selectedCount(team)) +
                                  " de " +
                                  _vm._s(team.students_actives.length) +
                                  " alunos selecionados "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _c(
                        "button",
                        {
                          staticClass: "focus:outline-none",
                          on: {
                            click: function ($event) {
                              return _vm.toggleTeamDetails(index)
                            },
                          },
                        },
                        [
                          _c("AppMaterialIcon", {
                            staticClass: "text-black",
                            attrs: {
                              iconName: _vm.expandedTeams.includes(index)
                                ? "remove"
                                : "add",
                              iconType: "outlined",
                              iconSize: "24",
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _c("transition", { attrs: { name: "expand" } }, [
                    _c(
                      "ul",
                      {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: _vm.expandedTeams.includes(index),
                            expression: "expandedTeams.includes(index)",
                          },
                        ],
                        staticClass:
                          "bg-light-blue p-4 rounded-lg mt-2 overflow-hidden team-details",
                      },
                      _vm._l(team.students_actives, function (student) {
                        return _c(
                          "li",
                          {
                            key: student.teams_student_id,
                            staticClass: "flex items-center mb-2",
                          },
                          [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedStudents,
                                  expression: "selectedStudents",
                                },
                              ],
                              staticClass: "mr-2",
                              attrs: { type: "checkbox" },
                              domProps: {
                                value: student.teams_student_id,
                                checked: Array.isArray(_vm.selectedStudents)
                                  ? _vm._i(
                                      _vm.selectedStudents,
                                      student.teams_student_id
                                    ) > -1
                                  : _vm.selectedStudents,
                              },
                              on: {
                                change: [
                                  function ($event) {
                                    var $$a = _vm.selectedStudents,
                                      $$el = $event.target,
                                      $$c = $$el.checked ? true : false
                                    if (Array.isArray($$a)) {
                                      var $$v = student.teams_student_id,
                                        $$i = _vm._i($$a, $$v)
                                      if ($$el.checked) {
                                        $$i < 0 &&
                                          (_vm.selectedStudents = $$a.concat([
                                            $$v,
                                          ]))
                                      } else {
                                        $$i > -1 &&
                                          (_vm.selectedStudents = $$a
                                            .slice(0, $$i)
                                            .concat($$a.slice($$i + 1)))
                                      }
                                    } else {
                                      _vm.selectedStudents = $$c
                                    }
                                  },
                                  function ($event) {
                                    return _vm.onStudentCheck(student, team)
                                  },
                                ],
                              },
                            }),
                            _c("span", [_vm._v(_vm._s(student.student_name))]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
      _vm.getLoadingVariablesState
        ? _c("div", [_c("PreLoading")], 1)
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }