var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permit && _vm.open
    ? _c("div", { staticClass: "font-system" }, [
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-3 justify-between items-center mx-12 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "text-ashes-hover font-medium text-2xl mx-2 col-span-2",
              },
              [_vm._v("Folhas de Pagamentos")]
            ),
            _c(
              "div",
              { staticClass: "mx-2 items-center justify-self-end" },
              [
                _vm.permitCreate
                  ? _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-add-payment_sheet",
                        showIcon: "",
                        iconName: "add",
                        text: "Cadastrar Folha de Pagamento",
                        dispatch: "PaymentSheets/openModalAddPaymentSheet",
                        typeButton: "primary",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "mx-14", attrs: { id: "tabs-button" } },
          [
            _c("FilterComponent", [
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "label-text": "Status da Folha",
                      "select-placeholder": "Selecione o status",
                      "multiple-option": false,
                      value: _vm.payment_sheet_params.status,
                      options: _vm.payment_sheet_statuses_options,
                      dispatch: _vm.setFilterStatus,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "name",
                      "input-type": "text",
                      "icon-name": "search",
                      showIcon: "",
                      "show-label": "",
                      value: _vm.payment_sheet_params.process_number,
                      "label-text": "Pesquisar por Nª do Processo",
                      "input-placeholder": "Pesquisar por Nª do Processo",
                      dispatch: "PaymentSheets/setFilterProcessNumber",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "label-text": "Matriz",
                      "select-placeholder": "Selecione a matriz",
                      "multiple-option": false,
                      value: _vm.payment_sheet_params.scholarship_matrix,
                      options: _vm.scholarship_matrices_for_menus,
                      dispatch: _vm.setFilterScholarshipMatrix,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "label-text": "Parcelas",
                      "select-placeholder": "Selecione a parcela",
                      "multiple-option": false,
                      value: _vm.payment_sheet_params.installment,
                      options: _vm.payment_sheet_installment_options,
                      dispatch: _vm.setFilterInstallment,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "label-text": "Remessa",
                      "select-placeholder": "Selecione a remessa",
                      "multiple-option": false,
                      value: _vm.payment_sheet_params.remittance,
                      options: _vm.payment_sheet_remittance_options,
                      dispatch: _vm.setFilterRemittance,
                    },
                  }),
                ],
                1
              ),
            ]),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "grid grid-cols-2 items-center mx-12 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              { staticClass: "mx-2" },
              [
                _vm.permit
                  ? _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-consult-payment_sheets",
                        showIcon: "",
                        iconName: "search",
                        text: "Consultar dados",
                        dispatch: "PaymentSheets/loadPaymentSheets",
                        payload: "load",
                        typeButton: "primary",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.payment_sheets.length > 0
              ? _c(
                  "div",
                  { staticClass: "mx-2 items-center justify-self-end" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.clearFields()
                          },
                        },
                      },
                      [
                        _c("AppButtonColorful", {
                          attrs: {
                            id: "btn-consult-payment_sheets",
                            showIcon: "",
                            iconName: "remove",
                            text: "Limpar consulta",
                            typeButton: "primary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { attrs: { id: "list-payment_sheets" } },
          [
            _c(
              "div",
              { staticClass: "mx-12" },
              [
                _c("ScholarshipPaymentSheetsCards", {
                  attrs: { data: _vm.payment_sheets },
                }),
              ],
              1
            ),
            _vm.payment_sheets.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "mx-12 my-6 pb-3",
                    attrs: { id: "payment_sheets-pagination" },
                  },
                  [
                    _c("BasePagination", {
                      attrs: {
                        total: _vm.totalPaymentSheets,
                        "total-per-page": _vm.totalPerPage,
                        dispatch: "PaymentSheets/setPaymentSheetsOffset",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("ModalPaymentSheet", {
              attrs: { open: _vm.modalToggleAddPaymentSheet },
            }),
            _c("DeletePaymentSheetConfirmation", {
              attrs: { open: _vm.modalToggleDeletePaymentSheet },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }