var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.open
        ? _c("BaseModal", {
            attrs: { headerColor: "#E6673E" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "grid grid-cols-2" }, [
                        _c("div", { staticClass: "mb-3 justify-self-start" }, [
                          _c(
                            "div",
                            {
                              staticClass: "md:w-full lg:w-full",
                              attrs: { id: "options" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { id: "name-matrix-situation" } },
                                [
                                  _c("BaseText", {
                                    attrs: {
                                      text: "Matriz - Situação",
                                      "type-text": "custom",
                                      weight: "medium",
                                      mode: "capitalize",
                                      color: "white",
                                      size: "xl",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]),
                        _c(
                          "div",
                          { staticClass: "justify-self-end" },
                          [
                            _c("AppButtonIcon", {
                              attrs: {
                                "for-close": "",
                                dispatch: "MatrixSituation/closeViewModal",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", [_c("div", [_c("MatrixSituationData")], 1)]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              4245444286
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }