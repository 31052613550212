<template>
  <div>
    <div class="grid grid-cols-custom items-center">
      <div class="mt-4 ml-5">
        <BaseText
          text="Status"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Área"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Nome do Participante"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Data"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Parceiro"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 ml-5">
        <BaseText
          text="Empresa"
          typeText="custom"
          weight="bold"
          :italic="false"
          mode="uppercase"
          size="sm"
          color="ashes-dark"
        />
      </div>
      <div class="mt-4 flex"></div>
    </div>
    <BaseStaggeredFade :duration="10" tag="ul">
      <li
        :key="forwarding_reservation_registration.id"
        :data-index="index"
        v-for="(forwarding_reservation_registration, index) in data"
      >
        <div>
          <AppCardForwardingReservationRegistration
            :forwarding_reservation_registration="forwarding_reservation_registration"
          />
        </div>
        <div class="border-ashes w-full border"></div>
      </li>
    </BaseStaggeredFade>
  </div>
</template>

<script>
import AppCardForwardingReservationRegistration from "../../components/AppCardForwardingReservationRegistration.vue";
import BaseText from "../../components/BaseText.vue";
import BaseStaggeredFade from "../../components/BaseStaggeredFade.vue";

export default {
  components: {
    AppCardForwardingReservationRegistration,
    BaseText,
    BaseStaggeredFade,
  },

  props: {
    data: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.grid-cols-custom {
  grid-template-columns: 10% 13% 23% 12% 15% 9%;
}
</style>
