var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2" }, [
                    _c(
                      "div",
                      { staticClass: "justify-self-start" },
                      [
                        _c("Label", {
                          staticClass: "text-ashes-dark",
                          attrs: {
                            text: "Falta Justificada",
                            icon: null,
                            "icon-type": null,
                            type: "text-2xl",
                            weight: "bold",
                            normal: "",
                            mode: "normal-case",
                          },
                        }),
                      ],
                      1
                    ),
                    _c(
                      "div",
                      { staticClass: "justify-self-end" },
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            dispatch: "Presence/closeJustificationModal",
                            payload: _vm.justification,
                            forClose: "",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c(
                    "div",
                    [
                      _c("BaseTextArea", {
                        attrs: {
                          active: "",
                          inputName: "descrição",
                          required: "",
                          labelText: "Descrição",
                          showLabel: "",
                          rows: "4",
                          value: _vm.justification,
                          dispatch: "Presence/setJustification",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { key: _vm.componentKey },
                    [
                      _c("AppButtonUploadFile2", {
                        attrs: {
                          id: "file-justificationdocument",
                          id_file: `${_vm.idFileStudentJustificationDocument}`,
                          name: "justificationdocument",
                          text: "+  Anexar Justificativa",
                          dispatch:
                            "ModalInscription/setDocumentInscriptionJustificationDocument",
                          "type-document": "JustificationDocument",
                          "label-text": "Anexar Documento",
                          "show-label": "",
                          color: "ashes-dark",
                        },
                      }),
                      _c(
                        "div",
                        { key: _vm.componentKey, staticClass: "-mt-8" },
                        [
                          _c("AppButtonIcon", {
                            attrs: {
                              iconName: "delete",
                              payload: _vm.idFileStudentJustificationDocument,
                              dispatch:
                                "ModalInscription/deleteStudentDocumentJustificationDocument",
                            },
                          }),
                          _c("AppButtonIcon", {
                            attrs: {
                              iconName: "download",
                              payload: _vm.idFileStudentJustificationDocument,
                              dispatch:
                                "ModalInscription/showStudentDocumentJustificationDocument",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c("div", { staticClass: "grid grid-cols-2 col-auto" }, [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.closeJustificationModal()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "justify-self-start" },
                          [
                            _c("AppButtonEmpty", {
                              attrs: {
                                text: "descartar",
                                typeButton: "danger",
                                payload: _vm.justification,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.updatePresence()
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "justify-self-end" },
                          [
                            _c("AppButtonEmpty", {
                              attrs: {
                                text: "Salvar",
                                typeButton: "success",
                                payload: _vm.justification,
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          2423477970
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }