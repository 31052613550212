var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.permit && _vm.open
    ? _c("div", { staticClass: "font-system" }, [
        _c(
          "div",
          {
            staticClass:
              "grid grid-cols-3 justify-between items-center mx-12 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              {
                staticClass:
                  "text-ashes-hover font-medium text-2xl mx-2 col-span-2",
              },
              [_vm._v(" Encaminhamento para Cadastro Reserva ")]
            ),
            _c(
              "div",
              { staticClass: "mx-2 items-center justify-self-end" },
              [
                _vm.permitCreate
                  ? _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-add-forwarding_reservation_registration",
                        showIcon: "",
                        iconName: "add",
                        text: "Cadastrar Encaminhamento",
                        dispatch:
                          "ForwardingReservationRegistrations/openModalAddForwardingReservationRegistration",
                        typeButton: "primary",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
          ]
        ),
        _c(
          "div",
          { staticClass: "mx-14", attrs: { id: "tabs-button" } },
          [
            _c("FilterComponent", [
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "label-text": "Status da Matrícula",
                      "select-placeholder": "Escolha a opção para buscar",
                      "multiple-option": false,
                      value: _vm.inscription_status_filtered,
                      options: _vm.incriptions_status,
                      dispatch: _vm.setFilterInscriptionStatus,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "label-text": "Filtrar por Nome do Aluno(a)",
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder":
                        "Digite o nome do aluno(a) para busca",
                      "multiple-option": false,
                      value: _vm.inscription_filtered,
                      options: _vm.inscriptions,
                      dispatch: _vm.setFilterInscription,
                      "load-dispatch":
                        "SpontaneousProductiveInsertions/loadInscriptions",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Área",
                      "label-text": "Pesquisar por Área",
                      "multiple-option": false,
                      value: _vm.user_areas_filtered,
                      options: _vm.user_areas,
                      dispatch: _vm.setUserAreasFiltered,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Equipamento",
                      "multiple-option": false,
                      value: _vm.user_equipments_filtered,
                      options:
                        _vm.equipments_forwarding_reservation_registrations,
                      "label-text": _vm.getLabelEquipmentFilter(),
                      dispatch: _vm.setFilterEquipment,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "label-text": "Empresa",
                      "select-placeholder":
                        "Digite o nome da empresa para buscar",
                      "multiple-option": false,
                      value: _vm.company_filtered,
                      options: _vm.companies,
                      dispatch: _vm.setFilterCompany,
                      "load-dispatch":
                        "ForwardingReservationRegistrations/loadCompanies",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "label-text": "Parceiro",
                      "select-placeholder":
                        "Digite o nome do parceiro para buscar",
                      "multiple-option": false,
                      value: _vm.partner_filtered,
                      options: _vm.partners,
                      dispatch: _vm.setFilterPartner,
                      "load-dispatch":
                        "ForwardingReservationRegistrations/loadPartners",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "select-placeholder": "Status Qualificação",
                      "label-text": "Pesquisar por Status Qualificação",
                      "multiple-option": false,
                      value: _vm.qualification_status_filtered,
                      options: _vm.qualification_statuses,
                      dispatch: _vm.setFilterQualificationStatus,
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("Multiselect", {
                    attrs: {
                      "close-on-select": true,
                      "show-label": "",
                      "clear-on-select": "",
                      "label-text": "Usuário(a) responsável pelo cadastro",
                      "select-placeholder":
                        "Digite o nome do usuário para buscar",
                      "multiple-option": false,
                      value: _vm.user_filtered,
                      options: _vm.users,
                      dispatch: _vm.setFilterUser,
                      "load-dispatch":
                        "ForwardingReservationRegistrations/loadUsers",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "filter_by_initial_date",
                      "input-type": "date",
                      value: _vm.initial_date_filtered,
                      "show-label": "",
                      "label-text": "Data do Encaminhamento - Data Inicial",
                      dispatch:
                        "ForwardingReservationRegistrations/setFilterInitialDate",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "col-span-2" },
                [
                  _c("BaseInput", {
                    attrs: {
                      active: "",
                      "input-name": "filter_by_final_date",
                      "input-type": "date",
                      value: _vm.final_date_filtered,
                      "show-label": "",
                      "label-text": "Data do Encaminhamento - Data Final",
                      dispatch:
                        "ForwardingReservationRegistrations/setFilterFinalDate",
                    },
                  }),
                ],
                1
              ),
            ]),
            _c("div", { staticClass: "mt-3" }, [
              _vm.forwarding_reservation_registrations?.length > 0 &&
              _vm.permitExportSpreadsheet
                ? _c(
                    "a",
                    {
                      attrs: { href: "javascript:void(0)" },
                      on: {
                        click: function ($event) {
                          return _vm.exportToExcel()
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "inline-block",
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          src: require("@/assets/images/logout_black_24dp.svg"),
                          alt: "",
                        },
                      }),
                      _c("BaseText", {
                        staticStyle: { cursor: "pointer" },
                        attrs: {
                          text: "Exportar planilha",
                          typeText: "custom",
                          italic: false,
                          size: "xs",
                          color: "burnedYellow",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
            ]),
            _vm.forwarding_reservation_registrations_spreadsheet.length > 0 &&
            _vm.modalToggleExportForwardingReservationRegistrationsSpreadsheet
              ? _c("BaseModal", {
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "header",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "grid grid-cols-3" }, [
                              _c(
                                "div",
                                {
                                  staticClass: "justify-self-start col-span-2",
                                },
                                [
                                  _c("Label", {
                                    staticClass: "text-ashes-dark",
                                    attrs: {
                                      text: "Exportar Planilha de Encaminhamentos para Cadastro Reserva",
                                      icon: null,
                                      "icon-type": null,
                                      type: "text-2xl",
                                      weight: "bold",
                                      normal: "",
                                      mode: "normal-case",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "justify-self-end" },
                                [
                                  _c("AppButtonIcon", {
                                    attrs: {
                                      dispatch:
                                        "ForwardingReservationRegistrations/closeExportForwardingReservationRegistrationsSpreadsheetModal",
                                      forClose: "",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "footer",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "grid grid-rows-2" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "grid justify-center items-center",
                                },
                                [
                                  _c("Label", {
                                    staticClass: "text-ashes-dark",
                                    attrs: {
                                      text: "Planilha gerada com sucesso!",
                                      icon: null,
                                      "icon-type": null,
                                      type: "text-2x1",
                                      weight: "normal",
                                      normal: "",
                                      mode: "normal-case",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c("div", { staticClass: "grid grid-cols-2" }, [
                                _c(
                                  "div",
                                  { staticClass: "justify-self-start" },
                                  [
                                    _c("AppButtonEmpty", {
                                      attrs: {
                                        text: "descartar",
                                        typeButton: "danger",
                                        dispatch:
                                          "ForwardingReservationRegistrations/closeExportForwardingReservationRegistrationsSpreadsheetModal",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "div",
                                  { staticClass: "justify-self-end" },
                                  [
                                    _c(
                                      "download-excel",
                                      {
                                        attrs: {
                                          type: "xls",
                                          meta: _vm.json_meta,
                                          fields: _vm.json_fields,
                                          data: _vm.json_data_forwarding_reservation_registration,
                                          "before-finish":
                                            _vm.closeExportForwardingReservationRegistrationsSpreadsheetModal,
                                          worksheet:
                                            "Encaminhamentos_Cadastro_Reserva",
                                          name: "encaminhamentos_cadastro_reserva.xls",
                                          escapeCsv: false,
                                        },
                                      },
                                      [
                                        _c("AppButtonEmpty", {
                                          attrs: {
                                            text: "Download da planilha",
                                            typeButton: "success",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    false,
                    4034068092
                  ),
                })
              : _vm._e(),
            !_vm.hasSpreadsheetForwardingReservationRegistrationsResponse
              ? _c("div", [_c("PreLoading")], 1)
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "grid grid-cols-2 items-center mx-12 my-6",
            attrs: { id: "tabs-button" },
          },
          [
            _c(
              "div",
              { staticClass: "mx-2" },
              [
                _vm.permit
                  ? _c("AppButtonColorful", {
                      attrs: {
                        id: "btn-consult-forwarding_reservation_registrations",
                        showIcon: "",
                        iconName: "search",
                        text: "Consultar dados",
                        dispatch:
                          "ForwardingReservationRegistrations/loadForwardingReservationRegistrations",
                        payload: "load",
                        typeButton: "primary",
                      },
                    })
                  : _vm._e(),
              ],
              1
            ),
            _vm.forwarding_reservation_registrations.length > 0
              ? _c(
                  "div",
                  { staticClass: "mx-2 items-center justify-self-end" },
                  [
                    _c(
                      "a",
                      {
                        attrs: { href: "javascript:void(0)" },
                        on: {
                          click: function ($event) {
                            return _vm.clearFields()
                          },
                        },
                      },
                      [
                        _c("AppButtonColorful", {
                          attrs: {
                            id: "btn-consult-forwarding_reservation_registrations",
                            showIcon: "",
                            iconName: "remove",
                            text: "Limpar consulta",
                            typeButton: "primary",
                          },
                        }),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
        _c(
          "div",
          { attrs: { id: "list-forwarding_reservation_registrations" } },
          [
            _c(
              "div",
              { staticClass: "mx-12" },
              [
                _c("ForwardingReservationRegistrationsCards", {
                  attrs: { data: _vm.forwarding_reservation_registrations },
                }),
              ],
              1
            ),
            _vm.forwarding_reservation_registrations.length > 0
              ? _c(
                  "div",
                  {
                    staticClass: "mx-12 my-6 pb-3",
                    attrs: {
                      id: "forwarding_reservation_registrations-pagination",
                    },
                  },
                  [
                    _c("BasePagination", {
                      attrs: {
                        total: _vm.totalForwardingReservationRegistrations,
                        "total-per-page": _vm.totalPerPage,
                        dispatch:
                          "ForwardingReservationRegistrations/setForwardingReservationRegistrationsOffset",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c("ModalForwardingReservationRegistration", {
              attrs: {
                open: _vm.modalToggleAddForwardingReservationRegistration,
              },
            }),
            _c("ModalViewForwardingReservationRegistrationData", {
              attrs: {
                open: _vm.modalToggleViewForwardingReservationRegistration,
              },
            }),
            _c("DeleteForwardingReservationRegistrationConfirmation", {
              attrs: {
                open: _vm.modalToggleDeleteForwardingReservationRegistration,
              },
            }),
          ],
          1
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }