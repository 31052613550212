var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Nome",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "mt-4 flex" }),
      ]),
      _c(
        "BaseStaggeredFade",
        { attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.data, function (segment, index) {
          return _c("li", { key: segment.id, attrs: { "data-index": index } }, [
            _c(
              "div",
              [_c("AppCardSegment", { attrs: { segment: segment } })],
              1
            ),
            _c("div", { staticClass: "border-ashes w-full border" }),
          ])
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }