var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { class: _vm.liClass }, [
    _c(
      "div",
      { staticClass: "grid grid-cols-custom gap-10 items-center pt-2" },
      [
        _c("div", { staticClass: "flex justify-start items-center gap-4" }, [
          _c("div", { staticClass: "place-self-center" }, [
            _c(
              "div",
              { class: _vm.iconClass },
              [
                _c("AppMaterialIcon", {
                  staticClass: "place-self-center text-white",
                  attrs: {
                    iconName: "bookmark_border",
                    iconType: "outlined",
                    iconSize: "36",
                  },
                }),
              ],
              1
            ),
          ]),
          _c(
            "div",
            { staticClass: "justify-self-start place-self-center" },
            [
              _c("BaseText", {
                attrs: {
                  text: _vm.getMeetingName(),
                  typeText: "custom",
                  weight: "bold",
                  italic: false,
                  size: "xl",
                },
              }),
            ],
            1
          ),
          _vm.meeting.has_unregistered_presence && !_vm.meeting.suspended
            ? _c(
                "div",
                {
                  staticClass: "flex items-center ml-1 pr-1 col-span-2 tooltip",
                },
                [
                  _c(
                    "button",
                    {
                      ref: "btnRef",
                      on: {
                        mouseenter: function ($event) {
                          return _vm.toggleTooltip()
                        },
                        mouseleave: function ($event) {
                          return _vm.toggleTooltip()
                        },
                      },
                    },
                    [
                      _c(
                        "span",
                        { staticClass: "material-icons mt-2 text-yellow-400" },
                        [
                          _c("span", { staticClass: "material-icons" }, [
                            _vm._v(" warning "),
                          ]),
                        ]
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    {
                      ref: "tooltipRef",
                      staticClass:
                        "bg-white border border-black-600 mr-3 block font-normal leading-normal text-sm text-left break-words rounded-lg tooltiptext shadow-xl w-auto",
                      class: {
                        hidden: !_vm.tooltipShow,
                        block: _vm.tooltipShow,
                      },
                    },
                    [
                      _c("div", { staticClass: "w-full" }, [
                        _c(
                          "div",
                          { staticClass: "text-red-500 font-semibold p-2" },
                          [
                            _c(
                              "span",
                              { staticClass: "tolltiptext-position" },
                              [
                                _vm._v(
                                  " Encontro com presença(s) pendente(s) de registro! "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ]),
                    ]
                  ),
                ]
              )
            : _vm._e(),
        ]),
        _c(
          "div",
          {},
          [
            _c("BaseText", {
              attrs: {
                text: _vm.meeting.date + " - " + _vm.meeting.shifts.name,
                typeText: "custom",
                italic: false,
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          {},
          [
            _c("BaseText", {
              attrs: {
                text: _vm.getTeacherName(),
                typeText: "custom",
                italic: false,
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: _vm.getEquipmentName(),
                typeText: "custom",
                italic: false,
                size: "sm",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _vm.isTeamsInProgress()
          ? _c(
              "div",
              {
                staticClass:
                  "justify-self-end grid grid-flow-col auto-cols-max",
              },
              [
                _vm.permitUp && _vm.season_date_manual_execution_end
                  ? _c(
                      "div",
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            iconName: "edit",
                            dispatch: "Meeting/editModal",
                            payload: _vm.meeting,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.permitToSuspend &&
                !_vm.meeting.suspended &&
                _vm.season_date_manual_execution_end
                  ? _c(
                      "div",
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            iconName: "block",
                            dispatch: "Meeting/toggleModalSuspendMeeting",
                            title: "Suspender Encontro",
                            payload: _vm.meeting,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.permitToDisableSuspension &&
                _vm.meeting.suspended &&
                _vm.season_date_manual_execution_end
                  ? _c(
                      "div",
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            iconName: "check_circle",
                            dispatch:
                              "Meeting/toggleModalDisableSuspensionMeeting",
                            title: "Desativar Suspensão",
                            payload: _vm.meeting,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.permitDes && _vm.season_date_manual_execution_end
                  ? _c(
                      "div",
                      [
                        _c("AppButtonIcon", {
                          attrs: {
                            iconName: "delete",
                            dispatch:
                              "Meetings/openCloseDeleteMeetingConfirmation",
                            payload: _vm.meeting,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            )
          : _vm._e(),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }