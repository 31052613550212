// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("../assets/images/background-sigin-admin.png");
var ___CSS_LOADER_URL_IMPORT_1___ = require("../assets/images/img-rodape.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
exports.push([module.id, ".signin-login[data-v-3acea9e3] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-color: #dbe6de;\n  background-size: cover;\n  width: 100%;\n  min-height: 100vh;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n}\n.bot[data-v-3acea9e3] {\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n  height: 55px;\n}\n.text-color-admin[data-v-3acea9e3] {\n  color: #328239;\n}\n@media (max-width: 768px) {\n.top-36[data-v-3acea9e3] {\n    left: 10px;\n}\n.signin-login[data-v-3acea9e3] {\n    padding: 20px;\n}\n}\r\n", ""]);
// Exports
module.exports = exports;
