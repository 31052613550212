<template>
  <div id="about">
    <div class="header fixed z-10 w-full">
      <div class="flex flex-wrap ml-32 my-24">
        <div id="logo" class="w-3/6">
          <img src="../assets/images/logo-inicio.png" alt="" />
        </div>
        <div id="menus" class="flex justify-center items-center w-auto mt-5">
          <router-link to="/">
            <AppButtonEmpty
              text="Início"
              class="w-36 h-10 mr-12"
              type-button="default"
              type-text="button-generic"
            />
          </router-link>
          <AppButtonEmpty
            :active="true"
            text="Sobre"
            class="w-36 h-10 mr-12"
            type-button="default"
            type-text="button-generic"
          />
          <!-- <router-link class="text-white underline hover:text-burnedYellow mr-12" to="/instrutor/entrar">
            <BaseText text="Sou Instrutor" type-text="custom" weight="medium" />
          </router-link> -->
          <router-link class="text-white underline hover:text-burnedYellow mr-12" to="/admin/entrar">
            <BaseText text="Área de Login" type-text="custom" weight="medium" />
          </router-link>
        </div>
      </div>
    </div>
    <div id="body">
      <div id="text-carrosel" class="flex flex-wrap">
        <div id="the-project" class="flex flex-col justify-center w-3/6">
          <div class="ml-32 mb-16">
            <BaseText
              text="O projeto"
              type-text="custom"
              weight="extrabold"
              size="3xl"
              color="burnedYellow-classic"
            />
          </div>
          <div class="ml-32 description text-color">
            <BaseText type-text="custom" weight="normal">
              O projeto Virando o Jogo é um dos projetos do Programa Superação (Decreto Nº 33.377 de 29 de
              novembro de 2019), que reúne uma nova geração de políticas públicas para a juventude cearense.
              As iniciativas devem ampliar as capacidades e as habilidades desse segmento social, além de
              reforçar fatores protetivos junto às famílias e às comunidades, promovendo a reinserção escolar,
              fortalecendo a cidadania e oferecendo cursos de qualificação profissional para os jovens.
            </BaseText>
          </div>
        </div>
        <div id="carrosel" class="w-3/6">
          <div class="w-full h-full">
            <splide :options="options">
              <splide-slide>
                <img src="../assets/images/about-virandoojogo-1.png" />
              </splide-slide>
              <splide-slide>
                <img src="../assets/images/about-virandoojogo-2.png" />
              </splide-slide>
              <splide-slide>
                <img src="../assets/images/about-virandoojogo-3.png" />
              </splide-slide>
              <splide-slide>
                <img src="../assets/images/about-virandoojogo-4.png" />
              </splide-slide>
              <splide-slide>
                <img src="../assets/images/about-virandoojogo-5.png" />
              </splide-slide>
            </splide>
          </div>
        </div>
      </div>
      <div id="objectives">
        <div class="flex justify-center align-center">
          <div class="my-20">
            <BaseText
              text="Objetivos"
              type-text="custom"
              weight="extrabold"
              size="3xl"
              color="burnedYellow-classic"
            />
          </div>
        </div>
        <div class="flex flex-wrap justify-center align-center pb-10">
          <div class="flex justify-center align-center flex-col text-center p-2 mr-24">
            <div>
              <img src="../assets/images/obj1.png" alt="" />
            </div>
            <div class="flex justify-center align-center h-32 w-60 mt-5">
              <BaseText
                text="Promoção da cidadania na plenitude das responsabilidades e dos direitos dos jovens"
                type-text="custom"
                weight="normal"
                class="flex justify-center align-center text-color"
              />
            </div>
          </div>
          <div class="flex justify-center align-center flex-col text-center p-2 mr-24">
            <div>
              <img src="../assets/images/obj2.png" alt="" />
            </div>
            <div class="flex justify-center align-center h-32 w-60 mt-5">
              <BaseText
                text="Fortalecimento dos vínculos familiares e comunitários"
                type-text="custom"
                weight="normal"
                class="flex justify-center align-center text-color"
              />
            </div>
          </div>
          <div class="flex justify-center align-center flex-col text-center p-2 mr-24">
            <div>
              <img src="../assets/images/obj3.png" alt="" />
            </div>
            <div class="flex justify-center align-center h-32 w-60 mt-5">
              <BaseText
                text="Qualificação profissional e reinserção escolar"
                type-text="custom"
                weight="normal"
                class="flex justify-center align-center text-color"
              />
            </div>
          </div>
          <div class="flex justify-center align-center flex-col text-center p-2 mr-24">
            <div>
              <img src="../assets/images/obj4.png" alt="" />
            </div>
            <div class="flex justify-center align-center h-32 w-60 mt-5">
              <BaseText
                text="Acesso a práticas esportivas, culturais, ambientais e de lazer"
                type-text="custom"
                weight="normal"
                class="flex justify-center align-center text-color"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="accordion-game" class="flex flex-wrap">
        <div id="the-game" class="flex flex-col justify-center w-3/6 py-32">
          <div class="ml-32 mb-16">
            <BaseText
              text="Virando o Jogo"
              type-text="custom"
              weight="extrabold"
              size="3xl"
              color="burnedYellow-classic"
            />
          </div>
          <div class="ml-32 description text-color">
            <BaseText type-text="custom" weight="normal">
              Os jovens que participam do projeto têm acesso a módulos de formação cidadã, ação comunitária,
              empreendedorismo e cursos de qualificação profissional em diversas áreas. Há ainda o incentivo
              de práticas esportivas, culturais, ambientais e de lazer. As ações são divididas em três fases
              de formação.
            </BaseText>
          </div>
        </div>
        <div id="accordion" class="w-3/6">
          <vsa-list class="bg-burnedYellow-classic accordion-custom">
            <vsa-item init-active>
              <vsa-heading>
                <BaseText text="1º FASE" type-text="custom" weight="normal" size="lg" color="white" />
                <BaseText
                  text="| FORMAÇÃO CIDADÃ E AÇÃO COMUNITÁRIA"
                  type-text="custom"
                  weight="semibold"
                  size="lg"
                  color="white"
                />
              </vsa-heading>

              <vsa-content>
                <BaseText type-text="custom" weight="normal" class="text-color">
                  Na Formação Cidadã os jovens têm encontros nos quais são abordados diversos temas como
                  autoconhecimento, autoestima, relações sociais e afetivas, saúde, projeto de vida e
                  primeiros socorros.<br />
                  Já a Ação Comunitária busca fortalecer a participação dos jovens diante da necessidades da
                  sua comunidade/bairro, incentivando a reflexão sobre questões locais e capacidade de
                  intervenção através de um plano de ação comunitária a ser planejado e realizado pelos
                  jovens.
                </BaseText>
              </vsa-content>
            </vsa-item>
            <vsa-item>
              <vsa-heading>
                <BaseText text="2º FASE" type-text="custom" weight="normal" size="lg" color="white" />
                <BaseText
                  text="| QUALIFICAÇÃO PROFISSIONAL E ATIVIDADE ESPORTIVA OU CULTURAL"
                  type-text="custom"
                  weight="semibold"
                  size="lg"
                  color="white"
                />
              </vsa-heading>

              <vsa-content>
                <BaseText type-text="custom" weight="normal" class="text-color">
                  Na 2ª fase o jovem opta pelo curso de qualificação profissional e atividade esportiva ou
                  cultural do seu interesse. São ofertados mais de 10 cursos nas áreas de comércio, cuidados
                  pessoais e beleza, administração, alimentação, moda, produção cultural, construção e
                  reparos.
                </BaseText>
              </vsa-content>
            </vsa-item>
            <vsa-item>
              <vsa-heading>
                <BaseText text="3º FASE" type-text="custom" weight="normal" size="lg" color="white" />
                <BaseText
                  text="| MERCADO DE TRABALHO, EMPREENDEDORISMO E GESTÃO FINANCEIRA"
                  type-text="custom"
                  weight="semibold"
                  size="lg"
                  color="white"
                />
              </vsa-heading>

              <vsa-content>
                <BaseText type-text="custom" weight="normal" class="text-color">
                  Essa fase tem como objetivo desenvolver habilidades e competências para aumentar as chances
                  de inclusão produtiva dos jovens, seja no mercado formal, seja como microempreendedor
                  individual/autônomos.
                </BaseText>
              </vsa-content>
            </vsa-item>
          </vsa-list>
        </div>
      </div>
      <div id="financial-assistance" class="flex flex-wrap">
        <div class="flex flex-col justify-center py-32 w-3/6">
          <div class="ml-32 mb-8">
            <BaseText
              text="Auxílio Financeiro"
              type-text="custom"
              weight="bold"
              size="2xl"
              color="burnedYellow-classic"
            />
          </div>
          <div class="ml-32 description text-color">
            <BaseText type-text="custom" weight="normal">
              Todas as fases possuem um auxílio financeiro mensal cujo valor varia de acordo com a
              participação do jovem nas atividades do Projeto, considerando os valores previstos em cada fase,
              sendo:
            </BaseText>
          </div>
        </div>
        <div class="flex py-32 w-3/6">
          <div class="flex flex-col mr-24">
            <div class="mb-3">
              <BaseText text="1ª Fase" type-text="custom" weight="normal" size="1xl" class="text-color" />
            </div>
            <div class="mb-3 w-48">
              <BaseText
                text="Formação Cidadã e Ação Comunitária"
                type-text="custom"
                weight="bold"
                size="1xl"
                class="text-color"
              />
            </div>
            <div>
              <BaseText
                text="R$ 200,00"
                type-text="custom"
                weight="bold"
                size="3xl"
                class="text-color"
                color="burnedYellow-classic"
              />
            </div>
          </div>
          <div class="flex flex-col mr-24">
            <div class="mb-3">
              <BaseText text="2ª Fase" type-text="custom" weight="normal" size="1xl" class="text-color" />
            </div>
            <div class="mb-3 w-68">
              <BaseText
                text="Qualificação Profissional e Atividade Esportiva ou Cultural"
                type-text="custom"
                weight="bold"
                size="1xl"
                class="text-color"
              />
            </div>
            <div>
              <BaseText
                text="R$ 250,00"
                type-text="custom"
                weight="bold"
                size="3xl"
                class="text-color"
                color="burnedYellow-classic"
              />
            </div>
          </div>
          <div class="flex flex-col mr-24">
            <div class="mb-3">
              <BaseText text="3ª Fase" type-text="custom" weight="normal" size="1xl" class="text-color" />
            </div>
            <div class="mb-3 w-48">
              <BaseText
                text="Mercado de Trabalho, Empreendedorismo e Gestão Financeira"
                type-text="custom"
                weight="bold"
                size="1xl"
                class="text-color"
              />
            </div>
            <div>
              <BaseText
                text="R$ 300,00"
                type-text="custom"
                weight="bold"
                size="3xl"
                class="text-color"
                color="burnedYellow-classic"
              />
            </div>
          </div>
        </div>
      </div>
      <div id="social" class="flex flex-wrap footer">
        <div class="flex justify-between w-full py-24 px-32">
          <div class="w-128">
            <BaseText type-text="custom" weight="normal" color="white">
              Todas as informações do Virando o Jogo serão divulgadas nesse site e nas redes sociais do
              projeto.
            </BaseText>
            <br />
            <BaseText
              text="Acompanhe nossas redes sociais!"
              type-text="custom"
              weight="semibold"
              size="base"
              color="burnedYellow-classic"
            />
          </div>
          <div class="flex items-center">
            <div class="mr-5">
              <a href="http://instagram.com/virandoojogo.superacao" target="_blank">
                <img src="../assets/images/instagram.png" />
              </a>
            </div>
            <div>
              <a href="http://fb.com/virandoojogo.superacao" target="_blank">
                <img src="../assets/images/facebook.png" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="bot w-full"></div>
    </div>
  </div>
</template>

<script>
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import BaseText from "../components/BaseText.vue";
import { VsaList, VsaItem, VsaHeading, VsaContent } from "vue-simple-accordion";

import "vue-simple-accordion/dist/vue-simple-accordion.css";
import "@splidejs/splide/dist/css/themes/splide-sea-green.min.css";
import { Splide, SplideSlide } from "@splidejs/vue-splide";

export default {
  components: {
    Splide,
    SplideSlide,
    AppButtonEmpty,
    BaseText,
    VsaList,
    VsaItem,
    VsaHeading,
    VsaContent,
  },

  data() {
    return {
      options: {
        rewind: true,
        fixedHeight: "454px",
        pagination: false,
        autoplay: true,
        pauseOnHover: true,
      },
    };
  },
};
</script>

<style>
.accordion-custom {
  --vsa-highlight-color: #db8300;
  --vsa-border-color: #ffa200;
  --vsa-text-color: #fff;
  --vsa-max-width: 100%;
  --vsa-heading-padding: 2.2rem 2rem;
  --vsa-content-padding: 1rem 2rem;
  --vsa-border-width: 1;
  --vsa-border-stylesolid: solid;
}

.vsa-item__heading,
.vsa-item__content {
  border-bottom: 1px solid #fff;
}

.vsa-item__trigger[aria-expanded="true"] .vsa-item__trigger__icon--is-default:before {
  transform: rotate(45deg) translate3d(25px, 10px, 0);
}

.vsa-item__trigger[aria-expanded="true"] .vsa-item__trigger__icon--is-default:after {
  transform: rotate(-45deg) translate3d(-24px, 10px, 0);
}

.footer {
  background-color: #662e91;
}

.text-color {
  color: #008037;
}

.description {
  width: 600px;
}

#body {
  position: relative;
  top: 290px;
}

#text-carrosel,
#accordion-game {
  background-color: #fbfbfb;
}

.text-fase {
  color: #002c13;
}

.splide {
  padding: 0 3em;
}

.splide__arrow svg {
  fill: #3ba543 !important;
}

.splide__arrow:hover svg {
  fill: #69c968 !important;
}

.header {
  background-color: #3ba543;
  background-image: linear-gradient(45deg, #3ba543 49%, #69c968 70%);
}

.bot {
  background-image: url("../assets/images/img-rodape.png");
  height: 55px;
}
</style>
