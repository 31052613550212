var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "m-5" }, [
    _c(
      "div",
      {
        staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
        attrs: { id: "person-data" },
      },
      [
        _c(
          "div",
          { staticClass: "col-span-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Dados do Encaminhamento",
                "type-text": "title-forms",
              },
            }),
            _c("hr", {
              staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Nome do Participante",
                text: _vm.inscription.name,
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Área",
                text: _vm.inscription.neighborhood.area.name,
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Data do Encaminhamento",
                text: _vm.formattedDate(
                  _vm.forwarding_reservation_registration.forwarding_date
                ),
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Empresa",
                text: _vm.company ? _vm.company.name : "Não informado",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-span-2" },
          [
            _c("AppTextField", {
              attrs: {
                "show-label": "",
                "label-text": "Parceiro",
                text: _vm.partner ? _vm.partner.name : "Não informado",
                "type-text": "topic-details",
              },
            }),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }