<template>
  <BaseCard :class="liClass">
    <div class="content-center">
      <div class="justify-self-start">
        <BaseText
          :text="area.name"
          typeText="custom"
          weight="bold"
          mode="underline"
          :italic="false"
          size="xl"
        />
      </div>
    </div>
    <div class="justify-items-start">
      <BaseText
        :text="area.qtdNeighborhoods + ' bairros'"
        typeText="custom"
        :italic="false"
        size="sm"
        color="ashes-dark"
      />
    </div>
    <div class="justify-items-start">
      <BaseText
        :text="area.qtdEquipment + ' equipamentos'"
        typeText="custom"
        :italic="false"
        size="sm"
        color="ashes-dark"
      />
    </div>
    <div class="text-right justify-self-end">
      <AppButtonIcon iconName="edit" dispatch="Area/editModal" v-if="permitUp" :payload="area" />
      <AppButtonIcon
        iconName="delete"
        :payload="area"
        v-if="permitDes"
        dispatch="Areas/openCloseDeleteAreaConfirmation"
      />
    </div>
  </BaseCard>
</template>

<script>
import BaseCard from "./BaseCard.vue";
import AppButtonIcon from "./AppButtonIcon.vue";
import BaseText from "./BaseText.vue";
import { mapActions } from "vuex";

export default {
  components: {
    BaseCard,
    AppButtonIcon,
    BaseText,
  },

  props: {
    active: {
      type: Boolean,
      default: false,
    },
    area: {
      type: Object,
    },
  },
  data() {
    return {
      permitUp: false,
      permitDes: false,
      permit: false,
    };
  },
  methods: {
    ...mapActions({
      canAcces: "AccessControl/canAcces",
    }),
    areaAcces() {
      this.canAcces("area_index").then((resp) => {
        this.permit = resp;
      });
    },
    areaDelete() {
      this.canAcces("area_destroy").then((resp) => {
        this.permitDes = resp;
      });
    },
    areaChange() {
      this.canAcces("area_update").then((resp) => {
        this.permitUp = resp;
      });
    },
  },

  computed: {
    liClass() {
      return {
        "w-full": true,
        "h-28": true,
        "hover:shadow-box": true,
        "rounded-xl": true,
        grid: true,
        "grid-cols-4": true,
        "justify-items-stretch": true,
        "gap-4": true,
        "content-center": true,
        "p-6": true,
        flex: true,
        "items-center": true,
        "cursor-pointer": true,
      };
    },
  },
  created() {
    this.areaAcces();
    this.areaChange();
    this.areaDelete();
  },
};
</script>

<style></style>
