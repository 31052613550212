export const util_function = {
  user: JSON.parse(localStorage.getItem("userData")),

  // Acessando as áreas do usuário
  user_areas() {
    return this.user?.areas || [];
  },

  processInscriptionStatuses: (status) => {
    let status_processed;
    switch (status) {
      case "accepted":
        status_processed = "Ativo(a)";
        break;
      case "not_accepted":
        status_processed = "Desligado(a)";
        break;
      case "graduate":
        status_processed = "Concludente";
        break;
      case "archived":
        status_processed = "Arquivado";
        break;
      case "open":
        status_processed = "Em Aberto";
        break;
      case "under_analysis":
        status_processed = "Em Análise";
        break;
      default:
        status_processed = "-";
    }
    return status_processed;
  },
  formattedBirthdate: (birthdate) => {
    return birthdate.split("-").reverse().join("/");
  },
  getLastPresence: (arrayDates) => {
    let greater_date = arrayDates[0] ? arrayDates[0] : null;
    arrayDates.forEach((presence) => {
      if (presence > greater_date) {
        greater_date = presence;
      }
    });
    return greater_date ? util_function.formattedBirthdate(greater_date) : "-";
  },
  isTeamsInProgress() {
    return this.tabTypeTeamView === "teams_in_progress";
  },
};
