var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.open
        ? _c("BaseModal", {
            attrs: { headerColor: "#f1f1f1" },
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "flex flex-row-reverse" },
                        [
                          _c("AppButtonIcon", {
                            attrs: {
                              "for-close": "",
                              dispatch: "ModalInscription/closeModal",
                            },
                          }),
                        ],
                        1
                      ),
                      _c("div", { staticClass: "ml-52 relative" }, [
                        _c(
                          "div",
                          { staticClass: "absolute top-11 -left-1" },
                          [
                            _c("BaseText", {
                              staticClass: "text-burnedYellow",
                              attrs: {
                                text: `${_vm.getPhaseName(_vm.student.id)}`,
                                typeText: "custom",
                                weight: "normal",
                                italic: false,
                                size: "lg",
                              },
                            }),
                            _c("BaseText", {
                              attrs: {
                                text: " | ",
                                typeText: "custom",
                                weight: "normal",
                                italic: false,
                                size: "sm",
                              },
                            }),
                            _c("BaseText", {
                              staticClass: "text-burnedYellow",
                              attrs: {
                                text: `${_vm.getDiscipline(_vm.student.id)}`,
                                typeText: "custom",
                                weight: "normal",
                                italic: false,
                                size: "lg",
                              },
                            }),
                            _c("BaseText", {
                              attrs: {
                                text: " | ",
                                typeText: "custom",
                                weight: "normal",
                                italic: false,
                                size: "sm",
                              },
                            }),
                            _c("BaseText", {
                              staticClass: "text-burnedYellow",
                              attrs: {
                                text: `${_vm.getTeamCode(_vm.student.id)}`,
                                typeText: "custom",
                                weight: "normal",
                                italic: false,
                                size: "lg",
                              },
                            }),
                          ],
                          1
                        ),
                      ]),
                      _c(
                        "div",
                        { staticClass: "flex flex-wrap mb-3 justify-center" },
                        [
                          _c(
                            "div",
                            { staticClass: "mr-6", attrs: { id: "avatar" } },
                            [
                              _c("img", {
                                staticClass: "rounded-full w-44 h-44",
                                attrs: {
                                  src: require("../assets/images/profile-vj.png"),
                                  alt: "Foto Profile",
                                },
                              }),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass:
                                "md:w-full lg:w-full xl:w-5/6 self-center",
                              attrs: { id: "options" },
                            },
                            [
                              _c(
                                "div",
                                { attrs: { id: "name-student" } },
                                [
                                  _c("BaseText", {
                                    attrs: {
                                      text: `${_vm.student.name}`,
                                      "type-text": "custom",
                                      weight: "normal",
                                      mode: "capitalize",
                                      color: "quantum",
                                      size: "3xl",
                                    },
                                  }),
                                  _vm.student.social_name
                                    ? _c("BaseText", {
                                        attrs: {
                                          text: ` - ${_vm.student.social_name}`,
                                          "type-text": "custom",
                                          weight: "normal",
                                          mode: "capitalize",
                                          color: "quantum",
                                          size: "3xl",
                                        },
                                      })
                                    : _vm._e(),
                                  _c("BaseText", {
                                    attrs: {
                                      text: `, ${String(_vm.student.age)}`,
                                      "type-text": "custom",
                                      weight: "normal",
                                      color: "quantum",
                                      size: "3xl",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "body",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "container1" }, [
                        _c(
                          "div",
                          { staticClass: "container2" },
                          [
                            _vm.showFormEditInscription
                              ? _c("FormStudent")
                              : _c("Student"),
                          ],
                          1
                        ),
                      ]),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              3326730203
            ),
          })
        : _vm._e(),
      _c("UnderAnalysisConfirmation", {
        attrs: { open: _vm.modalUnderAnalysisConfirmationOpen },
      }),
      _c("AcceptedConfirmation", {
        attrs: { open: _vm.modalAcceptedConfirmationOpen },
      }),
      _c("NotAcceptedConfirmation", {
        attrs: { open: _vm.modalNotAcceptedConfirmationOpen },
      }),
      _c("Alert", { attrs: { open: _vm.modalInscriptionAlertStatusOpen } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }