var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("label", { class: _vm.classCss, attrs: { for: _vm.id } }, [
      _c("span", [_vm._v(_vm._s(_vm.text))]),
      _c(
        "div",
        {
          staticClass:
            "absolute top-40 right-0 w-10 h-10 bg-burnedYellow-hover rounded-full text-white",
        },
        [
          _c("AppMaterialIcon", {
            staticClass: "ml-2 mt-2",
            attrs: { iconName: _vm.iconName },
          }),
        ],
        1
      ),
    ]),
    _c("input", {
      ref: "documents",
      staticClass: "opacity-0 w-0",
      attrs: {
        id: _vm.id,
        type: "file",
        name: _vm.name,
        accept: "image/*",
        multiple: _vm.isMultiple,
      },
      on: {
        input: function ($event) {
          return _vm.addImageInStore($event)
        },
      },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }