var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "div",
        { staticClass: "grid grid-cols-custom gap-10 items-center mt-4" },
        [
          _c(
            "div",
            { staticClass: "ml-7" },
            [
              _c("BaseText", {
                attrs: {
                  text: "Nome",
                  typeText: "custom",
                  weight: "medium",
                  italic: false,
                  mode: "uppercase",
                  size: "xs",
                  color: "quantum",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseText", {
                attrs: {
                  text: "Quantidade de Alunos(a)",
                  typeText: "custom",
                  weight: "medium",
                  italic: false,
                  mode: "uppercase",
                  size: "xs",
                  color: "quantum",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseText", {
                attrs: {
                  text: "Período",
                  typeText: "custom",
                  weight: "medium",
                  italic: false,
                  mode: "uppercase",
                  size: "xs",
                  color: "quantum",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseText", {
                attrs: {
                  text: "Instrutor",
                  typeText: "custom",
                  weight: "medium",
                  italic: false,
                  mode: "uppercase",
                  size: "xs",
                  color: "quantum",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("BaseText", {
                attrs: {
                  text: "Equipamento",
                  typeText: "custom",
                  weight: "medium",
                  italic: false,
                  mode: "uppercase",
                  size: "xs",
                  color: "quantum",
                },
              }),
            ],
            1
          ),
          _c("div", { staticClass: "flex" }),
        ]
      ),
      _c(
        "BaseStaggeredFade",
        { attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.data, function (team, index) {
          return _c(
            "li",
            {
              key: index,
              attrs: { "data-index": index },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.gotToTeam(team)
                },
              },
            },
            [
              _c("div", [_c("AppCardTeams", { attrs: { team: team } })], 1),
              _c("div", { staticClass: "border-ashes w-full border" }),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }