<template>
  <div class="font-system" v-if="permit">
    <div id="title-page" class="mx-12 my-6">
      <div class="text-ashes-hover font-medium text-3xl">{{ city.name }} | Instrutores</div>
    </div>
    <div id="tabs-button" class="flex justify-between mx-12 my-6">
      <div class="flex">
        <div class="w-104">
          <BaseInput
            active
            inputName=""
            inputType="text"
            iconName="search"
            showIcon
            input-placeholder="Pesquisar por nome ou cpf"
            dispatch="Teachers/filterList"
          />
        </div>
      </div>
      <div class="float-right">
        <AppButtonColorful
          id="btn-add-teacher"
          showIcon
          iconName="add"
          text="Adicionar Instrutor"
          dispatch="Teachers/openModalTeacher"
          typeButton="primary"
          v-if="permitCreate"
        />
      </div>
    </div>
    <div id="list-teacher">
      <div class="mx-12">
        <TeachersCards :data="teachers" />
      </div>
      <ModalTeacher :open="modalTeacherOpen" />
      <ModalResetTeacherPassword :open="modalResetPassword" />
      <DeleteTeacherConfirmation :open="modalDeleteTeacherOpen" />
    </div>
  </div>

  <ProhibitedAccessPage
    classText="grid auto-grid-auto p-10 text-carmesim-hover font-medium text-3xl mx-2 col-span-2"
    v-else
  />
</template>

<script>
import TeachersCards from "./list/TeachersCards.vue";
import ModalTeacher from "./ModalTeacher.vue";
import ModalResetTeacherPassword from "./resets/ModalResetTeacherPassword.vue";
import DeleteTeacherConfirmation from "./alerts/DeleteTeacherConfirmation.vue";
import AppButtonColorful from "../components/AppButtonColorful.vue";
import BaseInput from "../components/forms/BaseInput.vue";
import ProhibitedAccessPage from "../components/ProhibitedAccessPage.vue";
import { mapState, mapActions } from "vuex";

export default {
  components: {
    AppButtonColorful,
    TeachersCards,
    ModalTeacher,
    ModalResetTeacherPassword,
    DeleteTeacherConfirmation,
    BaseInput,
    ProhibitedAccessPage,
  },
  data() {
    return {
      permitCreate: false,
      permit: false,
      permitUp: false,
    };
  },
  methods: {
    ...mapActions({ canAcces: "AccessControl/canAcces" }),
  },

  created() {
    const city = localStorage.city_id;
    this.$store.dispatch("Teachers/loadTeachers", city);
    this.$store.dispatch("Teacher/loadProfiles");
    this.canAcces("teacher_create").then((resp) => {
      this.permit = resp;
    });
    this.$store.dispatch("Teachers/loadTeachers", city);
    this.canAcces("teacher_create").then((resp) => {
      this.permitCreate = resp;
    });
    this.canAcces("teacher_index").then((resp) => {
      this.permit = resp;
    });
    this.canAcces("teacher_update").then((resp) => {
      this.permitUp = resp;
    });
  },

  computed: {
    ...mapState({
      teachers: (state) => state.Teachers.filterTeachers,
      modalDeleteTeacherOpen: (state) => state.Teachers.modalDeleteTeacherConfirmationOpen,
      modalTeacherOpen: (state) => state.Teachers.modalTeacherOpen,
      modalResetPassword: (state) => state.Teacher.modalResetPassword,
      city: (state) => state.City.city,
    }),
  },
};
</script>
