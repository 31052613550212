import { render, staticRenderFns } from "./AppCardSpontaneousProductiveInsertion.vue?vue&type=template&id=57bec2aa&scoped=true"
import script from "./AppCardSpontaneousProductiveInsertion.vue?vue&type=script&lang=js"
export * from "./AppCardSpontaneousProductiveInsertion.vue?vue&type=script&lang=js"
import style0 from "./AppCardSpontaneousProductiveInsertion.vue?vue&type=style&index=0&id=57bec2aa&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57bec2aa",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/www/html/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('57bec2aa')) {
      api.createRecord('57bec2aa', component.options)
    } else {
      api.reload('57bec2aa', component.options)
    }
    module.hot.accept("./AppCardSpontaneousProductiveInsertion.vue?vue&type=template&id=57bec2aa&scoped=true", function () {
      api.rerender('57bec2aa', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/AppCardSpontaneousProductiveInsertion.vue"
export default component.exports