var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { attrs: { bg_color: "bg-burnedYellow-soft" } }, [
    _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.formattedDate(
                _vm.forwarding_postponement_history.created_at
              ),
              mode: "normal-case",
              typeText: "custom",
              weight: "semibold",
              size: "sm",
              color: "quantum",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.formattedDate(
                _vm.forwarding_postponement_history
                  .forwarding_expiration_date_original
              ),
              mode: "normal-case",
              typeText: "custom",
              weight: "normal",
              color: "ashes-dark",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.forwarding_postponement_history.postponement_user.name,
              mode: "normal-case",
              typeText: "custom",
              weight: "normal",
              color: "ashes-dark",
              size: "sm",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mt-4" },
        [
          _c("BaseText", {
            attrs: {
              text: _vm.forwarding_postponement_history
                .forwarding_postponement_comments,
              mode: "normal-case",
              typeText: "custom",
              weight: "normal",
              color: "ashes-dark",
              size: "sm",
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }