var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "nav",
    {
      staticClass: "breadcrumb-container",
      attrs: { "aria-label": "breadcrumb" },
    },
    [
      _c(
        "ol",
        { staticClass: "breadcrumb" },
        _vm._l(_vm.breadcrumbs, function (crumb, index) {
          return _c(
            "li",
            { key: index, staticClass: "breadcrumb-item" },
            [
              index < _vm.breadcrumbs.length - 1
                ? _c(
                    "router-link",
                    {
                      staticClass: "breadcrumb-link",
                      attrs: { to: crumb.path },
                    },
                    [
                      _c("span", [_vm._v(_vm._s(crumb.name))]),
                      _c("span", { staticClass: "breadcrumb-separator" }, [
                        _vm._v("|"),
                      ]),
                    ]
                  )
                : _c("span", { staticClass: "breadcrumb-current" }, [
                    _vm._v(_vm._s(crumb.name)),
                  ]),
            ],
            1
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }