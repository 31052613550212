var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "grid grid-cols-custom items-center" }, [
        _c(
          "div",
          { staticClass: "mt-4 ml-5" },
          [
            _c("BaseText", {
              attrs: {
                text: "Nº",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "Nome Completo",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "CPF",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "Agência",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "Conta",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "% de frequência no período",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "Valor da bolsa",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "mt-4 ml-1" },
          [
            _c("BaseText", {
              attrs: {
                text: "Status do Pagamento",
                typeText: "custom",
                weight: "bold",
                italic: false,
                mode: "uppercase",
                size: "xs",
                color: "ashes-dark",
              },
            }),
          ],
          1
        ),
        _c("div", { staticClass: "mt-4 flex" }),
      ]),
      _c(
        "BaseStaggeredFade",
        { attrs: { duration: 10, tag: "ul" } },
        _vm._l(_vm.data, function (beneficiary, index) {
          return _c(
            "li",
            { key: beneficiary.id, attrs: { "data-index": index } },
            [
              _c(
                "div",
                [
                  _c("AppCardScholarshipBeneficiaries", {
                    attrs: { beneficiary: beneficiary, index: index },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "border-ashes w-full border" }),
            ]
          )
        }),
        0
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }