var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.open
    ? _c("BaseModal", {
        attrs: { width: "w-128" },
        scopedSlots: _vm._u(
          [
            {
              key: "header",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "flex flex-row-reverse" },
                    [
                      _c("AppButtonIcon", {
                        attrs: {
                          "for-close": "",
                          payload: {
                            mutation:
                              "toggleModalInscriptionStatusArchivedConfirmation",
                          },
                          dispatch:
                            "ModalInscription/toggleModalInscriptionStatus",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "flex" },
                    [
                      _c("AppMaterialIcon", {
                        staticClass: "text-carmesim-hover mr-2",
                        attrs: { iconName: "cancel" },
                      }),
                      _c("BaseText", {
                        attrs: {
                          text: "Arquivar Inscrição",
                          typeText: "custom",
                          weight: "semibold",
                          size: "2xl",
                          color: "ashes-dark",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
            {
              key: "body",
              fn: function () {
                return [
                  _c("div", { staticClass: "text-quantum mb-4" }, [
                    _vm._v(
                      " A ficha de inscrição do jovem mudará para o status "
                    ),
                    _c("b", [_vm._v('"ARQUIVADO"')]),
                    _vm._v(". "),
                  ]),
                  _c("div", { staticClass: "text-quantum" }, [
                    _c("b", [
                      _vm._v("Tem certeza que deseja mudar o status do jovem?"),
                    ]),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "footer",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "flex justify-end" },
                    [
                      _c("AppButtonEmpty", {
                        staticClass: "mr-5",
                        attrs: {
                          text: "Não",
                          "type-button": "danger",
                          payload: {
                            mutation:
                              "toggleModalInscriptionStatusArchivedConfirmation",
                          },
                          dispatch:
                            "ModalInscription/toggleModalInscriptionStatus",
                        },
                      }),
                      _c("AppButtonEmpty", {
                        attrs: {
                          text: "Sim",
                          "type-button": "success",
                          payload: {
                            mutation:
                              "toggleModalInscriptionStatusArchivedConfirmation",
                            status: "archived",
                          },
                          dispatch: "ModalInscription/setStatusAndCloseModal",
                        },
                      }),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ],
          null,
          false,
          1226576983
        ),
      })
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }