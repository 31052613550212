var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("BaseCard", { class: _vm.liClass, attrs: { active: "" } }, [
    _c("div", { staticClass: "grid grid-rows-2 content-center" }, [
      _c(
        "div",
        [
          _c("Label", {
            attrs: {
              text: _vm.season.name,
              icon: null,
              iconType: null,
              type: "text-xl",
              weight: "extrabold",
              normal: "",
              mode: "normal-case",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm._l(_vm.season.areas_attributes, function (area) {
            return _c(
              "span",
              { key: area.code },
              [
                _vm._v(" | "),
                _c("Label", {
                  staticClass: "text-burnedYellow",
                  attrs: {
                    text: area.name,
                    icon: null,
                    iconType: null,
                    type: "text-xs",
                    weight: "medium",
                    normal: "",
                    mode: "uppercase",
                  },
                }),
              ],
              1
            )
          }),
          _vm._v(" | "),
        ],
        2
      ),
    ]),
    _c("div", { staticClass: "grid grid-cols-2" }, [
      _c(
        "div",
        [
          _c("Label", {
            staticClass: "text-ashes-dark",
            attrs: {
              text: _vm.season.date_inscription_start,
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: "medium",
              normal: "",
              mode: "uppercase",
            },
          }),
          _vm._v(" - "),
          _c("Label", {
            staticClass: "text-ashes-dark",
            attrs: {
              text: _vm.season.date_inscription_end,
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: "medium",
              normal: "",
              mode: "uppercase",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm._v(" | "),
          _c("Label", {
            attrs: {
              text: _vm.season.inscription_status,
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: "bold",
              normal: "",
              mode: "uppercase",
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticClass: "grid grid-cols-2" }, [
      _c(
        "div",
        [
          _c("Label", {
            staticClass: "text-ashes-dark",
            attrs: {
              text: _vm.season.date_execution_start,
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: "medium",
              normal: "",
              mode: "uppercase",
            },
          }),
          _vm._v(" - "),
          _c("Label", {
            staticClass: "text-ashes-dark",
            attrs: {
              text: _vm.season.date_execution_end,
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: "medium",
              normal: "",
              mode: "uppercase",
            },
          }),
        ],
        1
      ),
      _c(
        "div",
        [
          _vm._v(" | "),
          _c("Label", {
            attrs: {
              text: _vm.season.execution_status,
              iconType: null,
              type: "text-xs",
              icon: "none",
              weight: "bold",
              normal: "",
              mode: "uppercase",
            },
          }),
        ],
        1
      ),
    ]),
    _vm.permitFinishSeason && !_vm.season.date_manual_execution_end
      ? _c(
          "div",
          { staticClass: "text-right" },
          [
            _c("BaseButton", {
              attrs: {
                label: "Encerrar Edição",
                active: "",
                styleButton: "danger",
                dispatch: "Season/openCloseEndSeasonConfirmation",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm.permitFinishSeason && _vm.season.date_manual_execution_end
      ? _c(
          "div",
          { staticClass: "text-right" },
          [
            _c("BaseButton", {
              attrs: {
                label: "Edição Encerrada",
                active: "",
                styleButton: "danger",
              },
            }),
          ],
          1
        )
      : _vm._e(),
    !_vm.season.date_manual_execution_end
      ? _c(
          "div",
          { staticClass: "text-right" },
          [
            _vm.permit
              ? _c("AppButtonIcon", {
                  attrs: { iconName: "edit" },
                  on: {
                    "on-click": function ($event) {
                      $event.stopPropagation()
                      return _vm.openSeasonModal.apply(null, arguments)
                    },
                  },
                })
              : _vm._e(),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }